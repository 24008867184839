import React, { useState } from 'react';
import { OperationResponseItems, useOpenAPIData } from '@iptor/business';
import { Dialog } from '@iptor/base';
import DeleteTextVersion from './components/DeleteTextVersion';
import AddEditTextView from './components/AddEditTextView';
import MultipleTextView from './components/MultipleTextView';

interface OrderTextProps {
  order: number;
  handleClose: (needsTableRefresh: boolean) => void;
  orderLine?: number;
  open: boolean;
  textExists?: boolean;
  defaultLanguage: string;
}

const OrderText: React.FC<OrderTextProps> = ({ order, handleClose, open, orderLine, textExists, defaultLanguage }) => {
  const [needsTableRefresh, setNeedsTableRefresh] = useState(false);
  const [textToDelete, setTextToDelete] = useState<
    OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0] | null
  >(null);
  const [isEditView, setIsEditView] = useState(!textExists);
  const { response, loading, refresh } = useOpenAPIData(
    'internal.v1.base-orders',
    orderLine ? 'GET /salesOrders/{order}/lines/{orderLine}/texts' : 'GET /salesOrders/{order}/texts',
    {
      order,
      orderLine,
    },
  );
  const [textToEdit, setTextToEdit] = useState<
    OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0] | null
  >(null);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(needsTableRefresh);
      }}
      fullWidth
      maxWidth={'sm'}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
        },
        '& .MuiPaper-root': {
          backgroundColor: (theme) => theme.palette.background.default,
          maxWidth: 'min-content',
          minWidth: '700px',
          boxShadow: (theme) => theme.shadows[2],
        },
      }}
    >
      {textToDelete ? (
        <DeleteTextVersion
          text={textToDelete}
          onClose={() => {
            setTextToDelete(null);
          }}
          refresh={refresh}
          orderLine={orderLine}
          setNeedsTableRefresh={setNeedsTableRefresh}
        />
      ) : isEditView ? (
        <AddEditTextView
          setIsEditView={setIsEditView}
          initialData={textToEdit}
          orderId={order}
          refresh={refresh}
          orderLine={orderLine}
          textExists={textExists}
          setNeedsTableRefresh={setNeedsTableRefresh}
          defaultLanguage={defaultLanguage}
          setTextToEdit={setTextToEdit}
        />
      ) : (
        <MultipleTextView
          setIsEditView={setIsEditView}
          textToEdit={textToEdit}
          loading={loading || !response}
          handleClose={() => {
            handleClose(needsTableRefresh);
          }}
          textList={(response?.data?.data as any)?.items || []}
          setTextToEdit={setTextToEdit}
          setTextToDelete={setTextToDelete}
        />
      )}
    </Dialog>
  );
};

export default OrderText;

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export type OrderTypeDropdownProps = BusinessDropdownProps & {
  where?: string;
};

export const SalesOrderTypesDropdown = (props: OrderTypeDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;
  const { where } = props;

  const { options: orderTypeOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'orderType',
    params: { windowId: 'salesOrderTypes', where },
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} options={orderTypeOptions} loading={loading} columnNames={COLUMN_NAMES} />;
};

import styled from '@emotion/styled';
import { Button, useCustomSnackbar, useIptorTranslation } from '@iptor/base';
import { FormLayout, openAPIManager, OperationResponseDataData, useOpenAPIMethod } from '@iptor/business';
import { Box, Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import OrderInsights from '../../components/OrderInsights';
import { Order } from '../../constants';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import BtBPurchaseOrderAndBtBIRO from './components/BtBPurchaseOrderAndBtBIRO';
import DispatchAndSourcing from './components/DispatchAndSourcing';
import ItemInsights from './components/ItemInsights';
import Miscellaneous from './components/Miscellaneous';
import PriceVATTax from './components/PricingVATTax';
import Shipping from './components/Shipping';
import WeightAndProject from './components/WeightAndProject';
import { formatErrorText } from '../../utils/formatError';

const Container = styled(Box)`
  width: 100%;
`;
export const ItemInfoForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useIptorTranslation();
  const { orderId, orderLine } = useParams<{ orderId: string; orderLine: string }>();
  const { notify } = useOrderNotifications();
  const { handleSubmit, control, setValue, reset, watch } = useForm<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >({ mode: 'onChange' });
  const { closeAllSnackbars } = useCustomSnackbar();

  const orderLineInfoApi = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}');
  const orderApi = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}');

  const [initialValues, setInitialValues] = useState<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >({});
  const [noDataUpdated, setNoDataUpdated] = useState(false);

  const watchedValues = watch();

  useEffect(() => {
    if (orderLineInfoApi.response?.data?.data) {
      setInitialValues(orderLineInfoApi.response.data.data);
      reset(orderLineInfoApi.response.data.data);
    }
  }, [reset, orderLineInfoApi.response?.data?.data]);

  useEffect(() => {
    orderApi.execute({ order: +orderId });
    orderLineInfoApi.execute({ order: +orderId, orderLine: +orderLine });
  }, [orderId, orderLine]);

  useEffect(() => {
    const updatedData: Partial<
      OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>
    > = {};
    Object.keys(watchedValues).forEach((key) => {
      const typedKey = key as keyof typeof watchedValues;
      if (watchedValues[typedKey] !== initialValues[typedKey]) {
        (updatedData as Record<keyof typeof watchedValues, any>)[typedKey] = watchedValues[typedKey];
      }
    });
    setNoDataUpdated(Object.keys(updatedData).length === 0);
  }, [watchedValues, initialValues]);

  const onSubmit = handleSubmit(async (data) => {
    closeAllSnackbars();
    const updatedData: Partial<
      OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>
    > = {};
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof typeof data;
      if (data[typedKey] !== initialValues[typedKey]) {
        (updatedData as Record<keyof typeof data, any>)[typedKey] = data[typedKey] ?? '';
      }
    });
    if (Object.keys(updatedData).length > 0) {
      setLoading(true);
      try {
        if (Object.keys(updatedData).includes('requestedDeliveryDate')) {
          try {
            const response = await openAPIManager.execute(
              'internal.v1.base-orders',
              'GET /salesOrders/{order}/lineSimulations',
              {
                order: +orderId,
                item: data.item,
                orderQuantitySalesUnit: data.orderQuantitySalesUnit,
                warehouse: data.warehouse,
                requestedDeliveryDate: data.requestedDeliveryDate,
              },
            );
            if (response.data?.data) {
              updatedData.dispatchTime = response.data.data.dispatchTime;
            }
          } catch (error) {
            notify('error', formatErrorText(error));
          }
        }
        const orderLineResponse: any = await openAPIManager.execute(
          'internal.v1.base-orders',
          'PUT /salesOrders/{order}/lines/{orderLine}',
          { order: +orderId, orderLine: +orderLine },
          { ...updatedData },
        );
        if (orderLineResponse.data?.messages?.length > 0) {
          notify('warning', orderLineResponse.data.messages);
        }
        notify('success', `Congrats! Item number (${orderLineResponse.data.data.item}) has been updated successfully.`);
        history.goBack();
      } catch (err) {
        const error = err as any;
        if (error.response?.data?.messages) {
          error.response.data.messages.forEach((message: any) => {
            if (message.type.toLowerCase() === 'warning') {
              notify('warning', [message]);
            } else {
              notify('error', [message]);
            }
          });
        } else {
          notify('error', formatErrorText(error));
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <FormLayout
      title={t('common:TXT_ItemInfo')}
      actions={[
        <Button
          id="close"
          variant="outlined"
          size="medium"
          onClick={() => {
            closeAllSnackbars();
            history.goBack();
          }}
        >
          {t('common:TXT_Close')}
        </Button>,
        <Button
          loading={loading}
          variant="contained"
          size="medium"
          form="itemInfoForm"
          type="submit"
          id="update"
          disabled={noDataUpdated}
        >
          {t('common:TXT_Update')}
        </Button>,
      ]}
      fixedActionBar
    >
      <Container>
        <OrderInsights order={orderApi.response?.data?.data as Order} />
        <Typography fontSize={18} fontWeight={600} sx={{ marginTop: 2 }}>
          {t('sales_orders:ITEM_INFO_itemInfoDetails')}
        </Typography>
        <Grid component="form" container direction="column" spacing={1} id="itemInfoForm" onSubmit={onSubmit}>
          {!orderLineInfoApi.response?.data?.data ? (
            <>
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
            </>
          ) : (
            <>
              <ItemInsights orderLineInfo={orderLineInfoApi.response.data.data} />
              <DispatchAndSourcing
                setValue={setValue}
                control={control}
                orderLineInfo={orderLineInfoApi.response.data.data}
              />
              <Shipping control={control} orderLineInfo={orderLineInfoApi.response.data.data} />
              <PriceVATTax control={control} orderLineInfo={orderLineInfoApi.response.data.data} />
              <WeightAndProject control={control} orderLineInfo={orderLineInfoApi.response.data.data} />
              <BtBPurchaseOrderAndBtBIRO
                setValue={setValue}
                control={control}
                orderLineInfo={orderLineInfoApi.response.data.data}
              />
              <Miscellaneous control={control} orderLineInfo={orderLineInfoApi.response.data.data} />
            </>
          )}
        </Grid>
      </Container>
    </FormLayout>
  );
};

import { TableFilter, TableSettings, Text, useIptorTranslation } from '@iptor/base';
import { IptorTable, openAPIManager, OperationResponseItems } from '@iptor/business';
import { Divider, Stack } from '@mui/material';
import { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from 'react';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';

interface Props {
  currentOrder: number;
  setGroupInView: (val: { assortmentGroup: string; assortmentID: string }) => void;
  handleClose: () => void;
  setisAddDisabled: Dispatch<SetStateAction<boolean>>;
}

type AssortmentGroupType = OperationResponseItems<'internal.v1.base-orders', 'GET /assortments'>[0];

const PastOrdersTable = forwardRef((props: Props, ref) => {
  const { setisAddDisabled } = props;
  const [selectedRows, setSelectedRows] = useState<AssortmentGroupType[]>([]);
  const { notify } = useOrderNotifications();
  const { t } = useIptorTranslation();

  useEffect(() => {
    if (selectedRows.length > 0) {
      setisAddDisabled(false);
    } else {
      setisAddDisabled(true);
    }
  }, [selectedRows]);

  useImperativeHandle(ref, () => ({
    handleAddGroups,
  }));

  const handleAddGroups = async () => {
    for (const row of selectedRows) {
      await openAPIManager
        .execute('internal.v1.base-orders', 'GET /assortments/{assortmentID}/lines', {
          assortmentID: row.assortmentID,
          assortmentGroup: row.assortmentGroup,
        })
        .then(async (res) => {
          if (!res.data.data.items) {
            notify('success', t('sales_orders:ASSORTMENTS_noLinesFound', '', { assortmentID: row.assortmentID }));
            return;
          }
          const postRequests = res.data.data.items.map((orderLine) => {
            const { item, quantity, unit } = orderLine;

            return openAPIManager.execute(
              'internal.v1.base-orders',
              'POST /salesOrders/{order}/lines',
              { order: props.currentOrder },
              { item, orderQuantitySalesUnit: quantity, unit },
            );
          });

          const results = await Promise.allSettled(postRequests);

          const failedRequests = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[];

          if (failedRequests.length > 0) {
            failedRequests.map((error) =>
              notify(
                'error',
                (error.reason as any)?.response?.data?.messages,
                false,
                t('sales_orders:ASSORTMENTS_failedToCopyLines', '', {
                  assortmentGroup: row.assortmentGroup,
                  assortmentID: row.assortmentID,
                }),
              ),
            );
          } else {
            notify(
              'success',
              t('sales_orders:ASSORTMENTS_linesCopied', '', {
                assortmentGroup: row.assortmentGroup,
                assortmentID: row.assortmentID,
              }),
            );
          }
        })
        .catch((error) => {
          notify(
            'error',
            t('sales_orders:ASSORTMENTS_failedToFetchLines', '', {
              assortmentGroup: row.assortmentGroup,
              assortmentID: row.assortmentID,
              error,
            }),
          );
        });
    }

    props.handleClose();
  };

  return (
    <IptorTable
      tableID="assortmentGroupsTable"
      apiId="internal.v1.base-orders"
      apiEndpoint="GET /assortments"
      selectable="multiple"
      additionalFilters={
        <Stack gap={2} direction="row" alignSelf="center" height="max-content">
          <Text>{t('sales_orders:ASSORTMENTS_assortmentsSelected')}</Text>
          <Text fontWeight={600}> {selectedRows.length}</Text>
          <Divider orientation="vertical" flexItem={true} />
        </Stack>
      }
      additionalActions={
        <>
          <TableFilter />
          <TableSettings />
        </>
      }
      columns={[
        {
          display: t('sales_orders:TABLE_COLUMN_assortmentID'),
          key: 'assortmentID',
          cellRenderer: ({ row, value }) => (
            <Text
              sx={{ fontWeight: '600', cursor: 'pointer' }}
              id={'assortmentGroup'}
              onClick={() => {
                props.setGroupInView({ assortmentGroup: row.assortmentGroup, assortmentID: row.assortmentID });
              }}
              color="info"
            >
              {value}
            </Text>
          ),
        },
        {
          display: t('sales_orders:TABLE_COLUMN_groupName'),
          key: 'assortmentGroup',
        },
        { display: t('sales_orders:TABLE_COLUMN_description'), key: 'description' },
        { display: t('sales_orders:TABLE_COLUMN_items'), key: 'numberOfItems', dataType: 'number', disableSort: true },
      ]}
      onSelectedRowsChange={({ rows }) => setSelectedRows(rows)}
    />
  );
});

export default PastOrdersTable;

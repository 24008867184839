export default (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      d="M1.5 9C1.5 5.22876 1.5 3.34315 2.7448 2.17157C3.98959 1 5.99306 1 10 1H10.7727C14.0339 1 15.6645 1 16.7969 1.79784C17.1214 2.02643 17.4094 2.29752 17.6523 2.60289C18.5 3.66867 18.5 5.20336 18.5 8.27273V10.8182C18.5 13.7814 18.5 15.2629 18.0311 16.4462C17.2772 18.3486 15.6829 19.8491 13.6616 20.5586C12.4044 21 10.8302 21 7.68182 21C5.88275 21 4.98322 21 4.26478 20.7478C3.10979 20.3424 2.19875 19.4849 1.76796 18.3979C1.5 17.7217 1.5 16.8751 1.5 15.1818V9Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 11C18.5 12.8409 17.0076 14.3333 15.1667 14.3333C14.5009 14.3333 13.716 14.2167 13.0686 14.3901C12.4935 14.5442 12.0442 14.9935 11.8901 15.5686C11.7167 16.216 11.8333 17.0009 11.8333 17.6667C11.8333 19.5076 10.3409 21 8.5 21"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6 6H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 10H9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

import React, { forwardRef } from 'react';
import { IconButton as MuiIconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { CircularProgress } from '@mui/material';
import IconType from '../../constants/IconType';
import { Icon } from '../Icon';

// extent the CustomIconButtonProps to include slotProps for tooltip
export interface CustomIconButtonProps extends IconButtonProps {
  loading?: boolean;
  icon: IconType;
  variant?: 'text' | 'contained' | 'outlined';
  tooltipText?: string;
  tooltipProps?: TooltipProps;
}

export const IconButton = forwardRef<HTMLButtonElement, CustomIconButtonProps>(
  ({ tooltipText = '', icon, size = 'medium', loading, variant = 'contained', tooltipProps, ...props }, ref) => {
    const mergedProps = { ...props, variant };

    const getIconSize = () => {
      switch (size) {
        case 'small':
          return 'S';
        case 'medium':
          return 'M';
        case 'large':
          return 'L';
        default:
          return 'M';
      }
    };
    return (
      <Tooltip title={tooltipText} {...tooltipProps} arrow>
        <MuiIconButton
          {...mergedProps}
          disabled={props.disabled || loading}
          size={size}
          data-iscapture="true"
          data-for="global"
          data-tip={mergedProps?.id}
          className={loading ? 'MuiIconButton-loading' : ''}
          ref={ref}
        >
          <>
            {loading ? (
              <CircularProgress size={size} color="inherit" />
            ) : (
              <Icon icon={icon} size={getIconSize()} color={'inherit'} />
            )}
          </>
        </MuiIconButton>
      </Tooltip>
    );
  },
);

import { SpotlightAreaCard, useClientSettings, useIptorTranslation } from '@iptor/base';
import { styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useOpenAPIMethod } from '../../framework/openapi';

type SpotlightAreaProps = {
  totalCards: number;
};

export interface SpotlightStats {
  currency: string;
  entityID?: string;
  entityName?: string;
  totalCount: string | number;
  totalValue: string | number;
}

const useStatisticsApi = (groupBy?: string, orderBy?: string, limit?: number) => {
  const apiMethod = useOpenAPIMethod('internal.v1.base-orders', 'GET /statistics/salesOrders');

  const executeApi = (params: Record<string, any>) => {
    if (groupBy) {
      params.groupBy = groupBy;
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }
    if (limit !== null) {
      params.limit = limit;
    }

    apiMethod.execute(params);
  };

  return {
    execute: executeApi,
    response: apiMethod.response,
    error: apiMethod.error,
    loading: apiMethod.loading,
  };
};

export const SpotlightArea = ({ totalCards }: SpotlightAreaProps) => {
  // type TotalOrderStats = Partial<SpotlightStats>;

  const orderStatsApi = useStatisticsApi();
  const topItemStatsApi = useStatisticsApi('item', 'totalValue', 3);
  const topCustomerStatsApi = useStatisticsApi('customer', 'totalValue', 3);

  const clientSettings = useClientSettings();

  const clientdtFormat = useMemo(
    //Upper case required as dayjs dateFormats are always uppercase
    () => clientSettings?.dateFormat?.toUpperCase(),
    [clientSettings.dateFormat],
  );

  const dtFormat = 'YYYY-MM-DD';
  const todaysDate = dayjs(new Date()).format(dtFormat);
  const { t } = useIptorTranslation();

  const [captionString, setCaptionString] = useState<string | never>(t('common:TXT_Today') ?? '');

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [selectedMonthYearForItem, setSelectedMonthYearForItem] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [selectedMonthYearForCustomer, setSelectedMonthYearForCustomer] = useState<Dayjs | null>(
    dayjs().startOf('month'),
  );

  const getTotalOrderStats = (dateString: string) => {
    orderStatsApi.execute({
      fromDate: dateString,
      toDate: dateString,
    });
  };

  const getTopListedItems = (month: string, year: string) => {
    const fromDate = `${year}-${month}-01`;
    const toDate = dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');

    topItemStatsApi.execute({
      groupBy: 'item',
      orderBy: 'totalValue',
      limit: 3,
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  const getTopListedCustomers = (month: string, year: string) => {
    const fromDate = `${year}-${month}-01`;
    const toDate = dayjs(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD');

    topCustomerStatsApi.execute({
      groupBy: 'customer',
      limit: 3,
      orderBy: 'totalValue',
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  useEffect(() => {
    const todaysDate = dayjs(new Date()).format(dtFormat);
    const month = dayjs(todaysDate).format('MM'); // Get month
    const year = dayjs(todaysDate).format('YYYY'); // Get year

    const todaysDateString = new Date().toISOString().slice(0, 10);

    //total orders
    getTotalOrderStats(todaysDateString);
    // Top 3 Items
    getTopListedItems(month, year);
    // Top 3 customers
    getTopListedCustomers(month, year);
  }, []);

  const StyledDiv = styled('div')`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${totalCards}, 1fr);
    gap: ${({ theme }) => theme.spacing(2.6)};
  `;

  const formatToThreeDecimals = (value: string | number) => {
    if (!value) return '0'; // Handle undefined or null values
    const number = +value;
    return Number.isInteger(number) ? number.toString() : number.toFixed(3);
  };

  const totalOrderStats = ((orderStatsApi.response as any)?.data?.data?.items?.[0] || {}) as SpotlightStats;
  const topItemStats = ((topItemStatsApi.response as any)?.data?.data?.items || []) as SpotlightStats[];
  const topCustomerStats = ((topCustomerStatsApi.response as any)?.data?.data?.items || []) as SpotlightStats[];

  const subValue = formatToThreeDecimals(totalOrderStats?.totalCount);
  const headerValue = formatToThreeDecimals(totalOrderStats?.totalValue);
  return (
    <StyledDiv>
      <SpotlightAreaCard
        cardTitle={t('common:TXT_Total_Order_Value')}
        subValue={subValue}
        headerValue={headerValue}
        prefix={totalOrderStats?.currency || ''}
        id="currentDate"
        context={t('common:TXT_Orders')}
        loading={orderStatsApi.loading}
        variant={'primary'}
        captionString={captionString}
        selectedDate={selectedDate}
        onDatePickerChange={(newValue: any) => {
          const dValue = newValue?.$d || null;
          if (dValue) {
            const payloadDateString = dayjs(dValue).format(dtFormat);
            if (payloadDateString === todaysDate) {
              setCaptionString(t('common:TXT_Today'));
            } else {
              setCaptionString(dayjs(dValue).format(clientdtFormat));
            }
            getTotalOrderStats(payloadDateString);
          }
          setSelectedDate(newValue);
        }}
      />

      <SpotlightAreaCard
        cardTitle={t('common:TXT_Top_3_items')}
        variant={'secondary'}
        contentList={topItemStats}
        selectedMonthYear={selectedMonthYearForItem}
        id="itemDateMonth"
        loading={topItemStatsApi.loading}
        onMonthAndYearChange={(newValue: any) => {
          const dValue = newValue?.$d || null;
          if (dValue) {
            const dayjsDate = dayjs(dValue);
            const month = dayjsDate.format('MM');
            const year = dayjsDate.format('YYYY');
            getTopListedItems(month, year);
          }
          setSelectedMonthYearForItem(newValue);
        }}
        format="MMM YYYY"
      />
      <SpotlightAreaCard
        cardTitle={t('common:TXT_Top_3_customers')}
        variant={'secondary'}
        contentList={topCustomerStats}
        selectedMonthYear={selectedMonthYearForCustomer}
        id="customerDateMonth"
        loading={topCustomerStatsApi.loading}
        onMonthAndYearChange={(newValue: any) => {
          const dValue = newValue?.$d || null;
          if (dValue) {
            const dayjsDate = dayjs(dValue);
            const month = dayjsDate.format('MM');
            const year = dayjsDate.format('YYYY');
            getTopListedCustomers(month, year);
          }
          setSelectedMonthYearForCustomer(newValue);
        }}
        format="MMM YYYY"
      />
    </StyledDiv>
  );
};

import { Accordion, Checkbox, DatePicker, Text, useIptorTranslation, FormControlLabel, TextField } from '@iptor/base';
import {
  HandlerDropdown,
  WarehouseDropdown,
  AvailCheckAtOrderEntriesDropdown,
  OrderFulfillmentRuleDropdown,
  SignaturesDropdown,
  LiveSearchSuppliers,
} from '@iptor/business';
import { Grid2 as Grid, styled } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Order } from '../../../constants';
import { formatDate, formatDateToPayloadFormat } from '../../../utils/formatDate';

type ReferenceSourcingBacklogProps = {
  createProcess?: boolean;
  register?: UseFormRegister<Partial<Order>>;
  setValue?: UseFormSetValue<Partial<Order>>;
  getValues?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
};

const ReferenceSourcingBacklog = ({
  control,
  register,
  setValue,
  getValues,
  errors,
  createProcess,
}: ReferenceSourcingBacklogProps) => {
  const CustomFormControlLabel = styled(FormControlLabel)``;
  const { t } = useIptorTranslation();

  return (
    <Accordion
      headingTitle={t('common:TXT_Reference_Sourcing_Backlog')}
      moreInfo={
        <>
          <Grid container columnSpacing={3} rowSpacing={2} alignItems="flex-end">
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', marginLeft: 0 }}
                control={
                  <Text id="route" fontSize={14} color="textDisabled">
                    {getValues?.('route') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Route')}</Text>}
                labelPlacement="top"
                readOnly={!createProcess}
              />
            </Grid>
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Text id="departure" fontSize={14} color="textDisabled">
                    {getValues?.('departure') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Departure')}</Text>}
                labelPlacement="top"
                readOnly={!createProcess}
              />
            </Grid>
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Text id="destination" fontSize={14} color="textDisabled">
                    {getValues?.('destination') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Destination')}</Text>}
                labelPlacement="top"
                readOnly={!createProcess}
              />
            </Grid>
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Text id="shippingAgent" fontSize={14} color="textDisabled">
                    {getValues?.('shippingAgent') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Ship_Agent')}</Text>}
                labelPlacement="top"
                readOnly={!createProcess}
              />
            </Grid>
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', marginLeft: 0 }}
                control={
                  <Text id="defaultBtBsupplier" fontSize={14} color="textDisabled">
                    {getValues?.('defaultBtBsupplier') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Default_BTB_Supply')}</Text>}
                labelPlacement="top"
              />
            </Grid>
            <Grid size={3}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Text id="totalWeight" fontSize={14} color="textDisabled">
                    {getValues?.('totalWeight') || ' '}
                  </Text>
                }
                label={<Text fontWeight={600}>{t('common:TXT_Total_Weight')}</Text>}
                labelPlacement="top"
                readOnly={!createProcess}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} rowSpacing={2} alignItems="flex-end">
            <Grid size={3}>
              <Controller
                name="shipmentPreparation"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox id={field.name} {...field} disabled />}
                    id={field.name}
                    label={t('common:TXT_Ship_Prep')}
                    readOnly={!createProcess}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="transportNoteWithAmounts"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={<Checkbox id={field.name} {...field} disabled />}
                    id={field.name}
                    label={t('common:TXT_Trpt_Amount')}
                    readOnly={!createProcess}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="pickConsolidation"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={<Checkbox id={field.name} {...field} disabled />}
                    id={field.name}
                    label={t('common:TXT_Pick_Cons')}
                    readOnly={!createProcess}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="returnCollectionRequired"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={<Checkbox id={field.name} {...field} disabled />}
                    id={field.name}
                    label={t('common:TXT_Collect_Req')}
                    readOnly={!createProcess}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    >
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid size={12}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Reference_Info')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <DatePicker
              label={t('common:TXT_Order_Date')}
              value={formatDate(getValues?.('orderDate'))}
              onChange={(value) => setValue('orderDate', formatDateToPayloadFormat(value))}
              readOnly={!createProcess}
              slotProps={{
                textField: {
                  id: 'orderDate',
                },
              }}
            />
            <input {...register('orderDate')} type="hidden" name="orderDate" />
          </Grid>
          <Grid size={12 / 2}>
            <DatePicker
              label={t('common:TXT_Request_Delivery_Date')}
              value={formatDate(getValues?.('dispatchTime'))}
              onChange={(value) => setValue('dispatchTime', formatDateToPayloadFormat(value))}
              readOnly={!createProcess}
              slotProps={{
                textField: {
                  id: 'dispatchTime',
                },
              }}
            />
            <input {...register('dispatchTime')} type="hidden" name="dispatchTime" />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name={'handler'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <HandlerDropdown
                  onChange={(value) => field.onChange(value)}
                  id="handler"
                  value={field.value}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Handler')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="salesman"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <SignaturesDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  id="salesman"
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Salesman')}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="customerReference"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Reference')}
                  id="customerReference"
                  fullWidth
                  error={!!errors?.customerReference}
                  helperText={errors?.customerReference?.message}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="orderReference"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Your_Order')}
                  id="orderReference"
                  fullWidth
                  error={!!errors?.orderReference}
                  helperText={errors?.orderReference?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name={'supplier'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <LiveSearchSuppliers
                  error={!!error}
                  onLiveSearchInputChange={(_, value) => {
                    field.onChange(value);
                  }}
                  liveSearchInputValue={field.value || ''}
                  id="supplier"
                  autoFocus
                  fullWidth
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Supplier')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="supplierOrderReference"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Supplier_Order_Reference')}
                  fullWidth
                  id="supplierOrderReference"
                  error={!!errors?.supplierOrderReference}
                  helperText={errors?.supplierOrderReference?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid size={12} sx={{ marginTop: 3 }}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Sourcing')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name={'orderFulfilmentRule'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <OrderFulfillmentRuleDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  id="orderFulfilmentRule"
                  fullWidth
                  label={t('common:TXT_Order_Fulfillment_Rule')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name={'availCheckAtOrderEntry'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AvailCheckAtOrderEntriesDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  color={error ? 'error' : 'primary'}
                  id="availCheckAtOrderEntry"
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Avail_Check_At_Order_Entry')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="warehouse"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <WarehouseDropdown
                  color={error ? 'error' : 'primary'}
                  label={t('common:TXT_Warehouse')}
                  id="warehouse"
                  helperText={error?.message}
                  value={field.value}
                  onChange={(newValue) => {
                    const selectedWarehouse = newValue || '';
                    field.onChange(selectedWarehouse);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="autoSource"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Auto_Source')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="autoFulfilment"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Auto_Fulfill')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="orderFulfilmentRule"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Order_Fulfill')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="completeDelivery"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Complete_Delivery')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="availCheckAtPicklistPrint"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Avail_Chk_PP')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="defaultWarehouse"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Default_WHS')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="firmAllocateOrder"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Firm_Alloc')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="backlog"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Backlog')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="dispatchDateAdjustment"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Date_Adjust')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="backlogAfterPickListConf"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  id={field.name}
                  label={t('common:TXT_Blog_At_Pick')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default ReferenceSourcingBacklog;

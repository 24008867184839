import React from 'react';
import { IconButton, Iframe } from '@iptor/base';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

function ContextHelp() {
  //get path from MemoryRouter and set it as current path
  const location = useLocation();
  const path = location.pathname;

  const getHelpLink = () => {
    if (/^\/orders\/[^/]+\/header$/.test(path)) {
      return 'https://help.iptor.com/help/dis/dir301d/?simple';
    }

    if (/^\/orders\/[^/]+\/items$/.test(path)) {
      return 'https://help.iptor.com/help/dis/dmr302d/?simple';
    }

    if (/^\/items\/[^/]+\/[^/]+\/info$/.test(path)) {
      return 'https://help.iptor.com/help/dis/dmr307d/?simple';
    }

    if (path === '/') {
      return 'https://help.iptor.com/help/dis/dmr300d/?simple';
    }

    return 'https://help.iptor.com/help/dis/dmr300d/?simple';
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '700px',
        height: '100%',
        position: 'relative',
      }}
    >
      <IconButton
        icon="linkSquare01"
        size="small"
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1000,
        }}
        onClick={() => window.open(getHelpLink(), '_blank')}
      />
      <Iframe src={getHelpLink()} title="Help Documentation" width="100%" height="800px" />
    </Box>
  );
}

export default ContextHelp;

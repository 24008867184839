import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  getError,
  OpenAPIId,
  OpenAPIProviderContext,
  OperationConfig,
  OperationData,
  OperationId,
  OperationParameters,
  OperationResponse,
} from './manager';

// Helpers
// =======
export type ExecuteOperationMethodResult<R> = { OK: true; response: R } | { OK: false; reason: 'ignored' | 'error' };
type PromiseWrapper<R> = {
  promise: Promise<ExecuteOperationMethodResult<R>>;
  resolve: (response: R) => void;
  reject: (reason: 'ignored' | 'error') => void;
  pending: boolean;
};
function createPromiseWrapper<R>() {
  let resolve: (response: R) => void;
  let reject: (reason: 'ignored' | 'error') => void;
  let pending = true;

  const promise = new Promise<ExecuteOperationMethodResult<R>>((innerResolve) => {
    resolve = (response) => {
      pending = false;
      innerResolve({ OK: true, response });
    };
    reject = (reason) => {
      pending = false;
      innerResolve({ OK: false, reason });
    };
  });

  return { promise, resolve, reject, pending };
}

type MyExecuteOperationMethod<T extends OpenAPIId, K extends OperationId<T>> = (
  ignoreResponse: { yes: boolean },
  promiseWrapper: PromiseWrapper<OperationResponse<T, K>>,
  params?: OperationParameters<T, K>,
  data?: OperationData<T, K>,
  config?: OperationConfig<T, K>,
) => void;

// useOpenAPIMethod
// ================
type OperationMethodArguments<T extends OpenAPIId, K extends OperationId<T>> = {
  params?: OperationParameters<T, K>;
  data?: OperationData<T, K>;
  config?: OperationConfig<T, K>;
};

type OperationMethodRequestInfo<T extends OpenAPIId, K extends OperationId<T>> = OperationMethodArguments<T, K> & {
  promiseWrapper: PromiseWrapper<OperationResponse<T, K>>;
};

export type ExecuteOperationMethod<T extends OpenAPIId, K extends OperationId<T>> = (
  params?: OperationParameters<T, K>,
  data?: OperationData<T, K>,
  config?: OperationConfig<T, K>,
) => Promise<ExecuteOperationMethodResult<OperationResponse<T, K>>>;

type OpenAPIMethodState<T extends OpenAPIId, K extends OperationId<T>> = {
  loading: boolean;
  response?: OperationResponse<T, K>;
  error?: Error;
};

export function useOpenAPIMethod<T extends OpenAPIId, K extends OperationId<T>>(openAPIId: T, operationId: K) {
  const { getClientContext } = useContext(OpenAPIProviderContext);
  if (!getClientContext) throw new Error('Programming error: OpenAPIProvider should be included in the component tree');

  const clientContext = useMemo(() => getClientContext(openAPIId), [openAPIId, getClientContext]);

  const [state, setState] = useState<OpenAPIMethodState<T, K>>({ loading: false });
  const [operationMethodRequestInfo, setOperationMethodRequestInfo] = useState<
    OperationMethodRequestInfo<T, K> | undefined
  >(undefined);

  const myActualExecute: MyExecuteOperationMethod<T, K> = useCallback(
    async (ignoreResponse, promiseWrapper, params, data, config) => {
      try {
        setState((state) => ({ ...state, loading: true }));
        const client = await clientContext.openAPIClientPromise;
        if (ignoreResponse.yes) {
          if (promiseWrapper.pending) promiseWrapper.reject('ignored');
          return;
        }

        try {
          clientContext.onOperationMethodRequest(operationId.toString());
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const response = (await (client as any)[operationId](params, data, config)) as OperationResponse<T, K>;

          if (ignoreResponse.yes) {
            if (promiseWrapper.pending) promiseWrapper.reject('ignored');
            return;
          }
          setState((state) => ({ ...state, loading: false, error: undefined, response: response }));
          if (promiseWrapper.pending) promiseWrapper.resolve(response);
        } catch (e: unknown) {
          if (ignoreResponse.yes) {
            if (promiseWrapper.pending) promiseWrapper.reject('ignored');
            return;
          }
          setState((state) => ({ ...state, loading: false, error: getError(e), response: undefined }));
          if (promiseWrapper.pending) promiseWrapper.reject('error');
        } finally {
          clientContext.onOperationMethodResponse(operationId.toString());
          setState((state) => (state.loading ? { ...state, loading: false } : state));
        }
      } catch (e: unknown) {
        if (ignoreResponse.yes) {
          if (promiseWrapper.pending) promiseWrapper.reject('ignored');
          return;
        }
        const error = new Error(`Error getting API client (${getError(e)})`);
        setState((state) => ({ ...state, loading: false, error: error, response: undefined }));
        if (promiseWrapper.pending) promiseWrapper.reject('error');
      } finally {
        setState((state) => (state.loading ? { ...state, loading: false } : state));
      }
    },
    [clientContext, setState, operationId],
  );

  const execute: ExecuteOperationMethod<T, K> = useCallback(
    async (params, data, config) => {
      const promiseWrapper = createPromiseWrapper<OperationResponse<T, K>>();
      setOperationMethodRequestInfo({ params, data, config, promiseWrapper });
      return await promiseWrapper.promise;
    },
    [setOperationMethodRequestInfo],
  );

  useEffect(() => {
    if (operationMethodRequestInfo) {
      // Avoid API is executed without parameters (first cycle)
      const ignoreResponse = { yes: false };
      myActualExecute(
        ignoreResponse,
        operationMethodRequestInfo.promiseWrapper,
        operationMethodRequestInfo.params,
        operationMethodRequestInfo.data,
        operationMethodRequestInfo.config,
      );
      return () => {
        ignoreResponse.yes = true; // Remark: no longer interested in result with previous parameters
      };
    }
  }, [operationMethodRequestInfo, myActualExecute]);

  return { execute, response: state.response, error: state.error, loading: state.loading };
}

// useOpenAPIData
// ==============
export type RefreshOperationMethod<T extends OpenAPIId, K extends OperationId<T>> = () => Promise<
  ExecuteOperationMethodResult<OperationResponse<T, K>>
>;

export function useOpenAPIData<T extends OpenAPIId, K extends OperationId<T>>(
  openAPIId: T,
  operationId: K,
  parameters?: OperationParameters<T, K>,
  requestData?: OperationData<T, K>,
  config?: OperationConfig<T, K>,
) {
  const { execute, response, error, loading } = useOpenAPIMethod(openAPIId, operationId);
  const operationArgs = useRef<OperationMethodArguments<T, K>>({
    /*
    REMARK: users will call this hook on each render cycle as: useOpenAPIData('...', '...', { offset: 20, limit: 20 });
    BE AWARE that the use of curly brackets results in a new parameter instance for each render cycle !! So save
    this parameters once and keep using the first instance (additionally, we don't want to invoke extra rerendering 
    here, so useRef instead of useState))
  */
    params: parameters,
    data: requestData,
    config: config,
  });

  const refresh: RefreshOperationMethod<T, K> = useCallback(async () => {
    return await execute(operationArgs.current.params, operationArgs.current.data, operationArgs.current.config);
  }, [execute]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { refresh, response, error, loading };
}

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#0076BB" stroke="none" />
    <g clip-path="url(#clip0_10064_244796)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        stroke="none"
        d="M13.9956 7.50475C14.6626 7.11625 15.4996 7.59675 15.4996 8.36875V9.99825C15.6966 9.99825 15.8916 10.037 16.0736 10.1124C16.2556 10.1878 16.421 10.2983 16.5603 10.4376C16.6995 10.5769 16.81 10.7422 16.8854 10.9242C16.9608 11.1062 16.9996 11.3013 16.9996 11.4983C16.9996 11.6952 16.9608 11.8903 16.8854 12.0723C16.81 12.2543 16.6995 12.4196 16.5603 12.5589C16.421 12.6982 16.2556 12.8087 16.0736 12.8841C15.8916 12.9595 15.6966 12.9983 15.4996 12.9983V14.4983C15.4996 15.3222 14.5591 15.7928 13.8996 15.2983L12.8696 14.5253C12.319 14.1124 11.678 13.8368 10.9996 13.7213V15.1433C10.9997 15.4701 10.8815 15.786 10.667 16.0326C10.4526 16.2793 10.1561 16.4401 9.83241 16.4854C9.50869 16.5307 9.17951 16.4575 8.90554 16.2792C8.63157 16.1009 8.43128 15.8296 8.3416 15.5153L7.5566 12.7673C7.27411 12.4336 7.09027 12.0279 7.02574 11.5955C6.96122 11.1632 7.01857 10.7214 7.19134 10.3198C7.36412 9.91827 7.64547 9.57287 8.00377 9.32245C8.36208 9.07203 8.78313 8.92651 9.2196 8.90225L10.7286 8.81825C11.4669 8.77716 12.1849 8.56142 12.8236 8.18875L13.9956 7.50475ZM8.8166 13.5373L9.3031 15.2408C9.32651 15.3232 9.37897 15.3944 9.45079 15.4412C9.5226 15.488 9.60892 15.5072 9.69381 15.4954C9.7787 15.4835 9.85643 15.4413 9.91263 15.3766C9.96884 15.3118 9.99973 15.229 9.9996 15.1433V13.6382L9.2196 13.5947C9.08386 13.5872 8.94903 13.568 8.8166 13.5373ZM15.9996 11.4983C15.9996 11.3656 15.9469 11.2385 15.8532 11.1447C15.7594 11.0509 15.6322 10.9982 15.4996 10.9982V11.9983C15.6322 11.9983 15.7594 11.9456 15.8532 11.8518C15.9469 11.758 15.9996 11.6309 15.9996 11.4983Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10064_244796">
        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
  CardProps,
  TypographyProps,
} from '@mui/material';
import { IconButton } from '../IconButton';

export type DataCardProps = {
  title: string;
  subTitle: string;
  firstVal: string | number;
  secondVal: string | number;
  thirdVal: string | number;
  onClose?: () => void;
  onEdit?: () => void;
  error?: boolean;
};

type StyledCardProps = CardProps & { error?: boolean };

type StyledTypographyProps = TypographyProps & { txtVal: string | number };

const StyledCard = styled(Card)<StyledCardProps>(({ theme, error }) => ({
  minWidth: '138px',
  maxWidth: '138px',
  height: '135px',
  outline: error ? `1px solid ${theme.palette.error.main}` : 'none',
  borderRadius: theme.spacing(1.25),
  backgroundColor: '#FFF6ED',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  opacity: '60%',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  textAlign: 'left',
  color: theme.palette.secondary['500'],
}));
const CustomerTypography = styled(Typography)<StyledTypographyProps>(({ txtVal }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'black',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textWrap: 'nowrap',
  whiteSpace: txtVal === ' ' ? 'pre-wrap' : 'nowrap',
}));
const CustomerIdTypography = styled(Typography)<StyledTypographyProps>(({ txtVal }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#525252',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textWrap: 'nowrap',
  whiteSpace: txtVal === ' ' ? 'pre-wrap' : 'nowrap',
}));
const OrderIdTypography = styled(Typography)<StyledTypographyProps>(({ theme, txtVal }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  color: theme.palette.foreground['600'],
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textWrap: 'nowrap',
  whiteSpace: txtVal === ' ' ? 'pre-wrap' : 'nowrap',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1.125),
  border: '0.5px solid #000000',
  opacity: '10%',
}));
export const DataCard = ({ title, subTitle, firstVal, secondVal, thirdVal, onClose, error, onEdit }: DataCardProps) => {
  const theme = useTheme();
  return (
    <StyledCard error={error} elevation={0} sx={{ position: 'relative' }}>
      {onClose && (
        <IconButton
          onClick={onClose}
          size="small"
          icon="cancel01"
          variant="text"
          sx={(theme) => ({
            color: theme.palette.foreground[500],
            position: 'absolute',
            top: '0',
            right: '0',
            display: 'flex',
            zIndex: 1,
          })}
        />
      )}
      <CardContent sx={{ position: 'relative', maxHeight: '100%' }}>
        <Stack>
          <StyledTypography variant="body1">{title}</StyledTypography>
          <CustomerTypography txtVal={firstVal} variant="body2">
            {firstVal}
          </CustomerTypography>
          <CustomerIdTypography txtVal={secondVal} variant="body2">
            {secondVal}
          </CustomerIdTypography>
          <StyledDivider variant="fullWidth" />
          <StyledTypography variant="body1" sx={{ marginTop: theme.spacing(1.25) }}>
            {subTitle}
          </StyledTypography>
          <OrderIdTypography txtVal={thirdVal} variant="body2">
            {thirdVal}
          </OrderIdTypography>
        </Stack>
      </CardContent>
      {onEdit && (
        <IconButton
          onClick={onEdit}
          size="small"
          icon="edit"
          variant="text"
          sx={(theme) => ({
            color: theme.palette.foreground[500],
            position: 'absolute',
            bottom: '0',
            right: '0',
            display: 'flex',
            zIndex: 1,
          })}
        />
      )}
    </StyledCard>
  );
};

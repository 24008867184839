import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { Button, Icon, IconButton, TextField, useIptorTranslation } from '@iptor/base';
import { OperationResponseItems } from '@iptor/business';
import { useState } from 'react';
import { handleApiTextMessageToNormal } from './AddEditTextView';

interface Props {
  loading: boolean;
  textList: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>;
  textToEdit: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0];
  setTextToEdit: (text: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0]) => void;
  setIsEditView: (isEditView: boolean) => void;
  handleClose: () => void;
  setTextToDelete: (
    text: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0],
  ) => void;
}

function MultipleTextView(props: Props) {
  const { loading, handleClose, setTextToEdit, textList, setIsEditView, setTextToDelete, textToEdit } = props;
  const { t } = useIptorTranslation();
  const [openedIndex, setOpenedIndex] = useState<number>(0);

  return (
    <>
      <DialogTitle display="flex" alignItems="center" gap={1}>
        <Icon icon="informationCircle" color="primary" size="L" /> <b>{t('sales_orders:ORDERTEXT_title')}</b>
        <Icon
          icon="cancel01"
          onClick={handleClose}
          style={{ marginLeft: 'auto' }}
          sx={(theme) => ({
            '&:hover': {
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          })}
        />
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent sx={(theme) => ({ padding: theme.spacing(2), maxHeight: '500px' })}>
        {loading || !textList || textToEdit ? (
          <Skeleton variant="rounded" height={100} />
        ) : textList && textList.length > 0 ? (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {textList.map((text, index) => (
              <Paper
                elevation={3}
                onClick={() => {
                  setOpenedIndex(openedIndex === index ? -1 : index);
                }}
                sx={(theme) => ({
                  padding: '16px',
                  gap: theme.spacing(1),
                  backgroundColor: theme.palette.background.default,
                  display: 'flex',
                  flexDirection: 'column',
                })}
              >
                <Box display="flex" alignItems="center" sx={(theme) => ({ color: theme.palette.text.primary })}>
                  <Typography
                    data-iscapture="true"
                    data-for="global"
                    data-tip="version"
                    color="primary"
                    gutterBottom
                    display="flex"
                    fontWeight={600}
                    fontSize={14}
                  >
                    <Box> {`${t('sales_orders:ORDERTEXT_textSet')}: ${text.version}`}</Box>
                  </Typography>
                  <IconButton
                    size="small"
                    icon="edit"
                    variant="text"
                    onClick={() => {
                      setTextToEdit(text);
                      setIsEditView(true);
                    }}
                    style={{ marginRight: '8px', marginLeft: 'auto' }}
                  />
                  <IconButton
                    size="small"
                    icon="delete3"
                    variant="text"
                    color="error"
                    onClick={() => setTextToDelete(text)}
                  />
                </Box>
                <Typography gutterBottom fontWeight={600} fontSize={14}>
                  {t('sales_orders:ORDERTEXT_language')}: {text.language}
                </Typography>

                <Collapse
                  // key={index}
                  in={openedIndex === index}
                  collapsedSize={72}
                  // sx={{
                  //   marginBottom: 2,
                  //   overflow: 'visible',
                  // }}
                  timeout="auto"
                  // unmountOnExit
                >
                  {' '}
                  <TextField
                    multiline
                    id="longText"
                    fullWidth
                    sx={{
                      minHeight: '72px',
                      '& .MuiInputBase-root': {
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: '14px 0px',
                        pointerEvents: 'none',
                      },
                    }}
                    slotProps={{
                      input: {
                        sx: {
                          backgroundColor: 'FFFFFF',
                          border: 'none',
                          '& .MuiInputBase-input': {
                            // only one line and elipsis in case of not opened index !== index
                            display: openedIndex !== index ? '-webkit-box' : 'block',
                            WebkitLineClamp: openedIndex !== index ? 2 : 'none',
                            WebkitBoxOrient: openedIndex !== index ? 'vertical' : 'none',
                            fontFamily: 'monospace', // Ensures equal width for all characters
                            whiteSpace: 'normal',
                            wordBreak: 'break-all',
                            fontSize: '16px',
                            height: 'auto !important',
                            overflowWrap: 'anywhere',
                            minWidth: 'calc(70ch + 10px)', // Ensures text fits in the box with scrollbar with extra 2 px
                            maxWidth: 'calc(70ch + 10px)', // Ensures text fits in the box with scrollbar with extra 2 px
                            '::-webkit-scrollbar': {
                              width: '8px', // specify webkit scrollbar size
                            },
                            scrollbarGutter: 'stable',
                            fieldSizing: 'content',
                          },
                        },
                      },
                    }}
                    value={handleApiTextMessageToNormal(text.longText)}
                  />
                  <Box gap={1} flexWrap={'wrap'} alignItems={'center'} sx={{ display: 'flex' }}>
                    <Typography fontWeight={600} fontSize={14}>
                      {t('sales_orders:ORDERTEXT_printedPlaces')}:
                    </Typography>
                    {text.documents
                      .filter((document) => document.active)
                      .map((document, index: number) => (
                        <Box
                          key={index}
                          id="description"
                          data-iscapture="true"
                          data-for="global"
                          data-tip="description"
                          sx={(theme) => ({
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            paddingX: theme.spacing(0.5),
                            paddingY: 0,
                            borderRadius: theme.spacing(0.5),
                            fontSize: theme.typography.body2.fontSize,
                          })}
                        >
                          {document.description}
                        </Box>
                      ))}
                  </Box>
                </Collapse>
              </Paper>
            ))}
          </Box>
        ) : (
          <Box>
            <b>{t('sales_orders:ORDERTEXT_noTextFound')}</b>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={(theme) => ({ justifyContent: 'flex-start', paddingX: theme.spacing(1.5) })}>
        <Button
          variant="outlined"
          sx={{
            marginLeft: 'auto',
          }}
          id="close"
          onClick={handleClose}
        >
          {t('common:TXT_Close')}
        </Button>
        <Button
          variant="contained"
          iconLeft="plusSign"
          id="add"
          onClick={() => {
            setTextToEdit(null);
            setIsEditView(true);
          }}
        >
          {t('common:ROLE_BUTTON_Add')}
        </Button>
      </DialogActions>
    </>
  );
}

export default MultipleTextView;

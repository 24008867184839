/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, DataCardList, DataCardProps, DialogLayout, Theme, useIptorTranslation, useTheme } from '@iptor/base';
import {
  openAPIManager,
  OperationResponse,
  OperationResponseDataData,
  OperationResponseItemsRow,
  useOpenAPIMethod,
} from '@iptor/business';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import { PrintParameters } from './components/PrintParameters';

export type PrintConfirmationDialogWrapperProps = Omit<PrintConfirmationDialogProps, 'title' | 'documentType'>;

export type PrintConfirmationDialogProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  selectedRows: OperationResponseItemsRow<'internal.v1.base-orders', 'GET /salesOrders'>[];
  documentType: 'orderConfirmation' | 'picklist' | 'proFormaInvoice' | 'transportNote' | 'invoice';
  onSuccess: () => void;
};

export const PrintConfirmationDialog = ({
  open,
  handleClose,
  title,
  selectedRows,
  documentType,
  onSuccess,
}: PrintConfirmationDialogProps) => {
  const [loading, setLoading] = useState(false);
  const { notify } = useOrderNotifications();
  const theme: Theme = useTheme();

  const { t } = useIptorTranslation();

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: { email: '', numberOfCopies: 1, printerQueue: null, checked: false },
    mode: 'onSubmit',
  });

  const printConfirmationMessages = (
    variant: 'success' | 'error',
    documentType: PrintConfirmationDialogProps['documentType'],
    length: number,
  ) => {
    let title = '';
    switch (documentType) {
      case 'orderConfirmation':
        title =
          variant === 'success'
            ? t('sales_orders:PRINT_orderConfirmationSuccess', '', { length: length })
            : t('sales_orders:PRINT_orderConfirmationOrderStatusError', '', { length: length });
        break;
      case 'invoice':
        title =
          variant === 'success'
            ? t('sales_orders:PRINT_invoicingSuccess', '', { length: length })
            : t('sales_orders:PRINT_invoicingOrderStatusError', '', { length: length });
        break;
      case 'proFormaInvoice':
        title =
          variant === 'success'
            ? t('sales_orders:PRINT_proformaInvoiceSuccess', '', { length: length })
            : t('sales_orders:PRINT_proformaInvoiceOrderStatusError', '', { length: length });
        break;
      case 'picklist':
        title =
          variant === 'success'
            ? t('sales_orders:PRINT_pickListConfirmationSuccess', '', { length: length })
            : t('sales_orders:PRINT_pickListConfirmationOrderStatusError', '', { length: length });
        break;
    }
    return title;
  };

  const validateOrder = (orderStatus: number, documentType: PrintConfirmationDialogProps['documentType']) => {
    if (orderStatus < 10) return false;
    switch (documentType) {
      case 'orderConfirmation':
        return true;
      case 'picklist':
        return orderStatus === 20;
      case 'invoice':
        return orderStatus === 45;
      case 'proFormaInvoice':
        return orderStatus >= 10;
    }
  };

  const [selectedOrders, setSelectedOrders] = useState<DataCardProps[]>(
    selectedRows.map((row) => {
      const isValid = validateOrder(row.orderStatus, documentType);
      return {
        title: t('common:TXT_Customer'),
        subTitle: t('common:TXT_OrderNumber'),
        firstVal: row.customerReference || ' ',
        secondVal: row.customer,
        thirdVal: row.order,
        error: !isValid,
        onClose: () => {
          setSelectedOrders((prev) => {
            if (prev.length === 1) {
              handleClose();
            }
            return prev.filter((prevOrder) => prevOrder.thirdVal !== row.order);
          });
        },
      };
    }),
  );

  const notValidForInvoice = useMemo(() => {
    return selectedOrders.filter((order) => order.error === true);
  }, [selectedOrders]);

  const SubText = useCallback(() => {
    if (notValidForInvoice.length > 0) {
      const errorMessage = printConfirmationMessages('error', documentType, notValidForInvoice.length);
      return (
        <>
          {errorMessage} <b>{t('sales_orders:PRINT_removeList')}</b>
        </>
      );
    }
  }, [documentType, notValidForInvoice]);

  const numberOfCopies = watch('numberOfCopies');
  const printerQueue = watch('printerQueue');
  const checked = watch('checked');
  const email = watch('email');

  const printerQueueOptionsApi = useOpenAPIMethod('internal.v1.base-general', 'GET /printers');
  const getPrinterQueueOptions = () => printerQueueOptionsApi.execute();

  useEffect(() => {
    getPrinterQueueOptions();
  }, []);

  useEffect(() => {
    if (printerQueueOptionsApi.error) {
      console.log('Printer Queue Api Error:', printerQueueOptionsApi.error);
    }
  }, [printerQueueOptionsApi.error, numberOfCopies]);

  const onConfirmButtonClick = handleSubmit(async () => {
    try {
      setLoading(true);
      const allApiCalls = selectedOrders.map((card: DataCardProps) =>
        openAPIManager.execute(
          'internal.v1.base-orders',
          'POST /salesOrders/{order}/print',
          { order: Number(card.thirdVal) },
          {
            documentType,
            holdBeforePrint: checked,
            numberOfCopies,
            printerQueue: printerQueue?.value,
            addressOfRecipient: email,
          },
        ),
      );
      const results: PromiseSettledResult<
        OperationResponse<'internal.v1.base-orders', 'POST /salesOrders/{order}/print'>
      >[] = await Promise.allSettled(allApiCalls);
      const success = results.filter((result) => result.status === 'fulfilled') as PromiseFulfilledResult<
        OperationResponse<'internal.v1.base-orders', 'POST /salesOrders/{order}/print'>
      >[];
      const failed = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[];
      if (success.length > 0) {
        notify('success', printConfirmationMessages('success', documentType, success.length), true);
      }
      if (failed.length > 0) {
        failed.map((error) => {
          const errorReason = error.reason as any;
          if (errorReason?.response?.data?.messages?.length > 0) {
            notify('error', errorReason.response.data.messages);
          } else if (errorReason?.response?.data?.error) {
            const { code, message } = errorReason.response.data.error;
            notify('error', `${code} ${message}`);
          } else {
            notify('error', 'An unknown error occurred', true);
          }
        });
      }
      setLoading(false);
      handleClose();
      onSuccess();
    } catch (e: any) {
      setLoading(false);
      handleClose();
      console.log('Error In Confirm API:', e);
      notify('error', `Error In Confirm API: ${e.message}`, true);
      onSuccess();
    }
  });

  const printerQueueComboFormat = useMemo(() => {
    const options = (printerQueueOptionsApi.response?.data?.data || []) as OperationResponseDataData<
      'internal.v1.base-general',
      'GET /printers'
    >;
    return options?.items?.map((queue) => {
      if (queue.defaultPrinter) {
        setValue('printerQueue', {
          value: queue.printer,
          label: `${queue.printer} - ${queue.description} - ${queue.type}`,
          type: queue.type,
        });
      }
      return {
        value: queue.printer,
        label: `${queue.printer} - ${queue.description} - ${queue.type}`,
        type: queue.type,
      };
    });
  }, [printerQueueOptionsApi.response]);

  return (
    <DialogLayout
      slotProps={{
        paper: { sx: { backgroundColor: theme.palette.background.paper, overflow: 'auto!important' } },
      }}
      actions={[
        <Button
          key="close"
          variant="outlined"
          size="medium"
          id="close"
          onClick={() => {
            handleClose();
          }}
        >
          {t('common:TXT_Close')}
        </Button>,
        <Button
          disabled={
            !printerQueue ||
            numberOfCopies < 1 ||
            !numberOfCopies ||
            (printerQueue.type === '2' && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ||
            !!notValidForInvoice.length
          }
          key="confirm"
          id="confirm"
          loading={loading}
          variant="contained"
          size="medium"
          onClick={onConfirmButtonClick}
        >
          {t('common:TXT_Print')}
        </Button>,
      ]}
      fullScreen
      open={open}
      onClose={() => {
        handleClose();
      }}
      title={`${title} (${selectedOrders.length})`}
      fixedActionBar
    >
      <DataCardList options={selectedOrders} />
      <SubText />
      <PrintParameters
        watch={watch}
        control={control}
        printQueueOptions={printerQueueComboFormat}
        comboLoading={printerQueueOptionsApi.loading}
      />
    </DialogLayout>
  );
};

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Manner of transport', 'Description'];

export const PurchaseMannerOfTransportDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: mannerOfTransportOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /mannersOfTransport',
    labelAttribute: 'description',
    valueAttribute: 'mannerOfTransport',
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} loading={loading} options={mannerOfTransportOptions} columnNames={COLUMN_NAMES} />;
};

import icons from '../resources/svgs/index';

// Automatically generate a union type based on the keys of the icons object
export type IconType = keyof typeof icons; // This creates a union type like "plusIcon" | "minusIcon" | "searchIcon" ...

// Generate an array of icon names (as strings)
export const iconNamesArray: IconType[] = Object.keys(icons) as IconType[];

// Export both the union type and the array of icon names
export default IconType;

import React, { useEffect, useState } from 'react';
import './index.scss';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Widget } from '../../../../views/Dashboard';
import { Localization } from '../../../../framework/localization/Localization';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionType,
  InteractionStatus,
  BrowserAuthError
} from '@azure/msal-browser';
import { LoginButton } from '../LoginButton/index';
import { loginRequest } from '../config';
import { CellPlugin } from '@react-page/editor';

const msAxios = axios.create({
  transformRequest: (data, headers) => {
    delete headers.common;
  }
});

const toDoList: CellPlugin = {
  Renderer: ({ data }: any) => {
    const [graphData, setGraphData] = useState<any>({ items: [] });
    const [checkedState, setCheckedState] = useState<any>([]);
    const [tkn, setTkn] = useState<string>('');
    const [task, setTask] = useState<any>({});
    const [show, setShow] = useState<boolean>(true);
    const { instance, accounts, inProgress } = useMsal();
    const [showError, setShowError] = useState<boolean>(false);
    const [url, setUrl] = useState<any>();

    useEffect(() => {
      const getEndpoint = async () => {
        await axios
          .get('/msgraphEndpoint')
          .then((res) => {
            setUrl(res?.data || {});
          })
          .catch((err) => {
            console.error(`Error from msgraphEndpoint toDoList ${err}`);
          });
      };
      getEndpoint();
    }, []);

    useEffect(() => {
      if (inProgress === InteractionStatus.None && url !== undefined) {
        if (graphData.items.length !== 0) {
          return;
        }
        const request = {
          ...loginRequest,
          account: accounts[0]
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            getTask(response.accessToken);
          })
          .catch((error) => {
            console.error(`Error from acquireTokenSilent toDoList ${error}`);
          });
      }
    }, [instance, accounts, inProgress, url]);

    useEffect(() => {
      setCheckedState(new Array(graphData?.items?.length).fill(false));
    }, [graphData]);

    const handleLogin = () => {
      instance.acquireTokenPopup(loginRequest).catch((error) => {
        setShowError(!showError);
      });
    };

    const getTask = async (token: string) => {
      setTkn(token);

      await msAxios
        .get(`${url?.graphEndpoint}/todo/lists/Tasks/tasks`, {
          transformRequest: (data, headers) => {
            if (headers.common) {
              headers.common['Authorization'] = `Bearer ${token}`;
            }
            return data;
          }
        })
        .then((data) => {
          setGraphData({ items: data?.data?.value.filter((arr: any) => arr.status !== 'completed') || [] });
        })
        .catch((err) => {
          console.error(`Error from getTask ${err}`);
        });
    };

    const updateTask = async (id: any) => {
      const updatedCheckedState = checkedState.map((item: any, index: any) => (index === id ? !item : item));
      setCheckedState(updatedCheckedState);
      let currentToDo = [...graphData.items];
      let selectedToDo = currentToDo[id];
      const body = {
        status: 'completed'
      };

      await msAxios
        .patch(`${url?.graphEndpoint}/todo/lists/Tasks/tasks/${selectedToDo.id}`, JSON.stringify(body), {
          transformRequest: (data: any, headers: any) => {
            headers.common['Authorization'] = `Bearer ${tkn}`;
            headers.patch['Content-Type'] = 'application/json';
            return data;
          }
        })
        .then((data) => {
          const updatedTaskData = graphData.items.map((obj: any, i: any) => (i === id ? data?.data : obj));
          setGraphData({ items: updatedTaskData.filter((arr: any) => arr.status !== 'completed') || [] });
        })
        .catch((err) => {
          console.error(`Error from updateTask ${err}`);
        });
    };

    const addTask = () => {
      msAxios
        .post(`${url?.graphEndpoint}/todo/lists/Tasks/tasks`, JSON.stringify(task), {
          transformRequest: (data: any, headers: any) => {
            headers.common['Authorization'] = `Bearer ${tkn}`;
            headers.post['Content-Type'] = 'application/json';
            return data;
          }
        })
        .then((data) => {
          const updatedTaskData = [data?.data, ...graphData.items];
          setGraphData({ items: updatedTaskData.filter((arr: any) => arr.status !== 'completed') || [] });
          setTask({});
          setShow(!show);
        })
        .catch((err) => {
          setShow(!show);
          console.error(`Error from addTask ${err}`);
        });
    };

    return (
      <div className={'card to-do-wrapper'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <Row>
            <Col sm={12}>
              <p>
                <strong>{data.title || 'Tasks'}</strong>
              </p>
            </Col>
          </Row>
          <UnauthenticatedTemplate>
            <LoginButton
              name={Localization.instance.getString('Login_ok')}
              action={handleLogin}
              message={Localization.instance.getString('LOGIN_MS_WIDGET_LOGIN_MSG')}
            />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <span className='add-task'>
              <Row>
                <Col sm={12}>
                  <div>
                    {show && (
                      <div className='add-task-btn' onClick={() => setShow(!show)}>
                        <div className='icon'> + </div>
                        <span style={{ fontSize: '13px', fontWeight: '600' }}>Add a task</span>
                      </div>
                    )}
                    {!show && (
                      <div className='task-input'>
                        <input
                          style={{ width: '80%', border: 'none', paddingLeft: '10px', outline: 'none' }}
                          name='title'
                          value={task.title}
                          placeholder='Add a task'
                          onChange={(e) => setTask({ [e.target.name]: e.target.value })}
                        />
                        <button className='add-btn' onClick={() => addTask()}>
                          Add
                        </button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </span>
            {graphData.items.length !== 0 ? (
              <span className='to-do-list'>
                {graphData.items?.map((item: Record<string, any>, index: any) => (
                  <Row>
                    <Col sm={12}>
                      <div className='list-items'>
                        <input
                          className='boxes'
                          type='checkbox'
                          id={`custom-checkbox-${index}`}
                          checked={checkedState[index]}
                          onChange={() => updateTask(index)}
                        />
                        <div
                          className='task-name'
                          style={{ textDecoration: checkedState[index] ? 'line-through' : '' }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}
              </span>
            ) : (
              <div className='no-data-text'>
                {showError ? (
                  <strong>{Localization.instance.getString('DASHBOARD_SomethingWentWrong')}</strong>
                ) : (
                  <strong>{Localization.instance.getString('DASHBOARD_NoData')}</strong>
                )}
              </div>
            )}
          </AuthenticatedTemplate>
        </Widget>
      </div>
    );
  },
  id: 'Tasks',
  title: 'Tasks',
  description: 'Tasks from outlook account',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default toDoList;

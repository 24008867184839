import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';
import { BaseDropdown } from './component/BaseDropdown';

const COLUMN_NAMES: string[] = ['Discount Id', 'Description'];

export const DiscountIdsDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: discountOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'discountId',
    params: {
      windowId: 'salesDiscountIDs',
    },
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} loading={loading} options={discountOptions} columnNames={COLUMN_NAMES} />;
};

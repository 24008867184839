import { Theme } from '@mui/material';
import { ThemeColor } from 'src/types';

export const getThemeColor = ({
  color,
  theme,
  defaultColor,
}: {
  color?: ThemeColor | string;
  theme: Theme;
  defaultColor?: ThemeColor | string;
}) => {
  if (!color) return defaultColor;

  // Handle MUI default color values
  if (typeof color === 'string' && !color.includes('.')) {
    return color;
  }

  // Handle theme color paths (e.g., 'primary.main')
  const [key, variant] = (color as string).split('.');
  const paletteColor = theme.palette[key as keyof typeof theme.palette];

  if (key && variant && paletteColor && typeof paletteColor === 'object' && variant in paletteColor) {
    const colorValue = (paletteColor as unknown as { [key: string]: string })[variant];
    return colorValue;
  }

  // Fallback to the original color value if it's not a theme color
  return color;
};

import React from 'react';
import { Menu, MenuProps } from '../Menu';
import { Box, styled } from '@mui/material';
import { Button, CustomButtonProps } from '../Button';
import { CustomIconButtonProps, IconButton } from '../IconButton';

export type DropdownProps = Pick<MenuProps, 'options' | 'backgroundColor'> & {
  slotProps: {
    button?: CustomButtonProps;
    iconButton?: CustomIconButtonProps;
    menu?: Partial<MenuProps>;
  };
};

type StyledButtonProps = CustomButtonProps & { open?: boolean };

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, open }) => ({
  ':hover': {
    background: theme.palette.primary['100'],
    color: theme.palette.primary['400'],
    span: {
      cursor: 'pointer',
      color: theme.palette.primary['400'],
      svg: {
        cursor: 'pointer',
        pointerEvents: 'visible',
        stroke: `${theme.palette.primary['400']}!important`,
      },
      path: {
        stroke: theme.palette.primary['400'],
      },
    },
  },
  svg: {
    cursor: 'pointer',
    pointerEvents: 'visible',
    transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
    transition: 'transform 0.2s ease-in-out',
    path: {
      stroke: theme.palette.foreground[500],
    },
  },
}));

export const Dropdown = ({ options, backgroundColor = 'light', slotProps }: DropdownProps) => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box>
      {slotProps.button ? (
        <StyledButton
          {...slotProps?.button}
          open={open}
          iconRight={slotProps?.button?.iconRight || 'chevronRight'}
          onClick={handleClick}
          variant="outlined"
          data-iscapture="true"
          data-for="global"
          data-tip={slotProps?.button?.id}
        />
      ) : (
        slotProps.iconButton && (
          <IconButton
            sx={{ cursor: 'pointer', ...slotProps.iconButton?.sx }}
            {...slotProps.iconButton}
            data-iscapture="true"
            data-for="global"
            data-tip={slotProps?.iconButton?.id}
            onClick={handleClick}
          />
        )
      )}
      <Menu
        onClick={handleClose}
        onClose={handleClose}
        open={open}
        options={options}
        anchorEl={anchorElement}
        backgroundColor={backgroundColor}
        {...slotProps?.menu}
        MenuListProps={{
          onKeyDown: (e) => {
            if (e.key.toLowerCase() === 'enter') {
              handleClose();
            }
          },
        }}
      />
    </Box>
  );
};

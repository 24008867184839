import { Theme } from '@mui/material';
import { stepConnectorClasses } from '@mui/material';
import { TextSize, TextWeight } from './Typography';

export const Stepper = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .MuiStepIcon-root': {
        color: theme.palette.grey[300], // Default step icon color
        '&.Mui-active': {
          color: theme.palette.primary.main, // Active step icon color
        },
        '&.Mui-completed': {
          color: theme.palette.primary.light, // Completed step icon color
        },
      },

      '& .MuiStepIcon-text': {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 1.2,
      },

      '& .MuiStepLabel-label': {
        marginTop: '0px',
        textAlign: 'left',
        fontSize: TextSize.S,
        fontWeight: TextWeight.SEMIBOLD,
        color: theme.palette.foreground[500], // Default label color
        '&.Mui-active': {
          color: theme.palette.primary.dark, // Active label color
        },
        '&.Mui-completed': {
          color: theme.palette.primary.main, // Completed label color
        },
      },

      '& .MuiStepLabel-root': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
      },

      '& .MuiStepConnector-root': {
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
          left: 'calc(-50% + 16px)',
          right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.light,
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.light,
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.grey[400],
          borderTopWidth: 2,
          borderRadius: 1,
        },
      },
    }),
  },
};

import { ComponentBaseProps } from '../parsers/layout/types';
import { WindowProps } from '../../views/partials/Window';
import { ComponentMap } from '../../types/componentMap';
import { Constraint } from '../parsers/constraint';
import { format, parse } from 'date-fns';
import { Action } from '../../components/Button';
import { TabsProps } from 'react-bootstrap';
import { TabProps } from '../../components/Tab';
import { Group, GroupProps } from '../../views/partials/Group';
import { ContextOption, ContextOptions } from '../../components/DataTable';
import { BooleanFormatter } from '../parsers/BooleanFormatter';
import { Formatters } from '../parsers';
import { PanelProps } from '../../views/partials/Panel';
import { Localization } from '../localization/Localization';
import axios from 'axios';
import { Attachments } from '../../components/Attachment';
import { Icons } from '../../components/SquareIcon';
import { AperioViewCommand, AperioViews } from '../../types/AperioViews';
import { Command } from '../base/command';
import { RemoveTrailingDotsFormatter } from '../parsers/RemoveTrailingDotsFormatter';
import { logger } from '../../index';

let autocomplete: any = {};

export const XT: any = {
  dummy: () => {},
  attachments: {},
  autocomplete: {},
  globalConfig: {},
  applicationLinks: {},
  importantLinks: [],
  appConfig: {},
  tryFormatDate: (value: string) => {
    // let { century_break_year, dateSeparator, format6, format8, format8Sep } = props.windowProps.settings.regionals.dateformats[0].$;
  },
  computePageSize: ({
    ATTR_UNITOFMEAS,
    ATTR_MEASMETHOD,
    ATTR_CPI,
    ATTR_LPI,
    ATTR_PAGEWIDTH,
    ATTR_PAGELEN,
  }: {
    ATTR_UNITOFMEAS: string;
    ATTR_MEASMETHOD: string;
    ATTR_CPI: string;
    ATTR_LPI: string;
    ATTR_PAGEWIDTH: string;
    ATTR_PAGELEN: string;
  }) => {
    logger.debug('computePageSize', { ATTR_UNITOFMEAS, ATTR_CPI, ATTR_LPI, ATTR_PAGEWIDTH, ATTR_PAGELEN });
    let lin = +ATTR_PAGELEN;
    let col = +ATTR_PAGEWIDTH;
    if (col < 40) {
      col += 160;
    } else col += 5;

    const met = ATTR_MEASMETHOD;
    const measu = ATTR_UNITOFMEAS;

    let width = 0;
    let height = 0;
    let widthPoints, heightPoints;
    let multiplier, multipWidth;
    let lpi = +ATTR_LPI;
    let cpi = +ATTR_CPI;

    if (met === '*ROWCOL') {
      // Values in inches
      height = lin / lpi;
      width = col / cpi;
      multipWidth = 4.8 * cpi;
      multiplier = 72;
      // Finally we get the value in points
      widthPoints = Math.round(width * multipWidth);
      heightPoints = Math.round(height * multiplier);
      // Font size based on Height
      let fontHeigth = 42 / lpi - 0.01;
      // Adjust if format with few rows (to 24 rows)
      if (lin < 48) fontHeigth = Math.max(5, fontHeigth - ((48 - lin) * 25) / 600);

      return { fontSize: fontHeigth, width: widthPoints, height: heightPoints, lineHeight: fontHeigth };
    } else {
      height = lin;
      width = col;
      if (measu === '*INCH') {
        multiplier = 72;
      } else {
        // Centimeters
        multiplier = 28.3;
      }
      // Finally we get the value in points
      widthPoints = Math.round(width * multiplier) + 72;
      heightPoints = Math.round(height * multiplier) + Math.round(lin * 5) + 72;
      // Font size based on Height
      let fontHeigth = 72 / +ATTR_LPI;
      // Font size based on Width
      let fontWidth = 132 / +ATTR_CPI;

      return {
        fontSize: Math.min(12, fontHeigth, fontWidth),
        lineHeight: Math.min(12, fontHeigth, fontWidth),
        width: widthPoints,
        height: heightPoints,
      };
    }
  },
  loadConfigs: async () => {
    await axios
      .get('/autocompleteConfig.json')
      .then((response) => response.data)
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        autocomplete = jsonData;
        XT.autocomplete = jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(`Error from loadConfigs ${error}`);
      });
    await fetch('/globalConfig')
      .then((response) => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        XT.globalConfig = jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(`Error from globalConfig ${error}`);
      });
    await axios
      .get('/applicationLinks.json')
      .then((response) => response.data)
      .then((jsonData) => {
        // jsonData is parsed json object received from url

        XT.applicationLinks = jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(`Error from applicationLinks ${error}`);
      });

    await fetch('/importantLinks.json')
      .then((response) => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        XT.importantLinks = jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(`Error from importantLinks ${error}`);
      });
    await fetch('/appConfig')
      .then((response) => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        XT.appConfig = jsonData;
      })
      .catch((error) => {
        // handle your errors here
        console.error(`Error from appConfig ${error}`);
      });
  },
  getComponents(
    props: ComponentBaseProps,
    prevResult?: {
      components: any[];
      props: ComponentBaseProps;
      applicationLinks: any;
      autocomplete: any;
      locale: string;
    },
  ) {
    // Initialize result
    // =================
    const result: {
      components: any[];
      props: ComponentBaseProps;
      applicationLinks: any;
      autocomplete: any;
      locale: string;
    } = {
      components: [],
      props: {
        ...props,
        windowProps: { ...props.windowProps },
      },
      applicationLinks: XT.applicationLinks,
      autocomplete: autocomplete,
      locale: Localization.instance.locale,
    };

    // Complete (re)build components if needed
    // =======================================
    if (
      !prevResult || //                                                                 initial build
      !prevResult.components || //                                                      program protection (not expected to be ever true)
      !prevResult.props || //                                                           program protection (not expected to be ever true)
      props.layout !== prevResult.props.layout || //                                    always rebuild if layout changed
      props.data !== prevResult.props.data || //                                        always rebuild if data changed
      // props.flags !== prevResult.props.flags || //                                   BEWARE: do NOT include as each time new instance (at least for panel), but contents not expected to change without data changes
      // props.attributes !== prevResult.props.attributes || //                         BEWARE: do NOT include as each time new instance (at least for panel), but contents not expected to change without layout changes
      props.windowProps.layout !== prevResult.props.windowProps.layout || //            always rebuild if layout changed
      props.windowProps.data !== prevResult.props.windowProps.data || //                always rebuild if layout changed
      props.windowProps.settings !== prevResult.props.windowProps.settings || //        not expected to change without data/layout changes
      props.windowProps.driverList !== prevResult.props.windowProps.driverList || //    not expected to change without data/layout changes
      props.windowProps.tabSequence !== prevResult.props.windowProps.tabSequence || //  not expected to change without data/layout changes
      autocomplete !== prevResult.autocomplete || //                                    not expected to change without data/layout changes
      XT.applicationLinks !== prevResult.applicationLinks || //                         not expected to change without data/layout changes
      Localization.instance.locale !== prevResult.locale //                             not really expected to change (at least not until "now")
    ) {
      result.components = XT._getComponents_base(props);

      // Partial rebuild components
      // =========================
    } else {
      // initialize
      result.components = prevResult.components;

      // ... update prompt handler
      if (props.promptHandler !== prevResult.props.promptHandler) {
        result.components
          .filter((c) => ['editprompt', 'date'].findIndex((name) => name === c.componentName) > -1) // Remark: tab & group components are always rebuild (see further)
          .forEach((c) => {
            c.props.promptHandler = props.promptHandler;
          });
      }

      // ... update action handler
      if (props.actionHandler !== prevResult.props.actionHandler) {
        result.components
          .filter((c) => ['table', 'button'].findIndex((name) => name === c.componentName) > -1) // Remark: tab & group components are always rebuild (see further)
          .forEach((c) => {
            c.props.actionHandler = props.actionHandler;
          });
      }

      // ... update context menu handler
      if (props.contextMenuHandler !== prevResult.props.contextMenuHandler) {
        result.components
          .filter((c) => c.componentName === 'table') // Remark: tab & group components are always rebuild (see further)
          .forEach((c) => {
            c.props.onContextMenuClick = props.contextMenuHandler;
          });
      }

      // ... update load more handler
      if (props.loadMore !== prevResult.props.loadMore) {
        result.components
          .filter((c) => ['table', 'textarea'].findIndex((name) => name === c.componentName) > -1) // Remark: group components are always rebuild (see further)
          .forEach((c) => {
            if (c.componentName === 'table') {
              c.props.loadMore = XT._getComponents_getTableLoadMore(c.props, props);
            } else {
              c.props.loadMore = props.loadMore;
            }
          });
      }

      // ... update button active
      if (props.panelEvents !== prevResult.props.panelEvents) {
        result.components
          .filter((c) => c.componentName === 'button')
          .forEach((c) => {
            c.componentActive = XT._getComponents_getButtonActive(c.props, props);
          });
      }

      // ... update table context options/default actions
      if (props.windowProps.contextDefinition !== prevResult.props.windowProps.contextDefinition) {
        result.components
          .filter((c) => c.componentName === 'table')
          .forEach((c) => {
            const flags = c.componentLayout?.$?.flags || props.flags || [];
            const result = XT._getComponents_getTableContextOptions(c.props, c.componentLayout, props, flags);
            c.props.contextOptions = result.contextOptions;
            c.props.defaultActions = result.defaultActions;
          });
      }

      // ... update tab group props
      /*
        Remark: no "changed" condition included as applies to so many values (which may be or may be not used), that impact
        analysis is not that simple. For now always refresh all group properties (as until today no real negative impact)
      */
      if (props.windowProps.layout.screen.form[0].$.handler !== 'dynamictabfolder.DynamicTabHandler') {
        result.components
          .filter((c) => c.componentName === 'tab')
          .forEach((c) => {
            c.props.groupProps = XT._getComponents_getTabGroupProps(c.componentBaseProps, props);
          });
      }

      // ... update group props
      /*
        Remark: no "changed" condition included as applies to so many values (which may be or may be not used), that impact
        analysis is not that simple. For now always refresh all group properties (as until today no real negative impact)
      */
      result.components
        .filter((c) => c.componentName === 'group')
        .forEach((c) => {
          c.props = XT._getComponents_getGroupProps(c.componentBaseProps, c.componentLayout, props);
        });
    }

    // Return result
    // =============
    return result;
  },
  disableAutoComplete: (fieldId: string, formId: string, exclude: any[]) => {
    for (const item of exclude) {
      const formIdRegex = item.form && new RegExp(`^${item.form}$`);
      const fieldRegex = item.id && new RegExp(`^${item.id}$`);
      const fieldIdMatches = item.id === fieldId;
      const formIdMatches = item.form === formId;
      const fieldIdRegexResult = fieldRegex?.test(fieldId);
      const formIdRegexResult = formIdRegex?.test(formId);

      if (item.id && item.form) {
        if ((fieldIdMatches && formIdMatches) || (formIdRegexResult && fieldIdRegexResult)) {
          return true;
        }
      } else if (fieldIdMatches || formIdMatches || formIdRegexResult || fieldIdRegexResult) {
        return true;
      }
    }
    return false;
  },
  _getComponents_base: (props: ComponentBaseProps): any[] => {
    let _components: any[] = [];
    let { decimalsign, entry_delimiter, no, yes } = props.windowProps.settings.regionals.codes[0].$;
    let { century_break_year, dateSeparator, format6, format8, format8Sep } =
      props.windowProps.settings.regionals.dateformats[0].$;
    let { dimension } = props.windowProps.settings.regionals.account_dimensions[0];

    let recurse = (cur: Record<string, any>, key: string | number) => {
      if (key === '') {
        for (let k in cur) {
          recurse(cur, k);
        }
      } else if (Array.isArray(cur[key])) {
        //Check if component present
        if (!!ComponentMap[key]) {
          for (let i in cur[key]) {
            let componentActive = true;
            let attrs = cur[key][i]['$'] || {};

            if (!attrs && key !== 'titles') continue;

            //Confirm this
            let flags = attrs?.flags || props.flags || [];
            let vConstraint = new Constraint(attrs?.visibleconstraints);
            let dConstraint = new Constraint(attrs?.disableconstraints);
            let eConstraint = new Constraint(attrs?.errorconstraints);
            let fConstraint = new Constraint(attrs?.focusconstraints);
            //let eConstraint = new Constraint(attrs?.errorconstraint);
            // if (attrs?.visibleconstraints)
            let _props: Record<string, any> | undefined = {
              panelID: props.isPanel ? props.id : props.panelID,
              formID: props.windowProps.data.form.$.id,
              attributes: attrs || {},
              tooltipText: attrs?.id,
              id: attrs?.id,
              name: attrs?.id,
              readOnly: attrs?.readonly === 'true',
              textDirection: attrs?.textalign,
              visible: attrs?.visible?.toLowerCase() !== 'false' && vConstraint.evaluate(flags, true),
              disabled: attrs?.disabled === 'true' || attrs?.enabled === 'false' || dConstraint.evaluate(flags, false),
              isInvalid: eConstraint.evaluate(flags, false),
              hasFocusConstraint: fConstraint.evaluate(flags, false),
              autoCapitalize: attrs?.uppercase !== 'false',
            };

            let value = XT.getValue(props, _props.attributes.id);
            _props.defaultValue = value?.trimEnd() || '';
            _props.rawValue = value || '';
            if (attrs?.font?.toLowerCase()?.includes('courier')) {
              _props.className = 'monospace ';
            }

            let componentBaseProps = { ..._props };
            switch (key) {
              case 'dynamictable':
                let rows = '';
                cur[key][i].row.forEach(
                  (r: any) => (rows += XT.getValueFromWindow(props.windowProps, '', r.$.id).trimEnd() + '\n'),
                );
                _props.text = rows;
                _props.name = 'dynamictable';
                _props.className = 'monospace text-left';
                break;
              case 'table':
                if (
                  _props.attributes.id === 'TBL_GDMD38502' ||
                  _props.attributes.id === 'TBL_GDMD38512' ||
                  _props.attributes.id === 'TBL_FSR02202'
                ) {
                  let edit = cur[key][i].column.find((col: any) => col.$.readonly === 'false');
                  let printLimit = XT.getValue(props, 'FMXX70');

                  if (_props.attributes.id === 'TBL_GDMD38502' || _props.attributes.id === 'TBL_GDMD38512')
                    _props.printLimit = printLimit.indexOf('<') + 1;
                  _props.limit = +(edit?.$.limit || 70);
                  _props.attributes.rowCtrl = edit?.$.id || 'X3LINE';
                  _props.loadMore = props.loadMore;
                  _props.readOnly = !edit;
                  // if (_props.attributes.id === 'TBL_FSR02202') _props.limit = 58;
                  _components.push({
                    componentName: 'textarea',
                    componentActive: componentActive,
                    componentLayout: cur[key][i],
                    componentBaseProps: componentBaseProps,
                    layout: cur[key],
                    key: cur[key][i]['$'].id,
                    component: ComponentMap['textarea'],
                    props: _props,
                  });
                  _props = undefined;
                } else {
                  let selectionColumn = cur[key][i]['column'].find((x: any) => x.$.selectioncolumn === 'true');
                  attrs.disableActionOn = selectionColumn?.$?.disableconstraints;
                  _props.attributes.selectionColumn = selectionColumn?.$?.id;

                  const result = XT._getComponents_getTableContextOptions(_props, cur[key][i], props, flags);
                  _props.contextOptions = result.contextOptions;
                  _props.defaultActions = result.defaultActions;

                  _props.toggleID = 'NA';
                  let splitColumns: any[] = [];
                  if (!!cur[key][i]['columnsplitting']) {
                    let splits = cur[key][i]['columnsplitting'];
                    for (let splitIndex in splits) {
                      let split = splits[splitIndex];
                      for (let columnIndex in split.column) {
                        let column = split.column[columnIndex];
                        let mode = XT.getValue(props, column.mode?.[0].$.value);
                        let toggle = column.toggle.find((tog: any) => tog.$.value === mode) || column.toggle[0];
                        let heading = '';
                        if (column.heading && column.heading[0]) {
                          heading = XT.getValueFromWindow(
                            props.windowProps,
                            column.heading[0].$.panel,
                            column.heading[0].$.field,
                          );
                        }

                        _props.toggleID = mode || _props.toggleID;

                        for (let togColIndex in toggle.column) {
                          let togCol = toggle.column[togColIndex];
                          //TODO: remove double columns
                          let origCol = cur[key][i]['column']?.find((col: any) => col.$.id === column.$.name);
                          let colOrder = origCol ? origCol.$.columnorder : togCol.$.columnorder;
                          let text = togCol.$.text;
                          if (text === '*ACP') {
                            //Use account part handler
                            // let [panel, id] = attrs.userdata3.split('.');
                            let headingsField = XT.getValueFromWindow(
                              props.windowProps,
                              '',
                              heading.slice(togCol.$.from, togCol.$.to),
                            );
                            let firstPart = 1;
                            let fullWidth = togCol.$.width * 3;
                            let totalWidth = dimension.reduce((p: number, c: any) => p + +c.$.part, 0);
                            dimension.forEach((dim: any, index: number) => {
                              let _col = {
                                accessor: togCol.$.id + 'ACP' + dim.$.description,
                                Header: heading.substr(+togCol.$.from + +firstPart, +dim.$.part),
                                ...togCol.$,
                                limit: dim.$.part,
                                id: togCol.$.id + 'ACP' + dim.$.description + `|${index}`,
                                from: +togCol.$.from + +firstPart,
                                columnorder: colOrder,
                                to: +togCol.$.from + +firstPart + +dim.$.part,
                                width: (fullWidth / totalWidth) * +dim.$.part,
                                split: true,
                                split_id: column.$.name,
                              };
                              // if(!splitColumns.find((col: any) => col.id === _col.id))
                              splitColumns.push(_col);
                              firstPart += +dim.$.part + 1;
                              // if (col) {
                              //   col.Header = headingsField.substring(0, +dim.$.part) || col.Header;
                              //   headingsField = headingsField.slice(+dim.$.part + 1);
                              // }
                            });
                            continue;
                          }

                          if (heading) {
                            text = heading.slice(togCol.$.from, togCol.$.to);
                          }

                          if (splitColumns.filter((col: any) => col.accessor === togCol.$.id).length === 0)
                            splitColumns.push({
                              accessor: togCol.$.id,
                              Header: text,
                              ...togCol.$,
                              columnorder: colOrder,
                              width: togCol.$.width * 1.4,
                              split: true,
                              split_id: column.$.name,
                            });
                        }
                      }
                    }
                  }

                  let columns: any[] = [];
                  cur[key][i]['column'].forEach((column: any) => {
                    let header = column.$.text || '';
                    let noHeader = false;

                    if (!!column.heading) {
                      // let heading = column.heading[0].$;
                      if (column.heading && column.heading[0]) {
                        column.heading.forEach((head: any) => {
                          header = !!XT.getValueFromWindow(props.windowProps, head.$.panel, head.$.field)?.trim()
                            ? XT.getValueFromWindow(props.windowProps, head.$.panel, head.$.field)
                            : header;
                          if (!XT.getValueFromWindow(props.windowProps, head.$.panel, head.$.field)?.trim()) {
                            noHeader = true;
                          }
                        });
                      }
                      // header = XT.getValueFromWindow(props.windowProps, heading.panel, heading.field);
                    }
                    if (!!column.titles) {
                      // header = header;
                      for (let i in column.titles[0].title) {
                        let title = column.titles[0].title[i];

                        let vConstraint = new Constraint(title.$.visibleconstraints);
                        let vis = vConstraint.evaluate(flags, true);
                        if (vis) header = title.$.text;
                      }
                      if (header.trim() === '') {
                        header = column.titles[0].title?.[0]?.$?.text || '';
                      }
                    }
                    let colors;
                    if (!!column.colors) {
                      colors = column.colors[0].color.map((x: any) => x.$);
                    }
                    if (column.$.text === '*ACP') {
                      //Use account part handler
                      // let [panel, id] = attrs.userdata3.split('.');
                      let headingsField = XT.getValueFromWindow(props.windowProps, '', header);
                      let firstPart = 0;
                      let fullWidth = column.$.width * 3;
                      let totalWidth = dimension.reduce((p: number, c: any) => p + +c.$.part, 0);
                      dimension.forEach((dim: any, index: number) => {
                        let _col = {
                          accessor: column.$.id + 'ACP' + dim.$.description,
                          Header:
                            headingsField?.substr(firstPart, dim.$.part) ||
                            (header?.substr(firstPart, dim.$.part).trim() === '*ACP'
                              ? undefined
                              : header?.substr(firstPart, dim.$.part)) ||
                            dim.$.description,
                          ...column.$,
                          limit: dim.$.part,
                          id: column.$.id + 'ACP' + dim.$.description + `|${index}`,
                          from: +firstPart,
                          to: +firstPart + +dim.$.part,
                          width: 16 * +dim.$.part,
                          split: true,
                          split_id: column.$.id,
                          hideempty: 'true',
                        };
                        if (!!_col.Header.trim()) columns.push(_col);
                        firstPart += +dim.$.part + 1;
                        // if (col) {
                        //   col.Header = headingsField.substring(0, +dim.$.part) || col.Header;
                        //   headingsField = headingsField.slice(+dim.$.part + 1);
                        // }
                      });
                      return;
                    }

                    // let colVConstraint = new Constraint(column.$.visibleconstraints);
                    // if (!colVConstraint.evaluate(flags, true)) return;

                    if (columns.filter((col: any) => col.accessor === column.$.id).length === 0)
                      columns.push({
                        colors: colors,
                        accessor: column.$.id,
                        Header: header,
                        images: column?.$?.contenttype === 'image' ? column?.images?.[0]?.image : undefined,
                        noHeader: noHeader,
                        ...column.$,
                        textalign: column?.$?.contenttype === 'image' ? 'center' : column.$.textalign,
                        width: column.$.width * 1.4,
                      });
                  });

                  //Remove duplicate columns?????

                  // _props.columns.sort((a: any, b: any) => +(a.taborder || '99') - +(b.taborder || '99'));

                  let dynamicColumns: any[] = [];
                  if (props.windowProps.data.form.$.id === 'QueryResult') {
                    dynamicColumns = XT.getColumns(props.data.columns);
                  }

                  _props.columns = [
                    ...splitColumns,
                    ...columns.filter((x: any) => {
                      return splitColumns.filter((y: any) => y.split_id === x.accessor).length === 0;
                    }),
                    ...dynamicColumns,
                  ].sort((a: any, b: any) => +a.columnorder - +b.columnorder);
                  let value = XT.getRows(props, _props.columns, attrs);

                  let showFirst = true;
                  _props.columns = _props.columns
                    .map((col: any) => {
                      // if (col.readonly === 'false') col.disablecon;
                      col.Header = XT.getDynamicValue(props.windowProps, col.Header);
                      let hasOneRow = value.find((row: any) => !!row[col.accessor]?.trim());
                      let shouldShow = true;
                      if (col.hideempty === 'true' && !(hasOneRow || col.readonly === 'false')) {
                        shouldShow = false;
                      }
                      if (col.visible === 'false' && !col.Header.trim()) {
                        shouldShow = false;
                      }
                      if (showFirst && shouldShow) {
                        showFirst = false;
                      }
                      return col;
                    })
                    .filter((col: any, index: number) => {
                      if (col.disabled === 'true' || col.enabled === 'false') return false;

                      // if (showFirst && index === 0) return true;
                      let hasOneRow = value.find((row: any) => !!row[col.accessor]?.trim());
                      if (col.hideempty === 'true' && !(hasOneRow || col.readonly === 'false' || !col.noHeader)) {
                        return false;
                      }
                      if (col.visible === 'false' && !col.Header.trim()) {
                        return false;
                      }
                      return true;
                    });
                  if (attrs.userdata1 === '*ACP') {
                    //Use account part handler
                    let [panel, id] = attrs.userdata3.split('.');
                    let headingsField = XT.getValueFromWindow(props.windowProps, panel, id);
                    dimension.forEach((dim: any) => {
                      let dimReg = new RegExp(`^.*${attrs.userdata2 || 'DIM'}${dim.$.sequence}`, 'i');
                      let col = _props?.columns.find((col: any) => dimReg.test(col.accessor));
                      if (col) {
                        col.Header = headingsField.substring(0, +dim.$.part) || col.Header;
                        headingsField = headingsField.slice(+dim.$.part + 1);
                        col.limit = dim.$.part;
                        col.autotab = 'true';
                      }
                    });
                    const dimColsAllReg = new RegExp(`^.*${attrs.userdata2 || 'DIM'}`);
                    const dimColsAll = _props?.columns.filter((col: any) => dimColsAllReg.test(col.accessor));

                    // delete all not used dimensions columns
                    if (dimColsAll.length - dimension.length > 0) {
                      const dimColsToHideReg = new RegExp(
                        `^.*${attrs.userdata2 || 'DIM'}[${dimension.length + 1}-${dimColsAll.length}]`,
                        'i',
                      );
                      _props.columns = _props.columns.filter((col: any) => !dimColsToHideReg.test(col.accessor));
                    }
                  }

                  if (_props.formID === 'RWRD120' && _props.panelID === 'SUBFILE2')
                    _props.attributes.autopagedown = 'false'; // Force lower table to be fully visible

                  let autocompleteParams = autocomplete?.autocomplete?.fields
                    ?.sort((a: any, b: any) => (b.form || '').length - (a.form || '').length)
                    .map((ac: any) => {
                      let formRegex = ac.form && new RegExp(`^${ac.form}$`);
                      // return formRegex && formRegex.test(props.windowProps.data.form.$.id);
                      let fieldRegex = ac.id && new RegExp(`^${ac.id}$`);
                      if (formRegex) {
                        //Check for each column id
                        let formCols = _props?.columns.filter(
                          (col: any) => formRegex.test(props.windowProps.data.form.$.id) && fieldRegex.test(col.id),
                        );
                        if (formCols.length > 0) {
                          return { ...ac, cols: formCols.map((col: any) => col.id) };
                        } else {
                          return null;
                        }
                      } else {
                        let fieldCols = _props?.columns.filter((col: any) => fieldRegex.test(col.id));
                        if (fieldCols.length > 0) {
                          return { ...ac, cols: fieldCols.map((col: any) => col.id) };
                        } else {
                          return null;
                        }
                      }
                    })
                    .filter((ac: any) => !!ac);
                  _props.apiLimit = +(autocomplete?.autocomplete?.limit || 50);
                  _props.minLettersToRun = +(autocomplete?.autocomplete?.minLettersToRun || 2);
                  attrs.autoComplete = autocompleteParams;
                  _props.className += ' ' + attrs.class;

                  _props.tableHiddenColumns = _props.columns
                    .filter((col: any) => col.visible === 'false')
                    .map((col: any) => col.accessor);
                  _props.origHiddenColumns = _props.columns
                    .filter((col: any) => col.visible === 'false')
                    .map((col: any) => col.accessor);
                  _props.data = value;
                  _props.keyField = 'id';
                  _props.className = 'custom-table';
                  _props.onContextMenuClick = props.contextMenuHandler;
                  _props.loadMore = XT._getComponents_getTableLoadMore(_props, props);
                  _props.header = !(_props.attributes.header === 'false');
                  let layout = cur[key][i];
                  if (layout?.action?.[0]) {
                    let f4action = layout.action.find((x: any) => x.$.event?.toLowerCase() === 'f4');
                    if (f4action?.$?.rowfield) {
                      _props.attributes.rowfield = f4action.$.rowfield;
                    }
                  }
                  _props.actionHandler = props.actionHandler;
                }
                break;
              case 'edit':
                if (_props.attributes.id === 'FMXX70' && _props.attributes.ddspanel === 'GDMD38503') _props = undefined;
                else if (attrs?.class?.indexOf('Label') > -1 || attrs?.borderstyle === 'none') {
                  // key = 'label';
                  _props.text = _props.defaultValue.replace(/\.*$/g, '');
                  _props.className = attrs.class + ` text-${attrs.textalign}`;
                  // continue;
                  _components.push({
                    componentName: 'label',
                    componentActive: componentActive,
                    componentLayout: cur[key][i],
                    componentBaseProps: componentBaseProps,
                    layout: cur[key],
                    key: _props.id,
                    component: ComponentMap['label'],
                    props: _props,
                  });
                  _props = undefined;
                } else if (_props.attributes.multiline === 'true') {
                  _components.push({
                    componentName: 'multiline',
                    componentActive: componentActive,
                    componentLayout: cur[key][i],
                    componentBaseProps: componentBaseProps,
                    layout: cur[key],
                    key: _props.id,
                    component: ComponentMap['multiline'],
                    props: _props,
                  });
                  _props = undefined;
                } else {
                  let autocompleteParams = autocomplete?.autocomplete?.fields
                    ?.sort((a: any, b: any) => (b.form || '').length - (a.form || '').length)
                    .find((ac: any) => {
                      let formRegex = ac.form && new RegExp(`^${ac.form}$`);
                      // return formRegex && formRegex.test(props.windowProps.data.form.$.id);
                      let fieldRegex = ac.id && new RegExp(`^${ac.id}$`);
                      if (formRegex) {
                        return formRegex.test(props.windowProps.data.form.$.id) && fieldRegex.test(attrs.id);
                      } else {
                        return fieldRegex.test(attrs.id);
                      }
                    });
                  _props.apiLimit = +(autocomplete?.autocomplete?.limit || 50);
                  _props.minLettersToRun = +(autocomplete?.autocomplete?.minLettersToRun || 2);
                  _props.autoComplete = autocompleteParams;
                  _props.autoCompleteEndpoint = autocompleteParams && autocompleteParams.endpoint;
                  _props.autoCompleteKey = autocompleteParams && autocompleteParams.key;
                  _props.autoCompleteParams = autocompleteParams && autocompleteParams.params;
                  _props.disableAutoComplete =
                    autocompleteParams && autocompleteParams.exclude
                      ? XT.disableAutoComplete(attrs.id, _props.formID, autocompleteParams.exclude)
                      : false;
                  _props.className += ' ' + attrs.class;
                }
                let actionstext = props.windowProps.layout.screen.form[0]?.actionstext?.[0];
                let fields = actionstext?.fields?.[0]?.field;
                let field = fields?.find((f: any) => f.$.name === attrs.id);
                if (field) {
                  _props = undefined;
                }
                break;
              case 'editprompt':
                _props.promptHandler = props.promptHandler;
                _props.variant = 'prompt';
                let autocompleteParams = autocomplete?.autocomplete?.fields
                  ?.sort((a: any, b: any) => (b.form || '').length - (a.form || '').length)
                  .find((ac: any) => {
                    let formRegex = ac.form && new RegExp(`^${ac.form}$`);
                    // return formRegex && formRegex.test(props.windowProps.data.form.$.id);
                    let fieldRegex = ac.id && new RegExp(`^${ac.id}$`);
                    if (formRegex) {
                      return formRegex.test(props.windowProps.data.form.$.id) && fieldRegex.test(attrs.id);
                    } else {
                      return fieldRegex.test(attrs.id);
                    }
                  });
                _props.apiLimit = +(autocomplete?.autocomplete?.limit || 50);
                _props.minLettersToRun = +(autocomplete?.autocomplete?.minLettersToRun || 2);
                _props.autoComplete = autocompleteParams;
                _props.autoCompleteEndpoint = autocompleteParams && autocompleteParams.endpoint;
                _props.autoCompleteKey = autocompleteParams && autocompleteParams.key;
                _props.autoCompleteParams = autocompleteParams && autocompleteParams.params;
                _props.disableAutoComplete =
                  autocompleteParams && autocompleteParams.exclude
                    ? XT.disableAutoComplete(attrs.id, _props.formID, autocompleteParams.exclude)
                    : false;
                break;
              case 'titles':
                let titles = cur[key][i];
                if (
                  props.windowProps.layout.screen.form?.[0]?.titles &&
                  props.windowProps.layout.screen.form?.[0]?.titles?.length > 0
                ) {
                  titles = props.windowProps.layout.screen.form?.[0]?.titles[0];
                }
                _props.id = 'title';
                _props.attributes.id = 'title';
                _props.crumbs = [];
                _props.fields = [];
                for (let i in titles.title) {
                  let title = titles.title[i];
                  vConstraint = new Constraint(title.$?.visibleconstraints);
                  dConstraint = new Constraint(title.$?.disableconstraints);
                  let visible = title.$?.visible !== 'false' && vConstraint.evaluate(flags, true);
                  let disabled = title.$?.disabled === 'true' && dConstraint.evaluate(flags, false);
                  if (visible && !disabled && !!title.$?.text)
                    _props.crumbs.push(XT.getDynamicValue(props.windowProps, title.$?.text || ''));
                  if (title.$?.text.startsWith('|')) {
                    let matches = title?.$?.text?.match(/\|.*?\|/g);
                    if (matches) {
                      for (let i in matches) {
                        let match = matches[i];
                        let _match = match.replaceAll('|', '');
                        let [panel, field] = _match.split('.');
                        _props.fields.push(field || panel);
                      }
                    }
                  }
                }
                for (let i in titles.field) {
                  let title = titles.field[i];
                  vConstraint = new Constraint(title.$?.visibleconstraints);
                  dConstraint = new Constraint(title.$?.disableconstraints);
                  let visible = title.$?.visible !== 'false' && vConstraint.evaluate(flags, true);
                  let disabled = title.$?.disabled === 'true' && dConstraint.evaluate(flags, false);
                  //Search for edits
                  let edits = XT.searchPanel(props.windowProps.layout, 'edit')?.flat();
                  let field = edits.find((ed: any) => ed.$.id === title.$?.name);
                  if (field) {
                    let editVisible = new Constraint(field.$.visibleconstraints);
                    let editDisabled = new Constraint(field.$.disableconstraints);
                    visible = visible && editVisible.evaluate(flags, true);
                    disabled = disabled && editDisabled.evaluate(flags, false);
                  }
                  let _title = XT.getValueFromWindow(props.windowProps, title.$?.panel, title.$?.name);
                  if (visible && !disabled && _title) _props.crumbs.push(_title);
                  _props.fields.push(title.$?.name);
                }
                //props.text = attrs?.text;
                break;
              case 'label':
                _props.text = XT.getDynamicValue(props.windowProps, attrs?.text || '');
                _props.className += ' ' + (attrs?.class || '') + ' text-' + attrs?.textalign;
                if (_props.attributes.id === 'LBLCopyColumn') _props = undefined;
                break;
              case 'image':
                _props.src = 'data:image/gif;base64,' + value;
                _props.panelID = props.isPanel ? props.id : props.panelID;
                _props.formID = props.windowProps.data.form.$.id;
                _props.id = attrs?.id;
                _props.visible = attrs?.visible?.toLowerCase() !== 'false' && vConstraint.evaluate(flags, true);
                _props.disabled =
                  attrs?.disabled === 'true' || attrs?.enabled === 'false' || dConstraint.evaluate(flags, false);
                if (_props.attributes.id === 'LBLCopyColumn') _props = undefined;
                break;
              case 'stylededit':
                _props.text = XT.getDynamicValue(props.windowProps, attrs?.text || '');
                _props.text = XT.getValueFromWindow(props.windowProps, _props.panelID || '', attrs?.id);
                _props.className = 'monospace';
                _props.className += ' ' + (attrs?.class || '') + ' text-left';
                if (_props.attributes.id === 'LBLCopyColumn') _props = undefined;
                break;
              case 'checkbox':
                _props.key = attrs?.id;
                _props.yes = yes;
                _props.no = no;
                _props.defaultValue = Formatters.BooleanFormatter.in(_props.defaultValue, yes, no);
                //_props.defaultValue = undefined;
                //props.label = attrs?.id;
                _props.isTriState = attrs?.state3?.toLowerCase() === 'true';
                _props.name = String(attrs?.id);
                _props.id = _props.id + props.windowProps.id;
                break;
              case 'radio':
                dConstraint = new Constraint((props as GroupProps).attributes.disableconstraints || '');
                _props.disabled = dConstraint.evaluate(flags, false) || attrs?.enabled?.toLowerCase() === 'false';
                _props.yes = yes;
                _props.no = no;
                _props.key = attrs?.id;
                _props.label = attrs?.text;
                _props.name = props.id;
                _props.isGroup = props.attributes.radiogroup?.toLowerCase() === 'true';
                break;
              case 'group':
                _props.layout = cur[key][i];
                if (attrs.userdata1 === '*ACP') {
                  let [labelPre, editPre] = attrs.userdata2?.split(',') || ['DIM', 'DIM'];
                  let totalPartsUsed = dimension.reduce((p: number, c: any) => +p + +(c.$.part || 0), 0);
                  const totalParts = 32; // taken from XT?
                  let noOfParts = dimension.length;
                  dimension.forEach((dim: any) => {
                    let editID = (editPre || 'DIM') + dim.$.sequence;
                    let edit = _props?.layout.editprompt?.filter((ctrl: any) => ctrl.$.id.endsWith(editID));

                    let labelID = (labelPre || 'DIM') + dim.$.sequence;
                    let label = _props?.layout.edit?.filter((ctrl: any) => ctrl.$.id.endsWith(labelID));

                    if (label && label.length > 0) {
                      label.forEach((l: any) => {
                        l.$.limit = dim.$.part;
                        l.$.width = (dim.$.part / totalParts) * 100;
                      });
                    }

                    if (edit && edit.length > 0) {
                      edit.forEach((e: any) => {
                        e.$.limit = dim.$.part;
                        e.$.width = (dim.$.part / totalParts) * 100;
                        e.$.autotab = 'true';
                      });
                    }
                  });
                  // prepare placeholders for dimensions which are not used and will be hidden
                  // we need placeholders to preserve the correct layout
                  const noOfNotUsedDim = attrs.numcols - noOfParts;
                  Array(noOfNotUsedDim)
                    .fill(1)
                    .map((x, i) => i + 1 + noOfParts)
                    .forEach((dim: number, i: number) => {
                      let editID = (editPre || 'DIM') + dim;
                      let edit = _props?.layout.editprompt?.filter((ctrl: any) => ctrl.$.id.endsWith(editID));

                      let labelID = (labelPre || 'DIM') + dim;
                      let label = _props?.layout.edit?.filter((ctrl: any) => ctrl.$.id.endsWith(labelID));

                      if (label && label.length > 0) {
                        label.forEach((l: any) => {
                          l.$.limit = 0;
                          l.$.width = ((totalParts - totalPartsUsed) / noOfNotUsedDim / totalParts) * 100;
                          l.$.hide = true;
                        });
                      }

                      if (edit && edit.length > 0) {
                        edit.forEach((e: any) => {
                          e.$.limit = 0;
                          e.$.width = ((totalParts - totalPartsUsed) / noOfNotUsedDim / totalParts) * 100;
                          e.$.hide = true;
                        });
                      }
                    });
                  _props.attributes.isACP = true;
                }
                componentBaseProps = { ..._props };
                _props = XT._getComponents_getGroupProps(componentBaseProps, cur[key][i], props);

                // if (attrs.visible === 'false' && attrs.class === 'HeaderGroup') {
                //   let titles = _components.find((c: any) => c.componentName === 'titles');
                //   if (
                //     !(props.windowProps.layout.screen.form?.[0]?.titles && props.windowProps.layout.screen.form?.[0]?.titles?.length > 0)
                //   ) {
                //     _props.layout?.edit?.forEach((edit: any) => {
                //       let _titleVConstraint = new Constraint(edit.$.visibleconstraints);
                //       let _titleDConstraint = new Constraint(edit.$.disableconstraints);
                //       if (!titles?.props?.fields?.find((f: string) => f === edit.$.id))
                //         if (_titleVConstraint.evaluate(flags, true) && !_titleDConstraint.evaluate(flags, false) && titles)
                //           if (isNaN(+XT.getValueFromWindow(props.windowProps, '', edit.$.id)))
                //             titles.props?.crumbs?.push(XT.getValueFromWindow(props.windowProps, '', edit.$.id) || '');
                //     });
                //     _props = undefined;
                //   }
                // }
                if (_props?.id === 'TextGroup') {
                  _props = undefined;
                }
                break;
              case 'combo':
                _props.options = cur[key][i].value?.map((x: any) => ({
                  label: x.$.displaytext || x.$.text,
                  value: x.$.text || x.$.displaytext,
                }));
                _props.defaultValue = _props.options?.find((x: any) => x.value === (_props?.rawValue || ''));
                // _props.variant = 'prompt';
                if (!!_props?.options) {
                  _props.isCombo = true;
                  _props.variant = _props.readOnly ? 'combo' : 'combo-editable';
                  _props.autoCapitalize = attrs?.uppercase === 'true';
                  _components.push({
                    componentName: 'combo-editable',
                    componentActive: componentActive,
                    componentLayout: cur[key][i],
                    componentBaseProps: componentBaseProps,
                    layout: cur[key],
                    key: cur[key][i]['$'].id,
                    component: ComponentMap['combo-editable'],
                    props: { ..._props, readOnly: false },
                  });
                  _props = undefined;
                  // cur['combo'] = cur[key];
                }
                if (!_props?.options) {
                  _props = undefined;
                }
                break;
              case 'date':
                if (attrs.userdata1 === 'DATE_PICKER_NATIVE') {
                  _props.component = ComponentMap['datenative'];
                  _props.componentName = 'datenative';
                  let showCentury = attrs?.showcentury === 'true';
                  let dateFormat = format6;
                  _props.defaultValue = _props.defaultValue.trim();
                  if (_props.defaultValue?.length === 8) dateFormat = format8;
                  if (_props.defaultValue?.includes(dateSeparator)) dateFormat = format8Sep;
                  let date = Formatters.Date.in_DEPRECATED(_props.defaultValue, dateFormat, +century_break_year);
                  _props.defaultValue = date || null;
                  _components.push({
                    componentName: 'datenative',
                    componentActive: componentActive,
                    componentLayout: cur[key][i],
                    componentBaseProps: componentBaseProps,
                    layout: cur[key],
                    key: cur[key][i]['$'].id,
                    component: ComponentMap['datenative'],
                    props: _props,
                  });
                  _props = undefined;
                } else {
                  _props.promptHandler = props.promptHandler;
                  _props.variant = 'prompt';
                  _props.attributes.mask = 'date';
                }
                // let showCentury = attrs?.showcentury === 'true';
                // let dateFormat = format6;
                // _props.defaultValue = _props.defaultValue.trim();
                // if (_props.defaultValue?.length === 8) dateFormat = format8;
                // if (_props.defaultValue?.includes(dateSeparator)) dateFormat = format8Sep;
                // let date = Formatters.Date.in(_props.defaultValue, dateFormat, +century_break_year);
                // _props.defaultValue = date ? format(date, 'P', { locale: Localization.instance.format }) : _props.defaultValue;
                // _props.defaultValue = null;
                // if (!!_props.defaultValue)
                //   _props.defaultValue = parse(_props.defaultValue.match(/\d{2}/g)?.join('-'), 'MM-dd-yy', new Date());
                break;
              case 'time':
                // if (_props.readOnly || (_props.disabled && _props.defaultValue.length === 8)) _props.dateFormat = format8Sep;
                _props.defaultValue = Formatters.Time.in(_props.defaultValue, +attrs.limit || _props.rawValue.length);
                // _props.defaultValue = null;x
                // if (!!_props.defaultValue)
                //   _props.defaultValue = parse(_props.defaultValue.match(/\d{2}/g)?.join('-'), 'MM-dd-yy', new Date());
                break;
              case 'button':
                _props.tooltipText = attrs?.tooltip || attrs?.id;
                _props.name = XT.getDynamicValue(props.windowProps, attrs?.text || '');
                let _dConstraint = new Constraint(attrs.disableconstraints);
                _props.disabled = attrs?.enabled === 'false' || _dConstraint.evaluate(flags, false);
                if (!_props.disabled && cur[key][i].action) _props.action = cur[key][i].action[0].$;
                _props.className = 'btn-block';
                _props.actionHandler = props.actionHandler;
                // if (_props.action && _props.action.event && ['ENTER', 'DEFAULT'].indexOf(_props.action.event) === -1)
                //   if (!props.panelEvents[_props.action.event]) _props = undefined;
                if (!_props?.action && _props?.id === 'CloseButton') _props.action = { event: 'F3' };
                componentActive = XT._getComponents_getButtonActive(_props, props);

                break;
              case 'tab':
                if (props.windowProps.layout.screen.form[0].$.handler === 'dynamictabfolder.DynamicTabHandler') {
                  //Dynamic tabs
                  let activeID = _props.attributes.userdata1;
                  let activeTab = XT.getValueFromWindow(props.windowProps, '', activeID);
                  let tabListFields: string[] = _props.attributes.userdata2.split(',');
                  let tabs: TabProps[] = [];
                  let action: Action = cur[key][i].tabpage[0].activate_actions[0].action[0].$;
                  for (let i in tabListFields) {
                    let field = tabListFields[i];
                    let value = XT.getValueFromWindow(props.windowProps, '', field);
                    let tabValues = value.split('=');
                    let id = tabValues[0];
                    for (let j = 1; j < tabValues.length; j++) {
                      let str = tabValues[j].trim();
                      let endIndex = str.lastIndexOf(' ');
                      action.option = id;
                      let label = endIndex === -1 ? str : str.substring(0, endIndex);
                      let tab: TabProps = {
                        active: id === activeTab,
                        id: id,
                        visible: true,
                        title: label,
                        activate_actions: [{ ...action }],
                      };
                      tabs.push(tab);
                      id = str.substring(endIndex);
                    }
                  }
                  _props.tabs = tabs;
                  _props.needsAbsolute = true;
                  _props.group = Group;
                } else if (props.windowProps.layout.screen.form[0].$.handler === 'itembp.DriverBasedTabHandler') {
                  let tab = cur[key][i];
                  let activeField = tab.$.userdata1;
                  let activeValue = +(XT.getValueFromWindow(props.windowProps, '', activeField) || '').trimEnd();
                  let actionTemplate = tab.tabpage[0]?.activate_actions?.[0]?.action?.[0]?.$;
                  let tabs: TabProps[] = [];
                  for (let j = 0; j < props.windowProps.tabSequence?.length; j++) {
                    let tabDef = props.windowProps.tabSequence?.[j];
                    let action: Action = { ...actionTemplate };
                    action.option = `${j + 1}`;
                    let tab: TabProps = {
                      activate_actions: [action],
                      active: `${activeValue}` === `${j + 1}`,
                      id: `tab-${tabDef}`,
                      visible: true,
                      title: (props.windowProps.driverList?.[tabDef + ''] || '').trimEnd(),
                    };
                    tabs.push(tab);
                  }
                  _props.tabs = tabs;
                  _props.group = Group;
                  componentBaseProps = { ..._props };
                  componentBaseProps.id = componentBaseProps.id + '-group';
                  componentBaseProps.layout = {
                    $: cur[key][i].$,
                  };
                  componentBaseProps.layout = { ...componentBaseProps.layout, ...cur[key][i].tabpage[0] };
                  _props.groupProps = XT._getComponents_getTabGroupProps(componentBaseProps, props);
                  // _props = undefined;
                } else {
                  let tab = cur[key][i];
                  let tabPages = tab.tabpage;
                  let tabs: TabProps[] = [];
                  for (let i in tabPages) {
                    let tabPage = tabPages[i];
                    let vConstraint = new Constraint(tabPage.$.visibleconstraints);
                    let tab: TabProps = {
                      active: tabPage.$.default === 'always',
                      visible: tabPage.$.visible !== 'false' && vConstraint.evaluate(flags, true),
                      forward_actions: tabPage.forward_actions?.map((x: any) => x.action[0].$),
                      back_actions: tabPage.back_actions?.map((x: any) => x.action[0].$),
                      activate_actions: tabPage.activate_actions?.map((x: any) => x.action[0].$),
                      id: tabPage.$.id,
                      title: tabPage.$.text,
                    };
                    if (tab.visible) tabs.push(tab);
                  }
                  _props.tabs = tabs;
                  _props.group = Group;
                  componentBaseProps = { ..._props };
                  componentBaseProps.id = componentBaseProps.id + '-group';
                  componentBaseProps.layout = {
                    $: cur[key][i].$,
                  };
                  let currentTab = cur[key][i].tabpage.find((x: any) => x.$.default === 'always');
                  componentBaseProps.layout = { ...componentBaseProps.layout, ...currentTab };
                  _props.groupProps = XT._getComponents_getTabGroupProps(componentBaseProps, props);
                  // _components.push({
                  //   componentName: key,
                  //   componentActive: componentActive,
                  //   componentLayout: cur[key][i],
                  //   componentBaseProps: componentBaseProps,
                  //   layout: cur[key],
                  //   key: _props.id,
                  //   component: ComponentMap[key],
                  //   props: _props
                  // });
                  // _props = undefined;
                }
                break;
              default:
                break;
            }
            if (!!_props) {
              _components.push({
                componentName: key,
                componentActive: componentActive,
                componentLayout: cur[key][i],
                componentBaseProps: componentBaseProps,
                layout: cur[key],
                key: _props.id,
                component: ComponentMap[key],
                props: _props,
              });
            }
          }
        } else {
          for (let i in cur[key]) {
            recurse(cur[key], i);
          }
        }
      } else if (Object(cur[key]) === cur[key]) {
        for (let k in cur[key]) {
          recurse(cur[key], k);
        }
      }
    };
    recurse(props.layout, '');
    // let titles = _components.find((c: any) => c.componentName === 'titles');
    // _components[0] = titles;
    if (_components.findIndex((c: any) => c.componentName === 'table') > 0)
      _components[0] = _components.splice(
        _components.findIndex((c: any) => c.componentName === 'table'),
        1,
        _components[0],
      )[0];
    return _components;
  },
  _getComponents_getTableLoadMore: (tableProps: any, parentProps: any) => {
    return tableProps.attributes.autopagedown === 'false' || parentProps.windowProps.data.form.$.id === 'QueryResult'
      ? undefined
      : parentProps.loadMore;
  },
  _getComponents_getTableContextOptions: (tableProps: any, tableLayout: any, parentProps: any, flags: any) => {
    let result: {
      contextOptions: ContextOptions[];
      defaultActions: Action[] | undefined;
    } = {
      contextOptions: [[]],
      defaultActions: undefined,
    };

    let menu = parentProps.windowProps.layout.screen.form[0].contextmenu?.find((menu: any) => {
      return menu.$.id === tableProps?.attributes.contextmenu;
    });
    let selectionColumn = tableLayout['column'].find((x: any) => x.$.selectioncolumn === 'true');
    //if (menu?.$?.parser === 'OptionParser') {
    //} else {
    if (menu)
      result.contextOptions = [
        ((menu && menu.items ? menu.items[0] : menu.item ? menu : null) || { item: [] }).item
          .map((item: any, index: number) => {
            let label = XT.getDynamicValue(parentProps.windowProps, item.$.text);
            let action: Action = (item.action && item.action[0].$) || { command: 'ENTER' };
            let _type = (item.action && (item.action[0].$.option ? 'option' : 'command')) || 'option';
            let value =
              (item.action && (item.action[0].$.option || item.action[0].$.parameter || item.action[0].$.command)) ||
              item.$.id;
            action.name = action.name || 'FieldBasedCommandAction';
            action.field = action.field || selectionColumn?.$.id || 'local';
            action.command = action.command || 'ENTER';
            action.event = action.event || action.command || 'ENTER';
            if (
              !label &&
              parentProps.windowProps.contextDefinition &&
              parentProps.windowProps.contextDefinition[parentProps.windowProps.data.form.$.id]?.[tableProps?.id]
            ) {
              label =
                parentProps.windowProps.contextDefinition[parentProps.windowProps.data.form.$.id]?.[tableProps?.id][
                  item.$.id
                ];
              value = item.$.id;
              action.option = item.$.id;
            }
            if (item.$.factoryaction === 'true') {
              _type = 'command';
              value = 'F06';
              action = {
                command: 'F06',
                event: 'F06',
                name: 'RemoteCommand',
                text: label,
              };
            }
            if (item.$.disablable === 'false' && ['ENTER', 'DEFAULT', 'DELETE'].indexOf(action.command || '') === -1)
              _type = 'command';
            if (action.name === 'RemotePrompt') {
              action.field = tableLayout.$?.rowfield;
            }

            let vConstraint = new Constraint(item.$.visibleconstraints);
            let visible = vConstraint.evaluate(flags, true);

            return {
              id: item.$.id,
              key: selectionColumn?.$.id || 'local',
              type: _type,
              value: action?.overrideSQL === 'true' ? value + '_' + index : value,
              label: label,
              visible: visible,
              action: action,
            };
          })
          .filter((item: any) => !!item.visible)
          .filter((item: any) => {
            return (
              (!!item.action &&
                isNaN(+item.id) &&
                !parentProps.windowProps.contextDefinition?.[parentProps.windowProps.data.form.$.id]?.[
                  tableProps?.id
                ]?.[item.value]) ||
              Object.keys(
                parentProps.windowProps.contextDefinition?.[parentProps.windowProps.data.form.$.id]?.[tableProps?.id] ||
                  {},
              ).indexOf(item.id) > -1
            );
          }),
      ];
    else result.contextOptions = [[]];
    // attrs.disableActionOn = selectionColumn?.$?.disableconstraints;
    // _props.attributes.selectionColumn = selectionColumn?.$?.id;
    if (
      parentProps.windowProps.contextDefinition &&
      !!menu &&
      parentProps.windowProps.contextDefinition[parentProps.windowProps.data.form.$.id]?.[tableProps?.id]
    ) {
      // result.contextOptions[0] = [];
      Object.keys(
        parentProps.windowProps.contextDefinition[parentProps.windowProps.data.form.$.id]?.[tableProps?.id],
      ).forEach((k: string) => {
        let option =
          parentProps.windowProps.contextDefinition[parentProps.windowProps.data.form.$.id]?.[tableProps?.id][k];
        if (!result.contextOptions[0]?.find((x: any) => x.id === k)) {
          result.contextOptions[0].push(
            {
              id: k,
              key: selectionColumn?.$.id || 'local',
              type: 'option',
              value: k,
              label: option,
              visible: true,
              action: {
                event: 'ENTER',
                name: 'FieldBasedCommandAction',
                command: 'ENTER',
                option: k,
              },
            } as any, // Remark: without any TS complaints about visible property (NTH_SKE: check if visible really needed)
          );
        }
      });
    }

    result.contextOptions[0] = Array.from(
      new Set(result.contextOptions[0].map((x: ContextOption) => `${x.value}||${x.action?.command ?? 'ENTER'}`)),
    ).map(
      (option: string) =>
        result.contextOptions[0].find((a: ContextOption) => `${a.value}||${a.action?.command ?? 'ENTER'}` === option)!,
    );

    result.defaultActions = tableLayout?.action?.map((a: any) => a.$);

    //applicationLinks taken from json file - data dependent on contents
    //could be object or array - always check it and all transform to array
    let applicationLinks = XT.applicationLinks?.forms?.filter((form: any) => form.id === tableProps?.formID);
    const applicationLinksArray = Array.isArray(applicationLinks)
      ? applicationLinks
      : applicationLinks
        ? [applicationLinks]
        : [];

    for (let appLink of applicationLinksArray) {
      result.contextOptions.push([]);
      const panelsFromConfig = appLink.panel.filter((panel: any) => panel.id === tableProps?.panelID);
      const panelsArray = Array.isArray(panelsFromConfig) ? panelsFromConfig : [panelsFromConfig];

      for (let panel of panelsArray) {
        if (panel && panel.control === tableLayout.$?.id) {
          let application = XT.applicationLinks?.applications?.find((app: any) => app.id === panel.application);
          if (application) {
            let launcher = application.launcher.find((launch: any) => launch.id === panel.launcher);
            if (launcher) {
              let launcherKey = launcher.parameter;

              let panelVariableArray = Array.isArray(panel.variable) ? panel.variable : [panel.variable];
              for (let variable of panelVariableArray) {
                launcherKey = launcherKey.replace(variable.id, `|${variable.replacementValueHolder}|`);
              }
              //?is there any other param apart 1,2,.. and company????
              if (launcherKey.includes('|company|')) {
                launcherKey = launcherKey.replace(
                  '|company|',
                  parentProps.windowProps.settings.regionals.user[0].$.activeCompany,
                );
              }

              let option: ContextOption = {
                isDefault: false,
                key: launcherKey,
                label: XT.getDynamicValue(parentProps.windowProps, launcher.text || application.text),
                value: panel.launcher,
                id: application.id,
                type: 'application',
              };
              result.contextOptions[1].push(option);
            }
          }
        }
      }
    }

    let actions = tableLayout.action;
    if (actions && Array.isArray(actions) && actions.length > 0) {
      for (let i in actions) {
        let action = actions[i];
        let constraint = new Constraint(action.$.disableconstraints);
        if (!constraint.evaluate(flags, false) && ['enter', 'default'].indexOf(action.$.event?.toLowerCase()) > -1) {
          let option = result.contextOptions[0].find((x: ContextOption) => x.value === action.$.option);
          if (option) {
            option.isDefault = true;
            break;
          }
          break;
          // if (result.contextOptions[0].length === 1) {
          //   result.contextOptions[0][0].isDefault = true;
          //   break;
          // }
        }
      }
    }
    result.contextOptions[0].sort((a: any, b: any) => +a.id - +b.id);
    // result.contextOptions[0] = result.contextOptions[0].map((item: ContextOption) => {
    //   if (actions && Array.isArray(actions) && actions.length > 0) {
    //     for(let i in actions) {
    //
    //     }
    //   }
    //   return item;
    // });

    return result;
  },
  _getComponents_getButtonActive: (buttonProps: any, parentProps: any) => {
    if (
      buttonProps?.id !== 'CloseButton' &&
      buttonProps.action &&
      buttonProps.action.event &&
      ['ENTER', 'DEFAULT'].indexOf(buttonProps.action.event) === -1
    )
      if (!parentProps.panelEvents[buttonProps.action.event]) return false;
    return true;
  },
  _getComponents_getTabGroupProps: (componentBaseProps: any, parentProps: any) => {
    let _gprops: Record<string, any> = { ...parentProps, ...componentBaseProps };
    delete _gprops.tabs;
    delete _gprops.updateTab;
    _gprops.isPanel = false;
    _gprops.onContextMenuClick = parentProps.contextMenuHandler;
    _gprops.contextMenuHandler = parentProps.contextMenuHandler;
    _gprops.actionHandler = parentProps.actionHandler;
    _gprops.promptHandler = parentProps.promptHandler;
    return _gprops;
  },
  _getComponents_getGroupProps: (componentBaseProps: any, componentLayout: any, parentProps: any) => {
    const resultingProps = { ...parentProps, ...componentBaseProps };
    // resultingProps.attributes.dirtyflag = componentBaseProps.attributes.dirtyflag || parentProps.layout.$.dirtyflag;
    resultingProps.id = componentLayout.$.id;
    resultingProps.layout = componentLayout;
    resultingProps.isPanel = false;
    resultingProps.onContextMenuClick = parentProps.contextMenuHandler;
    resultingProps.actionHandler = parentProps.actionHandler;
    resultingProps.promptHandler = parentProps.promptHandler;
    resultingProps.loadMore = parentProps.loadMore;
    return resultingProps;
  },

  getIcon: (imageName: string) => {
    switch (imageName) {
      case 'flag_22': //FLAG
        return Icons.Flag;
      case 'text_doc_22': //TXT extension
        return Icons.Doc;
      case 'pdf_doc_22': //PDF
        return Icons.Pdf;
      case 'word_doc_22': //DOC
        return Icons.WordDocument;
      case 'excel_doc_22': //XLS
        return Icons.ExcelDocument;
      case 'archive_22': //ZIP, RAR
        return Icons.Zip;
      case 'xml_doc_22': //XML
        return Icons.XmlDoc;
      case 'html_doc_22': //HTM
        return Icons.HtmlDoc;
      case 'misc_doc_22':
        return Icons.Doc;
      default:
        return undefined;
    }
  },
  loadContextMenu: (props: ComponentBaseProps) => {
    if (!!props.layout.table) {
      for (let i in props.layout.table) {
        let table = props.layout.table[i];
        let contextMenu = props.windowProps.layout.screen.form[0].contextmenu?.find(
          (menu: any) => menu.$.id === table.$.contextmenu,
        );
        if (contextMenu && contextMenu.$.parser === 'OptionParser') {
          //Dynamic menu
          let action = contextMenu.action?.[0].$.command;

          let dConstraint = new Constraint(contextMenu.action?.[0].$.disableconstraints);
          if (dConstraint.evaluate(props.flags || [], false)) return;
          let fields = contextMenu.fields?.[0].field.map((f: any) => f.$);
          let firstFields: { id: string; value: string }[] = [];
          for (let j in fields) {
            let field = fields[j];
            let vConstraint = new Constraint(field.visibleconstraints);
            if (vConstraint.evaluate(props.flags || [], true))
              firstFields.push({
                id: field.name,
                value: XT.getValueFromWindow(props.windowProps, field.panel, field.name),
              });
          }
          props.contextLoader(
            action,
            fields,
            props.windowProps.data.form.$.id,
            table.$.id,
            contextMenu.$.id,
            firstFields,
          );
        }
      }
    }
  },
  loadFunctionData: (props: WindowProps, panelIDs: string[]) => {
    let actions: Action[] = [];
    let loaderAction: Action | null = null;
    let template: Action | null = null;
    if (!!props.layout.screen.form[0].actionstext) {
      let actionstext = props.layout.screen.form[0].actionstext[0];
      let fields = actionstext.fields?.[0]?.field
        ?.map((f: any) => f.$)
        .filter((f: any) => panelIDs.indexOf(f.panel) > -1);
      let loader = actionstext.loader?.[0];
      loaderAction = loader?.action[0].$;
      template = actionstext.item_template?.[0].action?.[0].$;
      for (let i in fields) {
        let field = fields[i];
        let value = XT.getValueFromWindow(props, field.panel, field.name);
        let parsedActions: string[] = value.match(/F\d+=([a-zA-Z]+\s)+/g) || [];
        for (let j in parsedActions) {
          let parsedAction = parsedActions[j].trimEnd().split('=');
          let action: Action = { event: parsedAction[0], text: parsedAction[1] };
          actions.push(action);
        }
      }
    }
    return { actions: actions, loader: loaderAction, template: template };
  },
  getValue: (props: ComponentBaseProps, id: string) =>
    props.data.ctrl?.filter((data: any) => data['$'].id === id)[0]?.$?.value,
  getValueFromWindow: (props: { data: Record<string, any> }, panelID: string, id: string) => {
    let value = '';
    let panels = props.data.form.panel.filter((p: any) => p.$.id === panelID);
    if (panels && panels.length > 0) {
      let panel = panels[panels.length - 1];
      let _value = panel?.ctrl?.find((c: any) => c.$.id === id);
      if (!!_value) {
        return _value?.$?.value || '';
      }
    }
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let _value = panel.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
      if (!!_value) {
        value = _value.$.value;
      }
    }
    return value;
  },
  getValueFromWindowRow: (props: { data: Record<string, any> }, panelID: string, rowID: string, id: string) => {
    let value = undefined;
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let row = panel.row?.find((row: any) => row.$.id === rowID);
      if (row) {
        let _value = row.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
        if (!!_value) {
          value = _value.$.value;
        }
      }
    }
    return value;
  },
  getValueFromWindowRowForAttachments: (
    props: { data: Record<string, any> },
    panelID: string,
    rowID: string,
    id: string,
  ) => {
    let value = [];
    const dataSeparator = '@@££$$';
    const idParts = id.split(',');
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let row = panel.row?.find((row: any) => row.$.id === rowID);
      if (row) {
        for (const idPart of idParts) {
          const _value = row.ctrl?.find((data: any) => data.$.id === idPart);
          if (!!_value) {
            value.push(_value.$.value.trim());
          }
        }
      }
    }
    return value.join(dataSeparator);
  },
  getReloadedFromWindow: (props: { data: Record<string, any> }, panelID: string, id: string) => {
    let value: boolean | undefined = undefined;
    let panels = props.data.form.panel.filter((p: any) => p.$.id === panelID);
    if (panels && panels.length > 0) {
      let panel = panels[panels.length - 1];
      let _value = panel?.ctrl?.find((c: any) => c.$.id === id);
      if (!!_value) {
        return _value?.$?.reloaded;
      }
    }
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let _value = panel.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
      if (!!_value) {
        value = _value.$.reloaded;
      }
    }
    return value;
  },
  getReloadedFromWindowRow: (props: { data: Record<string, any> }, panelID: string, rowID: string, id: string) => {
    let value: boolean | undefined = undefined;
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let row = panel.row?.find((row: any) => row.$.id === rowID);
      if (row) {
        let _value = row.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
        if (!!_value) {
          value = _value.$.reloaded;
        }
      }
    }
    return value;
  },
  getColumns: (cols: any[]) => {
    let columns: any[] = [];
    if (cols?.length > 0) {
      cols?.[0]?.column?.forEach((col: any) => {
        columns.push({
          name: col.$.name ?? col.$.text,
          accessor: col.$.id,
          Header: col.$.text,
          ...col.$,
          width: col.$.width * 5,
          visible: 'true',
        });
      });
    }
    return columns;
  },

  getReloadedRowFromWindowRow: (props: { data: Record<string, any> }, panelID: string, rowID: string, id: string) => {
    let value: boolean | undefined = undefined;
    for (let i in props.data.form.panel) {
      let panel = props.data.form.panel[i];
      let row = panel.row?.find((row: any) => row.$.id === rowID);
      if (row) {
        if (panel.$.reloaded !== 'false') {
          // reloaded is absent => newly loaded panel, false => panel not to be refreshed, true => panel to be refreshed
          return true;
        } else if (row.$.reloaded) {
          // true|false => whether any control in row is reloaded
          return true;
        }
        let _value = row.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
        if (!!_value) {
          value = _value.$.reloaded;
        }
      }
    }
    return value;
  },
  getRows: (
    props: { data: Record<string, any>; windowProps: WindowProps },
    columns: any[],
    attrs: Record<string, any>,
  ) => {
    let columnIds = columns.map((x: any) => (!!x.split ? x.split_id : x.id));
    let rowCondition = attrs.rowhandlercondition;
    let rowValueColumn = attrs.userdata1;
    let rowConditionColumn: string, rowConditionValue: string;
    if (rowCondition) {
      [rowConditionColumn, rowConditionValue] = rowCondition.split('=');
    }

    let _rows: any[] = [];

    props.data.row?.forEach((row: any) => {
      let selectableConstraint = new Constraint(attrs.disableActionOn);
      let selectable = !selectableConstraint.evaluate(
        (row.$.flags || '').split('').map((x: string) => x === '1'),
        false,
      );
      let _row: Record<string, string | boolean> = {
        id: row.$.id,
        flags: row.$.flags,
        highlight: row.$.highlight,
        selectable: selectable,
        reloaded: row.$.reloaded,
      };
      row.ctrl
        .filter((col: any) => columnIds.indexOf(col['$'].id) > -1)
        .forEach((v: any) => {
          let column = columns.find((col: any) => (!col.split ? col.id === v.$.id : col.split_id === v.$.id));
          if (column.split) {
            let splits = columns.filter((col: any) => col.split_id === v.$.id);
            splits.forEach(
              (col: any) =>
                (_row[col.id] =
                  col.formatter === 'RemoveTrailingDots'
                    ? v.$.value.substring(col.from, col.to).replace(/\.*$/g, '').trimEnd()
                    : v.$.value.substring(col.from, col.to).trimEnd()),
            );
          } else {
            _row[v.$.id] = v.$.value.trimEnd();
          }
        });
      if (rowCondition) {
        let ctrl = row.ctrl.find((x: any) => x.$.id === rowConditionColumn)?.$;
        if (ctrl) {
          if (ctrl.value === rowConditionValue) {
            let valueCtrl = row.ctrl.find((x: any) => x.$.id === rowValueColumn)?.$;
            if (valueCtrl && _rows.length > 0)
              _rows[_rows.length - 1].rowConditionText = [
                ...(_rows[_rows.length - 1].rowConditionText || []),
                valueCtrl.value,
              ];
            return;
          }
        }
      }
      let column = columns.find((col: any) => col.contenttype === 'image');
      if (column?.contenttype === 'image') {
        for (const idx in column.images) {
          const image = column.images[idx];
          const visibleConstraint = new Constraint(image?.$?.visibleconstraints);
          if (
            visibleConstraint.evaluate(
              (row.$.flags || '').split('').map((x: string) => x === '1'),
              true,
            )
          ) {
            _row[column.id] = XT.getDynamicValue(props.windowProps, image?.$?.id, _row.id);
            break;
          }
        }
      }
      _rows.push(_row);
    });
    return _rows;
  },
  getDynamicValue: (props: WindowProps, key: string = '', rowId?: string) => {
    let value = '' + key;
    let matches = key.match(/\|.*?\|/g);
    if (matches) {
      for (let i in matches) {
        let match = matches[i];
        let _match = match.replaceAll('|', '');
        let [panel, field] = _match.split('.');
        let part = '';
        if (panel === '*RSC') {
          part = Localization.instance.getString(field);
        } else {
          if (!!rowId) {
            part = XT.getValueFromWindowRow(props, panel, rowId, field || panel);
          } else {
            part = XT.getValueFromWindow(props, panel, field || panel);
          }
        }
        value = value.replace(match, part || '');
      }
    }
    return value;
  },
  getCommands(layout: Record<string, any>, data: Record<string, any>) {
    let commands: any[] = [];
    const findObjectAndParents = (item: any, key: string): boolean => {
      if (item && item[key]) {
        commands.push(item[key]);
        return true;
      }
      if (Array.isArray(item) || Object(item) === item) {
        for (let k in item) {
          findObjectAndParents(item[k], key);
        }
      }
      return false;
    };
    findObjectAndParents(layout.screen, 'command');
    // let actions: any[] = layout.screen.form.filter()
    // actions = [...actions, ...(layout.screen.form[0].actions[0]?.action?.map((x: any) => x.$) || [])];
    // actions.sort((a: any, b: any) => {
    //   if (a.text > b.text) return 1;
    //   else if (a.text < b.text) return -1;
    //   else return 0;
    // });
    return Array.from(new Set(commands));
  },
  searchPanel(panel: Record<string, any>, key: string) {
    let commands: any[] = [];
    const findObjectAndParents = (item: any, key: string): boolean => {
      if (item && item[key]) {
        commands.push(item[key]);
        return true;
      }
      if (Array.isArray(item) || Object(item) === item) {
        for (let k in item) {
          findObjectAndParents(item[k], key);
        }
      }
      return false;
    };
    // let temp = { ...this.props.layout.screen.form[0] };
    // delete temp.panel;
    findObjectAndParents(panel, key);
    return commands;
  },

  getScrollInfo(ref: any) {
    let window = ref.current.closest('.panel-area') as HTMLElement;

    let nBlocks = window?.scrollWidth / 15; // panel-area

    let x = ~~(ref.current.getBoundingClientRect().left / 15);
    let y = ~~((nBlocks * ref.current.getBoundingClientRect().top) / 15);
    return { x, y };
  },
  resetScrolling() {
    if (document.querySelectorAll('.window.active .modal-dialog').length > 0) {
      document.querySelectorAll('.window.active .modal-dialog *:not(.data-table:not(.fill-grid))').forEach((x) => {
        x.scrollTop = 0;
        x.scrollLeft = 0;
      });
    } else {
      document.querySelectorAll('.window.active *:not(.data-table:not(.fill-grid))').forEach((x) => {
        x.scrollTop = 0;
        x.scrollLeft = 0;
      });
    }
  },
  getFocusedElementActiveWindow(previous: Element | null): Element | null {
    let element: Element | null = null;

    // Only consider element if in the active window and in ...
    // ========================================================

    // --> ... panel-area                                                     // HTH_SKE: combination tests with context-area
    if (document.activeElement?.closest('.window.active .panel-area')) {
      element = document.activeElement;
      if (element.id === 'no-data-id') {
        element = element.closest('.data-table-span'); //                     Remark: have to save span here as div 'no-data-id' has no parent anymore at restore time
      } else if (element.classList.contains('rdg-text-editor')) {
        element = element.closest('.data-table-span'); //                     Remark: have to save span here as editor has sometimes no parent anymore at restore time (every two times ??? NTH_SKE: figure out why)
      }

      // --> ... button-bar
    } else if (document.activeElement?.closest('.window.active .button-bar')) {
      element = document.activeElement;
    }
    return element || previous;
  },
  restoreFocusActiveWindow(element: Element | null) {
    if (element) {
      // --> get data table parent span (only available for table)
      const dataTableSpan = element.closest('.data-table-span');

      // --> restore focus
      if (dataTableSpan) {
        (dataTableSpan as any).directFocus = true;
        (dataTableSpan as any).focus({ preventScroll: true });
      } else if (typeof (element as any).focus === 'function') {
        (element as any).focus();
      }
    }
  },
  selectNextElement() {
    const dispatchKeypressTabEvent = () => {
      document.activeElement?.dispatchEvent(
        new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          key: 'Tab',
        }),
      );

      document.body.dispatchEvent(
        new KeyboardEvent('keyup', {
          bubbles: true,
          cancelable: true,
          key: 'Tab',
        }),
      );

      /*
        IMPORTANT REMARKS:
          ° Be aware that the keyup event is also needed! The keydown event may seem to be sufficient as this triggers the
            needed functionality (through execution of desktop.handleKeyDown(event)). However the keydown event is also
            captured by the hotkeys-js component. The keyup event is needed there to remove the tab key from the "keys down"
            variable. Keydown without keyup will for instance result in a failure of the prompting functionality: the application
            will assume you are not only pressing keyboard button <F4> but also keyboard button <Tab> and as a result it will
            no longer execute the hotkeys handler defined in window.componentDidMount() as this one is configured to handle
            'F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,ENTER,PAGEDOWN,PAGEUP,ALT+F1,UP,DOWN,ALT+T' + enqKeys, NOT to handle TAB+F4.
            It took me hours to figure this out :-(
          ° keyup event is dispatched on document.body, NOT on the current reference of the edit control! This is done because
            when you dispatch the keydown event on an editable cell in a table, the corresponding edit control will be removed
            from the DOM and the capturing/bubbling cycle is then broken for that control (as a result the keyup is then no longer
            captured by the hotkeys-js component and this would result in same issues as described in previous remark).
      */
    };
    if (document.activeElement) dispatchKeypressTabEvent();
  },
  getFieldInfoFromWindowData(
    windowData: any,
    fieldId: string,
    panelId?: string, // OPTIONAL
  ): { fieldId: string; panelId: string; value: string } | undefined {
    // Skip nonsense
    if (!windowData) return undefined;

    // Get panels
    const panels: any[] = windowData.form?.panel;
    if (!panels || !panels.length) return undefined;
    if (panels.length <= 0) return undefined;

    // Helper function
    const getFieldInfo = (
      panelData: any, //NOT OPTIONAL
      fieldId: string,
    ): { fieldId: string; panelId: string; value: string } | undefined => {
      const panelId = panelData?.$?.id;
      if (!panelId) return undefined;

      const controls: any[] = panelData.ctrl;
      if (!controls || !controls.length) return undefined;
      if (controls.length <= 0) return undefined;

      const control = controls.find((control: any) => control.$?.id === fieldId);
      if (!control) return undefined;

      return { fieldId, panelId, value: control.$?.value };
    };

    // Get info
    if (panelId) {
      const panel = panels.find((panel: any) => panel.$?.id === panelId);
      return getFieldInfo(panel, fieldId);
    } else {
      for (const panel of panels) {
        const info = getFieldInfo(panel, fieldId);
        if (info) return info;
      }
      return undefined;
    }
  },
  getActualFieldInfo(
    command: Command,
    windowData: any,
    fieldId: string,
    panelId?: string,
  ): { fieldId: string; panelId: string; value: string } | undefined {
    let fieldInfo: { fieldId: string; panelId: string; value: string } | undefined;
    if (command) fieldInfo = command.getFieldInfo(fieldId, panelId);
    if (!fieldInfo) fieldInfo = XT.getFieldInfoFromWindowData(windowData, fieldId, panelId);
    return fieldInfo;
  },
  stringifyDataPanels(windowData: Record<string, any>): string {
    // Skip nonsense
    if (!windowData) return '';

    // Get panels
    const panels: any[] | undefined = windowData.form?.panel;
    if (!panels || !panels.length) return '';
    if (panels.length <= 0) return '';

    // Stringify: comma separated list
    return panels.reduce(
      (result: string, panel: any) => (!result ? panel.$?.id || '' : panel.$?.id ? result + ', ' + panel.$.id : result),
      '',
    );
  },
  getPanelLayouts(panelId: string, xtFormLayout: any): Record<string, any>[] {
    /*
      IMPORTANT REMARK: the xt form layout can contain MULTIPLE parts describing a single panel
      layout when tab controls are involved. Controls of a single panel can be defined outside
      any tab ("header fields"), but the same panel can also have controls inseide a tabpage
      ("detail field"), e.g. DMR307D
    */
    // Initialize
    // ==========
    const panelLayouts: Record<string, any>[] = [];

    // Skip nonsense
    // =============
    if (!xtFormLayout?.screen?.form) return panelLayouts;
    if (xtFormLayout.screen.form.length < 1) return panelLayouts;

    // Get panel layouts
    // =================
    const getLayoutsRecursive = (layoutExtract: any, panelId: string, componentType: string) => {
      if (!layoutExtract) {
        // Handle case undefined
        // ---------------------
        return;
      } else if (Array.isArray(layoutExtract)) {
        // Handle case layout extract is an array
        // --------------------------------------
        for (const arrayElement of layoutExtract) {
          getLayoutsRecursive(arrayElement, panelId, componentType);
        }
        return;
      } else if (Object(layoutExtract) === layoutExtract) {
        // Handle case layout extract is an object
        // ---------------------------------------

        // --> case panel object
        if (componentType === 'panel') {
          if (layoutExtract.$?.id === panelId) {
            panelLayouts.push(layoutExtract);
          } else {
            return;
          }
        }

        // --> case other object: drilldown
        for (const _key in layoutExtract) {
          const key = _key.toLocaleLowerCase();

          // only interested in panel and possible containers
          if (!['form', 'panel', 'tab', 'tabpage'].includes(key)) continue;

          // drilldown
          getLayoutsRecursive(layoutExtract[key], panelId, key);
        }
        return;
      } else {
        // primitive value reached              Remark: not really expected (infinite lopp protection for invalid layouts)
        // -----------------------
        return;
      }
    };
    getLayoutsRecursive(xtFormLayout.screen.form[0], panelId, 'form');

    // Return result
    // =============
    return panelLayouts;
  },
  getFieldComponentLayoutInfo(
    fieldId: string,
    panelId: string,
    xtFormLayout: any,
  ): { componentType: string; componentLayout: Record<string, any>; parentLayout: Record<string, any> } | undefined {
    // Get panel layout
    // ================
    const panelLayouts: Record<string, any>[] = XT.getPanelLayouts(panelId, xtFormLayout);
    if (!panelLayouts || panelLayouts.length < 1) return undefined;

    // Initialize
    // ==========
    const fieldComponentTypes = ['checkbox', 'combo', 'date', 'edit', 'editprompt', 'label', 'radio', 'time'];
    const containerComponentTypes = ['group', 'panel', 'tab', 'tabpage'];
    const getLayoutInfoRecursive = (
      layoutExtract: any,
      fieldId: string,
      componentType: string,
      parentLayout: Record<string, any>,
    ):
      | { componentType: string; componentLayout: Record<string, any>; parentLayout: Record<string, any> }
      | undefined => {
      if (!layoutExtract) {
        // Handle case undefined
        // ---------------------
        return undefined;
      } else if (Array.isArray(layoutExtract)) {
        // Handle case layout extract is an array
        // --------------------------------------
        for (const arrayElement of layoutExtract) {
          const layoutInfo = getLayoutInfoRecursive(arrayElement, fieldId, componentType, parentLayout);
          if (layoutInfo) return layoutInfo;
        }
        return undefined;
      } else if (Object(layoutExtract) === layoutExtract) {
        // Handle case layout extract is an object
        // ---------------------------------------

        // --> case component binded to field
        if (fieldComponentTypes.includes(componentType)) {
          if (layoutExtract.$?.id === fieldId) {
            return { componentType, componentLayout: layoutExtract, parentLayout };
          } else {
            return undefined;
          }
        }

        // --> special case "radiogroup'" binded to field
        if (componentType === 'group' && layoutExtract?.$?.radiogroup?.toLowerCase?.() === 'true') {
          if (layoutExtract.$?.id === fieldId) {
            return { componentType: 'radiogroup', componentLayout: layoutExtract, parentLayout };
          } else {
            return undefined;
          }
        }

        // --> case other object: drilldown
        for (const _key in layoutExtract) {
          const key = _key.toLocaleLowerCase();

          if (fieldComponentTypes.includes(key)) {
            // drilldown to component binded to field
            const layoutInfo = getLayoutInfoRecursive(layoutExtract[key], fieldId, key, layoutExtract);
            if (layoutInfo) return layoutInfo;
          } else if (containerComponentTypes.includes(key)) {
            // drilldown to possible container compoonents
            const layoutInfo = getLayoutInfoRecursive(layoutExtract[key], fieldId, key, layoutExtract);
            if (layoutInfo) return layoutInfo;
          } else {
            // skip other (eg contextmenu, titles, actions, button, ...)
          }
        }
        return undefined;
      } else {
        // primitive value reached              Remark: not really expected (infinite lopp protection for invalid layouts)
        // -----------------------
        return undefined;
      }
    };

    // Get layout info of component binded to a field
    // ==============================================
    for (const panelLayout of panelLayouts) {
      const info = getLayoutInfoRecursive(panelLayout, fieldId, 'panel', panelLayout);
      if (info) return info;
    }

    return undefined;
  },
  convertValueFromServerToAperioFormat(
    fieldInfo: { fieldId: string; panelId: string; value: string },
    componentType: string,
    attributes: Record<string, any>,
    outputType: AperioViews.FieldTypes,
    regionalSettings: Record<string, any>,
    inColumnTableMode: boolean = false,
  ): { value: string | number | boolean | undefined; error: string | undefined; warning: string | undefined } {
    // Internal functions
    // ==================
    const getResultingMask = (attributes: Record<string, any>, componentType: string): string => {
      if (['date', 'time', 'checkbox', 'radio'].includes(componentType)) return componentType;

      let mask = attributes.mask;
      if (!attributes.mask && attributes.formatter === 'FormattedNumeric') mask = 'numeric';
      if (
        attributes.formatter === 'PositiveIntegerWithZeros' ||
        (attributes.formatter === 'FormattedNumericAllowZeros' && mask === 'positiveinteger')
      )
        mask = 'positiveinteger_with_zeros';
      if (attributes.mask === 'delimitedquantity') {
        mask = 'decimal';
      } else if (attributes.formatter?.toLowerCase() === 'time') mask = 'time';
      if (attributes.formatter === 'PadZerosOrShowEmpty') mask = 'positiveinteger';
      if (attributes.formatter === 'FormattedNumericAllowZeros' && mask === 'decimal') mask = 'decimal_with_zeros';
      if (!mask && componentType === 'label') return componentType;
      return mask || '';
    };
    const getServerDecimalSeparator = (attributes: Record<string, any>, decimalsign: string): string => {
      return XT.isCommaAlwaysServerDecimalSeparator(attributes?.formatter, attributes?.mask) ? ',' : decimalsign;
    };

    // Initialization
    // ==============
    const dateFormattingServerInfo = regionalSettings?.dateformats?.[0]?.$;
    let { decimalsign, no, yes } = regionalSettings?.codes?.[0]?.$ || { decimalsign: '.', no: 'N', yes: 'Y' };

    const fieldId = fieldInfo.fieldId;
    const panelId = fieldInfo.panelId;
    let value = (fieldInfo.value || '').trimEnd();

    const valueInfo: {
      value: string | number | boolean | undefined;
      error: string | undefined;
      warning: string | undefined;
    } = {
      value: undefined,
      error: undefined,
      warning: undefined,
    };

    // Step 1: convert to formatted string
    // ====================================
    if (attributes.formatter === 'RemoveTextLimitMarker') {
      value = value.replace(']', '').trimEnd();
    }

    if (attributes.formatter === 'RemoveTrailingDots') {
      value = new RemoveTrailingDotsFormatter().in(value);
    }

    const mask = getResultingMask(attributes, componentType);
    switch (mask) {
      case 'positiveinteger':
      case 'positiveinteger_with_zeros':
      case 'decimal':
      case 'positivedecimal':
      case 'integer':
      case 'numeric':
      case 'decimal_with_zeros':
        // --> Initialize
        const decimalSeparator = getServerDecimalSeparator(attributes, decimalsign);
        const groupSeparator = decimalSeparator === '.' ? ',' : '.';

        // --> Remove all whitespaces
        value = value.replaceAll(' ', '');

        // --> Replace trailing with leading minus sign
        if (value.endsWith('-')) {
          value = `-${value.replaceAll('-', '')}`;
        }

        // --> Remove group separators
        value = value.replaceAll(groupSeparator, '');

        // --> Replace decimal separator with decimal point
        if (decimalSeparator !== '.') value = value.replace(decimalSeparator, '.');

        break;
      case 'date':
        value = Formatters.Date.convertValueFromServerFormatToClientFormat(
          value,
          dateFormattingServerInfo,
          'yyyy-MM-dd',
        );
        break;
      case 'time':
        const time = Formatters.Time.in(value.trim(), +(attributes?.limit ?? '0'));
        value = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
        break;
      case 'checkbox':
      case 'radio': //Remark "radiogroup" is handled seperately
        const _value = value.trim().toUpperCase();
        if (_value === attributes?.value?.toUpperCase?.()) {
          value = 'true';
        } else if (_value === attributes?.deselctedvalue?.toUpperCase?.().trim?.()) {
          value = 'false';
        } else if (_value === attributes?.deselectedvalue?.toUpperCase?.().trim?.()) {
          value = 'false';
        } else if (_value === yes?.toUpperCase?.()) {
          value = 'true';
        } else if (_value === no?.toUpperCase?.()) {
          value = 'false';
        } else if (_value === '1') {
          value = 'true';
        } else if (_value === '0') {
          value = 'false';
        } else {
          const isTriState = mask === 'checkbox' && attributes?.state3?.toLowerCase?.() === 'true';
          if (!isTriState) {
            value = 'false';
          } else {
            value = '';
          }
        }
        break;
      default:
        break;
    }

    // value = value || attributes?.text || '';         // Remark SKE: not sure what this is supposed to do (restriction on type needed ?? eg label only)

    // Step 2: convert to requested output type
    // ========================================
    if (outputType === AperioViews.FieldTypes.NUMERIC) {
      if (mask === 'date') {
        if (!value) {
          valueInfo.value = undefined; // no requirement specs today; feel free to change this to zero if needed
        } else {
          valueInfo.value = +value.replaceAll('-', '');
        }
      } else if (mask === 'time') {
        if (!value) {
          valueInfo.value = undefined; // no requirement specs today; feel free to change this to zero if needed
        } else {
          valueInfo.value = +value.replaceAll(':', '');
        }
      } else if (mask === 'checkbox' || mask === 'radio') {
        if (value === 'true') {
          valueInfo.value = 1;
        } else if (value === 'false') {
          valueInfo.value = 0;
        } else {
          valueInfo.value = undefined; // tristate!
        }
      } else {
        /*
          REMARK: this else branch contains both values for all numeric masks as the other "text" related ones, because
          aperio config requsts us to convert the value to a number. Of course the success conversion ratio for the
          numeric ones will be much higher as previous step prepared the values for this 2nd step. Nevertheless, free to
          use fields in Enterprise might still contain numeric data (and could be calculated by custom specific programs)
        */
        // --> Initialize
        value = value.trim();
        const parsingError = inColumnTableMode
          ? `Value '${value}' for column ${fieldId} in table ${panelId} can not be converted to number.`
          : `Value '${value}' for field ${fieldId} in panel ${panelId} can not be converted to number.`;

        // --> Temporarily remove minus sign
        let isNegative = false;
        if (value.startsWith('-')) {
          value = value.slice(1);
          isNegative = true;
        }

        // --> Value may not contain any other minus sign
        if (value.includes('-')) {
          valueInfo.error = parsingError;
          return valueInfo;
        }

        // --> Split value on decimal point
        const parts = value.split('.');

        // --> Number can contain max 2 parts
        if (parts.length > 2) {
          valueInfo.error = parsingError;
          return valueInfo;
        }

        // --> Get integral and fractional parts
        let integralPart = parts[0].trim() || '0';
        let fractionalPart = parts.length > 1 ? parts[1].trim() || '0' : '0';

        // --> Remove leading zeros from integral part
        while (integralPart.startsWith('0') && integralPart.length > 1) {
          integralPart = integralPart.slice(1);
        }

        // --> Integral part must be numeric
        try {
          const x = BigInt(integralPart);
        } catch (e) {
          valueInfo.error = parsingError;
          return valueInfo;
        }

        // --> Fractional part must be numeric
        try {
          const x = BigInt(fractionalPart);
          if (x === BigInt(0)) fractionalPart = '0';
        } catch (e) {
          valueInfo.error = parsingError;
          return valueInfo;
        }

        // --> Add lost precision warning (if appropriate)
        let lostPrecisionWarningNeeded = false;
        const _integer: string =
          integralPart === '0' ? '' : integralPart + fractionalPart === '0' ? '' : fractionalPart;
        const _maxSafeInteger = Number.MAX_SAFE_INTEGER.toString();
        if (_integer.length > _maxSafeInteger.length) {
          lostPrecisionWarningNeeded = true;
        } else if (_integer.length === _maxSafeInteger.length && _integer > _maxSafeInteger) {
          lostPrecisionWarningNeeded = true;
        }
        if (lostPrecisionWarningNeeded) {
          valueInfo.warning = inColumnTableMode
            ? `Value '${value}' for column ${fieldId} in table ${panelId} can not be converted to number without losing ` +
              `precision. Contact your Aperio provider to check if this parameter can be passed as text instead of number.`
            : `Value '${value}' for field ${fieldId} in panel ${panelId} can not be converted to number without losing ` +
              `precision. Contact your Aperio provider to check if this parameter can be passed as text instead of number.`;
        }

        // --> Get resulting number
        if (fractionalPart !== '0') {
          value = integralPart + '.' + fractionalPart;
        } else {
          value = integralPart;
        }

        if (isNegative && value !== '0') {
          value = '-' + value;
        }

        let number = 0;
        try {
          number = Number(value);
        } catch (e) {
          //                     not really expected anymore, program protection only
          valueInfo.error = parsingError;
          return valueInfo;
        }

        if (Number.isNaN(number)) {
          valueInfo.error = parsingError; // not really expected anymore, program protection only
        } else {
          valueInfo.value = number;
        }
        return valueInfo;
      }
      return valueInfo;
    } else if (outputType === AperioViews.FieldTypes.BOOLEAN) {
      if (mask === 'checkbox' || mask === 'radio') {
        if (value === 'true') {
          valueInfo.value = true;
        } else if (value === 'false') {
          valueInfo.value = false;
        } else {
          valueInfo.value = undefined;
        }
        return valueInfo;
      } else if (mask === 'time' || mask === 'date') {
        valueInfo.error = inColumnTableMode
          ? `Column ${fieldId} in table ${panelId} is a ${mask} and can not be converted to boolean.`
          : `Field ${fieldId} in panel ${panelId} is a ${mask} and can not be converted to boolean.`;
        return valueInfo;
      } else {
        const error = inColumnTableMode
          ? `Value '${value}' for column ${fieldId} in table ${panelId} can not be converted to boolean.`
          : `Value '${value}' for field ${fieldId} in panel ${panelId} can not be converted to boolean.`;

        value = value.trim().toLowerCase();
        if (value === 'true') {
          valueInfo.value = true;
          return valueInfo;
        } else if (value === 'false') {
          valueInfo.value = false;
          return valueInfo;
        }

        let number = NaN;
        try {
          number = Number(value);
        } catch (e) {
          // ignore error here (handled further)
        }

        if (number === 1) {
          valueInfo.value = true;
        } else if (number === 0) {
          valueInfo.value = false;
        } else {
          valueInfo.error = error;
        }
        return valueInfo;
      }
    } else if (outputType === AperioViews.FieldTypes.TEXT) {
      valueInfo.value = value;
      return valueInfo;
    } else {
      valueInfo.error = `Aperio field type "${outputType}" not yet supported`;
      return valueInfo;
    }
  },
  isApplicationInModalMode(isLoading: boolean): boolean {
    // Modal mode if ...
    // =================

    // ... application is currently loading data
    if (isLoading) return true;

    // ... any modal window is shown
    if (document.querySelector('[aria-modal="true"]')) {
      return true;
      // triggered by:
      // ° program/window tab showing dialog (eg for prompting)
      // ° client settings
      // ° show important links
      // ° role settings templates
      // ° about box
      // ° (any) table settings (eg column visibility)
      // ° ErrorModal
      // ° switch view modal
    }

    // ... application menu shown
    if (document.querySelector('.overlay-full:not([hidden])')) {
      return true;
    }

    return false;

    // Remarks:
    // ° print dialog --> Keys intercepted by browser (at least google)
    // ° code "exporting" box was obsolete
  },
  isCommaAlwaysServerDecimalSeparator(formatter: string | undefined, mask: string | undefined): boolean {
    // Workaround for known backend issue (XTise ignores the decimal separator defined at server side in following cases):
    if (['Decimal', 'DecimalWithZeros', 'PositiveDecimalWithZeros'].findIndex((el) => el === formatter) >= 0) {
      return true;
    } else if (!formatter) {
      if (
        [
          'decimal',
          'decimal_with_zeros',
          'delimitedquantity',
          'positivedecimal',
          'positivedecimal_with_zeros',
        ].findIndex((el) => el === mask) >= 0
      )
        return true;
    }
    return false;
  },
};

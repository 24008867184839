import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { noop } from 'lodash';
import { createAppTheme } from '../theme';

export type ThemeMode = 'light' | 'dark';

export type ThemeContextType = {
  mode: ThemeMode;
  toggleTheme: () => void;
  setMode: (mode: ThemeMode) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  toggleTheme: noop,
  setMode: noop,
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState<ThemeMode>(() => {
    const savedMode = localStorage.getItem('theme');
    const defaultTheme = localStorage.getItem('default_theme');

    if (savedMode === 'default') {
      return (defaultTheme as ThemeMode) || 'light';
    }
    return (savedMode as ThemeMode) || 'light';
  });

  const [theme, setTheme] = useState(createAppTheme(mode));

  useEffect(() => {
    localStorage.setItem('theme', mode);
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', mode);
    setTheme(createAppTheme(mode));
    // Dispatch a custom event that other parts of the app can listen to
    window.dispatchEvent(new CustomEvent('themeChange', { detail: { mode } }));
  }, [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const contextValue = useMemo(
    () => ({
      mode,
      toggleTheme,
      setMode,
    }),
    [mode],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

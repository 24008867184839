import React, { ChangeEvent, FocusEvent, useContext, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, Locale } from 'date-fns';
import CalenadrIcon from '../assets/calendar.svg';
import CalenadrDisabledIcon from '../assets/calendar-disabled.svg';
import CalenadrErrorIcon from '../assets/calendar-error.svg';
import '../styles/DatePicker.scss';
import '../styles/Edit.scss';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { Formatters } from '../framework/parsers';
import { LocalStorage } from '../framework/handlers/localStorage';

/**
 * @DatePickerProps
 *
 * attribute: XMl attributes for selected element
 */

interface DatePickerProps {
  attributes: Record<string, any>;
  className?: string;
  defaultValue?: Date;
  disabled?: boolean;
  id: string;
  isInvalid?: boolean;
  minDate?: Date;
  maxDate?: Date;
  name: string;
  readOnly?: boolean;
  visible?: boolean;
  locale?: string | Locale;
  dateFormat?: string;
  panelID: string;
  rowID?: string;
  rowFlags?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  name,
  className,
  defaultValue,
  visible = true,
  disabled = false,
  readOnly = false,
  isInvalid = false,
  minDate,
  maxDate,
  attributes,
  locale,
  panelID,
  rowID,
  rowFlags,
}) => {
  const [selected, setSelected] = useState<Date | null>(defaultValue || null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const hiddenRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLDivElement>(null);
  const {
    addToRowCommand,
    removeFromRowCommand,
    addToCommand,
    removeFromCommand,
    setScope,
    updateFlag,
    windowData,
    settings,
    errors,
    localization,
    cursor,
  } = useContext(CommandContext);
  const [isValidDate, setIsValidDate] = useState<boolean>(!(errors && isInvalid));
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);
  const [dateFormat, setDateFormat] = useState('MMddyy');
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    // set tabindex coordinates
    if (dateRef && dateRef.current) {
      const scrollInfo = XT.getScrollInfo(dateRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  let { century_break_year, dateSeparator, format6, format8, format8Sep } = settings?.regionals.dateformats[0].$ || {};

  useEffect(() => {
    if (hiddenRef && hiddenRef.current) {
      let dirtyflag = +(hiddenRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(dirtyflag);
    }
  }, []);

  useEffect(() => {
    let _value =
      (rowID
        ? XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, name)
        : XT.getValueFromWindow({ data: windowData }, panelID, id)) || '';

    let dateFormat = format6;
    _value = _value.trim();
    if (_value?.length === 8) dateFormat = format8;
    if (_value?.includes(dateSeparator)) dateFormat = format8Sep;
    setDateFormat(dateFormat);
    setSelected(Formatters.Date.in_DEPRECATED(_value, dateFormat, century_break_year));
    setIsValidDate(!(errors && isInvalid));
    if (hiddenRef && hiddenRef.current) {
      let _dirtyflag = +(hiddenRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      updateFlag(_dirtyflag, false);
    }
  }, [windowData]);

  useEffect(() => {
    if (selected) {
      let dateString = format(selected, dateFormat);
      if (defaultValue) {
        let defaultDate = format(defaultValue, dateFormat);
        if (dateString !== defaultDate) {
          if (rowID) addToRowCommand(panelID, rowID, name, dateString, dirtyflag, rowFlags);
          else addToCommand(panelID, id, dateString, dirtyflag);
        } else {
          if (rowID) removeFromRowCommand(panelID, rowID, name, dirtyflag);
          else removeFromCommand(panelID, id, dirtyflag);
        }
      } else {
        if (rowID) addToRowCommand(panelID, rowID, name, dateString, dirtyflag, rowFlags);
        else addToCommand(panelID, id, dateString, dirtyflag);
      }
    } else {
      if (defaultValue) {
        if (rowID) addToRowCommand(panelID, rowID, name, '', dirtyflag, rowFlags);
        else addToCommand(panelID, id, '', dirtyflag);
      } else {
        if (rowID) removeFromRowCommand(panelID, rowID, name, dirtyflag);
        else removeFromCommand(panelID, id, dirtyflag);
      }
    }
  }, [selected]);

  const checkValidDate = (e: any) => {
    const v = e.target.value;
    if (
      e.code === 'Backspace' &&
      (v.charAt(v.length - 1) == '/' || v.charAt(v.length - 1) == '-' || v.charAt(v.length - 1) == '.')
    ) {
      (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.slice(0, -1);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsOpen(false);
  };

  if (!visible) return null;

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled || readOnly) {
      return false;
    } else if (rowID) {
      return false;
    } else if (isOpen) {
      // --> keep default behavior arrow
      return false;
    }
    return true;
  };

  return (
    <>
      <input
        hidden
        ref={hiddenRef}
        id={id}
        name={id}
        type="text"
        defaultValue={selected ? format(selected, dateFormat) : ''}
      />
      <div
        className="datepicker-wrapper"
        ref={dateRef}
        data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
        data-for="global"
        data-iscapture="true"
        data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
        tabIndex={-1}
        onFocus={(e: FocusEvent) => {
          // Redirect focus to input control if focus given directly to the div (case arrow navigation)
          if (e.target === dateRef.current) {
            (dateRef.current?.querySelector('#picker' + id) as HTMLInputElement)?.focus();
          }
        }}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (
            ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
            LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
          ) {
            if (isOpen) setIsOpen(false);
            setTimeout(() => {
              dateRef?.current?.focus();
              XT.selectNextElement();
            }, 0);
          }
        }}
      >
        <ReactDatePicker
          id={'picker' + id}
          name={name}
          data-invalid={isInvalid}
          selected={selected}
          data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
          tabIndex={!readOnly && !disabled ? x + y : -1}
          onChangeRaw={(e) => {
            if ((e.target as HTMLInputElement).value) {
              //Mask formatter
              (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^\d]/g, '');
              let f = localization.dateFormat;
              f = f.indexOf('y') === f.lastIndexOf('y') ? f.replace('y', 'yyyy') : f;
              f = f.indexOf('M') === f.lastIndexOf('M') ? f.replace('M', 'MM') : f;
              f = f.indexOf('d') === f.lastIndexOf('d') ? f.replace('d', 'dd') : f;
              const sep = f.replace(/[Mdy]/g, '').charAt(0) || '';
              f = f.replace(/[/\-.]/g, '');
              const arr: string[] = [];
              let y = f.replace(/[Md]/g, '');
              let d = f.replace(/[My]/g, '');
              let M = f.replace(/[yd]/g, '');
              const ylen = y.length;
              const dlen = d.length;
              const Mlen = M.length;
              for (let i = 0; i < f.length && arr.length < 3; i++) {
                const c = f.charAt(i);
                if (!arr.includes(c)) arr.push(c);
              }
              let v = e.target.value;
              d = v.substring(f.indexOf('d'), f.lastIndexOf('d') + 1);
              M = v.substring(f.indexOf('M'), f.lastIndexOf('M') + 1);
              y = v.substring(f.indexOf('y'), f.lastIndexOf('y') + 1);
              if ((d !== '' && Number(d) > 31 && d !== '99') || d === '00') {
                d = d.slice(0, -1);
              }
              if ((M !== '' && Number(M) > 12 && M !== '99') || M === '00') {
                M = M.slice(0, -1);
              }
              const lastChar = f.charAt(f.length - 1);
              if (d.length == dlen && lastChar !== 'd') {
                d = d + sep;
              }
              if (M.length == Mlen && lastChar !== 'M') {
                M = M + sep;
              }
              if (y.length == ylen && lastChar !== 'y') {
                y = y + sep;
              }
              v = '';
              for (let i = 0; i < arr.length; i++) {
                switch (arr[i]) {
                  case 'd':
                    v = `${v}${d}`;
                    break;
                  case 'M':
                    v = `${v}${M}`;
                    break;
                  default:
                    v = `${v}${y}`;
                }
              }
              e.target.value = v.replace(/[./-]/g, '').includes('999999') ? '999999' : v;
            }
          }}
          onChange={(date, event: ChangeEvent) => {
            setIsOpen(false);
            if (!Array.isArray(date) && (event.target as HTMLInputElement).value !== '') {
              setSelected(date);
            } else {
              setSelected(null);
            }
          }}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          onBlur={handleBlur}
          minDate={minDate}
          maxDate={maxDate}
          className={`text-style text-font padding ${!isValidDate ? 'input-invalid' : ''} ${
            disabled ? 'disabled' : ''
          } ${className}`}
          useWeekdaysShort
          autoComplete="off"
          locale={locale}
          dateFormat={localization.dateFormat}
          open={isOpen}
          onFocus={(e: any) => {
            //Check for scope, if disabled or read only no need to set the scope
            (e.target as HTMLInputElement).onkeyup = checkValidDate;
            if (disabled || readOnly) return;
            if (hiddenRef && hiddenRef.current) {
              let ddspos = hiddenRef.current.closest('[data-ddspos]')?.getAttribute('data-ddspos') || '';
              setScope(ddspos);
            }
          }}
        />
        <img
          className="calendar-icon"
          alt="calendar-icon"
          src={!isValidDate ? CalenadrErrorIcon : disabled ? CalenadrDisabledIcon : CalenadrIcon}
          onClick={() => {
            if (disabled || readOnly) return;
            setIsOpen(!isOpen);
            setTimeout(() => {
              (dateRef.current?.querySelector('.react-datepicker__tab-loop__start') as HTMLDivElement)?.focus();
            }, 100);
          }}
        />
      </div>
    </>
  );
};

export default DatePicker;

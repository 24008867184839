export const base64: any = {
  // atob
  base64ToBytes: (base64: string, decode?: boolean) => {
    const textDecoder = new TextDecoder();
    const binString = atob(base64);
    const bytes = new Uint8Array(binString.length);
    for (let i = 0; i < binString.length; i++) {
      bytes[i] = binString.charCodeAt(i);
    }
    if (decode) {
      return textDecoder.decode(bytes);
    } else {
      return bytes;
    }
  },
  // btoa
  bytesToBase64: (value: any, encode?: boolean) => {
    const textEncoder = new TextEncoder();
    let returnValue = value;
    if (encode) {
      returnValue = textEncoder.encode(value);
    }
    const binString = String.fromCharCode(...returnValue);
    return btoa(binString);
  }
};

import { Theme } from '@mui/material';
import { TextSize, TextWeight } from './Typography';
import { Radius } from './Radius';

export const Table = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      background: 'transparent',
      borderCollapse: 'collapse',
      // borderSpacing: `0 ${theme.spacing(0.5)}`,
      td: {
        borderTop: `${theme.spacing(0.5)} solid transparent`,
      },
      thead: {
        tr: {
          background: theme.palette.background.tableHeader,
          th: {
            background: theme.palette.background.tableHeader,
            borderTop: 0,
          },
        },
      },
      tr: {
        background: theme.palette.common.white,
        borderBottom: 'none',
        '.MuiTablePagination-spacer': {
          display: 'none',
        },
        p: {
          margin: 0,
        },
        th: {
          fontWeight: TextWeight.REGULAR,
          fontSize: TextSize.XS,
          lineHeight: '20px',
        },
        td: {
          fontWeight: TextWeight.REGULAR,
          fontSize: TextSize.S,
        },
        '.selectable-box': {
          padding: '15px',
        },
        'th, td': {
          borderBottom: 'none',
          padding: theme.spacing(1.5),
        },
        'th:first-child, td:first-child': {
          borderRadius: `${Radius.M}px 0 0 ${Radius.M}px`,
        },
        'th:last-child, td:last-child': {
          borderRadius: `0 ${Radius.M}px ${Radius.M}px 0`,
        },
      },
    }),
  },
};

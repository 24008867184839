import React, { useState, useRef, useEffect, KeyboardEvent, useContext, useMemo } from 'react';
import { format, parse } from 'date-fns';
import Clock from '../assets/clock.svg';
import ClockDisabled from '../assets/clock-disabled.svg';
import ClockError from '../assets/clock-error.svg';
import '../styles/TimePicker.scss';
import '../styles/Edit.scss';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { Formatters } from '../framework/parsers';
import { LocalStorage } from '../framework/handlers/localStorage';
import { createPortal } from 'react-dom';
import { AperioViewCommand, AperioViews } from '../types/AperioViews';
import { useDispatch } from 'react-redux';
import { WindowActions } from '../types/actionTypes';
import { AperioView } from '../framework/handlers/aperioView';
import { debounce } from '@iptor/base';

const WIDTH_MULTIPLIER_FOR_SECONDS = 1.7;
/**
 * @TimePickerProps
 * attributes: XML attributes for selected element.
 */

type TimePickerProps = {
  attributes: Record<string, any>;
  className?: string;
  defaultValue?: Date;
  rawValue?: string;
  disabled?: boolean;
  id: string;
  isInvalid?: boolean;
  name: string;
  panelID: string;
  readOnly?: boolean;
  visible?: boolean;
  rowID?: string;
  rowFlags?: string;
  rowField?: string;
  onClose?: Function;
  onRowChange?: Function;
};

const getTimeIndividualValues = (timeValue: string, hideSeconds: boolean) => {
  /**
   * To get the individual time values hours, minutes, seconds | hours, minutes
   */
  const dateSplits = timeValue.split(':');
  if (!hideSeconds) {
    return {
      hours: dateSplits[0] || '',
      minutes: dateSplits[1] || '',
      seconds: dateSplits[2] || '',
    };
  }
  return {
    hours: dateSplits[0] || '',
    minutes: dateSplits[1] || '',
  };
};

const TimePicker: React.FC<TimePickerProps> = ({
  attributes,
  className = '',
  defaultValue = null,
  disabled = false,
  id,
  isInvalid = false,
  name,
  readOnly = false,
  panelID,
  rawValue = '0000',
  visible = true,
  rowID,
  rowFlags,
  rowField,
  onClose,
  onRowChange,
}) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState<Date | null>(defaultValue);
  const limitRef = useRef(attributes.limit ?? rawValue.length ?? 5);
  const valueInServerFormatRef = useRef<string>('');
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);
  const hoursRef = useRef<HTMLInputElement>(null);
  const minutesRef = useRef<HTMLInputElement>(null);
  const secondsRef = useRef<HTMLInputElement>(null);

  const timeDisplayValue = useMemo(
    () => (time ? Formatters.Time.out(time || new Date(), +limitRef.current < 5 ? 'HH:mm' : 'HH:mm:ss') : ''),
    [time, attributes],
  );

  const timeValues = getTimeIndividualValues(timeDisplayValue, +limitRef.current < 5); //
  const {
    addToRowCommand,
    removeFromRowCommand,
    addToCommand,
    removeFromCommand,
    errors,
    cursor,
    windowData,
    settings,
    getCommand,
    formAperioLinks,
    getFormLayout,
  } = useContext(CommandContext); //Creating a command for XT
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  // useEffect(() => {
  //   if (timeRef.current) {
  //     setX(timeRef.current.getBoundingClientRect().left);
  //     setY(timeRef.current.getBoundingClientRect().top);
  //   }
  // }, []);

  useEffect(() => {
    if (timeRef.current) {
      const scrollInfo = XT.getScrollInfo(timeRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  // useEffect(() => {
  //   let _value =
  //     (rowID
  //       ? XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, name)
  //       : XT.getValueFromWindow({ data: windowData }, panelID, name)) || "";
  //   setTime(defaultValue ? defaultValue : Formatters.Time.in(_value || null));
  // }, []);

  useEffect(() => {
    /**
     * XT.getValueFromWindowRow, XT.getValueFromWindow,XT.getReloadedFromWindowRow,XT.getReloadedFromWindow: Checking from the framework.
     */
    let _value =
      (rowID
        ? XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, name)
        : XT.getValueFromWindow({ data: windowData }, panelID, name)) || '';
    let reloaded = rowID
      ? XT.getReloadedFromWindowRow({ data: windowData }, panelID, rowID, name)
      : XT.getReloadedFromWindow({ data: windowData }, panelID, id);
    if (reloaded === true || reloaded === undefined) {
      setTime(defaultValue ? defaultValue : Formatters.Time.in(_value ?? null, +limitRef.current));
      valueInServerFormatRef.current = _value;
    }
  }, [windowData]);

  useEffect(() => {
    if (time) {
      let timeFormat = 'HHmm';
      if (+limitRef.current >= 5) timeFormat = 'HHmmss';
      const timeString = Formatters.Time.out(time, timeFormat);
      valueInServerFormatRef.current = timeString;
      if (defaultValue) {
        let defaultTime = format(defaultValue, timeFormat);
        if (defaultTime.endsWith('0')) defaultTime = defaultTime.slice(0, -1);
        if (onRowChange) onRowChange(timeString);
        if (defaultTime !== timeString) {
          if (rowID) addToRowCommand(panelID, rowID, name, timeString, dirtyflag);
          else addToCommand(panelID, name, timeString, dirtyflag);
        } else {
          if (rowID) removeFromRowCommand(panelID, rowID, name, dirtyflag);
          else removeFromCommand(panelID, name, dirtyflag);
        }
      } else {
        if (onRowChange) onRowChange(timeString);
        if (rowID) addToRowCommand(panelID, rowID, name, timeString, dirtyflag);
        else addToCommand(panelID, name, timeString, dirtyflag);
      }
    } else {
      valueInServerFormatRef.current = '';
      if (onRowChange) onRowChange('');
      if (defaultValue) {
        if (rowID) addToRowCommand(panelID, rowID, name, '', dirtyflag);
        else addToCommand(panelID, name, '', dirtyflag);
      } else {
        if (rowID) removeFromRowCommand(panelID, rowID, name, dirtyflag);
        else removeFromCommand(panelID, name, dirtyflag);
      }
    }
  }, [time]);

  const timeRef = useRef<HTMLInputElement>(null);

  const onMinutesChange = (minutes: string) => {
    /**
     * Parsing the minutes
     */
    const parsedMinutes = parseInt(minutes || '0');
    if (parsedMinutes > 59) return;

    const dateObj = parse(
      `${timeValues.hours}:${parsedMinutes.toString().padStart(2, '0')}${
        +limitRef.current >= 5 ? ':' + timeValues.seconds : ''
      }`,
      +limitRef.current < 5 ? 'HH:mm' : 'HH:mm:ss',
      new Date(),
    );
    setTime(dateObj);
  };

  const onHoursChange = (hours: string) => {
    /**
     * Parsing the hours
     */
    const parsedHours = parseInt(hours || '0');
    if (parsedHours > 23) return;

    const dateObj = parse(
      `${parsedHours.toString().padStart(2, '0')}:${timeValues.minutes || '00'}${
        +limitRef.current >= 5 ? ':' + (timeValues.seconds || '00') : ''
      }`,
      +limitRef.current < 5 ? 'HH:mm' : 'HH:mm:ss',
      new Date(),
    );
    setTime(dateObj);
  };

  const onSecondsChange = (seconds: string) => {
    /**
     * Parsing the seconds
     */
    const parsedSeconds = parseInt(seconds || '0');
    if (parsedSeconds > 59) return; //TODO: Check min max

    const dateObj = parse(
      `${timeValues.hours}:${timeValues.minutes}:${parsedSeconds.toString().padStart(2, '0')}`,
      +limitRef.current < 5 ? 'HH:mm' : 'HH:mm:ss',
      new Date(),
    );
    setTime(dateObj);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (popupRef.current && !popupRef?.current?.contains(event?.target)) {
        if (onClose && rowID) onClose(true);
        setIsPopUpVisible(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    if (isPopUpVisible) {
      hoursRef.current?.focus();
      const { x, bottom, width } = timeRef.current?.getBoundingClientRect() ?? {};
      if (popupRef.current?.style) {
        popupRef.current.style.top = `${bottom ?? 0}px`;
        popupRef.current.style.left = `${x}px`;
        popupRef.current.style.width = `${
          +limitRef.current >= 5 && width ? width * WIDTH_MULTIPLIER_FOR_SECONDS : width
        }px`;
      }
    }
  }, [isPopUpVisible]);

  useEffect(() => {
    if (rowID) {
      timeRef.current?.focus();
    }
  }, [timeRef]);

  const refAperioViewInfo = useRef<
    { event: AperioViews.Link.Event; createCommand: (windowData: any) => {} } | undefined
  >(undefined);
  useEffect(() => {
    // Initialize
    // ==========
    const dispatchUpdateContext = (aperioViewCommand: AperioViewCommand | undefined) => {
      dispatch({ type: WindowActions.UPDATE_CONTEXT, payload: { aperioViewCommand } });
    };
    const setAperioViewCommand = (link: AperioViews.Link, windowData: any) => {
      const aperioViewCommand = AperioView.getCommandBase(
        link,
        getCommand(),
        windowData,
        getFormLayout(),
        settings?.regionals,
      );
      dispatchUpdateContext(aperioViewCommand);
    };

    // Get event info
    // ==============
    const eventInfo = rowID // -->  not used in table as not yet supported
      ? undefined
      : AperioView.getFieldComponentEventInfo(id, panelID, formAperioLinks || []);

    // Only needed when an event is configured
    // ========================================
    if (eventInfo?.link) {
      // Create debounced function to update the context
      const ms = eventInfo.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE ? 10 : 300;
      const [submitCommand, cancel] = debounce(setAperioViewCommand, ms);

      // Set aperio view Info
      refAperioViewInfo.current = {
        event: eventInfo.event,
        createCommand: (windowData: any) => submitCommand(eventInfo.link, windowData),
      };

      // Clean-up effect
      return () => {
        cancel();
        refAperioViewInfo.current = undefined;
        dispatchUpdateContext(undefined);
      };
    }
  }, [id, panelID, rowID, formAperioLinks, getCommand, dispatch, settings?.regionals]);

  const refFocusInfo = useRef<{ timestamp: Date; serverValue: string } | undefined>(undefined);
  useEffect(() => {
    // Prevent create command to be called when not needed (see next effect)
    refFocusInfo.current = undefined;
  }, [windowData]);
  useEffect(() => {
    if (refAperioViewInfo?.current) {
      if (isPopUpVisible) {
        refFocusInfo.current = { timestamp: new Date(), serverValue: valueInServerFormatRef.current };
      } else if (refFocusInfo.current) {
        // Only if human interaction (vs software interaction)
        if (refFocusInfo.current.timestamp.getTime() + 100 < new Date().getTime()) {
          if (
            refAperioViewInfo.current.event.type === AperioViews.EventTypes.ON_FIELD_BLUR ||
            valueInServerFormatRef.current !== refFocusInfo.current.serverValue //==> ON_FIELD_UPDATE
          ) {
            refAperioViewInfo.current.createCommand(windowData);
          }
        }
      }
    }
  }, [isPopUpVisible, windowData]);

  const onInputFocus = () => {
    if (disabled || readOnly) return;
    if (isPopUpVisible) {
      if (onClose && rowID) onClose(true);
      setIsPopUpVisible(false);
    } else {
      setTimeout(() => {
        setIsPopUpVisible(true);
        hoursRef.current?.focus();
      }, 0);
    }
  };

  if (!visible) return null;

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled || readOnly) {
      return false;
    } else if (rowID) {
      return false;
    } else if (isPopUpVisible) {
      // --> keep default behavior arrow up/down
      return false;
    }
    return true;
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (
      ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
      LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
    ) {
      e.preventDefault(); //swallow the sign, remark: "act as tab" functionality itself is implemented at keyup time
    } else if (e.key.toLowerCase() === 'enter') {
      if (!timeRef?.current?.closest('.data-table-span')) {
        switch (e.target) {
          case minutesRef?.current:
            if (+limitRef.current < 5) {
              if (isPopUpVisible) {
                timeRef?.current?.focus();
              }
            }
            break;
          case secondsRef?.current:
            if (isPopUpVisible) {
              timeRef?.current?.focus();
            }
            break;
        }
      }
    } else if (e.key.toLowerCase() === 'tab') {
      switch (e.target) {
        case hoursRef?.current:
          if (!e.shiftKey) {
            e.stopPropagation();
            e.preventDefault();
            minutesRef.current?.focus();
          }
          break;
        case minutesRef?.current:
          if (e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            hoursRef.current?.focus();
          } else {
            if (+limitRef.current >= 5) {
              e.preventDefault();
              e.stopPropagation();
              secondsRef.current?.focus();
            } else {
              setTimeout(() => {
                if (onClose && rowID) onClose(true);
                setIsPopUpVisible(false);
              }, 0);
            }
          }
          break;
        case secondsRef?.current:
          if (e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            minutesRef.current?.focus();
          } else {
            setTimeout(() => {
              if (onClose && rowID) onClose(true);
              setIsPopUpVisible(false);
            }, 0);
          }
          break;
      }
    } else if (e.key.toLowerCase() !== 'enter' && e.key.toLowerCase() !== 'tab' && !e.shiftKey) {
      if (!isPopUpVisible) {
        setIsPopUpVisible(true);
      }
    }
  };

  return (
    <>
      <div
        className="time-input-container"
        data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
        data-for="global"
        data-iscapture="true"
        onKeyUp={(e: React.KeyboardEvent) => {
          if (
            ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
            LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
          ) {
            secondsRef?.current?.focus();
            XT.selectNextElement();
          }
        }}
      >
        <div className="input-wrapper input-group">
          <input
            // readOnly
            ref={timeRef}
            id={id}
            autoComplete={'off'}
            value={time ? timeDisplayValue : ''}
            type="text"
            className={`text-style time-picker text-font padding ${isPopUpVisible ? 'time-picker-open' : ''} ${
              errors && isInvalid ? 'input-invalid' : ''
            } ${className}`}
            autoFocus={isInvalid}
            data-invalid={isInvalid}
            data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
            data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
            tabIndex={!readOnly && !disabled ? x + y : -1}
            onFocus={onInputFocus}
            onKeyDown={(e) => onKeyDown(e)}
            name={name}
            disabled={disabled || readOnly}
          />
          <img
            className="img-clock"
            src={`${disabled ? ClockDisabled : errors && isInvalid ? ClockError : Clock}`}
            onClick={onInputFocus}
            alt=""
          />
        </div>
        {isPopUpVisible &&
          createPortal(
            <div className="time-popup-wrapper" ref={popupRef}>
              <div className="hours-wrapper">
                <label>HR</label>
                <input
                  autoComplete={'off'}
                  tabIndex={!readOnly && !disabled ? x + y + 1 : -1}
                  data-event="ignore"
                  name={name}
                  type="number"
                  className={`text-style text-font padding`}
                  value={timeValues.hours}
                  onKeyDown={(e) => onKeyDown(e)}
                  onChange={(e) => onHoursChange(e.target.value)}
                  onFocus={(e) => e.target.select()}
                  readOnly={readOnly}
                  ref={hoursRef}
                  min={0}
                  max={23}
                />
              </div>
              <div className="colon-wrapper">
                <label>:</label>
              </div>
              <div className="hours-wrapper">
                <label>MIN</label>
                <input
                  autoComplete={'off'}
                  tabIndex={!readOnly && !disabled ? x + y + 2 : -1}
                  data-event="ignore"
                  type="number"
                  className={`text-style text-font padding`}
                  value={timeValues.minutes}
                  onChange={(e) => onMinutesChange(e.target.value)}
                  readOnly={readOnly}
                  ref={minutesRef}
                  min={0}
                  max={60}
                  onKeyDown={(e) => onKeyDown(e)}
                  onFocus={(e) => e.target.select()}
                  onBlur={() => {
                    if (+limitRef.current < 5) {
                      if (document.activeElement !== document.body) {
                        if (onClose && rowID) onClose(true);
                        setIsPopUpVisible(false);
                      }
                    }
                  }}
                />
              </div>
              {+limitRef.current >= 5 && (
                <>
                  <div className="colon-wrapper">
                    <label>:</label>
                  </div>
                  <div className="hours-wrapper">
                    <label>SEC</label>
                    <input
                      autoComplete={'off'}
                      tabIndex={!readOnly && !disabled ? x + y + 3 : -1}
                      data-event="ignore"
                      type="number"
                      className={`text-style text-font padding`}
                      value={timeValues.seconds}
                      onChange={(e) => onSecondsChange(e.target.value)}
                      readOnly={readOnly}
                      ref={secondsRef}
                      onKeyDown={(e) => onKeyDown(e)}
                      min={0}
                      max={60}
                      onFocus={(e) => e.target.select()}
                      onBlur={() => {
                        if (document.activeElement !== document.body) {
                          if (onClose && rowID) onClose(true);
                          setIsPopUpVisible(false);
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>,
            document.body,
          )}
      </div>
    </>
  );
};

export default TimePicker;

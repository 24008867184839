/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Stack } from '@mui/material';
import { Button, Combo, DialogLayout, Text, useIptorTranslation } from '@iptor/base';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOpenAPIMethod } from '@iptor/business';
import PastItemsTable from './components/PastItemsTable';
import PastOrdersTable from './components/PastOrdersTable';
import { Order } from '../../constants';

interface Props {
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  currentOrder: Order;
}

function PastOrders(props: Props) {
  const [loading, setLoading] = useState(false);
  const { open, handleClose } = props;
  const itemTableRef = useRef(null);
  const orderTableRef = useRef(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const { t } = useIptorTranslation();

  if (!open) {
    return <></>;
  }

  const [selectedOrderId, setSelectedOrderId] = useState<number | undefined>(undefined);
  const [currentScreen, setCurrentScreen] = useState<'Past orders' | 'Ordered Items'>('Past orders');

  const handleAdd = useCallback(() => {
    setLoading(true);
    if (currentScreen === 'Past orders') {
      orderTableRef?.current?.handleAddOrders();
    } else {
      itemTableRef?.current?.handleAddOrderLines();
    }
  }, [itemTableRef, orderTableRef, currentScreen]);

  return (
    <DialogLayout
      actions={[
        <Button id="close" variant="outlined" size="medium" onClick={() => handleClose(false)}>
          Close
        </Button>,
        <Button
          hidden={!selectedOrderId}
          variant="outlined"
          size="medium"
          id="back"
          onClick={() => {
            setSelectedOrderId(undefined);
            setCurrentScreen('Past orders');
          }}
        >
          {t('common:TXT_Back')}
        </Button>,
        <Button
          id="add"
          variant="contained"
          size="medium"
          onClick={handleAdd}
          loading={loading}
          disabled={isAddButtonDisabled}
        >
          {t('common:ROLE_BUTTON_Add')}
        </Button>,
      ]}
      onClose={() => handleClose(false)}
      open={open}
      title={
        !selectedOrderId
          ? currentScreen
          : `${t('sales_orders:ORDER_order')} ${selectedOrderId} / ${t('sales_orders:ORDER_items')}`
      }
    >
      <Stack
        width="100%"
        gap={2}
        direction="row"
        alignItems="center"
        sx={(theme) => ({ paddingBottom: theme.spacing(2) })}
      >
        {!selectedOrderId && (
          <Combo
            disableClearable
            options={[
              { label: t('sales_orders:PASTORDERS_pastOrders'), value: 'Past orders' },
              { label: t('sales_orders:PASTORDERS_orderedItems'), value: 'Ordered Items' },
            ]}
            id="selectType"
            inputProps={{
              'data-for': 'global',
              'data-iscapture': 'true', // Tooltip content
              'data-tip': 'selectType',
            }}
            onChange={(event, option) => setCurrentScreen((option as any).value)}
            size="small"
            disableTyping
            value={currentScreen}
          />
        )}
        <Text>{t('sales_orders:CUSTOMER_customerNumber')}:</Text>{' '}
        <Text id="customer" fontWeight={600}>
          {props.currentOrder.customer}
        </Text>{' '}
        <Divider orientation="vertical" flexItem={true} />
        <Text>{t('sales_orders:CUSTOMER_customerName')}:</Text>{' '}
        <Text id="customerName" fontWeight={600}>
          {props.currentOrder.customerName}
        </Text>
      </Stack>

      {currentScreen === 'Ordered Items' ? (
        <PastItemsTable
          selectedOrderId={selectedOrderId}
          currentOrder={props.currentOrder.order}
          ref={itemTableRef}
          handleClose={handleClose}
          setDisabled={setIsAddButtonDisabled}
        />
      ) : (
        <PastOrdersTable
          currentOrder={props.currentOrder.order}
          setSelectedOrderId={(value) => {
            setSelectedOrderId(value);
            setCurrentScreen('Ordered Items');
          }}
          ref={orderTableRef}
          handleClose={handleClose}
          setDisabled={setIsAddButtonDisabled}
        />
      )}
    </DialogLayout>
  );
}

export default PastOrders;

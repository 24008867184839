import { Combo, ComboProps } from '@iptor/base';
import { useOpenAPIMethod } from '@iptor/business';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { formatErrorText } from '../../../utils/formatError';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { rowState, UpdateOrderLineFn } from '../constants';

export const OrderLineUnit = React.memo(
  ({
    row,
    setColumnValueForRow,
    value,
    disabled,
    updateOrderLine,
    currentOrderId,
    handleRefreshHeader,
  }: rowState &
    ComboProps & {
      updateOrderLine: UpdateOrderLineFn;
      currentOrderId: number;
      handleRefreshHeader: () => void;
    }) => {
    const { notify } = useOrderNotifications();
    const getUnits = useOpenAPIMethod('internal.v1.base-inventory', 'GET /items/{item}/units');
    const [initialState, setInitialState] = useState(value); // initial state before triggering the API

    // Ref to track if API has been called
    const hasFetchedUnits = useRef(false);

    useEffect(() => {
      if (row.item && !hasFetchedUnits.current) {
        getUnits.execute({ item: row.item });
        hasFetchedUnits.current = true; // Mark as fetched
      }
    }, [row.item, getUnits]);

    // Reset the fetch flag if row.item changes
    useEffect(() => {
      hasFetchedUnits.current = false;
    }, [row.item]);

    const unitsData = getUnits.response?.data?.data?.items || [];
    const unitOptions = unitsData.map((item) => ({
      label: `${item.unit}`,
      value: item.unit,
    }));

    const onUnitChange = useCallback(
      async (newValue: { label: string; value: string }) => {
        const selectedUnit = newValue?.value;
        if (!selectedUnit) return;

        setColumnValueForRow([{ column: 'netSalesValue', value: undefined }]);

        updateOrderLine(
          row.order,
          row.orderLine,
          {
            order: +currentOrderId,
            item: row.item,
            unit: selectedUnit,
            orderQuantitySalesUnit: row?.orderQuantitySalesUnit,
            warehouse: row?.warehouse,
          },
          (response) => {
            setColumnValueForRow([
              { column: 'unit', value: response.unit },
              { column: 'orderQuantitySalesUnit', value: response.orderQuantitySalesUnit },
              { column: 'netSalesValue', value: response.netSalesValue },
            ]);
            setInitialState(response.unit);
            notify('success', 'Order line Updated Successfully');
            handleRefreshHeader();
          },
          (err) => {
            setColumnValueForRow([
              { column: 'unit', value: initialState },
              { column: 'orderQuantitySalesUnit', value: initialState },
              { column: 'netSalesValue', value: initialState },
            ]);
            notify('error', formatErrorText(err));
          },
        );
      },
      [updateOrderLine, currentOrderId, row, setColumnValueForRow, notify, handleRefreshHeader],
    );

    return (
      <Combo
        size="small"
        value={value}
        options={unitOptions}
        id="unitCombo"
        disabled={disabled}
        sx={{
          '& .MuiInputBase-root': {
            minWidth: '140px',
          },
        }}
        onChange={async (e, newValue) => onUnitChange(newValue as { label: string; value: string })}
      />
    );
  },
);

OrderLineUnit.displayName = 'OrderLineUnit';

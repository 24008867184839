import { Icon, useIptorTranslation } from '@iptor/base';
import { Tooltip, Box } from '@mui/material';

interface Props {
  discount: boolean;
  type: 'order' | 'line';
}

function OrderDiscountLabel(props: Props) {
  const { t } = useIptorTranslation();
  const { discount, type } = props;

  if (!discount) {
    return <></>;
  }

  return (
    <Tooltip
      title={t(
        type === 'order'
          ? 'sales_orders:DISCOUNT_orderIncludesDiscount'
          : 'sales_orders:DISCOUNT_orderlineIncludesDiscount',
      )}
      placement="top"
      arrow
      color={'warning'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Icon id="orderLineDiscount" icon={'coupon01'} color="secondary.main" size="S" />
      </Box>
    </Tooltip>
  );
}

export default OrderDiscountLabel;

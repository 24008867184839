/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  debounce,
  DialogLayout,
  NumericField,
  SnackbarWrapper,
  TableFilter,
  TableRowValues,
  TableSettings,
  Text,
  TextField,
  theme,
  useCustomSnackbar,
  useIptorTranslation,
  Button,
} from '@iptor/base';
import { Search as SearchIcon } from '@mui/icons-material';
import { CircularProgress, Grid2 as Grid, InputAdornment, styled } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OperationParameters, OperationResponseItems, useOpenAPIMethod } from '../../framework/openapi';
import { IptorTable, IptorTableColumn, IptorTableRef } from '../IptorTable';
export type ItemType = OperationResponseItems<'internal.v1.base-inventory', 'GET /items'>[0];

export type ItemsProps = {
  headerData: { warehouse: string; orderId: number };
  handleSelectedItemList: (items: Record<string, string | number | unknown>[]) => void;
  open: boolean;
  handleClose: () => void;
  searchedItemId?: string;
};

const StyledText = styled(Text)`
  font-size: 16;
  color: ${theme.palette.text.primary};
  font-weight: ${theme.typography.fontWeightBold};
  line-height: ${theme.spacing(3)};
  max-width: 250px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

// const StyledImage = styled('img')`
//   width: 70px;
//   height: 70px;
//   border-radius: 8px;
//   object-fit: cover;
// `;

interface rowState {
  headerData: { warehouse: string; orderId: number };
  row: Record<string, any>;
  responseCallback?: (data: Record<string, any>) => void;
  col?: string;
  value?: string;
  setColumnValueForRow?: (values: { column: string; value: TableRowValues }[], currentIndex?: number) => void;
}

/** For future development post MVP */

// interface WarehouseState {
//   selectedWarehouse: string;
//   availableWarehouses: { label: string; value: string }[];
//   availableQuantity: number;
//   warehouseListWithAvlQty?: { warehouse: string; value: string }[];
// }

// const Warehouse = ({ headerData, row, setColumnValueForRow, value }: rowState) => {
//   const getItemWarehouseStock = useOpenAPIMethod('internal.v1.base-inventory', 'GET /itemBalances/{item}');
//   const defaultWarehouse = headerData.warehouse;
//   const [warehouseState, setWarehouseState] = useState<Record<string, WarehouseState>>({});
//   const getWarehouses = useOpenAPIMethod('internal.v1.base-inventory', 'GET /warehouses');

//   useEffect(() => {
//     getWarehouses.execute({
//       type: 1,
//     });
//   }, []);

//   useEffect(() => {
//     setColumnValueForRow([
//       {
//         column: 'availableQuantity',
//         value: undefined,
//       },
//       {
//         column: 'warehouse',
//         value: defaultWarehouse,
//       },
//     ]);
//     getItemWarehouseStock.execute({ item: row.item });
//   }, [row.item]);

//   const getWareHouseResponse = async () => {
//     const warehouseData = getItemWarehouseStock.response?.data?.data?.items || [];

//     const warehouseDropdownOptions = warehouseData.map((item) => {
//       const currentWarehouse = getWarehouses?.response?.data?.data?.items?.find(
//         (option) => option?.warehouse === item.warehouse,
//       );
//       return {
//         label: `${currentWarehouse?.warehouse} - ${currentWarehouse?.description}`,
//         value: currentWarehouse?.warehouse,
//       };
//     });

//     const warehouseListWithAvlQty = warehouseData.map((item) => {
//       return {
//         warehouse: item?.warehouse,
//         value: item?.availableQuantity,
//       };
//     });

//     const choosenWarehouseInfo = warehouseData.find((w) => w.warehouse === defaultWarehouse) || {};
//     const defaultQuantity = choosenWarehouseInfo?.availableQuantity || '-';

//     setColumnValueForRow([
//       {
//         column: 'availableQuantity',
//         value: defaultQuantity?.toString(),
//       },
//     ]);

//     setWarehouseState((prevState) => ({
//       ...prevState,
//       [row.item]: {
//         selectedWarehouse: defaultWarehouse,
//         availableWarehouses: warehouseDropdownOptions,
//         availableQuantity: defaultQuantity,
//         warehouseListWithAvlQty,
//       },
//     }));
//   };

//   useEffect(() => {
//     if (
//       getItemWarehouseStock.response &&
//       getItemWarehouseStock.response?.data?.data?.items &&
//       getWarehouses?.response?.data?.data?.items
//     ) {
//       getWareHouseResponse();
//     }
//   }, [getWarehouses.response, getItemWarehouseStock.response]);

//   useEffect(() => {
//     if (getItemWarehouseStock?.error && axios.isAxiosError(getItemWarehouseStock.error)) {
//       setColumnValueForRow([
//         {
//           column: 'availableQuantity',
//           value: '-',
//         },
//       ]);
//     }
//   }, [getItemWarehouseStock?.error]);

//   return (
//     <Combo
//       size="small"
//       value={value ?? defaultWarehouse}
//       options={warehouseState[row.item]?.availableWarehouses || []}
//       sx={{
//         '& .MuiInputBase-root': {
//           minWidth: '300px',
//         },
//       }}
//       onChange={async (e, newValue) => {
//         const selectedWarehouse = (newValue as { label: string; value: string })?.value;
//         const warehouse = warehouseState[row.item]?.warehouseListWithAvlQty.find(
//           (wh) => wh.warehouse === selectedWarehouse,
//         );
//         setColumnValueForRow([
//           { column: 'availableQuantity', value: warehouse?.value },
//           { column: 'orderQuantitySalesUnit', value: '-' },
//           { column: 'warehouse', value: selectedWarehouse },
//         ]);

//         const client = await openAPIManager.getClient('internal.v1.base-orders');
//         setColumnValueForRow([{ column: 'salesPrice', value: undefined }]);
//         try {
//           const response = await client['GET /salesOrders/{order}/lineSimulations']({
//             order: headerData.orderId,
//             item: row.item,
//             orderQuantitySalesUnit: row?.orderQuantitySalesUnit !== '-' ? row?.orderQuantitySalesUnit : 1,
//             warehouse: selectedWarehouse,
//           });

//           setColumnValueForRow([{ column: 'salesPrice', value: response?.data?.data?.netSalesValue || '-' }]);
//         } catch (error) {
//           setColumnValueForRow([{ column: 'salesPrice', value: '-' }]);
//         }

//         setWarehouseState((prevState) => ({
//           ...prevState,
//           [row.item]: {
//             ...prevState[row.item],
//             selectedWarehouse,
//             availableQuantity: warehouse ? warehouse.value : 0,
//           },
//         }));
//       }}
//     />
//   );
// };
/** -----------------------End------------------------------------ */
const Quantity = ({ headerData, row, setColumnValueForRow, value }: rowState) => {
  const getItemUnitPrices = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}/lineSimulations');

  const getItemPriceResponse = async () => {
    setColumnValueForRow([
      { column: 'salesPrice', value: getItemUnitPrices.response?.data?.data?.netSalesValue ?? 'N/A' },
      {
        column: 'availableQuantity',
        value: getItemUnitPrices?.response?.data?.data.availableQuantity || '-',
      },
      {
        column: 'warehouse',
        value: getItemUnitPrices?.response?.data?.data.warehouse || '-',
      },
    ]);
  };

  useEffect(() => {
    setColumnValueForRow([
      { column: 'salesPrice', value: undefined },
      {
        column: 'orderQuantitySalesUnit',
        value: 1,
      },
      {
        column: 'availableQuantity',
        value: undefined,
      },
      {
        column: 'warehouse',
        value: undefined,
      },
    ]);
    getItemUnitPrices.execute({
      order: headerData.orderId,
      item: row.item,
      orderQuantitySalesUnit: 1,
      // warehouse: headerData.warehouse,
    });
  }, [row.item]);

  useEffect(() => {
    if (getItemUnitPrices.response && getItemUnitPrices.response?.data) {
      getItemPriceResponse();
    }
  }, [getItemUnitPrices.response]);

  useEffect(() => {
    if (getItemUnitPrices?.error && axios.isAxiosError(getItemUnitPrices.error)) {
      setColumnValueForRow([
        {
          column: 'salesPrice',
          value: '-',
        },
        {
          column: 'availableQuantity',
          value: '-',
        },
        {
          column: 'warehouse',
          value: '-',
        },
      ]);
    }
  }, [getItemUnitPrices?.error]);

  const callLimeSimulationApi = async (newValue: number) => {
    getItemUnitPrices.execute({
      order: headerData.orderId,
      item: row.item,
      orderQuantitySalesUnit: Number(newValue) ? +newValue : 1,
      warehouse: row?.warehouse,
    });
  };
  return (
    <>
      <NumericField
        value={Number(value) ? +value : undefined}
        id="quantity"
        onChangeNumeric={(newValue: number) => {
          if (newValue !== undefined) {
            setColumnValueForRow([
              {
                column: 'salesPrice',
                value: undefined,
              },
              {
                column: 'orderQuantitySalesUnit',
                value: newValue,
              },
            ]);
            callLimeSimulationApi(newValue);
          }
        }}
        size="small"
        buttonLayout="horizontal"
      />
    </>
  );
};

export function Items(props: ItemsProps) {
  const { open, handleClose, headerData, handleSelectedItemList, searchedItemId } = props;
  const { t } = useIptorTranslation();
  const { showSnackbar } = useCustomSnackbar();

  const [inputValue, setInputValue] = useState<string>(searchedItemId);
  const [searchValue, setSearchValue] = useState<string>(searchedItemId);
  const debouncedSetSearchValue = useCallback(
    (value: string, debounceTimeInMs = 300) => debounce(() => setSearchValue(value), debounceTimeInMs)[0](),
    [],
  );
  const iptorTableRef = useRef<IptorTableRef>(null);
  const [selectedRows, setSelectedRows] = useState<ItemType[]>([]);
  const pageSize = 10;

  const params = useMemo<OperationParameters<'internal.v1.base-inventory', 'GET /items'>>(
    () => ({
      limit: 10,
      freeTextSearch: searchValue,
    }),
    [searchValue],
  );

  useEffect(() => {
    iptorTableRef?.current?.refresh();
  }, [params]);

  const itemColumns = useMemo<
    IptorTableColumn<
      'internal.v1.base-inventory',
      'GET /items',
      {
        actions: string;
        warehouse: string;
        availableQuantity: string;
        orderQuantitySalesUnit: string;
        salesPrice: string;
      }
    >[]
  >(
    () => [
      {
        display: t('common:Att_category_ITEM'),
        key: 'item',
        cellRenderer: ({ row }) => {
          return (
            <Grid container spacing={0.5} sx={{ minWidth: '180px' }}>
              <Grid size={8}>
                <StyledText
                  id="itemDesc"
                  sx={{
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.text.primary,
                    fontSize: 16,
                    mb: 1,
                  }}
                >
                  {row['description']}
                </StyledText>
                <StyledText
                  id="itemText"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.body1,
                  })}
                >
                  {row['item']}
                </StyledText>
              </Grid>
            </Grid>
          );
        },
      },
      {
        display: t('common:TXT_Unit'),
        key: 'stockUnit',
        cellRenderer: ({ value }) => (
          <>
            <StyledText id="stockUnit">{value}</StyledText>
          </>
        ),
      },
      {
        display: t('common:TXT_Warehouse'),
        key: 'warehouse',
        cellRenderer: ({ row }) => {
          return (
            <>
              {row?.warehouse !== undefined ? (
                <StyledText id="warehouse">{row?.warehouse}</StyledText>
              ) : (
                <CircularProgress size={'15px'} />
              )}
            </>
          );
        },
      },
      {
        display: t('common:TXT_Avalaible_Qty'),
        key: 'availableQuantity',
        cellRenderer: ({ row }: any) => {
          return (
            <>
              {row?.availableQuantity !== undefined ? (
                <StyledText id="availableQuantity">{row?.availableQuantity}</StyledText>
              ) : (
                <CircularProgress size={'15px'} />
              )}
            </>
          );
        },
      },
      {
        display: t('common:TXT_Quantity'),
        key: 'orderQuantitySalesUnit',
        cellRenderer: ({ row, value, setColumnValueForRow }) => {
          return (
            <Quantity
              headerData={headerData}
              row={row}
              value={value ?? 1}
              setColumnValueForRow={setColumnValueForRow}
            />
          );
        },
      },
      {
        display: t('common:TXT_Unit_Price'),
        key: 'salesPrice',
        cellRenderer: ({ row }) => (
          <>
            {row?.salesPrice ? (
              <StyledText id="salesPrice">
                {row?.salesPrice !== '-' ? `${row?.['currency'] ?? 'GBP'} ${row?.salesPrice}` : '-'}
              </StyledText>
            ) : (
              <CircularProgress size={'15px'} />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!open) {
    return <></>;
  }

  return (
    <>
      <SnackbarWrapper>
        <DialogLayout
          title={t('common:Att_category_ITEM')}
          open={open}
          slotProps={{
            actionBar: {
              sx: {
                padding: `${theme.spacing(4.25)}`,
              },
            },
            paper: {
              sx: {
                top: '18%',
                minHeight: '85%',
              },
            },
          }}
          onClose={handleClose}
          children={
            <IptorTable
              apiId="internal.v1.base-inventory"
              apiEndpoint={'GET /items'}
              disableActionBar={false}
              customRef={iptorTableRef}
              onSelectedRowsChange={({ rows }) => {
                setSelectedRows(rows);
              }}
              // onRowsChange={({ rows }) => {
              //   setSelectedRows([]);
              // }}
              selectable="multiple"
              columns={itemColumns}
              pageSize={pageSize}
              apiParams={{ freeTextSearch: searchValue, limit: pageSize }}
              additionalActions={
                <>
                  <TableFilter />
                  <TableSettings />
                </>
              }
              additionalFilters={
                <div>
                  <TextField
                    placeholder={t('common:TXT_Enter_Item_Name_Placeholder')}
                    value={inputValue}
                    id="itemInput"
                    onChange={(e) => {
                      debouncedSetSearchValue(e.target.value);
                      setInputValue(e?.target?.value);
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        minWidth: '500px',
                        height: '48px',
                        background: theme.palette.background.default,
                        marginBottom: '8px',
                      },
                    }}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <Text
                    fontWeight={theme.typography.fontWeightBold}
                    sx={{ mt: theme.spacing(0.5) }}
                  >{`${t('common:TXT_Items_Selected')}: ${selectedRows?.length}`}</Text>
                </div>
              }
            />
          }
          actions={
            <>
              <Button id="close" variant="outlined" size="medium" onClick={handleClose}>
                {t('common:TXT_Close')}
              </Button>
              <Button
                variant="contained"
                id="add"
                size="medium"
                onClick={() => {
                  if (selectedRows && selectedRows?.length > 0) {
                    handleSelectedItemList(selectedRows);
                    handleClose();
                  } else {
                    showSnackbar({ variant: 'warning', message: t('common:TXT_No_Rows_Selected') });
                  }
                }}
              >
                {t('common:ROLE_BUTTON_Add')}
              </Button>
            </>
          }
        />
      </SnackbarWrapper>
    </>
  );
}

import React, { createContext, useState, useContext, ReactNode, useMemo, useEffect } from 'react';

// Define the context type
interface TooltipVisibilityContextType {
  isTooltipVisible: boolean;
  setTooltipVisibility: (isVisible: boolean) => void;
}

// Create the context
const TooltipVisibilityContext = createContext<TooltipVisibilityContextType | undefined>(undefined);

// Context provider component
export const TooltipVisibilityProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(new URL(window.location.href).searchParams.has('dev'));

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    if (isTooltipVisible) {
      if (!searchParams.has('dev')) {
        searchParams.append('dev', '');
      }
    } else {
      searchParams.delete('dev');
    }

    // Convert searchParams to string but remove any '=' if present
    const queryString = searchParams.toString().replace(/=(&|$)/g, '$1');
    window.history.replaceState({}, '', url.pathname + (queryString ? '?' + queryString : ''));
  }, [isTooltipVisible]);

  // Toggle function
  // const resetTooltip = () => setIsTooltipVisible(false);
  const setTooltipVisibility = (isVisible: boolean) => setIsTooltipVisible(isVisible);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ isTooltipVisible, setTooltipVisibility }), [isTooltipVisible]);

  return <TooltipVisibilityContext.Provider value={contextValue}>{children}</TooltipVisibilityContext.Provider>;
};

// Custom hook for using the context
export const useTooltipVisibility = () => {
  const context = useContext(TooltipVisibilityContext);
  if (!context) {
    throw new Error('useTooltipVisibility must be used within a TooltipVisibilityProvider');
  }
  return context;
};

import { useEffect } from 'react';
import { Box, Text, useClientSettings, useIptorTranslation } from '@iptor/base';
import { useOpenAPIMethod } from '@iptor/business';
import { Divider, Grid2 as Grid, Skeleton } from '@mui/material';
import { Order } from '../../../constants';
import AddressCard from '../../../components/addresses/AddressCard';

type OrderSummaryProps = {
  order?: Order;
};

const OrderSummary = ({ order }: OrderSummaryProps) => {
  const addressApi = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}/addresses/{addressType}');
  const { formatCurrency } = useClientSettings();
  const { t } = useIptorTranslation();

  useEffect(() => {
    if (order?.order) {
      addressApi.execute({ order: order.order, addressType: 'D' });
    }
  }, [order?.order]);

  return (
    <Box>
      <Grid container spacing={6} justifyContent="space-between">
        <Grid size={4}>
          {addressApi.response?.data?.data && !addressApi.loading ? (
            <AddressCard
              address={addressApi.response.data.data}
              orderStatus={order.orderStatus}
              customer={order.customer}
              onAddressEdit={() => {
                addressApi.execute({ order: order.order, addressType: 'D' });
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={(theme) => ({
                minHeight: '188px',
                borderRadius: theme.spacing(1),
              })}
            />
          )}
        </Grid>

        <Grid size={3} alignContent="center">
          {!order?.order ? (
            <Skeleton
              variant="rectangular"
              sx={(theme) => ({
                minHeight: '188px',
                borderRadius: theme.spacing(1),
              })}
            />
          ) : (
            <Grid container component="dl" rowSpacing={2} columnSpacing={4}>
              <Grid size={6}>
                <Text component="dt" variant="body1">
                  {t('common:TXT_Subtotal')}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text id="totalAmountExclVAT" component="dd" variant="body1" textAlign="right">
                  {formatCurrency({
                    amount: order.totalAmountExclVAT,
                    currency: order.primaryCurrencyISO || order.primaryCurrency,
                  })}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text component="dt" variant="body1">
                  {t('common:TXT_Discount')}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text id="discount" component="dd" variant="body1" textAlign="right">
                  {formatCurrency({
                    amount: order.discount,
                    currency: order.primaryCurrencyISO || order.primaryCurrency,
                  })}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text component="dt" variant="body1">
                  {t('common:TXT_VAT')}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text id="vatAmount" component="dd" variant="body1" textAlign="right">
                  {formatCurrency({
                    amount: order.vatAmount,
                    currency: order.primaryCurrencyISO || order.primaryCurrency,
                  })}
                </Text>
              </Grid>

              <Divider
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                  borderWidth: 0.5,
                  width: '100%',
                })}
              />

              <Grid size={6}>
                <Text component="dt" variant="body1" fontWeight={600}>
                  {t('common:TXT_Total')}
                </Text>
              </Grid>
              <Grid size={6}>
                <Text id="totalAmountInclVAT" component="dd" variant="body1" fontWeight={600} textAlign="right">
                  {formatCurrency({
                    amount: order.totalAmountInclVAT,
                    currency: order.primaryCurrencyISO || order.primaryCurrency,
                  })}
                </Text>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderSummary;

import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Icon, SheetDropZone, Text } from '@iptor/base';
import { openAPIManager } from '@iptor/business';
import { DialogActions, LinearProgress, Stack } from '@mui/material';
import { Workbook } from 'exceljs';
import { FileData, FileResult } from './Constants';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import { Order } from '../../constants';

interface Props {
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  currentOrder: Order;
}

function BulkUpload({ open, handleClose, currentOrder }: Props) {
  const { notify } = useOrderNotifications();
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [validatedFilesResults, setValidatedFilesResults] = useState<FileResult[]>([]);
  const [processedFilesResults, setProcessedFilesResults] = useState<FileResult[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const allFilesValidated = validatedFilesResults.every(({ valid, total, errors }) => valid + errors === total);
  const allFilesProcessed = processedFilesResults.every(({ valid, total, errors }) => valid + errors === total);
  const invalidFiles = validatedFilesResults.filter(({ errors }) => errors > 0);
  const unprocessedFiles = processedFilesResults.filter(({ errors }) => errors > 0);

  const handleAPIError = (file: FileData, rowIndex: number, error: any) => {
    notify(
      'error',
      `File (${file.fileName}) at row (${rowIndex + 1}) => ${error?.response?.data?.messages?.map((m: any) => m.text).join(' ')}`,
    );
  };

  const updateFileResults = (
    setResults: React.Dispatch<React.SetStateAction<FileResult[]>>,
    file: FileData,
    isValid: boolean,
  ) => {
    setResults((prev) => {
      const index = prev.findIndex((f) => f.fileName === file.fileName);
      const newResults = [...prev];
      newResults[index] = isValid
        ? { ...newResults[index], valid: newResults[index].valid + 1 }
        : { ...newResults[index], errors: newResults[index].errors + 1 };
      return newResults;
    });
  };

  const handleFileRows = async (
    file: FileData,
    endpoint: 'GET /salesOrders/{order}/lineSimulations' | 'POST /salesOrders/{order}/lines',
    setResults: React.Dispatch<React.SetStateAction<FileResult[]>>,
  ) => {
    const isLineSimulation = endpoint === 'GET /salesOrders/{order}/lineSimulations';

    file.rows.forEach((row, rowIndex) => {
      openAPIManager
        .execute(
          'internal.v1.base-orders',
          endpoint,
          { order: currentOrder.order, ...(isLineSimulation ? row : {}) }, // Include `row` in params if `isLineSimulation`
          isLineSimulation ? undefined : { ...row }, // Include `row` in body if not `isLineSimulation`
        )
        .then(() => updateFileResults(setResults, file, true))
        .catch((error) => {
          updateFileResults(setResults, file, false);
          handleAPIError(file, rowIndex, error);
        });
    });
  };

  const handleSheetDataChange = (data: FileData[]) => {
    setFilesData(data);
    const initialResults = data.map((file) => ({
      fileName: file.fileName,
      total: file.rows.length,
      valid: 0,
      errors: 0,
    }));
    setValidatedFilesResults(initialResults);
    setProcessedFilesResults(initialResults);
    data.forEach((file) => handleFileRows(file, 'GET /salesOrders/{order}/lineSimulations', setValidatedFilesResults));
  };

  const handleDownloadTemplate = async () => {
    const templateData = [
      ['item', 'description', 'orderQuantitySalesUnit', 'warehouse', 'unit', 'requestDeliveryDate'],
    ];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Template');
    templateData.forEach((row) => {
      worksheet.addRow(row);
    });

    // Generate the Excel file as a buffer - writeFile() possible only in node
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer and trigger download using link, temporarily apended to DOM
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); //type for .xlsx
    const aurl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = aurl;
    a.download = 'BulkUploadTemplate.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(aurl); // Revoke the Object URL to release memory
    a.remove();
  };

  const resetState = () => {
    setIsProcessing(false);
    setFilesData([]);
    setValidatedFilesResults([]);
    setProcessedFilesResults([]);
  };

  const calculateProgress = (results: FileResult[]) =>
    (results.reduce((acc, { valid, errors }) => acc + valid + errors, 0) /
      results.reduce((acc, { total }) => acc + total, 0)) *
    100;

  useEffect(() => {
    if (allFilesProcessed && isProcessing) {
      notify('success', 'All files processed successfully');
      handleClose();
      resetState();
    }
  }, [allFilesProcessed]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={() => {
        handleClose(false);
        resetState();
      }}
    >
      <Box>
        <Stack
          direction="row"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}
        >
          <Text variant="h6">Import File</Text>
          <Icon icon="cancel01" onClick={() => handleClose(false)} />
        </Stack>
        <Button
          size="small"
          variant="outlined"
          sx={{ marginLeft: 'auto' }}
          onClick={handleDownloadTemplate}
          iconRight="download4"
          id="downloadTemplate"
        >
          Download Template
        </Button>
        <SheetDropZone onSheetDataChange={handleSheetDataChange} />

        {invalidFiles.map((file, index) => (
          <Text key={index} color="error" variant="body1">
            File {file.fileName} has {file.errors} invalid order lines. Please validate and re-upload.
          </Text>
        ))}
        {unprocessedFiles.map((file, index) => (
          <Text key={index} color="error" variant="body2">
            File {file.fileName} encountered {file.errors} issues during processing.
          </Text>
        ))}
      </Box>
      {filesData.length > 0 && (
        <LinearProgress
          sx={{ minHeight: '4px' }}
          variant="determinate"
          color={!allFilesValidated ? 'warning' : invalidFiles.length || unprocessedFiles.length ? 'error' : 'primary'}
          value={isProcessing ? calculateProgress(validatedFilesResults) : calculateProgress(processedFilesResults)}
        />
      )}
      <DialogActions>
        <Button
          id="cancel"
          onClick={() => {
            handleClose(false);
            resetState();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            !allFilesValidated || invalidFiles.length > 0 || filesData.length === 0 || unprocessedFiles.length > 0
          }
          onClick={() => {
            setIsProcessing(true);
            filesData.forEach((file) =>
              handleFileRows(file, 'POST /salesOrders/{order}/lines', setProcessedFilesResults),
            );
          }}
          id="process"
          loading={isProcessing}
        >
          Process
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkUpload;

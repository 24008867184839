import React, { FunctionComponent, useState, useEffect } from 'react';
import { Form, FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTable } from 'react-table';
import { Localization } from '../framework/localization/Localization';
import '../styles/ColumnVisibilityTable.scss';
import { Icons, SquareIcon } from './SquareIcon';
/*
data : data to be show in table
header: table header
 */

export type ColumnsHeader = {
  Header: string;
  isVisible: boolean;
  hideHeader: boolean;
  columns: {
    Header: string;
    accessor: string;
  }[];
};

export type ColumnSettings = {
  id: string;
  name: string;
  hide: boolean;
  noHeader: boolean;
};

export interface DataWithTTSeq extends ColumnSettings {
  tooltipSequence: string;
  isSeqInvalid: boolean;
}

export type ColumnVisbilityTableProps = {
  columns: ColumnsHeader[];
  className?: string;
  data: DataWithTTSeq[];
  header?: boolean;
  onChangeTooltipSequence: (id: string, value: string) => void;
  toggleHidden: Function;
  handleReset: () => void;
  handleInvert: () => void;
  handleSelectAll: () => void;
  handleUnselectAll: () => void;
};

export const ColumnVisibilityTable: FunctionComponent<ColumnVisbilityTableProps> = ({
  columns,
  data,
  header = true,
  onChangeTooltipSequence,
  toggleHidden,
  handleReset,
  handleInvert,
  handleSelectAll,
  handleUnselectAll
}: ColumnVisbilityTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups }: any = useTable({
    columns,
    data
  } as any);
  const [filter, setFilter] = useState('');
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsAllSelected(data.every((column: any) => !column.hide));
  }, [data]);

  return (
    <>
      <div className='modal-controls'>
        <span className='custom-search-column'>
          <input
            type='text'
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            placeholder={Localization.instance.getString('EDIT_COLUMNS_SearchColumnsByName')}
          />
        </span>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip className={`custom light`} id={`tooltip-reset`}>
              {Localization.instance.getString('TABLE_SETTINGS_Reset_Columns_Selection')}
            </Tooltip>
          }
        >
        <span className='column-visibilty-span' onClick={handleReset}>
          <SquareIcon className='column-visibilty-icon' size='18px'>
            {Icons.ResetArrow}
          </SquareIcon>
        </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip className={`custom light`} id={`tooltip-invert`}>
              {Localization.instance.getString('TABLE_SETTINGS_Invert_Columns_Selection')}
            </Tooltip>
          }
        >
        <span className='column-visibilty-span' onClick={handleInvert}>
          <SquareIcon className='column-visibilty-icon' size='18px'>
            {Icons.InvertArrows}
          </SquareIcon>
        </span>
        </OverlayTrigger>
      </div>
      <div className='column-visibility-table-container'>
        <table {...getTableProps()} className='column-visiblity-table'>
          {header && (
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, i: number) => {
                    return column.hideHeader === false ? null : (
                      <th key={i} {...column.getHeaderProps()}>
                        {column.render('Header') === 'Enabled' ? (
                          <div style={{ textAlign: 'right' }}>
                            <Form.Check
                              type='checkbox'
                              name={column.id}
                              id={`table-selection-chk-${column.id}`}
                              checked={isAllSelected}
                              //indeterminate={isAllSelected}
                              custom={true}
                              data-event='ignore'
                              onChange={(e) => {
                                e.stopPropagation();
                                isAllSelected ? handleUnselectAll() : handleSelectAll();
                              }}
                            />
                          </div>
                        ) : (
                          column.render('Header')
                        )}
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}

          <tbody {...getTableBodyProps()}>
            {data
              .filter(
                (column: any) =>
                  (!filter.toLowerCase().trim() || column.name.toLowerCase().includes(filter.toLowerCase())) && !column.noHeader
              )
              .map((column: any) => {
                return (
                  <tr>
                    <td>{column.name}</td>
                    <td>
                      <div style={{ textAlign: 'right' }}>
                        <Form.Check
                          type='checkbox'
                          name={column.id}
                          id={`table-selection-chk-${column.id}`}
                          checked={!column.hide}
                          custom={true}
                          data-event='ignore'
                          onChange={(e) => {
                            e.stopPropagation();
                            toggleHidden(column.id);
                          }}
                        />
                      </div>
                    </td>
                    <td className='tooltip-column-visibility'>
                      <input
                        defaultValue={column.tooltipSequence}
                        type='number'
                        className={`text-style text-font padding editable-cell ${
                          column.isSeqInvalid ? 'is-invalid' : ''
                        }`}
                        onChange={(e) => {
                          onChangeTooltipSequence(column.id, e.target.value);
                        }}
                        min={1}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

import { LogInfo, LogLevel } from '../loggerTypes';
import { LoggerAppender } from './LoggerAppender';

export class ConsoleAppender extends LoggerAppender {
  // protected stringifyMeta(meta: Record<string, any> | undefined): string {
  //   if (!meta) return '';
  //   if (Object(meta).entries.length < 1) return '';

  //   let result = '{';
  //   for (const [key, value] of Object.entries(meta)) {
  //     if (value !== undefined) {
  //       result += `${key}: ${LoggerAppender.stringifyData(value)},`;
  //     }
  //   }
  //   if (result === '{') return '';  //Case attributes all undefined

  //   result = result.slice(0, -1) + '}';
  //   return result;
  // }

  public log(logInfo: LogInfo, logLevel: LogLevel): void {
    if (logLevel > this.logLevel) return;
    const timestamp = new Date();
    let logMessage = `${LogLevel[logLevel]} ${timestamp.toJSON()}`;

    const data = LoggerAppender.stringifyData(logInfo.logData);
    if (data) logMessage += ` ${data}`;

    let meta = logInfo.meta ? logInfo.meta : {};
    const tags = LoggerAppender.stringifyTags(logInfo.tags || []);
    if (tags) meta = { ...meta, tags: tags };

    // const _meta = this.stringifyMeta(meta);
    // if (_meta) logMessage += ' ' + _meta;
    if (meta) logMessage += ' ' + LoggerAppender.stringifyData(meta);

    switch (logLevel) {
      case LogLevel.INFO:
        console.info(logMessage);
        break;
      case LogLevel.ERROR:
        console.error(logMessage);
        break;
      case LogLevel.DEBUG:
        console.debug(logMessage);
        break;
      case LogLevel.WARN:
        console.warn(logMessage);
        break;
      default:
        console.log(logMessage);
    }
  }
}

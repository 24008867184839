import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Country', 'Description'];

export const AddressCountryDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: warehousesOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /countries',
    labelAttribute: 'description',
    valueAttribute: 'country',
    shouldPreventApiCall,
  });

  return (
    <BaseDropdown {...props} label="Country" loading={loading} options={warehousesOptions} columnNames={COLUMN_NAMES} />
  );
};

import React, { useMemo } from 'react';
import {
  Select,
  MenuItem,
  SelectProps,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  SvgIcon,
  Typography,
  useTheme,
  MenuItemProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { Check, Warning as WarningIcon, Error as ErrorIcon } from '@mui/icons-material';

// Extend MUI's SelectProps to include our custom props if needed
export type ComboDeprecatedProps = SelectProps & {
  options: Array<{ value: string | number; label: string }>;
  helperText?: string;
  loading?: boolean;
};

// Styled Select component
const StyledInputLabel = styled(InputLabel)(({ theme, variant }) => ({
  ...(variant === 'standard' && {
    position: 'relative',
    transform: 'none',
    marginBottom: '-4px',
    color: '#404040',
    '&.Mui-focused': {
      color: '#404040',
    },
  }),
}));

export type ExtendedMenuItem = MenuItemProps & { size: 'small' | 'medium' | 'large' };

const StyledMenuItem = styled(MenuItem)<ExtendedMenuItem>(({ size }) => ({
  ...(size === 'small' && {
    fontSize: 14,
  }),
  ...(size === 'large' && {
    fontSize: 18,
  }),
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme, color }) => ({
  ...(color === 'warning' && {
    color: theme.palette.warning.main,
  }),
  ...(color === 'error' && {
    color: theme.palette.error.main,
  }),
}));

// Reusable Dropdown component
export const ComboDeprecated: React.FC<ComboDeprecatedProps> = ({
  options,
  value,
  onChange,
  label,
  fullWidth,
  helperText,
  disabled,
  loading,
  color,
  variant,
  size = 'medium',
  ...props
}) => {
  const { palette } = useTheme();
  const helperIcon = useMemo(() => {
    if (loading)
      if (!helperText)
        return (
          <>
            <CircularProgress size={16} sx={{ color: palette.foreground[400], mr: 0 }} />
            <Typography variant="body2">Loading</Typography>
          </>
        );
      else return <CircularProgress size={16} sx={{ color: palette.foreground[400], mr: 0 }} />;

    switch (color) {
      case 'success':
        return <SvgIcon component={Check} sx={{ color: palette[color].main, fontSize: 16, mr: 0 }} />;
      case 'warning':
        return <SvgIcon component={WarningIcon} sx={{ color: palette[color].main, fontSize: 16, mr: 0 }} />;
      case 'error':
        return <SvgIcon component={ErrorIcon} sx={{ color: palette[color].main, fontSize: 16, mr: 0 }} />;
    }
  }, [loading, color, helperText]);

  return (
    <FormControl fullWidth={fullWidth}>
      {label && <StyledInputLabel variant={variant}>{label}</StyledInputLabel>}

      <Select
        value={value}
        onChange={onChange}
        {...props}
        disabled={disabled}
        color={color}
        label={label}
        size={size}
        variant={variant}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value} size={size}>
            {option.label}
          </StyledMenuItem>
        ))}
      </Select>
      <StyledFormHelperText
        disabled={disabled}
        color={color}
        sx={{
          marginLeft: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {helperIcon}
        {helperText}
      </StyledFormHelperText>
    </FormControl>
  );
};

import { ButtonProps, Stack, SxProps, Theme, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextWeight } from '../../constants/Typography';
import { IconButton } from '../IconButton';
import { Text } from '../Text';
import { TextField, TextFieldProps } from '../TextField';
import { useClientSettings } from '../../utils/ClientSettings/index';

// Extend TextFieldProps to handle button layout, step, and min/max for number input
export type NumericFieldProps = TextFieldProps & {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  buttonLayout?: 'horizontal' | 'vertical' | 'none';
  onChangeNumeric?: (value: number | null) => void;
  precision?: number; // Number of decimal places
  thousandsSeparator?: string; // Custom thousands separator
  decimalSeparator?: string; // Custom decimal separator
  sx?: SxProps<Theme>;
  additionalSlotProps?: {
    negativeButton?: ButtonProps;
    positiveButton?: ButtonProps;
    textField?: TextFieldProps;
  };
};

export const NumericField = ({
  min = -Infinity, // Default to no lower limit
  max = Infinity, // Default to no upper limit
  step = 1,
  value = 0,
  buttonLayout = 'vertical',
  precision = 2,
  label = '',
  disabled = false,
  size = 'medium',
  onChangeNumeric,
  thousandsSeparator,
  decimalSeparator,
  sx,
  ...props
}: NumericFieldProps) => {
  const clientSettings = useClientSettings();
  const theme = useTheme();

  const grpSep = useMemo(
    () => thousandsSeparator ?? clientSettings.groupSeparator,
    [thousandsSeparator, clientSettings.groupSeparator],
  );
  const decSep = useMemo(
    () => decimalSeparator ?? clientSettings.decimalSeparator,
    [decimalSeparator, clientSettings.decimalSeparator],
  );
  const addThousandSeparator = (num: string) => num.replace(/\B(?=(\d{3})+(?!\d))/g, grpSep);
  const removeThousandSeparator = (num: string) => num.replace(new RegExp(`\\${grpSep}`, 'g'), '');

  const getFormattedValue = (input: number | string) => {
    if (typeof input === 'string' || !input) {
      return '';
    }
    const stringValue = input.toFixed(precision);
    const [integerPart, decimalPart] = stringValue.split('.');
    const formattedInteger = addThousandSeparator(integerPart || '');
    return decimalPart !== undefined ? `${formattedInteger}${decSep}${decimalPart}` : formattedInteger;
  };

  const getNumericValue = (formattedValue: string) => {
    const [integerPart, decimalPart] = formattedValue.split(decSep);
    const numericInteger = removeThousandSeparator(integerPart || '');
    return parseFloat(decimalPart ? `${numericInteger}.${decimalPart}` : numericInteger);
  };

  const [inputValue, setInputValue] = useState<number>(value);
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [inputTextValue, setInputTextValue] = useState<string>(getFormattedValue(value));

  useEffect(() => {
    if (!inFocus) {
      setInputValue(value);
      setInputTextValue(getFormattedValue(value));
    }
  }, [value, decimalSeparator, thousandsSeparator]);

  const updateValue = useCallback(
    (newValue: number) => {
      const roundedValue = parseFloat(newValue.toFixed(precision));
      setInputValue(roundedValue);
      setInputTextValue(getFormattedValue(roundedValue));
      onChangeNumeric?.(roundedValue);
    },
    [precision, onChangeNumeric],
  );

  const handleIncrease = useCallback(() => {
    const newValue = Math.min((inputValue || min) + step, max);
    updateValue(newValue);
  }, [inputValue, step, max, updateValue]);

  const handleDecrease = useCallback(() => {
    const newValue = Math.max((inputValue || max) - step, min);
    updateValue(newValue);
  }, [inputValue, step, min, updateValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    // Remove invalid characters except numbers, the decimal separator, and negative sign
    inputValue = inputValue.replace(new RegExp(`[^0-9${decSep}-]`, 'g'), '');

    // Remove existing thousand separators
    inputValue = inputValue.replace(new RegExp(`\\${grpSep}`, 'g'), '');

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = inputValue.split(decSep);

    // Add thousand separators only to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, grpSep);

    // Combine the formatted integer with the decimal part, if any
    const formattedValue = decimalPart !== undefined ? `${formattedInteger}${decSep}${decimalPart}` : formattedInteger;

    // Update the input value
    setInputTextValue(formattedValue);
    const valueAsNumber = getNumericValue(inputValue);
    setInputValue(valueAsNumber);
    onChangeNumeric?.(isNaN(valueAsNumber) ? null : valueAsNumber);
  };

  const handleBlur = () => {
    let adjustedValue = parseFloat(inputValue?.toString());
    setInFocus(false);
    if (!isNaN(adjustedValue)) {
      adjustedValue = Math.max(min, Math.min(max, adjustedValue));
      updateValue(adjustedValue);
    }
  };

  const removeInputSpinners = {
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  };

  const renderHorizontalButtons = () => (
    <Stack direction="column" spacing={1} alignItems="top" sx={sx}>
      <Text
        variant="body2"
        color={inFocus ? theme.palette.primary.main : theme.palette.foreground[500]}
        fontWeight={TextWeight.SEMIBOLD}
      >
        {label} {props.required ? '*' : ''}
      </Text>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          icon="minusSign"
          onClick={handleDecrease}
          size={size}
          disabled={inputValue <= min || disabled}
          sx={(theme) => ({
            mt: '1px !important',
            mb: 'auto !important',
            outlineWidth: 1,
            padding: size === 'small' ? theme.spacing(1.625) : theme.spacing(2.3125),
          })}
          {...props.additionalSlotProps?.negativeButton}
          variant="outlined"
        />

        <TextField
          {...props}
          size={size}
          onChange={handleInputChange}
          disabled={disabled}
          value={inputTextValue}
          onBlur={handleBlur}
          onFocus={() => setInFocus(true)}
          sx={{
            width: '80px',
            '& .MuiInputBase-root': {
              minWidth: '80px',
              textAlignLast: 'center',
            },

            ...removeInputSpinners,
          }} // Adjust width to your needs
          {...props.additionalSlotProps?.textField}
        />

        <IconButton
          icon="plusSign"
          size={size}
          onClick={handleIncrease}
          disabled={inputValue >= max || disabled}
          sx={(theme) => ({
            mt: '1px !important',
            mb: 'auto !important',
            outlineWidth: 1,
            padding: size === 'small' ? theme.spacing(1.625) : theme.spacing(2.3125),
          })}
          {...props.additionalSlotProps?.positiveButton}
          variant="outlined"
        />
      </Stack>
    </Stack>
  );

  const renderVerticalButtons = () => (
    <TextField
      {...props}
      label={label} // Use the label prop for the vertical layout
      value={inputTextValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onFocus={() => setInFocus(true)}
      disabled={disabled}
      size={size}
      slotProps={{
        ...props.slotProps,
        input: {
          ...props.slotProps?.input,
          endAdornment: (
            <Stack direction="row" gap={'8px'}>
              {(props.slotProps?.input as any)?.endAdornment}
              <Stack direction="column" gap={'1px'}>
                <IconButton
                  icon="plusSign"
                  size="small"
                  onClick={handleIncrease}
                  disabled={inputValue >= max || disabled}
                  sx={{
                    height: '50%',
                    padding: '1px',
                    fontSize: 5,
                    borderRadius: '2px 2px 0px 0px',
                    svg: { width: 12, height: 12 },
                    '&:focus': {
                      zIndex: 5,
                      outlineWidth: 1,
                    },
                    outlineWidth: 1,
                  }}
                  {...props.additionalSlotProps?.positiveButton}
                  variant="outlined"
                />
                <IconButton
                  icon="minusSign"
                  size="small"
                  onClick={handleDecrease}
                  disabled={inputValue <= min || disabled}
                  sx={{
                    height: '50%',
                    padding: '1px',
                    fontSize: 5,
                    borderRadius: '0px 0px 2px 2px',
                    svg: { width: 12, height: 12 },
                    '&:focus': {
                      zIndex: 5,
                      outlineWidth: 1,
                    },
                    outlineWidth: 1,
                  }}
                  {...props.additionalSlotProps?.negativeButton}
                  variant="outlined"
                />
              </Stack>
            </Stack>
          ),
        },
      }}
      {...props.additionalSlotProps?.textField}
      sx={{
        ...removeInputSpinners,
        '& .MuiInputBase-root': {
          paddingRight: '6px ',
        },
        ...sx,
      }}
    />
  );

  const renderNoButtons = () => (
    <TextField
      {...props}
      label={label}
      type="text"
      size={size}
      value={inputTextValue}
      onChange={handleInputChange}
      disabled={disabled}
      onFocus={() => setInFocus(true)}
      onBlur={handleBlur}
      sx={{ ...removeInputSpinners, ...sx }}
    />
  );

  switch (buttonLayout) {
    case 'horizontal':
      return renderHorizontalButtons();
    case 'vertical':
      return renderVerticalButtons();
    case 'none':
      return renderNoButtons();
    default:
      return renderNoButtons();
  }
};

export default (
  <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0369 0H25.689C27.3458 0 28.689 1.34315 28.689 3V30.3842C28.689 32.0411 27.3458 33.3842 25.689 33.3842H3.6893C2.03244 33.3842 0.689301 32.0411 0.689301 30.3842L0.689301 9.34758L10.0369 0Z"
      fill="#1B9754"
    />
    <path
      d="M0.68866 9.34758L10.0366 0L10.0363 6.34769C10.0363 8.0045 8.69316 9.34758 7.03635 9.34758H0.68866Z"
      fill="#D9FFEB"
    />
    <path
      d="M9.00928 17.018C9.04489 16.9183 9.09474 16.8186 9.15884 16.7189C9.22294 16.6191 9.30128 16.5301 9.39386 16.4518C9.49357 16.3663 9.60752 16.2987 9.73572 16.2488C9.86391 16.1918 10.0064 16.1633 10.163 16.1633C10.3696 16.1633 10.5512 16.2168 10.7079 16.3236C10.8645 16.4233 11.0248 16.5978 11.1886 16.847L12.0219 18.1183L13.2504 16.3022C13.3074 16.2737 13.3857 16.2488 13.4854 16.2274C13.5923 16.1989 13.6955 16.1847 13.7952 16.1847C14.1228 16.1847 14.3792 16.263 14.5644 16.4197C14.7496 16.5764 14.8422 16.7687 14.8422 16.9966C14.8422 17.196 14.7888 17.3741 14.6819 17.5308C14.5822 17.6874 14.4433 17.8762 14.2653 18.097L13.1649 19.443L14.9063 21.9321C14.8493 22.1956 14.7247 22.4057 14.5324 22.5624C14.3472 22.712 14.0944 22.7868 13.7739 22.7868C13.5673 22.7868 13.3893 22.7369 13.2397 22.6372C13.0902 22.5304 12.937 22.3523 12.7804 22.1031L11.915 20.7356L10.569 22.7654C10.512 22.7725 10.4586 22.7761 10.4087 22.7761C10.3589 22.7832 10.309 22.7868 10.2592 22.7868C9.85323 22.7868 9.55411 22.7049 9.36181 22.5411C9.16952 22.3773 9.07338 22.1743 9.07338 21.9321C9.07338 21.7256 9.11611 21.5475 9.20157 21.398C9.29416 21.2413 9.42235 21.0597 9.58616 20.8532L10.7079 19.411L9.00928 17.018Z"
      fill="white"
    />
    <path
      d="M16.8675 22.7334C16.5613 22.7334 16.3191 22.6443 16.1411 22.4663C15.963 22.2882 15.874 22.0461 15.874 21.7398V16.2168C15.9523 16.2025 16.077 16.1847 16.2479 16.1633C16.4188 16.1348 16.5826 16.1206 16.7393 16.1206C16.9031 16.1206 17.0456 16.1348 17.1666 16.1633C17.2948 16.1847 17.4017 16.2274 17.4871 16.2915C17.5726 16.3556 17.6367 16.4447 17.6794 16.5586C17.7221 16.6726 17.7435 16.8221 17.7435 17.0073V21.2271H20.1899C20.2398 21.3054 20.286 21.4122 20.3288 21.5475C20.3715 21.6757 20.3929 21.8111 20.3929 21.9535C20.3929 22.2384 20.3323 22.4414 20.2113 22.5624C20.0902 22.6764 19.93 22.7334 19.7305 22.7334H16.8675Z"
      fill="white"
    />
  </svg>
);

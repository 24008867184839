import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Localization } from '../framework/localization/Localization';
import '../styles/NumpadSignsBehavior.scss';

type NumpadSignsBehaviorProps = {
  actAsTab: boolean;
  clearIfAllSelected: boolean;
  onChange: (actAsTab: boolean, clearIfAllSelected: boolean) => void;
};

export const NumpadSignsBehavior: React.FunctionComponent<NumpadSignsBehaviorProps> = ({
  actAsTab,
  clearIfAllSelected,
  onChange
}) => {
  const toggleActAsTab = () => {
    onChange(!actAsTab, clearIfAllSelected);
  };

  const toggleClearIfAllSelected = () => {
    onChange(actAsTab, !clearIfAllSelected);
  };

  return (
    <div className='numpad-signs-behavior'>
      <Row>
        <Col md={3}>
          <label className='switch'>
            <input type='checkbox' checked={actAsTab} onChange={toggleActAsTab} />
            <span className='slider round' />
          </label>
        </Col>
        <Col md={9}>
          {actAsTab && (
            <Form.Check
              id={'checkbox-clear-all'}
              label={Localization.instance.getString('SETTINGS_ClearIfAllSelected')}
              checked={clearIfAllSelected}
              custom={true}
              onChange={toggleClearIfAllSelected}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default (
  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none">
    <path
      d="M7 0H17.9677C19.6246 0 20.9677 1.34315 20.9677 3V22C20.9677 23.6569 19.6246 25 17.9677 25H3C1.34315 25 0 23.6569 0 22L0 7L7 0Z"
      fill="#777777"
      stroke="none"
    />
    <path d="M0 7L7 0L6.99986 4.00011C6.9998 5.65692 5.65667 7 3.99986 7H0Z" fill="#AAAAAA" stroke="none" />{' '}
  </svg>
);

import { BaseDropdown } from './component/BaseDropdown';
import { OperationResponseItems } from '../../framework/openapi';
import { BusinessDropdownProps } from './component/type';
import { getOptionsWithLabelValuePair } from '../../utils/getOptionsWithLabelValuePair';
import { useEffect, useMemo } from 'react';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';

type AddressType = OperationResponseItems<
  'internal.v1.base-general',
  'GET /businessPartners/{businessPartner}/addresses'
>[0];

export type AddressesDropdownProps = Omit<BusinessDropdownProps, 'onChange'> & {
  businessPartner: string;
  onChange: (value: string | number, object: AddressType) => void;
  filterAddressType?: 'S' | 'D' | 'I' | 'C' | 'P';
};

const COLUMN_NAMES: string[] = ['Address number', 'Details'];

const filterByAddressType = (address: AddressType, filterAddressType: 'S' | 'D' | 'I' | 'C' | 'P') => {
  switch (filterAddressType) {
    case 'S':
      return address.shipToAddress;
    case 'I':
      return address.invoiceAddress;
    case 'C':
      return address.confirmationAddress;
    case 'P':
      return address.debtorAddress;
    case 'D':
      return address.dispatchAddress;
    default:
      return true;
  }
};

export const AddressesDropdown = ({ onChange, value, businessPartner, ...props }: AddressesDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { response, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-general',
    operationId: 'GET /businessPartners/{businessPartner}/addresses',
    labelAttribute: 'addressLine1',
    valueAttribute: 'addressNumber',
    params: { businessPartner },
    shouldPreventApiCall,
  });

  const addressOptions = useMemo(() => {
    return getOptionsWithLabelValuePair({
      list: response?.data?.data?.items.filter((add) => filterByAddressType(add, props.filterAddressType)) || [],
      labelAttribute: 'addressLine1',
      valueAttribute: 'addressNumber',
    });
  }, [response, props.filterAddressType]);

  useEffect(() => {
    if (response?.data?.data?.items) {
      const address = response?.data?.data?.items.find((add) => add.addressNumber === value);
      onChange(value, address);
    }
  }, [value, response?.data?.data?.items]);

  return (
    <BaseDropdown
      {...props}
      loading={loading}
      label="Address number"
      onChange={(value) => {
        const address = response?.data?.data?.items.find((add) => add.addressNumber === value);
        onChange(value, address);
      }}
      options={addressOptions}
      value={value}
      columnNames={COLUMN_NAMES}
    />
  );
};

import React, { useMemo } from 'react';
import {
  Autocomplete as MUIAutoComplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  useTheme,
} from '@mui/material';
import { TextField, TextFieldProps } from '../TextField';

// Extend MUI's SelectProps to include our custom props if needed
export type ComboProps = Omit<
  AutocompleteProps<{ value: string | number; label: string }, boolean, boolean, boolean>,
  'renderInput' | 'options'
> & {
  options: Array<{ value: string | number; label: string }>;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
  loading?: boolean;
  label?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  disableTyping?: boolean;
  placeHolder?: string;
  helperText?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  textFieldProps?: TextFieldProps;
  required?: boolean;
  readOnly?: boolean;
  inputProps?: TextFieldProps['inputProps'];
};

export const Combo: React.FC<ComboProps> = ({
  options,
  value,
  onChange,
  fullWidth = false,
  helperText,
  loading,
  size = 'medium',
  color,
  readOnly,
  disableTyping,
  placeHolder,
  renderInput,
  variant = 'standard',
  textFieldProps,
  required,
  ...props
}) => {
  const theme = useTheme();

  const readOnlyStyles = useMemo(
    () =>
      readOnly
        ? {
            '& .MuiInputLabel-root': {
              color: `${theme.palette.foreground[600]} !important`,
            },
            '& .MuiInputBase-root': {
              border: 'none',

              '& .MuiInputBase-input': {
                WebkitTextFillColor: theme.palette.text.disabled,
              },
              '&:before': {
                borderBottomStyle: 'solid',
              },
            },
          }
        : {},
    [readOnly, theme],
  );

  // Prevent propagation for the popup indicator click
  const handleIndicatorMouseDown = (event: React.MouseEvent) => {
    // Prevent the event from propagating to avoid reopening
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <MUIAutoComplete
      {...props}
      options={options}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField
                {...params}
                variant={variant}
                size={size}
                label={props.label}
                fullWidth={fullWidth}
                placeholder={placeHolder}
                helperText={helperText}
                color={color}
                isAutoComplete={true}
                onKeyDown={
                  disableTyping
                    ? (e) => {
                        e.preventDefault();
                      }
                    : undefined
                }
                loading={loading}
                required={required}
                readOnly={readOnly}
                sx={readOnlyStyles}
                inputProps={{ 'data-1p-ignore': '', ...params.inputProps, ...props.inputProps, 'data-tip': props.id }}
                {...textFieldProps}
              />
            )
      }
      value={value}
      onChange={onChange}
      size={size}
      disabled={props.disabled}
      readOnly={readOnly}
      slotProps={{
        ...props.slotProps, // Spread external slotProps
        popupIndicator: {
          ...props.slotProps?.popupIndicator, // Merge external popupIndicator slotProps
          sx: {
            color: readOnly ? theme.palette.text.disabled : undefined, // Make arrow appear disabled
            pointerEvents: readOnly ? 'none' : 'auto', // Disable clicks
            opacity: readOnly ? 0.5 : 1, // Reduce opacity for disabled effect
          },
          onMouseDown: (event: React.MouseEvent<Element, MouseEvent>) => {
            handleIndicatorMouseDown(event); // Call internal logic
          },
        },
      }}
    />
  );
};

import { useTheme as useMuiTheme } from '@mui/material';
import type { Theme } from '@mui/material/styles';

// Re-export the Theme type which includes our extensions
export type { Theme };

export function useTheme(): Theme {
  return useMuiTheme();
}

export default useTheme;

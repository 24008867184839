export default (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5047 10C19.5047 14.9706 15.4742 19 10.5023 19C5.53048 19 1.5 14.9706 1.5 10C1.5 5.02944 5.53048 1 10.5023 1C13.8345 1 16.7438 2.80989 18.3003 5.5M17.0351 10.9877L19.2857 8.73766L21.5363 10.9877M13.875 12.4541L10.5 11.3291V6.625"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

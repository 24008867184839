import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createSelector } from '@reduxjs/toolkit';
import { DesktopReducer, desktopState } from './desktopReducer';
import { SettingsReducer, settingsState } from './settingsReducer';
import { AppReducer, AppState } from './appReducer';
import { User } from '../../types/user';

export interface RootState {
  readonly desktop: desktopState;
  readonly settings: settingsState;
  readonly app: AppState;
}

const rootReducer = combineReducers({
  desktop: DesktopReducer,
  settings: SettingsReducer,
  app: AppReducer
});

export const selectDesktopSettings = (state: RootState): Record<string, any> => state.desktop.settings;

export const selectCurrentUserId = (state: RootState): string =>
  selectDesktopSettings(state)?.regionals?.user?.[0]?.$?.userId;
export const selectCurrentUserName = (state: RootState): string => selectDesktopSettings(state)?.fullName;
export const selectCurrentUser: (state: RootState) => User = createSelector(
  [selectCurrentUserId, selectCurrentUserName],
  (id, name) => {
    return {
      id: id,
      username: id,
      name: name
    };
  }
);

export default createStore(rootReducer, applyMiddleware(thunk));

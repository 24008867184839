import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';

import Editor, { CellPluginList, Value, ValueWithLegacy } from '@react-page/editor';
import image from '@react-page/plugins-image';
import divider from '@react-page/plugins-divider';
import background, { ModeEnum } from '@react-page/plugins-background';
import spacer from '@react-page/plugins-spacer';
import contactForm from './plugins/ContactForm';
import './styles/styles.css';
import singleValue from './plugins/SingleValue';
import pieChart from './plugins/PieChart';
import quickLinks from './plugins/QuickLinks';
import dashboardTable from './plugins/DashboardTable';
import customComponent from './plugins/CustomComponent';
import { defaultSlate } from './plugins/slate';
import companyInformation from './plugins/CompanyInformation';
import calenderEvents from './plugins/MSGraph/CalenderEvents/index';
import toDoList from './plugins/MSGraph/ToDoList/index';
import singleValueNew from './plugins/SingleValueNew';
import lastBusinessPartners from './plugins/LastBusinessPartners';
import lastSalesOrders from './plugins/LastSalesOrders';
import areas from './plugins/Areas';
import rssReader from './plugins/RssReader';
import anyTable from './plugins/AnyTable';
import enquiries from './plugins/Enquiries';
import dkCompanyInformation from './plugins/DKCompanyInformation';
// @ts-ignore
const cellPlugins: CellPluginList = [
  defaultSlate,
  image,
  contactForm,
  divider,
  background({
    enabledModes: ModeEnum.COLOR_MODE_FLAG | ModeEnum.IMAGE_MODE_FLAG | ModeEnum.GRADIENT_MODE_FLAG
    // @ts-ignore
  }),
  spacer,
  singleValue,
  pieChart,
  rssReader,
  quickLinks,
  dashboardTable,
  dkCompanyInformation,
  companyInformation,
  customComponent,
  calenderEvents,
  toDoList,
  singleValueNew,
  lastBusinessPartners,
  lastSalesOrders,
  areas,
  anyTable,
  enquiries
];

export const ContentEditor = (
  props: { editing: boolean; content: ValueWithLegacy | null; setContent: (v: Value) => void } = {
    editing: false,
    content: null,
    setContent: (v: Value) => {}
  }
) => {
  return (
    <div style={{ padding: '8px 0px' }}>
      <Editor readOnly={!props.editing} cellPlugins={cellPlugins} value={props.content} onChange={props.setContent} />
    </div>
  );
};

import { Card, Stack } from '@mui/material';
import { IconButton, Text, useIptorTranslation } from '@iptor/base';
import { OperationResponseItems } from '@iptor/business';
import { useState } from 'react';
import EditAddress from '../../dialogs/EditAddress';
import { AddressTypesTranslation } from './constants';

type AddressType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/addresses'>[0];

const isEditable = (addressType: string, orderStatus: number) => {
  if (['C', 'D', 'S'].includes(addressType) && orderStatus <= 30) {
    return true;
  }
  if (['I', 'P'].includes(addressType) && orderStatus <= 45) {
    return true;
  }
  return false;
};

const AddressCard = ({
  address,
  orderStatus,
  customer,
  onAddressEdit,
}: {
  address: AddressType;
  orderStatus: number;
  customer: string;
  onAddressEdit: () => void;
}) => {
  const { t } = useIptorTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const editable = isEditable(address.addressType, orderStatus);

  return (
    <Card
      sx={(theme) => ({
        minHeight: '188px',
        padding: theme.spacing(3),
        borderRadius: theme.spacing(1),
        color: theme.palette.text.primary,
        boxShadow: 'none',
      })}
    >
      {openEdit && (
        <EditAddress
          open={openEdit}
          address={address}
          customer={customer}
          handleClose={(refresh) => {
            refresh && onAddressEdit();
            setOpenEdit(false);
          }}
        />
      )}
      <Stack>
        <Text fontWeight={600} fontSize={20} position="relative" marginBottom={2}>
          {t(AddressTypesTranslation[address.addressType as keyof typeof AddressTypesTranslation] as any)}
          {editable && (
            <IconButton
              onClick={() => setOpenEdit(true)}
              size="small"
              icon="edit"
              id="edit"
              variant="text"
              sx={(theme) => ({
                color: theme.palette.text.primary,
                position: 'absolute',
                right: '0',
                top: '0',
                display: 'flex',
                zIndex: 1,
              })}
            />
          )}
        </Text>
        {address.address ? (
          <Text id="addressNumber" fontWeight={600} fontSize={16}>
            {t('sales_orders:ADDRESS_labelAddressNumber')}: {address.address}
          </Text>
        ) : (
          <Text fontWeight={600} fontSize={16} color="text.secondary">
            {t('sales_orders:ADDRESS_noAddress')}
          </Text>
        )}
        <Text id="addressInfo" fontSize={16} color="text.secondary">
          {[
            address.name,
            address.addressLine1,
            address.addressLine2,
            address.addressLine3,
            address.addressLine4,
            address.county,
            address.state,
            address.country,
            address.postalCode,
          ]
            .filter(Boolean)
            .join(', ')}
        </Text>
      </Stack>
    </Card>
  );
};

export default AddressCard;

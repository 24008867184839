import { LiveSearchApiWrapper, LiveSearchApiWrapperProps } from './wrapper/LiveSearchWrapper';
import { Clients } from '../../framework/openapi';

export type LiveSearchItemsProps = Omit<
  LiveSearchApiWrapperProps<'internal.v1.base-inventory', 'GET /items'>,
  'apiClient' | 'apiFunction' | 'columnKey'
>;

export const LiveSearchItems = ({ columnIds, ...props }: LiveSearchItemsProps) => {
  return (
    <LiveSearchApiWrapper
      {...props} // needs to be on the beginning to not override specified below props for the defaults
      apiClient={Clients.Internal.Base.V1.Inventory}
      apiFunction={'GET /items'}
      columnKey={'item'}
      columnIds={columnIds ? columnIds : ['item', 'description', 'internalName']}
    />
  );
};

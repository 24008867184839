import React from 'react';

export default (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" color="inherit">
    <path
      stroke="currentColor"
      d="M2.03746 1.5H15.9625C16.1168 1.55412 16.2566 1.64283 16.3713 1.75935C16.486 1.87588 16.5725 2.01711 16.6242 2.17224C16.6759 2.32736 16.6914 2.49227 16.6695 2.65432C16.6477 2.81637 16.589 2.97127 16.4981 3.10714L11.1423 9V16.5L6.85767 13.2857V9L1.50188 3.10714C1.41093 2.97127 1.35228 2.81637 1.33042 2.65432C1.30856 2.49227 1.32407 2.32736 1.37577 2.17224C1.42747 2.01711 1.51397 1.87588 1.62867 1.75935C1.74336 1.64283 1.8832 1.55412 2.03746 1.5Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

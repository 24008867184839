import { TableFilter, TableSettings, Text, useClientSettings, useIptorTranslation } from '@iptor/base';
import { IptorTable, openAPIManager, OperationResponseItems } from '@iptor/business';
import { CircularProgress, Stack } from '@mui/material';
import { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from 'react';

import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import OrderSimulation from './OrderSimulation';
// import { formatPrice } from '../../../utils/formatPrice';
interface Props {
  currentOrder: number;
  selectedAssortmentGroup: { assortmentGroup: string; assortmentID: string };
  handleClose: () => void;
  setisAddDisabled: Dispatch<SetStateAction<boolean>>;
}
type AssortmentLineType = OperationResponseItems<
  'internal.v1.base-orders',
  'GET /assortments/{assortmentID}/lines'
>[0] & { warehouse?: string };

const CircularProgressLoading = (props: { value?: string }) => {
  return (
    <div style={{ display: 'flex' }}>
      {props.value === undefined ? <CircularProgress size={'15px'} /> : props.value}
    </div>
  );
};

const AssortmentItemsTable = forwardRef(
  ({ selectedAssortmentGroup, currentOrder, handleClose, setisAddDisabled }: Props, ref) => {
    const [selectedRows, setSelectedRows] = useState<AssortmentLineType[]>([]);
    const { notify } = useOrderNotifications();

    useImperativeHandle(ref, () => ({
      handleAddOrderLines,
    }));
    const { formatCurrency } = useClientSettings();
    const { t } = useIptorTranslation();

    useEffect(() => {
      if (selectedRows.length > 0 && selectedRows.reduce((acc, { quantity }) => acc + quantity, 0) > 0) {
        setisAddDisabled(false);
      } else {
        setisAddDisabled(true);
      }
    }, [selectedRows]);

    const handleAddOrderLines = async () => {
      const postRequests = selectedRows.map((orderLineItem) => {
        const { line, item, quantity, unit, warehouse } = orderLineItem;

        return {
          line,
          request: openAPIManager.execute(
            'internal.v1.base-orders',
            'POST /salesOrders/{order}/lines',
            { order: currentOrder },
            { item, unit, orderQuantitySalesUnit: quantity, warehouse },
          ),
        };
      });

      const results = await Promise.allSettled(postRequests.map(({ request }) => request));

      const failedRequests = results
        .map((result, index) => ({ result, orderLine: postRequests[index].line }))
        .filter(({ result }) => result.status === 'rejected') as Array<{
        result: PromiseRejectedResult;
        orderLine: number;
      }>;

      if (failedRequests.length > 0) {
        failedRequests.forEach(({ result, orderLine }) =>
          notify(
            'error',
            (result.reason as any)?.response?.data?.messages,
            false,
            t('sales_orders:ASSORTMENTS_failedToCopyLines', '', { orderLine }),
          ),
        );
        const successfulPromises = results.length - failedRequests.length;
        successfulPromises > 0 &&
          notify('success', t('sales_orders:ASSORTMENTS_remainingOrderLinesAdded', '', { successfulPromises }));
      } else {
        notify('success', t('sales_orders:ASSORTMENTS_allOrderLinesAdded'));
      }
      handleClose();
    };

    return (
      <IptorTable
        tableID={`assortmentItemTable-${selectedAssortmentGroup.assortmentGroup}-${selectedAssortmentGroup.assortmentID}`}
        selectable="multiple"
        apiId="internal.v1.base-orders"
        apiEndpoint="GET /assortments/{assortmentID}/lines"
        columns={[
          { display: t('sales_orders:TABLE_COLUMN_orderLine'), key: 'line', dataType: 'number' },
          {
            display: t('sales_orders:TABLE_COLUMN_items'),
            key: 'items',
            cellRenderer: ({ row }) => (
              <Stack direction="row">
                <Stack direction="column">
                  <span id={'itemId'}>{row.item}</span>
                  <small id={'description'}>{row.description}</small>
                </Stack>
              </Stack>
            ),
          },
          { display: t('sales_orders:TABLE_COLUMN_unit'), key: 'unit' },
          {
            display: t('sales_orders:TABLE_COLUMN_warehouse'),
            key: 'warehouse',
            cellRenderer: ({ value }) => {
              return <CircularProgressLoading value={value} />;
            },
          },
          {
            display: t('sales_orders:TABLE_COLUMN_availableQuantity'),
            key: 'availableQuantity',
            dataType: 'number',
            cellRenderer: ({ value }) => {
              return <CircularProgressLoading value={value} />;
            },
          },
          {
            display: t('sales_orders:TABLE_COLUMN_quantity'),
            key: 'quantity',
            dataType: 'number',
          },
          {
            display: t('sales_orders:TABLE_COLUMN_unitPrice'),
            key: 'salesPrice',
            dataType: 'number',
            cellRenderer: ({ value, row }) => {
              return (
                <CircularProgressLoading
                  value={
                    value && value != '-'
                      ? formatCurrency({ amount: value, currency: row.salesPrimaryCurrency })
                      : value
                  }
                />
              );
            },
          },
          {
            display: '',
            key: 'orderSimulation',
            disableFilter: true,
            disableSort: true,
            cellRenderer: ({ row, setColumnValueForRow }) => {
              return <OrderSimulation row={row} setColumnValueForRow={setColumnValueForRow} orderId={currentOrder} />;
            },
          },
        ]}
        additionalFilters={
          <Stack gap={2} direction="row" alignSelf="center" height="max-content">
            <Text>{t('sales_orders:ASSORTMENTS_itemsSelected')}</Text>
            <Text fontWeight={600}>{selectedRows.length}</Text>
          </Stack>
        }
        additionalActions={
          <>
            <TableFilter />
            <TableSettings />
          </>
        }
        apiParams={selectedAssortmentGroup}
        onSelectedRowsChange={({ rows }) => {
          setSelectedRows(rows);
        }}
      />
    );
  },
);
export default AssortmentItemsTable;

import { Accordion, Checkbox, Text, TextField, useIptorTranslation, FormControlLabel } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Order } from '../../../constants';
import {
  ProjectCostCodeDropdown,
  ProjectPhaseDropdown,
  ProjectsDropdown,
  VatHandlingCodesDropdown,
} from '@iptor/business';

type ProjectInfoFeesProps = {
  createProcess?: boolean;
  control?: Control<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
};

const ProjectInfoFees = ({ control, errors, createProcess }: ProjectInfoFeesProps) => {
  const { t } = useIptorTranslation();
  return (
    <Accordion headingTitle={t('common:TXT_Project_Info_And_Fees')}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid size={12}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Fees')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="freightFee"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Freight')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.freightFee}
                  helperText={errors?.freightFee?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="insuranceFee"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Insurance')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.insuranceFee}
                  helperText={errors?.insuranceFee?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="insurancePercentage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Insurance_Percentage')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.insurancePercentage}
                  helperText={errors?.insurancePercentage?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="postageFee"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Postage')}
                  fullWidth
                  error={!!errors?.postageFee}
                  id={field.name}
                  helperText={errors?.postageFee?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="administrationFees"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Admin_Fees')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.administrationFees}
                  helperText={errors?.administrationFees?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="invoiceFee"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Invoice_Fee')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.invoiceFee}
                  helperText={errors?.invoiceFee?.message}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="vatHandlingCodeOfFreight"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VatHandlingCodesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  fullWidth
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_VAT_Freight')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="vatHandlingCodeOfInsurance"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VatHandlingCodesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  fullWidth
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_VAT_Insurance')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="vatHandlingCodeOfPost"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VatHandlingCodesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_VAT_Postage')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="vatHandlingCodeOfAdmFee"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VatHandlingCodesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  fullWidth
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_VAT_Admin')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="vatHandlingCodeOfInvFee"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <VatHandlingCodesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  fullWidth
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_VAT_Invoice')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="manualFreightFee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  label={t('common:TXT_Main_Freight_Fee')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="calculateInsurance"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  label={t('common:TXT_Calc_Insur')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="administrationFee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  label={t('common:TXT_Admin_Fee')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="invoiceFee"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} checked={!!field.value} />}
                  label={t('common:TXT_Invoice_Fee')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid size={12} sx={{ marginTop: 3 }}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Project_Information')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="project"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ProjectsDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  color={error ? 'error' : 'primary'}
                  id={field.name}
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Project')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name={'projectPhase'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ProjectPhaseDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Phase')}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="projectCostCode1"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ProjectCostCodeDropdown
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  fullWidth
                  label={t('common:TXT_Cost_Code')}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default ProjectInfoFees;

import React from 'react';
import { Global, css } from '@emotion/react';

export const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-RegularItalic.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
      }

      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
      }

      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

      @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
      }

      body {
        font-family: 'Open Sans', sans-serif;
      }
    `}
  />
);

import da_common from './da/common.json';
import de_common from './de/common.json';
import en_common from './en/common.json';
import en_sales_orders from './en/sales_orders.json';
import es_common from './es/common.json';
import es_co_common from './es_co/common.json';
import es_mx_common from './es_mx/common.json';
import fi_common from './fi/common.json';
import fr_common from './fr/common.json';
import it_common from './it/common.json';
import nl_common from './nl/common.json';
import nl_be_common from './nl_be/common.json';
import no_common from './no/common.json';
import pl_common from './pl/common.json';
import pt_common from './pt/common.json';
import sv_common from './sv/common.json';
import zh_common from './zh/common.json';
export const resources = {
  da: {
    common: da_common,
  },
  de: {
    common: de_common,
  },
  en: {
    common: en_common,
    sales_orders: en_sales_orders,
  },
  es: {
    common: es_common,
  },
  es_co: {
    common: es_co_common,
  },
  es_mx: {
    common: es_mx_common,
  },
  fi: {
    common: fi_common,
  },
  fr: {
    common: fr_common,
  },
  it: {
    common: it_common,
  },
  nl: {
    common: nl_common,
  },
  nl_be: {
    common: nl_be_common,
  },
  no: {
    common: no_common,
  },
  pl: {
    common: pl_common,
  },
  pt: {
    common: pt_common,
  },
  sv: {
    common: sv_common,
  },
  zh: {
    common: zh_common,
  },
};

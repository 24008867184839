import { Button, Icon, Text, useIptorTranslation, useClientSettings } from '@iptor/base';
import { openAPIManager, OperationResponseDataData } from '@iptor/business';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { formatErrorText } from '../../utils/formatError';
import { useOrderNotifications } from '../../hooks/useOrderNotification';

interface Props {
  discountToDelete: DiscountType;
  orderLine?: number;
  open: boolean;
  currency: string;
  handleClose: (refresh?: boolean) => void;
}
type DiscountType = OperationResponseDataData<
  'internal.v1.base-orders',
  'GET /salesOrders/{order}/discounts/{generalDiscountKey}'
>;
function DeleteDiscount({ discountToDelete, open, handleClose, orderLine, currency }: Props) {
  const { t } = useIptorTranslation();
  const [loading, setLoading] = useState(false);
  const { notify } = useOrderNotifications();
  const { formatNumber, formatCurrency } = useClientSettings();

  const handleDelete = () => {
    setLoading(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        orderLine
          ? 'DELETE /salesOrders/{order}/lines/{orderLine}/discounts/{generalDiscountKey}'
          : 'DELETE /salesOrders/{order}/discounts/{generalDiscountKey}',
        {
          generalDiscountKey: discountToDelete.generalDiscountKey,
          order: discountToDelete.order,
          sequence: discountToDelete.sequence,
          sequenceGroup: discountToDelete.sequenceGroup,
          orderLine,
        },
      )
      .then(() => {
        notify('success', t('sales_orders:DISCOUNT_deletedSuccessfully'));
        handleClose();
      })
      .catch((error) => {
        notify('error', formatErrorText(error), false, t('sales_orders:DISCOUNT_deleteError'));
        handleClose();
      });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
        <Icon icon="delete3" color="error.main" size="L" />
        <Text variant="h6" fontWeight={600}>
          {t('sales_orders:DISCOUNT_deletedTitle')}
        </Text>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Icon id="cancel" icon="cancel01" onClick={() => handleClose(false)} />
        </Box>
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text variant="subtitle1" paddingBottom={1}>
          {t('sales_orders:DISCOUNT_discount')}
          <b>{` ${discountToDelete.discountId} - ${discountToDelete.discountIdDescription}
           (${
             discountToDelete.discountPercentage
               ? formatNumber({ number: discountToDelete.discountPercentage }) + '%'
               : formatCurrency({
                   amount: discountToDelete.discountAmountPerUnit,
                   currency,
                 })
           }) `}</b>
          {t('sales_orders:DISCOUNT_willBeDeleted')}
          <b> {t('common:TXT_Want_To_Proceed')}</b>
        </Text>
      </DialogContent>
      <DialogActions>
        <Button id="cancel" variant="outlined" onClick={() => handleClose(false)}>
          {t('common:TXT_Cancel')}
        </Button>
        <Button id="delete" variant="contained" onClick={() => handleDelete()} loading={loading}>
          {t('common:TXT_Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDiscount;

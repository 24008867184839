import { ChevronRight } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  Shadows,
} from '@mui/material';
import { styled } from '@mui/system';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { TextSize, TextWeight } from '../../constants/Typography';
import { Switch } from '../Switch';
import { Text } from '../Text';
import { useIptorTranslation } from 'src/framework/translations';

export type AccordionProps = MuiAccordionProps & {
  headingTitle: string;
  expanded?: boolean;
  moreInfo?: ReactNode | ReactNode[];
  size?: 'small' | 'medium';
  extraActions?: ReactNode;
};

const StyledChevronRight = styled(ChevronRight)<{ expanded?: boolean }>`
  background-color: ${({ theme, expanded }) =>
    expanded ? theme.palette.primary[400] : theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  transform-origin: center;
`;

const StyledAccordion = styled(MuiAccordion)`
  padding: 1rem 0.5rem;
  box-shadow: ${({ theme }) => (theme?.shadows as Shadows)[1]};
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.paper} !important;
  background-image: none;
  &.MuiAccordion-root {
    position: unset;
    border-radius: ${({ theme }) => theme.spacing(1)};
    background-color: ${({ theme }) => theme.palette.background.default};
    &.Mui-expanded {
      margin: 0;
    }
  }

  &:before {
    display: none;
  }
` as typeof MuiAccordion;

const StyledAccordionSummary = styled(AccordionSummary)`
  border: none;
  border-radius: ${({ theme }) => theme.spacing(1)};

  &.MuiAccordionSummary-root {
    background-color: ${({ theme }) =>
      theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.paper} !important;
    border: none;
    &.Mui-focusVisible {
      svg {
        outline: solid ${({ theme }) => theme.palette.primary['500']};
        outline-width: 2px;
      }
    }
  }

  p.MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary[400]};
  }

  & > .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin-inline-start: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  & > .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.palette.primary[400]};
    transform-origin: center;
    transition: ${({ theme }) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      theme.transitions?.create('transform', { duration: theme.transitions?.duration.short })};
  }

  .Mui-expanded {
    &.MuiAccordionSummary-expandIconWrapper {
      color: ${({ theme }) => theme.palette.background.default};
      transform: rotate(90deg);
      transform-origin: center;
    }
  }

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.paper} !important;
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1)};
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1)};
`;

export const Accordion = ({
  expanded = false,
  size = 'medium',
  headingTitle,
  moreInfo,
  children,
  extraActions,
  ...props
}: PropsWithChildren<AccordionProps>) => {
  const [internalExpanded, setInternalExpandedValue] = useState<boolean>(expanded);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { onChange } = props;
  const { t } = useIptorTranslation();

  useEffect(() => {
    setInternalExpandedValue(expanded);
  }, [expanded]);

  const handleAccordionChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    setInternalExpandedValue(expanded);
  };

  const expandMoreInfoCollapsible = (event: React.ChangeEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
  };

  return (
    <StyledAccordion
      expanded={internalExpanded}
      onChange={(event, expanded) => (onChange ? onChange(event, expanded) : handleAccordionChange(event, expanded))}
      sx={{ padding: size === 'small' ? 0 : (theme) => `${theme.spacing(1)} ${theme.spacing(0.5)}`, ...props.sx }}
      {...props}
    >
      <StyledAccordionSummary expandIcon={<StyledChevronRight expanded={internalExpanded} />}>
        <Text
          color="secondary.main"
          fontSize={size === 'small' ? TextSize.XXS : TextSize.S}
          fontWeight={TextWeight.SEMIBOLD}
        >
          {headingTitle}
        </Text>
        {internalExpanded && !!moreInfo && (
          <Switch
            checked={isOpen}
            size="small"
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
            onChange={expandMoreInfoCollapsible}
            label={t('common:TXT_More_Info')}
            labelPosition="start"
          />
        )}
        {extraActions}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>

      <Collapse in={isOpen} sx={(theme) => ({ padding: theme.spacing(2), whiteSpace: 'pre-wrap' })}>
        <Text fontSize={20} fontWeight={TextWeight.SEMIBOLD} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
          {t('common:TXT_More_Info')}
        </Text>
        {moreInfo}
      </Collapse>
    </StyledAccordion>
  );
};

import { createTheme, Theme, ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import { DatePicker as MUIDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { TextField } from '../../constants/TextField';
import { Radius } from '../../constants/Radius';
import { useClientSettings } from '../../utils/ClientSettings/index';
import { useMemo } from 'react';

const Popper = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .MuiDateCalendar-root': {
        backgroundColor: theme.palette.background.default,
        borderRadius: `${Radius.M}px`,
      },
    }),
  },
};
const IconButton = {
  styleOverrides: {
    root: {
      padding: '10px',
      margin: '-10px',
    },
  },
};

const newTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      MuiTextField: TextField,
      MuiPopper: Popper,
      MuiIconButton: IconButton,
    } as ThemeOptions['components'],
  });

//TODO: Fix the type and check all usages as this currently breaks a lot of handlers
//export const DatePicker = <TDate extends PickerValidDate, TEnableAccessibleFieldDOMStructure extends boolean = false>(props: DatePickerProps<TDate, TEnableAccessibleFieldDOMStructure> & { size?: 'small' | 'medium' }) => {
export const DatePicker = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: DatePickerProps<any> & {
    size?: 'small' | 'medium';
    readOnly?: boolean;
  },
) => {
  const { size = 'medium', format, readOnly, ...rest } = props;
  const theme = useTheme();
  const clientSettings = useClientSettings();

  const dtFormat = useMemo(
    //Upper case required as dayjs dateFormats are always uppercase
    () => format ?? clientSettings?.dateFormat?.toUpperCase(),
    [format, clientSettings.dateFormat],
  );

  const readOnlyStyles = useMemo(
    () =>
      readOnly
        ? {
            '& .MuiInputLabel-root': {
              color: `${theme.palette.foreground[600]} !important`,
            },
            '& .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                WebkitTextFillColor: `${theme.palette.text.disabled} !important`,
                cursor: 'default',
              },
              '& .MuiIconButton-root': {
                display: 'none',
              },
            },
          }
        : {},
    [readOnly, theme],
  );

  return (
    <ThemeProvider theme={newTheme}>
      <MUIDatePicker
        {...rest}
        format={dtFormat}
        disabled={props.disabled}
        readOnly={readOnly}
        sx={{
          ...(props.sx || {}),
          ...readOnlyStyles,
          minWidth: 135,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            ...props.slotProps?.textField,
            size,
            InputProps: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...((props.slotProps?.textField as any)?.InputProps || {}),
              readOnly,
            },
            inputProps: {
              'data-for': 'global',
              'data-iscapture': 'true',
              'data-tip': `${(props.slotProps?.textField as any)?.id}`,
              ...(props.slotProps?.textField as any)?.inputProps,
            },
          },
        }}
      />
    </ThemeProvider>
  );
};

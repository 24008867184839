import { openAPIManager } from '@iptor/business';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  warehouse: string;
  item: string;
  price?: number;
}

function WarehouseAvailableQuantity(props: Props) {
  const [availableBalance, setAvailableBalance] = useState(undefined);
  const { item, warehouse } = props;

  useEffect(() => {
    openAPIManager
      .execute('internal.v1.base-inventory', 'GET /itemBalances/{item}', {
        item,
        warehouse,
      })
      .then((res) => {
        setAvailableBalance(res.data.data.items[0].availableQuantity);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.price]);

  return <>{availableBalance === undefined ? <CircularProgress size={'15px'} /> : availableBalance}</>;
}

export default WarehouseAvailableQuantity;

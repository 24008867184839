import { Accordion, Checkbox, DatePicker, NumericField, Text, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import {
  LiveSearchSuppliers,
  OperationResponseDataData,
  PurchaseCurrencyDropdown,
  PurchaseMannerOfTransportDropdown,
  PurchaseOrderTypesDropdown,
  PurchaseTermsOfDeliveryDropdown,
  PurchaseUnitsDropdown,
} from '@iptor/business';
import dayjs from 'dayjs';
import { formatDateToPayloadFormat } from '../../../utils/formatDate';

export type BtBPurchaseOrderAndBtBIROProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  setValue: UseFormSetValue<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function BtBPurchaseOrderAndBtBIRO({ control, setValue, orderLineInfo }: BtBPurchaseOrderAndBtBIROProps) {
  const { t } = useIptorTranslation();
  return (
    <Accordion
      headingTitle={t('sales_orders:ITEM_INFO_btbPurchaseOrderTitle')}
      moreInfo={
        <Grid container spacing={2}>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:Att_category_PURCHASE')}`}</Text>
            <Text id="purchaseOrder" variant="body1">
              {orderLineInfo.purchaseOrder || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Purchase_Line')}`}</Text>
            <Text id="purchaseOrderLine" variant="body1">
              {orderLineInfo.purchaseOrderLine || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Purchase_Status')}`}</Text>
            <Text id="btbPurchaseOrderLineStatus" variant="body1">
              {orderLineInfo.btbPurchaseOrderLineStatus}
            </Text>
          </Grid>
          {orderLineInfo.iROrderType === '' ? (
            <Grid size={3} direction="row" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <Checkbox
                sx={{ '& .MuiCheckbox-root': { padding: 0, margin: 0 } }}
                value={orderLineInfo.transitDelivery}
                checked={!!orderLineInfo.transitDelivery}
                id="transitDelivery"
                disabled
              />
              /
              <Checkbox
                id="directDelivery"
                value={orderLineInfo.directDelivery}
                checked={!!orderLineInfo.directDelivery}
                disabled
              />{' '}
              <Text variant="body1">{t('sales_orders:ITEM_INFO_transDirect')}</Text>
            </Grid>
          ) : (
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>
                {t('common:TXT_Supplier')}
              </Text>
              <Text id="supplierText" variant="body1">
                {orderLineInfo.supplier}
              </Text>
            </Grid>
          )}
        </Grid>
      }
    >
      {orderLineInfo.iROrderType === '' ? (
        <>
          <Text
            variant="h6"
            sx={(theme) => ({
              marginBottom: theme.spacing(3),
            })}
          >
            {t('sales_orders:ITEM_INFO_btbPurchaseOrder')}
          </Text>
          <Grid container spacing={2}>
            <Grid size={3}>
              <Controller
                name="supplier"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <LiveSearchSuppliers
                    error={!!error}
                    onLiveSearchInputChange={(_, value) => {
                      field.onChange(value);
                    }}
                    id={field.name}
                    liveSearchInputValue={field.value || ''}
                    autoFocus
                    fullWidth
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Supplier')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="purchasePriceLine"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericField
                    {...field}
                    fullWidth
                    error={!!error}
                    id={field.name}
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Purchase_Price')}
                    buttonLayout="none"
                    onChangeNumeric={(value) => field.onChange(value)}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="purchaseCurrency"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseCurrencyDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    color={error ? 'error' : 'primary'}
                    id={field.name}
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Purchase_Currency')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="purchaseUnit"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseUnitsDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    id={field.name}
                    color={error ? 'error' : 'primary'}
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Purchase_Unit')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="orderTypeBtB"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseOrderTypesDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    id={field.name}
                    color={error ? 'error' : 'primary'}
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Purchase_Order_Type')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="purchaseTermsOfDelivery"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseTermsOfDeliveryDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    id={field.name}
                    color={error ? 'error' : 'primary'}
                    helperText={error ? error.message : ''}
                    label={t('sales_orders:ITEM_INFO_purchaseOrderTod')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="purchaseMannerOfTransport"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseMannerOfTransportDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    color={error ? 'error' : 'primary'}
                    id={field.name}
                    helperText={error ? error.message : ''}
                    label={t('sales_orders:ITEM_INFO_purchaseOrderMot')}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="plannedProductionDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    slotProps={{
                      textField: {
                        id: 'purchaseMannerOfTransport',
                      },
                    }}
                    label={t('common:TXT_Planned_Production')}
                    onChange={(value) => setValue('plannedProductionDate', formatDateToPayloadFormat(value))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="confirmedProductionDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    slotProps={{
                      textField: {
                        id: field.name,
                      },
                    }}
                    label={t('common:TXT_Confirmed_Production')}
                    onChange={(value) => setValue('confirmedProductionDate', formatDateToPayloadFormat(value))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="plannedCompletionDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    slotProps={{
                      textField: {
                        id: field.name,
                      },
                    }}
                    label={t('common:TXT_Planned_Completion')}
                    onChange={(value) => setValue('plannedCompletionDate', formatDateToPayloadFormat(value))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="confirmedCompletionDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    slotProps={{
                      textField: {
                        id: field.name,
                      },
                    }}
                    label={t('common:TXT_Confirmed_Completion')}
                    onChange={(value) => setValue('confirmedCompletionDate', formatDateToPayloadFormat(value))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="promisedDispatchDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    label={t('common:TXT_Dispatch_Date')}
                    slotProps={{
                      textField: {
                        id: field.name,
                      },
                    }}
                    onChange={(value) => setValue('promisedDispatchDate', formatDateToPayloadFormat(value))}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Text
            variant="h6"
            sx={(theme) => ({
              marginY: theme.spacing(3),
            })}
          >
            {t('sales_orders:ITEM_INFO_btbIro')}
          </Text>
          <Grid container spacing={2}>
            <Grid size={3}>
              <Controller
                name="iROrderType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PurchaseOrderTypesDropdown
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    fullWidth
                    id={field.name}
                    color={error ? 'error' : 'primary'}
                    helperText={error ? error.message : ''}
                    label={t('common:TXT_Purchase_Order_Type')}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Accordion>
  );
}
export default BtBPurchaseOrderAndBtBIRO;

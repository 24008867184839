/*
    SUMMARY rows 
    ============
    ° "rows" should reflect always the current state of the rows and is the result of server AND user interaction
      ° Each time after server interaction, the "rows" are initialized from the (updated) data coming from the server (useEffect[data] ==> setRows(updated))
      ° Each time the user changes a row, the rdg component is informing us about the changes through a callback (onRowsChange={(gridRows: any) => ...). Be
        aware that rdg is never changing an existing row instance. It is giving us each time a NEW INSTANCE of a row with the changed values (complying to 
        REACT rules). According to the same rules, we should not change the array "rows", but take a copy of "rows" first and then for each grid row (containing 
        the new instances of rows with the changed data) we should find (id based) the corresponding original row (the instance of the row with the old data) 
        inside the copied "rows" and replace it by the new instance.
        
        IMPORTANT REMARKS
        1. It is important to understand that inside the rdg callback (onRowsChange={(gridRows: any) => ...) we are only replacing instances in the (copy of) 
           "rows". As a consequence, at that moment all other rows based variables are holding still a reference to the original row with the old data (including 
           filteredRows, sortedRows, refSortedRows, ...) !!
        2. Creating a copy of rows allows React to see there is a state change and as a consequence it will trigger rerendering (allowing other code in the render
           and inside effects to be executed whenever needed)
        3. We are also adding the entries of the "changed" gridRows to a new array refChangedTableRows, but we're not ready to explain this at this point (see 
           further)
  
    ° "filteredRows" is the result of a memoized function that is executed in each render cycle. This memoized function encapsulates the real filter function that
      is only executed when something is changing in the corresonding dependency array, in all other cases it returns the same result from the previous execution.
      ° originally the execution of the filtering function was triggered by any change of "rows" ("rows" was part of the dependency array). This had some undesired 
        side effects. For instance, if you are filtering on order line number less than 100 and you want to "add" an new order line with line number 120, the 
        moment you enter the line number, the row is filtered out and no longer visible (as user changes row => onRowsChange={(gridRows: any) ==> rows = [... rows] 
        ==> "rows" instance changed ==> recalculation of "filteredRows" ==> filtering out the "added" line (remark: from the point of the tables view, rows are 
        not really adedd, the user is changing a preloaded "empty" EXISTING row, eg fast order entry)
      ° To eliminate the unwanted side effects, the memoized function that returns the filtered rows should not be called each time when rows are changing, more 
        specific it should not be called when "rows" are changing as the result of user interaction. 
  
    ° "refFilterBaseRows": extra variable created to replace the rows in the "filteredRows" dependency array. The new trigger for filtering calculation is now only 
      changed when it is really needed to do the filtering calculation. That is : 
      ° each time after server interaction, the "refFilterBaseRows" are initialized from the (updated) data coming from the server (useEffect[data] ==> 
        refFilterBaseRows.current = updated)
      ° each time the user explicitly "asks" for filtering by changing the filter criteria, the "refFilterBaseRows" is assigned to the same instance of "rows". 
        Remember (see important remarks "rows" 1), that when the user is changing data, the only row collection that includes references to the new row instances 
        with the changed data is the "rows" array. Going back to previous example, assume the user is adding order line 120 (not immediately disapearing anymore 
        as "rows" are no longer triggering the execution of the actual filtering function and at that time the "refFilterBaseRows" is not changed). And assume that 
        after entering new line 120, the user changes the filter to see only order lines greater than 100. At that time the user will expect the newly added order 
        line to be included in the result. As at changing filtering time "rows" is the only collection that "sees" the user changes, there is no other way than 
        update the "refFilterBaseRows" from the "rows". The assigment from "rows" to "refFilterBaseRows" is part of a render cycle but only when filters were 
        changed by the user.

      REMARK: 
       1. in fact there is also a third case - see furter.
       2. Important remarks 2 in section about "rows" claims that the copy of "rows" is needed for React, here is a second reason now. As after changing 
          filtering, "refFilterBaseRows" references the same instance as the "rows" and replacing a row in the existing "rows" would be equal to changing the 
          "refFilterBaseRows".
  
    ° "sortedRows": is the result of a memoized function that is executed in each render cycle. This memoized function encapsulates the real sorting function that 
      is only executed when something is changing in the corresponding dependency array, in all other cases it returns the same result from the previous execution. 
      ° The actual sorting is executed starting from the "filteredRows" (similar to filtering, you could see this as "refSortBaseRows"). As a consequence, 
        originally the execution of the sorting function was triggered by any change of "filteredRows" or by any explicit user action that changed the requested 
        sorting.
      ° The fact that "sortedRows" are calculated starting from "filteredRows" explains the third case when filtered rows should be recalculated. When the user 
        requires new sorting, he will expect to see his changes in the result. Changed rows will only be available when we change the "refFilterBaseRows" first
      ° the "sortedRows" are binded to the rows of the rdg table component that we are using (<ReactDataGrid rows={sortedRows} ...). Makes sense: as a user we 
        want the table only to show the rows that are the result of the requested filtering and in the sequence of the requested sorting.
      ° Almost there ;). However by changing the dependency from "rows" to "refFilterBaseRows" for recalculating the "filteredRows", the "filteredRows" result 
        is not always seeing all changes anymore (again see important remarks "rows" 1). And by using the "filteredRows" as the base to get the "sortedRows", the 
        same goes for the "sortedRows". So if we keep it this way, the changes will not be visible anymore in the table (rerender ==> sortedRows binded to rdg 
        table rows & sorted rows still referencing old instances ==> rdg table rows still referencing old instances ==> user does not see his changes anymore)
  
    ° "refChangedTableRows": a new variable was introduced to keep references to the changed rows. 
      ° All changed rows communicated to us by the rdg table component through its callback (onRowsChange={(gridRows: any) => ...) are added to this new list. 
        The variable needs to be cleared after each server interaction (result of server interaction always overrides any user input). Remark when server 
        interaction is triggered by the user, the server gets all the data that was changed by the user (using "rows" as input for the server). At that point 
        it is up to the server to decide if the changes will be accepted or ignored depending on the requested action (eg <update record> action versus <refresh 
        data> action).  
      ° Ideally, to make it better understandable, another new variable should be created now, something like "refResultingTableRows". It should contain the 
        result by applying the changes of "refChangedTableRows" on a copy of the "sortedRows" and this variable should then be used to bind with the rows of 
        the rdg table component. However this would require a lot of renaming and if we do this right now, it would result in lots of merging conficts. So 
        renaming some stuff could be done in a next refactoring step when we are sure nobody else is also touching code in this file... 
      ° To avoid merging conflicts, we will abuse the existing "sortedRows" to implement the functionality we need (as described for "refChangedTableRows"). 
        Additional complexity is caused by the fact that this variable is the result of a memoized function and therefor readonly! So the only possibility to 
        get the result we want is changing the encapsulated sorting function. To force the sorted rows to include the merging of the new instances, we have no 
        other option than adding the "refChangedTableRows" in the dependency array of the memoized function. To avoid that we executing each time the sorting 
        itself (performance!!), we will introduce a new parameter "fullRebuildSortedRows', so that the function is able to distinguish the fact that he should 
        re-execute the complete sorting or if that he only has to merge the "changed" rows.
           
      Remark: merging rows could also be done using the existing "rows", but for performance reasons the "refChangedTableRows" was created (remind that "rows" 
        potentially can have thousand of rows or even more).
   
    ° "refSortedRows": is not something new. It contains a copy of "sortedRows". Due to the way React is working, we saw stale data in "sortedRows" inside some 
      effects (stale data meaning the data that is available at the time we create an effect function vs the actual data we need when we execute the function). 
*/

import React, {
  FocusEvent,
  FunctionComponent,
  MouseEvent as RMouseEvent,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Column, ContextOption, ContextOptions, DataTableProps } from './DataTable';
import ReactDataGrid, {
  DataGridHandle,
  FormatterProps,
  HeaderRendererProps,
  Row as GridRow,
  RowRendererProps,
  SortColumn,
} from 'react-data-grid';
import '../styles/Table.scss';
import { TableActions, WindowActions } from '../types/actionTypes';
import { connect } from 'react-redux';
import { Icons, SquareIcon } from './SquareIcon';
import { ButtonGroup, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { ReactComponent as DropdownArrow } from './../assets/dropdown-arrow.svg';
import { Action } from './Button';
import { ColumnVisibility } from './ColumnVisibility';
import ReactTooltip from 'react-tooltip';
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from '@firefox-devtools/react-contextmenu';
import { createPortal } from 'react-dom';
import { DraggableHeaderRenderer, Filter, FilterContext } from './DraggableHeaderRenderer';
import { useAdvancedEffect, useStateCallback } from '../types/hooks';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { Formatters } from '../framework/parsers';
import { Constraint } from '../framework/parsers/constraint';
import {
  exportToClip,
  exportToCsv,
  exportToPdf,
  exportToXlsx,
  exportType,
  generateRows_ClientFormat,
  generateRows_ISO,
  generateRows_XLSX,
} from './helpers/exportUtils';
import { RemoveTrailingDotsFormatter } from '../framework/parsers/RemoveTrailingDotsFormatter';
import { PositiveIntegerFormatter } from '../framework/parsers/PositiveIntegerFormatter';
import { PositiveIntegerWithZerosFormatter } from '../framework/parsers/PositiveIntegerWithZerosFormatter';
import { DecimalFormatter } from '../framework/parsers/DecimalFormatter';
import { PositiveDecimalFormatter } from '../framework/parsers/PositiveDecimalFormatter';
import { IntegerFormatter } from '../framework/parsers/IntegerFormatter';
import { NumberFormatter } from '../framework/parsers/NumberFormatter';
import { PositiveDecimalWithZerosFormatter } from '../framework/parsers/PositiveDecimalWithZerosFormatter';
import { ColorMap } from '../types/colorMap';
import { Checkbox } from './Checkbox';
import { Workbook } from 'exceljs';
import { XTNumber, XTString } from '../framework/parsers/models/XTData';
import { EditWrapper } from './EditWrapper';
import axios, { AxiosResponse } from 'axios';
import { LocaleContext } from '../App';
import { Localization } from '../framework/localization/Localization';
import { usePrevious } from '../types/hooks';
import { AperioViewCommand, AperioViews } from '../types/AperioViews';
import { debounceWithChangeTimeoutInterval, useConfirmationDialog } from '@iptor/base';
import { Command } from '../framework/base/command';
import { AperioView } from '../framework/handlers/aperioView';
import { useComplexState } from '../hooks/complex-state';
import { format } from 'date-fns';
import { Container, Row as BTRow, Col as BTCol } from 'react-bootstrap';
import { Option, SettingsCombo } from './SettingsCombo';
import { FormattingOptions, LocaleFormattingOptions } from '../views/partials/Setting';
import { useNotes } from '../framework/your-note/NotesProvider';
import { XTValueToNote } from '../framework/your-note/XTValueParser';
import { Loader } from '../views/partials/Loader';
import { showDialog } from 'framework/base/dialogUtils';

// import xlsx = stream.xlsx;
const basicRowHeight = 35;

function rowKeyGetter(row: any) {
  return row.id;
}

export const TableContext = React.createContext<{
  columns: Column[];
  panelID: string;
  attributes: any;
  formID: string;
  yes: string;
  no: string;
}>({
  columns: [],
  panelID: '',
  attributes: {},
  formID: '',
  yes: 'Y',
  no: 'N',
});

enum ImportOptions {
  CLIPBOARD = 'CLIPBOARD',
  FILE = 'FILE',
}

type ImportState = {
  status: 'NOT_ACTIVE' | 'REQUESTED' | 'CONFIRMED' | 'LOADING_EXTRA_PAGES';
  importOption: ImportOptions;
  textData: string[][];
  selectedRowIndex: number;
  selectedRowId: string;
  selectedColumnId: string;
  allowedColumns: { id: string; header: string }[];
  prevNumberOfSortedRows: number;
  pagedownExecuted: boolean;
};
const createInitialImportState = (): ImportState => {
  return {
    status: 'NOT_ACTIVE',
    importOption: ImportOptions.CLIPBOARD,
    textData: [[]],
    selectedRowIndex: -1,
    selectedRowId: '',
    selectedColumnId: '',
    allowedColumns: [],
    prevNumberOfSortedRows: 0,
    pagedownExecuted: false,
  };
};

const TableComponent: FunctionComponent<DataTableProps> = ({
  attributes,
  columns,
  data,
  contextOptions,
  onContextMenuClick,
  panelID,
  tableHiddenColumns = [],
  loadMore,
  id,
  formID,
  tableColumnResizing,
  tableDefaultAction,
  tableSortBy = [],
  defaultActions = [],
  setTableHiddenColumns,
  setColumnResize,
  setDefaultAction,
  setTableSortBy,
  tableColumnOrder,
  setTableColumnOrder,
  resetTableColumnOrder,
  resetTableColumnWidths,
  tableTooltipSequence,
  setTooltipSequence,
  isTableTooltipEnabled,
  toggleTooltip,
  toggleID,
  updateContext = () => {},
}) => {
  const {
    attachments,
    windowID,
    windowData,
    sendCustomCommand,
    addToRowCommand,
    removeFromCommand,
    localization,
    setAction,
    sendCommand,
    setScope,
    lastRows,
    settings,
    promptHandler,
    getCommand,
    cursor,
    errors,
    reload,
    windowLayout,
    formAperioLinks,
    getFormLayout,
  } = useContext(CommandContext);

  const { setFocussedElement, setFieldValue, setDisplayValue, focussedElementRef } = useNotes();
  const { localeSettings } = useContext(LocaleContext);
  let { decimalsign, no, yes } = settings?.regionals.codes[0].$ || {};
  //TODOOOOOOOOOOOOOOOOOOOOOOOOOO   let { century_break_year, dateSeparator, format6, format8, format8Sep } = settings?.regionals.dateformats[0].$ || {};
  //=====================================================================================================
  const refSpecialKeys = useRef<{ isShiftKeyDown: boolean; isCtrlKeyDown: boolean }>({
    isShiftKeyDown: false,
    isCtrlKeyDown: false,
  });
  // Remark: do not define this as state !!!  State change ==> rerender ==> editor is unmounted (==> supplied value lost)
  // const [isShiftClicked, setIsShiftClicked] = useState(false);
  // const [isCtrlClicked, setIsCtrlClicked] = useState(false);
  //=====================================================================================================
  const [isShown, setIsShown] = useState(false);
  const [selectedRowIdxs, setSelectedRowIdxs] = useStateCallback<Set<string>>(new Set());
  const refSelectedRowIds = useRef<Set<string>>(new Set());
  refSelectedRowIds.current = selectedRowIdxs; // REMARK_SKE_20241001: no idea why I need to copy thif value, but if I use selectedRowIdxs in handleExternalPageDownRequest, the set is always empty
  const prevSelectedRowIdxs = usePrevious(selectedRowIdxs);
  const prevWindowData = useRef<any>(null);
  const refContextMenu = useRef<any>(null);
  const refSetAperioViewCommandDebounceInterval = useRef(50); // Remark: server interaction (see further)
  const refFreezeScrolling = useRef({ on: false, scrollLeft: 0, scrollTop: 0 });
  const [markedRows, setMarkedRows] = useState<any[]>([]);
  useLayoutEffect(() => {
    // there is conflict wtih DataGrid component - they, due to own reasons, enforce to select cell on useLayoutEffect
    // that means, that if the contextMEnu is opened, then it lose focus just after being opened
    // to prevent that behaviour, we set back focus to contextMenu on the same useLayoutEffect
    const contextMenuOpened = document.getElementsByClassName('react-contextmenu--visible')?.length > 0;
    if (contextMenuOpened) {
      setTimeout(() => refContextMenu.current?.menu?.focus(), 0); // Required for Firefox
    }
  });
  useEffect(() => {
    if (selectedRowIdxs.size > 0) {
      let lastRowIDS = Object.keys(lastRows?.[formID]?.[panelID] || {});
      for (let i in lastRowIDS) {
        let _rowID = lastRowIDS[i];
        if (!selectedRowIdxs.has(_rowID)) {
          delete lastRows[formID][panelID][_rowID];
        }
      }
    }
    prevSelectedRowIdxs?.forEach((rowId) => {
      if (id === 'SpooledfilesTable' || id === 'MyDocumentsTable' || id === 'MessageQueueTable') {
        removeFromCommand(panelID, rowId, -1);
      }
    });
  }, [selectedRowIdxs]);
  const [sortColumns, setSortColumns] = useState<readonly Readonly<SortColumn>[]>(tableSortBy);
  const refSortColumns = useRef<readonly Readonly<SortColumn>[]>(sortColumns);
  const [importState, setImportState] = useState<ImportState>(createInitialImportState());
  const [IsImportDropdownOpen, setIsImportDropdownOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const [sourceFormattingInfo, setSourceFormattingInfo] = useState({
    dateFormat: localeSettings.dateFormat,
    decimalSeparator: localeSettings.decimalSeparator,
    groupSeparator: localeSettings.groupSeparator,
  });
  const [localeFormattingOptions, setLocaleFormattingOptions] = useState<LocaleFormattingOptions>(
    FormattingOptions.getLocaleFormattingOptions(),
  );
  useEffect(() => {
    setLocaleFormattingOptions(FormattingOptions.getLocaleFormattingOptions());
  }, [localeSettings]);

  /*
  import data from file or clipboard
  */
  const importFrom = async (option: ImportOptions) => {
    // Any table cell should be selected first
    // ---------------------------------------
    if (currentColumn.current < 0 || currentSelection.current < 0) {
      displayWarning(
        Localization.instance.getString('TABLE_IMPORT_Please_select_a_table_cell_before_requesting_import'),
      );
      return;
    }

    // Get columns allowed for import
    // ------------------------------
    const allowedColumns: { id: string; header: string }[] = [];
    _columns.forEach((c) => {
      // IMPORTANT REMARK: loop over _columns as sequence of table columns needs to be respected (NOT columns) !!
      const col = columns.find((col) => col.id === c.id);
      if (col) {
        if (col.readonly === 'false' && col.visible !== 'false')
          allowedColumns.push({ id: col.id, header: col.Header });
      }
    });

    // At least one allowed column needed
    // ----------------------------------
    if (allowedColumns.length <= 0) {
      displayWarning(Localization.instance.getString('TABLE_IMPORT_No_valid_table_cells_available_to_import_data'));
      return;
    }

    // Collect table selection info
    // ----------------------------
    const selectedRowIndex = currentSelection.current;
    const selectedRowId = sortedRows[currentSelection.current].id;
    let selectedColumnId = _columns[currentColumn.current].id;
    if (!allowedColumns.find((col) => selectedColumnId === col.id)) {
      selectedColumnId = allowedColumns[0].id;
    }

    // Declare helper function
    // -----------------------
    const changeImportState = (option: ImportOptions, text: string) => {
      // --> Get data values from text (omit "empty" lines)
      const textData: string[][] = [];
      const onlyTabsInString = new RegExp(/^\t+$/);
      const textLines = text.split('\n');
      textLines.forEach((line: string) => {
        const _line = line.endsWith('\r') ? line.substring(0, line.length - 1) : line;
        if (!onlyTabsInString.test(_line) && _line.trim() !== '') {
          textData.push(_line.split('\t').map((col) => col.replaceAll('""', '"').replace(/(^"|"$)/g, '')));
        }
      });

      // --> Create new import state
      setImportState({
        status: 'REQUESTED',
        importOption: option,
        textData: textData,
        selectedRowIndex: selectedRowIndex,
        selectedRowId: selectedRowId,
        selectedColumnId: selectedColumnId,
        allowedColumns: allowedColumns,
        prevNumberOfSortedRows: 0,
        pagedownExecuted: false,
      });
    };

    // Handle clipboard import
    // -----------------------
    if (option === ImportOptions.CLIPBOARD) {
      if (window.isSecureContext) {
        try {
          const text = await navigator.clipboard.readText();
          changeImportState(option, text);
        } catch (error: any) {
          displayWarning(localization.getString('Copy_To_Clipboard_FireFox_Warning'));
        }
      } else {
        displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
      }
      return;
    }

    // Handle file import
    // ------------------
    if (option === ImportOptions.FILE) {
      // --> Declare helper function
      const uploadFiles = (e: Event) => {
        // ... determine selected file
        const input = e.target as HTMLInputElement;
        if (!input.files) return;
        const file: File = input.files[0];
        if (!file) return;

        // ... handle text files
        if (file.type.match(/text.*/)) {
          const reader = new FileReader();
          reader.onload = (evt) => {
            const result = evt.target?.result;
            const text =
              typeof result === 'string'
                ? result //Temp solution for converting CSV to tab delimited values
                    .split('\n')
                    .map((r) => r.split(/,(?=(?:[^\"]*\"[^\"]*\")*(?![^\"]*\"))/g).join('\t'))
                    .join('\n')
                : '';
            changeImportState(option, text);
          };
          reader.readAsText(file);
          return;
        }

        // ... handle excel files
        const reader = new FileReader();
        reader.onload = async (evt) => {
          const bstr = evt.target?.result as ArrayBuffer;
          const wb = new Workbook();
          try {
            await wb.xlsx.load(bstr);
          } catch (err) {
            displayWarning(Localization.instance.getString('TABLE_IMPORT_INVALID_FILE'));
            return;
          }
          const data = (await wb.csv.writeBuffer()).toString();
          const text = data.replace(/,/g, '\t'); // HTH: handle escape CSV characters !!
          changeImportState(option, text);
        };
        reader.readAsArrayBuffer(file);
      };

      // --> Show select file dialog
      const inputFile = document.createElement('input');
      inputFile.type = 'file';
      inputFile.onchange = (e) => uploadFiles(e);
      inputFile.click();

      // --> Restore table cell focus          // REMARK: (at least) needed when file selection is canceled !!
      tableRef.current?.selectCell({ idx: currentColumn.current, rowIdx: currentSelection.current }, true);
    }
  };

  const displayWarning = (warning: string) => {
    return showDialog({
      message: warning,
      completeScreenBlock: true,
      restrictOutsideClick: true,
      hideCancelButton: true,
      confirmText: Localization.instance.getString('TXT_OK'),
      overlayContainerId: 'legacy',
      onConfirm: () => {
        tableRef.current?.selectCell({ idx: currentColumn.current, rowIdx: currentSelection.current }, true);
      },
    });
  };
  /**
   * mark the rows for export
   * **/
  const setMarkAndUnmarkRows = (markedRows: string[] | null) => {
    refChangedTableRows.current = [...refChangedTableRows.current];
    if (markedRows === null) {
      setRows(
        rows.map((row: any) => {
          row.markedForCopy = false;
          refChangedTableRows.current.push(row);
          return row;
        }),
      );
    } else {
      setRows(
        rows.map((row: any) => {
          if (markedRows.indexOf(row.id) > -1) {
            row.markedForCopy = true;
            refChangedTableRows.current.push(row);
          }
          return row;
        }),
      );
    }
  };

  const [rows, setRows] = useState<any[]>([]);
  const refFilterBaseRows = useRef<any[]>([]);
  const refChangedTableRows = useRef<any[]>([]);
  const refSortedRows = useRef<any[]>([]);
  const fullRebuildSortedRows = useRef<boolean>(false);

  const currentSelection = useRef<number>(0);
  const currentColumn = useRef<number>(-1);
  const selectedDefaultAction = useRef<string | undefined>(undefined);
  const tableSpanRef = useRef<HTMLSpanElement>(null);
  const [selection] = useState<any>({});

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    /** Setting the X & Y for tab order calculation  */
    if (tableSpanRef.current) {
      const scrollInfo = XT.getScrollInfo(tableSpanRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  // register whether right mouse button down
  const refRightMouseButtonDown = useRef<boolean>(false);
  useEffect(() => {
    function onMouseDown(e: MouseEvent) {
      if (e.button === 2) {
        refRightMouseButtonDown.current = true;
      } else if (e.buttons >= 2) {
        /*
          Remark: code included to cover strange case I saw with Google, to simulate:
            1. browser not maximized
            2. left mouse button down
            3. right mouse button down (keep left down)
            4. move mouse cursor outside browser
            5. right mouse button UP (keep left down)
            6. move mouse cursor back inside browser
            7. right mouse button down (keep left down) ==> e.button = 0 (?????) but e.buttons = 3    <= 1 (left) + 2 (right)
        */
        if (e.buttons % 4 >= 2) refRightMouseButtonDown.current = true;
      }
    }

    function onMouseUp(e: MouseEvent) {
      if (e.button === 2)
        setTimeout(() => {
          refRightMouseButtonDown.current = false;
        }, 0); // IMPORTANT REMARK: without timeout the value is already false again when we need it, seems like the event is queued somewhere (REACT: batchedEventUpdates?)
    }

    /*
      Remark listeners:
        ° Adding event listener directly to document guarantees that mouseup event is also triggered when button released outside the browser
        ° Adding mousedown in capturing phase to make sure rdg cannot select cell before registering right mouse button down
        ° Adding mousedown NOT in capturing phase to make sure rdg can finish the select cell functionality before unregistering right mouse button down (and even then we need a timout, see previous important remark)
    */
    document.addEventListener('mousedown', onMouseDown, { capture: true });
    document.addEventListener('mouseup', onMouseUp, { capture: false });
    return () => {
      document.removeEventListener('mousedown', onMouseDown, { capture: true });
      document.removeEventListener('mouseup', onMouseUp, { capture: false });
    };
  }, []);

  // set updated default action
  useEffect(() => {
    selectedDefaultAction.current = tableDefaultAction;
  }, [tableDefaultAction]);

  // updated rows
  const isPageDownRequested = useRef<boolean>(false);
  const [isBottomReached, setIsBottomReached] = useState(false);
  const refServerFocusRequested = useRef<boolean>(false);
  const [importedRows, setImportedRows] = useState<any[]>([]);

  const [scrollToId, setScrollToId] = useComplexState({ scrollId: '-1' });
  const [addFocusListener, setAddFocusListener] = useComplexState({ requested: false }); // Remark: only false during first rendering cycle (mounting) as needed focus sink not available yet at that time, "changes" to true every time data is changing (identifying server interaction)
  const [initialSelectedCell, setInitialSelectedCell] = useComplexState<{
    colIdx: number;
    rowIdx: number;
    isEditableTable: boolean;
    cursorSelectionStart: number;
    column: Column | undefined;
  }>({ colIdx: -1, rowIdx: -1, isEditableTable: false, cursorSelectionStart: -1, column: undefined });
  const [onInitialFocusInfo, setOnInitialFocusInfo] = useComplexState({ idxFirstRow: -2, tableScrollTop: 0 });

  const refPreventComponentScrolling = useRef(false);
  const preventComponentScrolling = (ms: number = 0) => {
    refPreventComponentScrolling.current = true;
    setTimeout(() => (refPreventComponentScrolling.current = false), ms);
  };

  useEffect(() => {
    // ============================
    // Get resulting rows from data
    // ============================
    let _rows = rows;
    let someRowsLoaded = !isPageDownRequested.current;
    // setIsPageDownRequested(false);
    let updated = data.map((row: any) => {
      let _row = _rows.find((r: any) => r.id === row.id);
      if (_row) {
        if (row.reloaded === false) {
          _row.rowConditionText = row.rowConditionText;
          return _row;
        } else {
          someRowsLoaded = true;
          return row;
        }
      }
      someRowsLoaded = true;
      return row;
    });
    setRows(updated);
    refFilterBaseRows.current = updated;
    fullRebuildSortedRows.current = true;
    refChangedTableRows.current = [];
    refFreezeScrolling.current.on = false;
    // if (isTableTooltipEnabled) ReactTooltip.rebuild();

    // =======================================
    // Initialization after server interaction
    // =======================================
    if (windowData !== prevWindowData.current) {
      prevWindowData.current = windowData;
      const _isPageDownRequested = isPageDownRequested.current;
      isPageDownRequested.current = false;
      refServerFocusRequested.current = false;
      refSetAperioViewCommandDebounceInterval.current = 50; // Remark: server interaction might request to select a row ==> no need to debounce this for 300 ms (user will never be fast enough to select another row)

      // Determine whether table completely loaded
      // =========================================
      /*
      If number of rows IS NOT a multiple of pagesize then the table is always completely loaded.

      If number of rows IS a multiple of pagesize then the table might or might not be completely
      loaded. Two systems need to be supported:
        1.  more advanced programs might indicate the end of file condition with a disabled constraint
            on the pagedown event. This type of programs is not handled here. The loadmore function
            covers this kind of programs and won't do an actual server call if pagedown is disabled.
        2.  some older programs do not support this functionality and for those we will need to do a
            server call anyway. Only when that call does not give any new rows anymore, we are sure we
            reached the end of the file

      Remark: for some specific programs Enterprise sends separate rows containing text (eg sales order
      maintenance). These rows are handled in the client by adding them as an array to the corresponding
      "parent" row in the "rowConditionText" property (see XT.getRows(...)). In order to check if the
      number of rows sent by Enterprise corresponds to a multiple of the pagesize those text rows need
      to be included again.
    */
      let _isBottomReached = false;
      if (updated.length > 0) {
        // ignore eventual first cycle without data
        const pagesize = +attributes.pagesize;
        const totalNumberOfRows =
          updated.length + updated.flatMap((x) => x.rowConditionText).filter((x) => x !== undefined).length;
        // --> Always bottom reached if number of rows is not multiple of pagesize
        if (!isNaN(pagesize) && pagesize !== 0 && totalNumberOfRows % pagesize !== 0) {
          _isBottomReached = true;

          // --> Bottom reached if previous pagedown request didn't load any new data
        } else if (!someRowsLoaded) {
          _isBottomReached = true;
        }
      } else if (updated.length === 0) {
        _isBottomReached = true;
      }
      setIsBottomReached(_isBottomReached);

      // Submit request to load extra page //REMOVE autopagedown from client side
      // =================================
      // if (!_isBottomReached && loadMore) {
      // Remark: fixed size tables have no loadmore function (eg w/w window definitions)
      // setTimeout(() => {
      //   if (tableRef?.current) {
      //     let table = tableRef.current;
      //     let target = table.element;
      //     // console.info(target, data, data.length, rows.length);
      //     if (target && data && data.length > 0) {
      //       const pagesize = +attributes.pagesize;
      //       if (isNaN(pagesize) || pagesize === 0 || data.length % pagesize === 0) {
      //         //No scroll present
      //         if (data.length * basicRowHeight <= target.clientHeight - basicRowHeight) {
      //           //More records can be loaded
      //           // console.info('initial load');
      //           // loadMore?.(panelID);
      //           isPageDownRequested.current = loadMore?.(panelID) || false;
      //         }
      //       }
      //     }
      //   }
      // }, 500);
      // }

      // Handle pagedown request from import
      // ===================================
      if (importState.status === 'LOADING_EXTRA_PAGES') {
        setImportState({ ...importState, status: 'CONFIRMED', pagedownExecuted: true });
        return;
      }

      // Reset table when panel completely reloaded
      // ==========================================
      let selected = new Set<string>();
      const panelAttributes = windowData.form?.panel?.find((panel: any) => panel.$?.id === panelID)?.$;
      const _currentColumn = currentColumn.current;
      if (panelAttributes?.reloaded === 'true') {
        tableRef.current?.element?.scrollTo({ top: 0, left: 0, behavior: 'auto' });

        if (
          !document.body.hasAttribute('data-uploading-attachment') &&
          (currentColumn.current !== -1 || currentSelection.current !== 0)
        ) {
          if (updated.length > 0 && (currentColumn.current !== 0 || currentSelection.current !== 0)) {
            const prvActiveElement = document.activeElement;
            tableRef.current?.selectCell({ idx: 0, rowIdx: 0 }); // Remark: there might be no (sorted) rows but rdg will ignore select if no rows available
            if (document.activeElement !== prvActiveElement) {
              if (typeof (prvActiveElement as any).focus === 'function') {
                (prvActiveElement as any).focus(); // NTH_SKE: forced add/remove tabindex (-1) if not available???
              }
            }
            if (document.activeElement !== prvActiveElement) {
              if (typeof (document.activeElement as any).blur === 'function') {
                (document.activeElement as any).blur(); // make document.body active element
              }
            }
          }
          currentColumn.current = -1;
          currentSelection.current = 0;
        } else if (document.body.hasAttribute('data-uploading-attachment')) {
          selected = selectedRowIdxs;
          document.body.removeAttribute('data-uploading-attachment');
        }

        // Determine selected rows
        // =======================
      } else {
        currentColumn.current = -1;

        let highlighted = false;
        // let tableBottom = tableRef?.current?.getBoundingClientRect()?.bottom || 0;
        // let tableTop = tableRef?.current?.getBoundingClientRect()?.top || 0;
        Object.keys(lastRows?.[formID]?.[panelID] || {}).forEach((rowID) => {
          let row = data.find((r: any) => r.id === rowID);
          if (row) {
            highlighted = true;
            selected.add(rowID);
          }
        });
        if (!highlighted) {
          data?.forEach((row: any) => {
            if (row.highlight === true) {
              selected.add(row.id);
              highlighted = true;
            }
          });
        }

        if (!highlighted) {
          if (attributes.rowposition) {
            let pos = XT.getValueFromWindow({ data: windowData }, panelID, attributes.rowposition);
            if (!columns.find((col: Column) => col.prompt === 'true' || col.readonly === 'false')) {
              if (pos?.trim() !== '') {
                selected.add(pos);
                highlighted = true;
              }
            }
          }
        }

        // if (!highlighted && data.length > 0) {
        //   selected.add('1');
        // }
      }

      // Set resulting selected rows
      // ===========================
      setSelectedRowIdxs(selected);

      // Scroll to first selected row
      // ============================
      if (selected.size > 0) {
        let _scrollToId = selected.values().next().value;
        setScrollToId({ scrollId: _scrollToId });
      }

      // Case server pagedown: restore selectiion of last selected row (before actual server pagedown)
      if (selected.size === 0 && !isEditableTable && _isPageDownRequested && refSelectedRowIds.current?.size > 0) {
        currentColumn.current = _currentColumn;
        setSelectedRowIdxs(new Set(refSelectedRowIds.current));
        refServerFocusRequested.current = true;
      }

      // Request to add focus listener        --> covering selection first cell/row whenever focus moves to the table
      // =============================
      if (updated.length > 0) setAddFocusListener({ requested: true });

      // Select initial cell
      // ===================
      if (cursor) {
        const cursorPos = cursor.split(',');
        if (cursorPos.length === 2) {
          // Get requested dds row & column from cursor
          // ------------------------------------------
          const cursorColumn = cursorPos[0];
          const cursorRow = cursorPos[1];

          // Get corresponding column & select cell (if not yet done)
          // --------------------------------------------------------
          let cursorSelectionStart = -1;
          let column = columns.find((c: Column) => c.ddspos?.startsWith(cursorColumn + ','));

          if (!column) {
            column = columns.find((c) => {
              if (c.autoselect === 'false') {
                const pos = c.ddspos?.split(',');
                if (!pos) {
                  return false;
                } else if (pos.length !== 2) {
                  return false;
                } else if (isNaN(+pos[0])) {
                  return false;
                } else if (isNaN(+cursorColumn)) {
                  return false;
                } else if (isNaN(+c.limit)) {
                  return false;
                } else if (+cursorColumn >= +pos[0] && +cursorColumn < +pos[0] + +c.limit) {
                  cursorSelectionStart = +cursorColumn - +pos[0];
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            });
          }

          if (column) {
            const colIdx = _columns.findIndex((e) => e.key === column!.id); // Remark: had to add "!", typescript bug ??????
            const ddsPos = column.ddspos?.split(',');
            if (ddsPos.length === 2) {
              // --> 1. get row index from rowIndexField
              let rowIdx: number = -1;
              let rowIndexField = attributes.rowIndexField || attributes.rowfield || attributes.rowposition || 'ZZSFRC';
              if (rowIndexField) {
                rowIdx = +XT.getValueFromWindow({ data: windowData }, panelID, rowIndexField) - 1;
              }
              // --> 2. calculate row index from cursor field (if still needed)
              if (isNaN(rowIdx) || rowIdx === -1) {
                rowIdx = +cursorRow - +ddsPos[1]; // Remark: column contains dds pos of first row
              }
              // --> select cell
              if (colIdx > -1 && rowIdx > -1) {
                refServerFocusRequested.current = true;
                if (colIdx !== currentColumn.current || rowIdx !== currentSelection.current) {
                  setInitialSelectedCell({ colIdx, rowIdx, isEditableTable, cursorSelectionStart, column });
                }
              }
            }
          }
        }
      }
    }
  }, [data, windowData]);

  useEffect(() => {
    if (scrollToId.scrollId !== '-1') {
      let { top, bottom } = tableRef?.current?.element?.getBoundingClientRect() || {};
      let target = (tableRef?.current?.element as HTMLElement)?.querySelector(
        `.row-wrapper[data-row='${scrollToId.scrollId}']`,
      ) as HTMLElement;
      let { top: targetTop, bottom: targetBottom } = target?.getBoundingClientRect() || {};
      if (!target || targetTop < (top || 0) || targetBottom > (bottom || 1000)) {
        const toIndex = sortedRows.findIndex((r: any) => r.id === scrollToId.scrollId);
        tableRef?.current?.scrollToRow(toIndex === -1 ? 0 : toIndex);
      }
    }
  }, [scrollToId]);

  useEffect(() => {
    columns.forEach((col) => {
      const applyGridLines = (retries = 0) => {
        if (col && !col._gridlinesStyleInfo) {
          if (tableSpanRef && tableRef?.current?.element) {
            let cell = tableSpanRef?.current?.querySelector('.table-cell');
            if (!cell) cell = tableSpanRef?.current?.querySelector('.editable-cell');
            if (cell) {
              const info = getGridlinesStyleInfo(col, cell);
              if (info) {
                const backgroundSize = `${(info.characterWidth * 2) / info.characterHeight}em`;
                tableRef.current.element.style.setProperty('--gridlines-background-size', backgroundSize);
              }
            }
          }
          if (col && !col._gridlinesStyleInfo) {
            if (retries < 10) {
              // avoid infinite loop, until now not able to simulate case where multiple retries were needed
              retries += 1;
              setTimeout(() => applyGridLines(retries), 10);
            }
          }
        }
      };

      if (attributes.gridlines === 'true') setTimeout(applyGridLines, 0); // Remark: timeout needed for rendering cells first
      if (col.autoselect === 'false') col.preferredCaretPosition = 99999;
    });
  }, [attributes.gridlines, columns]);

  const refSubmitAperioEventCommand = useRef<((row: any, columns: Column[], windowData: any) => {}) | undefined>(
    undefined,
  );
  useEffect(() => {
    const getRowSelectedAperioEventInfo = (
      tableId: string,
      formAperioLinks: AperioViews.Link[],
    ): { event: AperioViews.Link.Event; link: AperioViews.Link } | undefined => {
      for (const link of formAperioLinks) {
        const event = link.events.find(
          (event) => event.type === AperioViews.EventTypes.ON_ROW_SELECTION && event.tableId === tableId,
        );
        if (event) return { event, link }; // Remark: only ONE selected event expected (else return "first")
      }
      return undefined;
    };
    const getAperioViewCommand = (
      link: AperioViews.Link,
      row: any,
      columns: Column[],
      command: Command | undefined,
      windowData: any,
      regionalSettings: Record<string, any>,
    ): AperioViewCommand => {
      // Create command with parameter values of controls binded to fields
      // =================================================================
      const aperioViewCommand = AperioView.getCommandBase(link, command, windowData, getFormLayout(), regionalSettings);

      // Add parameters with values from columns
      // =======================================
      const parameters = link.parameters.filter((parameter) => !!parameter.tableId); // filter on columns
      for (const parameter of parameters) {
        // Get column
        // ----------

        // --> Execute
        const column = columns.find((col: any) => col.id === parameter.fieldId);

        // --> Error handling
        if (!column) {
          // add error
          aperioViewCommand.errors.push({
            type: AperioViewCommand.ErrorTypes.ERROR,
            message: `Layout XML contains no info for column ${parameter.fieldId} in table ${parameter.tableId}.`,
          });

          // handle next parameter
          continue;
        }

        // Get actual value of linked column
        // ---------------------------------

        // --> Error handling
        if (!(parameter.fieldId in row)) {
          // add error
          aperioViewCommand.errors.push({
            type: AperioViewCommand.ErrorTypes.ERROR,
            message: `Unexpected error: column ${parameter.fieldId} missing in row of table ${parameter.tableId}.`,
          });

          // handle next parameter
          continue;
        }

        // --> Execute
        const serverValue = (row[parameter.fieldId] || '').trimEnd();

        // Convert actual value from server format to "aperio format"
        // ----------------------------------------------------------

        // --> Initialize
        let componentType = 'edit';
        if (column.formatter?.toLowerCase() === 'time') {
          componentType = 'time';
        } else if (column.formatter?.toLowerCase() === 'date') {
          componentType = 'date';
        } else if (column.formatter?.toLowerCase() === 'boolean') {
          componentType = 'checkbox';
        }

        // --> Execute
        const valueInfo: {
          value: string | number | boolean | undefined;
          error: string | undefined;
          warning: string | undefined;
        } = XT.convertValueFromServerToAperioFormat(
          { fieldId: parameter.fieldId, panelId: parameter.tableId, value: serverValue },
          componentType,
          column,
          parameter.fieldType,
          regionalSettings,
          true, // inColumnTableMode
        );

        // --> Handle error/warning
        if (valueInfo.error) {
          aperioViewCommand.errors.push({ type: AperioViewCommand.ErrorTypes.ERROR, message: valueInfo.error });
          continue;
        } else if (valueInfo.warning) {
          aperioViewCommand.errors.push({ type: AperioViewCommand.ErrorTypes.WARNING, message: valueInfo.warning });
        }

        // Add parameter
        // -------------
        aperioViewCommand.params[parameter.aperioId] = valueInfo.value;
      }

      return aperioViewCommand;
    };
    const setAperioViewCommand = (
      link: AperioViews.Link,
      row: any,
      columns: Column[],
      command: Command | undefined,
      windowData: any,
      regionalSettings: Record<string, any>,
    ) => {
      updateContext({
        aperioViewCommand: getAperioViewCommand(link, row, columns, command, windowData, regionalSettings),
      });
    };

    let cancelDebouncedFunction: (() => void) | undefined;
    const link = getRowSelectedAperioEventInfo(id, formAperioLinks || [])?.link;
    if (link) {
      const { debouncedFunction, cancel, changeTimeoutInterval } = debounceWithChangeTimeoutInterval(
        setAperioViewCommand,
        refSetAperioViewCommandDebounceInterval.current,
      );
      refSubmitAperioEventCommand.current = (row: any, columns: Column[], windowData: any) => {
        const promise = debouncedFunction(link, row, columns, getCommand(), windowData, settings?.regionals);
        if (refSetAperioViewCommandDebounceInterval.current < 300) {
          refSetAperioViewCommandDebounceInterval.current = 300; // Remark: User interaction starts here, debounce 300 ms to allow the user to select the "final" row he wants by pressing for instance 3 times arrow down
          changeTimeoutInterval(refSetAperioViewCommandDebounceInterval.current);
        }
        return promise;
      };
      cancelDebouncedFunction = cancel;
    }
    return () => {
      cancelDebouncedFunction?.();
      refSubmitAperioEventCommand.current = undefined;
    };
  }, [id, formAperioLinks, updateContext, getCommand, settings?.regionals, getFormLayout]);

  function getGridlinesStyleInfo(column: Column, fromElement: Element): Column.gridlinesStyleInfo | undefined {
    if (column && !column._gridlinesStyleInfo) {
      if (fromElement) {
        const style = window.getComputedStyle(fromElement);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context!.font = style.font;
        column._gridlinesStyleInfo = {
          characterHeight: parseFloat(style.fontSize), // remark: parse removes px
          characterWidth: context!.measureText('0123456789').width / 10,
          paddingLeft: parseFloat(style.paddingLeft), // remark: parse removes px
        };
      }
    }
    return column?._gridlinesStyleInfo;
  }

  const isEditableTable = useMemo(() => {
    // Table is considered to be editable if at least one cell CAN BE editable (ignore row dependent constraints here)
    return columns.some((col: Column) => col.readonly === 'false' && col.visible !== 'false');
  }, [columns]);

  const invalidCells = useMemo((): Record<string, string[]> => {
    // NTH: get only first error and rename hasInvalidCells

    // Handle obvious cases
    // ====================
    if (!errors) return {};
    if (!isEditableTable) return {};

    // No invalid cells if no error constraints defined
    // ================================================
    const ecColumns = columns.filter((col) => col.errorconstraints);
    if (ecColumns.length === 0) return {};

    // Return invalid cells
    // =================
    return data.reduce((errCells: Record<string, string[]>, row: any) => {
      const errorColumns = ecColumns.reduce((errColumns: string[], col: Column) => {
        const eConstraint = new Constraint(col.errorconstraints || '');
        const isInvalid = eConstraint.evaluate(
          row.flags?.split('').map((x: string) => x === '1'),
          false,
        );
        if (isInvalid) errColumns.push(col.id);
        return errColumns;
      }, []);
      if (errorColumns.length > 0) errCells[row.id] = errorColumns;
      return errCells;
    }, {});
  }, [data, columns, errors, isEditableTable]);

  //TODO: Update type
  const [filters, setFilters] = useState<Filter>({
    task: '',
    priority: 'Critical',
    issueType: 'All',
    developer: '',
    complete: undefined,
    enabled: false,
  });

  const refFilters = useRef<Filter>(filters);

  const tableRef = useRef<DataGridHandle>(null);
  const importDropdownRef = useRef<HTMLButtonElement>(null);
  const importModalCancelButtonRef = useRef<HTMLButtonElement>(null);

  // Inactivate scroll freezing whenever focus back to parent active window       --> More info in onScrollCapture
  // ======================================================================
  const refWindow = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const onFocus = () => {
      if (refFreezeScrolling.current.on) {
        setTimeout(() => {
          if (refFreezeScrolling.current.on) refFreezeScrolling.current.on = false;
        }, 100);
      }
    };

    refWindow.current = tableRef.current?.element?.closest(`.window div[data-window="${windowID}"]`) || null;
    if (refWindow.current) {
      const divWindow = refWindow.current;
      divWindow.addEventListener('focus', onFocus, { capture: true });
      return () => {
        divWindow.removeEventListener('focus', onFocus, { capture: true });
      };
    }
  }, []);

  // Activate scroll freezing whenever rendering while not in active window       --> More info in onScrollCapture
  // ======================================================================
  if (!refFreezeScrolling.current.on && !tableRef.current?.element?.closest('.window.active')) {
    refFreezeScrolling.current.on = true;
  }

  const searchPanelByID = function (panel: Record<string, any>, id: string) {
    let element: any = {};
    const findObjectAndParents = (item: any, id: string): boolean => {
      if (item && item?.$?.id === id) {
        element = item;
        return true;
      }
      if (Array.isArray(item) || Object(item) === item) {
        for (let k in item) {
          findObjectAndParents(item[k], id);
        }
      }
      return false;
    };
    findObjectAndParents(panel, id);
    return element;
  };

  const tableExportProviderRows = function (
    windowLayout: Record<string, any>,
    windowData: Record<string, any>,
    exportHeaderProviders: string,
  ) {
    if (exportHeaderProviders) {
      const [panelID, tableID] = exportHeaderProviders.split('.');
      const panel = windowLayout.screen?.form[0]?.panel?.find((panel: any) => panel.$.id === panelID) ?? {};
      const providerTable = searchPanelByID(panel, tableID);
      const data = providerTable.row.map((row: Record<string, any>) =>
        XT.getValueFromWindow({ data: windowData }, panelID, row.$.id),
      );
      return data;
    }
  };

  /**
   * select formatter as per the mask and formatter present in xml attributes
   * **/

  const SelectFormatter = (props: FormatterProps<any, any>) => {
    // const isInvalid = false;
    const cols = [...columns];
    attachments.forEach((att: any) => {
      const commaIndex = att.$.key.indexOf(',');
      const col = att.$.key.substring(commaIndex + 1);
      if (col && att.$.column) {
        cols.push({
          Header: att.$.column,
          accessor: col || att.$.id,
          attID: col || att.$.id,
          id: att.$.id,
          width: 50,
          contenttype: 'att',
          textalign: 'center',
          checked: false,
          deselectedvalue: '',
          padchar: '',
          limit: '',
          mask: '',
          ddspos: '',
        });
      }
    });
    let col = cols.find((c: Column) => c.id === props.column.key);
    let value = props.row[props.column.key] || '';
    let contentType = col?.mask || col?.contenttype;
    if (col?.contenttype === 'boolean') {
      contentType = 'boolean';
    }
    if (col?.formatter?.toLowerCase() === 'time') {
      contentType = 'time';
    }
    if (col?.formatter === 'RemoveTrailingDots') {
      value = new RemoveTrailingDotsFormatter().in(value);
    }
    if (col?.mask === 'integer' || col?.formatter?.toLowerCase() === 'integer') {
      contentType = 'integer';
    }

    let isEditable = col?.readonly === 'false';
    if (col?.disableconstraints) {
      const dConstraint = new Constraint(col?.disableconstraints);
      isEditable = !dConstraint.evaluate(props.row?.flags?.split('').map((x: string) => x === '1') || [], false);
    }

    let visible = true;
    if (col?.visibleconstraints) {
      let colVConstraint = new Constraint(col.visibleconstraints);
      if (!colVConstraint.evaluate(props.row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
        visible = false;
      }
    }

    const eConstraint = new Constraint(col?.errorconstraints || '');
    const isInvalid = eConstraint.evaluate(
      props.row?.flags?.split('').map((x: string) => x === '1'),
      false,
    );

    let decSep = getServerDecimalSeparator(col);
    let groupSep = decSep === '.' ? ',' : '.';

    const noteValue = XTValueToNote(
      contentType,
      value,
      col?.limit,
      decSep,
      localization,
      col?.value || yes,
      col?.deselectedvalue || no,
      settings,
    );

    switch (contentType) {
      case 'boolean':
        if (!isEditable)
          value = Formatters.BooleanFormatter.in(
            (value || ' ').trim(),
            col?.value || yes,
            col?.deselectedvalue || no,
          ) ? (
            <SquareIcon size="16px" className={'table-checkbox-icon'}>
              {Icons.Check}
            </SquareIcon>
          ) : null;
        break;
      case 'time': {
        value = value.trim();
        // if (value.startsWith('24')) value = value.replace('24', '00');
        if (col?.limit === '5') {
          value = `0${value}`;
        }
        let time = Formatters.Time.in(value?.trim() || '', +(col?.limit ?? '0'));
        value = time ? Formatters.Time.out(time, +(col?.limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
        break;
      }
      case 'date':
        value = Formatters.Date.convertValueFromServerFormatToClientFormat(
          value,
          settings?.regionals?.dateformats[0].$,
          localization.dateFormat,
        );
        break;
      case 'positiveinteger': {
        const temp = new PositiveIntegerFormatter().in(value);
        value = temp === 'NaN' ? value : temp;
        break;
      }
      case 'positiveinteger_with_zeros': {
        const temp0 = new PositiveIntegerWithZerosFormatter().in(value);
        value = temp0 === 'NaN' ? value : temp0;
        break;
      }
      case 'decimal':
        value = new DecimalFormatter().in(value, decSep);
        break;
      case 'positivedecimal':
        value = new PositiveDecimalFormatter().in(value, decSep, groupSep);
        break;
      case 'integer':
        value = new IntegerFormatter().in(value);
        break;
      case 'numeric':
        value = new NumberFormatter().in(value, decSep, false);
        break;
      case 'decimal_with_zeros':
        value = new PositiveDecimalWithZerosFormatter().in(value, decSep);
        break;
      case 'image': {
        const icon = XT.getIcon(value);
        if (icon) {
          value = <SquareIcon size="16px">{icon}</SquareIcon>;
        }
        break;
      }
      default:
        value = value.trimEnd();
        break;
    }

    if (currentSelection.current === props.rowIdx && `${id}|${col?.id}` === focussedElementRef.current) {
      if (contentType === 'boolean') {
        setFieldValue(noteValue);
        setDisplayValue(noteValue === 'true' ? col?.value || yes : col?.deselectedvalue || no);
      } else if (contentType === 'image') {
        setFieldValue(noteValue);
        setDisplayValue(noteValue);
      } else {
        setFieldValue(noteValue);
        setDisplayValue(value);
      }
    }

    let dds = col?.ddspos;
    if (dds) {
      let split = dds.split(',');
      dds = split[0] + ',' + (+split[1] + (+props.row.id - 1) * (attributes.span || 1));
    }
    let rowOffset = 0;
    let pageSize = +attributes.pagesize || 0;
    if ((attributes.span || 1) > 1) pageSize = pageSize / +attributes.span;
    if (pageSize > 0) rowOffset = Math.floor((+props.row.id - 1) / pageSize) * pageSize;

    let background = 'inherit';
    let foreground = 'inherit';
    if (col?.colors) {
      for (let i in col?.colors) {
        let color = col?.colors[i];

        let constraint = new Constraint(color.visibleconstraints);
        if (constraint.evaluate(props.row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
          if (color.background) {
            if (!color.background.startsWith('rgb') && color.background.split(',').length == 3) {
              color.background = 'rgb(' + color.background + ')';
            }
            if (color.background.startsWith('rgb')) {
              background = color.background;
            } else {
              background = ColorMap[color.background] || 'inherit';
            }
          }

          if (color.foreground) {
            if (!color.foreground.startsWith('rgb') && color.foreground.split(',').length == 3) {
              color.foreground = 'rgb(' + color.foreground + ')';
            }
            if (color.foreground.startsWith('rgb')) {
              foreground = color.foreground;
            } else {
              foreground = ColorMap[color.foreground] || 'inherit';
            }
          }
          break;
        }
      }
    }
    if (isInvalid && errors) {
      background = '#f8bac6';
    }

    let rowField = attributes.rowfield || attributes.rowposition;
    return (
      <div
        className={'table-cell'}
        style={{
          color: foreground,
          backgroundColor:
            isEditable && visible && col?.visible !== 'false' && !(isInvalid && errors) ? 'lightyellow' : background,
        }}
        onDoubleClick={(e) => {
          props.row.selectable && handleDefaultAction(e, props.row);
        }}
        onMouseDown={(e: React.MouseEvent) => {
          /*
            To simulate the issue we're trying to solve here you need an editable table (eg w/w sales orders, right click and select "fast entry"): select a
            cell in EDIT mode, scroll that cell "just" outside the visible rows of the table and click another (editable) cell. As a result the rdg component
            will scroll the original selected cell back into the visible part of the table and the selected cell is still the original one (but it is no longer
            in edit mode).

            Compare behavior in following cases:
            ° select a cell in EDIT mode, click another (editable) cell inside the visible part of the table ==> clicked cell becomes selected one (OK)
            ° select a cell in EDIT mode, scroll that cell "a lot" outside the visible rows of the table and click another (editable) cell ==> clicked cell
              becomes selected one (and no scrolling; OK)

            Remark: same behavior can be simulated on the rdg examples, eg https://adazzle.github.io/react-data-grid/#/all-features; double click any cell of
            column "First Name" (to get it in EDIT mode), scroll cell outside view and click any other cell (extra remark: scrolling "just" or "a lot" does
            not make any difference here).

            Debugging revealed that part of the code in DataGrid.tsx (in useLayoutEffect) gives the focus back to the previous selected control and as a
            side effect of that, the cell is scrolled into view. Remark that in this particular case the click event on the cell is not even triggered (I
            guess that due to the scrolling the mouse up part on the "clicked" cell is missing).

            Additional remarks:
            ° As the issue only occurs when a cell is in EDIT mode, I first tried to get the cell in SELECT mode before the code in useLayoutEffect was
              executed. This failed because the only way (I saw) to get it in SELECT mode is executing selectCell({...}, false) which also induces the same
              scrolling side effect.
            ° Part of the fix executes the click function on the target cell, so it seems like it makes more sense to include this fix in onClick (or
              onClickCapture). However in that case the code in useLayouEffect (that we want to avoid) is already executed, so no other option was available
              than to use onMouseDown. Theoretically seen, at mouse down time it is not possible to know if this will result in a click event or not, but I
              don't see any other options here.
            ° Current new code is not only executed in the case 'scroll that cell "just" outside the visible rows', but also in the two other cases. As the
              code seems also to get the expected result for the two other cases, there was chosen not to try to add (very complex) conditions to filter out
              the specific case
            ° If you are changing this code, do NOT forget to test possible impact on right click and double click events.
          */

          // Only needed on left mousedown on editable table
          // ===============================================
          if (e.button === 0 && isEditableTable) {
            // Get info previous selection
            // ---------------------------
            const cRowIdx = currentSelection.current;
            const cIdx = currentColumn.current;

            // No additional action needed if target cell is already the selected one
            // ----------------------------------------------------------------------
            if (cRowIdx === props.rowIdx && cIdx === props.column.idx) return;

            // No additional action needed if current selected cell not in EDIT mode
            // ---------------------------------------------------------------------
            const cRow = refSortedRows.current[cRowIdx];
            const cColumn = _columns[cIdx];
            if (cRow && cColumn && !cColumn.editable(cRow)) return;

            // Determine whether target cell editable
            // --------------------------------------
            const isEditable =
              (typeof props.column.editable === 'function'
                ? props.column.editable(props.row)
                : !!props.column.editable) && !!props.column.editorOptions?.editOnClick;

            // Select target cell
            // ------------------
            tableRef.current?.selectCell({ idx: props.column.idx, rowIdx: props.rowIdx }, isEditable);

            // Simulate cell click behavior
            // ----------------------------
            if (isEditable) {
              /*
                When the target cell is editable, the execution of selectCell(...) on previous line of code will result in the replacement
                of the cell with another element to edit the value. As a result it will not be possible anymore to trigger a mouseup (and
                as such a click) event on the original cell.
              */
              const rdgCell = e.currentTarget?.closest('[role="gridcell"]') as HTMLElement;
              if (typeof rdgCell?.click === 'function') {
                rdgCell.click();
              }
            }
          }

          // Set preferred caret position
          // ============================
          const setPreferredCaretPosition = () => {
            // Only if applicable
            // ------------------
            if (e.button !== 0) return; // left mouse button only
            if (!isEditableTable) return; // only editable table

            // Only if target cell editable
            // ----------------------------
            const isEditable =
              (typeof props.column.editable === 'function'
                ? props.column.editable(props.row)
                : !!props.column.editable) && !!props.column.editorOptions?.editOnClick;
            if (!isEditable) return;

            // Only if target column has no auto select text on focus
            // ------------------------------------------------------
            if (col?.autoselect === 'false') {
              const info = getGridlinesStyleInfo(col!, e.currentTarget);
              if (info) {
                const caretPosition = Math.floor((e.nativeEvent.offsetX - info.paddingLeft) / info.characterWidth);
                setTimeout(() => {
                  if (col) col.preferredCaretPosition = caretPosition;
                }, 0); //remark: timeout needed to be after blur event of the original element that also may update the preferred caret position
              }
            }
          };
          setPreferredCaretPosition();
        }}
      >
        {col?.contenttype === 'att' ? (
          (() => {
            return XT.attachments[formID]?.hasKey(
              [XT.getValueFromWindowRowForAttachments({ data: windowData }, panelID, props.row.id, col?.attID || '')],
              col?.Header,
            );
          })() && (
            <SquareIcon size={'16px'} className={'icon-primary-table'}>
              {Icons.Paperclip}
            </SquareIcon>
          )
        ) : props.row.markedForCopy && col?.id?.trim().toLowerCase() === 'copypaste' ? (
          '*'
        ) : contentType === 'boolean' && isEditable ? (
          <Checkbox
            attributes={{ deselectedvalue: col?.deselectedvalue, ddspos: dds }}
            label=""
            isInvalid={isInvalid && errors}
            id={`row-${props.row.id}-col-${col?.id}`}
            name={col?.id || ''}
            panelID={panelID}
            rowID={props.row.id}
            rowFlags={props.row.flags}
            rowField={attributes.rowfield || attributes.rowposition}
            yes={yes}
            no={no}
            defaultValue={Formatters.BooleanFormatter.in(value, yes, no)}
            onRowChange={(val: string) => {
              props.onRowChange({ ...props.row, [col?.id || '']: val });
            }}
          />
        ) : visible ? (
          value
        ) : (
          ''
        )}
        <span
          className={
            'table-prompt' + (col?.contenttype === 'date' ? ' date' : col?.contenttype === 'time' ? ' time' : ' text')
          }
          hidden={
            !(
              (col?.prompt === 'true' || col?.contenttype === 'date' || col?.contenttype === 'time') &&
              isEditable &&
              visible &&
              col?.visible !== 'false'
            )
          }
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (col?.contenttype === 'time') {
              return;
            }
            let panel =
              tableRef?.current?.element?.closest('[data-ddspanel]')?.getAttribute('data-ddspanel') || panelID || '';
            setScope(dds || '1,1', rowOffset);
            promptHandler(col?.id || '', panel, rowField, props.row.id);
          }}
        />
      </div>
    );
  };

  const contextItemsRef = useRef<ContextOptions[]>([]);
  useEffect(() => {
    contextItemsRef.current = contextOptions;
  }, [contextOptions]);

  const handleDefaultAction = (e: RMouseEvent<HTMLElement>, row: any) => {
    e.preventDefault();
    let defaultAction = contextItemsRef?.current?.[0].find(
      (x: ContextOption) => x.isDefault && (x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER'),
    );
    if (selectedDefaultAction.current) {
      defaultAction =
        contextItemsRef?.current?.[0].find(
          (x: ContextOption) =>
            x.value === selectedDefaultAction.current && (x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER'),
        ) || defaultAction;
    }
    let abstractDefaultAction = defaultActions?.find((ac: Action) => {
      let con = new Constraint(ac.disableconstraints || '');
      let flags = row?.flags?.split('').map((x: string) => x === '1') || [];
      if (!con.evaluate(flags, false) && ac.event === 'DEFAULT') {
        return true;
      }
    });
    if (abstractDefaultAction && attributes.rowfield)
      abstractDefaultAction.field = abstractDefaultAction.field || attributes.rowfield;
    let tempAction: ContextOption = {
      action: abstractDefaultAction,
      isDefault: true,
      id: defaultAction?.id || '',
      label: 'DEFAULT',
      key: attributes.selectionColumn || '',
      type: 'option',
      value: abstractDefaultAction?.option || defaultAction?.value || '',
    };
    if (tempAction.action?.name?.toLowerCase() === 'downloaddocumentoutput') {
      downloadDocumentOutput(row.id);
    }
    if (tempAction.value && !selectedDefaultAction.current) {
      if (attributes.fullselect === 'true' || formID === 'SpooledFileQueueSelection') {
        let rowID = row.id;
        addToRowCommand(panelID, rowID, tempAction?.key, tempAction?.value, -1);
        let tempCols = windowData.form.panel
          .find((p: any) => p.$.id === panelID)
          .row.find((r: any) => r.$.id === rowID).ctrl;
        tempCols = tempCols.filter((c: any) => c.$.id !== tempAction?.key);
        columns.forEach((col: Column) => {
          let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, col.id) || '';
          if (col.contenttype === 'time') {
            let time = Formatters.Time.in(val?.trim() || '', +(col?.limit ?? '0'));
            val = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
          }
          if (tempCols.find((c: any) => c.$.id === col.id)) addToRowCommand(panelID, rowID, col.id, val, -1);
        });
        tempCols
          .filter((c: any) => columns.map((c: Column) => c.id).indexOf(c.$.id) === -1)
          .forEach((ctrl: any) => {
            addToRowCommand(
              panelID,
              rowID,
              ctrl.$.id,
              XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, ctrl.$.id),
              -1,
            );
          });
      }
      onContextMenuClick(panelID, [row.id], tempAction);
    } else if (defaultAction) {
      if (attributes.fullselect === 'true' || formID === 'SpooledFileQueueSelection') {
        let rowID = row.id;
        addToRowCommand(panelID, rowID, defaultAction?.key || '', defaultAction?.value || '', -1);
        let tempCols = windowData.form.panel
          .find((p: any) => p.$.id === panelID)
          .row.find((r: any) => r.$.id === rowID).ctrl;
        tempCols = tempCols.filter((c: any) => c.$.id !== defaultAction?.key);
        columns.forEach((col: Column) => {
          let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, col.id) || '';
          if (col.contenttype === 'time') {
            let time = Formatters.Time.in(val?.trim() || '', +(col?.limit ?? '0'));
            val = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
          }
          if (tempCols.find((c: any) => c.$.id === col.id)) addToRowCommand(panelID, rowID, col.id, val, -1);
        });
        tempCols
          .filter((c: any) => columns.map((c: Column) => c.id).indexOf(c.$.id) === -1)
          .forEach((ctrl: any) => {
            addToRowCommand(
              panelID,
              rowID,
              ctrl.$.id,
              XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, ctrl.$.id),
              -1,
            );
          });
      }
      onContextMenuClick(panelID, [row.id], defaultAction);
    }
  };

  const handleSpecialKeys = (e: KeyboardEvent) => {
    refSpecialKeys.current = { isShiftKeyDown: e.shiftKey, isCtrlKeyDown: e.ctrlKey || e.metaKey };
  };

  const ContextKeyNav = (e: KeyboardEvent) => {
    const tableSelectedEl: HTMLElement = tableRef?.current?.element?.querySelector(
      '[role="gridcell"][aria-selected="true"]',
    )!;
    let posX = tableSelectedEl
      ? tableSelectedEl?.getBoundingClientRect().x! + 20
      : tableSpanRef?.current?.getBoundingClientRect().x! + 50;
    let posY = tableSelectedEl
      ? tableSelectedEl?.getBoundingClientRect().y! + 20
      : tableSpanRef?.current?.getBoundingClientRect().y! + 50;
    if (e.key.toLowerCase() === 'contextmenu') {
      e.preventDefault();
      const contextEvent = new MouseEvent('contextmenu', {
        view: window,
        bubbles: true,
        cancelable: true,
        button: 2,
        buttons: 0,
        clientX: posX,
        clientY: posY,
      });
      if (tableRef?.current?.element) {
        tableRef?.current?.element?.dispatchEvent(contextEvent);
      } else {
        tableSpanRef?.current?.dispatchEvent(contextEvent);
      }
    }
  };

  const contextEventonShow = () => {
    const body = document.querySelector('body');
    body?.setAttribute('data-event', 'ignore');
    const contextListElement: HTMLUListElement = document.querySelector(
      '[role="menuitem"][class="react-contextmenu-item"]',
    )!;
    contextListElement?.focus();
    contextListElement?.blur();
  };

  const contextEventonHide = () => {
    const body = document.querySelector('body');
    body?.removeAttribute('data-event');
    XT.restoreFocusActiveWindow(tableSpanRef.current);
  };

  const contextMenuStopDef = (e: MouseEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  };

  const ignoreHeader = (e: MouseEvent) => {
    let target = e.target as HTMLElement;
    if (target.classList.contains('rdg-header-row') || !!target.closest('.rdg-header-row')) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const shouldContextMenuBeDisabled = (contextOption: ContextOption) => {
    let selectedRowEnablesAction = rows.find(
      (row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1 && row.selectable,
    );
    let disabled =
      (!selectedRowEnablesAction || selectedRowIdxs.size === 0) &&
      (contextOption.type === 'option' || !contextOption.type);
    return disabled;
  };

  const getHeaderRowHeight = () => {
    return attributes.header === 'false' ? 0 : filters.enabled ? basicRowHeight * 2 : basicRowHeight;
  };
  const getRowHeight = (row: any) => {
    if (!row) {
      return 0;
    } else {
      // Basic row height
      let height = basicRowHeight;
      // Adjust height for text(s) (eg work with sales orders)
      if (row.rowConditionText?.length > 0) {
        height += row.rowConditionText.length * basicRowHeight;
      }
      return height;
    }
  };
  const getSortedRowOffsetTop = (rowIndex: number, startIndex: number = -1, startOffsetTop?: number) => {
    /*
      IMPORTANT REMARK: row offsetTop is also available on the row-wrapper div elements of the rdg. However rdg does not create
      all row wrappers for performance reasons (only for the rows in the rdg viewport). If you select a row, and scroll enough
      down, the row-wrapper for the selected row will no longer exist
    */

    // --> Determine start values (if missing)
    if (startIndex < 0 || startIndex > refSortedRows.current.length - 1 || startOffsetTop === undefined) {
      startIndex = 0;
      startOffsetTop = getHeaderRowHeight();
    }

    // --> Minimize number of loops
    if (rowIndex - 0 < Math.abs(rowIndex - startIndex)) {
      // Remark: makes no sense to calculate offset for eg row 5 starting from row 1000
      startIndex = 0;
      startOffsetTop = getHeaderRowHeight();
    }

    // --> Handle case row index is the start index
    if (rowIndex === startIndex) return startOffsetTop;

    // --> Calculate offset top
    let i = startIndex;
    let offsetTop = startOffsetTop;

    if (i < rowIndex) {
      while (i < rowIndex) {
        offsetTop += getRowHeight(refSortedRows.current[i]);
        i++;
      }
    } else {
      while (i > rowIndex) {
        i--;
        offsetTop -= getRowHeight(refSortedRows.current[i]);
      }
    }

    // --> Return result
    return offsetTop;
  };
  const getIndexOfFirstVisibleSortedRow = (scrollTop?: number) => {
    // Only if table element available
    // -------------------------------
    if (!tableRef.current?.element) return -1;

    // Only if table has rows
    // ----------------------
    if (refSortedRows.current.length <= 0) return -1;

    // Only if rows visible
    // --------------------
    const headerRowHeight = getHeaderRowHeight();
    if (tableRef.current.element.clientHeight <= headerRowHeight) return -1;

    // Initialize scroll top
    // ---------------------
    if (scrollTop === undefined) scrollTop = tableRef.current.element.scrollTop;

    // Determine lowest row index where top visible
    // --------------------------------------------
    let rowIndex = 0;
    let totalHeight = 0;
    while (totalHeight < scrollTop) {
      totalHeight += getRowHeight(refSortedRows.current[rowIndex]);
      rowIndex++;
      if (rowIndex >= refSortedRows.current.length) return rowIndex - 1; // case only last row partially visible
    }
    return rowIndex;
  };
  const getMaxNumberOfCompletelyVisibleBasicRows = () => {
    if (!tableRef.current?.element) return 0;
    const clientHeight = tableRef.current.element.clientHeight;
    return Math.max(Math.floor((clientHeight - getHeaderRowHeight()) / basicRowHeight), 0);
  };
  const handleExternalPageRequest_GetCurrentSelectedRowIndex = () => {
    // --> Skip nonsense
    if (refSortedRows.current.length <= 0) return -1;

    // --> Initialize
    let rowIndex = -1;

    // --> 1. Index of selected row equal to table cursor
    if (refSelectedRowIds.current?.size > 0) {
      for (const rowId of refSelectedRowIds.current) {
        rowIndex = refSortedRows.current.findIndex((row: any) => row.id === rowId);
        if (rowIndex >= 0 && rowIndex === currentSelection.current && currentColumn.current !== -1) {
          return rowIndex;
        }
      }
    }

    // --> 2. index of last added selected row
    if (rowIndex >= 0) return rowIndex;

    // --> 3. First row of table viewport
    rowIndex = getIndexOfFirstVisibleSortedRow();
    if (rowIndex >= 0) return rowIndex;

    // --> 4. First row of table
    return 0;
  };
  const handleExternalPageDownRequest = () => {
    // --> Only if table element available
    if (!tableRef.current?.element) return;

    // --> Only if table has rows
    if (refSortedRows.current.length <= 0) return;

    // --> Only if not already executing server pagedown
    if (isPageDownRequested.current) return;

    // --> No action if already completely scrolled down
    const clientHeight = tableRef.current.element.clientHeight;
    if (tableRef.current.element.scrollHeight <= clientHeight + tableRef.current.element.scrollTop) return;

    // --> Should only be executed if table has no focus
    if (tableRef.current.element?.contains(document.activeElement)) return;

    // --> For now only implemented for non editable tables           (have no time/budget now to do full tests for editable tables)
    if (isEditableTable) return;

    // --> Get current selected row index
    const selectedRowIndex = handleExternalPageRequest_GetCurrentSelectedRowIndex();

    // --> Determine resulting row index
    let resultingRowIndex = selectedRowIndex;
    const maxNumberOfBasicRows = getMaxNumberOfCompletelyVisibleBasicRows();
    let numberOfBasicRows = maxNumberOfBasicRows + 1;
    while (true) {
      const rowHeight = getRowHeight(refSortedRows.current[resultingRowIndex]);
      numberOfBasicRows -= rowHeight / basicRowHeight;
      if (numberOfBasicRows <= 0) break;
      if (resultingRowIndex >= refSortedRows.current.length - 1) break;
      resultingRowIndex++;
    }

    // --> Determine resulting scroll top
    let resultingScrollTop = getSortedRowOffsetTop(selectedRowIndex);
    resultingScrollTop -= numberOfBasicRows * basicRowHeight; // Adjust to scroll new selected entirely in view
    resultingScrollTop += (maxNumberOfBasicRows + 1) * basicRowHeight - clientHeight; // Small adjustment to get new selected exazctly at the bottom

    // --> Apply result
    tableRef.current.element.scrollTop = resultingScrollTop;

    // --> Select row
    setSelectedRowIdxs(new Set<string>([refSortedRows.current[resultingRowIndex].id]));

    // --> Select cell (without scrolling)
    setTimeout(() => {
      preventComponentScrolling();
      if (currentColumn.current < 0) currentColumn.current = 0;
      tableRef.current?.selectCell({ rowIdx: resultingRowIndex, idx: currentColumn.current });
    }, 20); // Not too fast: tableRef.current.element.scrollTop = resultingScrollTop; must be executed first
  };
  const handleExternalPageUpRequest = () => {
    // --> Only if table element available
    if (!tableRef.current?.element) return;

    // --> Only if table has rows
    if (refSortedRows.current.length <= 0) return;

    // --> Only if not already executing server pagedown
    if (isPageDownRequested.current) return;

    // --> No action if already completely scrolled up
    if (tableRef.current.element.scrollHeight === 0) return;

    // --> Should only be executed if table has no focus
    if (tableRef.current.element?.contains(document.activeElement)) return;

    // --> For now only implemented for non editable tables           (have no time/budget now to do full tests for editable tables)
    if (isEditableTable) return;

    // --> Get current selected row index
    const selectedRowIndex = handleExternalPageRequest_GetCurrentSelectedRowIndex();

    // --> Determine resulting row index
    let resultingRowIndex = selectedRowIndex;
    const maxNumberOfBasicRows = getMaxNumberOfCompletelyVisibleBasicRows();
    let numberOfBasicRows = maxNumberOfBasicRows;
    while (resultingRowIndex > 0) {
      resultingRowIndex--;
      const rowHeight = getRowHeight(refSortedRows.current[resultingRowIndex]);
      numberOfBasicRows -= rowHeight / basicRowHeight;
      if (numberOfBasicRows <= 0) break;
    }

    // --> Determine resulting scroll top
    let resultingScrollTop =
      getSortedRowOffsetTop(selectedRowIndex) - (maxNumberOfBasicRows + 1 - numberOfBasicRows) * basicRowHeight;

    // --> Apply result
    tableRef.current.element.scrollTop = resultingScrollTop;

    // --> Select row
    setSelectedRowIdxs(new Set<string>([refSortedRows.current[resultingRowIndex].id]));

    // --> Select cell (without scrolling)
    setTimeout(() => {
      preventComponentScrolling();
      if (currentColumn.current < 0) currentColumn.current = 0;
      tableRef.current?.selectCell({ rowIdx: resultingRowIndex, idx: currentColumn.current });
    }, 20); // Not too fast: tableRef.current.element.scrollTop = resultingScrollTop; must be executed first
  };

  useEffect(() => {
    document.addEventListener('keydown', handleSpecialKeys);
    document.addEventListener('keyup', handleSpecialKeys);
    const rdgTable = tableRef?.current?.element;
    rdgTable?.addEventListener('contextmenu', ignoreHeader);
    const tableSpan = tableSpanRef?.current;
    tableSpan?.addEventListener('keyup', ContextKeyNav);
    if (tableSpan) (tableSpan as any).handleExternalPageDownRequest = handleExternalPageDownRequest;
    if (tableSpan) (tableSpan as any).handleExternalPageUpRequest = handleExternalPageUpRequest;
    document.addEventListener('contextmenu', contextMenuStopDef);
    setTimeout(() => {
      const sink = tableRef?.current?.element?.querySelector('.rdg-focus-sink') as HTMLElement; // Remark: code changed to make sure the right sink is selected (active window can have TWO tables, one on the window itself but also a 2nd one if a modal is shown (eg warehouse prompt))
      if (sink && !sink.hasAttribute('data-event')) {
        sink.setAttribute('data-event', 'table');
      }
    }, 100);
    return () => {
      document.removeEventListener('keydown', handleSpecialKeys);
      document.removeEventListener('keyup', handleSpecialKeys);
      rdgTable?.removeEventListener('contextmenu', ignoreHeader);
      tableSpan?.removeEventListener('keyup', ContextKeyNav);
      document.removeEventListener('contextmenu', contextMenuStopDef);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isTableTooltipEnabled) {
        ReactTooltip.rebuild();
      }
    }, 1000);
  }, [
    isTableTooltipEnabled,
    tableTooltipSequence,
    windowData,
    tableColumnOrder,
    tableHiddenColumns,
    tableColumnResizing,
  ]);

  if (refFilters.current !== filters) {
    fullRebuildSortedRows.current = true;
    refFilters.current = filters;
    refFilterBaseRows.current = rows;
    refChangedTableRows.current = []; // remark: useless at this point, new data is already included by previous statement
  }

  if (refSortColumns.current !== sortColumns) {
    fullRebuildSortedRows.current = true;
    refSortColumns.current = sortColumns;
    refFilterBaseRows.current = rows;
    refChangedTableRows.current = []; // remark: useless at this point, new data is already included by previous statement
  }

  /**
   * To fetch ToolTip Content
   */
  const getTooltipContent = (row: any) => {
    let tableInfos = _columns.map((tableColumn: any) => {
      let column = columns.find((col: any) => col.id === tableColumn.id);
      let hasAttachment = XT.attachments[formID]?.hasKey([
        XT.getValueFromWindowRow({ data: windowData }, panelID, row.id, tableColumn?.id || ''),
      ]);
      return { key: tableColumn.key, label: tableColumn.name || '', att: !column, hasAttachment };
    });
    if (tableTooltipSequence && Object.keys(tableTooltipSequence).length > 0) {
      const sortable = [];
      for (let key in tableTooltipSequence)
        if (tableTooltipSequence.hasOwnProperty(key) && tableTooltipSequence[key])
          sortable.push({ key, seq: parseInt(tableTooltipSequence[key]) });
      sortable.sort((a, b) => a.seq - b.seq);
      const sortedKeys = sortable.map((item) => item.key);
      tableInfos = sortedKeys.map((tableColumn) => {
        let column = columns.find((col: any) => col.id === tableColumn);
        return { key: tableColumn, label: column?.Header || '', att: false, hasAttachment: false };
      });
    }
    return tableInfos
      .filter((x: any) => !!x.label)
      .map(
        (tableInfo: any) =>
          `<span className='tooltip-text-table'>${tableInfo.label}: ${
            tableInfo.att ? (tableInfo.hasAttachment ? 'Yes' : 'No') : row[tableInfo.key] || ''
          }</span><br/>`,
      )
      .join('');
  };

  const isCommaAlwaysServerDecimalSeparator = (column?: Column): boolean => {
    return XT.isCommaAlwaysServerDecimalSeparator(column?.formatter, column?.mask);
  };

  const getServerDecimalSeparator = (column?: Column): string => {
    return isCommaAlwaysServerDecimalSeparator(column) ? ',' : decimalsign;
  };

  /*
   * select the element to be rendered in column cell
   * */
  const _columns = useMemo(() => {
    const cols = [...columns];
    attachments.forEach((att: any) => {
      const commaIndex = att.$.key.indexOf(',');
      const col = att.$.key.substring(commaIndex + 1);
      if (col && att.$.column) {
        cols.push({
          Header: att.$.column,
          accessor: col || att.$.id,
          attID: col || att.$.id,
          id: att.$.id,
          width: 50,
          contenttype: 'att',
          textalign: 'center',
          checked: false,
          deselectedvalue: '',
          padchar: '',
          limit: '',
          mask: '',
          ddspos: '',
        });
      }
    });
    return cols
      .map((col: Column, index: number) => {
        return {
          key: col.id,
          id: col.id,
          name: col.Header,
          contenttype: col.contenttype,
          editable: (row: any) => {
            let isEditable = col.readonly === 'false';
            if (col.disableconstraints) {
              const dConstraint = new Constraint(col.disableconstraints);
              isEditable = !dConstraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], false);
            }
            let visible = true;
            if (col.visibleconstraints) {
              let colVConstraint = new Constraint(col.visibleconstraints);
              if (!colVConstraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
                visible = false;
              }
            }
            return isEditable && visible && col.visible !== 'false';
          },
          resizable: true,
          draggable: true,
          sortable: !(col.sort === 'false'),
          headerCellClass: `filter-cell text-${col.textalign || 'left'}`,
          cellClass: `text-${col.textalign || 'left'}`,
          width: tableColumnResizing?.[col.id] || col.width,
          index: tableColumnOrder?.indexOf(col.id) > -1 ? tableColumnOrder?.indexOf(col.id) : index,
          formatter: SelectFormatter,
          editor: EditWrapper,
          editorOptions: {
            editOnClick: true, // remark: default behavior react-data-grid uses double click, but in our application the default action should be invoked on double click
            onNavigation: (event: React.KeyboardEvent<HTMLDivElement>) => {
              /*
                Remark: if defined this function is called in edit mode (only) whenever user requests keyboard navigation.
                Supported navigation keys are ArrowUp, ArrowDown, ArrowLeft, ArrowRight, Tab, Home, End, PageUp & PageDown.
                The default implementation allows only Tab in edit mode to navigate.

                Customized implementation
                  ° will NEVER allow (keyboard) navigation if the user has supplied an invalid value (eg invalid date)
                  ° in all other cases ArrowUp, ArrowDown, Tab, PageUp & PageDown will be accepted as navigation keys.
                  ° ArrowLeft, ArrowRight, Home, End will be blocked as navigation keys, to allow the user to "navigate"
                    within the selected cell.

                Remark that other implementations could be possible: eg ArrowLeft & Home also allowed if cursor at the beginning
                of the value in the selected cell, ...
              */
              const sortedRows = refSortedRows.current;

              const getNextPosition = (startPos: { idx: number; rowIdx: number }, shiftKey: boolean) => {
                // Initialize
                // ==========
                const nextPos = {
                  idx: startPos.idx + (shiftKey ? -1 : 1),
                  rowIdx: startPos.rowIdx,
                };

                // Adjust if outside column boundaries
                // ===================================
                if (nextPos.idx < 0) {
                  nextPos.idx = _columns.length - 1;
                  nextPos.rowIdx -= 1; // BEWARE: cellNavigationMode="CHANGE_ROW" assumed
                } else if (nextPos.idx > _columns.length - 1) {
                  nextPos.idx = 0;
                  nextPos.rowIdx += 1; // BEWARE: cellNavigationMode="CHANGE_ROW" assumed
                }

                // Adjust if outside row boundaries
                // ================================
                if (nextPos.rowIdx < 0) {
                  nextPos.rowIdx = sortedRows.length - 1;
                } else if (nextPos.rowIdx > sortedRows.length - 1) {
                  nextPos.rowIdx = 0;
                }

                // Return result
                // =============
                return nextPos;
              };

              const getNextEditablePosition = (shiftKey: boolean) => {
                // Initialize
                // ==========
                let nextPos = {
                  idx: currentColumn.current,
                  rowIdx: currentSelection.current,
                };

                // Get next editable position
                // ==========================
                while (true) {
                  // Get next position
                  // -----------------
                  nextPos = getNextPosition(nextPos, shiftKey);

                  // Infinite loop protection
                  // ------------------------
                  if (nextPos.idx === currentColumn.current && nextPos.rowIdx === currentSelection.current) {
                    return nextPos;

                    // Return next position if cell is editable
                    // ----------------------------------------
                  } else if (_columns[nextPos.idx].editable(sortedRows[nextPos.rowIdx])) {
                    return nextPos;
                  }
                }
              };

              // Handle case current selected cell contains invalid data
              // =======================================================
              if (
                event.target instanceof HTMLInputElement ||
                event.target instanceof HTMLTextAreaElement ||
                event.target instanceof HTMLSelectElement
              ) {
                const dataInputInvalid = event.target.getAttribute('data-input-invalid');
                if (dataInputInvalid === 'true') {
                  if (['ArrowLeft', 'ArrowRight', 'Home', 'End'].indexOf(event.key) === -1) {
                    // Remark: allow ArrorLeft, ... in selected cell
                    event.preventDefault(); //         but we don't want PageUp/PageDown to be triggered by the application (which could trigger a server call to load the next page)   NTH_SKE: re-test with/without (seems to have no real impact???)
                  }
                  return false;
                }
              }

              // Block ArrowLeft, ArrowRight, Home & End
              // =======================================
              if (['ArrowLeft', 'ArrowRight', 'Home', 'End'].indexOf(event.key) !== -1) {
                //event.preventDefault();                                                       // Remark: do NOT include! Keys must be allowed for navigation within the selected cell
                return false;
              }

              // Override <TAB> behavior editable table: only navigate to editable cells             Remark: current function is only executed FROM if currently in EDIT mode !!
              // =======================================================================
              if (event.key === 'Tab') {
                const nextPos = getNextEditablePosition(event.shiftKey);
                if (nextPos) {
                  setTimeout(() => {
                    if (nextPos.idx !== currentColumn.current || nextPos.rowIdx !== currentSelection.current) {
                      tableRef.current?.selectCell({ idx: nextPos.idx, rowIdx: nextPos.rowIdx }, true);
                    }
                  }, 0);
                }
                return true; // BEWARE: return false has some strange side-effects (eg editable cell & <SHIFT> + <TAB> & <ARROWUP> ==> editable cell in SELECT instead of EDIT mode)
              }

              // Allow other navigation
              // ======================
              return true;
            },
          },
          headerRenderer: (props: HeaderRendererProps<any>) => {
            let placeholder = '';
            if (col.contenttype?.toLowerCase() === 'numeric') {
              placeholder = Localization.instance.getString('TABLE_FILTER_PlaceholderNumbers'); //'e.g.: "10-20", "<20", ">10", "10"';
            } else if (col.contenttype?.toLowerCase() === 'date') {
              placeholder = Localization.instance.getString(localization.dateFormat);
            }
            return (
              <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder}>
                {(filters) => (
                  <Form.Control
                    className={`text-style text-font padding filter text-${col.textalign || 'left'}`}
                    id={`table-${id}-${col.id}`}
                    type={'text'}
                    data-event={'ignore'}
                    placeholder={placeholder}
                    autoComplete={'off'}
                    onChange={(e) => {
                      let { value } = e.target;
                      setFilters({ ...filters, [col.id]: value });
                    }}
                    value={filters[col.id] || ''}
                  />
                )}
              </DraggableHeaderRenderer>
            );
          },
        };
      })
      .filter((col: any) => !tableHiddenColumns?.includes(col.key))
      .sort((a: any, b: any) => a.index - b.index);
  }, [columns, tableColumnOrder, tableHiddenColumns, tableColumnResizing, attachments, importedRows, markedRows]);

  /**
   * get filtered rows
   */

  const filteredRows = useMemo<any>(() => {
    return refFilterBaseRows.current
      .map((row: any) => {
        row.tooltip = getTooltipContent(row);
        return row;
      })
      .filter((row: any) => {
        return Object.keys(row).every((col: string): boolean => {
          // Case no filter supplied
          // -----------------------
          if (!filters[col]) return true;

          // Get column properties/value
          // ---------------------------
          let column = columns.find((c: Column) => c.id === col);
          let columnValue = row[col];

          // Handle filtering numeric columns
          // --------------------------------
          const xtColumnValue = new XTString(columnValue).serverFormatToNumber(getServerDecimalSeparator(column));
          if (!xtColumnValue.isNaN && column?.contenttype === 'numeric') {
            let filter = filters[col].trim();

            if (filter.startsWith('>=')) {
              let filterValue = new XTString(filter.replace('>=', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isGreaterThanOrEqualTo(filterValue);
            } else if (filter.startsWith('>')) {
              let filterValue = new XTString(filter.replace('>', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isGreaterThan(filterValue);
            } else if (filter.startsWith('<=')) {
              let filterValue = new XTString(filter.replace('<=', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isLessThanOrEqualTo(filterValue);
            } else if (filter.startsWith('<>')) {
              let filterValue = new XTString(filter.replace('<>', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isNotEqualTo(filterValue);
            } else if (filter.startsWith('<')) {
              let filterValue = new XTString(filter.replace('<', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isLessThan(filterValue);
            } else if (filter.startsWith('=')) {
              let filterValue = new XTString(filter.replace('=', '')).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isEqualTo(filterValue);
            } else if (filter.includes('-')) {
              let positions: number[] = [];
              let position = filter.indexOf('-');
              while (position !== -1) {
                positions.push(position);
                position = filter.indexOf('-', position + 1);
              }
              if (positions.length === 1 && filter.startsWith('-')) {
                //case single negative number supplied
                let filterValue = new XTString(filter).clientFormatToNumber(
                  localization.decimalSeparator,
                  localization.groupSeparator,
                );
                if (filterValue.isNaN) return false;
                return xtColumnValue.isEqualTo(filterValue);
              } else if (positions.length > 3) {
                // case too many minus signs ==> not numeric
                return false;
              } else {
                // case range supplied
                let filterValue1, filterValue2;
                if (filter.startsWith('-')) {
                  // case first element of the range is a negative number ==> split on second minus sign
                  filterValue1 = new XTString(filter.substring(0, positions[1]).trim()).clientFormatToNumber(
                    localization.decimalSeparator,
                    localization.groupSeparator,
                  );
                  filterValue2 = new XTString(filter.substring(positions[1] + 1).trim()).clientFormatToNumber(
                    localization.decimalSeparator,
                    localization.groupSeparator,
                  );
                } else {
                  // case first element of the range is a positive number ==> split on first minus sign
                  filterValue1 = new XTString(filter.substring(0, positions[0]).trim()).clientFormatToNumber(
                    localization.decimalSeparator,
                    localization.groupSeparator,
                  );
                  filterValue2 = new XTString(filter.substring(positions[0] + 1).trim()).clientFormatToNumber(
                    localization.decimalSeparator,
                    localization.groupSeparator,
                  );
                }
                if (filterValue1.isNaN || filterValue2.isNaN) return false;
                // get min/max filter values
                let minFilterValue = filterValue1;
                let maxFilterValue = filterValue2;
                if (filterValue1.isGreaterThan(filterValue2)) {
                  minFilterValue = filterValue2;
                  maxFilterValue = filterValue1;
                }

                return (
                  xtColumnValue.isGreaterThanOrEqualTo(minFilterValue) &&
                  xtColumnValue.isLessThanOrEqualTo(maxFilterValue)
                );
              }
            } else {
              let filterValue = new XTString(filter).clientFormatToNumber(
                localization.decimalSeparator,
                localization.groupSeparator,
              );
              if (filterValue.isNaN) return false;
              return xtColumnValue.isEqualTo(filterValue);
            }

            // Handle filtering date columns
            // -----------------------------
          } else if (column?.contenttype === 'date') {
            let value = Formatters.Date.convertValueFromServerFormatToClientFormat(
              columnValue,
              settings?.regionals?.dateformats[0].$,
              localization.dateFormat,
            );
            return value?.toLowerCase().indexOf(filters[col].toLowerCase()) > -1;

            // Handle filtering other columns
            // ------------------------------
          } else {
            return row[col]?.toLowerCase().indexOf(filters[col].toLowerCase()) > -1;
          }
        });
      });
  }, [refFilterBaseRows.current, filters, tableTooltipSequence]);

  /**
   * Parse Date
   */
  const parseDateForSorting = (value: string) => {
    return Formatters.Date.convertValueFromServerFormatToClientFormat(
      value,
      settings?.regionals?.dateformats[0].$,
      'yyyyMMdd',
    );
  };

  /**
   * To sort the rows.
   */
  const sortedRows = useMemo<any>(() => {
    if (fullRebuildSortedRows.current) {
      fullRebuildSortedRows.current = false;

      if (sortColumns.length === 0) {
        refSortedRows.current = filteredRows;
        return filteredRows;
      }

      const sortedRows = [...filteredRows];
      sortedRows.sort((a: any, b: any) => {
        for (const sort of sortColumns) {
          const col: Column | undefined = columns.find((col: Column) => col.id === sort.columnKey);
          if (col) {
            let compareResult = 0;
            if (col.contenttype === 'date') {
              let aDate = parseDateForSorting(a[col.id]);
              let bDate = parseDateForSorting(b[col.id]);
              compareResult = sort.direction === 'ASC' ? aDate.localeCompare(bDate) : bDate.localeCompare(aDate);
            } else if (col.contenttype?.toLowerCase() === 'numeric') {
              const serverDecimalSeparator = getServerDecimalSeparator(col);
              const aVal = new XTString(a[col.id]).serverFormatToNumber(serverDecimalSeparator);
              const bVal = new XTString(b[col.id]).serverFormatToNumber(serverDecimalSeparator);
              compareResult = sort.direction === 'ASC' ? XTNumber.compare(aVal, bVal) : XTNumber.compare(bVal, aVal);
            } else {
              compareResult =
                sort.direction === 'ASC'
                  ? (a[col.id] || '').localeCompare(b[col.id] || '')
                  : (b[col.id] || '').localeCompare(a[col.id] || '');
            }
            if (compareResult !== 0) return compareResult;
          }
        }
        return 0;
      });
      refSortedRows.current = sortedRows;
      return sortedRows;
    } else {
      if (refChangedTableRows.current.length < 1) return refSortedRows.current;
      const _rows = [...refSortedRows.current];
      refChangedTableRows.current.forEach((row: any) => {
        const rowIndex = _rows.findIndex((r: any) => r.id === row.id);
        if (rowIndex > -1) _rows[rowIndex] = row;
      });
      refChangedTableRows.current.length = 0; // Remark: clears the arry, keeping the same instance (avoid unneeded trigger to calculate sorted rows again)
      refSortedRows.current = _rows;
      return _rows;
    }
  }, [filteredRows, sortColumns, refChangedTableRows.current]);

  useAdvancedEffect(
    () => {
      /*
        Initialize selected cell from row selection
        ===========================================

        The implementation of selected rows is mainly done in onSelectedCellChange. However that function is not called
        by the component the first time the table gets focus: position is "hardcoded" (0, 0). As a consequence the first
        time the component gets focus the corresponding row will not be selected.

        In some specific cases where the server dictates which are the selected rows we don't even want the selected cell
        to be positioned at cell (0, 0).

        The only way to change the current cell of the table seems to be calling the function selectCell. However we cannot
        do the call at initialization time as that call would move also the focus to the table which might not be the desired
        behavior.

        To solve the problem we will call selectCell the first time the table receives focus.

        // Remark: seems like a REACT anti-pattern to use references to expose current state values (through refSortedRows &
        // refselectedRowIdxs), however when I do not specify those entries in the "value changed" array of the useEffect, I get
        // stale references of those objects at the add listener time (not having yet the needed value). When I do add the state
        // objects themselves in the "value changed" array of the useEffect, I don't seem to get the desired combination where
        // both values are available (maybe this is caused by another bug somewhere or by the sequence the code is executed?)
      */
      // console.info('>>>>>>>>>>>>>>>> listener START', data || 'NO DATA', windowData || 'NO WINDOW DATA')
      let refInfo: {
        sink: HTMLElement | undefined; // Remark: remove listener cannot access sink when it's declared directly: would be stale version (undefined) as before assignment in add listener in timeout)
        timeoutID: ReturnType<typeof setTimeout> | undefined;
        idxFirstRow: number;
        tableScrollTop: number;
      } = {
        sink: undefined,
        timeoutID: undefined,
        idxFirstRow: -1,
        tableScrollTop: 0,
      };

      let numberOfRetries = 0;

      function addSinkFocusListenerOnce() {
        refInfo.sink = tableRef?.current?.element?.querySelector('.rdg-focus-sink') as HTMLElement;
        if (refInfo.sink) {
          refInfo.sink.addEventListener('focus', onFocusSink, { once: true }); // Remark: option once ==> listener will be removed after first execution
          // console.info('>>>>>>>>>>>>>>>> listener ADDED', data || 'NO DATA', windowData || 'NO WINDOW DATA')
        } else if (data?.length > 0) {
          numberOfRetries += 1;
          // console.info('>>>>>>>>>>>>>>>> listener RETRY', data || 'NO DATA', windowData || 'NO WINDOW DATA')
          if (numberOfRetries < 200) refInfo.timeoutID = setTimeout(addSinkFocusListenerOnce, 50); // Remark: was not able to run this code, but better be safe than sorry :)
        }
      }

      function onFocusSink() {
        // Save current scroll info table
        // ==============================
        if (tableRef.current?.element) {
          const tableTop = tableRef.current.element.getBoundingClientRect().top;
          const headerRowHeight = attributes.header === 'false' ? 0 : filters.enabled ? 70 : 35;
          const firstRow = Array.from(tableRef.current.element.querySelectorAll('.row-wrapper[data-index]')).find(
            (row) => {
              const rowTop = row.getBoundingClientRect().top;
              if (rowTop < tableTop + headerRowHeight - 1) return false;
              return rowTop < tableTop + headerRowHeight + 2;
            },
          );
          let idxFirstRow = -1;
          if (firstRow) idxFirstRow = +(firstRow.getAttribute('data-index') || '-1');
          // console.info('>>>>>>>>>>>>> first row', idxFirstRow)
          refInfo.idxFirstRow = idxFirstRow;
          refInfo.tableScrollTop = tableRef.current.element.scrollTop;
        } else {
          // program protection only (not able to simulate this specific case)
          refInfo.idxFirstRow = -1;
          refInfo.tableScrollTop = 0;
        }

        // Submit base functionality
        // =========================
        setOnInitialFocusInfo({ idxFirstRow: refInfo.idxFirstRow, tableScrollTop: refInfo.tableScrollTop }); // Render first needed !! (else: Synchronize selected cell from selected rows is NOT working)
      }

      if (currentColumn.current === -1 && addFocusListener.requested) {
        // Remark: only if requested, wait until focus sink available (skip first render cycle (mounting))
        if (tableRef.current?.element?.contains(document.activeElement)) {
          // case: table has already focus when server data changes. E.g. enter non-existing warehouse code in editable grid and press <ENTER>, pressing <F5> while table has focus, return from modal prompt, ...
          onFocusSink();
        } else {
          addSinkFocusListenerOnce();
        }
      }

      // Clean-up
      // ========
      return () => {
        // console.info('>>>>>>>>>>>>>>>> listener CLEAN-UP')

        // Case sink created            ==> when timeout has triggered execution of addSinkFocusListenerOnce
        // -----------------
        if (refInfo.sink) {
          /*
            When sink got focus, event listener was removed automatically (option once). However we also need to cover the case
            where the sink did not get focus when this useEffect is triggered again. Eg launch program with a table, do not
            select a row but press F5 (refresh) ==> new listener will be added by this effect so the old one needs to be removed
          */
          refInfo.sink.removeEventListener('focus', onFocusSink); // Remark: "theoretically" only needed when sink did not get focus (but remove listener has no effect if already removed)
          // console.info('>>>>>>>>>>>>>>>> listener REMOVED', data || 'NO DATA', windowData || 'NO WINDOW DATA')

          // Case sink not yet created
          // -------------------------
        } else if (refInfo.timeoutID) {
          /*
            This means that data is changed (triggering this effect) before the "previous" listener was added. As executing current
            "useEffect" code will attempt to add a new listener, we need to cancel the previous request otherwise we end up with
            2 listeners.
          */
          clearTimeout(refInfo.timeoutID);
          // console.info('>>>>>>>>>>>>>>>> listener CANCELED', refInfo.timeoutID, data || 'NO DATA', windowData || 'NO WINDOW DATA')
        }
      };
    },
    [addFocusListener],
    [attributes.header, data?.length, filters.enabled, setOnInitialFocusInfo],
  );

  useAdvancedEffect(
    () => {
      function onFocusSinkBase() {
        // console.info('>>>>>>>>>>>>>>>> listener EXECUTED', data || 'NO DATA', windowData || 'NO WINDOW DATA')
        function determineColumnIndex(rowIdx: number) {
          if (!isEditableTable) return 0;
          const row = sortedRows[rowIdx];
          if (row && _columns) {
            const idx = _columns.findIndex((col) => col.editable(row));
            if (idx !== -1) return idx;
          }
          return 0;
        }

        // No longer action needed if selected cell is known by now      e.g. "system" selected initial cell (server based)
        // ========================================================
        if (currentColumn.current !== -1) return;

        // Initialize
        // ==========

        // --> sorted rows
        if (!sortedRows || sortedRows.length === 0) {
          // program protection: not really expected to be ever executed (sink should never get focus if table has no rows), but with timers you never know
          setTimeout(onFocusSinkBase, 50); // bad timing => retry later
          return;
        }

        // --> selected rows
        const _selectedRowIdxs = new Set(selectedRowIdxs);

        // Select first invalid cell (if any)
        // ==================================
        if (Object.keys(invalidCells).length > 0) {
          const minRowId: string = Object.keys(invalidCells).sort((a, b) => a.localeCompare(b))[0]; // remark: respect server row order (sort on id because server sends error for (his) first row with error)
          const rowIdx: number = sortedRows.findIndex((row: any) => row.id === minRowId);
          const idx = _columns.findIndex((col) => col.id === invalidCells[minRowId][0]); //         // remark: respect server column order (==> array element 0)
          if (rowIdx !== -1 && idx !== -1) {
            tableRef.current?.selectCell({ rowIdx: rowIdx, idx: idx });
          } // NTH_SKE else: handle case row filtered out (==> not in sorted rows) or column not visible

          // Synchronize selected cell from selected rows (if any)
          // =====================================================
        } else if (_selectedRowIdxs.size > 0) {
          /*
            To simulate issue covered here:
              1 start "work with exchange rates"
              2 select row 2 & 3
              3 press <ENTER>
              4 press <F12>           ==> row 3 is still selected (remark that the cursor is in the positioner currency field)
              5 press <SHIFT+TAB>     ==> cell (0,0) is selected  (CONFLICTS WITH row 3 is selected)
          */

          // --> Get id of first selected
          const [rowId] = _selectedRowIdxs;

          // --> get corresponding index in sorted rows
          let index = sortedRows.findIndex((row: any) => row.id === rowId);
          if (index === -1) index = 0; // program protection: not really expected but possible in described simulation scenario if you use a filter to remove selected row 3 out of the sorted rows (from general point of view, not sure if we should keep selected rows when we are filtering)

          // --> select cell
          if (tableRef.current) {
            /* Remark
                Current code scrolls the selected lines into view (if any). This is also the default behavior of the rdg component when the table receives input
                focus. When you use the standard available function (scrollTo), the corresponding row becomes the FIRST visible in the table (if the row was not
                already in the viewport before the scroll request). So far so good, however if you select for the first time a cell in a table, the standard behavior
                of the component positions the scroll bar in such a way that the selected cell becomes part of the LAST visible row. As work-around previous line of
                code restores the scroll position according to the selected rows principle (first visible)

                NTH: get rid of "flickering"
            */
            if (!isEditableTable) preventComponentScrolling(); // Do not scroll for non editable tables (keep current scrollposition)
            tableRef.current.selectCell({ rowIdx: index, idx: determineColumnIndex(index) });
            setSelectedRowIdxs(_selectedRowIdxs); // restore current selected (as previous statement probably has changed it)

            if (isEditableTable) {
              if (onInitialFocusInfo.idxFirstRow === index) {
                tableRef.current.element?.scrollTo({
                  top: onInitialFocusInfo.tableScrollTop,
                  behavior: 'auto',
                });
              } else if (onInitialFocusInfo.idxFirstRow === -1 && rowId && tableRef.current.element) {
                // workaround for firefox
                const tableHeight = tableRef.current.element.clientHeight - 35; //TNTH adjust for filter??
                const rowIndex = data.findIndex((r: any) => r.id === rowId);
                const scrollTop = rowIndex * 35;
                if (scrollTop + 35 > tableHeight) {
                  tableRef.current.element.scrollTo({
                    top: scrollTop,
                    behavior: 'auto',
                  });
                }
              }
            }
          }

          // Else select "first" row/cell
          // ============================
        } else {
          if (tableRef.current) {
            let rowIdx = currentSelection.current;
            let idx = currentColumn.current;

            if (!isEditableTable && idx === -1 && rowIdx <= 0) {
              // if only some scrolling done until now: select first visible row (remark: use previous saved value; as at this time somehow actual scrollTop is 0)
              rowIdx = getIndexOfFirstVisibleSortedRow(refFreezeScrolling.current.scrollTop);
              idx = 0;
            }

            if (rowIdx < 0) {
              rowIdx = 0;
            } else if (rowIdx >= sortedRows.length) {
              rowIdx = sortedRows.length - 1;
            }
            if (idx < 0) {
              idx = 0;
            } else if (idx >= _columns.length) {
              idx = _columns.length - 1;
            }

            if (isEditableTable) {
              let nextPos = {
                idx: idx,
                rowIdx: rowIdx,
              };

              const getNextPosition = (startPos: { idx: number; rowIdx: number }) => {
                // Initialize
                // ==========
                const nextPos = {
                  idx: startPos.idx + 1,
                  rowIdx: startPos.rowIdx,
                };

                // Adjust if outside column boundaries
                // ===================================
                if (nextPos.idx > _columns.length - 1) {
                  nextPos.idx = 0;
                  nextPos.rowIdx += 1;
                }

                // Adjust if outside row boundaries
                // ================================
                if (nextPos.rowIdx > sortedRows.length - 1) {
                  nextPos.rowIdx = 0;
                }

                // Return result
                // =============
                return nextPos;
              };

              while (true) {
                // Next position found if cell is editable
                // ----------------------------------------
                if (_columns[nextPos.idx].editable(sortedRows[nextPos.rowIdx])) {
                  break;
                }
                // Get next position
                // -----------------
                nextPos = getNextPosition(nextPos);

                // Infinite loop protection
                // ------------------------
                if (nextPos.idx === idx && nextPos.rowIdx === rowIdx) {
                  nextPos.idx = 0;
                  nextPos.rowIdx = 0;
                  break;
                }
              }
              tableRef.current.selectCell({ rowIdx: nextPos.rowIdx, idx: nextPos.idx });
            } else {
              refSpecialKeys.current = { isShiftKeyDown: false, isCtrlKeyDown: false };
              setSelectedRowIdxs(new Set<string>([refSortedRows.current[rowIdx].id]));
              preventComponentScrolling();
              tableRef.current.selectCell({ rowIdx: rowIdx, idx: idx });
            }
          }
        }
      }

      if (onInitialFocusInfo.idxFirstRow !== -2) onFocusSinkBase();
    },
    [onInitialFocusInfo],
    [sortedRows, selectedRowIdxs, _columns, invalidCells, isEditableTable, setSelectedRowIdxs],
  );

  /*
    apply settings to column
 */

  const settingsColumns = useMemo(() => {
    const allColumns = columns.map((col: Column, index: number) => {
      return {
        key: col.id,
        id: col.id,
        name: col.Header,
        editable: false,
        resizable: true,
        draggable: true,
        width: tableColumnResizing?.[col.id] || col.width,
        hide: tableHiddenColumns?.includes(col.id),
        index: tableColumnOrder?.indexOf(col.id) > -1 ? tableColumnOrder?.indexOf(col.id) : index,
        noHeader: col.noHeader,
      };
    });
    if (attachments) {
      attachments.map((att: any, index: number) => {
        const col = { ...att.$ }; //Make a copy
        if (col.column) {
          col.Header = col.column; //Fix structure to adhere to type Column
          allColumns.push({
            key: col.id,
            id: col.id,
            name: col.Header,
            editable: false,
            resizable: true,
            draggable: true,
            width: tableColumnResizing?.[col.id] || col.width,
            hide: tableHiddenColumns?.includes(col.id),
            noHeader: false,
            index:
              tableColumnOrder?.indexOf(col.id) > -1 ? tableColumnOrder?.indexOf(col.id) : allColumns.length + index,
          });
        }
      });
    }
    return allColumns;
  }, [columns, tableColumnOrder, tableHiddenColumns, tableColumnResizing]);

  let timeout: any;
  const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
    const sourceColumnIndex =
      tableColumnOrder?.indexOf(sourceKey) > -1
        ? tableColumnOrder?.indexOf(sourceKey)
        : settingsColumns.find((c: any) => c.id === sourceKey)?.index;
    const targetColumnIndex =
      tableColumnOrder?.indexOf(targetKey) > -1
        ? tableColumnOrder?.indexOf(targetKey)
        : settingsColumns.find((c: any) => c.id === targetKey)?.index;
    if (sourceColumnIndex !== undefined && targetColumnIndex !== undefined) {
      let reorderedColumns = [...settingsColumns.sort((a: any, b: any) => a.index - b.index)];
      reorderedColumns.splice(
        targetColumnIndex + (targetColumnIndex < sourceColumnIndex ? 1 : 0),
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0],
      );
      setTableColumnOrder?.({
        colOrder: reorderedColumns.map((col: any) => col.id),
        panelID: panelID,
        formID: formID,
        tableID: id,
        toggleID: toggleID,
      });
    }
  };

  const downloadDocumentOutput = (rowId: string) => {
    let path = XT.getValueFromWindowRow({ data: windowData }, panelID, rowId, 'PATH');
    let name = XT.getValueFromWindowRow({ data: windowData }, panelID, rowId, 'FileName');
    axios
      .post('/client/' + 'document-output', { path: path, filename: name }, { responseType: 'blob' })
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          // trigger download and reload page
          let blob = new Blob([res.data]);
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
          link.remove();
          reload();
        } else {
          showDialog({
            message: localization.getString('TXT_DownloadError') || 'File cannot be downloaded at the moment',
            completeScreenBlock: true,
            restrictOutsideClick: true,
            hideCancelButton: true,
            confirmText: Localization.instance.getString('TXT_OK'),
            overlayContainerId: 'legacy',
          });
        }
      })
      .catch((e: any) => {
        console.error(`Error from downloadDocumentOutput${e}`);
        showDialog({
          message: localization.getString('TXT_DownloadError') || 'File cannot be downloaded at the moment',
          completeScreenBlock: true,
          restrictOutsideClick: true,
          hideCancelButton: true,
          confirmText: Localization.instance.getString('TXT_OK'),
          overlayContainerId: 'legacy',
        });
      });
  };

  /*
  render context menu and execute corresponding action on click
   */
  const handleContextOption = (contextOption: ContextOption) => {
    // if (selectedRowIdxs.size > 0) {
    if (contextOption.type === 'application') {
      let url = `${contextOption.key}`;
      let matches = contextOption.key.match(/\|(.*?)\|/g);
      let selectedRow = selectedRowIdxs.values().next().value;
      if (selectedRow) {
        let rowID = selectedRow;
        url = url.replaceAll(' ', '+');
        if (matches && matches.length > 0) {
          matches.forEach((match: any) => {
            let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, match.replaceAll('|', ''));
            url = url.replace(match, encodeURIComponent((val || '').trim()));
          });
        }
        if (contextOption.id === 'IBS_PV') {
          const aurl = window.URL.createObjectURL(new Blob([`[Command]\nArguments=${url}`]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = aurl;
          // the filename you want
          a.download = 'Start_PlannerView.ipv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(aurl);
          a.remove();
        } else {
          const urlOpenedInNewTab = window.open(url);
          if (urlOpenedInNewTab) urlOpenedInNewTab.opener = null;
        }
      }
    } else if (contextOption.action?.overrideSQL === 'true') {
      let selectedRow = selectedRowIdxs.values().next().value;
      if (selectedRow) {
        let rowID = selectedRow;
        let val = XT.getValueFromWindowRow(
          { data: windowData },
          panelID,
          rowID,
          contextOption.action?.variables?.replaceAll('|', ''),
        );
        if (val?.startsWith('SELECT')) {
          if (contextOption.action?.exportType) {
            sendCustomCommand(
              `<?xml version="1.0" encoding="UTF-8"?><form action="EXECUTE_SQL" id="QueryManager"><ctrl id="SQLSTRING" value="${val.replace(
                'SELECT *',
                'SELECT count(1)',
              )}/* heading=text */"/><ctrl id="TRIM" value="true"/><ctrl id="INCLUDE_LINENBR" value="true"/><ctrl id="TITLE" value="printer_output"/></form>`,
              { action: contextOption.action },
            );
          } else {
            sendCustomCommand(
              `<?xml version="1.0" encoding="UTF-8"?><form action="EXECUTE_SQL" id="QueryResult"><ctrl id="SQLSTRING" value="${val}/* heading=text */"/><ctrl id="TRIM" value="true"/><ctrl id="INCLUDE_LINENBR" value="true"/><ctrl id="TITLE" value="printer_output"/></form>`,
              { action: contextOption.action },
            );
          }
        } else {
          showDialog({
            message:
              localization.getString(contextOption?.action?.errorMessage || '') ||
              Localization.instance.getString('TXT_ErrorOccurred'),
            completeScreenBlock: true,
            restrictOutsideClick: true,
            hideCancelButton: true,
            confirmText: Localization.instance.getString('TXT_OK'),
            overlayContainerId: 'legacy',
          });
        }
      }
    } else if (contextOption.action?.name?.toLowerCase() === 'downloaddocumentoutput') {
      if (selectedRowIdxs.size !== 1) {
        showDialog({
          message: localization.getString('TXT_Select_one_file_at_the_time') || 'Only one file can be selected',
          completeScreenBlock: true,
          confirmText: Localization.instance.getString('TXT_OK'),
          cancelText: Localization.instance.getString('ROLE_BUTTON_Cancel'),
          restrictOutsideClick: true,
          hideCancelButton: true,
          overlayContainerId: 'legacy',
        });
      } else {
        downloadDocumentOutput(selectedRowIdxs.values().next().value);
      }
      return;
    } else {
      if (attributes.fullselect === 'true' || formID === 'SpooledFileQueueSelection') {
        Array.from(selectedRowIdxs).forEach((id: string) => {
          let rowID = id;
          addToRowCommand(panelID, rowID, contextOption.key, contextOption.value, -1);
          let tempCols = windowData.form.panel
            .find((p: any) => p.$.id === panelID)
            .row.find((r: any) => r.$.id === rowID).ctrl;
          tempCols = tempCols.filter((c: any) => c.$.id !== contextOption?.key);
          columns.forEach((col: Column) => {
            let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, col.id) || '';
            if (col.contenttype === 'time') {
              let time = Formatters.Time.in(val?.trim() || '', +(col?.limit ?? '0'));
              val = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
            }
            if (tempCols.find((c: any) => c.$.id === col.id)) addToRowCommand(panelID, rowID, col.id, val, -1);
          });
          tempCols
            .filter((c: any) => columns.map((c: Column) => c.id).indexOf(c.$.id) === -1)
            .forEach((ctrl: any) => {
              addToRowCommand(
                panelID,
                rowID,
                ctrl.$.id,
                XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, ctrl.$.id),
                -1,
              );
            });
        });
      }
      if (contextOption.action?.name?.toLowerCase() === 'financialquickentrycopydelete') {
        let selectedRows = sortedRows.filter(
          (row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1 && row.selectable,
        );
        if (contextOption.value === 'MARK_SELECTED') {
          setMarkAndUnmarkRows([...selectedRows.map((r: any) => r.id)]);
        } else if (contextOption.value === 'RESET_MARKED') {
          setMarkAndUnmarkRows(null);
          setMarkedRows([]);
        } else if (contextOption.value === 'PASTE_MARKED') {
          let current = +selectedRows[selectedRows.length - 1].id;
          let _rows = rows;
          rows
            .filter((row: any) => row.markedForCopy === true)
            .forEach((row: any) => {
              let _rowIndex = _rows.findIndex((r: any) => r.id === `${current}`);
              if (_rowIndex > -1) {
                _rows[_rowIndex] = { ...row, id: `${current}`, markedForCopy: false };
                columns.forEach((col: Column) => {
                  let value = '';
                  if (col.id.toLowerCase() !== 'copypaste') {
                    value = row[col.id];
                    addToRowCommand(panelID, `${current}`, col.id, value, -1);
                  }
                });
              }
              current++;
            });
          setAction({ event: 'ENTER' });
          sendCommand();
          setMarkAndUnmarkRows([]);
          setMarkedRows([]);
        } else if (contextOption.value === 'DELETE_SELECTED') {
          setMarkAndUnmarkRows([]);
          setMarkedRows([]);
          selectedRowIdxs.forEach((id: string) => {
            let rowID = id;
            columns.forEach((col: Column) => {
              if (col.id.toLowerCase() !== 'copypaste') addToRowCommand(panelID, rowID, col.id, '', -1);
            });
          });
          setAction({ event: 'ENTER' });
          sendCommand();
        }
      } else if (contextOption?.action?.name?.toLowerCase() === 'textentrycopydelete') {
        let selectedRows = sortedRows.filter(
          (row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1 && row.selectable,
        );
        if (contextOption.value === 'MARK_SELECTED') {
          setMarkAndUnmarkRows([...selectedRows.map((r: any) => r.id)]);
          setMarkedRows([[...selectedRows.map((r: any) => r.id)]]);
        } else if (contextOption.value === 'RESET_MARKED') {
          setMarkAndUnmarkRows(null);
          setMarkedRows([]);
        } else if (contextOption.value === 'PASTE_MARKED') {
          let current = +selectedRows[selectedRows.length - 1].id;
          let _rows = rows;
          rows
            .filter((row: any) => row.markedForCopy === true)
            .forEach((row: any) => {
              let _rowIndex = _rows.findIndex((r: any) => r.id === `${current}`);
              if (_rowIndex > -1) {
                _rows[_rowIndex] = { ...row, id: `${current}`, markedForCopy: false };
              }
              current++;
            });
          setMarkAndUnmarkRows(null);
          setMarkedRows([]);
        } else if (contextOption.value === 'DELETE_SELECTED') {
          let colId = '';
          _columns.forEach((c) => {
            const col = columns.find((col) => col.id === c.id);
            if (col) {
              if (col.readonly === 'false' && col.visible !== 'false') colId = col.id;
            }
          });
          let idsToDelete: string[] = [];
          selectedRows.forEach((row: any) => idsToDelete.push(row.id));
          let _rows = rows;
          for (let i = 0; i < _rows.length; i++) {
            if (idsToDelete.includes(_rows[i].id)) {
              _rows[i][colId] = null;
              _rows[i].markedForCopy = false;
            }
          }
          let offset = 0;
          for (let i = 0; i < _rows.length; i++) {
            if (_rows[i][colId] === null) {
              offset++;
            } else if (offset > 0) {
              _rows[i - offset][colId] = _rows[i][colId];
              _rows[i - offset].markedForCopy = _rows[i].markedForCopy;
              _rows[i][colId] = null;
              _rows[i].markedForCopy = false;
            }
          }
          _rows = _rows.filter((row: any) => row[colId] !== null);
          setMarkedRows([]);
        } else if (contextOption.value === 'COPY_TO_CLIPBOARD') {
          setMarkAndUnmarkRows([...selectedRows.map((r: any) => r.id)]);
          setMarkedRows([...selectedRows.map((r: any) => r.id)]);
          let colId = '';
          _columns.forEach((c) => {
            const col = columns.find((col) => col.id === c.id);
            if (col) {
              if (col.readonly === 'false' && col.visible !== 'false') colId = col.id;
            }
          });
          let clipBoardText: string[] = [];
          rows
            .filter((row: any) => row.markedForCopy === true)
            .forEach((row: any) => {
              clipBoardText.push(row[colId]);
            });
          navigator.clipboard
            .writeText(clipBoardText.join('\n'))
            .catch((err) => console.error(`Unable to copy text to clipboard: ${err}`));
          setMarkedRows([]);
        } else if (contextOption.value === 'PASTE_FROM_CLIPBOARD') {
          let colId = '';
          _columns.forEach((c) => {
            const col = columns.find((col) => col.id === c.id);
            if (col) {
              if (col.readonly === 'false' && col.visible !== 'false') colId = col.id;
            }
          });
          navigator.clipboard
            .readText()
            .then((clipboardText) => {
              let current = +selectedRows[selectedRows.length - 1].id;
              let _rows = rows;
              clipboardText.split('\n').forEach((text: string) => {
                let _rowIndex = _rows.findIndex((r: any) => r.id === `${current}`);
                if (_rowIndex > -1) {
                  _rows[_rowIndex][colId] = text;
                  _rows[_rowIndex].markedForCopy = false;
                }
                current++;
              });
              setMarkedRows([]);
            })
            .catch((err) => {
              console.error(`Unable to paste from clipboard: ${err}`);
            });
        }
      } else {
        let selectedRows = sortedRows.filter(
          (row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1 && row.selectable,
        );
        onContextMenuClick(
          panelID,
          selectedRows.map((row: any) => row.id),
          contextOption,
        );
      }
    }
    // }
  };

  const refForcedSelectedCell = useRef<{
    rowIdx: Number;
    colIdx: Number;
    rowsHaveFocus: boolean;
    documentLostFocus: boolean;
  }>({ rowIdx: -1, colIdx: -1, rowsHaveFocus: false, documentLostFocus: false });
  useEffect(() => {
    /*
      The react-data-grid component is more a spreadsheet style component than a real table component. To maintain data in an editable cell, the user can
      start typing data in a selected cell. As a consequence the existing value is replaced by the new entered data. To change existing data (eg inserting
      an additional character or removing one) the user must press F2 or doubleclick the cell first. However in our application both F2 and doubleclick
      might invoke an associated action (and trigger a server call). That and the fact that it is not very practical that you need an extra manipulation
      before you can change an existing value, forced us to write some code to change this default behavior.

      The rdg component distinguishes two different modes for a selected cell:
        ° SELECT: "normal" selected cell (shows as a yellow outlined "readonly" cell)
        ° EDIT: "changable" cell (shows as a green outlined "editable" cell with current value selected or with a cursor, the result of pressing F2 on a
          "normal" selected cell)
      The base idea is to force the mode of an editable cell to EDIT whenever a cell gets selected.

      The only place where the component exposes the selected cell mode is in the row renderer function. It is also a perfect candidate to include our
      changes as whenever a cell gets selected (or whenever the mode of the selected cell changes) the corresponding row needs to be rerendered to show
      the visual aspects of the selection.

      As only one element on a document can have INPUT focus, part of the standard behavior of the rdg component is that the mode of the selected cell is
      changed from EDIT to SELECT whenever an element outside the datagrid is selected. We need to keep this behavior (otherwise it would not be possible
      anymore to select any element outside the grid). That's why we need to keep track of the fact if any cell has the input focus. To accomplish this
      event listeners are defined on both the focus out and focus in event of the grid. If any cell has INPUT focus than we can force the mode to EDIT, in
      the other case we need to keep the selected cell in SELECT mode. Remark: unfortunately the related target is not always present on the focus out nor
      on the focus in event. That's why we need them both. Additionally be aware that we get some "unexpected" focus in/out events when a cell changes from
      SELECT to EDIT mode (and vice versa) - see appended detailed list.

      Additional remarks:
        ° some extra code was needed in onTableFocusOut to bypass an rdg bug
        ° some extra code was needed in onTableFocusIn to reselect a cell when application gets focus back (eg when switching between different applications).
          Default behavior is switching from EDIT to SELECT mode when the applicication looses focus and when focus comes back no row rendering was triggered.
          An explicit (re-)select of the cell triggers the row renderer function (and forces the selected cell to EDIT mode when needed)

      USER ACTION                                                             EVENT           TARGET                       RELATED TARGET        RELATED CUSTOM CODE
      ======================================================================  =========       ===============              ==============        ===================
      keyboard nav from not editable to not editable cell:                    FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      keyboard nav from editable to not editable cell:                        FOCUS OUT       rdg-text-editor     -->      <null>
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>

      keyboard nav from not editable to editable cell:                        FOCUS OUT       rdg-focus-sink      -->      rdg-text-editor       <== FORCE TO EDIT MODE
                                                                              FOCUS IN        rdg-text-editor     <--      rdg-focus-sink        <== FORCE TO EDIT MODE

      keyboard nav from editable to editable cell:                            FOCUS OUT       rdg-text-editor     -->      <null>
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>
                                                                              FOCUS OUT       rdg-focus-sink      -->      rdg-text-editor       <== FORCE TO EDIT MODE
                                                                              FOCUS IN        rdg-text-editor     <--      rdg-focus-sink        <== FORCE TO EDIT MODE

      right click not editable cell (==> contextmenu):                        FOCUS OUT       rdg-focus-sink      -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      (window active)

      from contextmenu to not editable cell (press ESC):                      FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      right click editable cell (==> contextmenu):                            FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      from contextmenu to editable cell (press ESC):                          FOCUS OUT       rdg-text-editor              <null>
                                                                              FOCUS IN        rdg-focus-sink               <null>
                                                                              FOCUS OUT       rdg-focus-sink      -->      rdg-text-editor       <== FORCE TO EDIT MODE
                                                                              FOCUS IN        rdg-text-editor     <--      rdg-focus-sink        <== FORCE TO EDIT MODE

      click column header while not editable cell selected:                   FOCUS OUT       rdg-focus-sink      -->      (window active)

      click column header while editable cell selected:                       FOCUS OUT       rdg-text-editor     -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (window active)       <== FIX BUG RDG

      click table menu while not editable cell selected:                      FOCUS OUT       rdg-focus-sink      -->      (dropdown-toggle)

      click table menu while editable cell selected:                          FOCUS OUT       rdg-text-editor     -->      (dropdown-toggle)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (dropdown-toggle)     <== FIX BUG RDG

      click filter input while not editable cell selected:                    FOCUS OUT       rdg-focus-sink      -->      (filter)
                                                                              FOCUS IN        (filter)            <--      rdg-focus-sink

      click filter input while editable cell selected:                        FOCUS OUT       rdg-text-editor      -->     (filter)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (filter)              <== FIX BUG RDG
                                                                              FOCUS IN        (filter)            <--      rdg-focus-sink        <== FIX BUG RDG

      click browser url while not editable cell selected:                     FOCUS OUT       rdg-focus-sink      -->      <null>

      click browser url while editable cell selected:                         FOCUS OUT       rdg-text-editor     -->      <null>

      click any other control outside table while not editable cell selected: FOCUS OUT       rdg-focus-sink      -->      (form-control)

      click any other control outside table while editable cell selected:     FOCUS OUT       rdg-text-editor     -->      (form-control)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (form-control)        <== FIX BUG RDG

      click maximize/restore button while not editable cell selected:         FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      click maximize/restore button while editable cell selected:             FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      select other application while not editable cell selected:              FOCUS OUT       rdg-focus-sink      -->      <null>

      reselect our UI application (return to not editable cell)               FOCUS IN        rdg-focus-sink      <--      <null>                ==> RESELECT CELL (no effect as not editable)

      select other application while editable cell selected:                  FOCUS OUT       rdg-text-editor     -->      <null>

      reselect our UI application (return to editable cell)                   FOCUS IN        rdg-focus-sink      <--      <null>                ==> RESELECT CELL (==> FORCE TO EDIT MODE)
                                                                              FOCUS OUT       rdg-focus-sink      -->      rdg-text-editor       <== FORCE TO EDIT MODE
                                                                              FOCUS IN        rdg-text-editor     <--      rdg-focus-sink        <== FORCE TO EDIT MODE

      press ESC while not editable cell selected:                             FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      press ESC while editable cell selected:                                 FOCUS OUT       rdg-text-editor     -->      <null>
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>
                                                                              FOCUS OUT       rdg-focus-sink      -->      rdg-text-editor       <== FORCE TO EDIT MODE
                                                                              FOCUS IN        rdg-text-editor     <--      rdg-focus-sink        <== FORCE TO EDIT MODE

      click (other) not editable cell while not editable cell selected:       FOCUS OUT       rdg-focus-sink      -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      (window active)

      click (other) editable cell while not editable cell selected:           FOCUS OUT       rdg-focus-sink      -->      (window active)
                                                                              FOCUS IN        rdg-text-editor     <--      (window active)       Remark: no FORCE TO EDIT MODE as editorOptions.editOnClick = true

      click (other) not editable cell while editable cell selected:           FOCUS OUT       rdg-text-editor     -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (window active)       <== FIX BUG RDG    NTH: is there a way to recognize this situation as UNEXPECTED???
                                                                              FOCUS IN        rdg-focus-sink      <--      (window active)       <== FIX BUG RDG    NTH: is there a way to recognize this situation as UNEXPECTED???

      click (other) editable cell while editable cell selected:               FOCUS OUT       rdg-text-editor     -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      <null>                <== RDG BUG
                                                                              FOCUS OUT       rdg-focus-sink      -->      (window active)       <== FIX BUG RDG    NTH: is there a way to recognize this situation as UNEXPECTED???
                                                                              FOCUS IN        rdg-text-editor     <--      (window active)       <== FIX BUG RDG    NTH: is there a way to recognize this situation as UNEXPECTED???

      click same not editable cell while not editable cell selected:          FOCUS OUT       rdg-focus-sink      -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      (window active)

      click same editable cell while editable cell selected:                  FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------

      ==================================================================================================================================================================
      HTH: fix known issue (no focus in/out events when canceling context menu ==> selected cell NOT forced to EDIT mode)
      right click editable cell while not editable selected                   FOCUS OUT       rdg-focus-sink      -->      (window active)
                                                                              FOCUS IN        rdg-focus-sink      <--      (window active)

      from contextmenu to not editable cell (press ESC):                      FOCUS OUT       ------                       -------
                                                                              FOCUS IN        ------                       -------
      ==================================================================================================================================================================
    */
    const rdgTable = tableRef?.current?.element;
    rdgTable?.addEventListener('focusout', onTableFocusOut);
    rdgTable?.addEventListener('focusin', onTableFocusIn);
    return () => {
      rdgTable?.removeEventListener('focusout', onTableFocusOut);
      rdgTable?.removeEventListener('focusin', onTableFocusIn);
    };
  }, []);

  useAdvancedEffect(
    () => {
      if (initialSelectedCell.colIdx > -1 && initialSelectedCell.rowIdx > -1) {
        if (
          initialSelectedCell.colIdx !== currentColumn.current ||
          initialSelectedCell.rowIdx !== currentSelection.current
        ) {
          // Select initial cell (editable table)
          // ====================================
          refForcedSelectedCell.current.rowIdx = initialSelectedCell.rowIdx;
          refForcedSelectedCell.current.colIdx = initialSelectedCell.colIdx;

          tableRef.current?.selectCell(
            { rowIdx: initialSelectedCell.rowIdx, idx: initialSelectedCell.colIdx },
            initialSelectedCell.isEditableTable,
          ); // remark: not editable column in editable table is handled by the component itself (so not needed to check it also here)

          if (initialSelectedCell.cursorSelectionStart >= 0 && initialSelectedCell.isEditableTable) {
            initialSelectedCell.column!.preferredCaretPosition = initialSelectedCell.cursorSelectionStart; // To execute this code: w/w reports && context option "layout":  move input cursor (aka caret) to any in lower grid && press F4 && double click any row ==> after server interaction this code is executed to "restore" the caret position (as requested by server through cursor)
          }

          // Scroll into view (if needed)
          // ============================
          const rowHeight = 35;
          if (
            tableRef?.current?.element &&
            (initialSelectedCell.rowIdx + 2) * rowHeight > tableRef.current.element.clientHeight
          ) {
            const adjust = Math.floor((tableRef.current.element.clientHeight - rowHeight) / (rowHeight * 2));
            const toIndex = Math.max(Math.min(initialSelectedCell.rowIdx - adjust - 1, sortedRows.length - 1), 0);
            if (sortedRows.length > 0) {
              setScrollToId({ scrollId: sortedRows[toIndex].id });
            }
          }
        }
      }
    },
    [initialSelectedCell],
    [setScrollToId],
  );

  useEffect(() => {
    if (importState.status === 'CONFIRMED') {
      if (!loadAdditionalPageBeforeImport()) {
        setSelectedRowIdxs(new Set([importState.selectedRowId])); // REMARK: keep order as next statements clears this info !
        startImport();
      }
    }
  }, [importState]);

  useEffect(() => {
    if (importState.status === 'NOT_ACTIVE') {
      tableRef.current?.selectCell({ idx: currentColumn.current, rowIdx: currentSelection.current }, true);
    }
  }, [importState]);

  const onTableFocusOut = (e: any) => {
    // Determine whether cell of any row will have focus
    // =================================================
    if (!e?.relatedTarget) {
      // eg click url input browser, select other application, ...
      refForcedSelectedCell.current.rowsHaveFocus = false;
    } else if (!e.relatedTarget.className?.includes('rdg')) {
      // NTH: more specific condition? Remark: currently column headers and filter fields do NOT fullfill the condition (and that is what we want) !!
      refForcedSelectedCell.current.rowsHaveFocus = false;
    }
    refForcedSelectedCell.current.documentLostFocus = !document.hasFocus();

    // Reset spcial keys down when application loses focus
    // ===================================================
    if (refForcedSelectedCell.current.documentLostFocus)
      refSpecialKeys.current = { isShiftKeyDown: false, isCtrlKeyDown: false };

    // Bypass react-data-grid issue: reselect control
    // ==============================================
    /*
      Remark: when a selected cell is in EDIT mode and a control outside the table is clicked,
      the mode of the cell is changed from EDIT to SELECT. This makes sense to me, but as a
      side effect the "cell" (rdg-focus-sink) gets also the focus again (DataGrid.js line 364).
      I would say this is a bug. To bypass the problem, we use a timeout to move the focus back
      to the original target of the click.
    */
    if (
      refForcedSelectedCell.current.rowsHaveFocus === false &&
      e?.relatedTarget &&
      !e.relatedTarget.classList.contains('data-table-span')
    ) {
      setTimeout(() => {
        if (document.hasFocus() && document.activeElement?.classList.contains('rdg-focus-sink')) {
          e?.relatedTarget.focus({ preventScroll: true });
        }
      }, 0);
    }
  };
  const onTableFocusIn = (e: any) => {
    // Determine whether cell of any row will have focus
    // =================================================
    if (!refForcedSelectedCell.current.rowsHaveFocus) {
      // Determine whether cell of any row gets focus
      // --------------------------------------------
      if (
        (e?.target?.className && e?.target?.className?.includes('rdg')) ||
        (e.target.type === 'checkbox' && e.target.closest('.editable-cell'))
      ) {
        refForcedSelectedCell.current.rowsHaveFocus = true;
      }
      // Reselect current cell if application gets focus back         (forces row rendering ==> force EDIT mode if needed)                    NTH? restrict to only if cell had EDIT mode???
      // ----------------------------------------------------
      if (
        refForcedSelectedCell.current.documentLostFocus &&
        refForcedSelectedCell.current.rowsHaveFocus &&
        e?.target &&
        e.target.classList.contains('rdg-focus-sink')
      ) {
        if (isEditableTable) {
          tableRef.current?.selectCell({ idx: currentColumn.current, rowIdx: currentSelection.current });
        } else {
          preventComponentScrolling();
        }
      }
    }
    refForcedSelectedCell.current.documentLostFocus = false;
  };
  /*
  display row in table
   */
  const rowRenderer = (props: RowRendererProps<any>) => {
    // Force selected cell always to be in EDIT mode when editable
    // ===========================================================
    /*
      Remark: if not in edit mode all kind of akward side-effects occurred when
      ° entering data. Eg when entering data in a selected cell not yet in edit mode the following was happening:
         - did go in edit mode
         - appended first typed character to existing value
         - selected the entire resulting value
         - the second typed character was replacing the entire selected resulting value
         - ...
      ° performing keyboard navigation (eg multiple arrow up's were needed to leave a cell, ...)
    */

    if (props.selectedCellProps?.mode && refForcedSelectedCell.current.rowsHaveFocus) {
      // Initialize
      // ----------
      const rowIdx = props.rowIdx;
      const colIdx = props.selectedCellProps.idx;

      // Force to EDIT mode when editable
      // --------------------------------
      if (props.selectedCellProps?.mode !== 'EDIT' && _columns[colIdx].editable(props.row)) {
        /*
          Force always "last" selected to EDIT mode
          But only if not yet done: avoid infinite loop if force already tried (remark: was not able to simulate a case like that, but as we require rerendering from a rendering function it's better to be safe :-()
        */
        if (rowIdx !== refForcedSelectedCell.current.rowIdx || colIdx !== refForcedSelectedCell.current.colIdx) {
          // --> Set forced selected cell
          refForcedSelectedCell.current.rowIdx = rowIdx;
          refForcedSelectedCell.current.colIdx = colIdx;

          // --> Force to EDIT mode
          setTimeout(() => {
            // only if still editable, has input focus and force not canceled
            if (
              _columns[colIdx].editable(props.row) &&
              refForcedSelectedCell.current.rowsHaveFocus === true &&
              rowIdx === refForcedSelectedCell.current.rowIdx &&
              colIdx === refForcedSelectedCell.current.colIdx
            ) {
              try {
                tableRef.current?.selectCell({ rowIdx, idx: colIdx }, true);
              } catch (e) {
                console.error(`Error from rowRenderer, ${e}`);
              }
            }
          }, 0);
        }

        // Reset forced selected cell
        // --------------------------
        /*
        Covers 3 possible cases
          ° other not editable cell selected in SELECT mode ==> always select last selected cell ==> cancel force of previous one (if not yet executed)
          ° other editable cell selected in EDIT mode ==> always select last selected cell ==> cancel force of previous one (if not yet executed)
          ° same cell in EDIT mode now ==> force was a success ==> reset force
      */
      } else {
        refForcedSelectedCell.current.rowIdx = -1;
        refForcedSelectedCell.current.colIdx = -1;
      }
    }

    // Return row
    // ==========
    return (
      <div
        data-panel={panelID}
        data-row={props.row.id}
        data-rowfield={attributes.rowfield || attributes.rowposition}
        className="row-wrapper"
        data-index={props.rowIdx}
        data-tip={props.row.tooltip}
        style={{ position: 'absolute', height: props.height + 'px', top: props.top + 'px' }}
      >
        <GridRow {...props} height={basicRowHeight} top={0} />
        <div
          style={{ position: 'absolute', height: props.height - basicRowHeight + 'px', top: basicRowHeight + 'px' }}
          className={'row-condition-text'}
        >
          {((props.row.rowConditionText as Array<string>) ?? []).map((item) => (
            <>
              {item} <br />
            </>
          ))}
        </div>
      </div>
    );
  };
  const memoizedRowRenderer = useMemo(() => {
    return rowRenderer;
  }, [
    panelID,
    attributes,
    _columns, // seems like variable refers to "OLD" _columns from inside the function rowRenderer if not included (eg drag & drop column to other position)
    localeSettings, // force rerendering of rows when locale settings changed
  ]);

  const gridElement = (
    <TableContext.Provider value={{ columns, panelID, formID, attributes, yes, no }}>
      <ReactDataGrid
        ref={tableRef}
        headerRowHeight={attributes.header === 'false' ? 0 : filters.enabled ? 70 : undefined}
        rowRenderer={memoizedRowRenderer}
        rowHeight={(row) => {
          // in the rowRenderer function which use this rowHeight there is -basicRowHeight px,
          // so to the height we want achive in grid element, we need to add +basicRowHeight px
          // e.g. when there is no rowConditionText and final height should be 0, we need to set height to basicRowHeight px
          let height = basicRowHeight;
          if (row.row && row.row.rowConditionText?.length > 0) {
            height += row.row.rowConditionText.length * basicRowHeight;
          }
          return height;
        }}
        className={
          'rdg-light data-table ignore-event' +
          (filters.enabled ? ' filter-container' : '') +
          (attributes.class ? ` ${attributes.class}` : '') +
          (attributes.autopagedown !== 'false' ? ' autopagedown' : '') +
          (attributes.gridlines === 'true' ? ' gridlines' : '')
        }
        onRowsChange={(gridRows: any[]) => {
          if (!gridRows || gridRows.length < 1) return;
          const _rows = [...rows];
          refChangedTableRows.current = [...refChangedTableRows.current];
          gridRows.forEach((row: any) => {
            const rowIndex = _rows.findIndex((r: any) => r.id === row.id);
            if (rowIndex > -1 && _rows[rowIndex] !== row) {
              _rows[rowIndex] = row;
              refChangedTableRows.current.push(row);
            }
          });
          setRows(_rows);
        }}
        columns={_columns}
        sortColumns={sortColumns}
        rows={sortedRows}
        cellNavigationMode="CHANGE_ROW"
        rowKeyGetter={rowKeyGetter}
        enableVirtualization={true}
        selectedRows={selectedRowIdxs}
        onSelectedRowsChange={setSelectedRowIdxs} //NTH_SKE: test if needed, what is this doing?
        onSelectedCellChange={(p) => {
          setFocussedElement(`${id}|${_columns[p.idx].id}`);
          // Get info previous selection
          // ===========================
          let oldSelected = currentSelection.current;
          let oldColumn = currentColumn.current;

          // Handle selected cell not changed
          // ================================
          if (oldSelected === p.rowIdx && oldColumn === p.idx) {
            // --> Toggle row selection if <ctrl> down
            if (
              !refRightMouseButtonDown.current &&
              !refSpecialKeys.current.isShiftKeyDown &&
              refSpecialKeys.current.isCtrlKeyDown
            ) {
              let row = sortedRows[p.rowIdx];
              let selected = selectedRowIdxs;
              if (!selected.has(row.id)) {
                selected.add(row.id);
                setSelectedRowIdxs(selected);
              } else if (selected.size > 1) {
                selected.delete(row.id);
                setSelectedRowIdxs(selected);
              }
            }

            // --> No further processing
            return;
          }

          // Get corresponding row
          // =====================
          let row = sortedRows[p.rowIdx];

          // Determine current row changed
          // =============================
          let currentRowChanged = false;
          if (currentSelection.current !== p.rowIdx || oldColumn === -1) currentRowChanged = true;

          // Save row/column new selected cell
          // =================================
          currentSelection.current = p.rowIdx;
          currentColumn.current = p.idx;
          // HTH: remove code commented out. Original code to force mode to EDIT. Didn't cover all possibilities and new code was move to row renderer. Wait with removal until we're sure current system has no unwanted side effects.
          // if (_columns[p.idx].editable(sortedRows[p.rowIdx])) {
          //   setTimeout(() => {                                          // Remark: timout needed to first allow unmount of previous "edit" control (and when data was changed overthere)
          //     try {
          //       if (_columns[p.idx].editable(sortedRows[p.rowIdx])) tableRef.current?.selectCell(p, true);  // condition repeated to make sure still editable at run time
          //     } catch(e) {
          //     }
          //   }, 0);
          // };

          // Determine resulting selected rows
          // =================================
          let selected = selectedRowIdxs;
          if (refRightMouseButtonDown.current) {
            /*
              Keep current selected rows if cell within current selected rows, else create new set for the
              corresponding current row only
            */
            if (!selected.has(row.id)) selected = new Set([row.id]);
          } else {
            // Reset selected rows (if needed)
            if (!refSpecialKeys.current.isShiftKeyDown && !refSpecialKeys.current.isCtrlKeyDown) {
              selected = new Set();
            } else if (selected.size === 0) {
              selected = new Set(); // special case: use <SHIFT> + <TAB> to move focus from outside the table into "fresh" loaded table ==> need new reference in state (selectedRowIdxs) to guarantee re-render will be done by REACT
            }

            // Handle "shift key down" mode
            if (refSpecialKeys.current.isShiftKeyDown) {
              // NTH_SKE: verify if this is the expected behavior
              let min = Math.min(oldSelected, p.rowIdx);
              let max = Math.max(oldSelected, p.rowIdx);
              while (min++ < max) {
                let rowID = sortedRows[min].id;
                selected.add(rowID);
              }
              selected.add(row.id);

              // Handle "control key down" mode: toggle selection corresponding row
            } else if (refSpecialKeys.current.isCtrlKeyDown) {
              if (!selected.has(row.id)) {
                selected.add(row.id);
              } else if (selected.size > 1) {
                selected.delete(row.id);
              }

              // Handle "normal" mode: select corresponing row
            } else {
              selected.add(row.id);
            }
          }
          // if (!refSpecialKeys.current.isShiftKeyDown && !refSpecialKeys.current.isCtrlKeyDown) {
          //   selected = new Set();
          // } else if (selected.size === 0) {
          //   selected = new Set(); // special case: use <SHIFT> + <TAB> to move focus from outside the table into "fresh" loaded table ==> need new reference in state (selectedRowIdxs) to guarantee re-render will be done by REACT
          // }
          //
          // if (refSpecialKeys.current.isShiftKeyDown) {
          //   let min = Math.min(oldSelected, p.rowIdx);
          //   let max = Math.max(oldSelected, p.rowIdx);
          //   while (min++ < max) {
          //     let rowID = sortedRows[min].id;
          //     selected.add(rowID);
          //   }
          // }
          // selected.add(row.id);
          setSelectedRowIdxs(selected);

          // Set aperio parameters (if appropriate)
          // ======================================
          if (currentRowChanged) refSubmitAperioEventCommand?.current?.(row, columns, windowData);

          // ...
          // ===
          let attachmentConfig = (attachments?.length || 0) > 0;
          if (attachmentConfig) {
            const dataSeparator = '@@££$$';
            let attachmentKeys: Record<string, string> = {};
            let cat = attachments?.filter((x: any) => x.$.key.startsWith(id));
            if (!!cat && cat.length > 0) {
              if (row) {
                cat.forEach((c: any) => {
                  const colKeys = c.$.key.split(',').slice(1);
                  if (colKeys.length === 1) {
                    const colKey = colKeys[0];
                    attachmentKeys[colKey] = (row[colKey] || '').trim();
                  } else if (colKeys.length > 1) {
                    const keyName = colKeys.join(',');
                    const values = colKeys.map((colKey: string) => (row[colKey] || '').trim());
                    attachmentKeys[keyName] = values.join(dataSeparator);
                  }
                });
              }
              setTimeout(() => updateContext({ attachmentKeys: attachmentKeys }), 500);
            }
          }
        }}
        onScroll={(e) => {
          let target = e.target as HTMLDivElement;
          if (
            target.scrollTop + target.clientHeight >= target.scrollHeight - basicRowHeight &&
            target.clientHeight !== target.scrollHeight && // Remark: may look strange as this condition indicates there is no scrollbar, so one could expect the scroll event is not possible in this case. However there seem to be some edge cases when the control is rendered while not vidible (eg scrollbar on non active tab)
            !isPageDownRequested.current &&
            !filters.enabled &&
            !isBottomReached
          ) {
            setIsBottomReached(true); //Remark: if loadmore does not trigger server call value will remain true, in the other case its value will be re-evaluated in useEffect

            // Reset always lastRows on server pagedown (prevent scrolling EUI-255)
            if (lastRows?.[formID]?.[panelID]) {
              delete lastRows[formID][panelID];
            }

            isPageDownRequested.current = loadMore?.(panelID) || false;
          }
        }}
        onSortColumnsChange={(val) => {
          setSortColumns(val);
          setTableSortBy?.({
            windowID: windowID,
            panelID: panelID,
            formID: formID,
            tableID: id,
            sortBy: val,
          });
        }}
        onColumnResize={(idx: number, width: number) => {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(
            () =>
              setColumnResize?.({
                formID: formID,
                panelID: panelID,
                tableID: id,
                toggleID: toggleID,
                column: _columns[idx].key,
                width: width,
              }),
            500,
          );
        }}
        emptyRowsRenderer={() => (
          <div id={'no-data-id'} data-event={'table'} style={{ textAlign: 'center', padding: '100px' }} tabIndex={-1}>
            {isBottomReached && (
              <h3 className={'empty-table-text'}>{Localization.instance.getString('TABLE_NoDataToShow')}</h3>
            )}
          </div>
        )}
      />
    </TableContext.Provider>
  );

  const loadAdditionalPageBeforeImport = (): boolean => {
    // --> Validate result of previous pagedown request (if appropriate)
    if (importState.pagedownExecuted) {
      // ... preious pagedown request should not insert records before start row
      const selectedRowIndex = sortedRows.findIndex((row: any) => importState.selectedRowId === row.id);
      if (selectedRowIndex < 0) {
        displayWarning(
          Localization.instance.getString(
            'TABLE_IMPORT_FAILED_Unexpected_error_while_adding_extra_rows__start_row_missing',
          ),
        );
        setImportState(createInitialImportState());
        return true;
      } else if (selectedRowIndex > importState.selectedRowIndex) {
        displayWarning(
          Localization.instance.getString(
            'TABLE_IMPORT_FAILED_Current_sorting_causes_adding_extra_rows_before_start_row__adjust_sorting_columns',
          ),
        );
        setImportState(createInitialImportState());
        return true;
      }

      // ... previous pagedown request should have added rows (avoid infinite kloop)
      if (sortedRows.length <= importState.prevNumberOfSortedRows) {
        if (filteredRows.length < rows.length) {
          displayWarning(
            Localization.instance.getString(
              'TABLE_IMPORT_FAILED_Unable_to_add_the_needed_additional_rows__adjust_or_disable_filtering_on_columns',
            ),
          );
          setImportState(createInitialImportState());
          return true;
        } else {
          displayWarning(
            Localization.instance.getString(
              'TABLE_IMPORT_FAILED_Unable_to_add_the_needed_additional_rows__loading_error_occured_or_end_of_file_reached',
            ),
          );
          setImportState(createInitialImportState());
          return true;
        }
      }
    }

    // --> No additional rows needed if enough rows available after the selected row (including selected one)
    if (sortedRows.length - importState.selectedRowIndex >= importState.textData.length) return false;

    // --> Table must support pagedown
    if (!loadMore) {
      displayWarning(
        Localization.instance.getString(
          'TABLE_IMPORT_FAILED_Number_of_rows_to_be_imported_exceeds_number_of_available_rows__and_current_table_does_not_support_pagedown',
        ),
      );
      setImportState(createInitialImportState());
      return true;
    }

    // --> Table should not be already at end of file
    if (isBottomReached) {
      displayWarning(
        Localization.instance.getString(
          'TABLE_IMPORT_FAILED_Number_of_rows_to_be_imported_exceeds_maximum_number_of_available_rows__end_of_file_condition_reached',
        ),
      );
      setImportState(createInitialImportState());
      return true;
    }

    // --> Prevent two pagedown requests
    if (isPageDownRequested.current) return true;

    // --> Submit pagedown request
    setIsBottomReached(true); //Remark: if loadmore does not trigger server call value will remain true, in the other case its value will be re-evaluated in useEffect
    isPageDownRequested.current = loadMore(panelID) || false;

    // --> Table should not be already at end of file (bis)
    if (!isPageDownRequested.current) {
      displayWarning(
        Localization.instance.getString(
          'TABLE_IMPORT_FAILED_Number_of_rows_to_be_imported_exceeds_maximum_number_of_available_rows__end_of_file_condition_reached_or_pagedown_not_supported',
        ),
      );
      setImportState(createInitialImportState());
      return true;
    }

    // Adjust import state
    setImportState({ ...importState, status: 'LOADING_EXTRA_PAGES', prevNumberOfSortedRows: sortedRows.length });
    return true;
  };
  /*
   * import function to start import process
   * */
  const startImport = () => {
    const _rows = [...rows];
    const startRowIndex = sortedRows.findIndex((row: any) => importState.selectedRowId === row.id);
    const startColumnIndex = importState.allowedColumns.findIndex((col) => importState.selectedColumnId === col.id);

    if (startRowIndex > -1 && startColumnIndex > -1) {
      refChangedTableRows.current = [...refChangedTableRows.current];

      // --> Loop through all rows
      for (let i = startRowIndex, j = 0; i < sortedRows.length && j < importState.textData.length; i++, j++) {
        // Copy row (before update)                                       Remark: NEVER change state (immutable!)
        const rowIndex = _rows.findIndex((row: any) => row.id === sortedRows[i].id);
        const row = { ..._rows[rowIndex] };

        // Get import column values to be imported
        const importColumnsData = importState.textData[j];

        // Loop through all allowed columns
        for (
          let l = startColumnIndex, m = 0;
          l < importState.allowedColumns.length && m < importColumnsData.length;
          l++
        ) {
          // ... get corresponding column definition
          const col = columns.find((c: Column) => c.id === importState.allowedColumns[l].id);
          if (!col) {
            // Program protection only: not really expected
            m++;
            continue;
          }

          // ... keep original value if column currently is disabled by a disable constraint
          if (col.disableconstraints) {
            const dConstraint = new Constraint(col.disableconstraints);
            if (dConstraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], false)) {
              m++;
              continue;
            }
          }

          // ... determine server formatted value (==> "value")
          const _value = importColumnsData[m].replace(/'\r'/g, '');
          let value = _value;
          let contentType = col.contenttype || col.mask;
          if (col.formatter?.toLowerCase() === 'time') {
            contentType = 'time';
          }
          if (col.formatter === 'RemoveTrailingDots') {
            value = new RemoveTrailingDotsFormatter().in(value);
          }
          if (col.mask === 'integer') {
            contentType = 'integer';
          }

          if (contentType === 'numeric') {
            const xtString = new XTString(_value);
            let decimalSeparator = '.'; //Assuming imported csv always has . as decimal
            let groupSeparator = ',';
            if (importState.importOption === ImportOptions.CLIPBOARD) {
              decimalSeparator = sourceFormattingInfo.decimalSeparator;
              groupSeparator = sourceFormattingInfo.groupSeparator;
            }
            const num = xtString.clientFormatToNumber(decimalSeparator, groupSeparator);
            value = String(
              num.toXTStringServerFormat(getServerDecimalSeparator(col), true, true, true, +(col.limit || '0'), ''),
            );
          }

          if (contentType === 'date' && !!_value) {
            if (importState.importOption === ImportOptions.CLIPBOARD) {
              try {
                //Try formatting the parsed value to server format
                const { serverValue } = Formatters.Date.parseValueFromClientFormatToServerFormat(
                  _value,
                  sourceFormattingInfo.dateFormat,
                  settings?.regionals?.dateformats[0].$,
                );
                value = serverValue;
              } catch (e) {
                //Worst case scenario (Value cannot be parsed or formatted with server value) then do not import dates
                value = '';
              }
            } else {
              const date = new Date(_value); //Try to parse date if a standard format is provided
              //If date is parsed then format date for selected dateFormat according to localization else try to use value as is
              // As is value assumes date string is already formatted as per localization
              const formatted = !isNaN(date.getUTCMilliseconds()) ? format(date, localization.dateFormat) : _value;
              try {
                //Try formatting the parsed value to server format
                const { serverValue } = Formatters.Date.parseValueFromClientFormatToServerFormat(
                  formatted,
                  localization.dateFormat,
                  settings?.regionals?.dateformats[0].$,
                );
                value = serverValue;
              } catch (e) {
                //Worst case scenario (Value cannot be parsed or formatted with server value) then do not import dates
                value = '';
              }
            }
          }

          if (col.uppercase !== 'false') {
            value = value.toUpperCase();
          }

          // ... update (copied) row value
          if (value.trim() !== '') {
            row[col.id] = value.trimEnd(); // remark: Record<string, string>
            addToRowCommand(panelID, `${row.id}`, col.id, value, -1);
          }

          m++;
        }

        // update corresponding row in (copied) rows
        _rows[rowIndex] = row;
        refChangedTableRows.current.push(row);
      }
    }
    setRows(_rows);
    setImportedRows(refChangedTableRows.current);
    /*
      BEWARE: setImportedRows(refChangedTableRows.current);  is a work around for a bug (?) in component Checkbox, that does not cover
      the change of property defaultValue !! The defaultValue is only used to initialize the state on mounting. the use of "importedRows"
      as dependancy on the _columns (usememo) forces the selectFormatter to be a "new" function after importing. As a consequence it creates
      each time new instances for all conttrols (including checkbox)

      NTH: real fix and completely remove usage of importedRows and setImportedRows
      HTH: FIX BUG, if boolean columns are uses it is NOT working today (see command onn interact: sending 'TRU'E instead of 'Y')
    */

    setImportState(createInitialImportState());
  };
  /*
   * Display model to select starting column while import data
   * */
  const importModal = () => {
    return (
      <>
        {importState.status === 'REQUESTED' && (
          <div>
            <Modal
              show={importState.status === 'REQUESTED'}
              onHide={() => setImportState(createInitialImportState())}
              restoreFocus={false}
              onEntered={() => importDropdownRef.current?.focus()}
              size="lg"
              backdrop="static"
              keyboard={true}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="import-modal"
              onEscapeKeyDown={() => setImportState(createInitialImportState())}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>{Localization.instance.getString('TABLE_IMPORT_SelectColumnNumber')}</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ overflow: 'visible' }}>
                <p className="import-modal-title">
                  <strong>{Localization.instance.getString('TABLE_IMPORT_StartPastingInColumnNumber') + ':'}</strong>
                </p>
                <Dropdown
                  show={IsImportDropdownOpen}
                  id={'import-dropdown'}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key.toLowerCase() === 'enter') {
                      onStartImportRequested();
                    }
                  }}
                  onToggle={(isOpen) => setIsImportDropdownOpen(isOpen)}
                >
                  <Dropdown.Toggle
                    ref={importDropdownRef}
                    className={`combo-text-style combo-text-font dropdown-box full-width import-dropdown`}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (
                        e.key.toLowerCase() === 'escape' &&
                        importDropdownRef.current?.closest('#import-dropdown')?.classList.contains('show')
                      ) {
                        e.stopPropagation();
                      } else if (e.shiftKey && e.key.toLowerCase() === 'tab') {
                        importModalCancelButtonRef.current?.focus();
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    <span className={'selected-item'}>{`${
                      importState.allowedColumns.find((col) => importState.selectedColumnId === col.id)?.header || ''
                    }`}</span>
                    <DropdownArrow className={'combo-icon-image-import'} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key.toLowerCase() === 'escape') {
                        e.stopPropagation();
                      }
                    }}
                  >
                    {importState.allowedColumns.map((col) => (
                      <Dropdown.Item
                        data-event="ignore"
                        className={'dropdown-list-item'}
                        key={col.id}
                        value={col.id}
                        onClick={() => {
                          setImportState({ ...importState, selectedColumnId: col.id });
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key.toLowerCase() === 'enter') {
                            setImportState({ ...importState, selectedColumnId: col.id });
                            e.stopPropagation();
                          }
                        }}
                      >
                        <span>{`${col.header}`}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {importState.importOption === ImportOptions.CLIPBOARD && renderSourceFormattingOptions()}
              </Modal.Body>
              <Modal.Footer className="import-modal-footer">
                <button
                  data-event="ignore"
                  onClick={() => {
                    onStartImportRequested();
                  }}
                  className="truncate btn-block btn btn-outline-dark btn-lg import-btn"
                  disabled={
                    importState.importOption === ImportOptions.CLIPBOARD &&
                    sourceFormattingInfo.decimalSeparator === sourceFormattingInfo.groupSeparator
                  }
                >
                  {Localization.instance.getString('TABLE_IMPORT_BUTTON_Ok')}
                </button>
                <button
                  ref={importModalCancelButtonRef}
                  data-event="ignore"
                  onClick={() => setImportState(createInitialImportState())}
                  className="truncate btn-block btn btn-outline-dark btn-lg import-btn"
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() === 'tab' && !e.shiftKey) {
                      importDropdownRef.current?.focus();
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  {Localization.instance.getString('TABLE_IMPORT_BUTTON_Cancel')}
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </>
    );
  };

  const [loading, setLoading] = useState(false);
  const determineArrowNavigationAllowed = (): boolean => {
    if (sortedRows.length === 0) {
      return true;
    } else if (hasFocus) {
      return false; // Remark: once a table has focus, default behavior for up/down arrow navigation expected (navigation from cell to cell)
    }
    return true;
  };
  const exportToClipCtrl = () => {
    let curEl = document.querySelector('.window.active [aria-selected="true"][role="gridcell"]');
    return curEl?.textContent;
  };
  const getContextMenuId = () => {
    /*
      Remark: react-contextmenu expects a contextmenu id to be unique (as needs unambiguous link between ContextMenuTrigger & ContextMenu)
      To accomplish uniqueness we will include in the build of the id:
        1. windowID: is a unique identifier per window (program tab)
        2. A single window still can contain two tables (and as such two context menus): table directly on the window itself but
           possibly also a second one in a modal window (eg press F4 on currency positioner in work with exchange rates program)
           To guarantee a unique id we will also include:
            a) formId: ISeries does not support recursive calls, I expect this one to be unique within a single window
            b) panelId: to cover the case where a modal prompt is defined within the same form  (in ISeries the panelId within a
               form is guaranteed to be unique)
    */
    return `grid-context-menu-${windowID}-${formID || ''}-${panelID || ''}`;
  };
  const timeRef = useRef<ReturnType<typeof setTimeout>>();

  const contextMenuCheck = () => {
    if (attributes.header === 'false') {
      return true;
    } else if (contextOptions[0].length === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {attributes.header === 'false' ? null : (
        <ButtonGroup className={'table-menu-bar'}>
          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-menu-${id}`}
            title={Localization.instance.getString('TABLE_TableSettings')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            <Dropdown.Item data-event="ignore" as="button" onClick={() => setIsShown(true)}>
              {Localization.instance.getString('TABLE_EditColumns')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => toggleTooltip?.({ panelID: panelID, formID: formID, tableID: id })}
            >
              {isTableTooltipEnabled
                ? Localization.instance.getString('TABLE_HideTooltip')
                : Localization.instance.getString('TABLE_ShowTooltip')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => setFilters({ ...filters, enabled: !filters.enabled })}
            >
              {filters.enabled
                ? Localization.instance.getString('TABLE_HideFilters')
                : Localization.instance.getString('TABLE_ShowFilters')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() =>
                resetTableColumnOrder?.({
                  panelID: panelID,
                  formID: formID,
                  tableID: id,
                  toggleID: toggleID,
                })
              }
            >
              {Localization.instance.getString('TABLE_RestoreColumnOrder')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                resetTableColumnWidths?.({
                  panelID: panelID,
                  formID: formID,
                  tableID: id,
                  toggleID: toggleID,
                });
                setLoading(true);
                setTimeout(() => setLoading(false), 100);
              }}
            >
              {Localization.instance.getString('TABLE_RestoreColumnWidths')}
            </Dropdown.Item>
          </DropdownButton>
          {isEditableTable && (
            <DropdownButton
              className="table-menu"
              as={ButtonGroup}
              id={`table-import-data-${id}`}
              title={Localization.instance.getString('TABLE_IMPORT_ImportData')}
              variant="outline-dark"
              data-event={'ignore'}
            >
              {window.isSecureContext && (
                <Dropdown.Item
                  data-event="ignore"
                  as="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    importFrom(ImportOptions.CLIPBOARD);
                  }}
                >
                  {Localization.instance.getString('TABLE_IMPORT_FromClipboard')}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                data-event="ignore"
                as="button"
                onClick={(e) => {
                  e.stopPropagation();
                  importFrom(ImportOptions.FILE);
                }}
              >
                {Localization.instance.getString('TABLE_IMPORT_FromFile')}
              </Dropdown.Item>
            </DropdownButton>
          )}
          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-export-csv-${id}`}
            title={Localization.instance.getString('TABLE_EXPORT_ExportToCSV')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToCsv(
                  _columns,
                  generateRows_ISO(
                    columns,
                    sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                    decimalsign,
                    settings?.regionals.dateformats[0].$,
                    yes,
                    no,
                  ),
                  `${formID}-${id}.csv`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToCsv(
                  _columns,
                  generateRows_ISO(columns, rows, decimalsign, settings?.regionals.dateformats[0].$, yes, no),
                  `${formID}-${id}.csv`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_AllRows')}
            </Dropdown.Item>
            <Dropdown.Item
              hidden={attributes.autopagedown === 'false' || !loadMore}
              data-event="ignore"
              as="button"
              onClick={() => {
                loadMore?.(
                  panelID,
                  true,
                  columns.filter((col) => {
                    return _columns.find((_col) => {
                      return col.id === _col.id;
                    });
                  }),
                  rows,
                  {
                    ...attributes,
                    exportType: exportType.csv,
                    decimalsign,
                    dateformats: settings?.regionals.dateformats[0].$,
                    yes,
                    no,
                    clientFormattingSettings: {
                      dateFormat: localization.dateFormat,
                      decimalSeparator: localization.decimalSeparator,
                      groupSeparator: localization.groupSeparator,
                    },
                  },
                  async (data: Record<string, any>) => {
                    return exportToCsv(
                      _columns,
                      generateRows_ISO(
                        columns,
                        [...(data.rows || [])],
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                        yes,
                        no,
                      ),
                      `${formID}-${id}-eof.csv`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  },
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-export-excel-${id}`}
            title={Localization.instance.getString('TABLE_EXPORT_ExportToExcel')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToXlsx(
                  _columns,
                  generateRows_XLSX(
                    columns,
                    sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                    decimalsign,
                    settings?.regionals.dateformats[0].$,
                  ),
                  `${formID}-${id}.xlsx`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToXlsx(
                  _columns,
                  generateRows_XLSX(columns, rows, decimalsign, settings?.regionals.dateformats[0].$),
                  `${formID}-${id}.xlsx`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_AllRows')}
            </Dropdown.Item>

            <Dropdown.Item
              hidden={attributes.autopagedown === 'false' || !loadMore}
              data-event="ignore"
              as="button"
              onClick={() =>
                loadMore?.(
                  panelID,
                  true,
                  columns.filter((col) => {
                    return _columns.find((_col) => {
                      return col.id === _col.id;
                    });
                  }),
                  rows,
                  {
                    ...attributes,
                    exportType: exportType.xlsx,
                    decimalsign,
                    dateformats: settings?.regionals.dateformats[0].$,
                    yes,
                    no,
                    clientFormattingSettings: {
                      dateFormat: localization.dateFormat,
                      decimalSeparator: localization.decimalSeparator,
                      groupSeparator: localization.groupSeparator,
                    },
                  },
                  async (data: Record<string, any>) => {
                    return exportToXlsx(
                      _columns,
                      generateRows_XLSX(
                        columns,
                        [...(data.rows || [])],
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                      ),
                      `${formID}-${id}.xlsx`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  },
                )
              }
            >
              {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-export-clip-${id}`}
            title={Localization.instance.getString('TABLE_EXPORT_ExportToClipboard')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                if (window.isSecureContext) {
                  exportToClip(
                    _columns,
                    generateRows_ISO(
                      columns,
                      sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                      decimalsign,
                      settings?.regionals.dateformats[0].$,
                      yes,
                      no,
                    ),
                    tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                  );
                } else {
                  {
                    displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                  }
                }
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                if (window.isSecureContext) {
                  exportToClip(
                    _columns,
                    generateRows_ISO(columns, rows, decimalsign, settings?.regionals.dateformats[0].$, yes, no),
                    tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                  );
                } else {
                  {
                    displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                  }
                }
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_AllRows')}
            </Dropdown.Item>
            <Dropdown.Item
              hidden={attributes.autopagedown === 'false' || !loadMore}
              data-event="ignore"
              as="button"
              onClick={() => {
                if (window.isSecureContext) {
                  loadMore?.(
                    panelID,
                    true,
                    columns.filter((col) => {
                      return _columns.find((_col) => {
                        return col.id === _col.id;
                      });
                    }),
                    rows,
                    {
                      ...attributes,
                      exportType: exportType.clipboard,
                      decimalsign,
                      dateformats: settings?.regionals.dateformats[0].$,
                      yes,
                      no,
                      clientFormattingSettings: {
                        dateFormat: localization.dateFormat,
                        decimalSeparator: localization.decimalSeparator,
                        groupSeparator: localization.groupSeparator,
                      },
                    },
                    async (data: Record<string, any>) => {
                      return exportToClip(
                        _columns,
                        generateRows_ISO(
                          columns,
                          [...(data.rows || [])],
                          decimalsign,
                          settings?.regionals.dateformats[0].$,
                          yes,
                          no,
                        ),
                        tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                      );
                    },
                  );
                } else {
                  {
                    displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                  }
                }
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-export-pdf-${id}`}
            title={Localization.instance.getString('TABLE_EXPORT_ExportToPDF')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToPdf(
                  _columns,
                  generateRows_ClientFormat(
                    // NTH_SKE: allow user to specify to formatting options (through modal form)
                    columns,
                    sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                    decimalsign,
                    settings?.regionals.dateformats[0].$,
                    yes,
                    no,
                    {
                      dateFormat: Localization.instance.dateFormat,
                      decimalSeparator: Localization.instance.decimalSeparator,
                      groupSeparator: Localization.instance.groupSeparator,
                    },
                  ),
                  `${formID}-${id}.pdf`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
            </Dropdown.Item>
            <Dropdown.Item
              data-event="ignore"
              as="button"
              onClick={() => {
                exportToPdf(
                  _columns,
                  generateRows_ClientFormat(columns, rows, decimalsign, settings?.regionals.dateformats[0].$, yes, no, {
                    dateFormat: Localization.instance.dateFormat,
                    decimalSeparator: Localization.instance.decimalSeparator,
                    groupSeparator: Localization.instance.groupSeparator,
                  }),
                  `${formID}-${id}.pdf`,
                  tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                );
              }}
            >
              {Localization.instance.getString('TABLE_EXPORT_AllRows')}
            </Dropdown.Item>
            <Dropdown.Item
              hidden={attributes.autopagedown === 'false' || !loadMore}
              data-event="ignore"
              as="button"
              onClick={() =>
                loadMore?.(
                  panelID,
                  true,
                  columns.filter((col) => {
                    return _columns.find((_col) => {
                      return col.id === _col.id;
                    });
                  }),
                  rows,
                  {
                    ...attributes,
                    exportType: exportType.pdf,
                    decimalsign,
                    dateformats: settings?.regionals.dateformats[0].$,
                    yes,
                    no,
                    clientFormattingSettings: {
                      dateFormat: localization.dateFormat,
                      decimalSeparator: localization.decimalSeparator,
                      groupSeparator: localization.groupSeparator,
                    },
                  },
                  async (data: Record<string, any>) => {
                    return exportToPdf(
                      _columns,
                      generateRows_ClientFormat(
                        columns,
                        [...(data.rows || [])],
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                        yes,
                        no,
                        {
                          dateFormat: Localization.instance.dateFormat,
                          decimalSeparator: Localization.instance.decimalSeparator,
                          groupSeparator: Localization.instance.groupSeparator,
                        },
                      ),
                      `${formID}-${id}-eof.pdf`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  },
                )
              }
            >
              {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
            </Dropdown.Item>
          </DropdownButton>

          <DropdownButton
            className="table-menu"
            as={ButtonGroup}
            id={`table-default-action-${id}`}
            title={Localization.instance.getString('TABLE_DefaultAction')}
            variant="outline-dark"
            data-event={'ignore'}
          >
            {contextOptions[0]
              .filter((x: any) => x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER')
              .map((option: ContextOption) => {
                return (
                  <Dropdown.Item
                    data-event="ignore"
                    as="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDefaultAction?.({
                        formID: formID,
                        panelID: panelID,
                        tableID: id,
                        defaultAction: option.value,
                      });
                    }}
                  >
                    <span style={{ position: 'absolute', right: '4px' }}>
                      {(!selectedDefaultAction.current && option.isDefault) ||
                      selectedDefaultAction.current === option.value ? (
                        <SquareIcon size={'16px'}>{Icons.Check}</SquareIcon>
                      ) : (
                        ''
                      )}
                    </span>
                    {option.label}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
        </ButtonGroup>
      )}
      <ColumnVisibility
        hCols={tableHiddenColumns}
        isShown={isShown}
        hide={() => setIsShown(false)}
        modalTitle={Localization.instance.getString('TABLE_EditColumnVisibility')}
        columnsSettings={settingsColumns.map((columnSetting) => {
          return {
            id: columnSetting.id,
            name: columnSetting.name,
            hide: columnSetting.hide,
            noHeader: columnSetting.noHeader === undefined ? false : columnSetting.noHeader,
          };
        })}
        tooltipSequence={tableTooltipSequence}
        setHCols={(hcols: any) => {
          setTableHiddenColumns?.({
            formID: formID,
            panelID: panelID,
            tableID: id,
            toggleID: toggleID,
            hCols: hcols,
          });
        }}
        setTooltipSequence={(tooltipSequence) =>
          setTooltipSequence?.({
            formID: formID,
            panelID: panelID,
            tableID: id,
            tooltipSequence,
          })
        }
      />
      <ReactTooltip disable={!isTableTooltipEnabled} html textColor="#2f3c46" backgroundColor="#d0eeee" />

      {/*<ContextMenuTrigger id={getContextMenuId()} collect={() => ({ rowIdx: 0 })} holdToDisplay={1000} mouseButton={2} disable={!contextMenuCheck()}>*/}
      <ContextMenuTrigger
        // Due to error in ContextMenu library (stopPropagation on event which shouldn't be stopped when holdToDisplay is enabled (=1000)) we disabled
        // that functionality and as it is needed, we handle it ourself in onTouchStart / onTouchEnd events
        id={getContextMenuId()}
        attributes={{ className: attributes.header === 'false' ? 'no-table-menu' : '' }}
        collect={() => ({ rowIdx: 0 })}
        disable={!contextMenuCheck()}
        mouseButton={2}
        holdToDisplay={-1} // disabled and moved to onTouchStart event
      >
        <span
          onKeyDown={(e) => {
            // when contextMenu is opened then other than its key events ['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'],
            // so e.g. pageDown / pageUp should be disabled
            const contextMenuOpened = document.getElementsByClassName('react-contextmenu--visible')?.length > 0;
            const selectedAndNotDisabledMenuItem =
              document.querySelectorAll('.react-contextmenu-item--selected:not(.react-contextmenu-item--disabled)')
                ?.length > 0;
            if (
              (contextMenuOpened &&
                !['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'].includes(
                  e.key.toLowerCase(),
                )) ||
              (contextMenuOpened && !selectedAndNotDisabledMenuItem && e.key.toLowerCase() === 'enter')
            ) {
              e.stopPropagation();
              e.preventDefault();
              return;
            }
            if (e.key.toLowerCase().includes('delete') && !isEditableTable) {
              const deleteAction = defaultActions?.find((x) => x.event.toLowerCase() === 'delete');
              if (deleteAction) {
                const contextOption = contextOptions[0].find(
                  (x: ContextOption) => x.action?.option === deleteAction.option,
                );
                if (contextOption && !shouldContextMenuBeDisabled(contextOption)) {
                  handleContextOption(contextOption);
                }
              }
            }
            const autocompleteOpen = document.getElementById('autocomplete-input-list');
            if (autocompleteOpen) return false;
            if (e.code.toLowerCase().indexOf('enter') > -1 && selectedRowIdxs.size > 0) {
              let diff = false;
              const command = getCommand();
              if (command && Object.values(command.children?.[panelID]?.children || {}).length > 0) {
                diff = !!rows.find((r: any) => !!command.children[panelID].children[r.id]);
              }
              //If editable cell is currently focused stop default action
              if (tableRef?.current?.element?.querySelector('.rdg-editor-container')) return true;
              if (diff) return true;

              let selected = sortedRows.filter(
                (row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1 && row.selectable,
              );
              let defaultAction = contextOptions[0].find(
                (x: ContextOption) => x.isDefault && (x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER'),
              );
              if (selectedDefaultAction.current) {
                defaultAction =
                  contextOptions[0].find(
                    (x: ContextOption) =>
                      x.value === selectedDefaultAction.current &&
                      (x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER'),
                  ) || defaultAction;
              }
              let abstractDefaultAction = defaultActions?.find((ac: Action) => {
                let con = new Constraint(ac.disableconstraints || '');
                let flags = selected[0]?.flags?.split('').map((x: string) => x === '1') || [];
                if (!con.evaluate(flags, false) && ac.event === 'DEFAULT') {
                  return true;
                }
                return false;
              });
              if (abstractDefaultAction && attributes.rowfield)
                abstractDefaultAction.field = abstractDefaultAction.field || attributes.rowfield;
              let tempAction: ContextOption = {
                action: abstractDefaultAction,
                isDefault: true,
                id: defaultAction?.id || '',
                label: 'DEFAULT',
                key: attributes.selectionColumn || '',
                type: 'option',
                value: abstractDefaultAction?.option || defaultAction?.value || '',
              };

              if (tempAction.value && !selectedDefaultAction.current && selected) {
                if (attributes.fullselect === 'true' || formID === 'SpooledFileQueueSelection') {
                  selected.forEach((row: any) => {
                    let rowID = row.id;
                    addToRowCommand(panelID, rowID, tempAction?.key, tempAction?.value, -1);
                    let tempCols = windowData.form.panel
                      .find((p: any) => p.$.id === panelID)
                      .row.find((r: any) => r.$.id === rowID).ctrl;
                    tempCols = tempCols.filter((c: any) => c.$.id !== tempAction?.key);
                    columns.forEach((col: Column) => {
                      let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, col.id) || '';
                      if (col.contenttype === 'time') {
                        let time = Formatters.Time.in(val?.trim() || '', +(col?.limit ?? '0'));
                        val = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
                      }
                      if (tempCols.find((c: any) => c.$.id === col.id))
                        addToRowCommand(panelID, rowID, col.id, val, -1);
                    });
                    tempCols
                      .filter((c: any) => columns.map((c: Column) => c.id).indexOf(c.$.id) === -1)
                      .forEach((ctrl: any) => {
                        addToRowCommand(
                          panelID,
                          rowID,
                          ctrl.$.id,
                          XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, ctrl.$.id),
                          -1,
                        );
                      });
                  });
                }

                onContextMenuClick(
                  panelID,
                  selected.map((row: any) => row.id),
                  tempAction,
                );
                // e.stopPropagation();
                // e.preventDefault();
                // return false;
              } else if (defaultAction && selected) {
                if (attributes.fullselect === 'true' || formID === 'SpooledFileQueueSelection') {
                  selected.forEach((row: any) => {
                    let rowID = row.id;
                    addToRowCommand(panelID, rowID, defaultAction?.key || '', defaultAction?.value || '', -1);
                    let tempCols = windowData.form.panel
                      .find((p: any) => p.$.id === panelID)
                      .row.find((r: any) => r.$.id === rowID).ctrl;
                    tempCols = tempCols.filter((c: any) => c.$.id !== defaultAction?.key);
                    columns.forEach((col: Column) => {
                      let val = XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, col.id) || '';
                      if (col.contenttype === 'time') {
                        let time = Formatters.Time.in(val?.trim() || '', +(col?.limit ?? '0'));
                        val = time ? Formatters.Time.out(time, 'HH:mm:ss') : '';
                      }
                      if (tempCols.find((c: any) => c.$.id === col.id))
                        addToRowCommand(panelID, rowID, col.id, val, -1);
                    });
                    tempCols
                      .filter((c: any) => columns.map((c: Column) => c.id).indexOf(c.$.id) === -1)
                      .forEach((ctrl: any) => {
                        addToRowCommand(
                          panelID,
                          rowID,
                          ctrl.$.id,
                          XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, ctrl.$.id),
                          -1,
                        );
                      });
                  });
                }
                onContextMenuClick(
                  panelID,
                  selected.map((row: any) => row.id),
                  defaultAction,
                );
                // e.stopPropagation();
                // e.preventDefault();
              }
            }
          }}
          onKeyDownCapture={(event: React.KeyboardEvent<HTMLSpanElement>) => {
            /*
                When pressing <TAB> on a not editable table, the focus should be moved outside the
                table to the "next" element within the active window. This behavior is programmed
                in the function onKeyDown in Desktop.tsx.

                One of the problems to be solved is preventing the default behavior of the table
                (selecting the next cell in the table). This is accomplished by subscribing to the
                keydown event when it is still in the capturing cycle. Calling the stopPropagation
                on that event guarantees that the event never reaches the event listener of the table.

                However the same call (stopPropagation) results also in canceling the bubble cycle,
                and as such the function onKeyDown in Desktop.tsx is also not executed anymore.

                To make that function to be called again a new <Tab> keydown event is dispatched.

                Remarks
                  ° the call to preventDefault is included to prevent the browsers default behavior
                    (would select the "next" element of the document opposed to the next element of
                    the active window)
                  ° the condition "event.target !== tableSpanRef.current" is needed to avoid an
                    infinite loop
                  ° focus can be given back to the table by arrow navigation or by pressing <SHIFT+TAB>)
              */
            let target = event.target as HTMLInputElement;
            if (
              event.key.toLowerCase() === 'c' &&
              (event.ctrlKey || event.metaKey) &&
              !target.classList?.contains('editable-cell') &&
              !target.classList?.contains('filter')
            ) {
              let txt = exportToClipCtrl();
              navigator.clipboard.writeText(txt || '').catch((err) => {
                console.error(`Error from onKeyDownCapture${err}`);
              });
              return false;
            }
            if (event.key.toLowerCase() === 'a' && (event.ctrlKey || event.metaKey)) {
              event.preventDefault();
              let rowID = sortedRows.map((row: any) => row.id as string);
              if (rowID) {
                let maxLength = sortedRows.length;
                let i = 0;
                let selected = new Set<string>([rowID[0]]);
                while (i++ < maxLength - 1) {
                  selected.add(rowID[i]);
                }
                setSelectedRowIdxs(selected);
              }
            }
            if (
              event.key === 'Tab' &&
              !isEditableTable &&
              event.target !== tableSpanRef.current &&
              !target.classList?.contains('filter')
            ) {
              event.preventDefault();
              event.stopPropagation();
              tableSpanRef.current?.dispatchEvent(
                new KeyboardEvent('keydown', {
                  code: event.code,
                  key: event.key,
                  keyCode: event.keyCode,
                  charCode: event.charCode,
                  cancelable: true,
                  view: window,
                  bubbles: true,
                  ctrlKey: event.ctrlKey,
                  shiftKey: event.shiftKey,
                  metaKey: event.metaKey,
                  altKey: event.altKey,
                }),
              );
              return false;
            }
          }}
          data-event={'table'}
          data-table-id={id}
          data-selected-col={selection?.idx}
          data-selected-col-id={_columns[selection?.idx]?.id}
          data-selected-val={rows?.[selection?.rowIdx]?.[_columns[selection?.idx]?.id]?.trim()}
          data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
          data-autofocus={refServerFocusRequested.current ? 'true' : 'false'}
          data-pseudo-tabindex={x + y}
          data-table-editable={isEditableTable}
          data-invalid={Object.keys(invalidCells).length > 0}
          tabIndex={-1}
          ref={tableSpanRef}
          className={'data-table-span'}
          onFocus={(e: FocusEvent<HTMLSpanElement>) => {
            // Workaround aperio view
            // ======================
            if (refSubmitAperioEventCommand.current && sortedRows.length > 0) {
              /*
                  When current selected cell is (0, 0) and server refresh is requested (F5), the onSelectedCellChange
                  function is not called when the grid gets focus again (rdg bug). As a workaround we trying to recognize
                  this situation here and submitting the aperio view command for selecting row 0
                */
              if (
                currentSelection.current === 0 &&
                currentColumn.current === 0 &&
                !hasFocus && // needed to prevent code is executed when application becomes the active one again (eg pressing 2 times <alt> + <tab> on Windows machines)
                (e.target as any).directFocus
              ) {
                setTimeout(() => {
                  // Only if conditions stil fulfilled (after the timeout)
                  if (
                    refSubmitAperioEventCommand.current &&
                    sortedRows.length > 0 &&
                    currentSelection.current === 0 &&
                    currentColumn.current === 0 &&
                    document.activeElement?.closest?.('.data-table-span') // cell is actually still the one with focus
                  ) {
                    refSubmitAperioEventCommand.current(sortedRows[0], columns, windowData);
                  }
                }, 10);
              }
            }

            // Adjust state
            // ============
            if (!hasFocus) setHasFocus(true);
            // Redirect focus to table if span gets focus directly   --> e.g. through arrow navigation
            // ===================================================
            if (e.target === tableSpanRef.current) {
              if ((e.target as any).directFocus) {
                // Remove temporary property
                // -------------------------
                delete (e.target as any).directFocus;

                // Get the focus sink element of the table
                // ---------------------------------------
                const sink = e.target.querySelector('.rdg-focus-sink') as HTMLDivElement;

                // Case sink not found      e.g. table without rows has no sink
                // -------------------
                if (!sink) {
                  setTimeout(() => {
                    (e.target.querySelector('#no-data-id') as HTMLDivElement)?.focus();
                  }, 0);
                  /*
                      Remarks:
                        ° without time-out resulting focus went "in some cases" to document body, eg: keep on tabbing ==> after focus last button
                          once ok, keep on tabbing ==> after last button once body, ...
                        ° also tried to give focus to table instead of "no-data-id" element (setTimeout( ()=> {tableRef.current?.element?.focus()}, 0)).
                          Resulted in focus still on the span and this resulted in default browser behavior on pressing function keys, eg F5 => browser
                          message to reload instead of triggering server refresh request
                        ° same akward behavior where resulting focus goes once to the "no-data-id" element and the other time to the body is when you
                          have focus outside the table and click on the "no-data-id" element. eg: click outside grid & click "no-data-id" element ==>
                          resultig focus on "no-data-id" element, then again click outside grid & click "no-data-id" element ==> resultig focus on
                          document body, ... Remark that current code is not the cause of this behavior as inthis case e.target is not the span and
                          as such current code is not executed.
                    */

                  // setTimeout(() => {
                  // }, 200)

                  // Case sink found
                  // ---------------
                } else {
                  setTimeout(() => {
                    if (
                      (document.activeElement !== sink &&
                        tableSpanRef.current &&
                        !tableSpanRef.current.contains(document.activeElement)) ||
                      document.activeElement === tableSpanRef.current
                    ) {
                      //NTH_SKE: THINK!! (infinite loop possible?)
                      // console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                      // console.info('>>>> span gets focus: FORCING SELECT SINK NOW')
                      // console.info('>>>>   doc.active WAS', document.activeElement)
                      // console.info('>>>>')

                      if (isEditableTable && currentSelection.current >= 0 && currentColumn.current >= 0) {
                        tableRef.current?.selectCell(
                          { idx: currentColumn.current, rowIdx: currentSelection.current },
                          true,
                        ); // Remark: if cell not editable, parameter is ignored by rdg component (==> OK)
                      } else {
                        if (!isEditableTable) preventComponentScrolling(20); // 0 not always sufficient (eg close client settings ==> "sometimes scrolling")
                        sink.focus();
                      }

                      //   console.info('>>>>   doc.active IS', document.activeElement)
                      //   setTimeout(() => {
                      //     console.info('>>>>')
                      //     console.info('>>>>   doc.active BECOMES', document.activeElement)
                      //     console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                      //     console.info('')
                      //   }, 200)
                      // } else {
                      //   console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                      //   console.info('>>>> span gets focus: SUBMIT SELECT - NO LONGER NEEDED')
                      //   console.info('>>>>   doc.active WAS', document.activeElement)
                      //   setTimeout(() => {
                      //     console.info('>>>>')
                      //     console.info('>>>>   doc.active BECOMES', document.activeElement)
                      //     console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                      //     console.info('')
                      //   }, 200)
                    }
                  }, 0);
                }
              }
              // } else if (!e.relatedTarget && document.activeElement === e.target) {
              //   if (e.target?.classList?.contains('rdg-focus-sink')) {
              //     e.stopPropagation();
              //     e.preventDefault();
              //   }
            }
          }}
          onBlur={(e: FocusEvent<HTMLSpanElement>) => {
            if (!e.currentTarget.contains(e.relatedTarget) && hasFocus) setHasFocus(false);
          }}
          onTouchStart={(e: React.TouchEvent) => {
            // Event needed for touch screens to open contextMenu on tapAndHold action (tap and hold at least 1s)
            // originally it should be hadled by the ContextMenu component itself, but due to bug in this library it was moved here
            // see also comment in ContextMenuTrigger
            // The idea is to trigger contextmenu event on touch, but with 1s delay
            // if user will hold tapping shorter than 1s, then event will be cancelled in onTouchEnd event
            // if not, then contextmenu event will be processed
            let target = e.currentTarget;
            // handle it only on grid cells or no-data area - so exclude e.g. scrollbars
            if (target.className.includes('data-table-span') || target.closest('.data-table-span')) {
              // timeout needed for editable grid - replaces cell with editable cell on next render
              setTimeout(() => {
                if (document.activeElement && target !== document.activeElement) {
                  target = document.activeElement;
                }
                // only handle if new target still valid element of selected table
                if (target.className.includes('data-table-span') || target.closest('.data-table-span')) {
                  timeRef.current = setTimeout(() => {
                    if (target === document.activeElement) {
                      target?.dispatchEvent(
                        new MouseEvent('contextmenu', {
                          bubbles: true,
                          cancelable: true,
                          view: window,
                          relatedTarget: e.nativeEvent.target,
                          clientX: e.nativeEvent.touches[0].clientX,
                          clientY: e.nativeEvent.touches[0].clientY,
                          button: 2,
                          buttons: 0,
                        }),
                      );
                    }
                  }, 1000);
                }
              }, 0);
            }
          }}
          onTouchEnd={() => {
            // cancel event
            if (timeRef.current) {
              clearTimeout(timeRef.current);
            }
          }}
          onScrollCapture={(e: React.UIEvent) => {
            /*
                The purpose of the freeze scrolling functionality is to keep the scroll position of a table while a user is selecting another 
                window (program) tab and later on comes back.
                
                Issues to be solved:
                  ° When a window is no longer the active window, the scroll top & left are currently affected by css code (they become both zero)
                  ° When current used component (react-data-grid) receives focus, it's default behavior is scrolling the current selected cell into view
                Both changes are captured in the onScroll event

                The base idea is to save the resulting scroll position on every scroll event - except for the unwanted changes - and to restore the last saved 
                position when we come back.
                  ° Previous described first issue defines also the start of the freezing period (changes to scroll positions will not longer be saved starting 
                    from here). It's easy to recognize this case, as it changes the scroll top & left while the table is not longer part of the active window. 
                    Determining the start of the freeze period in this way covers all existing cases except one: the case where scroll top & left are both 
                    already zero (as in this case swithing tabs will not trigger the scroll event). As a back-up the freeze period will also be started when 
                    rendering is triggered while the table is no longer on the active form.
                    Remark: back-up only might not be enough, as the render can be AFTER first described issue. Back-up is only "on time" when swithing tabs had 
                    no impact on the scrolling (but not able to simulate anymore).
                  ° Previous described second issue defines also the end of the freezing period. It can be recognized as the first scrollling while the freezing 
                    is on and when the table is again part of the active window. Determining the end of the freeze period in this way covers all existing 
                    cases except two: the case where the current cell is already in view (so the components default behavior is not triggered) and the case where
                    the table did not have the input focus. As a back-up the freeze period will also be ended when the active window gets input focus while freeze 
                    is on. Remark: the focus event is executed BEFORE the components default behavior. As a result the freezing can only be ended using a timer.
              */

            // Handle freeze scrolling
            // =======================
            if (e.target === tableRef.current?.element) {
              if (!tableRef.current.element.closest('.window.active')) {
                // Case not in the active window: start freeze
                refFreezeScrolling.current.on = true;
              } else if (refFreezeScrolling.current.on) {
                // Case active window and in freeze mode: stop freeze & restore last saved position
                if (
                  tableRef.current.element.scrollTop !== refFreezeScrolling.current.scrollTop ||
                  tableRef.current.element.scrollLeft !== refFreezeScrolling.current.scrollLeft
                ) {
                  // Remark: additional test needed for firefox (you get onScroll event even when no scrolling info yet changed, making the stop freeze to early)
                  refFreezeScrolling.current.on = false;
                  tableRef.current.element.scrollTop = refFreezeScrolling.current.scrollTop;
                  tableRef.current.element.scrollLeft = refFreezeScrolling.current.scrollLeft;
                }
              } else {
                // Case else: save scroll position (unless base componanent scrolling prevented)
                if (!refPreventComponentScrolling.current) {
                  refFreezeScrolling.current.scrollTop = tableRef.current.element.scrollTop;
                  refFreezeScrolling.current.scrollLeft = tableRef.current.element.scrollLeft;
                } else {
                  tableRef.current.element.scrollTop = refFreezeScrolling.current.scrollTop;
                  tableRef.current.element.scrollLeft = refFreezeScrolling.current.scrollLeft;
                }
              }
            }
          }}
        >
          {loading ? <>Resetting...</> : <FilterContext.Provider value={filters}>{gridElement}</FilterContext.Provider>}
        </span>
      </ContextMenuTrigger>

      {importModal()}
      <Loader loading={importState.status === 'CONFIRMED' || importState.status === 'LOADING_EXTRA_PAGES'} />
      {createPortal(
        <ContextMenu
          ref={refContextMenu}
          onShow={contextEventonShow}
          onHide={contextEventonHide}
          id={getContextMenuId()}
          hideOnLeave={false}
        >
          {contextOptions.map((options: ContextOptions) => {
            return options.map((contextOption: ContextOption) => {
              const disabled = shouldContextMenuBeDisabled(contextOption);
              return (
                <MenuItem disabled={disabled} data-event={'ignore'} onClick={() => handleContextOption(contextOption)}>
                  {contextOption.label}
                </MenuItem>
              );
            });
          })}
          {attributes.header === 'false' && (
            <>
              {contextOptions.flat(Infinity).length > 0 && <MenuItem divider />}
              <SubMenu
                title={Localization.instance.getString('TABLE_TableSettings')}
                hoverDelay={0}
                preventCloseOnClick={true}
                data-event={'ignore'}
              >
                <MenuItem data-event="ignore" onClick={() => setIsShown(true)}>
                  {Localization.instance.getString('TABLE_EditColumns')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => toggleTooltip?.({ panelID: panelID, formID: formID, tableID: id })}
                >
                  {isTableTooltipEnabled
                    ? Localization.instance.getString('TABLE_HideTooltip')
                    : Localization.instance.getString('TABLE_ShowTooltip')}
                </MenuItem>
                {/* <MenuItem data-event='ignore' disabled onClick={() => setFilters({ ...filters, enabled: !filters.enabled })}>
                  {filters.enabled ? Localization.instance.getString('TABLE_HideFilters') : Localization.instance.getString('TABLE_ShowFilters')}
                </MenuItem> */}
                <MenuItem
                  data-event="ignore"
                  onClick={() =>
                    resetTableColumnOrder?.({ panelID: panelID, formID: formID, tableID: id, toggleID: toggleID })
                  }
                >
                  {Localization.instance.getString('TABLE_RestoreColumnOrder')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    resetTableColumnWidths?.({ panelID: panelID, formID: formID, tableID: id, toggleID: toggleID });
                    setLoading(true);
                    setTimeout(() => setLoading(false), 100);
                  }}
                >
                  {Localization.instance.getString('TABLE_RestoreColumnWidths')}
                </MenuItem>
              </SubMenu>
              {isEditableTable && (
                <SubMenu
                  title={Localization.instance.getString('TABLE_IMPORT_ImportData')}
                  hoverDelay={0}
                  data-event={'ignore'}
                >
                  {window.isSecureContext && (
                    <MenuItem
                      data-event="ignore"
                      onClick={(e) => {
                        e.stopPropagation();
                        importFrom(ImportOptions.CLIPBOARD);
                      }}
                    >
                      {Localization.instance.getString('TABLE_IMPORT_FromClipboard')}
                    </MenuItem>
                  )}
                  <MenuItem
                    data-event="ignore"
                    onClick={(e) => {
                      e.stopPropagation();
                      importFrom(ImportOptions.FILE);
                    }}
                  >
                    {Localization.instance.getString('TABLE_IMPORT_FromFile')}
                  </MenuItem>
                </SubMenu>
              )}
              <SubMenu
                title={Localization.instance.getString('TABLE_EXPORT_ExportToCSV')}
                hoverDelay={0}
                data-event={'ignore'}
              >
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToCsv(
                      _columns,
                      generateRows_ISO(
                        columns,
                        sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                        yes,
                        no,
                      ),
                      `${formID}-${id}.csv`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToCsv(
                      _columns,
                      generateRows_ISO(columns, rows, decimalsign, settings?.regionals.dateformats[0].$, yes, no),
                      `${formID}-${id}.csv`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_AllRows')}
                </MenuItem>
                {!(attributes.autopagedown === 'false' || !loadMore) && (
                  <MenuItem
                    data-event="ignore"
                    onClick={() => {
                      loadMore?.(
                        panelID,
                        true,
                        columns.filter((col) => {
                          return _columns.find((_col) => {
                            return col.id === _col.id;
                          });
                        }),
                        rows,
                        {
                          ...attributes,
                          exportType: exportType.csv,
                          decimalsign,
                          dateformats: settings?.regionals.dateformats[0].$,
                          yes,
                          no,
                          clientFormattingSettings: {
                            dateFormat: localization.dateFormat,
                            decimalSeparator: localization.decimalSeparator,
                            groupSeparator: localization.groupSeparator,
                          },
                        },
                        async (data: Record<string, any>) => {
                          return exportToCsv(
                            _columns,
                            generateRows_ISO(
                              columns,
                              [...(data.rows || rows || [])],
                              decimalsign,
                              settings?.regionals.dateformats[0].$,
                              yes,
                              no,
                            ),
                            `${formID}-${id}-eof.csv`,
                            tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                          );
                        },
                      );
                    }}
                  >
                    {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
                  </MenuItem>
                )}
              </SubMenu>
              <SubMenu
                title={Localization.instance.getString('TABLE_EXPORT_ExportToExcel')}
                hoverDelay={0}
                data-event={'ignore'}
              >
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToXlsx(
                      _columns,
                      generateRows_XLSX(
                        columns,
                        sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                      ),
                      `${formID}-${id}.xlsx`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToXlsx(
                      _columns,
                      generateRows_XLSX(columns, rows, decimalsign, settings?.regionals.dateformats[0].$),
                      `${formID}-${id}.xlsx`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_AllRows')}
                </MenuItem>
                {!(attributes.autopagedown === 'false' || !loadMore) && (
                  <MenuItem
                    data-event="ignore"
                    onClick={() => {
                      loadMore?.(
                        panelID,
                        true,
                        columns.filter((col) => {
                          return _columns.find((_col) => {
                            return col.id === _col.id;
                          });
                        }),
                        rows,
                        {
                          ...attributes,
                          exportType: exportType.xlsx,
                          decimalsign,
                          dateformats: settings?.regionals.dateformats[0].$,
                          yes,
                          no,
                          clientFormattingSettings: {
                            dateFormat: localization.dateFormat,
                            decimalSeparator: localization.decimalSeparator,
                            groupSeparator: localization.groupSeparator,
                          },
                        },
                        async (data: Record<string, any>) => {
                          return exportToXlsx(
                            _columns,
                            generateRows_XLSX(
                              columns,
                              [...(data.rows || rows || [])],
                              decimalsign,
                              settings?.regionals.dateformats[0].$,
                            ),
                            `${formID}-${id}.xlsx`,
                            tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                          );
                        },
                      );
                    }}
                  >
                    {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
                  </MenuItem>
                )}
              </SubMenu>
              <SubMenu
                title={Localization.instance.getString('TABLE_EXPORT_ExportToClipboard')}
                hoverDelay={0}
                data-event={'ignore'}
              >
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    if (window.isSecureContext) {
                      exportToClip(
                        _columns,
                        generateRows_ISO(
                          columns,
                          sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                          decimalsign,
                          settings?.regionals.dateformats[0].$,
                          yes,
                          no,
                        ),
                        tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                      );
                    } else {
                      {
                        displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                      }
                    }
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    if (window.isSecureContext) {
                      exportToClip(
                        _columns,
                        generateRows_ISO(columns, rows, decimalsign, settings?.regionals.dateformats[0].$, yes, no),
                        tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                      );
                    } else {
                      {
                        displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                      }
                    }
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_AllRows')}
                </MenuItem>
                {!(attributes.autopagedown === 'false' || !loadMore) && (
                  <MenuItem
                    data-event="ignore"
                    onClick={() => {
                      if (window.isSecureContext) {
                        loadMore?.(
                          panelID,
                          true,
                          columns.filter((col) => {
                            return _columns.find((_col) => {
                              return col.id === _col.id;
                            });
                          }),
                          rows,
                          {
                            ...attributes,
                            exportType: exportType.csv,
                            decimalsign,
                            dateformats: settings?.regionals.dateformats[0].$,
                            yes,
                            no,
                            clientFormattingSettings: {
                              dateFormat: localization.dateFormat,
                              decimalSeparator: localization.decimalSeparator,
                              groupSeparator: localization.groupSeparator,
                            },
                          },
                          async (data: Record<string, any>) => {
                            return exportToClip(
                              _columns,
                              generateRows_ISO(
                                columns,
                                [...(data.rows || rows || [])],
                                decimalsign,
                                settings?.regionals.dateformats[0].$,
                                yes,
                                no,
                              ),
                              tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                            );
                          },
                        );
                      } else {
                        {
                          displayWarning(localization.getString('Copy_To_Clipboard_Warning'));
                        }
                      }
                    }}
                  >
                    {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
                  </MenuItem>
                )}
              </SubMenu>
              <SubMenu
                title={Localization.instance.getString('TABLE_EXPORT_ExportToPDF')}
                hoverDelay={0}
                data-event={'ignore'}
              >
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToPdf(
                      _columns,
                      generateRows_ClientFormat(
                        // NTH_SKE: allow user to specify to formatting options (through modal form)
                        columns,
                        sortedRows.filter((row: any) => Array.from(selectedRowIdxs).indexOf(row.id) > -1),
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                        yes,
                        no,
                        {
                          dateFormat: Localization.instance.dateFormat,
                          decimalSeparator: Localization.instance.decimalSeparator,
                          groupSeparator: Localization.instance.groupSeparator,
                        },
                      ),
                      `${formID}-${id}.pdf`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_SelectedRows')}
                </MenuItem>
                <MenuItem
                  data-event="ignore"
                  onClick={() => {
                    exportToPdf(
                      _columns,
                      generateRows_ClientFormat(
                        columns,
                        rows,
                        decimalsign,
                        settings?.regionals.dateformats[0].$,
                        yes,
                        no,
                        {
                          dateFormat: Localization.instance.dateFormat,
                          decimalSeparator: Localization.instance.decimalSeparator,
                          groupSeparator: Localization.instance.groupSeparator,
                        },
                      ),
                      `${formID}-${id}.pdf`,
                      tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                    );
                  }}
                >
                  {Localization.instance.getString('TABLE_EXPORT_AllRows')}
                </MenuItem>
                {!(attributes.autopagedown === 'false' || !loadMore) && (
                  <MenuItem
                    data-event="ignore"
                    onClick={() =>
                      loadMore?.(
                        panelID,
                        true,
                        columns.filter((col) => {
                          return _columns.find((_col) => {
                            return col.id === _col.id;
                          });
                        }),
                        rows,
                        {
                          ...attributes,
                          exportType: exportType.pdf,
                          decimalsign,
                          dateformats: settings?.regionals.dateformats[0].$,
                          yes,
                          no,
                          clientFormattingSettings: {
                            dateFormat: localization.dateFormat,
                            decimalSeparator: localization.decimalSeparator,
                            groupSeparator: localization.groupSeparator,
                          },
                        },
                        async (data: Record<string, any>) => {
                          return exportToPdf(
                            _columns,
                            generateRows_ClientFormat(
                              columns,
                              [...(data.rows || [])],
                              decimalsign,
                              settings?.regionals.dateformats[0].$,
                              yes,
                              no,
                              {
                                dateFormat: Localization.instance.dateFormat,
                                decimalSeparator: Localization.instance.decimalSeparator,
                                groupSeparator: Localization.instance.groupSeparator,
                              },
                            ),
                            `${formID}-${id}-eof.pdf`,
                            tableExportProviderRows(windowLayout, windowData, attributes.exportHeaderProviders),
                          );
                        },
                      )
                    }
                  >
                    {Localization.instance.getString('TABLE_EXPORT_EndOfFile')}
                  </MenuItem>
                )}
              </SubMenu>
              {contextOptions[0].find((x: any) => x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER') && (
                <SubMenu
                  title={Localization.instance.getString('TABLE_DefaultAction')}
                  hoverDelay={0}
                  data-event={'ignore'}
                >
                  {contextOptions[0]
                    .filter((x: any) => x.action?.event === 'DEFAULT' || x.action?.event === 'ENTER')
                    .map((option: ContextOption) => {
                      return (
                        <MenuItem
                          data-event="ignore"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDefaultAction?.({
                              formID: formID,
                              panelID: panelID,
                              tableID: id,
                              defaultAction: option.value,
                            });
                          }}
                        >
                          <span style={{ position: 'absolute', right: '4px' }}>
                            {(!selectedDefaultAction.current && option.isDefault) ||
                            selectedDefaultAction.current === option.value ? (
                              <SquareIcon size={'16px'}>{Icons.Check}</SquareIcon>
                            ) : (
                              ''
                            )}
                          </span>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </SubMenu>
              )}
            </>
          )}
        </ContextMenu>,
        document.body,
      )}
    </>
  );

  function renderSourceFormattingOptions() {
    return (
      <Container className="source-formatting">
        <BTRow>
          <BTCol className="col-label">
            <strong>{`${Localization.instance.getString('TABLE_IMPORT_SourceFormatting')}:`}</strong>
          </BTCol>
        </BTRow>
        <BTRow>
          <BTCol className="col-label">{`${Localization.instance.getString('SETTINGS_DateFormat')}:`}</BTCol>
          <BTCol>
            <SettingsCombo
              options={localeFormattingOptions.date}
              defaultValue={{ value: sourceFormattingInfo.dateFormat, label: '' }}
              translateOptionLabels={false}
              onChangeOption={(option: Option) =>
                setSourceFormattingInfo({ ...sourceFormattingInfo, dateFormat: option.value })
              }
            />
          </BTCol>
        </BTRow>
        <BTRow>
          <BTCol className="col-label">{`${Localization.instance.getString('SETTINGS_DecimalSeparator')}:`}</BTCol>
          <BTCol>
            <SettingsCombo
              options={localeFormattingOptions.decimalSeparator}
              defaultValue={{ value: sourceFormattingInfo.decimalSeparator, label: '' }}
              translateOptionLabels={false}
              onChangeOption={(option: Option) =>
                setSourceFormattingInfo({ ...sourceFormattingInfo, decimalSeparator: option.value })
              }
            />
          </BTCol>
        </BTRow>
        <BTRow>
          <BTCol className="col-label">{`${Localization.instance.getString('SETTINGS_GroupSeparator')}:`}</BTCol>
          <BTCol>
            <SettingsCombo
              options={localeFormattingOptions.groupSeparator}
              defaultValue={{ value: sourceFormattingInfo.groupSeparator, label: '' }}
              translateOptionLabels={false}
              isInvalid={sourceFormattingInfo.decimalSeparator === sourceFormattingInfo.groupSeparator}
              onChangeOption={(option: Option) =>
                setSourceFormattingInfo({ ...sourceFormattingInfo, groupSeparator: option.value })
              }
            />
          </BTCol>
        </BTRow>
      </Container>
    );
  }
  function onStartImportRequested() {
    if (importState.importOption === ImportOptions.CLIPBOARD) {
      if (sourceFormattingInfo.decimalSeparator === sourceFormattingInfo.groupSeparator) return;
    }
    setImportState({ ...importState, status: 'CONFIRMED' });
  }
};

const mapStateToProps = ({ settings }: any, props: any) => {
  const { id, tableHiddenColumns, formID, panelID, toggleID, origHiddenColumns } = props;
  const windowID = props.windowProps.id;
  const tableSettings = settings?.tables?.[formID]?.[panelID]?.[id];
  const sortTableSettings = settings?.sortingOrder?.[windowID]?.[formID]?.[panelID]?.[id];

  return {
    tableHiddenColumns: tableSettings?.[toggleID]?.hCols || tableHiddenColumns || origHiddenColumns,
    tableColumnResizing: tableSettings?.[toggleID]?.columnWidths,
    tableDefaultAction: tableSettings?.defaultAction,
    tableColumnOrder: tableSettings?.[toggleID]?.colOrder,
    tableTooltipSequence: tableSettings?.tooltipSequence,
    isTableTooltipEnabled: !!tableSettings?.isTooltipEnabled,
    tableSortBy: sortTableSettings?.sortBy,
  };
};

const mapDispatchToProps = {
  setTableHiddenColumns: (payload: any) => ({ type: TableActions.SET_HIDDEN_COLUMNS, payload }),
  setColumnResize: (payload: any) => ({ type: TableActions.SET_COLUMN_WIDTHS, payload }),
  setDefaultAction: (payload: any) => ({ type: TableActions.SET_DEFAULT_ACTION, payload }),
  setTableColumnOrder: (payload: any) => ({ type: TableActions.SET_COLUMN_ORDER, payload }),
  resetTableColumnOrder: (payload: any) => ({ type: TableActions.RESET_COLUMN_ORDER, payload }),
  resetTableColumnWidths: (payload: any) => ({ type: TableActions.RESET_COLUMN_WIDTH, payload }),
  setTooltipSequence: (payload: any) => ({ type: TableActions.SET_TOOLTIP_SEQUENCE, payload }),
  toggleTooltip: (payload: any) => ({ type: TableActions.TOGGLE_TOOLTIP, payload }),
  updateContext: (payload: any) => ({ type: WindowActions.UPDATE_CONTEXT, payload: payload }),
  setTableSortBy: (payload: any) => ({ type: TableActions.SET_COLUMN_SORT_BY, payload: payload }),
};

export const Table = connect(mapStateToProps, mapDispatchToProps)(TableComponent);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationContext, Button, Switch, useIptorTranslation } from '@iptor/base';
import { SpotlightArea } from '@iptor/business';
import { Grid2 as Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { OrderListTable } from './components/OrderListTable';
import { FormLayout } from '@iptor/business';
import OrderItemsTable from '../SalesOrderItems/components/OrderItemsTable';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

export const SalesOrderListForm = () => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [showSalesOrderLines, setShowSalesOrderLines] = useState(query.get('showOrderLines') === 'true' || false);
  const { t } = useIptorTranslation();
  const { closeWindow } = useContext(ApplicationContext);

  useEffect(() => {
    if (showSalesOrderLines) {
      query.set('showOrdersInError', (!showSalesOrderLines).toString());
    }
    query.set('showOrderLines', showSalesOrderLines.toString());
    history.replace({ pathname: location.pathname, search: query.toString() });
  }, [showSalesOrderLines]);

  return (
    <FormLayout
      title={t('common:TXT_Sales_Orders_List')}
      actions={[
        <Button id="close" onClick={() => closeWindow()}>
          {t('common:TXT_Close')}
        </Button>,
      ]}
    >
      <Grid container sx={{ width: '100%' }}>
        <SpotlightArea totalCards={3} />
      </Grid>
      <Grid container sx={{ width: '100%', mt: 2 }}>
        {showSalesOrderLines ? (
          <OrderItemsTable
            customAdditionalFilters={
              <>
                <Switch
                  label={t('common:TXT_View_by_Items')}
                  labelPosition="start"
                  id="viewByItems"
                  checked={true}
                  onChange={() => setShowSalesOrderLines(false)}
                />
                <Switch
                  label={t('common:TXT_Orders_in_Error')}
                  labelPosition="start"
                  id="ordersInError"
                  disabled
                  checked={false}
                  onChange={() => setShowSalesOrderLines(false)}
                />
              </>
            }
            customAdditionalActions={
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                id="create"
                to="/orders/create"
                sx={{ alignSelf: 'end' }}
              >
                + {t('common:TXT_New_Sales_Order')}
              </Button>
            }
          />
        ) : (
          <OrderListTable setShowSalesOrderLines={setShowSalesOrderLines} />
        )}
      </Grid>
    </FormLayout>
  );
};

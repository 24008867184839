import React, { FunctionComponent, useContext } from 'react';
import { EditorProps } from 'react-data-grid';
import { Constraint } from '../framework/parsers/constraint';
import { ColorMap } from '../types/colorMap';
import { RemoveTextLimitMarkerFormatter } from '../framework/parsers/RemoveTextLimitMarkerFormatter';
import { RemoveTrailingDotsFormatter } from '../framework/parsers/RemoveTrailingDotsFormatter';
import TimePicker from './TimePicker';
import { Edit } from './Edit';
import { Checkbox } from './Checkbox';
import { Formatters } from '../framework/parsers';
import { TableContext } from './Table';
import { Column } from './DataTable';
import { XT } from '../framework/handlers/xt';

export const EditWrapper: FunctionComponent<EditorProps<any>> = ({
  row,
  column,
  rowIdx,
  onClose,
  onRowChange,
}: EditorProps<any, any>) => {
  // let isEditable = col.readonly === 'false';
  // if (col.disableconstraints) {
  //   const dConstraint = new Constraint(col.disableconstraints);
  //   isEditable = !dConstraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], false);
  // }

  // let colVConstraint = new Constraint(col.visibleconstraints || '');
  // if (!colVConstraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], true)) cell.visible = false;
  // const eConstraint = new Constraint(col.errorconstraints);
  // const isInvalid = eConstraint.evaluate(
  //   row.flags.split('').map((x: string) => x === '1'),
  //   false
  // );
  const { columns, panelID, formID, attributes, yes, no } = useContext(TableContext);
  let col: Column | undefined = columns.find((c: Column) => c.id === column.key);

  let autoCompleteParams = attributes.autoComplete.find((ac: any) => ac.cols.indexOf(column.key) > -1)?.params;
  let autoCompleteEndpoint = attributes.autoComplete.find((ac: any) => ac.cols.indexOf(column.key) > -1)?.endpoint;
  let autoCompleteKey = attributes.autoComplete.find((ac: any) => ac.cols.indexOf(column.key) > -1)?.key;
  let autoComplete = !!attributes.autoComplete.find((ac: any) => ac.cols.indexOf(column.key) > -1);
  let autoCompleteExclude = attributes.autoComplete.find((ac: any) => ac.cols.indexOf(column.key) > -1)?.exclude;
  let disableAutoComplete = autoCompleteExclude ? XT.disableAutoComplete(col?.id, formID, autoCompleteExclude) : false;
  if (col) {
    // const isInvalid = false;
    const eConstraint = new Constraint(col.errorconstraints || '');
    const isInvalid = eConstraint.evaluate(
      row.flags.split('').map((x: string) => x === '1'),
      false,
    );
    let contentType = col.contenttype || col.mask;
    const accessor = col.id;
    // const cellProps = cell.getCellProps();
    let background = 'initial';
    let foreground = 'initial';
    if (col.colors) {
      for (let i in col.colors) {
        let color = col.colors[i];

        let constraint = new Constraint(color.visibleconstraints);
        if (constraint.evaluate(row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
          if (color.background) {
            if (!color.background.startsWith('rgb') && color.background.split(',').length === 3) {
              color.background = 'rgb(' + color.background + ')';
            }
            if (color.background.startsWith('rgb')) {
              background = color.background;
            } else {
              background = ColorMap[color.background] || 'initial';
            }
          }

          if (color.foreground) {
            if (!color.foreground.startsWith('rgb') && color.foreground.split(',').length == 3) {
              color.foreground = 'rgb(' + color.foreground + ')';
            }
            if (color.foreground.startsWith('rgb')) {
              foreground = color.foreground;
            } else {
              foreground = ColorMap[color.foreground] || 'initial';
            }
          }
          break;
        }
      }
    }
    let _value = row[column.key] || '';
    let _limit = col.limit;
    if (col.formatter === 'RemoveTextLimitMarker') {
      // _limit = _value.indexOf(']');
      // _value = _value.replace(']', '');
      _value = new RemoveTextLimitMarkerFormatter().in(_value, ']');
    }
    // if (col.mask === 'positiveinteger') {
    //   contentType = 'numeric';
    // }
    // if (col.mask === 'decimal') {
    //   _value = new DecimalFormatter().in(_value, decimalsign, entry_delimiter);
    // }
    if (col.formatter?.toLowerCase() === 'time') {
      contentType = 'time';
    }
    if (col.formatter === 'RemoveTrailingDots') {
      _value = new RemoveTrailingDotsFormatter().in(_value);
    }

    if (col.mask === 'integer') {
      contentType = 'integer';
    }

    // let dateFormat, dateValue;
    // if (contentType === 'date') {
    //   dateFormat = format6;
    //   if (_value?.length === 8) dateFormat = format8;
    //   if (_value.includes(dateSeparator)) dateFormat = format8Sep;
    //   dateValue = Formatters.Date.in(_value, dateFormat, century_break_year);
    // }
    let dds = col.ddspos;
    if (dds) {
      let split = dds.split(',');
      dds = split[0] + ',' + (+split[1] + (+row.id - 1) * (attributes.span || 1));
    }
    let rowOffset = 0;
    let pageSize = +attributes.pagesize || 0;
    if ((attributes.span || 1) > 1) pageSize = pageSize / +attributes.span;
    if (pageSize > 0) rowOffset = Math.floor((+row.id - 1) / pageSize) * pageSize;

    return (
      <span
        data-ddspos={dds}
        data-panel={panelID}
        data-row={row.id}
        data-column={accessor}
        data-rowfield={attributes.rowfield || attributes.rowposition}
        data-padchar={col?.padchar}
        data-limit={col?.limit}
        style={{ overflow: 'visible' }}
      >
        {contentType === 'time' ? (
          <TimePicker
            attributes={{
              mask: 'date',
              limit: _limit,
              formatter: col.formatter,
              autotab: col.autotab,
              ddspos: dds,
              rowOffset: rowOffset,
            }}
            isInvalid={isInvalid}
            panelID={panelID}
            rowID={row.id}
            rowFlags={row.flags}
            rowField={attributes.rowfield || attributes.rowposition}
            className={`editable-cell rdg-text-editor text-${col.textalign}`}
            id={`row-${row.id}-col-${accessor}`}
            name={accessor}
            onClose={onClose}
            defaultValue={Formatters.Time.in(_value, +(col?.limit ?? '0')) ?? undefined}
            onRowChange={(val: string) => {
              onRowChange({ ...row, [column.key]: val });
            }}
          />
        ) : contentType === 'date' ? (
          <Edit
            autoCapitalize={col.uppercase !== 'false'}
            attributes={{
              mask: 'date',
              limit: _limit,
              formatter: col.formatter,
              autotab: col.autotab,
              ddspos: dds,
              rowOffset: rowOffset,
            }}
            isInvalid={isInvalid}
            panelID={panelID}
            rowID={row.id}
            rowFlags={row.flags}
            rowField={attributes.rowfield || attributes.rowposition}
            className={`editable-cell rdg-text-editor text-${col.textalign}`}
            variant={'prompt'}
            id={`row-${row.id}-col-${accessor}`}
            name={accessor}
            autoComplete={false}
            defaultValue={_value}
            onClose={onClose}
            onRowChange={(val: string) => {
              onRowChange({ ...row, [column.key]: val });
            }}
          />
        ) : contentType === 'boolean' ? (
          <Checkbox
            attributes={{ deselectedvalue: col.deselectedvalue, ddspos: dds, rowOffset: rowOffset }}
            label=""
            isInvalid={isInvalid}
            id={`row-${row.id}-col-${accessor}`}
            name={accessor}
            panelID={panelID}
            rowID={row.id}
            rowFlags={row.flags}
            className={'editable-cell'}
            rowField={attributes.rowfield || attributes.rowposition}
            yes={yes}
            no={no}
            defaultValue={Formatters.BooleanFormatter.in(_value, yes, no)}
            onClose={onClose}
            onRowChange={(val: string) => {
              onRowChange({ ...row, [column.key]: val });
            }}
          />
        ) : (
          <Edit
            autoCapitalize={col.uppercase !== 'false'}
            attributes={{
              mask: col.mask,
              limit: _limit,
              formatter: col.formatter,
              autotab: col.autotab,
              autoselect: col.autoselect,
              alternativepromptkey: col.alternativepromptkey,
              ddspos: dds,
              rowOffset: rowOffset,
            }}
            isInvalid={isInvalid}
            panelID={panelID}
            rowID={row.id}
            rowFlags={row.flags}
            rowField={attributes.rowfield || attributes.rowposition}
            className={`editable-cell rdg-text-editor text-${col.textalign}`}
            variant={col.prompt ? 'prompt' : 'edit'}
            id={`row-${row.id}-col-${accessor}`}
            name={accessor}
            autoComplete={autoComplete}
            autoCompleteParams={autoCompleteParams}
            autoCompleteEndpoint={autoCompleteEndpoint}
            autoCompleteKey={autoCompleteKey}
            disableAutoComplete={disableAutoComplete}
            defaultValue={_value}
            onClose={onClose}
            onRowChange={(val: string) => {
              onRowChange({ ...row, [column.key]: val });
            }}
            preferredCaretPosition={col.preferredCaretPosition}
            changePreferredCaretPosition={(position) => {
              if (col && (col.preferredCaretPosition ?? -1) > -1) {
                // --> ignore request if functionality not active
                col.preferredCaretPosition = position;
              }
            }}
          />
        )}
      </span>
    );
  } else {
    return <></>;
  }
};

import { AperioViewCommand, AperioViews } from '../../types/AperioViews';
import { RequestError } from '../../types/RequestError';
import { WindowActions } from '../../types/actionTypes';
import { WindowProps } from '../../views/partials/Window';
import { Localization, LocalizationInfo } from '../localization/Localization';

type contextAreaState = {
  activeAperioLinks: AperioViews.Link[];
  aperioViewCommand?: AperioViewCommand;
  aperioViewIsClosing: boolean;
};

export type desktopState = {
  windows: Record<string, WindowProps>;
  layouts: Record<string, any>;
  activeWindow: string;
  settings: Record<string, any>;
  driverList: Record<string, Record<string, string>>;
  editing: boolean;
  localeSettings: LocalizationInfo.Settings;
  enquiries: Record<string, any>;
  attachments: Record<string, any>;
  dashboard: string;
  connectionInfo: Record<string, any>;
  versionInfo: Record<string, string>;
  contextAreas: Record<string, contextAreaState>;
  requestErrors: {
    pending?: RequestError.JSON;
    confirmed: Record<string, RequestError.JSON>; // Last confirmed error per open windows
  };
};
const initialState: desktopState = {
  windows: {
    dashboard: {
      localization: Localization.instance,
      id: 'dashboard',
      icon: 'dashboard',
      title: '',
      program: { id: 'dash', text: '' },
      layout: {},
      data: {}
    }
  },
  layouts: {},
  settings: {},
  driverList: {},
  editing: false,
  activeWindow: 'dashboard',
  localeSettings: {
    locale: 'en',
    dateFormat: 'MM/dd/yyyy',
    decimalSeparator: '.',
    groupSeparator: ','
  },
  enquiries: {},
  attachments: {},
  dashboard: '',
  connectionInfo: {},
  versionInfo: {},
  contextAreas: {},
  requestErrors: { confirmed: {} }
};

export const DesktopReducer = (state = initialState, action: { type: WindowActions; payload: any }) => {
  switch (action.type) {
    case WindowActions.UPDATE_SETTINGS: {
      state.settings = convertAperioViewSettings(action.payload.settings) || state.settings;
      state.driverList = action.payload.driverList || state.driverList;
      state.enquiries = action.payload.enquiries || state.enquiries;
      state.attachments = action.payload.attachments || state.attachments;
      state.dashboard = action.payload.dashboard || state.dashboard;
      if (
        action.payload.session &&
        Object.keys(action.payload.session).length !== 0 &&
        action.payload.session.constructor === Object
      ) {
        state.windows = action.payload.session.windows;
        state.activeWindow = action.payload.session.activeWindow;
        state.layouts = action.payload.session.layouts;
      }
      state.connectionInfo = action.payload.connectionInfo || state.connectionInfo;
      state.versionInfo = action.payload.versionInfo || state.versionInfo;
      return { ...state };
    }

    case WindowActions.UPDATE_CONTEXT: {
      let contextAreaStateChanged = false;

      // --> Initialize "new" context area state
      let contextAreaState = state.contextAreas[state.activeWindow];
      if (!contextAreaState) {
        contextAreaState = { activeAperioLinks: [], aperioViewCommand: undefined, aperioViewIsClosing: false };
      } else {
        contextAreaState = { ...contextAreaState };
      }
      const prevAperioViewIsClosing = contextAreaState.aperioViewIsClosing;

      // --> Set active aperio links
      if (action.payload.activeAperioLinks) {
        // set active aperio links for "new form"
        if (action.payload.activeAperioLinks.length > 0 || contextAreaState.activeAperioLinks.length > 0) {
          contextAreaState.activeAperioLinks = [...action.payload.activeAperioLinks];
          contextAreaStateChanged = true;
        }

        // start closing (if requested, smooth transition)
        if (action.payload?.aperioViewStatus === 'CLOSING') {
          if (!prevAperioViewIsClosing) {
            contextAreaState.aperioViewIsClosing = true;
            contextAreaStateChanged = true;
          }

          // reset aperio view command (do not show data anymore of "previous form")
        } else {
          if (contextAreaState.aperioViewCommand) {
            contextAreaState.aperioViewCommand = undefined;
            contextAreaStateChanged = true;
          }
          if (prevAperioViewIsClosing) {
            contextAreaState.aperioViewIsClosing = false;
            contextAreaStateChanged = true;
          }
        }
      }

      // --> Handle end of closing requested
      if (action.payload?.aperioViewStatus === 'CLOSED') {
        // only if still in "closing" state !!! "closing" state is also changed by changing the command that in some cases is even expected to be executed first (smooth transition)
        if (prevAperioViewIsClosing) {
          contextAreaState.aperioViewIsClosing = false;
          contextAreaState.aperioViewCommand = undefined;
          contextAreaStateChanged = true;
        }
      }

      // --> Set aperio view command
      if ('aperioViewCommand' in action.payload) {
        // do not change if both undefined
        if (action.payload.aperioViewCommand || contextAreaState.aperioViewCommand) {
          if (!action.payload.aperioViewCommand) {
            contextAreaState.aperioViewCommand = undefined;
            contextAreaStateChanged = true;
          } else {
            // only change if command is really changing
            let isEqual = true;
            if (!contextAreaState.aperioViewCommand) {
              isEqual = false;
            } else if (contextAreaState.aperioViewCommand.errors?.length > 0) {
              isEqual = false; // always rerender if errors
            } else if (contextAreaState.aperioViewCommand.aperioForm !== action.payload.aperioViewCommand.aperioForm) {
              isEqual = false;
            } else if (
              Object.keys(contextAreaState.aperioViewCommand.params).length !==
              Object.keys(action.payload.aperioViewCommand.params).length
            ) {
              isEqual = false;
            } else {
              Object.entries(contextAreaState.aperioViewCommand.params).forEach(([key, value]) => {
                if (value !== action.payload.aperioViewCommand.params[key]) isEqual = false;
              });
            }

            if (!isEqual) {
              contextAreaState.aperioViewCommand = { ...action.payload.aperioViewCommand };
              contextAreaStateChanged = true;
            }
          }
        }

        // always remove closing status
        if (prevAperioViewIsClosing) {
          contextAreaState.aperioViewIsClosing = false;
          contextAreaStateChanged = true;
        }
      }

      //NTH_SKE: revise ?
      let window = state.windows[state.activeWindow];
      window.contextArea = action.payload.contextArea === undefined ? window.contextArea : action.payload.contextArea;
      window.attachmentKeys =
        action.payload.attachmentKeys === undefined ? window.attachmentKeys : action.payload.attachmentKeys;

      if (contextAreaStateChanged) {
        return { ...state, contextAreas: { ...state.contextAreas, [state.activeWindow]: contextAreaState } };
      }
      return { ...state };
    }
    case WindowActions.LAUNCH: {
      if (
        action.payload.program.view === 'QueryManagerView' ||
        action.payload.program.view === 'BrowserView' ||
        action.payload.program.view === 'DashboardView' ||
        action.payload.program.view === 'ApplicationView'
      ) {
        return {
          ...state,
          layouts: {
            ...state.layouts
          },
          activeWindow: action.payload.window_id,
          windows: {
            ...state.windows,
            [action.payload.window_id]: {
              id: action.payload.window_id,
              program: action.payload.program,
              application: action.payload.application,
              data: action.payload.data,
              layout: {},
              title: action.payload.program.text,
              panelMaster: {},
              tabSequence: [],
              actionDefinitions: {}
            }
          }
        };
      } else {
        let _panels = action.payload.data.form.panel;
        let _panelData: any = {
          [action.payload.data.form.$.id]: {}
        };
        for (let i in _panels) {
          let _panel = _panels[i];
          _panelData[action.payload.data.form.$.id][_panel.$.id] = _panel;
        }
        let tabSequence: number[] | undefined = undefined;
        let activeDriver: string | undefined = undefined;
        let sequenceKey: string | undefined = undefined;
        if (
          (action.payload.layout || state.layouts[action.payload.data.form.$.id]).screen.form[0].$.handler ===
          'itembp.DriverBasedTabHandler'
        ) {
          let id = (action.payload.layout || state.layouts[action.payload.data.form.$.id]).screen.form[0].$.userdata1;
          activeDriver = action.payload.data.form.$?.callstack
            ?.split(',')
            ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
            ? 'ASGD3346'
            : 'ASGD3343';
          sequenceKey = action.payload.data.form.$?.callstack
            ?.split(',')
            ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
            ? 'tabSequenceBP'
            : action.payload.data.form.$?.callstack
                ?.split(',')
                ?.find((x: string) => ['DMR001', 'DMR171'].indexOf(x) > -1)
            ? 'tabSequenceItem'
            : '';
          for (let i in action.payload.data.form.panel) {
            let panel = action.payload.data.form.panel[i];
            let _value = panel.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
            if (!!_value) {
              tabSequence =
                _value.$.value
                  .trim()
                  .match(/\d{2}/g)
                  ?.map((x: string) => +x) || [];
              break;
            }
          }
        }
        sessionStorage.setItem('windowOpened', Object.keys(state.windows).length.toString() + 1);
        return {
          ...state,
          layouts: {
            ...state.layouts,
            [action.payload.data.form.$.id]: !!action.payload.layout
              ? action.payload.layout
              : state.layouts[action.payload.data.form.$.id]
          },
          activeWindow: action.payload.window_id,
          windows: {
            ...state.windows,
            [action.payload.window_id]: {
              id: action.payload.window_id,
              program: action.payload.program,
              data: action.payload.data,
              layout: action.payload.layout || state.layouts[action.payload.data.form.$.id],
              title: action.payload.program.text,
              panelMaster: _panelData,
              tabSequence: tabSequence,
              activeDriver: activeDriver,
              actionDefinitions: action.payload.actions,
              attachments: action.payload.attachments?.attachment_list?.category,
              ...(!!sequenceKey && { [sequenceKey]: tabSequence })
            }
          }
        };
      }
    }
    case WindowActions.CLOSE: {
      //Delete chosen window and shift active 1 to left, if active window was deleted
      const indexOfActive = Object.keys(state.windows).indexOf(state.activeWindow);
      const indexOfClosed = Object.keys(state.windows).indexOf(action.payload.window_id);
      delete state.windows[action.payload.window_id];

      let activeWindow: string;
      if (indexOfActive === indexOfClosed) {
        activeWindow =
          indexOfActive === 0 ? Object.keys(state.windows)[0] : Object.keys(state.windows)[indexOfActive - 1];
      } else {
        activeWindow = state.activeWindow;
      }

      // --> Remove request error history
      let requestErrors = state.requestErrors;
      if (state.requestErrors.confirmed[action.payload.window_id]) {
        requestErrors = { ...state.requestErrors };
        requestErrors.confirmed = { ...requestErrors.confirmed };
        delete requestErrors.confirmed[action.payload.window_id];
      }

      sessionStorage.setItem('windowOpened', Object.keys(state.windows).length.toString());
      return {
        ...state,
        activeWindow: activeWindow,
        windows: { ...state.windows },
        requestErrors: requestErrors
      };
    }
    case WindowActions.EDIT: {
      state.editing = true;
      return {
        ...state
      };
    }
    case WindowActions.RENAME: {
      // state.windows[state.activeWindow].title = action.payload.name;
      state.editing = false;
      return {
        ...state
      };
    }
    case WindowActions.SWITCH: {
      //Change active window
      state.activeWindow = action.payload.id;
      state.editing = false;
      return {
        ...state
      };
    }
    case WindowActions.CHANGE_LOCALE_SETTINGS: {
      return {
        ...state,
        localeSettings: {
          ...state.localeSettings,
          locale: action.payload.localeSettings.locale,
          dateFormat: action.payload.localeSettings.dateFormat,
          decimalSeparator: action.payload.localeSettings.decimalSeparator,
          groupSeparator: action.payload.localeSettings.groupSeparator
        }
      };
    }
    case WindowActions.INTERACT: {
      //Should reload logic
      let window = state.windows[action.payload.window_id];
      let layout = !!action.payload.layout ? action.payload.layout : state.layouts[action.payload.data.form.$.id];
      let flags = '';
      if (layout.screen.form[0].$.dialog === 'true') {
        action.payload.data.form.panel = action.payload.data.form.panel.map((panel: any) => {
          let oldPanel =
            window.panelMaster &&
            window.panelMaster[action.payload.data.form.$.id] &&
            window.panelMaster[action.payload.data.form.$.id][panel.$.id];
          if (panel.$.id.indexOf('MSG') > -1) return panel; //Ignore all MSG panels from panelMaster
          // flags = panel.$.flags;
          if (oldPanel) {
            //Panel present in old
            oldPanel.$ = panel.$;
            if (panel.$.reloaded !== 'false') {
              // if (panel.$.reloaded !== 'true') {
              //   panel.$.flags = oldPanel.$.flags;
              // } else {
              //   flags = panel.$.flags;
              // }
              if (window.panelMaster) window.panelMaster[action.payload.data.form.$.id][panel.$.id] = panel;
            } else if (panel.$.reloaded === 'false') {
              //Merge control values
              // flags = oldPanel.$.flags;
              oldPanel.ctrl = oldPanel.ctrl?.map(
                (old: any) => panel.ctrl?.find((n: any) => n.$.id === old.$.id) || old
              );
              oldPanel.row = oldPanel.row?.map((old: any) => panel.row?.find((n: any) => n.$.id === old.$.id) || old);
              if (panel.row)
                oldPanel.row = [
                  ...oldPanel.row,
                  ...panel.row.filter((n: any) => oldPanel.row.map((o: any) => o.$.id).indexOf(n.$.id) === -1)
                ];
            }
            return panel.$.reloaded !== 'false' ? panel : oldPanel;
          } else {
            if (window.panelMaster) {
              if (!window.panelMaster[action.payload.data.form.$.id])
                window.panelMaster[action.payload.data.form.$.id] = { [panel.$.id]: panel };
              else window.panelMaster[action.payload.data.form.$.id][panel.$.id] = panel;
            }
            return panel;
          }
        });
        if (!!flags) {
          action.payload.data.form.panel.forEach((p: any) => (p.$.flags = flags));
        }
        const dialog = {
          // Remark: original code was mutating the (previous) state.  ==> NTH_SKE_20130309: fix all other similar state mutations
          ...state.windows[action.payload.window_id],
          id: action.payload.window_id,
          data: { ...action.payload.data },
          layout: action.payload.layout || state.layouts[action.payload.data.form.$.id],
          // title: action.payload.window.title,
          panelMaster: window.panelMaster,
          actionDefinitions: action.payload.actions,
          // attachments: action.payload.attachments,
          isDialog: true
        };
        return {
          ...state,
          layouts: {
            ...state.layouts,
            [action.payload.data.form.$.id]: !!action.payload.layout
              ? action.payload.layout
              : state.layouts[action.payload.data.form.$.id]
          },
          windows: {
            ...state.windows,
            [action.payload.window_id]: {
              ...state.windows[action.payload.window_id],
              id: action.payload.window_id,
              // title: action.payload.window.title,
              panelMaster: window.panelMaster,
              dialog: dialog,
              actionDefinitions: undefined
            }
          }
        };
      } else {
        action.payload.data.form.panel = action.payload.data.form.panel.map((panel: any) => {
          let oldPanel =
            window.panelMaster &&
            window.panelMaster[action.payload.data.form.$.id] &&
            window.panelMaster[action.payload.data.form.$.id][panel.$.id];
          if (panel.$.id.indexOf('MSG') > -1) return panel; //Ignore all MSG panels from panelMaster
          // flags = panel.$.flags;
          if (oldPanel) {
            //Panel present in old
            oldPanel.$ = panel.$;
            if (panel.$.reloaded !== 'false') {
              // if (panel.$.reloaded !== 'true') {
              //   panel.$.flags = oldPanel.$.flags;
              // } else {
              //   flags = panel.$.flags;
              // }
              if (window.panelMaster) window.panelMaster[action.payload.data.form.$.id][panel.$.id] = panel;
            } else if (panel.$.reloaded === 'false') {
              // flags = oldPanel.$.flags;
              oldPanel.ctrl = oldPanel.ctrl?.map(
                (old: any) => panel.ctrl?.find((n: any) => n.$.id === old.$.id) || old
              );
              oldPanel.row = oldPanel.row?.map((old: any) => panel.row?.find((n: any) => n.$.id === old.$.id) || old);
              if (panel.row) {
                oldPanel.row = [
                  ...oldPanel.row,
                  ...panel.row.filter((n: any) => oldPanel.row.map((o: any) => o.$.id).indexOf(n.$.id) === -1)
                ];
              }
            }
            return panel.$.reloaded !== 'false' ? panel : oldPanel;
          } else {
            if (window.panelMaster) {
              if (!window.panelMaster[action.payload.data.form.$.id])
                window.panelMaster[action.payload.data.form.$.id] = { [panel.$.id]: panel };
              else window.panelMaster[action.payload.data.form.$.id][panel.$.id] = panel;
            }
            return panel;
          }
        });
        if (!!flags) {
          action.payload.data.form.panel.forEach((p: any) => (p.$.flags = flags));
        }

        let tabSequence1: number[] | undefined = undefined;
        let activeDriver: string | undefined = undefined;
        let sequenceKey: 'tabSequenceItem' | 'tabSequenceBP' | undefined = undefined;
        if (
          (action.payload.layout || state.layouts[action.payload.data.form.$.id]).screen.form[0].$.handler ===
          'itembp.DriverBasedTabHandler'
        ) {
          let id = (action.payload.layout || state.layouts[action.payload.data.form.$.id]).screen.form[0].$.userdata1;
          activeDriver = action.payload.data.form.$?.callstack
            ?.split(',')
            ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
            ? 'ASGD3346'
            : 'ASGD3343';
          sequenceKey = action.payload.data.form.$?.callstack
            ?.split(',')
            ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
            ? 'tabSequenceBP'
            : action.payload.data.form.$?.callstack
                ?.split(',')
                ?.find((x: string) => ['DMR001', 'DMR171'].indexOf(x) > -1)
            ? 'tabSequenceItem'
            : undefined;
          for (let i in action.payload.data.form.panel) {
            let panel = action.payload.data.form.panel[i];
            let _value = panel.ctrl?.find((data: any) => data.$.id === id); //In case panels have only rows and no controls
            if (!!_value) {
              tabSequence1 =
                _value.$.value
                  .trim()
                  .match(/\d{2}/g)
                  ?.map((x: string) => +x) || [];
              break;
            }
          }
        }

        return {
          ...state,
          layouts: {
            ...state.layouts,
            [action.payload.data.form.$.id]: !!action.payload.layout
              ? action.payload.layout
              : state.layouts[action.payload.data.form.$.id]
          },
          windows: {
            ...state.windows,
            [action.payload.window_id]: {
              ...state.windows[action.payload.window_id],
              id: action.payload.window_id,
              data: { ...action.payload.data },
              layout: action.payload.layout || state.layouts[action.payload.data.form.$.id],
              // title: action.payload.window.title,
              panelMaster: window.panelMaster,
              actionDefinitions: action.payload.actions || state.windows[action.payload.window_id].actionDefinitions,
              dialog: undefined,
              tabSequence: tabSequence1 || state.windows[action.payload.window_id].tabSequence,
              activeDriver: activeDriver,
              attachments: action.payload.attachments?.attachment_list?.category,
              ...(!!sequenceKey && {
                [sequenceKey]: tabSequence1 || state.windows[action.payload.window_id][sequenceKey]
              })
            }
          }
        };
      }
    }
    case WindowActions.LOAD_CONTEXT: {
      return {
        ...state,
        windows: {
          ...state.windows,
          [action.payload.window_id]: {
            ...state.windows[action.payload.window_id],
            contextDefinition: {
              [action.payload.formID]: {
                [action.payload.context]: action.payload.data.contextOptions
              }
            }
          }
        }
      };
    }
    case WindowActions.LOAD_ACTIONS: {
      return {
        ...state,
        windows: {
          ...state.windows,
          [action.payload.window_id]: {
            ...state.windows[action.payload.window_id],
            actionDefinitions: action.payload.data.actions
          }
        }
      };
    }
    case WindowActions.LOAD_ATTACHMENTS: {
      return {
        ...state,
        windows: {
          ...state.windows,
          [action.payload.windowId]: {
            ...state.windows[action.payload.windowId],
            attachments: [...action.payload.attachments]
          }
        }
      };
    }
    case WindowActions.NEW_REQUEST_ERROR: {
      // Initialize
      const requestErrors = { ...state.requestErrors };

      // Handle unexpected situation: still a pending one
      if (requestErrors.pending && state.windows[requestErrors.pending.windowId]) {
        // --> change user action to UNKNOWN
        const pending = { ...requestErrors.pending };
        pending.userAction = 'UNKNOWN';

        // --> move pending to confirmed
        requestErrors.confirmed = { ...requestErrors.confirmed, [requestErrors.pending.windowId]: pending };
      }

      // Registrate new error
      requestErrors.pending = action.payload.requestError;

      // Return resulting state
      return { ...state, requestErrors: requestErrors };
    }
    case WindowActions.CONFIRM_REQUEST_ERROR: {
      // Handle unexpected case
      if (!state.requestErrors.pending) return state;

      // Intialize
      const requestErrors = { ...state.requestErrors };

      // Change user action
      const pending = { ...requestErrors.pending! };
      pending.userAction = action.payload.userAction;

      // Move pending to confirmed
      if (pending.windowId && state.windows[pending.windowId]) {
        requestErrors.confirmed = { ...requestErrors.confirmed, [pending.windowId]: pending };
      }

      // Remove pending
      delete requestErrors.pending;

      // Return resulting state
      return { ...state, requestErrors: requestErrors };
    }
    default:
      return state;
  }
};

namespace XT {
  export namespace Aperioviews {
    export namespace View {
      export type Attributes = {
        id: string; //                  "part.AperioView",
        path: string; //                "/xtview",
      };

      export namespace Link {
        export type Attributes = {
          aperioform: string; //        "businessPartnerInfo",
          form: string; //              "DMR300D",
          panels?: string; //           "DMR30002, DMR30017",
        };

        export namespace Param {
          export type Attributes = {
            field: string; //           "TBL_DMR30002.FMCUNO",   "OHORNO"
            id: string; //              "businessPartner",       "order"
            panel?: string;
            type?: string; //                                    "numeric",
          };
        }

        export type Param = {
          $: Param.Attributes;
        };

        export namespace Event {
          export type Attributes = {
            field: string; //           "TBL_DMR30002",   "FMPRDC"
            type: string; //            "selection",      "update", ...
            panel?: string;
          };
        }

        export type Event = {
          $: Event.Attributes;
        };
      }

      export type Link = {
        $: Link.Attributes;
        param: Link.Param[];
        event: Link.Event[];
      };
    }

    export type View = {
      $: View.Attributes;
      link: View.Link[];
    };
  }

  export type Aperioviews = {
    view: [Aperioviews.View];
  };
}
const convertAperioViewSettings = (payloadSettings: any): any => {
  const _convertAperioViewSettings = (payloadSetting: any): AperioViews | undefined => {
    // Initialize result
    // =================

    // --> Get aperio view info payload
    const xtArray = payloadSettings?.regionals?.aperioviews as any[];

    // --> No settings if no info defined
    if (!xtArray || xtArray.length < 1) return undefined;

    // --> Get aperio view settings
    const xt = xtArray[0] as XT.Aperioviews;

    // --> No settings if no views defined
    if (!xt || !xt.view || xt.view.length < 1) return undefined;

    // --> Initialize
    const result: AperioViews = {
      id: xt.view[0].$?.id?.trim?.() || '',
      path: xt.view[0].$?.path?.trim?.() || '',
      links: [],
      errors: []
    };

    // Add links
    // =========
    const xtLinks = xt.view[0].link;
    if (!xtLinks || xtLinks.length < 1) return result;
    xtLinks.forEach((xtLink) => {
      // Initialize link
      // ---------------
      let isValidLink = true;
      const link: AperioViews.Link = {
        formId: (xtLink.$?.form?.trim?.() || '').toLocaleUpperCase(),
        aperioFormId: xtLink.$?.aperioform?.trim?.() || '',
        panelIds: (xtLink.$?.panels?.split?.(',') || []).map((el) => el.trim().toLocaleUpperCase()), // "DMR30202, DMR30217" => ["DMR30202", "DMR30217"]
        events: [],
        parameters: []
      };

      // Validation link attributes
      // --------------------------

      // --> attributes expected
      if (!xtLink.$) {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: attributes are missing. ` + `Detail link: ${JSON.stringify(xtLink)}.`
        );
      }

      // --> form id mandatory
      if (xtLink.$ && !link.formId) {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: form is mandatory. ` +
            `Detail attributes link: ${JSON.stringify(xtLink.$)}.`
        );
      }

      // --> aperio form id mandatory
      if (xtLink.$ && !link.aperioFormId) {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: aperioform is mandatory. ` +
            `Detail attributes link: ${JSON.stringify(xtLink.$)}.`
        );
      }

      // --> type of panels should be string (remark optional attribute)
      if (xtLink.$?.panels && typeof xtLink.$.panels !== 'string') {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: unexpected format panels. ` +
            `Detail attributes link: ${JSON.stringify(xtLink.$)}.`
        );
      }

      // Add events
      // ----------
      if (xtLink.event?.length > 0) {
        xtLink.event.forEach((xtEvent) => {
          // Initialize
          let isValidEvent = true;
          let event: AperioViews.Link.Event | undefined;

          const xtField = (xtEvent.$?.field?.trim?.() || '').toLocaleUpperCase();
          const xtPanel = (xtEvent.$?.panel?.trim?.() || '').toLocaleUpperCase();
          const xtType = (xtEvent.$?.type?.trim?.() || '').toLocaleLowerCase();

          // Validation event attributes

          // --> attributes expected
          if (!xtEvent.$) {
            isValidEvent = false;
            result.errors.push(
              `Unable to load aperio view event: attributes are missing. ` +
                `Detail event: ${JSON.stringify(xtEvent)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> type of field should be string (remark might be optional attribute depending on type)
          if (xtEvent.$?.field && typeof xtEvent.$?.field !== 'string') {
            isValidEvent = false;
            result.errors.push(
              `Unable to load aperio view event: unexpected format field. ` +
                `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> type of panel should be string (remark optional attribute)
          if (xtEvent.$?.panel && typeof xtEvent.$?.panel !== 'string') {
            isValidEvent = false;
            result.errors.push(
              `Unable to load aperio view event: unexpected format panel. ` +
                `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> type mandatory
          if (xtEvent.$ && !xtType) {
            isValidEvent = false;
            result.errors.push(
              `Unable to load aperio view event: type is mandatory. ` +
                `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // Create type specific events
          if (xtType) {
            switch (xtType) {
              case AperioViews.EventTypes.ON_FORM_LOAD: {
                event = { type: xtType };
                break;
              }
              case AperioViews.EventTypes.ON_ROW_SELECTION: {
                if (xtField) {
                  event = { type: xtType, tableId: xtField };
                } else if (isValidEvent) {
                  isValidEvent = false;
                  result.errors.push(
                    `Unable to load aperio view event: field is mandatory (table name) for event type "${xtType}". ` +
                      `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
                  );
                }
                break;
              }
              case AperioViews.EventTypes.ON_FIELD_UPDATE:
              case AperioViews.EventTypes.ON_FIELD_BLUR: {
                if (xtField) {
                  event = {
                    type: xtType,
                    panelId: xtPanel ? xtPanel : undefined, // Remark: explicit conversion to undefined
                    fieldId: xtField
                  };
                } else if (isValidEvent) {
                  isValidEvent = false;
                  result.errors.push(
                    `Unable to load aperio view event: field is mandatory for event type "${xtType}". ` +
                      `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
                  );
                }
                break;
              }
              default: {
                isValidEvent = false;
                result.errors.push(
                  `Unable to load aperio view event: event type "${xtType}" not supported. ` +
                    `Detail event: ${JSON.stringify(xtEvent.$)} for link ${JSON.stringify(xtLink.$)}.`
                );
              }
            }
          }

          // Add valid events
          if (isValidEvent && event) link.events.push(event);
        });
      }

      // At least one valid event expected
      // ---------------------------------
      if (link.events.length < 1) {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: at least one valid event expected. ` +
            `Detail attributes link: ${JSON.stringify(xtLink.$)}.`
        );
      }

      // Add parameters
      // --------------
      let hasAnyInvalidParameter = false;
      if (xtLink.param?.length > 0) {
        xtLink.param.forEach((xtParam) => {
          // Initialize
          let isValidParameter = true;
          const xtId = xtParam.$?.id?.trim?.() || '';
          let xtField = (xtParam.$?.field?.trim?.() || '').toLocaleUpperCase();
          let xtTable: string | undefined = undefined;
          if (xtField.includes('.')) [xtTable, xtField] = xtField.split('.').map((el) => el.trim());

          const xtPanel = (xtParam.$?.panel?.trim?.() || '').toLocaleUpperCase();
          const xtType = (xtParam.$?.type?.trim?.() || '').toLocaleLowerCase();
          const type = xtType ? xtType : AperioViews.FieldTypes.TEXT; // Remark: explicitly replace space(s), empty string, undefined, ... with TEXT

          // Validation parameter attributes

          // --> attributes expected
          if (!xtParam.$) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: attributes are missing. ` +
                `Detail param: ${JSON.stringify(xtParam)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> id mandatory
          if (xtParam.$ && !xtId) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: id is mandatory. ` +
                `Detail param: ${JSON.stringify(xtParam.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> field mandatory
          if (xtParam.$ && !xtField) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: field is mandatory. ` +
                `Detail param: ${JSON.stringify(xtParam.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> should be known type (remark: optional but default value assigned)
          if (xtParam.$ && !(Object.values(AperioViews.FieldTypes) as string[]).includes(type)) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: type "${type}" not supported. ` +
                `Detail param: ${JSON.stringify(xtParam.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // --> table name only expected for link with event type "selection"
          if (
            xtParam.$ &&
            xtTable &&
            link.events.find((event) => event.type !== AperioViews.EventTypes.ON_ROW_SELECTION)
          ) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: table name only expected for link ` +
                `with event type ${AperioViews.EventTypes.ON_ROW_SELECTION}. ` +
                `Detail param: ${JSON.stringify(xtParam.$)} for link ${JSON.stringify(xtLink.$)}.`
            );

            // --> table name should be defined on event with event type "selection"
          } else if (
            xtParam.$ &&
            xtTable &&
            !link.events.find((event) => (event as AperioViews.Link.TableEvent).tableId === xtTable)
          ) {
            isValidParameter = false;
            result.errors.push(
              `Unable to load aperio view parameter: no matching table name found on event  ` +
                `with event type ${AperioViews.EventTypes.ON_ROW_SELECTION}. ` +
                `Detail param: ${JSON.stringify(xtParam.$)} for link ${JSON.stringify(xtLink.$)}.`
            );
          }

          // Add valid parameter
          if (isValidParameter) {
            link.parameters.push({
              aperioId: xtId,
              panelId: xtPanel ? xtPanel : undefined,
              tableId: xtTable ? xtTable : undefined,
              fieldId: xtField,
              fieldType: type as AperioViews.FieldTypes
            });
          } else {
            hasAnyInvalidParameter = true;
          }
        });
      }

      // All parameters must be valid
      // ----------------------------
      if (hasAnyInvalidParameter) {
        isValidLink = false;
        result.errors.push(
          `Unable to load aperio view link: invalid parameter(s) found (see previous messages). ` +
            `Detail attributes link: ${JSON.stringify(xtLink.$)}.`
        );
      }

      // Add valid link
      // --------------
      if (isValidLink) result.links.push(link);
    });

    // Return result
    // =============
    return result;
  };

  let aperioViews: AperioViews | undefined;
  try {
    aperioViews = _convertAperioViewSettings(payloadSettings);
  } catch (e: any) {
    let id = '';
    let path = '';
    try {
      const xt = payloadSettings?.regionals?.aperioviews as XT.Aperioviews;
      if (xt?.view?.length > 0) {
        id = xt.view[0].$?.id?.trim?.() || id;
        path = xt.view[0].$?.path?.trim?.() || path;
      }
    } catch (_e: any) {
      // ignore inner exception
    }

    aperioViews = {
      id: id,
      path: path,
      links: [],
      errors: [
        `Aperio views are not available. Exception occured while parsing Aperio configuration settings: ${
          e.toString?.() || 'unknown error'
        }`
      ]
    };
  }

  if (aperioViews) {
    return {
      ...payloadSettings,
      regionals: {
        ...payloadSettings.regionals,
        aperioviews: aperioViews
      }
    };
  } else {
    return payloadSettings;
  }
};

import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import {
  OpenAPIId,
  GetClientContextType,
  openAPIManager as openAPIApplicationManager,
  OpenAPIProviderContext,
} from './manager';

export const OpenAPIProvider = ({ children }: PropsWithChildren<unknown>) => {
  const openAPIManager = openAPIApplicationManager;
  const clientContexts = useRef<Record<string, ReturnType<GetClientContextType>>>({});
  const [currentCalls, setCurrentCalls] = useState<Record<string, Record<string, number>>>({});

  const updateCurrentCalls: (openAPIId: string, operationId: string, value: number) => void = useCallback(
    (openAPIId, operationId, value) => {
      setCurrentCalls((previous) => {
        const next = { ...previous };
        if (!next[openAPIId]) next[openAPIId] = {};
        const nextOps = { ...next[openAPIId] };
        if (!nextOps[operationId]) {
          nextOps[operationId] = value;
        } else {
          nextOps[operationId] += value;
        }
        return next;
      });
    },
    [setCurrentCalls],
  );
  const onClientOperationMethodRequest: (openAPIId: OpenAPIId, operationId: string) => void = useCallback(
    (openAPIId, operationId) => {
      updateCurrentCalls(openAPIId, operationId, +1);
    },
    [updateCurrentCalls],
  );
  const onClientOperationMethodResponse: (openAPIId: OpenAPIId, operationId: string) => void = useCallback(
    (openAPIId, operationId) => {
      updateCurrentCalls(openAPIId, operationId, -1);
    },
    [updateCurrentCalls],
  );

  const createClientContext: GetClientContextType = useCallback(
    (openAPIId) => {
      return {
        openAPIClientPromise: openAPIManager.getClient(openAPIId),
        onOperationMethodRequest: (operationId: string) => onClientOperationMethodRequest(openAPIId, operationId),
        onOperationMethodResponse: (operationId: string) => onClientOperationMethodResponse(openAPIId, operationId),
      };
    },
    [onClientOperationMethodRequest, onClientOperationMethodRequest],
  );
  const getClientContext: GetClientContextType = useCallback(
    (openAPIId) => {
      if (!clientContexts.current[openAPIId]) {
        clientContexts.current[openAPIId] = createClientContext(openAPIId);
      }
      return clientContexts.current[openAPIId]!;
    },
    [createClientContext],
  );

  return <OpenAPIProviderContext.Provider value={{ getClientContext }}>{children}</OpenAPIProviderContext.Provider>;
};

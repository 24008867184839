export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#FFAB00" stroke="none" />
    <path
      d="M7.5 12C7.5 12.5523 7.05228 13 6.5 13C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11C7.05228 11 7.5 11.4477 7.5 12Z"
      fill="white"
    />
    <path
      d="M13.0263 12C13.0263 12.5523 12.5786 13 12.0263 13C11.474 13 11.0263 12.5523 11.0263 12C11.0263 11.4477 11.474 11 12.0263 11C12.5786 11 13.0263 11.4477 13.0263 12Z"
      fill="white"
    />
    <path
      d="M18.5526 12C18.5526 12.5523 18.1049 13 17.5526 13C17.0003 13 16.5526 12.5523 16.5526 12C16.5526 11.4477 17.0003 11 17.5526 11C18.1049 11 18.5526 11.4477 18.5526 12Z"
      fill="white"
    />
    <path
      d="M7.5 12C7.5 12.5523 7.05228 13 6.5 13C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11C7.05228 11 7.5 11.4477 7.5 12Z"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M13.0263 12C13.0263 12.5523 12.5786 13 12.0263 13C11.474 13 11.0263 12.5523 11.0263 12C11.0263 11.4477 11.474 11 12.0263 11C12.5786 11 13.0263 11.4477 13.0263 12Z"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M18.5526 12C18.5526 12.5523 18.1049 13 17.5526 13C17.0003 13 16.5526 12.5523 16.5526 12C16.5526 11.4477 17.0003 11 17.5526 11C18.1049 11 18.5526 11.4477 18.5526 12Z"
      stroke="white"
      stroke-width="2"
    />
  </svg>
);

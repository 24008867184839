import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type AddressStateProvinceProps = BusinessDropdownProps & {
  country?: string;
};

const COLUMN_NAMES: string[] = ['State/Province', 'Description'];

export const AddressStateProvinceDropdown = ({ country, ...props }: AddressStateProvinceProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: warehousesOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: country ? 'GET /countries/{country}/states' : 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'state',
    params: country ? { country } : { windowId: 'states' },
    shouldPreventApiCall,
  });

  useEffect(() => {
    refetch();
  }, [country]);

  return (
    <BaseDropdown
      {...props}
      loading={loading}
      label="State/Province"
      options={warehousesOptions}
      columnNames={COLUMN_NAMES}
    />
  );
};

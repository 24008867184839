import React, { FunctionComponent, useEffect, useRef, useState, MouseEvent, useContext, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import '../styles/Checkbox.scss';
import { Formatters } from '../framework/parsers';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { LocalStorage } from '../framework/handlers/localStorage';
import { AperioViewCommand, AperioViews } from '../types/AperioViews';
import { WindowActions } from '../types/actionTypes';
import { useDispatch } from 'react-redux';
import { AperioView } from '../framework/handlers/aperioView';
import { debounce } from '@iptor/base';

/*
 * attributes: XML attributes,
 * isTriState: indeterminate state
 * panelID: panel id in window
 * rowID:  tableRow Id
 * yes:  TODO: comment to be added
 * no:  TODO: comment to be added
 * */
type CheckboxProps = {
  attributes: Record<string, any>;
  isTriState?: boolean;
  className?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  id: string;
  isInvalid?: boolean;
  label: string;
  name: string;
  panelID: string;
  visible?: boolean;
  yes: string;
  no: string;
  rowID?: string;
  rowFlags?: string;
  rowField?: string;
  onClose?: Function;
  onRowChange?: Function;
};

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  attributes,
  isTriState = false,
  className = '',
  defaultValue,
  disabled = false,
  id,
  isInvalid = false,
  label,
  name,
  panelID,
  readOnly = false,
  visible = true,
  yes,
  no,
  rowID,
  rowFlags,
  rowField,
  onClose,
  onRowChange,
}) => {
  const dispatch = useDispatch();
  const checkRef = useRef<HTMLInputElement>(null);
  const hiddenRef = useRef<HTMLInputElement>(null);
  const {
    addToRowCommand,
    removeFromRowCommand,
    addToCommand,
    removeFromCommand,
    errors,
    windowData,
    cursor,
    settings,
    getCommand,
    formAperioLinks,
    getFormLayout,
    updateFlag,
  } = useContext(CommandContext);
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);
  const states: (boolean | undefined)[] = isTriState ? [undefined, false, true] : [false, true];
  const [state, setState] = useState(isTriState ? defaultValue : defaultValue === true);
  const valueInServerFormatRef = useRef<string>('');

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    // set tabindex
    if (checkRef.current) {
      const scrollInfo = XT.getScrollInfo(checkRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  const getValueAndSetDirtyFlag = (checkRef: any): any => {
    let _dirtyflag = +(checkRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
    setDirtyFlag(_dirtyflag);
    let _value = (rowID ? defaultValue : XT.getValueFromWindow({ data: windowData }, panelID, name)) || ' ';
    return { _value, _dirtyflag };
  };

  useEffect(() => {
    if (checkRef && checkRef.current) {
      const values = getValueAndSetDirtyFlag(checkRef);
      let _value = values._value;
      let _dirtyflag = values._dirtyflag;
      let boolValue = Formatters.BooleanFormatter.in(
        _value || ' ',
        attributes.value || yes,
        attributes.deselctedvalue || no,
      );
      setState(isTriState ? boolValue : boolValue === true);
      if (isTriState) checkRef.current.indeterminate = boolValue === undefined;
      let stringValue = Formatters.BooleanFormatter.out(
        state === true,
        attributes.value || yes,
        attributes.deselctedvalue || no,
        state === undefined,
      );
      if (boolValue === undefined && !isTriState) {
        updateFlag(_dirtyflag, false);
        if (!rowID) addToCommand(panelID, name, stringValue, -1);
      }
    }
  }, []);

  useEffect(() => {
    if (checkRef.current?.closest('.editable-cell')) {
      checkRef?.current?.focus();
    }
  }, [checkRef]);

  useEffect(() => {
    if (checkRef && checkRef.current) {
      const values = getValueAndSetDirtyFlag(checkRef);
      let _value = values._value;
      let _dirtyflag = values._dirtyflag;
      let reloaded = rowID
        ? XT.getReloadedFromWindowRow({ data: windowData }, panelID, rowID, name)
        : XT.getReloadedFromWindow({ data: windowData }, panelID, name);
      let boolValue = Formatters.BooleanFormatter.in(
        _value || ' ',
        attributes.value || yes,
        attributes.deselctedvalue || no,
      );
      if (reloaded === true || reloaded === undefined) {
        setState(isTriState ? boolValue : boolValue === true);
        if (isTriState) checkRef.current.indeterminate = boolValue === undefined;
        let stringValue = Formatters.BooleanFormatter.out(
          state === true,
          attributes.value || yes,
          attributes.deselctedvalue || no,
          state === undefined,
        );
        valueInServerFormatRef.current = stringValue;
        if (boolValue === undefined && !isTriState) {
          updateFlag(_dirtyflag, false);
          if (!rowID) addToCommand(panelID, name, stringValue, -1);
        }
      }
    }
  }, [windowData]);

  const refAperioViewInfo = useRef<
    { event: AperioViews.Link.Event; createCommand: (windowData: any) => void } | undefined
  >(undefined);
  useEffect(() => {
    // Initialize
    // ==========
    const dispatchUpdateContext = (aperioViewCommand: AperioViewCommand | undefined) => {
      dispatch({ type: WindowActions.UPDATE_CONTEXT, payload: { aperioViewCommand } });
    };
    const setAperioViewCommand = (link: AperioViews.Link, windowData: any) => {
      const aperioViewCommand = AperioView.getCommandBase(
        link,
        getCommand(),
        windowData,
        getFormLayout(),
        settings?.regionals,
      );
      dispatchUpdateContext(aperioViewCommand);
    };
    let onFocusInfo: { timestamp: Date; serverValue: string } | undefined;

    // Get event info
    // ==============
    const eventInfo =
      rowID || !checkRef?.current // -->  not used in table as not yet supported
        ? undefined
        : AperioView.getFieldComponentEventInfo(name, panelID, formAperioLinks || []);

    // Only needed when an event is configured
    // ========================================
    if (eventInfo?.link) {
      // Grab instance
      const _check = checkRef.current!;
      // Create/add focus event listener
      const onFocus = () => {
        onFocusInfo = { timestamp: new Date(), serverValue: valueInServerFormatRef.current };
      };
      _check.addEventListener('focus', onFocus);

      // Initialize on focus info (if appropriate)
      if (document.activeElement === _check) onFocus();

      // Create debounced function to update the context
      const [submitCommand, cancel] = debounce(setAperioViewCommand, 300);

      // Set create command function to call debounced function only when needed
      const createCommand = (windowData: any) => {
        // --> Handle only on human interactions (filter out software interactions unless autocomplete)
        if (!onFocusInfo) {
          return;
        } else if (new Date().getTime() - onFocusInfo.timestamp.getTime() < 100) {
          return;
        }

        // --> For event type "updated", handle only if value changed
        if (
          eventInfo.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE &&
          onFocusInfo.serverValue === valueInServerFormatRef.current
        ) {
          return;
        }

        // --> Submit debounced function
        submitCommand(eventInfo.link, windowData);

        // --> Set focus info from current values (case ON_FIELD_UPDATE does not necessarily blur)
        onFocus();
      };

      // Set aperio view Info
      refAperioViewInfo.current = { event: eventInfo.event, createCommand };

      // Clean-up effect
      return () => {
        _check.removeEventListener('focus', onFocus);
        cancel();
        refAperioViewInfo.current = undefined;
        dispatchUpdateContext(undefined);
      };
    }
  }, [checkRef?.current, name, panelID, rowID, formAperioLinks, getCommand, dispatch, settings?.regionals]);
  const handleChange = (target: EventTarget) => {
    let currIndex = states.indexOf(state);
    currIndex++;
    currIndex %= states.length;
    setState(states[currIndex]);

    (target as HTMLInputElement).indeterminate = isTriState && states[currIndex] === undefined;

    const stringValue = Formatters.BooleanFormatter.out(
      states[currIndex] === true,
      attributes.value || yes,
      attributes.deselctedvalue || no,
      states[currIndex] === undefined,
    );
    valueInServerFormatRef.current = stringValue;
    if (refAperioViewInfo?.current?.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE) {
      // Remark: ON_FIELD_UPDATE implemented at value changed itself (so exclude it here!!)
      refAperioViewInfo.current.createCommand(windowData);
    }
    valueInServerFormatRef.current = stringValue;
    if (refAperioViewInfo?.current?.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE) {
      // Remark: ON_FIELD_UPDATE implemented at value changed itself (so exclude it here!!)
      refAperioViewInfo.current.createCommand(windowData);
    }
    if (onRowChange) onRowChange(stringValue);
    if (states[currIndex] !== defaultValue) {
      if (rowID) {
        addToRowCommand(panelID, rowID, name, stringValue, dirtyflag);
      } else addToCommand(panelID, name, stringValue, dirtyflag);
    } else {
      if (rowID) {
        removeFromRowCommand(panelID, rowID, name, dirtyflag);
      } else removeFromCommand(panelID, name, dirtyflag);
    }
  };

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled || readOnly) {
      return false;
    } else if (rowID) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={'custom-checkbox-container'}
      data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
      data-for="global"
      data-iscapture="true"
    >
      {onRowChange && (
        <div
          className={'checkbox-overlay'}
          onMouseDown={(e) => {
            if (onRowChange) {
              e.preventDefault();
            }
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            if (onRowChange) {
              handleChange(e.target);
            }
          }}
        ></div>
      )}
      <input data-event="ignore" data-inputtype="checkbox" ref={hiddenRef} hidden id={id} name={id} type="text" />
      <Form.Check
        autoFocus={isInvalid}
        data-invalid={isInvalid}
        data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
        data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
        tabIndex={!readOnly && !disabled && !onRowChange ? x + y : -1}
        data-tristate={isTriState === true}
        onBlur={() => {
          if (onClose && rowID) onClose(true);
          if (refAperioViewInfo?.current?.event.type === AperioViews.EventTypes.ON_FIELD_BLUR) {
            // Remark: ON_FIELD_UPDATE implemented at value changed itself (so exclude it here!!)
            refAperioViewInfo.current.createCommand(windowData);
          }
        }}
        onChange={(e) => {
          handleChange(e.target);
        }}
        id={'checkbox-' + id}
        isInvalid={errors && isInvalid}
        label={label}
        disabled={disabled || readOnly}
        hidden={!visible}
        className={className}
        checked={state}
        ref={checkRef}
        custom={true}
        name={name}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (
            ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
            LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
          ) {
            XT.selectNextElement();
          }
        }}
      />
    </div>
  );
};

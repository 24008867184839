import { DialogActions, DialogContent, DialogTitle, Divider, Paper, Skeleton, Tooltip } from '@mui/material';
import { Button, Icon, IconButton, Text, useIptorTranslation } from '@iptor/base';
import { useEffect, useRef } from 'react';
import { useOpenAPIMethod } from '@iptor/business';
import useClickOutside from '../../../hooks/useClickOutside';

export const TextIcon = ({
  order,
  open,
  orderLine,
  handleTextOpen,
  handleOpenFullDialog,
}: {
  order: number;
  orderLine?: number;
  open: boolean;
  handleTextOpen: (isOpen: boolean) => void;
  handleOpenFullDialog: () => void;
}) => {
  const ref = useRef(null);
  useClickOutside(ref, () => handleTextOpen(false));
  const { response, loading, execute } = useOpenAPIMethod(
    'internal.v1.base-orders',
    orderLine ? 'GET /salesOrders/{order}/lines/{orderLine}/texts' : 'GET /salesOrders/{order}/texts',
  );
  const { t } = useIptorTranslation();

  useEffect(() => {
    if (open) {
      execute({
        order,
        orderLine,
        limit: 1,
      });
    }
  }, [open]);

  return (
    <Tooltip
      title={
        open ? (
          <Paper
            ref={ref}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              minWidth: '400px',
            })}
          >
            <DialogTitle
              display="flex"
              alignItems="center"
              gap={1}
              sx={(theme) => ({ color: theme.palette.text.secondary })}
            >
              <Icon icon="informationCircle" color="primary" size="L" />
              <b>{t('sales_orders:ORDERTEXT_text')}</b>
              <Icon
                icon="cancel01"
                onClick={() => handleTextOpen(false)}
                style={{ marginLeft: 'auto' }}
                sx={(theme) => ({
                  '&:hover': {
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                  },
                })}
              />
            </DialogTitle>
            <Divider
              sx={(theme) => ({
                opacity: 0.6,
                borderWidth: '1px',
                marginX: theme.spacing(3),
              })}
            />
            <DialogContent sx={(theme) => ({ paddingBottom: 0, color: theme.palette.text.secondary })}>
              {loading || !response ? (
                <Skeleton variant="rounded" height={100} />
              ) : (
                <Text
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {(response?.data?.data as any)?.items[0]?.longText
                    ? (response?.data?.data as any)?.items[0]?.longText
                    : t('sales_orders:ORDERTEXT_orderHasNoText')}
                </Text>
              )}
            </DialogContent>
            <DialogActions sx={(theme) => ({ justifyContent: 'flex-start', paddingX: theme.spacing(1.5) })}>
              <Button
                variant="text"
                color="info"
                iconRight="chevronDown"
                sx={(theme) => ({
                  padding: theme.spacing(1.5),
                })}
                onClick={handleOpenFullDialog}
              >
                {t('sales_orders:ORDERTEXT_readMore')}
              </Button>
            </DialogActions>
          </Paper>
        ) : null
      }
      open={open}
      onClose={() => handleTextOpen(false)}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
      slotProps={{
        arrow: {
          sx: (theme) => ({
            color: theme.palette.background.default,
          }),
        },
        tooltip: {
          sx: (theme) => ({
            backgroundColor: theme.palette.background.default,
            padding: 0,
            margin: 1,
            minWidth: 400,
          }),
        },
      }}
      PopperProps={{
        sx: {
          zIndex: 10,
        },
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 20,
            },
          },
        ],
      }}
    >
      <IconButton
        icon="txt02"
        variant="text"
        size="small"
        onClick={() => handleTextOpen(true)}
        tooltipText={t('sales_orders:ORDERTEXT_orderText')}
      />
    </Tooltip>
  );
};

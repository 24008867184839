import hotkeys from 'hotkeys-js';
import React, {
  ChangeEvent,
  Component,
  Context,
  createContext,
  createRef,
  FocusEvent,
  MouseEvent,
  RefObject,
  useContext,
} from 'react';
import { ButtonGroup, Col, Container, Dropdown, DropdownButton, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Action, Button, ButtonProps } from '../../components/Button';
import { Column, ContextItem, ContextOption } from '../../components/DataTable';
import { Tabs, TabsProps } from '../../components/Tab';
import { Command } from '../../framework/base/command';
import { Knot, KnotElement } from '../../framework/base/models/knot';
import { WindowManger } from '../../framework/base/windowManager';
import { Constraint } from '../../framework/parsers/constraint';
import { Program } from '../../types/program';
import { Panel, PanelProps } from './Panel';
import { XT } from '../../framework/handlers/xt';
import { Group } from './Group';
import { ErrorMessage, ErrorMessages } from '../../components/ErrorMessages';
import { Localization } from '../../framework/localization/Localization';
import { Attachments } from '../../components/Attachment';
import { WindowActions } from '../../types/actionTypes';
import { ContextArea } from './ContextArea';
import { Toolbar } from './Toolbar';
import { Calendar } from '../../components/Calendar';
import { CommandContext } from '../../framework/parsers/layout/types';
import { Title } from '../../components/Title';
import { Edit } from '../../components/Edit';
import ReactTooltip from 'react-tooltip';
import { Exporter } from '../../components/Exporter';
import {
  exportToCsv,
  exportToFile,
  exportToXlsx,
  exportType,
  generateRows_ISO,
  generateRows_XLSX,
} from '../../components/helpers/exportUtils';
import { TooltipContext } from '../../helpers/TooltipContext';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { AperioViews } from '../../types/AperioViews';
import { ConfirmationDialogOptions, debounce } from '@iptor/base';
import { AperioView } from '../../framework/handlers/aperioView';
import { enquiryHotKeys } from '../../helpers/hotkeys';
import { LoggerContext } from '../../logger/react/LoggerProvider';
import { ClientLogger } from '../../logger/ClientLogger';
import { showDialog } from 'framework/base/dialogUtils';

const whm = 1.32;
const wwm = 1.32;

type FormatName = {
  id: string;
  opcode: string;
  open_level: string;
  order: string;
};

export type WindowProps = {
  id: string;
  application?: string;
  program: Program;
  title: string;
  icon?: string;
  //layouts: Record<string, any>; //Record<id, layout> one record for each layout fetched in current window (Can be stored at manager level if layouts do not change)
  layout: Record<string, any>;
  data: Record<string, any>;
  panelMaster?: Record<string, Record<string, any>>;
  actionHandler?: Function;
  startLoading?: Function;
  endLoading?: Function;
  interact?: Function;
  loadContext?: Function;
  loadActions?: Function;
  setActiveAperioLinks?: Function;
  contextDefinition?: Record<string, Record<string, Record<string, any>>>; //{formID: {panelId: {contextId: {menu}}}}
  actionDefinitions?: Action[];
  dialog?: WindowProps;
  isDialog?: boolean;
  driverList?: Record<string, string>;
  tabSequence?: number[];
  tabSequenceItem?: number[];
  tabSequenceBP?: number[];
  activeDriver?: string;
  quickLaunch?: Function;
  localization: Localization;
  attachments?: any[];
  settings?: Record<string, any>;
  contextArea?: string;
  attachmentKeys?: any;
  updateContext?: Function;
  startExport?: (windowID: string, title: string, cb: (data: Record<string, any>) => void) => void;
  stopExport?: (windowID: string, abort?: boolean) => void;
  CloseWindow?: Function;
  restoreFocusActiveWindow?: () => void;
  onModalWindowShow?: () => void;
  enquiries?: any;
  showDialog?: (options: ConfirmationDialogOptions) => void;
  closeDialog?: () => void;
};

type WindowState = {
  //TODO: Rename window?
  title: Record<string, string[]>;
  command?: Command;
  exporting: boolean;
  rowCount: number;
  formAperioLinks: AperioViews.Link[];
};

export const ExportContext = createContext({ rows: 0, show: false });

class WindowComponent extends Component<WindowProps> {
  state: WindowState;
  resizerRef: RefObject<HTMLDivElement>;
  command: Command = new Command('form', new Knot(KnotElement.INFDS));
  rows: string[] = [];
  flags: boolean[] = [];
  lastExecutedPanel?: string;
  lastRowActions: Record<string, Record<string, Record<string, any>>> = {}; // [formID][panel][row] = {id, value}
  protectedPanel?: string;
  contextLoaderAction?: string;
  lastRows: Record<string, Record<string, Record<string, Record<string, string>>>> = {}; // [formID][panelID][rowID][id]
  dirtyList: Record<string, Record<string, number>> = {}; //[flag]: [input_id]: count;
  static contextType = TooltipContext;
  context!: React.ContextType<typeof TooltipContext>;
  loggerContext?: React.ContextType<typeof LoggerContext>;
  _logger: ClientLogger | undefined;

  getCursor() {
    for (let i in this.props.data.form.panel) {
      let panel = this.props.data.form.panel[i];
      let col = panel?.ctrl?.find((c: any) => ['XXCSCO', 'ZZCSCL'].indexOf(c.$.id) > -1);
      let line = panel?.ctrl?.find((c: any) => ['XXCSLI', 'ZZCSRW'].indexOf(c.$.id) > -1);
      if (col && line) {
        return `${col.$.value},${line.$.value}`;
      }
    }
    return '';
  }

  handleLoggerContext(contextVal: React.ContextType<typeof LoggerContext>) {
    if (!this.loggerContext && contextVal) {
      this.loggerContext = contextVal;
    }
  }

  public get logger(): ClientLogger {
    if (!this._logger && this.loggerContext) {
      this._logger = this.loggerContext.getLogger('Window');
    }
    return this._logger!;
  }

  errHandler(err: any) {
    // --> see IMPORTANT_REMARK_20240324
    if (err?.response?.status === 300) {
      // //Show popup yes/no, on yes => send this.command again
      // let confirmation = window.confirm(err?.response?.data);
      // if (confirmation) {
      //   this.props.startLoading?.();
      //   this.sendCommand(true);
      // }

      showDialog({
        title: Localization.instance.getString('TXT_AreYouSure'),
        message: err?.advanced ? err?.response?.data?.text : err?.response?.data,
        completeScreenBlock: true,
        restrictOutsideClick: true,
        hideCancelButton: true,
        confirmText: Localization.instance.getString('TXT_OK'),
        overlayContainerId: 'legacy',
        onConfirm: async () => {
          this.props.startLoading?.();
          await this.sendCommand(true);
          // Perform your action here
        },
      });
    } else if (err?.response?.status === 320) {
      //Handler prompt for print attributes
      let row = this.props.data.form.panel
        .find((p: any) => p.$.id === 'DetailPanel')
        ?.row?.find(
          (r: any) =>
            `${Object.keys(this.lastRowActions[this.props.data.form.$.id]['DetailPanel'] || {})?.[0]}` === r.$.id,
        );
      /*<Attributes Copies="1" EndPage="0" SaveFile="*NO" StartPage="0"/>
       * <ctrl id="JOBINFO_startPage" value="1"/>
       * <ctrl id="JOBINFO_endPage" value="4"/>
       * <ctrl id="JOBINFO_copies" value="1"/>
       * <ctrl id="JOBINFO_save" value="*YES"/>
       * <ctrl id="JOBINFO_release" value="true"/>
       * */
      let pages = row?.ctrl?.find((c: any) => c?.$?.id?.toLowerCase() === 'pages')?.$?.value || '1';
      let isHeld =
        row?.ctrl?.find((c: any) => c?.$?.id?.toLowerCase() === 'status')?.$?.value?.toLowerCase() === '*held';
      // let copies = row?.ctrl?.find((c: any) => c?.$?.id?.toLowerCase() === 'RemainingCopies')?.$?.value || '1';
      let attributes = err?.response?.data?.Attributes?.$;
      showDialog({
        title: <h5>{Localization.instance.getString('PRINTING_EditPrintParameters')}</h5>,
        message: (
          <Row className={'text-left'}>
            <Col sm={8}>
              <p>{Localization.instance.getString('PRINTING_PrintRange')}</p>
            </Col>
            <Col sm={4}>
              <p>{Localization.instance.getString('PRINTING_Copies')}</p>
            </Col>
            <Col sm={4}>
              <input
                defaultValue={+attributes.StartPage || 1}
                min={1}
                max={+pages}
                style={{ width: '100%' }}
                type={'number'}
                id={'JOBINFO_startPage'}
              />
            </Col>
            <Col sm={4}>
              <input
                defaultValue={+attributes.EndPage || +pages}
                min={1}
                max={+pages}
                style={{ width: '100%' }}
                type={'number'}
                id={'JOBINFO_endPage'}
              />
            </Col>
            <Col sm={4}>
              <input
                defaultValue={+attributes.Copies}
                style={{ width: '100%' }}
                type={'number'}
                id={'JOBINFO_copies'}
              />
            </Col>
            <Col lg={12}>
              <p>{Localization.instance.getString('PRINTING_OtherParameters')}</p>
            </Col>
            <Col lg={12}>
              <label>
                <input
                  type={'checkbox'}
                  name={'JOBINFO_save'}
                  id={'JOBINFO_save'}
                  defaultChecked={attributes?.SaveFile === '*YES'}
                />
                {Localization.instance.getString('PRINTING_KeepAfterPrinting')}
              </label>
            </Col>
            <Col lg={12}>
              <label>
                <input type={'checkbox'} name={'JOBINFO_release'} id={'JOBINFO_release'} disabled={!isHeld} />
                {Localization.instance.getString('PRINTING_ReleaseSpooledFile')}
              </label>
            </Col>
          </Row>
        ),
        completeScreenBlock: true,
        restrictOutsideClick: true,
        confirmText: Localization.instance.getString('Yes'),
        cancelText: Localization.instance.getString('No'),
        overlayContainerId: 'legacy',
        onConfirm: async () => {
          let save = (document.getElementById('JOBINFO_save') as HTMLInputElement)?.checked ? '*YES' : '*NO';
          let release = (document.getElementById('JOBINFO_release') as HTMLInputElement)?.checked ? 'true' : 'false';
          this.addToCommand(
            'SearchPanel',
            'JOBINFO_copies',
            (document.getElementById('JOBINFO_copies') as HTMLInputElement)?.value,
            -1,
          );
          this.addToCommand(
            'SearchPanel',
            'JOBINFO_startPage',
            (document.getElementById('JOBINFO_startPage') as HTMLInputElement)?.value,
            -1,
          );
          this.addToCommand(
            'SearchPanel',
            'JOBINFO_endPage',
            (document.getElementById('JOBINFO_endPage') as HTMLInputElement)?.value,
            -1,
          );
          this.addToCommand('SearchPanel', 'JOBINFO_save', save, -1);
          this.addToCommand('SearchPanel', 'JOBINFO_release', release, -1);
          this.sendCommand(true);
        },
      });
    } else if (err?.response?.status === 350) {
      this.initCommand(this.props);
      showDialog({
        message: err?.response?.data,
        completeScreenBlock: true,
        restrictOutsideClick: true,
        hideCancelButton: true,
        confirmText: Localization.instance.getString('TXT_OK'),
        overlayContainerId: 'legacy',
      });
    } else if (err?.advanced) {
      // Do not execute next cases anymore !!
    } else if (err?.response?.status === 500) {
      WindowManger.errorHandler(err);
    } else {
      showDialog({
        message: Localization.instance.getString('TXT_UNKNOWN_ERROR'),
        completeScreenBlock: true,
        restrictOutsideClick: true,
        hideCancelButton: true,
        confirmText: Localization.instance.getString('TXT_OK'),
        overlayContainerId: 'legacy',
      });
    }
    this.lastRowActions[this.props.data.form.$.id] = {};
  }

  initCommand(props: WindowProps) {
    if (props.data.form.$.id === 'MSG') return;
    if (!this.props.dialog) hotkeys.unbind('*', props.id + '-dialog');
    this.contextLoaderAction = '';
    let data = props.data.form;
    this.dirtyList = {};
    this.command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    for (let index in data.panel) {
      let panel = data.panel[index];
      if (panel.$.id.indexOf('MSG') > -1) continue;
      let switchoff = this.getLayout(panel.$.id)?.$?.switchoff;
      let flags = (data.panel[data.panel.length - 1].$.flags || '').split('').map((x: string) => x === '1');
      if (switchoff) {
        let matches = switchoff.match(/\d{2}/g);
        matches?.forEach((match: string) => {
          flags[+match - 1] = false;
        });
      }
      let dirtyFlags = this.searchXML('dirtyflag');
      dirtyFlags.forEach((flag: any) => (flags[+flag - 1] = false));
      this.command.childSet(
        panel.$.id,
        new Knot(KnotElement.PANEL, { id: panel.$.id, flags: flags.map((x: boolean) => (x ? '1' : '0')).join('') }),
      );
    }
    // let ddspos = e?.target?.parentNode?.parentElement?.getAttribute('data-ddspos') || '';
    let defaultPanel = props.data?.form?.panel?.find((x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1);
    let panel = defaultPanel?.$.id || '';
    this.command.setPanel(panel);
    // this.command.setCursor(ddspos);
  }

  setScope(ddspos: string, rowOffset?: number): void {
    let rowOfset = rowOffset || 0;
    this.command.setCursor(ddspos, rowOfset);
  }

  addToCommand(panelID: string, id: string, value: string, dirtyflag: number): void {
    if (dirtyflag !== -1) {
      this.dirtyList[dirtyflag] = { ...this.dirtyList[dirtyflag], [id]: (this.dirtyList[dirtyflag]?.[id] || 0) + 1 };
    }
    if (panelID === 'dummy') {
      this.command?.childDel('dummy');
      this.command?.childSet(
        id,
        new Knot(KnotElement.CTRL, {
          id: id,
          value: value
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"'"/g, '&apos;')
            .replace(/"/g, '&quot;'),
        }),
      );
      this.command.updateFlag(dirtyflag, true);
    } else {
      this.command.children[panelID]?.childSet(
        id,
        new Knot(KnotElement.CTRL, {
          id: id,
          value: value
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"'"/g, '&apos;')
            .replace(/"/g, '&quot;'),
        }),
      );
      this.command.updateFlag(dirtyflag, true);
    }
  }

  addToRowCommand(panelID: string, rowID: string, id: string, value: string, dirtyflag: number, flags?: string): void {
    if (dirtyflag !== -1) {
      this.dirtyList[dirtyflag] = { ...this.dirtyList[dirtyflag], [id]: (this.dirtyList[dirtyflag]?.[id] || 0) + 1 };
    }
    if (panelID === 'dummy') {
      this.command?.childDel('dummy');
      if (!this.command.children?.[rowID]) {
        this.command.childSet(rowID, new Knot(KnotElement.ROW, { id: rowID, flags: 'null' }));
      }
      this.command.children?.[rowID]?.childSet(
        id,
        new Knot(KnotElement.CTRL, {
          id: id,
          value: value
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"'"/g, '&apos;')
            .replace(/"/g, '&quot;'),
        }),
      );
      this.command.updateFlag(dirtyflag, true);
    } else {
      if (!this.command.children[panelID]?.children?.[rowID]) {
        this.command.children[panelID]?.childSet(
          rowID,
          new Knot(KnotElement.ROW, {
            id: rowID,
            flags: this.command.children[panelID].attributeGet('flags') || 'null',
          }),
        );
      }
      this.command.children[panelID]?.children?.[rowID]?.childSet(
        id,
        new Knot(KnotElement.CTRL, {
          id: id,
          value: value
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"'"/g, '&apos;')
            .replace(/"/g, '&quot;'),
        }),
      );
      if (!this.lastRows[this.props.data.form.$.id]) {
        this.lastRows[this.props.data.form.$.id] = {};
      }
      if (!this.lastRows[this.props.data.form.$.id][panelID]) {
        this.lastRows[this.props.data.form.$.id][panelID] = {};
      }
      if (!this.lastRows[this.props.data.form.$.id][panelID][rowID]) {
        this.lastRows[this.props.data.form.$.id][panelID][rowID] = {};
      }
      this.lastRows[this.props.data.form.$.id][panelID][rowID][id] = value
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"'"/g, '&apos;')
        .replace(/"/g, '&quot;');
      this.command.updateFlag(dirtyflag, true);
    }
  }

  setSelectedRows(formID: string, panelID: string, tableID: string, ids: string[]): void {
    this.rows = ids;
  }

  setSelectedOption(option: ContextOption, panelID: string): void {
    let command = this.command;
    if (option.action && option.action.name === 'RemotePrompt') {
      for (let i in this.props.data.form.panel) {
        let panel = this.props.data.form.panel[i];
        if (panel.$.id.indexOf('MSG') > -1) continue;
        let retfld = panel.ctrl.find((x: any) => x.$.id === option.action?.returnfield);
        let retpanel = panel.ctrl.find((x: any) => x.$.id === option.action?.returnpanel);
        let rowfld = panel.ctrl.find((x: any) => x.$.id === option.action?.field);
        if (retfld) {
          command?.children[panel.$.id]?.childSet(
            retfld.$.id,
            new Knot(KnotElement.CTRL, {
              id: retfld.$.id,
              value: option.key,
            }),
          );
        }
        if (retpanel) {
          command?.children[panel.$.id]?.childSet(
            retpanel.$.id,
            new Knot(KnotElement.CTRL, {
              id: retpanel.$.id,
              value: panelID,
            }),
          );
        }
        if (rowfld) {
          command?.children[panel.$.id]?.childSet(
            rowfld.$.id,
            new Knot(KnotElement.CTRL, {
              id: rowfld.$.id,
              value: this.rows[0] + '',
            }),
          );
        }
      }
    }
    if (option.type === 'option') {
      let flags = command?.children[panelID].attributeGet('flags');
      let nonZeroRows = this.getPanelData(panelID).row.filter((r: any) =>
        r.ctrl.find((x: any) => x.$.id === option.key && x.$.value.trim() !== ''),
      );
      for (let i in nonZeroRows) {
        let _row = nonZeroRows[i];
        let row = new Knot(KnotElement.ROW, { id: _row.$.id, flags: flags || '' });
        row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: '' }));
        command?.children[panelID].childSet(_row.$.id, row);
      }

      for (let i in this.rows) {
        let id = +this.rows[i] + '';
        let row =
          command?.children[panelID].children?.[id] ||
          new Knot(KnotElement.ROW, {
            id: id,
            flags: flags || '',
          });
        row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: option.value }));
        command?.children[panelID].childSet(id, row);
      }
      //TODO: Confirm if all dirtyflags are set to zero similar to action flags
      let dirty = +this.props.layout.screen?.form[0]?.$?.dirtyflag || -1;
      command?.updateFlag(dirty, false);
      this.setState((state) => {
        return { ...state, command };
      });
      this.actionHandler(option.action || { event: 'DEFAULT' });
    } else {
      this.actionHandler(option.action || { command: option.value, event: option.value });
    }
  }

  removeFromCommand(panelID: string, id: string, dirtyflag: number): void {
    this.command.children[panelID]?.childDel(id);
    // this.command.updateFlag(dirtyflag, false);
    if (dirtyflag !== -1) {
      this.dirtyList[dirtyflag] = { ...this.dirtyList[dirtyflag], [id]: (this.dirtyList[dirtyflag]?.[id] || 0) - 1 };
      if (this.dirtyList[dirtyflag][id] < 1) {
        delete this.dirtyList[dirtyflag][id];
        if (Object.keys(this.dirtyList[dirtyflag]).length === 0) this.command.updateFlag(dirtyflag, false);
      }
    }
  }

  removeFromRowCommand(panelID: string, rowID: string, id: string, dirtyflag: number): void {
    this.command.children[panelID]?.children?.[rowID]?.childDel(id);
    if (Object.keys(this.command.children[panelID]?.children?.[rowID]?.children || {}).length === 0) {
      this.command.children[panelID]?.childDel(rowID);
    }
    // this.command.updateFlag(dirtyflag, false);

    if (dirtyflag !== -1) {
      this.dirtyList[dirtyflag] = { ...this.dirtyList[dirtyflag], [id]: (this.dirtyList[dirtyflag]?.[id] || 0) - 1 };
      if (this.dirtyList[dirtyflag][id] < 1) {
        delete this.dirtyList[dirtyflag][id];
        if (Object.keys(this.dirtyList[dirtyflag]).length === 0) this.command.updateFlag(dirtyflag, false);
      }
    }
  }

  async sendCommand(novalidate: boolean = false) {
    this.props.startLoading?.();
    await this.props.interact?.(
      this.props.id,
      this.command,
      this.props.endLoading,
      this.scopeHandler,
      this.errHandler,
      novalidate,
    );
  }

  updateFlag(flag: number, setTo: boolean): void {
    this.command.updateFlag(flag, setTo);
  }

  clearFlag(flag: number): void {
    this.updateFlag(flag, false);
  }

  setAction(action: Action): void {
    //Add reset flags
    if (!!action.event || !!action.command) this.command.setAction(action.event || action.command || '');
  }

  private _attachments: Attachments = new Attachments([]);

  protected get attachments(): Attachments {
    this._attachments.setList(this.props.attachments || []);
    return this._attachments;
  }

  shouldComponentUpdate(nextProps: Readonly<WindowProps>, nextState: Readonly<WindowState>, nextContext: any): boolean {
    // let newTitle = !(
    //   nextState.title?.every((t: string) => this.state.title?.includes(t)) &&
    //   this.state.title?.every((t: string) => nextState.title?.includes(t))
    // );
    let newTitle = !(
      Object.keys(nextState.title).every((panel: string) =>
        nextState.title[panel]?.every((t: string) => this.state.title[panel]?.includes(t)),
      ) &&
      Object.keys(this.state.title).every((panel: string) =>
        this.state.title[panel]?.every((t: string) => nextState.title[panel]?.includes(t)),
      )
    );
    if (this.props.data !== nextProps.data) {
      this.setState((state) => {
        return { ...state, title: undefined };
      });
    }
    if (!this.lastRowActions[nextProps.data.form.$.id]) this.lastRowActions[nextProps.data.form.$.id] = {};
    return (
      this.props !== nextProps ||
      newTitle ||
      this.state.exporting !== nextState.exporting ||
      this.state.rowCount !== nextState.rowCount
    );
  }

  componentDidUpdate(prevProps: Readonly<WindowProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.data !== prevProps.data) {
      this.initCommand(this.props);
      if (this.lastRows?.[this.props.data.form.$.id]) {
        Object.keys(this.lastRows?.[this.props.data.form.$.id]).forEach((panelID: any) => {
          Object.keys(this.lastRows?.[this.props.data.form.$.id]?.[panelID]).forEach((rowID) => {
            if (Object.keys(this.lastRows?.[this.props.data.form.$.id]?.[panelID] || {}).length > 1)
              Object.keys(this.lastRows?.[this.props.data.form.$.id]?.[panelID]?.[rowID]).forEach((id) => {
                // let oldVal = this.lastRows?.[this.props.data.form.$.id]?.[panelID]?.[rowID]?.[id];
                let newVal = XT.getValueFromWindowRow({ data: this.props.data }, panelID, rowID, id);
                let reloaded = XT.getReloadedRowFromWindowRow({ data: this.props.data }, panelID, rowID, id); // new function return true if panel is reloaded or row is reloaded or ctrl is reloaded
                if (newVal !== undefined && (reloaded === true || reloaded === undefined)) {
                  // or row is reloaded or panel is reloaded
                  delete this.lastRows?.[this.props.data.form.$.id]?.[panelID]?.[rowID]?.[id];
                }
              });
            if (Object.keys(this.lastRows?.[this.props.data.form.$.id]?.[panelID]?.[rowID]).length === 0) {
              delete this.lastRows?.[this.props.data.form.$.id]?.[panelID]?.[rowID];
            }
          });
          if (Object.keys(this.lastRows?.[this.props.data.form.$.id]?.[panelID]).length === 0) {
            delete this.lastRows?.[this.props.data.form.$.id]?.[panelID];
          }
        });
        if (Object.keys(this.lastRows?.[this.props.data.form.$.id]).length === 0) {
          delete this.lastRows?.[this.props.data.form.$.id];
        }
      }
      setTimeout(() => ReactTooltip.rebuild(), 500);
    }
    if (this.props.enquiries !== prevProps.enquiries) {
      this.handleEnquiryTemplateChanged();
    }
    if (this.props.data !== prevProps.data || this.props.settings?.regionals !== prevProps.settings?.regionals) {
      this.handleAperioView();
    }
  }

  protected get messages(): ErrorMessage[] | undefined {
    let messagesPanel = this.props.data?.form?.panel?.find((x: any) => x.$.id.indexOf('MSG') > -1);
    if (messagesPanel && messagesPanel.message_list && messagesPanel.message_list.length > 0) {
      let messages: ErrorMessage[] = [];
      for (let i in messagesPanel.message_list) {
        let message = messagesPanel.message_list[i];
        if (message.message) {
          for (let j in message.message) {
            let mess = message.message[j];
            messages.push({
              title: mess.second_level_message ? mess.$.text : '',
              body: mess.second_level_message
                ? mess.second_level_message[0].$.text.replaceAll('&N', '\n')
                : mess.$.text,
            });
          }
        }
      }
      return messages;
    }
    return undefined;
  }

  protected get layout(): Record<string, any> {
    let _layout: Record<string, any> = {};
    this.props.layout.screen.form[0].panel?.forEach((panel: any) => {
      _layout[panel.$.id] = panel;
      if (panel.$.sflcontrolfmt) _layout[panel.$.sflcontrolfmt] = panel;
    });
    return _layout;
  }

  protected get overlapping(): Record<string, any> {
    let _overlapping: Record<string, any> = {};
    this.props.layout.screen?.overlapping?.[0]?.panel?.forEach((panel: any) => {
      _overlapping[panel.$.id] = panel.panel?.map((x: any) => x.$) || [];
    });
    return _overlapping;
  }

  protected get panels(): PanelProps[] {
    let flags = '';
    let initialPanel = '';
    let panelsData: Record<string, any> = {};
    let newPanels: Record<string, any> = {};
    let formatNames: Record<string, FormatName> = {};
    let panelIDs = this.props.data?.form?.panel?.map((pan: any) => pan.$.id);
    this.props.data?.form?.panel?.forEach((panel: any, index: number) => {
      panel.loadOrder = index;
      panelsData[panel.$.id] = panel;
      initialPanel = panel.$.id;
      flags = panel.$.flags || '';
    });
    Object.keys(panelsData).forEach((panID: string) => {
      if (!!panelsData[panID] && !panID.includes('MSG') && this.props.layout.screen.form[0].$?.id !== 'error') {
        newPanels[panID] = {
          attributes: { ...this.layout[panID].$ },
          data: panelsData[panID],
          flags: flags.split('').map((x: string) => x === '1'),
          id: panID,
          panelID: panID,
          layout: this.layout[panID],
          order: this.layout[panID].$.sequence,
          windowID: this.props.id,
          windowProps: this.props,
          isPanel: true,
        };
        if (this.overlapping?.[panID]?.find((x: any) => panelIDs.indexOf(x.id) > -1)) {
          delete newPanels[panID];
        } else this.lastExecutedPanel = panID;
      }
      panelIDs.shift();
    });
    this.flags = flags.split('').map((x: string) => x === '1');
    let executedPanel = this.props.data.form.screen_data?.[0]?.format_name?.find(
      (x: any) => x.$.opcode === 'EXFMT' || x.$.opcode === 'READ',
    );
    let protectedPanel = Object.values(newPanels).find(
      (p: PanelProps) =>
        (p.attributes.id === executedPanel?.$.id || p.attributes.sflcontrolfmt === executedPanel?.$.id) &&
        p.attributes.panelprotection === 'true',
    );
    this.protectedPanel = protectedPanel?.panelID;

    if (this.props.data.form.$.id === 'SpooledFileViewDialog') {
      let panID = 'DetailPanel';
      newPanels[panID] = {
        attributes: { ...this.layout[panID].$ },
        data: panelsData[panID],
        flags: flags.split('').map((x: string) => x === '1'),
        id: panID,
        panelID: panID,
        layout: this.layout[panID],
        order: this.layout[panID].$.sequence,
        windowID: this.props.id,
        windowProps: this.props,
        isPanel: true,
      };
    }

    return Object.values(newPanels)
      .map((x: any) => {
        x.flags = flags.split('').map((x: string) => x === '1');
        return x;
      })
      .sort((a: PanelProps, b: PanelProps) => +a.order - +b.order);
  }

  protected get actions(): any[] {
    let { actions } = XT.loadFunctionData(
      this.props,
      this.panels.map((panel: PanelProps) => panel.id),
    );
    return actions;
  }

  protected get panelEvents(): Record<string, Action> {
    let _actions: Record<string, Action> = {};
    // _actions[this.props.data.form.$.id] = {};
    this.props.layout.screen.form[0]?.actions?.[0]?.action?.forEach((action: any) => {
      let dconstraint = new Constraint(action.$.disableconstraints);
      if (!dconstraint.evaluate(this.flags, false)) _actions[action.$.event] = action.$;
    });
    let loaded = this.panels.sort((a: any, b: any) => a.data.loadOrder - b.data.loadOrder);
    loaded[loaded.length - 1]?.layout.actions?.forEach((actions: any) =>
      actions.action?.forEach((action: any) => {
        let dconstraint = new Constraint(action.$.disableconstraints);
        if (!dconstraint.evaluate(this.flags, false)) {
          if (!_actions[action.$.event] || (_actions[action.$.event] && !_actions[action.$.event]))
            _actions[action.$.event] = action.$;
          if (_actions[action.$.event] && action.$.text) _actions[action.$.event].text = action.$.text;
        }
      }),
    );
    // this.panels.forEach((panel: PanelProps) => {
    //   if (!!this.protectedPanel && this.protectedPanel !== panel.panelID) return;
    //   panel.layout.actions?.forEach((actions: any) =>
    //     actions.action?.forEach((action: any) => {
    //       let dconstraint = new Constraint(action.$.disableconstraints);
    //       if (!dconstraint.evaluate(this.flags, false)) {
    //         if (!_actions[action.$.event] || (_actions[action.$.event] && !_actions[action.$.event])) _actions[action.$.event] = action.$;
    //         if (_actions[action.$.event] && action.$.text) _actions[action.$.event].text = action.$.text;
    //       }
    //     })
    //   );
    // });
    return _actions;
  }

  // protected get loadedActions() {
  //   return this.props.actionDefinitions?.[this.props.data.form.$.id];
  // }

  //TODO: Check tab parsing outside the panels
  protected get tabs(): TabsProps | null {
    if (this.props.layout.screen.form[0].tab) {
      let currentTab: any = {};
      let found: boolean = false;
      let tabs = this.props.layout.screen.form[0].tab[0].tabpage.map((tabpage: any) => {
        found = !!tabpage.panel.find((pan: any) => this.panels.map((x: any) => x.id).indexOf(pan.$.id) > -1);
        tabpage.$.active = false;
        if (found) {
          currentTab = tabpage;
          currentTab.$.id = tabpage.panel[0].$.id;
          currentTab.$.active = true;
        }
        let tab = { ...tabpage.$ };
        tab.title = tabpage.$.text;
        tab.forward_actions = tabpage.forward_actions?.map((x: any) => x.action[0].$);
        tab.back_actions = tabpage.back_actions?.map((x: any) => x.action[0].$);
        tab.activate_actions = tabpage.activate_actions?.map((x: any) => x.action[0].$);
        return tab;
      });
      this.logger.childWithMeta({ function: 'tabs' }).debug('WindowComponent tabs', {
        tabs,
        ...currentTab?.$,
        layout: { group: [currentTab?.panel[0]] },
        windowProps: this.props,
        windowID: this.props.id,
        flags: this.flags,
        data: this.getPanelData(currentTab?.$?.id),
      });
      let flags = this.flags;
      tabs = tabs.filter((tabpage: any) => {
        let vConstraint = new Constraint(tabpage.visibleconstraints);
        return vConstraint.evaluate(flags, true);
      });
      if (currentTab.$) {
        return {
          id: currentTab.$.id,
          tabs: tabs,
          group: Group,
          tabSwitcher: this.updateTab,
          groupProps: {
            order: 0,
            style: {},
            isPanel: false,
            id: currentTab.$.id,
            panelID: currentTab.panel[0].$.id,
            panelEvents: this.panelEvents,
            contextLoader: this.contextLoader,
            contextMenuHandler: this.contextMenuHandler,
            actionHandler: this.actionHandler,
            promptHandler: this.promptHandler,
            attributes: { ...currentTab.$, text: undefined },
            layout: currentTab.panel[0],
            windowProps: this.props,
            windowID: this.props.id,
            flags: this.flags,
            data: this.getPanelData(currentTab.$.id),
            loadMore: this.loadMore,
            updateTitle: this.updateTitle,
          },
        };
      } else {
        return null;
      }
    }
    return null;
  }

  reload(getOnly?: boolean) {
    // if (this.props.isDialog) return;
    let _actions = this.searchXML('action').flat();
    let tempAction = _actions.find((x: any) => {
      if (x.$.event?.toLowerCase() === 'f5') {
        let dConstraint = new Constraint(x.$.disableconstraints);
        return !dConstraint.evaluate(this.flags, false);
      }
      return false;
    });

    XT.resetScrolling();

    // if (tempAction && tempAction.$.panelID === panelID) {
    //   this.actionHandler({ event: 'PAGEDOWN' });
    // }

    if (tempAction) {
      let data = this.props.data.form;
      let command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
      for (let index in data.panel) {
        let panel = data.panel[index];
        if (panel.$.id.indexOf('MSG') > -1) continue;
        let switchoff = this.getLayout(panel.$.id)?.$?.switchoff;
        // let flags = panel.$.flags.split('').map((x: string) => x === '1');
        let flags = (data.panel[data.panel.length - 1].$.flags || '').split('').map((x: string) => x === '1');
        if (switchoff) {
          let matches = switchoff.match(/\d{2}/g);
          matches?.forEach((match: string) => {
            flags[+match - 1] = false;
          });
        }
        let dirtyFlags = this.searchXML('dirtyflag');
        dirtyFlags.forEach((flag: any) => (flags[+flag - 1] = false));
        command.childSet(
          panel.$.id,
          new Knot(KnotElement.PANEL, { id: panel.$.id, flags: flags.map((x: boolean) => (x ? '1' : '0')).join('') }),
        );
      }
      // let ddspos = e?.target?.parentNode?.parentElement?.getAttribute('data-ddspos') || '';
      let defaultPanel = this.props.data.form.panel.find(
        (x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1,
      );
      let panel = defaultPanel?.$.id || '';
      command.setPanel(panel);
      // this.command.setCursor(ddspos);
      command.setAction('F05');
      if (tempAction.$.command && tempAction.$.command.length === 2) command.updateFlag(+tempAction.$.command, true);
      if (getOnly) return command.toString();
      this.props.startLoading?.();
      this.props.interact?.(this.props.id, command, this.props.endLoading, this.scopeHandler, this.errHandler);
    }
    // this.actionHandler({ event: 'PAGEDOWN' });
  }

  loadMore(
    panelID: string,
    EOF: boolean = false,
    columns: Column[] = [],
    rows: any[] = [],
    attributes: Record<string, any> = {},
    cb: Function,
  ) {
    // if (this.props.isDialog) return;
    let disableConstraint = '';
    let _actions = this.searchXML('action').flat();
    let tempAction = _actions.find((x: any) => {
      if (x.$.event?.toLowerCase() === 'pagedown') {
        let dConstraint = new Constraint(x.$.disableconstraints);
        disableConstraint = x.$.disableconstraints;
        return !dConstraint.evaluate(this.flags, false);
      }
      return false;
    });
    // if (tempAction && tempAction.$.panelID === panelID) {
    //   this.actionHandler({ event: 'PAGEDOWN' });
    // }

    this.searchXML('button')?.[0]?.forEach((button: any) => {
      if (button?.action?.[0]?.$?.event?.toLowerCase() === 'pagedown') {
        if (button?.$?.disableconstraints) {
          disableConstraint = button.$.disableconstraints;
          let dConstraint = new Constraint(disableConstraint);
          let disabled = dConstraint.evaluate(this.flags, false);
          if (disabled) tempAction = undefined;
        }
      }
    });

    if (tempAction && (tempAction.$.panelID ? tempAction.$.panelID === panelID : 1)) {
      let data = this.props.data.form;
      let command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
      for (let index in data.panel) {
        let panel = data.panel[index];
        if (panel.$.id.indexOf('MSG') > -1) continue;
        let switchoff = this.getLayout(panel.$.id)?.$?.switchoff;
        // let flags = panel.$.flags.split('').map((x: string) => x === '1');
        let flags = (data.panel[data.panel.length - 1].$.flags || '').split('').map((x: string) => x === '1');
        if (switchoff) {
          let matches = switchoff.match(/\d{2}/g);
          matches?.forEach((match: string) => {
            flags[+match - 1] = false;
          });
        }
        let dirtyFlags = this.searchXML('dirtyflag');
        dirtyFlags.forEach((flag: any) => (flags[+flag - 1] = false));
        command.childSet(
          panel.$.id,
          new Knot(KnotElement.PANEL, { id: panel.$.id, flags: flags.map((x: boolean) => (x ? '1' : '0')).join('') }),
        );
      }

      if (this.props.data.form.$.id === 'SpooledFiles') {
        this.command.children[panelID]?.childSet(
          'JOBINFO_NAME',
          new Knot(KnotElement.CTRL, {
            id: 'JOBINFO_NAME',
            value: '',
          }),
        );
        this.command.children[panelID]?.childSet(
          'JOBINFO_USER',
          new Knot(KnotElement.CTRL, {
            id: 'JOBINFO_USER',
            value: '',
          }),
        );
        this.command.children[panelID]?.childSet(
          'JOBINFO_NUMBER',
          new Knot(KnotElement.CTRL, {
            id: 'JOBINFO_NUMBER',
            value: '',
          }),
        );
      }
      // let ddspos = e?.target?.parentNode?.parentElement?.getAttribute('data-ddspos') || '';
      let defaultPanel = this.props.data.form.panel.find(
        (x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1,
      );
      let panel = defaultPanel?.$.id || '';
      command.setPanel(panel);
      // this.command.setCursor(ddspos);
      command.setAction('PAGEDOWN');
      if (tempAction.$.command && tempAction.$.command.length === 2) command.updateFlag(+tempAction.$.command, true);

      // if (!EOF) {
      this.props.startLoading?.();
      this.props.interact?.(
        this.props.id,
        command,
        this.props.endLoading,
        this.scopeHandler,
        this.errHandler,
        false,
        true,
        { type: attributes?.exportType },
        EOF,
        disableConstraint,
        {
          columns,
          rowCount: rows.length,
          attributes,
          panelID,
          cb,
          reload: this.reload,
          refreshCommand: EOF ? (this.reload(true) ?? '') : '',
        },
      );
      if (EOF) {
        this.props.startExport?.(this.props.id, this.props.title, () => {
          this.reload();
        });
      }
      return true;
    } else if (EOF) {
      this.props.startLoading?.();
      cb({ rows: rows }).finally(() => this.props.endLoading?.());
    }
    return false;
  }

  exportRows: Record<string, any>[] = [];

  async updateTab(actions: { toSend: Action; toIgnore?: Action }[]) {
    let command = this.command;
    for (let i in actions) {
      let action = actions[i].toSend;
      let _action = actions[i].toIgnore;
      if (_action) {
        //Set flag to false;
        command?.updateFlag(+(_action.command || -1), false);
      }
      action.event = action.event || action.command || '';
      await this.actionHandler(action, undefined, true);
    }
    this.props.endLoading?.();
  }

  constructor(props: WindowProps) {
    super(props);
    this.resizerRef = createRef<HTMLDivElement>();
    //this.getLayout = this.getLayout.bind(this);
    //this.getPanelFlags = this.getPanelFlags.bind(this);
    //this.getPanelData = this.getPanelData.bind(this);
    this.getGlobalButtons = this.getGlobalButtons.bind(this);
    this.scopeHandler = this.scopeHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    this.contextMenuHandler = this.contextMenuHandler.bind(this);
    this.contextLoader = this.contextLoader.bind(this);
    this.actionLoader = this.actionLoader.bind(this);
    this.promptHandler = this.promptHandler.bind(this);
    this.updateTab = this.updateTab.bind(this);
    this.actionLoader = this.actionLoader.bind(this);
    this.contextHandler = this.contextHandler.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.defaultActionHandler = this.defaultActionHandler.bind(this);

    this.addToRowCommand = this.addToRowCommand.bind(this);
    this.removeFromRowCommand = this.removeFromRowCommand.bind(this);
    this.addToCommand = this.addToCommand.bind(this);
    this.removeFromCommand = this.removeFromCommand.bind(this);
    this.setScope = this.setScope.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
    this.setAction = this.setAction.bind(this);
    this.initCommand = this.initCommand.bind(this);
    this.updateFlag = this.updateFlag.bind(this);
    this.clearFlag = this.clearFlag.bind(this);
    this.searchXML = this.searchXML.bind(this);
    this.setSelectedRows = this.setSelectedRows.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.getCursor = this.getCursor.bind(this);
    this.reload = this.reload.bind(this);
    this.errHandler = this.errHandler.bind(this);
    this.sendCustomCommand = this.sendCustomCommand.bind(this);
    this.getFormLayout = this.getFormLayout.bind(this);
    this.getCommand = this.getCommand.bind(this);
    this.handleAperioView = this.handleAperioView.bind(this);
    this.handleEnquiryTemplateChanged = this.handleEnquiryTemplateChanged.bind(this);
    this.setModalWidth = this.setModalWidth.bind(this);

    this.initCommand(props);

    this.state = {
      title: {},
      command: undefined,
      exporting: false,
      rowCount: 0,
      formAperioLinks: [],
    };
  }

  updateTitle = (title: string[], panelID: string) => {
    //Store panelID with title. Updated all calls to include panelID
    //If state is empty or all new titles are not available in state
    if (
      (this.state.title[panelID] ?? []).length === 0 ||
      !title.every((t: string) => this.state.title[panelID]?.includes(t)) ||
      title.length === 0
    ) {
      this.setState((state: Readonly<WindowState>) => ({
        ...state,
        title: {
          ...state.title,
          [panelID]: title,
        },
      }));
    }
  };

  componentDidMount() {
    //if (this.isTableTooltipEnabled) ReactTooltip.rebuild();

    /*************************Resizer*********************************/
    // let resizer = document.querySelector(`[data-window="${this.props.id}"] .context-area .resizer`);
    /*********************End Resizer*********************************/
    // if (!this.props.dialog) hotkeys.setScope(this.props.id + (this.props.isDialog ? '-dialog' : ''));
    hotkeys.deleteScope(this.props.id + (this.props.isDialog ? '-dialog' : ''));
    let enqKeys =
      !this.props.isDialog && sessionStorage.getItem('enquiry_window') === 'current'
        ? this.getEnquiryTemplateFunctionKeys()
        : '';
    enqKeys = enqKeys ? ',' + enqKeys : enqKeys;
    hotkeys(
      'F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,ENTER,PAGEDOWN,PAGEUP,ALT+F1,UP,DOWN,ALT+T,SHIFT+ENTER, ALT+ENTER, CTRL+ENTER, CMD+ENTER,ESCAPE' +
        enqKeys, //TODO: Update post FunctionLoader
      { keyup: true, keydown: true, scope: this.props.id + (this.props.isDialog ? '-dialog' : '') },
      (event, handler) => {
        // when contextMenu is opened then other than its key events ['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'], so e.g. pageDown / pageUp should be disabled
        const contextMenuOpened = document.getElementsByClassName('react-contextmenu--visible')?.length > 0;
        const selectedAndNotDisabledMenuItem =
          document.querySelectorAll('.react-contextmenu-item--selected:not(.react-contextmenu-item--disabled)')
            ?.length > 0;
        if (
          (contextMenuOpened &&
            !['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'].includes(
              handler.key.toLowerCase(),
            )) ||
          (contextMenuOpened && !selectedAndNotDisabledMenuItem && handler.key.toLowerCase() === 'enter')
        ) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }

        // Case <ENTER>: Let context menu handle the event on actve menu item (no extra action needed by hotkeys)
        if (contextMenuOpened && selectedAndNotDisabledMenuItem && handler.key.toLowerCase() === 'enter') {
          return;
        }

        if (contextMenuOpened && handler.key.toLowerCase() === 'escape') {
          return;
        }

        // Keep default behavior buttons           // press <ENTER> ==> execute "onclick" function
        // =============================
        if (
          (handler.key.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') &&
          event.type === 'keydown' &&
          event.target instanceof HTMLButtonElement
        ) {
          /*
            #########################################################################################################
            #########################################################################################################
            REMARK: before these changes hotkeys was configured to be called both on keydown and keyup. Not sure if
            both are needed. Anyway all keydown events were blocked (return false) and some specific code was written
            to change/replace the behavior on the keyup events.

            The problem to be solved now is that the default behavior of buttons is triggered by the keydown event,
            but when the default action is execeted, the target element of the keyup event is (in most cases) no
            longer the button.

            To limit possible impact, there was chosen to keep current coding for all keys except for the <ENTER> key
            that is changed to:
              keydown: keep default behavior for buttons but replace/change behavior for other controls
              keyup: block default behavior

            Remark that the attribute "data-event='ignore'" is not an option here as for instance we still want to
            trigger the refresh functionality when F5 is pressed when the button has focus.
            #########################################################################################################
            #########################################################################################################
          */
          return;
        }

        // Handle arrow navigation
        // =======================
        if (handler.key.toUpperCase() === 'DOWN' || handler.key.toUpperCase() === 'UP') {
          if (event.type === 'keyup' && !this.props.dialog) {
            setTimeout(() => this.handleArrowNavigation(handler.key.toUpperCase() === 'UP'), 0);
          }
          return; // --> keep default behavior combo, multiline, ...
        }

        if (handler.key.toLowerCase() === 'escape') {
          // Handle <ESCAPE> for dialogs
          if (this.props.isDialog && event.type === 'keydown' && !event.repeat) {
            let _actions = this.searchXML('action').flat();
            const f12Action = _actions.find((action: any) => action.$?.event?.toLowerCase() === 'f12');
            if (f12Action) {
              this.actionHandler({ event: f12Action.$.event, command: f12Action.$.command });
              return false;
            }
            const f3Action = _actions.find((action: any) => action.$?.event?.toLowerCase() === 'f3');
            if (f3Action) {
              this.actionHandler({ event: f3Action.$.event, command: f3Action.$.command });
              return false;
            }
          }
          return;
        }

        // Other ...
        // =========
        if (
          !this.props.dialog &&
          ((event.type === 'keyup' && handler.key.toLowerCase() !== 'enter' && event.key.toLowerCase() !== 'enter') ||
            (event.type === 'keydown' &&
              (handler.key.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') &&
              !event.repeat))
          /*
            #########################################################################################################
            #########################################################################################################
            see remark on keep default behavior buttons
            #########################################################################################################
            #########################################################################################################
          */
        ) {
          // if (handler.key.toLocaleLowerCase() === 'f4') {
          // };
          //Handle keybinding
          if (handler.shortcut.toLowerCase() === 'f1') {
            this.props.updateContext?.({ contextArea: 'help' });
            return false;
          }
          if (handler.shortcut.toLowerCase().startsWith('shift') && event.key.toLowerCase() !== 'enter') {
            let match = handler.shortcut.match(/^shift\+f(\d+)$/i);
            if (match && match[1] && !isNaN(+match[1])) {
              this.actionHandler({ event: `F${+match[1] + 12}` });
            }
            event.stopPropagation();
            event.stopImmediatePropagation();
            event.preventDefault();
            return false;
          }
          let target = event.target as HTMLInputElement;
          if (
            ~(~(target.parentElement?.className || '').indexOf('prompt') || 0) > -1 &&
            handler.key.toLowerCase() === 'f4'
          ) {
            this.promptHandler(
              target.name,
              target.closest('[data-ddspanel]')?.getAttribute('data-ddspanel') ||
                target.closest('[data-panel]')?.getAttribute('data-panel') ||
                '',
              target.closest('[data-rowfield]')?.getAttribute('data-rowfield') || '',
              target.closest('[data-row]')?.getAttribute('data-row') || '',
            );
          } else {
            let _actions = this.searchXML('action').flat();
            let tempAction = _actions.find((x: any) => {
              if (
                x.$.event?.toLowerCase() === handler.key.toLowerCase() ||
                ((handler.key.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') &&
                  x.$.event?.toLowerCase() === 'default')
              ) {
                let dConstraint = new Constraint(x.$.disableconstraints);
                return !dConstraint.evaluate(this.flags, false);
              }
              return false;
            });

            if (handler.key.toUpperCase() === 'PAGEDOWN' || handler.key.toUpperCase() === 'PAGEUP') {
              // Case table has currently input focus
              // ------------------------------------
              if (document.activeElement?.closest('.data-table-span')) {
                return; // REMARK: pageup/pagedown is handled in the table itself ==> prevent that action handler is also executed with PAGEUP/PAGEDOWN event
              }

              // Handle other components that support PAGEUP/PAGEDOWN events
              // -----------------------------------------------------------
              let keepComponentSpecificBehavior = false; // REMARL_SKE_20241001: no interaction with other components that need call action handler with PAGEUP/PAGEDOWN event (TESTED: Calender, previous/Next-buttons, autocomplete)

              // Get "active" table                         T
              // ------------------
              if (!keepComponentSpecificBehavior) {
                let baseSelector = '.window.active';
                if (this.props.isDialog) baseSelector += '.modal';
                let table = document.querySelector(`${baseSelector} .data-table.autopagedown`); // NTH: what if multiple tables ? (assume only one with autopagedown defined in layout)
                let tableSpan = table ? table.closest('.data-table-span') : undefined;
                if (tableSpan) {
                  if (handler.key.toUpperCase() === 'PAGEDOWN') {
                    (tableSpan as any).handleExternalPageDownRequest();
                  } else {
                    (tableSpan as any).handleExternalPageUpRequest();
                  }
                  return; // REMARK: pageup/pagedown is handled in the table itself ==> :prevent that action handler is also executed with PAGEUP/PAGEDOWN event
                }
              }
            }
            // REMARK: following code seems not needed anymore since editorOptions.onNavigation is implemented
            // NTH: check if hotkeys still needs to capture UP/DOWN & clean-up code if the case
            /*
            if (handler.key.toUpperCase() === 'DOWN' || handler.key.toUpperCase() === 'UP') {
              let table = document.querySelector('.window.active .data-table');
              if (table && !document.activeElement?.className.includes('rdg-focus-sink')) {
                (document.querySelector('.window.active .data-table .rdg-focus-sink') as HTMLDivElement)?.focus();
                return;
              }
            }
            */

            if (handler.shortcut.toLowerCase() === 'alt+t') {
              // Get "active" table
              // ==================
              let baseSelector = '.window.active';
              if (this.props.isDialog) baseSelector += '.modal';
              let tableSpan = document.querySelector(`${baseSelector} .data-table-span`);

              // Navigate IN/OUT table
              // =====================
              if (tableSpan) {
                // Navigate OUT
                // ------------
                if (document.activeElement?.closest('.data-table-span')) {
                  // Collect candidate elements to give focus to
                  // --> a. "basic" input controls
                  let inputs: any = document.querySelectorAll(
                    `${baseSelector} [tabindex]:not(.panel-area):not(.event-dispatcher-dummy):not(table):not(.data-table-span):not(.btn):not(.rdg-focus-sink):not(.rdg-text-editor):not([readonly]):not([disabled])`,
                  );

                  // --> b. global buttons
                  if (inputs.length < 1)
                    inputs = document.querySelectorAll(
                      `${baseSelector} .button-bar .btn[tabindex]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])`,
                    );

                  // --> c. inline buttons
                  if (inputs.length < 1)
                    inputs = document.querySelectorAll(
                      `${baseSelector} .panel-area .btn[tabindex]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])`,
                    );

                  // Determine element to give focus to
                  if (inputs.length > 0) {
                    let tabindexList = Array.from(inputs).map((x: any) => +x.getAttribute('tabindex'));
                    if (tabindexList.some((index) => index > 0)) {
                      // first tabindex > 0
                      tabindexList = tabindexList.filter((index) => index > 0);
                    } else {
                      // then tabindex = 0, remark that tabindex -1 should not be reachable trough keyboard navigation !
                      tabindexList = tabindexList.filter((index) => index === 0);
                    }
                    if (tabindexList.length > 0) {
                      let minIndex = Math.min(...tabindexList);
                      let nextInput = Array.from(inputs).find(
                        (x: any) => +x.getAttribute('tabindex') === minIndex,
                      ) as HTMLElement;
                      if (document.activeElement !== nextInput) XT.restoreFocusActiveWindow(nextInput);
                    }
                  }

                  // Navigate IN
                  // -----------
                } else {
                  XT.restoreFocusActiveWindow(tableSpan);
                }
              }
              return;
            }

            if (handler.key.toLowerCase() === 'f5' && !!tempAction) this.reload();
            else if (
              handler.key.toLowerCase() !== 'f4' &&
              (!!tempAction ||
                handler.key.toLowerCase() === 'enter' ||
                event.key.toLowerCase() === 'enter') /* || handler.key.toLowerCase() === 'f5'*/
            ) {
              this.actionHandler({ event: handler.key, command: tempAction?.$?.command }, undefined);
            }
          }
        }
        return false;
      },
    );
    if (!this.props.dialog) hotkeys.setScope(this.props.id + (this.props.isDialog ? '-dialog' : '')); // Remark: keep this code as previous deleteScope may have changed the scope incorrectly
    setTimeout(() => ReactTooltip.rebuild(), 500);
    this.handleAperioView();
    if (this.props.isDialog) {
      this.setModalWidth();
      window.addEventListener('resize', this.setModalWidth);
    }
  }

  componentWillUnmount() {
    hotkeys.unbind('*', this.props.id + (this.props.isDialog ? '-dialog' : ''));
    hotkeys.deleteScope(this.props.id + (this.props.isDialog ? '-dialog' : ''));
    if (this.props.isDialog) hotkeys.setScope(this.props.id); // Remark: keep this code as previous deleteScope may have changed the scope incorrectly
    this.cancelSubmitAperioViewCommand?.();
    if (this.props.isDialog) {
      this.setModalWidth();
      window.removeEventListener('resize', this.setModalWidth);
    }
  }

  promptHandler(id: string, panelID: string, rowFieldID?: string, rowID?: string, alternativePromptKey?: string) {
    let data = this.props.data.form;
    let command = this.command;
    command.setPanel(panelID);
    for (let index in data.panel) {
      let panel = data.panel[index];
      if (panel.$.id.indexOf('MSG') > -1) continue;
      let ctrlPanel = panel.ctrl.find((c: any) => c.$.id === 'XWRENA');
      if (ctrlPanel)
        command?.children[panel.$.id]?.childSet(
          ctrlPanel.$.id,
          new Knot(KnotElement.CTRL, {
            id: ctrlPanel.$.id,
            value: panelID,
          }),
        );
      let ctrlField = panel.ctrl.find((c: any) => c.$.id === 'XWFLDN');
      if (ctrlField)
        command?.children[panel.$.id]?.childSet(
          ctrlField.$.id,
          new Knot(KnotElement.CTRL, {
            id: ctrlField.$.id,
            value: id,
          }),
        );

      let rowField = panel.ctrl.find((c: any) => c.$.id === rowFieldID);
      if (!!rowField && rowID)
        command?.children[panel.$.id]?.childSet(
          rowField.$.id,
          new Knot(KnotElement.CTRL, {
            id: rowField.$.id,
            value: rowID,
          }),
        );
      // command?.updateFlag(dirtyflag, true);
    }
    // this.setState({ command: command });
    this.actionHandler({ event: alternativePromptKey?.toUpperCase() || 'F04' }, undefined);
  }

  contextLoader(
    action: string,
    fields: { name: string; panel: string }[],
    formID: string,
    tableID: string,
    contextID: string,
    firstEntries: { name: string; value: string }[],
  ) {
    if (!fields || fields.length === 0) return;
    let payload = {
      table: tableID,
      formID: formID,
      contextID: contextID,
      fields: fields,
      firstEntries: firstEntries.map((f: any) => f.value),
    };
    let data = this.props.data.form;
    let command: Command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    for (let index in data.panel) {
      let panel = data.panel[index];
      if (panel.$.id.indexOf('MSG') > -1) continue;
      command.childSet(panel.$.id, new Knot(KnotElement.PANEL, { id: panel.$.id, flags: panel.$.flags }));
    }

    command.setAction(action);

    command.setCursor('1,1');

    let allowCommand = false;
    fields?.forEach((field) => {
      let layout = this.layout[field.panel];
      if (layout) {
        let _actions = layout.actions?.[0]?.action?.filter(
          (ac: any) => ac.$.event === action || ac.$.command === action,
        );
        _actions?.forEach((_action: any) => {
          let dConstraint = new Constraint(_action?.$?.disableconstraints);
          if (_action && !dConstraint.evaluate(this.flags, false)) {
            allowCommand = true;
            return;
          }
        });
      }
    });
    if (!this.panelEvents[action] && !allowCommand) {
      action = '';
    }
    this.contextLoaderAction = action;
    this.props.startLoading && this.props.startLoading();
    this.props.loadContext &&
      this.props.loadContext(this.props.id, payload, action ? command : null, this.props.endLoading, this.scopeHandler);
  }

  //TODO: No longer used. Remove all usages
  actionLoader() {
    return true;
    // if (this.props.isDialog) return true;
    // let { actions, loader, template } = XT.loadFunctionData(
    //   this.props,
    //   this.panels.map((p: PanelProps) => p.id)
    // );
    // if (!!actions && actions.length > 0 && !!loader && !!template && !this.props.actionDefinitions) {
    //   let data = this.props.data.form;
    //   let command: Command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    //   for (let index in data.panel) {
    //     let panel = data.panel[index];
    //     if (panel.$.id.indexOf('MSG') > -1) continue;
    //     command.childSet(panel.$.id, new Knot(KnotElement.PANEL, { id: panel.$.id, flags: panel.$.flags }));
    //   }
    //
    //   if (loader.name === 'FieldBasedCommandAction') {
    //     let data = this.props.data.form;
    //     for (let index in data.panel) {
    //       let panel = data.panel[index];
    //       if (panel.$.id.indexOf('MSG') > -1) continue;
    //       let ctrl = panel.ctrl.find((c: any) => c.$.id === loader?.field);
    //       if (!!ctrl) {
    //         command?.children[panel.$.id]?.childSet(
    //           ctrl.$.id,
    //           new Knot(KnotElement.CTRL, {
    //             id: ctrl.$.id,
    //             value: loader.option || ' '
    //           })
    //         );
    //       }
    //     }
    //   }
    //
    //   command.setAction(loader.event);
    //
    //   command.setCursor('0,0');
    //
    //
    //   this.props.startLoading && this.props.startLoading();
    //   this.props.loadActions && this.props.loadActions(this.props.id, command, template, this.props.endLoading, this.scopeHandler);
    // }
  }

  defaultActionHandler(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    let target = e.target as HTMLElement;
    if (['td' /*, 'tr'*/].indexOf(target.nodeName.toLowerCase()) > -1) {
    }
  }

  scopeHandler(e?: FocusEvent<HTMLDivElement>) {
    // if (e?.target.getAttribute('data-event') === 'ignore') return;
    // if (e?.target.getAttribute('role') === 'dialog') return;
    // // if (this.props.dialog) return;
    // if (!e) return;
    // // if (!e) {
    // //   let elements = Array.from(document.querySelectorAll('.window.active input[tabindex]:not([disabled])'));
    // //   let indexes = elements.map((elem: Element) => +(elem.getAttribute('tabindex') || '0'));
    // //   if (!this.props.dialog && !this.props.isDialog) {
    // //     let shouldFocus = document.querySelector(`.window.active input[tabindex="${Math.min(...indexes)}"]`);
    // //     if (shouldFocus) {
    // //       let el = document.querySelector(':focus') as HTMLElement;
    // //       if (el) el.blur();
    // //       (shouldFocus as HTMLInputElement).focus();
    // //       shouldFocus.dispatchEvent(new Event('focus'));
    // //       let container = document.querySelector('.window.active > div');
    // //       if (container) {
    // //         container.scrollTop = 0;
    // //       }
    // //       return;
    // //     }
    // //   }
    // // }
    // if (!this.props.dialog) hotkeys.setScope(this.props.id + (this.props.isDialog ? '-dialog' : ''));
    // let data = this.props.data.form;
    // let command: Command;
    // if (this.state.command == null) {
    //   //Create default scope
    //   // this.actionLoader();
    //   command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    //   for (let index in data.panel) {
    //     let panel = data.panel[index];
    //     if (panel.$.id.indexOf('MSG') > -1) continue;
    //     command.childSet(panel.$.id, new Knot(KnotElement.PANEL, { id: panel.$.id, flags: panel.$.flags }));
    //   }
    // } else {
    //   command = this.state.command;
    //   if (command.id !== data.$.id) {
    //     // this.actionLoader();
    //     //Rescope on navigation
    //     command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    //     for (let index in data.panel) {
    //       let panel = data.panel[index];
    //       if (panel.$.id.indexOf('MSG') > -1) continue;
    //       command.childSet(panel.$.id, new Knot(KnotElement.PANEL, { id: panel.$.id, flags: panel.$.flags }));
    //     }
    //   }
    // }
    // let ddspos = e?.target?.parentNode?.parentElement?.getAttribute('data-ddspos') || '';
    // let defaultPanel = this.props.data.form.panel.find((x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1);
    // let panel = defaultPanel?.$.id || '';
    // command.setPanel(panel);
    // command.setCursor(ddspos);
    //
    //
    // this.setState({ command: command });
  }

  contextHandler(e: MouseEvent<HTMLElement>) {
    // if (this.props.contextArea !== 'aperio') return;
    // if ((e?.target as HTMLElement)?.getAttribute('data-event') === 'ignore') return;
    // if ((e?.target as HTMLElement)?.getAttribute('role') === 'dialog') return;
    // let target = e.target as HTMLElement;
    //
    // let attachmentConfig = (this.props.attachments?.length || 0) > 0;
    // if (attachmentConfig) {
    //   let attachmentKeys: Record<string, string> = {};
    //   const element2 = target.closest('table') as HTMLElement;
    //   if (target && element2) {
    //     let tableId = element2.id;
    //     let cat = this.props.attachments?.filter((x: any) => x.$.key.startsWith(tableId));
    //     if (!!cat && cat.length > 0) {
    //       let row = target.closest('tr');
    //       let panel = this.props.data.form.panel.find((x: any) => !!x.row && x.row.length > 0);
    //       let _row = panel?.row.find((r: any) => r.$.id === row?.getAttribute('data-row'));
    //       if (_row)
    //         for (let i = 0; i < _row.ctrl.length; i++) {
    //           let col = _row.ctrl[i];
    //           cat.forEach((c: any) => {
    //             let [_, colKey] = c.$.key.split(',');
    //             if (col.$.id === colKey) {
    //               attachmentKeys[col.$.id] = (col.$.value || '').trim();
    //             }
    //           });
    //           // break;
    //         }
    //       this.props.updateContext?.({ attachmentKeys: attachmentKeys });
    //     }
    //   }
    // }
  }

  changeHandler(e: ChangeEvent<HTMLDivElement>) {
    //TODO: Cross check with Eclipse
    // if (this.props.dialog) return;
    // let { decimalsign, entry_delimiter, no, yes } = this.props.settings?.regionals.codes[0].$;
    // let { century_break_year, dateSeparator, format6, format8, format8Sep } = this.props.settings?.regionals.dateformats[0].$;
    // if (e.target.getAttribute('data-event') === 'ignore') return;
    // let value = (e.target as HTMLInputElement).value;
    // let data = this.props.data.form;
    // let command = this.state.command;
    // for (let index in data.panel) {
    //   let panel = data.panel[index];
    //   if (panel.$.id.indexOf('MSG') > -1) continue;
    //   let ctrl = panel.ctrl?.find((c: any) => c.$.id === e.target.id);
    //   if (!!ctrl) {
    //     if ((e.target as HTMLInputElement).type === 'checkbox')
    //       value = Formatters.BooleanFormatter.out(
    //         (e.target as HTMLInputElement).checked,
    //         yes,
    //         no,
    //         (e.target as HTMLInputElement).indeterminate
    //       );
    //     if ((e.target as HTMLInputElement).type === 'radio') {
    //       let _radios:
    //         | HTMLCollectionOf<HTMLInputElement>
    //         | undefined = e.target.parentElement?.parentElement?.parentElement?.parentElement?.getElementsByTagName('input');
    //       let radios = Array.from(_radios as Iterable<HTMLInputElement>);
    //       let radioKnots: Record<string, { knot: Knot; dirtyflag: number }> = {};
    //       let changed = false;
    //       for (let index in radios) {
    //         let radio = radios[index];
    //
    //         let _ctrl = panel.ctrl.find((c: any) => c.$.id === radio.id);
    //         let _dirtyflag = +((radio.closest('[data-dirtyflag]') as HTMLElement)?.getAttribute('data-dirtyflag') || -1);
    //         radioKnots[_ctrl.$.id] = {
    //           knot: new Knot(KnotElement.CTRL, { id: _ctrl.$.id, value: radio.checked ? '1' : ' ' }),
    //           dirtyflag: _dirtyflag
    //         };
    //         if ((_ctrl.$.value === '1') !== radio.checked) {
    //           //Value changed
    //           changed = true;
    //         }
    //       }
    //       for (let key in radioKnots) {
    //         let radioKnot = radioKnots[key];
    //         if (changed) {
    //           command?.children[panel.$.id]?.childSet(key, radioKnot.knot);
    //           command?.updateFlag(radioKnot.dirtyflag, true);
    //         } else {
    //           command?.children[panel.$.id]?.childDel(key);
    //           command?.updateFlag(radioKnot.dirtyflag, false);
    //         }
    //       }
    //     }
    //     let dirtyflag = +((e.target.closest('[data-dirtyflag]') as HTMLElement)?.getAttribute('data-dirtyflag') || -1);
    //
    //     let origType = (e.target as HTMLInputElement).getAttribute('data-inputtype');
    //     if (ctrl.$.value.trim() !== value.trim() || origType === 'combo') {
    //       //TODO: Confirm other cases
    //       let padChar = e.target.parentNode?.parentElement?.getAttribute('data-padchar') || '';
    //       let limit = +(e.target.parentNode?.parentElement?.getAttribute('data-limit') || 0);
    //       let mask = e.target.getAttribute('data-mask');
    //       value = value.padStart(limit, padChar);
    //       if (mask && mask === 'decimal' && value.length < limit) {
    //         value = value.padStart(limit, ' ');
    //       }
    //       command?.children[panel.$.id]?.childSet(
    //         ctrl.$.id,
    //         new Knot(KnotElement.CTRL, {
    //           id: ctrl.$.id,
    //           value: value
    //         })
    //       );
    //       command?.updateFlag(dirtyflag, true);
    //     } else {
    //       command?.children[panel.$.id]?.childDel(ctrl.$.id);
    //       command?.updateFlag(dirtyflag, false);
    //     }
    //     // }
    //   }
    // }
    // this.setState({ command: command });
  }

  searchXML(key: string) {
    let commands: any[] = [];
    const findObjectAndParents = (item: any, key: string): boolean => {
      if (item && item[key]) {
        commands.push(item[key]);
        return true;
      }
      if (Array.isArray(item) || Object(item) === item) {
        for (let k in item) {
          findObjectAndParents(item[k], key);
        }
      }
      return false;
    };
    let temp = { ...this.props.layout.screen.form[0] };
    delete temp.panel;
    delete temp.group;
    findObjectAndParents({ ...this.panels.map((pan: PanelProps) => this.getLayout(pan.id)), ...temp }, key);
    return Array.from(new Set(commands));
  }

  setModalWidth = () => {
    const modal = document.querySelector<HTMLDivElement>('.modal-body');
    const menuBar = modal?.querySelector<HTMLElement>('.table-menu-bar');

    const dataWidth = this.props.dialog?.layout?.screen?.form[0]?.$?.width
      ? this.props.dialog?.layout?.screen?.form[0]?.$?.width * wwm
      : 0;
    const menuBarWidth = menuBar ? menuBar.clientWidth + 50 : 0;

    const largestWidth = menuBarWidth > dataWidth ? menuBarWidth : dataWidth;

    const width = largestWidth > 0 ? `${largestWidth}px` : 'auto';

    modal.style.width = width;
  };

  async actionHandler(action: Action, e?: MouseEvent<HTMLElement>, noLoad: boolean = false, panelID: string = '') {
    if (!this.command) {
      setTimeout(() => this.actionHandler(action, e), 100);
      return;
    }
    if (action?.name === 'LinkQueryAction') {
      let val = XT.getValueFromWindow({ data: this.props.data }, panelID, action?.variables?.replaceAll('|', ''));
      if (val?.startsWith('SELECT')) {
        if (action?.exportType) {
          this.sendCustomCommand(
            `<?xml version="1.0" encoding="UTF-8"?><form action="EXECUTE_SQL" id="QueryManager"><ctrl id="SQLSTRING" value="${val.replace(
              'SELECT *',
              'SELECT count(1)',
            )}/* heading=text */"/><ctrl id="TRIM" value="true"/><ctrl id="INCLUDE_LINENBR" value="true"/><ctrl id="TITLE" value="printer_output"/></form>`,
            { action },
          );
        } else {
          this.sendCustomCommand(
            `<?xml version="1.0" encoding="UTF-8"?><form action="EXECUTE_SQL" id="QueryResult"><ctrl id="SQLSTRING" value="${val}/* heading=text */"/><ctrl id="TRIM" value="true"/><ctrl id="INCLUDE_LINENBR" value="true"/><ctrl id="TITLE" value="printer_output"/></form>`,
            { action },
          );
        }
        return;
      } else {
        showDialog({
          message:
            Localization.instance.getString(action?.errorMessage || '') ||
            Localization.instance.getString('TXT_ErrorOccurred'),
          completeScreenBlock: true,
          restrictOutsideClick: true,
          hideCancelButton: true,
          confirmText: Localization.instance.getString('TXT_OK'),
          overlayContainerId: 'legacy',
        });
        return;
      }
    }
    if (action?.event?.toLowerCase() === 'f5') {
      this.reload();
      return;
    }

    if (this.props.data.form.$.id === 'SpooledFileViewDialog') {
      if (!action) {
        switch ((e?.target as HTMLElement)?.id || '') {
          case 'prev':
            action = { event: 'PAGEUP' };
            break;
          case 'next':
            action = { event: 'PAGEDOWN' };
            break;
          case 'goto':
            action = { event: 'DEFAULT' };
            this.addToCommand('DetailPanel', 'action', 'GOTOPAGE', -1);
            this.addToCommand(
              'DetailPanel',
              'reqpage',
              this.command?.children?.['DetailPanel']?.children?.['page']?.attributeGet('value') || '1',
              -1,
            );
            break;
          default:
            action = { event: 'DEFAULT' };
            this.addToCommand('DetailPanel', 'action', 'GOTOPAGE', -1);
            this.addToCommand(
              'DetailPanel',
              'reqpage',
              this.command?.children?.['DetailPanel']?.children?.['page']?.attributeGet('value') || '1',
              -1,
            );
            break;
        }
      }
    }

    if (this.props.data.form.$.id === 'SpooledFileViewDialog') {
      if (!action) {
      }
    }

    if (!action) {
      action = { event: 'ENTER' };
    }

    // let _actions = this.searchXML('action').flat();
    // let tempAction = _actions.find((x: any) => {
    //   if (x.$.event?.toLowerCase() === action.event.toLowerCase()) {
    //     let dConstraint = new Constraint(x.$.disableconstraints);
    //     return !dConstraint.evaluate(this.flags, false);
    //   }
    //   return false;
    // });
    // if(!tempAction && action.event.toLowerCase() === 'f5') return;

    if (this.props.dialog) return;
    let command = this.command;
    let actions = this.getPanelActions();

    let tempAction = this.panelEvents[action.event];
    if (action.event === 'ENTER' && !tempAction) tempAction = this.panelEvents['DEFAULT'];

    if (action.command && /^F\d{2}$/i.test(action.command)) action.event = action.command;

    if (/^F0\d$/i.test(action.event)) action.event = action.event?.replace('0', '');

    // =====================================================================
    // Ignore requested action if current window contains invalid input data               --> eg if incomplete date entered => cannot determine coresponding server value => user needs to correct value first
    // =====================================================================
    if (!action.event || ['F1', 'F3', 'F5', 'F12'].indexOf(action.event) === -1) {
      /*
          Remark SKE 2021/10/13:
            ° not sure of exact place to insert this code as in following code lines the value of action.event seems to be changed in some special cases. As no
              documentation available that specify this special changes, I choosed this place to make sure the command object will not be changed.
            ° some actions do not need data - like events F3 (exit) and F12 (back)). Feel free to adjust above list if other cases are needed.
      */
      let inputErrorElements = document.querySelectorAll(
        '.window.active [tabindex][data-input-invalid=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      if (inputErrorElements && inputErrorElements.length > 0) {
        let tabindexList = Array.from(inputErrorElements).map((x: any) => +x.getAttribute('tabindex'));
        if (tabindexList.some((index) => index > 0)) {
          // first tabindex > 0
          tabindexList = tabindexList.filter((index) => index > 0);
        } else if (tabindexList.some((index) => index === 0)) {
          // then tabindex = 0
          tabindexList = tabindexList.filter((index) => index === 0);
        } // finally tabindex = -1
        let minIndex = Math.min(...tabindexList);
        let firstErrorElement = Array.from(inputErrorElements).find(
          (x: any) => +x.getAttribute('tabindex') === minIndex,
        ) as HTMLElement;
        firstErrorElement?.focus();
        return;
      }
    }
    // =====================================================================
    // =====================================================================
    // =====================================================================

    let _action = actions.find((x: any) => x.event?.toLowerCase() === action.event?.toLowerCase());
    //if (action.event.toUpperCase() !== 'DEFAULT' && action.event.toUpperCase() !== 'ENTER') return;
    if (action.event === 'ENTER' && !_action) _action = actions.find((x: any) => x.event?.toLowerCase() === 'DEFAULT');
    if (_action?.command === 'BACK' || tempAction?.command === 'BACK' || action.command === 'BACK')
      action.event = 'BACK';
    if (_action?.command === 'MOVE' || tempAction?.command === 'MOVE' || action.command === 'MOVE')
      action.event = 'MOVE';
    if (_action?.command === 'FILTER' || tempAction?.command === 'FILTER' || action.command === 'FILTER')
      action.event = 'FILTER';
    if (_action?.command === 'GOTOPAGE' || tempAction?.command === 'GOTOPAGE' || action.command === 'GOTOPAGE') {
      action.event = 'GOTOPAGE';
      if (_action) _action.event = 'GOTOPAGE';
      this.addToCommand('DetailPanel', 'action', 'GOTOPAGE', -1);
      this.addToCommand(
        'DetailPanel',
        'reqpage',
        this.command?.children?.['DetailPanel']?.children?.['page']?.attributeGet('value') || '1',
        -1,
      );
    }
    // let allActions = this.searchXML('action');
    if (action.name === 'FieldBasedCommandAction') {
      let data = this.props.data.form;
      for (let index in data.panel) {
        let panel = data.panel[index];
        if (panel.$.id.indexOf('MSG') > -1) continue;
        let ctrl = panel.ctrl.find((c: any) => c.$.id === action.field);
        if (ctrl) {
          command?.children[panel.$.id]?.childSet(
            ctrl.$.id,
            new Knot(KnotElement.CTRL, {
              id: ctrl.$.id,
              value: action.option || ' ',
            }),
          );
        }
      }
    }
    if (
      !(
        ['Spooledfiles', 'MessageQueue', 'SpooledFileQueueSelection', 'Jobs', 'DocumentOutput'].indexOf(
          this.props.data.form.$.id,
        ) !== -1 &&
        (action.event === 'ENTER' || action.event === 'DEFAULT')
      )
    ) {
      command?.setAction(_action?.event ? _action.event : action.event);
    }
    if (action.event === 'FILTER' || action.command === 'FILTER') command?.setAction('ENTER');
    command.setActionSpecificEndpointProgram(_action?.event ? _action : action);
    //Disable all flags
    let commands = XT.getCommands(this.props.layout, this.props.data);
    for (let i in commands) {
      let _command = commands[i];
      if (_command.length === 2) {
        command.updateFlag(+_command, false);
      }
    }
    if (action.command?.startsWith('F') && (_action?.command || tempAction?.command)) action.command = _action.command;

    if (
      (action.command || _action?.command || tempAction?.command) &&
      action.command !== 'ENTER' &&
      !isNaN(+(action.command || _action?.command || tempAction?.command))
    ) {
      command.updateFlag(+(action.command || _action?.command || tempAction?.command || -1), true);
    }
    // command.setFirstVisibleRow();
    this.setState((state) => {
      return { ...state, command };
    });
    e?.currentTarget?.blur();
    if (
      !tempAction &&
      !_action &&
      !action.command &&
      (action.event === 'ENTER' || action.event === 'DEFAULT') &&
      this.props.data.form.$.id === 'Jobs' &&
      !action.option
    ) {
      return;
    }
    // enforce proper xml, like in XT
    if (
      this.props.data.form.$.id === 'SpooledFileViewDialog' &&
      (action.event === 'PAGEDOWN' || action.event === 'PAGEUP' || action.event === 'GOTOPAGE')
    ) {
      const customAction = action.event === 'PAGEUP' ? 'prev' : action.event === 'PAGEDOWN' ? 'next' : 'GOTOPAGE';
      const customCommand = `<?xml version="1.0" encoding="UTF-8"?><form action="${customAction}" id="SpooledFileView"><ctrl id="action" value="${customAction}"/>${
        customAction === 'GOTOPAGE'
          ? '<ctrl id="reqpage" value="' +
            (this.command?.children?.['DetailPanel']?.children?.['page']?.attributeGet('value') || '1') +
            '"/>'
          : ''
      }</form>`;
      this.sendCustomCommand(customCommand, undefined, 'SpooledFileView');
      return;
    }
    if (this.props.data.form.$.id === 'QueryResult' && action.event === 'F3') {
      this.props.startLoading && this.props.startLoading();
      this.props.CloseWindow?.(this.props.id, this.props.endLoading);
      return;
    }
    this.props.startLoading && this.props.startLoading();
    await this.props.interact?.(
      this.props.id,
      command,
      noLoad ? () => {} : this.props.endLoading,
      this.scopeHandler,
      this.errHandler,
    );
    this.setState((state) => {
      return { ...state, command: null };
    });
  }

  contextMenuHandler(panelID: string, ids: string[], option: ContextItem) {
    // if (ids.length === 0) return;
    this.logger
      .childWithMeta({ function: 'contextMenuHandler' })
      .debug('contextMenuHandler', { panelID, ids, option, panel: this.props.data.form.panel });
    let command = this.command;
    if (option.action && option.action.name === 'LinkQueryAction') {
      if (option.action.exportType === '*EXCEL' || option.action.exportType === '*CSV') {
        WindowManger.Interact(
          this.props.id,
          command,
          this.props.endLoading ? this.props.endLoading : () => {},
          this.scopeHandler,
          this.errHandler,
          // () => {
          // get rows and columns
          // if (option.action?.exportType === '*CSV') {exportToCsv(columns, rows, name)};
          // if (option.action?.exportType === '*EXCEL') exportToXlsx(columns, rows, name);
          // }
        );
      }
    } else if (option.action && option.action.name === 'RemotePrompt') {
      for (let i in this.props.data.form.panel.filter((p: any) => p.$.id !== 'dummy')) {
        let panel = this.props.data.form.panel[i];
        if (panel.$.id.indexOf('MSG') > -1) continue;
        let retfld = panel.ctrl.find((x: any) => x.$.id === option.action?.returnfield);
        let retpanel = panel.ctrl.find((x: any) => x.$.id === option.action?.returnpanel);
        let rowfld = panel.ctrl.find((x: any) => x.$.id === option.action?.field);
        if (retfld) {
          command?.children[panel.$.id]?.childSet(
            retfld.$.id,
            new Knot(KnotElement.CTRL, {
              id: retfld.$.id,
              value: option.key,
            }),
          );
        }
        if (retpanel) {
          command?.children[panel.$.id]?.childSet(
            retpanel.$.id,
            new Knot(KnotElement.CTRL, {
              id: retpanel.$.id,
              value: panelID,
            }),
          );
        }
        if (rowfld) {
          command?.children[panel.$.id]?.childSet(
            rowfld.$.id,
            new Knot(KnotElement.CTRL, {
              id: rowfld.$.id,
              value: ids[0] + '',
            }),
          );
        }
      }
    }
    if (option.type === 'option') {
      let flags = command?.children[panelID]?.attributeGet('flags');
      let nonZeroRows = this.getPanelData(panelID).row?.filter(
        (r: any) =>
          r.ctrl.find((x: any) => x.$.id === option.key && x.$.value.trim() !== '') && ids.indexOf(r.$.id) === -1,
      );
      for (let i in nonZeroRows) {
        let _row = nonZeroRows[i];
        let row = new Knot(KnotElement.ROW, { id: _row.$.id, flags: flags || '' });
        row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: '' }));
        command?.children[panelID]?.childSet(_row.$.id, row);
      }

      let lastRowKeys = Object.keys(this.lastRowActions[this.props.data.form.$.id]?.[panelID] || {});
      for (let i in lastRowKeys) {
        let rowID = lastRowKeys[i];
        // let _row = nonZeroRows[i];
        if (ids.indexOf(rowID) > -1) continue;
        let _ctrl = this.getPanelData(panelID)
          .row.find((r: any) => r.$.id === rowID)
          ?.ctrl?.find((c: any) => c.$.id === option.key)?.$;
        // If ctrl is not reloaded or has a value reset/deselect the row before setting new option
        if (_ctrl?.value?.trim() === '' && _ctrl?.reloaded !== false) continue;
        let row = new Knot(KnotElement.ROW, { id: rowID, flags: flags || '' });
        row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: '' }));
        delete this.lastRowActions[this.props.data.form.$.id][panelID][rowID];
        if (this.lastRows[this.props.data.form.$.id]?.[panelID]?.[rowID]) {
          delete this.lastRows[this.props.data.form.$.id][panelID][rowID];
        }
        command?.children[panelID].childSet(rowID, row);
      }

      if (panelID === 'dummy') {
        for (let i in ids) {
          let id = +ids[i] + '';
          let row =
            command?.children?.[id] ||
            new Knot(KnotElement.ROW, {
              id: id,
              flags: flags || '',
            });
          if (!this.lastRowActions[this.props.data.form.$.id]) {
            this.lastRowActions[this.props.data.form.$.id] = {};
          }
          if (!this.lastRowActions[this.props.data.form.$.id][panelID]) {
            this.lastRowActions[this.props.data.form.$.id][panelID] = {};
          }
          this.lastRowActions[this.props.data.form.$.id][panelID][id] = option.value;
          row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: option.value }));
          command?.childSet(id, row);
        }
      } else {
        // Reset last rows if new context action initiated (Used for highlighting post response)
        // if (!this.lastRows[this.props.data.form.$.id]) {
        //   this.lastRows[this.props.data.form.$.id] = {};
        // }
        // this.lastRows[this.props.data.form.$.id][panelID] = {};

        for (let i in ids) {
          let id = +ids[i] + '';
          // let row =
          //   command?.children[panelID].children?.[id] ||
          //   new Knot(KnotElement.ROW, {
          //     id: id,
          //     flags: flags || ''
          //   });
          if (!this.lastRowActions[this.props.data.form.$.id]) {
            this.lastRowActions[this.props.data.form.$.id] = {};
          }
          if (!this.lastRowActions[this.props.data.form.$.id][panelID]) {
            this.lastRowActions[this.props.data.form.$.id][panelID] = {};
          }
          this.lastRowActions[this.props.data.form.$.id][panelID][id] = option.value;
          // row.childSet(option.key, new Knot(KnotElement.CTRL, { id: option.key, value: option.value }));
          // command?.children[panelID].childSet(id, row);
          this.addToRowCommand(panelID, id, option.key, option.value, -1);
        }
      }
      //TODO: Confirm if all dirtyflags are set to zero similar to action flags
      let dirty = +this.props.layout.screen?.form[0]?.$?.dirtyflag || -1;
      command?.updateFlag(dirty, false);
      this.setState((state) => {
        return { ...state, command };
      });
      this.actionHandler(option.action || { event: 'DEFAULT' });
    } else {
      // if(/^\d{1,2}$/.test(option.value)) {
      //   option.value = option.value.replace(/(^\d{1}$)/, 'F0$1');
      //   option.value = option.value.replace(/(^\d{2}$)/, 'F$1');
      // }
      this.actionHandler(option.action || { command: option.value, event: option.value });
    }
  }

  getLayout(id: string): any {
    return this.props.layout.screen.form[0].panel.filter(
      (panel: any) => panel['$'].id === id || panel['$'].sflcontrolfmt === id,
    )[0];
  }

  getPanelFlags(panelID: string): boolean[] {
    //Confirm this logic
    return (this.props.data.form.panel[0].$.flags || '').split('').map((x: string) => x === '1');
  }

  getPanelData(id: string): any {
    return !!this.props.panelMaster && !!this.props.panelMaster[this.props.data.form.$.id]
      ? this.props.panelMaster[this.props.data.form.$.id][id]
      : {};
  }

  getGlobalButtons() {
    //TODO: Confirm with MultiFunctionLoader
    // let actions = this.panelEvents[this.lastExecutedPanel];
    let loaded = this.actions;
    // actions = actions.map((a: Action) => {
    //   a = loaded.find((x: Action) => x.event === a.event) || a;
    //   return a;
    // });
    let actionEvents = this.panelEvents;
    const showFunctionTooltip = this.context.showFunctionTooltip;
    if (!!this.props.actionDefinitions && this.props.actionDefinitions.length > 0) {
      let buttons =
        this.props.layout.screen.form[0].group?.[0]?.button
          ?.filter((button: any) => {
            return (
              button.action[0].$.event === 'DEFAULT' ||
              button.$.text === 'OK' ||
              !!actionEvents[button.action[0].$.event]
            );
          })
          .map((button: any) => {
            let action =
              actionEvents[
                button.action[0].$.event
              ]; /* actions.find((x: any) => button.action[0].$.event === x.event)*/
            let def = this.props.actionDefinitions?.find((a: any) => a.event === action?.event);
            if (def) {
              action.text = def.text;
            }
            if (!!action && !!action.text) {
              button.$.text = action.text;
              button.$.tooltip = `${action.text || button.$.text}${action.event === 'F1' ? '' : '/' + action.event}`;
            } else {
              button.$.tooltip = `${button.$.text}${
                button.action[0].$.event === 'F1' ? '' : '/' + button.action[0].$.event
              }`;
            }
            return button;
          })
          .filter(
            (x: any) => !!x.$.text && x.$.visible !== 'false' && x.action?.[0]?.$?.event !== this.contextLoaderAction,
          ) || [];
      return [
        ...buttons,
        ...this.props.actionDefinitions
          .filter(
            (action: Action) =>
              action.event !== 'ENTER' &&
              !this.props.layout.screen.form[0].group?.[0]?.button.find(
                (b: any) => b?.action?.[0]?.$.event === action.event,
              ),
          )
          .sort((a: Action, b: Action) => +a.event.replace('F', '') - +b.event.replace('F', ''))
          .map((action: Action) => {
            return {
              $: {
                text: action.text,
                tooltip: `${action.text}${action.event === 'F1' ? '' : '/' + action.event}`,
              },
              action: [{ $: action }],
            };
          }),
        ...this.props.actionDefinitions
          .filter((action: Action) => action.event === 'ENTER')
          .map((action: Action) => {
            return {
              $: {
                text: action.text,
                tooltip: `${action.text}`,
              },
              action: [{ $: action }],
            };
          }),
      ].filter((button: any) => {
        let event = button.action?.[0]?.$?.event;
        return event === 'ENTER' || event === 'DEFAULT' || (event && !!actionEvents[event]);
      });
    }
    return (
      this.props.layout.screen.form[0].group?.[0]?.button
        ?.filter((button: any) => {
          return button.action?.[0].$.event === 'DEFAULT' || !!actionEvents[button.action?.[0].$.event];
        })
        .map((button: any) => {
          let action =
            actionEvents[button.action[0].$.event]; /* actions.find((x: any) => button.action[0].$.event === x.event)*/
          if (!!action && !!action.text) {
            button.$.text = action.text;
            button.$.tooltip = `${action.text || button.$.text}${action.event === 'F1' ? '' : '/' + action.event}`;
          } else {
            button.$.tooltip = `${button.$.text}${
              button.action?.[0].$.event === 'F1' ? '' : '/' + button.action?.[0].$.event
            }`;
          }
          return button;
        })
        .filter(
          (x: any) => !!x.$.text && x.$.visible !== 'false' && x.action?.[0]?.$?.event !== this.contextLoaderAction,
        ) || []
    );
  }

  getPanelActions(): any {
    //TODO: Confirm with MultiFunctionLoader
    let actions: any[] = [];
    //let globalButtons = this.getGlobalButtons();
    for (let index in this.panels) {
      let c = this.panels[index];
      if (!c.layout?.actions) continue;
      let props = c.layout?.actions[0]?.action
        ?.map((x: any) => {
          x['$'].visible = x['$'].visible !== 'false';
          x.$.panelID = c.panelID;
          return x['$'];
        })
        .filter((action: any) => {
          let dConstraint = new Constraint(action.disableconstraints);
          return !!action.visible && !dConstraint.evaluate(this.flags, false);
        });
      actions = [...actions, ...(props ? props : [])];
    }
    actions = [...actions, ...(this.props.layout.screen.form[0].actions?.[0]?.action?.map((x: any) => x.$) || [])];
    actions.sort((a: any, b: any) => {
      if (a.text > b.text) return 1;
      else if (a.text < b.text) return -1;
      else return 0;
    });
    let uniqueActions = Array.from(new Set(actions.map((x) => x.event))).map((event: any) =>
      actions.find((a: any) => a.event === event),
    );
    return uniqueActions;
  }

  sendCustomCommand(command: string, option?: { action: Action }, id?: string) {
    let exportToFile;
    if (option?.action?.exportType) {
      exportToFile = {} as exportToFile;
      if (option?.action?.exportType === '*CSV') {
        exportToFile.generateRows = generateRows_ISO;
        exportToFile.type = exportType.csv;
        exportToFile.export = exportToCsv;
      }
      if (option?.action?.exportType === '*EXCEL') {
        exportToFile.export = exportToXlsx;
        exportToFile.generateRows = generateRows_XLSX;
        exportToFile.type = exportType.xlsx;
      }
      exportToFile.decimalsign = command.includes('QueryManager')
        ? '.'
        : this.props.settings?.regionals?.codes[0]?.$?.decimalsign;
      exportToFile.dateFormattingServerInfo = this.props.settings?.regionals?.dateformats[0]?.$;
    }
    let customCommand = new Command(id ?? 'customCommand', new Knot(KnotElement.FORM));
    customCommand.toString = () => {
      return command;
    };
    this.props.startLoading?.();
    this.props.interact?.(
      this.props.id,
      customCommand,
      this.props.endLoading,
      () => {},
      this.errHandler,
      undefined,
      undefined,
      exportToFile,
    );
  }

  handleArrowNavigation(up: boolean) {
    const getPositionInfo = (element: Element, container: Element): { element: Element; x: number; y: number } => {
      const margin = 2;
      const rect = element.getBoundingClientRect();
      const x = ~~((rect.left + margin + container.scrollLeft) / (margin * 2));
      const y = ~~((rect.top + margin + container.scrollTop) / (margin * 2));
      return { element: element, x: x, y: y };
    };

    const getSortedNavigatableElements = (container: Element): { element: Element; x: number; y: number }[] => {
      return Array.from(container.querySelectorAll('[data-arrow-navigation=true]'))
        .map((el) => getPositionInfo(el, container))
        .sort((a, b) => {
          if (a.x < b.x) return -1;
          if (a.x > b.x) return 1;
          return a.y < b.y ? -1 : 1;
        });
    };

    // Get current active element
    // ==========================
    let fromElement = document.activeElement?.closest('[data-arrow-navigation=true]');
    if (!fromElement) return;

    // Get supported containers
    // ========================
    let currentContainer = fromElement.closest('.window.active .panel-area');
    let nextContainer: Element | null = null;

    let parentContainer = fromElement.closest('.window.active.modal') || document; // Remark: only consider elements in prompt dialog if appropriate
    if (currentContainer) {
      nextContainer = parentContainer.querySelector('.window.active .button-bar') || currentContainer;
    } else {
      currentContainer = fromElement.closest('.window.active .button-bar');
      if (!currentContainer) {
        return;
      } else {
        nextContainer = parentContainer.querySelector('.window.active .panel-area') || currentContainer;
      }
    }

    // Determine to element
    // ====================
    let toElement: Element | null = null;

    // Get sorted "navigatable" elements current container
    // ---------------------------------------------------
    let currentElements = getSortedNavigatableElements(currentContainer);

    // Get index of the from element
    // -----------------------------
    let index = currentElements.findIndex((el) => el.element === fromElement);
    if (index === -1) return; // not expected to be ever executed (program protection)

    // Increment/decrement index
    // -------------------------
    index += up ? -1 : 1;

    // Case resulting index within current array boundaries
    // ----------------------------------------------------
    if (index >= 0 && index < currentElements.length) {
      toElement = currentElements[index].element;

      // Case resulting index outside current array boundaries
      // -----------------------------------------------------
    } else {
      // --> get sorted "navigatable" elements next container
      let nextElements: { element: Element; x: number; y: number }[] = [];
      if (nextContainer === currentContainer) {
        nextElements = currentElements;
      } else {
        nextElements = getSortedNavigatableElements(nextContainer);
        if (nextElements.length === 0) nextElements = currentElements;
      }

      // --> case arrow up: last element next container
      if (up) {
        toElement = nextElements[nextElements.length - 1].element;

        // --> case arrow down: first element next container
      } else {
        toElement = nextElements[0].element;
      }
    }

    // Give focus to resulting to element
    // ==================================
    if (!toElement) return;
    if (fromElement === toElement) return;
    if (typeof (toElement as any).focus === 'function') {
      if (toElement.classList.contains('data-table-span') || toElement.classList.contains('radio-buttons-group')) {
        (toElement as any).directFocus = true;
      }
      (toElement as any).focus();
    }
  }

  getFormLayout() {
    return this.props.layout;
  }

  getCommand() {
    return this.command;
  }

  private previousPanelInfo: { formId: string; panelIds: string[] } = { formId: '', panelIds: [] };
  private cancelSubmitAperioViewCommand: (() => void) | undefined;
  handleAperioView() {
    // Collect ALL active links defined for this form (& panels)
    // =========================================================

    // --> Get current form and panels from data
    const formId: string = this.props.data?.form?.$?.id;
    const activePanelIds: string[] = (this.props.data?.form?.panel || [])
      .map((pan: any) => pan.$.id)
      .filter((id: string) => id !== 'MSGSFL');

    // --> Get ALL active links defined for this form (& panels)
    const getActiveLinks = (
      formId: string,
      activePanelIds: string[],
      regionals: Record<string, any>,
    ): AperioViews.Link[] => {
      // --> Skip nonsense
      if (!formId || !activePanelIds || activePanelIds.length < 1) return [];

      // --> Get aperio view configuration settings
      const aperioViews = regionals?.aperioviews as AperioViews;
      if (!aperioViews?.links || aperioViews.links.length < 1) return [];

      // --> Filter links for current form (& panels)
      const result: AperioViews.Link[] = aperioViews.links.filter((link) => {
        return (
          link.formId === formId &&
          (link.panelIds.length < 1 || link.panelIds.some((panelId) => activePanelIds.includes(panelId)))
        );
      });
      return result;
    };
    const formAperioLinks = getActiveLinks(formId, activePanelIds, this.props.settings?.regionals);
    this.setState((state) => {
      return { ...state, formAperioLinks };
    });

    // --> Save active form links in redux state
    this.props.setActiveAperioLinks?.(formAperioLinks);

    // --> No further processing if no active links
    if (formAperioLinks.length < 1) {
      this.cancelSubmitAperioViewCommand?.();
      this.cancelSubmitAperioViewCommand = undefined;
      this.previousPanelInfo = { formId, panelIds: activePanelIds };
      return;
    }

    // Handle "on form load" aperio event
    // ==================================

    // --> No "on form load"" if no new panels loaded
    const newPanelsAreLoaded = (): boolean => {
      let changed: boolean = false;
      if (formId !== this.previousPanelInfo.formId) {
        changed = true;
      }
      if (activePanelIds.length !== this.previousPanelInfo.panelIds.length) {
        changed = true;
      }
      if (activePanelIds.some((id) => !this.previousPanelInfo.panelIds.includes(id))) {
        changed = true;
      }

      this.previousPanelInfo = { formId, panelIds: activePanelIds };

      return changed;
    };
    if (!newPanelsAreLoaded()) return;

    // --> Cancel previous submit of Aperio view command (if any)
    this.cancelSubmitAperioViewCommand?.();
    this.cancelSubmitAperioViewCommand = undefined;

    // --> Get "on form load" aperio event info
    const getFormLoadAperioEventInfo = (): { event: AperioViews.Link.Event; link: AperioViews.Link } | undefined => {
      for (const link of formAperioLinks) {
        if (link.panelIds.length < 1 || activePanelIds.every((id) => link.panelIds.includes(id))) {
          const event = link.events.find((event) => event.type === AperioViews.EventTypes.ON_FORM_LOAD);
          if (event) return { event, link }; // Remark: only ONE selected event expected (else return "first")
        }
      }
      return undefined;
    };
    const eventInfo = getFormLoadAperioEventInfo();
    if (!eventInfo) return;

    // --> Submit Aperio event command
    const setAperioViewCommand = () => {
      this.props.updateContext?.({
        aperioViewCommand: AperioView.getCommandBase(
          eventInfo.link,
          this.command,
          this.props.data,
          this.props.layout,
          this.props.settings?.regionals,
        ),
      });
    };
    const [submitCommand, cancel] = debounce(setAperioViewCommand, 50);
    submitCommand();
    this.cancelSubmitAperioViewCommand = cancel;
  }
  getEnquiryTemplateFunctionKeys() {
    let enqBindings: string[] = [];
    this.props.enquiries?.top?.enquiries?.[0]?.enq
      ?.filter((menu: any) => !!menu.$.desc.trim())
      .forEach((menu: any) => {
        enqBindings.push(`shift+f${+menu.$.cmd.replace('F', '') - 12}`);
      });
    return enqBindings.join(',');
  }

  handleEnquiryTemplateChanged() {
    if (sessionStorage.getItem('enquiry_window') === 'current') {
      const currentScope = hotkeys.getScope();
      // Unbind keys of previous template
      hotkeys.unbind(enquiryHotKeys.join(','), currentScope);
      // Get keys of new template
      const keys = this.getEnquiryTemplateFunctionKeys();
      // Bind keys of new template
      hotkeys(keys, { keyup: true, keydown: true, scope: currentScope }, (event, handler) => {
        if (!this.props.dialog && event.type === 'keyup') {
          let match = handler.shortcut.match(/^shift\+f(\d+)$/i);
          if (match && match[1] && !isNaN(+match[1])) {
            this.actionHandler({ event: `F${+match[1] + 12}` });
          }
          event.stopPropagation();
          event.stopImmediatePropagation();
          event.preventDefault();
          return false;
        }
      });
    }
  }

  render() {
    // let panelButtons: number = this.getGlobalButtons().length;
    let msgLabels = [];
    let msgButtons = [];
    for (let i in this.props.layout.screen.form[0].group) {
      let group = this.props.layout.screen.form[0].group[i];
      msgLabels.push(...(group?.label?.map((x: any) => x.$.text) || []));
      msgButtons.push(...(group?.button || []));
    }
    if (this.props.data.form.$.id === 'MSG' || this.props.layout?.screen?.form[0]?.$?.id === 'error') {
      return (
        <Container fluid style={{ paddingTop: '20px' }}>
          <div className={'work-plus-context-area'}>
            <div className={'button-rows-1 work-area'}>
              <Row>
                <Col>
                  <h3>{XT.getDynamicValue(this.props, this.props.layout.screen.form[0].$?.text)}</h3>
                  <p>
                    {msgLabels.map((s: string) => (
                      <>
                        <small>{XT.getDynamicValue(this.props, s)}</small>
                        <br />
                      </>
                    ))}
                  </p>
                </Col>
              </Row>
              <Row>
                {msgButtons.map((x: any) => (
                  <Col md={2}>
                    <Button
                      action={x.action[0].$}
                      actionHandler={(e) => {
                        let command = new Command(this.props.data.form.$.id, new Knot(KnotElement.INFDS, {}));
                        let flags = (this.props.data.form.panel[this.props.data.form.panel.length - 1].$.flags || '')
                          .split('')
                          .map((x: string) => x === '1');
                        for (let index in this.props.data.form.panel) {
                          let panel = this.props.data.form.panel[index];
                          this.command.childSet(
                            panel.$.id,
                            new Knot(KnotElement.PANEL, {
                              id: panel.$.id,
                              flags: flags.map((x: boolean) => (x ? '1' : '0')).join(''),
                            }),
                          );
                        }
                        let defaultPanel = this.props.data.form.panel.find(
                          (x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1,
                        );
                        let panel = defaultPanel?.$.id || '';
                        command.setAction(e.event);
                        command.setPanel(panel);
                        // this.command.setCursor(ddspos);
                        this.props.startLoading?.();
                        this.props.interact?.(this.props.id, command, this.props.endLoading, () => {});
                      }}
                      id={x.$.id}
                      name={XT.getDynamicValue(this.props, x.$.text)}
                      variant={'outline-dark full-width text-center'}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <ContextArea
              isDialog={!!this.props.isDialog}
              data={this.props.data}
              messages={this.messages}
              resizerRef={this.resizerRef}
              window={this.props.id}
              restoreFocusWindow={this.props.restoreFocusActiveWindow}
            />
          </div>
          <Toolbar
            actionHandler={this.actionHandler}
            startLoading={this.props.startLoading}
            endLoading={this.props.endLoading}
            quickLaunch={this.props.quickLaunch}
            restoreFocusWindow={this.props.restoreFocusActiveWindow}
            isDialog={!!this.props.isDialog}
          />
        </Container>
      );
    }

    let buttons = this.getGlobalButtons();
    let titles = this.panels.flatMap((panel: PanelProps) => [...(this.state.title[panel.panelID] ?? [])]); //Parse titles in panel order
    return (
      <LoggerContext.Consumer>
        {(loggerContext) => {
          this.handleLoggerContext(loggerContext);
          return (
            <CommandContext.Provider
              value={{
                command: this.command,
                attachments: this.props.attachments || [],
                errors: (this.messages || []).length > 0,
                setAction: this.setAction,
                sendCommand: this.sendCommand,
                addToCommand: this.addToCommand,
                addToRowCommand: this.addToRowCommand,
                removeFromCommand: this.removeFromCommand,
                removeFromRowCommand: this.removeFromRowCommand,
                setScope: this.setScope,
                updateFlag: this.updateFlag,
                windowData: this.props.data,
                cursor: this.getCursor(),
                settings: this.props.settings,
                promptHandler: this.promptHandler,
                setSelectedRows: this.setSelectedRows,
                setSelectedOption: this.setSelectedOption,
                localization: this.props.localization,
                lastRows: this.lastRows,
                sendCustomCommand: this.sendCustomCommand,
                windowID: this.props.id,
                reload: this.reload,
                windowLayout: this.props.layout,
                formAperioLinks: this.state.formAperioLinks,
                getCommand: this.getCommand,
                getFormLayout: this.getFormLayout,
              }}
            >
              <ExportContext.Provider value={{ rows: this.state.rowCount, show: this.state.exporting }}>
                <Exporter
                  export={() => {
                    //Stop export and call callback;
                    this.props.stopExport?.(this.props.id);
                  }}
                  abort={() => {
                    //Stop export and callback
                    this.props.stopExport?.(this.props.id, true);
                  }}
                />
              </ExportContext.Provider>

              <div
                onMouseUp={() => {
                  document.body.className = document.body.className.replace(' no-select', '').replace(' resizing', '');
                  this.resizerRef?.current?.removeAttribute('data-resizing');
                }}
                onMouseMove={(e: MouseEvent<HTMLSpanElement>) => {
                  if (
                    e.button === 0 &&
                    e.buttons === 1 &&
                    this.resizerRef?.current?.getAttribute('data-resizing') === 'true' &&
                    this.resizerRef
                  ) {
                    this.resizerRef.current.style.flex = `1 1 ${
                      (this.resizerRef.current.closest('.window')?.clientWidth || 0) - e.pageX - 72
                    }px`;
                    let container = this.resizerRef.current.closest('.work-plus-context-area');
                    if (container && container.firstChild)
                      (container.firstChild as HTMLElement).style.flex = `1 1 ${e.pageX}px`;
                  }
                }}
                onKeyDown={(event: React.KeyboardEvent) => {
                  if (
                    ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].includes(event.key) &&
                    event.shiftKey &&
                    this.props?.dialog
                  ) {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                data-window={this.props.id}
                onChange={this.changeHandler}
                onFocus={this.scopeHandler}
                onClick={this.contextHandler}
                onContextMenu={this.contextHandler}
              >
                <input type="hidden" className="event-dispatcher-dummy" value=" " />
                {!!this.props.dialog && this.props.dialog.data.form.$.id === 'ASTD900' ? (
                  <>
                    <Calendar
                      {...this.props.dialog}
                      startLoading={this.props.startLoading}
                      endLoading={this.props.endLoading}
                      dialog={undefined}
                      isDialog={true}
                    />
                    <Toolbar
                      actionHandler={this.actionHandler}
                      startLoading={this.props.startLoading}
                      endLoading={this.props.endLoading}
                      quickLaunch={this.props.quickLaunch}
                      restoreFocusWindow={this.props.restoreFocusActiveWindow}
                      isDialog={true}
                      disableRefresh={true}
                    />
                  </>
                ) : (
                  <Modal
                    onHide={this.scopeHandler}
                    onShow={this.props.onModalWindowShow}
                    animation={false}
                    keyboard={false}
                    show={!!this.props.dialog}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className={'window active'}
                  >
                    <Modal.Body
                      style={{
                        width: `${
                          this.props.dialog?.layout?.screen?.form[0]?.$?.width
                            ? this.props.dialog?.layout?.screen?.form[0]?.$?.width * wwm + 'px'
                            : 'auto'
                        }`,
                        height: `${
                          this.props.dialog?.layout?.screen?.form[0]?.$?.height
                            ? this.props.dialog?.layout?.screen?.form[0]?.$?.height * whm + 'px'
                            : 'auto'
                        }`,
                      }}
                    >
                      {!!this.props.dialog && (
                        <Window
                          {...this.props.dialog}
                          startLoading={this.props.startLoading}
                          endLoading={this.props.endLoading}
                          dialog={undefined}
                          isDialog={true}
                          restoreFocusActiveWindow={this.props.restoreFocusActiveWindow}
                        />
                      )}
                    </Modal.Body>
                  </Modal>
                )}

                <div className={'work-plus-context-area'}>
                  <div className={'button-rows-1 work-area'}>
                    <Title
                      crumbs={
                        titles?.length > 0 && titles[0]
                          ? titles
                          : [XT.getDynamicValue(this.props, this.props.layout.screen.form[0].$.text || '') || '']
                        // this.state.title && this.state.title[0]
                        //   ? this.state.title
                        //   : [XT.getDynamicValue(this.props, this.props.layout.screen.form[0].$.text || '') || '']
                      }
                      id={'title' + this.props.id}
                      className="custom-popup-modal"
                    />
                    <div className={'panel-area'} tabIndex={1}>
                      {this.panels.map((props: PanelProps) => (
                        <Panel
                          key={this.props.id + '-' + props.id}
                          {...props}
                          panelCount={this.panels.length + (this.tabs ? 1 : 0)}
                          protectedPanel={this.protectedPanel}
                          executedPanel={this.lastExecutedPanel}
                          panelEvents={this.panelEvents}
                          dirtyflag={props.layout.$.dirtyflag}
                          updateTab={this.updateTab}
                          contextMenuHandler={this.contextMenuHandler}
                          contextLoader={this.contextLoader}
                          promptHandler={this.promptHandler}
                          actionHandler={this.actionHandler}
                          loadMore={this.loadMore}
                          updateTitle={this.updateTitle}
                        />
                      ))}
                      {!!this.tabs && (
                        <>
                          <Tabs {...this.tabs} />
                        </>
                      )}
                    </div>
                  </div>
                  {!this.props.isDialog && (
                    <ContextArea
                      isDialog={!!this.props.isDialog}
                      data={this.props.data}
                      messages={this.messages}
                      resizerRef={this.resizerRef}
                      window={this.props.id}
                      restoreFocusWindow={this.props.restoreFocusActiveWindow}
                    />
                  )}
                </div>
                <Container fluid>
                  <Row className="button-bar" noGutters>
                    <Col style={this.props.isDialog ? { paddingTop: '8px' } : {}} sm={12} md={12} lg={12} xl={12}>
                      <Row noGutters>
                        {[
                          ...buttons?.slice(0, 10).map((button: any, i: number) => {
                            let attrs: any = button.$;
                            let name = XT.getDynamicValue(this.props, attrs.text || '');
                            const showFunctionTooltip = this.context.showFunctionTooltip;
                            let props: ButtonProps = {
                              name: name,
                              id: attrs.id || attrs.event,
                              variant: 'outline-dark',
                              className: 'truncate btn-block',
                              action: button.action ? button.action[0].$ : { event: attrs.event },
                              actionHandler: this.actionHandler,
                              tooltip: XT.getDynamicValue(this.props, attrs.tooltip || '') || `${name} /${attrs.event}`,
                              showFunctionTooltip: showFunctionTooltip,
                            };
                            return (
                              <Col key={i} md={this.props.isDialog ? '2' : '1'}>
                                <Button {...props} />
                              </Col>
                            );
                          }),
                        ]}
                        <Col md="1">
                          <DropdownButton
                            className="function-loader"
                            hidden={buttons.length <= 10}
                            as={ButtonGroup}
                            id={`LoadedFunction`}
                            title={<SquareIcon className="more-button">{Icons.KebabMenu1}</SquareIcon>}
                            variant="outline-dark"
                            data-event={'ignore'}
                          >
                            {buttons?.slice(10).map((button: any) => {
                              let attrs = button.$;
                              const showFunctionTooltip = this.context.showFunctionTooltip;
                              let props: ButtonProps = {
                                name: attrs.text,
                                id: attrs.id || attrs.event,
                                variant: 'outline-dark',
                                className: 'truncate btn-block',
                                action: button.action ? button.action[0].$ : { event: attrs.event },
                                actionHandler: this.actionHandler,
                                showFunctionTooltip: showFunctionTooltip,
                              };
                              return (
                                <Dropdown.Item
                                  key={props.id}
                                  as="button"
                                  onClick={() => this.actionHandler(props.action)}
                                >
                                  {props.name}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                {!this.props.dialog && (
                  <Toolbar
                    actionHandler={this.actionHandler}
                    startLoading={this.props.startLoading}
                    endLoading={this.props.endLoading}
                    quickLaunch={this.props.quickLaunch}
                    restoreFocusWindow={this.props.restoreFocusActiveWindow}
                    isDialog={!!this.props.isDialog}
                  />
                )}
              </div>
            </CommandContext.Provider>
          );
        }}
      </LoggerContext.Consumer>
    );
  }
}

export const Window = connect(
  (state: any, oldProps: WindowProps) => {
    let window = state.desktop.windows[oldProps.id];

    return {
      driverList: state.desktop.driverList[window?.activeDriver],
      localization: Localization.instance,
      settings: state.desktop.settings,
      enquiries: state.desktop.enquiries,
      actionDefinitions: window?.actionDefinitions,
      contextDefinitions: window?.contextDefinition,
      tabSequence: window?.data?.form.$?.callstack
        ?.split(',')
        ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
        ? window?.tabSequenceBP
        : window?.tabSequenceItem,
    };
  },
  {
    interact: (
      windowID: string,
      command: Command,
      endLoading: Function,
      scopeHandler: Function,
      errHandler: Function,
      novalidate?: boolean,
      isPageLoad?: boolean,
      exportToFile?: exportToFile,
      isEOF?: boolean,
      disabledOn?: string,
      EOFParams?: {
        columns: any[];
        rowCount: number;
        attributes: Record<string, any>;
        panelID: string;
        cb: Function;
        reload: Function;
        refreshCommand: string;
      },
    ) =>
      WindowManger.Interact(
        windowID,
        command,
        endLoading,
        scopeHandler,
        errHandler,
        novalidate,
        isPageLoad,
        exportToFile,
        isEOF,
        disabledOn,
        EOFParams,
      ),
    loadContext: WindowManger.LoadContext,
    loadActions: WindowManger.LoadActions,
    quickLaunch: WindowManger.Launch,
    setActiveAperioLinks: WindowManger.SetActiveAperioLinks,
    updateContext: (payload: any) => ({ type: WindowActions.UPDATE_CONTEXT, payload: payload }),
    CloseWindow: (windowId: string, endLoading: Function) => WindowManger.CloseWindow(windowId, endLoading),
  },
)(WindowComponent);

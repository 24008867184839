import { useContext, useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { MenuItemProps, Dropdown, DropdownProps, Modal } from '@iptor/base';
import { ApplicationContext, Program } from '@iptor/base';
import { ContextMenuEnum } from '../layout/FormLayout/components/SideMenuContextProvider';

export const FormToolbar = (props: { handleContextMenu?: (type: ContextMenuEnum) => void }) => {
  const {
    quicklinks,
    quickLaunch,
    endLoading,
    startLoading,
    flattenedMenu,
    getMenuItems,
    restoreFocusWindow,
    refresh,
    handleSettingsShow,
    handleRoleShow,
  } = useContext(ApplicationContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const queryBuilderProgram: Program | undefined = getMenuItems?.('QueryBuilder', flattenedMenu);
  const restoreFocus = () => {
    restoreFocusWindow?.();
  };

  const theme = useTheme();

  const quicklinksOptions: MenuItemProps[] = [];
  quicklinks.map((quicklink: Program) => {
    const programFromMenu = getMenuItems?.(quicklink.id, flattenedMenu);
    quicklink = { ...quicklink, ...programFromMenu };
    if (programFromMenu) {
      quicklinksOptions.push({
        text: quicklink.text,
        icon: quicklink.image,
        onClick: () => {
          if (startLoading && quickLaunch && endLoading) {
            startLoading();
            quickLaunch(quicklink, endLoading, quicklink.view === 'ApplicationView' ? 'application_window' : 'program');
          }
        },
      });
    }
  });

  const contextHelpOptions: MenuItemProps[] = [
    {
      icon: 'help_circle',
      text: 'Context Help',
      onClick: () => props.handleContextMenu?.(ContextMenuEnum.CONTEXT_HELP),
    },
    {
      icon: 'paperclip',
      text: 'Attachments',
      onClick: () => props.handleContextMenu?.(ContextMenuEnum.ATTACHMENTS),
    },
    {
      icon: 'attachFile',
      text: 'Add Notes',
      onClick: () => props.handleContextMenu?.(ContextMenuEnum.ADD_NOTES),
    },
  ];

  const toolbarOptions: MenuItemProps[] = [
    {
      icon: 'printer',
      tooltip: 'Print',
      onClick: () => {
        restoreFocus();
        window.print();
      },
    },
    {
      icon: 'refresh',
      tooltip: 'Refresh',
      onClick: () => {
        if (refresh) {
          refresh();
        }
      },
    },
    {
      icon: 'chat1',
      tooltip: 'Display Server Message',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch(
          {
            id: 'MessageQueue',
            text: 'Display Server Messages',
          },
          endLoading,
          'static',
        );
      },
    },
    {
      icon: 'server_view',
      tooltip: 'Display Server Jobs',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch({ id: 'Jobs', text: 'Display Server Jobs' }, endLoading, 'static');
      },
    },
    {
      icon: 'copy',
      tooltip: 'Display Printer Output',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch(
          {
            id: 'Spooledfiles',
            text: 'Display Printer output',
          },
          endLoading,
          'static',
        );
      },
    },
    {
      icon: 'xml_doc',
      tooltip: 'Display Document Output',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch({ id: 'DocumentOutput', text: 'Display document output' }, endLoading, 'static');
      },
    },
    {
      icon: 'toolbar_settings',
      tooltip: 'Client Settings',
      onClick: () => handleSettingsShow(),
    },
    {
      icon: 'personal_settings',
      tooltip: 'Role Templates',
      onClick: () => handleRoleShow(),
    },
    {
      icon: 'upload',
      tooltip: 'File Upload',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch(
          {
            id: 'UI_FILEUP',
            text: 'File Upload',
            view: 'DashboardView',
          },
          endLoading,
          'aperio_program',
        );
      },
    },
    {
      icon: 'error_report',
      tooltip: 'Generate Error Report',
      onClick: handleModal,
    },
    {
      icon: 'query',
      tooltip: 'Query Builder',
      onClick: () => {
        restoreFocus();
        startLoading?.();
        quickLaunch(queryBuilderProgram, endLoading, 'aperio_program');
      },
    },
  ];

  const quickLinksSlotProps: DropdownProps = {
    backgroundColor: 'light',
    slotProps: {
      button: {
        children: 'References',
        color: 'primary',
        variant: 'outlined',
        //@ts-expect-error this works but MUI doesn't officially support this prior to v6 - see https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
        'data-testid': 'toolbar-references',
        id: 'toolbar-references',
        sx: {
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.primary.main}`
              : `1px solid ${theme.palette.divider}`,
          height: '48px',
          padding: '0 16px',
          borderRadius: `${theme.shape.small} !important`,
          outline: 'none',
          color: `${theme.palette.text.primary} !important`,
          svg: {
            stroke: `${theme.palette.text.primary} !important`,
          },
        },
      },
    },
    options: quicklinksOptions,
  };
  const contextHelpSlotProps: DropdownProps = {
    backgroundColor: 'light',
    slotProps: {
      button: {
        color: 'primary',
        variant: 'outlined',
        iconLeft: 'help_circle',
        //@ts-expect-error this works but MUI doesn't officially support this prior to v6 - see https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
        'data-testid': 'toolbar-help',
        id: 'toolbar-help',
        sx: {
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.primary.main}`
              : `1px solid ${theme.palette.divider}`,
          height: '48px',
          width: '48px',
          borderRadius: `${theme.shape.small} !important`,
          outline: 'none',
          '& span:first-child svg': {
            stroke: `${theme.palette.text.primary} !important`,
            transform: 'none',
            path: {
              stroke: 'inherit',
            },
          },
        },
      },
    },
    options: contextHelpOptions,
  };
  const toolbarSlotProps: DropdownProps = {
    backgroundColor: 'dark',
    slotProps: {
      iconButton: {
        color: 'primary',
        variant: 'outlined',
        icon: 'ellipsis',
        //@ts-expect-error this works but MUI doesn't officially support this prior to v6 - see https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
        'data-testid': 'toolbar-utils',
        id: 'toolbar-utils',
        sx: {
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.palette.primary.main}`
              : `1px solid ${theme.palette.divider}`,
          height: '48px',
          width: '48px',
          borderRadius: `${theme.shape.small} !important`,
          outline: 'none',
          svg: {
            stroke: `${theme.palette.text.primary} !important`,
            path: {
              stroke: 'inherit',
            },
          },
        },
      },
    },
    options: toolbarOptions,
  };

  return (
    <>
      <Stack spacing={2} justifyContent={'flex-end'} direction={'row'}>
        <Dropdown {...quickLinksSlotProps} />
        <Dropdown {...contextHelpSlotProps} />
        <Dropdown {...toolbarSlotProps} />
      </Stack>
      {/*Placeholder for Context Help Options*/}
      <Modal open={isModalOpen} onClose={handleModal}>
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Under Construction
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
            This feature is currently under development. Please check back later!
          </Typography>
        </Stack>
      </Modal>
    </>
  );
};

import React, { Context, FunctionComponent, useCallback, useEffect, useState } from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Login } from './views/Login';
import { Client } from './views/Client';
import { Logout } from './views/Logout';
import './styles/typography.scss';
import { Loader } from './views/partials/Loader';
import ForgotPassword from './views/ForgotPassword';
import ForgotPasswordMessage from './views/ForgotPasswordMessage';
import ResetPassword from './views/ResetPassword';
import { Localization, LocalizationInfo } from './framework/localization/Localization';
import { es, enUS, da, de, fi, fr, it, nl, nlBE, nb, pl, pt, sv, zhCN } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { PrivateRoute } from './router/PrivateRoute';
import { ErrorModal } from './components/ErrorModal';
import { hasAuthParams } from 'react-oidc-context';
// import { useLogger } from './logger/useLogger';

//Content editor imports
import '@react-page/editor/lib/index.css';
import { NotesHealthProvider } from './framework/your-note/NotesProvider';
import Test from 'Test';
import { IptorTranslationProvider, TooltipVisibilityProvider, theme, ThemeProvider, useCustomAuth } from '@iptor/base';

registerLocale('da', da);
registerLocale('de', de);
registerLocale('en', enUS);
registerLocale('es', es);
registerLocale('es_co', es);
registerLocale('es_mx', es);
registerLocale('fi', fi);
registerLocale('fr', fr);
registerLocale('it', it);
registerLocale('nl', nl);
registerLocale('nl_be', nlBE);
registerLocale('no', nb);
registerLocale('pl', pl);
registerLocale('pt', pt);
registerLocale('sv', sv);
registerLocale('zh', zhCN);

type LocaleContextType = {
  localeSettings: LocalizationInfo.Settings;
  setCurrentLocale: () => void;
};
export const LocaleContext: Context<LocaleContextType> = React.createContext<LocaleContextType>({
  localeSettings: { locale: 'en', dateFormat: 'MM/dd/yyyy', decimalSeparator: '.', groupSeparator: ',' },
  setCurrentLocale: () => {},
});

export type ClientContextType = {
  flattenedMenu?: Record<string, any>;
  startLoading: Function;
  endLoading: Function;
  getMenuItems: Function;
};
export const ClientContext: Context<ClientContextType> = React.createContext<ClientContextType>({
  flattenedMenu: {},
  startLoading: () => {},
  endLoading: () => {},
  getMenuItems: () => {},
});
export type AppProps = {
  appId: string;
};

// If auth needed outside components, turn on that feature
// export let authFromContext: { getAuth: () => AuthContextProps };

export const App: FunctionComponent<AppProps> = (props: { appId: string }) => {
  const { isAuthenticated, userLocale, signinRedirect, activeNavigator, isLoading } = useCustomAuth((auth) => ({
    isAuthenticated: auth.isAuthenticated,
    userLocale: auth.userLocale,
    signinRedirect: auth.signinRedirect,
    activeNavigator: auth.activeNavigator,
    isLoading: auth.isLoading,
  }));

  // If auth needed outside components, turn on that feature
  // const getAuth = useCallback(() => {
  //   return auth;
  // }, [auth]);
  // authFromContext = { getAuth };

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading && !hasTriedSignin) {
      signinRedirect().finally(() => {
        return false;
      });
      setHasTriedSignin(true);
    }
  }, [isAuthenticated, activeNavigator, isLoading, hasTriedSignin, signinRedirect]);

  const [loading, setLoading] = useState(false);
  const [langLoaded, setLangLoaded] = useState(false);
  // const logger = useLogger('App');
  // logger.trace('Rendering App');

  const preventDefFuncKey = (event: KeyboardEvent) => {
    if (/^f[0-9]{1,2}/.test(event.key?.toLowerCase())) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  };

  useEffect(() => {
    const defaultTheme = localStorage.getItem('default_theme');
    let theme = localStorage.getItem('theme');
    if (theme === 'default') theme = defaultTheme;
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', theme || '');
    document.addEventListener('keydown', preventDefFuncKey);

    return () => {
      document.removeEventListener('keydown', preventDefFuncKey);
    };
  }, []);

  const startLoading = () => setLoading(true);
  const endLoading = () => setLoading(false);

  const getSelectedLanguageFromAuthToken = async () => {
    if (!process.env.REACT_APP_IS_DISPATCHER) {
      await Localization.instance.setSettingsFromLanguage(userLocale || 'en');
    }
  };

  if (isAuthenticated) {
    if (!langLoaded)
      getSelectedLanguageFromAuthToken().then(() => {
        setLangLoaded(true);
      });

    return langLoaded ? (
      <IptorTranslationProvider>
        <ThemeProvider>
          <TooltipVisibilityProvider>
            <NotesHealthProvider>
              <Loader loading={loading} />
              <Router>
                <Switch>
                  {!process.env.REACT_APP_IS_DISPATCHER ? (
                    <>
                      <PrivateRoute
                        exact
                        path="/app"
                        component={Client}
                        componentProps={{
                          loading: loading,
                          startLoading: startLoading,
                          endLoading: endLoading,
                          appId: props.appId,
                        }}
                      />
                      <PrivateRoute
                        exact
                        path="/"
                        component={Login}
                        componentProps={{
                          loading: loading,
                          startLoading: startLoading,
                          endLoading: endLoading,
                        }}
                      />
                      <PrivateRoute
                        exact
                        path="/test"
                        component={Test}
                        componentProps={{
                          loading: loading,
                          startLoading: startLoading,
                          endLoading: endLoading,
                        }}
                      />
                      <PrivateRoute
                        exact
                        path="/logout"
                        component={Logout}
                        componentProps={{
                          loading: loading,
                          startLoading: startLoading,
                          endLoading: endLoading,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Route exact path="/">
                        <Login loading={loading} startLoading={startLoading} endLoading={endLoading} />
                      </Route>
                      <Route exact path="/app">
                        <Client
                          loading={loading}
                          startLoading={startLoading}
                          endLoading={endLoading}
                          appId={props.appId}
                        />
                      </Route>
                      <Route exact path="/forgot-password">
                        <ForgotPassword />
                      </Route>
                      <Route exact path="/forgot-password/message">
                        <ForgotPasswordMessage />
                      </Route>
                      <Route exact path="/reset-password">
                        <ResetPassword loading={loading} startLoading={startLoading} endLoading={startLoading} />
                      </Route>
                    </>
                  )}
                </Switch>
              </Router>
              <ErrorModal />
            </NotesHealthProvider>
          </TooltipVisibilityProvider>
        </ThemeProvider>
      </IptorTranslationProvider>
    ) : (
      <div></div>
    );
  } else {
    return <div></div>;
  }
};

// export default App;

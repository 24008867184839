import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
import './../../styles/Setting.scss';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Localization } from '../../framework/localization/Localization';
import { LocaleContext } from '../../App';
import { Dark } from '../../components/Dark';
import { XT } from '../../framework/handlers/xt';

export type ImportantLinksProps = {
  className?: string;
  hide: () => void;
  isShown: boolean;
};

export const ImportantLinks: FunctionComponent<ImportantLinksProps> = ({ isShown, hide }) => {
  return (
    <>
      <Modal className={'right-overlay'} show={isShown} animation={false} onHide={hide} size="lg" keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="mr-3">
              <SquareIcon className="icon-modal-header" size="20px">
                {Icons.Bookmark}
              </SquareIcon>
            </span>
            <span>{Localization.instance.getString('TXT_ImportantLinks')}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Setting-Content-container">
            <Row>
              <Col md={12}>
                <ListGroup>
                  {XT.importantLinks.map((link: { url: string; title: string }) => (
                    <ListGroupItem
                      className="pointer"
                      onClick={() => {
                        const urlOpenedInNewTab = window.open(link.url);
                        if (urlOpenedInNewTab) urlOpenedInNewTab.opener = null;
                      }}
                    >
                      {link.title}: {link.url}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

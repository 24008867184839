import { createContext, useContext } from 'react';
import { ClientSettings } from './types';
import dayjs from 'dayjs';

// default value used only when no provider is setup
export const ClientSettingsContext = createContext<ClientSettings>({
  locale: 'en',
  dateFormat: 'MM/dd/yyyy',
  decimalSeparator: '.',
  groupSeparator: ',',
  currency: '',
  reportingCurrency: '',
  handler: '',
});

export const ClientSettingsProvider = ClientSettingsContext.Provider;

const getCurrencySymbol = (currency: string) => {
  try {
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency })
      .formatToParts(1)
      .find((x) => x.type === 'currency').value;
  } catch (e) {
    return '';
  }
};

const formatNumber = ({
  number,
  decimalSeparator,
  groupSeparator,
  numDecimals = 2,
}: {
  number: number;
  decimalSeparator: string;
  groupSeparator: string;
  numDecimals?: number;
}) => {
  if (isNaN(number)) {
    return '';
  }
  const stringValue = numDecimals > 0 ? number.toFixed(numDecimals) : number.toString();
  const [integerPart, decimalPart] = stringValue.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
  return decimalPart !== undefined ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
};

const formatCurrency = ({
  amount,
  currency,
  decimalSeparator,
  groupSeparator,
  numDecimals = 2,
}: {
  amount: number;
  currency: string;
  decimalSeparator: string;
  groupSeparator: string;
  numDecimals?: number;
}) =>
  `${getCurrencySymbol(currency)} ${formatNumber({ number: amount, decimalSeparator, groupSeparator, numDecimals })}`;

export const useClientSettings = () => {
  const clientSettings = useContext(ClientSettingsContext);
  return {
    ...clientSettings,
    formatNumber: ({ number }: { number: number }) =>
      formatNumber({
        number,
        decimalSeparator: clientSettings.decimalSeparator,
        groupSeparator: clientSettings.groupSeparator,
      }),
    formatCurrency: ({ amount, currency }: { amount: number; currency: string }) =>
      formatCurrency({
        amount,
        currency,
        decimalSeparator: clientSettings.decimalSeparator,
        groupSeparator: clientSettings.groupSeparator,
      }),
    formatDate: ({ date }: { date: Date | string }) => dayjs(date).format(clientSettings.dateFormat?.toUpperCase()),
  };
};

import { forwardRef, PropsWithChildren } from 'react';
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';

export type TextProps = MuiTypographyProps & {
  className?: string;
};

export const Text = forwardRef<HTMLSpanElement, PropsWithChildren<TextProps>>(
  ({ className, children, ...props }, ref) => {
    return (
      <MuiTypography
        data-for="global"
        data-iscapture="true"
        data-tip={props.id}
        className={className}
        ref={ref}
        {...props}
      >
        {children}
      </MuiTypography>
    );
  },
);

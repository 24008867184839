/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  debounce,
  Dropdown,
  DropdownProps,
  IconButton,
  MenuItemProps,
  NumericField,
  RowHighlightType,
  TableCellRendererProps,
  TableColumn,
  TableFilter,
  TableRowValues,
  TableSettings,
  useClientSettings,
  useIptorTranslation,
} from '@iptor/base';
import { IptorTable, openAPIManager, OperationResponseDataData, OperationResponseItems } from '@iptor/business';
import { CircularProgress, Link, Stack } from '@mui/material';
import { forwardRef, ReactElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import DeleteOrderLinesDialog from '../../../dialogs/DeleteOrderLines';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { OrderLine, UpdateOrderLineFn } from '../constants';
import OrderDiscountLabel from '../../../components/discounts/OrderDiscountLabel';
import OrderStatus from '../../../components/OrderStatus';
import OrderText from '../../../dialogs/OrderText';
import { formatErrorText } from '../../../utils/formatError';
import { TextIcon } from '../../../dialogs/OrderText/components/TextIcon';
import { Order } from '../../../constants';
import AddItemGrid from './AddOrderLineGrid';
import { OrderLineUnit } from './OrderLineUnit';
import { OrderLineWarehouse } from './OrderLineWarehouse';

type OrderItemsTableProps = {
  passedOrderId?: number;
  refreshOrder?: () => void;
  order?: Order;
  customAdditionalFilters?: ReactElement;
  customAdditionalActions?: ReactElement;
};

export type SalesOrderLineType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrdersLines'>[0];

const OrderItemsTable = forwardRef(
  (
    { passedOrderId, refreshOrder, order, customAdditionalFilters, customAdditionalActions }: OrderItemsTableProps,
    ref,
  ) => {
    const { notify } = useOrderNotifications();
    const { formatCurrency, currency } = useClientSettings();
    const { t } = useIptorTranslation();

    const tableRef = useRef(null);
    const { orderId } = useParams<{ orderId: string }>();
    const currentOrderId = +orderId || +passedOrderId;
    const [loadingValidateItem, setLoadingValid] = useState<boolean>(false);
    const [openedTextOrderLineId, setOpenedTextOrderLineId] = useState<number | null>(null);
    const [orderLinesToDelete, setOrderLinesToDelete] = useState([]);
    const [selectedRows, setSelectedRows] = useState<SalesOrderLineType[]>([]);
    const [openedTextDialog, setOpenedTextDialog] = useState<null | {
      orderLineId: number;
      hasText: boolean;
    }>(null);

    const handleRefreshTable = () => {
      tableRef?.current?.refresh();
    };
    const handleRefreshHeader = () => {
      refreshOrder?.();
    };

    useImperativeHandle(ref, () => ({
      handleRefresh: () => {
        tableRef?.current?.refresh();
      },
    }));

    const updateOrderLine: UpdateOrderLineFn = async (
      orderId: number,
      line: number,
      data: Partial<OrderLine>,
      handleSuccess: (
        result: Partial<
          OperationResponseDataData<'internal.v1.base-orders', 'PUT /salesOrders/{order}/lines/{orderLine}'>
        >,
      ) => void,
      handleFailure: (
        error: Partial<
          OperationResponseDataData<'internal.v1.base-orders', 'PUT /salesOrders/{order}/lines/{orderLine}'>
        >,
      ) => void,
    ) => {
      openAPIManager
        .execute(
          'internal.v1.base-orders',
          'PUT /salesOrders/{order}/lines/{orderLine}',
          {
            order: orderId,
            orderLine: line,
          },
          {
            ...data,
          },
        )
        .then((result) => {
          handleSuccess(result.data.data);
        })
        .catch((error) => {
          handleFailure(error);
        });
    };

    const updateOrderLineQuantity = async ({
      line,
      newQuantity,
      currentValue,
      itemId,
      setColumnValueForRow,
      setInitialValue,
      orderId,
    }: {
      line: number;
      newQuantity: number;
      currentValue: number;
      itemId: string;
      setColumnValueForRow: (
        values: {
          column: string;
          value: TableRowValues;
        }[],
      ) => void;
      setInitialValue: (value: number) => void;
      orderId: number;
    }) => {
      if (newQuantity !== 0 && newQuantity != currentValue) {
        updateOrderLine(
          orderId,
          line,
          { orderQuantitySalesUnit: newQuantity, orderLine: line, item: itemId },
          (response) => {
            setInitialValue(newQuantity);
            setColumnValueForRow([
              { column: 'orderQuantitySalesUnit', value: response.orderQuantitySalesUnit },
              { column: 'netSalesValue', value: response.netSalesValue || 0 },
            ]);
            notify('success', 'Order line Updated Successfully');
            handleRefreshHeader();
          },
          (error) => {
            setColumnValueForRow([{ column: 'orderQuantitySalesUnit', value: currentValue }]);
            notify('error', formatErrorText(error));
          },
        );
      }
    };

    const [debouncedUpdateOrderLine, teardown] = debounce(updateOrderLineQuantity, 1500);

    const itemColumns: TableColumn[] = useMemo(
      () => [
        { display: t('common:TXT_Line'), key: 'orderLine', dataType: 'number' },
        {
          display: t('common:Att_category_ITEM'),
          key: 'item',
          cellRenderer: ({ row }: TableCellRendererProps) => (
            <Stack direction="row">
              <Stack direction="column">
                <Link component={RouterLink} to={`/items/${row.order}/${row.orderLine}/info`}>
                  <span>{row.description}</span>
                </Link>
                <small>{row.item}</small>
              </Stack>
              <OrderDiscountLabel discount={row.discountsExist} type="line" />
            </Stack>
          ),
        },
        {
          display: currentOrderId ? '' : 'Order ID',
          key: 'order',
          dataType: 'number',
          disableSort: currentOrderId ? true : false,
          disableFilter: currentOrderId ? true : false,
          cellRenderer: ({ value }: TableCellRendererProps) => {
            if (currentOrderId) {
              return <></>;
            }

            return (
              <Link component={RouterLink} to={`/orders/${value}/items`}>
                {value}
              </Link>
            );
          },
        },
        {
          display: t('common:TXT_Unit'),
          key: 'unit',
          cellRenderer: ({ row, value, setColumnValueForRow }: TableCellRendererProps) => {
            return (
              <OrderLineUnit
                headerData={{
                  orderId: row.order,
                  warehouse: row.warehouse,
                }}
                disabled={row.orderLineStatus > 20}
                options={[]}
                row={row}
                value={value}
                setColumnValueForRow={setColumnValueForRow}
                updateOrderLine={updateOrderLine}
                currentOrderId={row.order}
                handleRefreshHeader={handleRefreshHeader}
              />
            );
          },
        },
        {
          display: t('common:TXT_Warehouse'),
          key: 'warehouse',
          cellRenderer: ({ row, value, setColumnValueForRow }: TableCellRendererProps) => {
            return (
              <OrderLineWarehouse
                headerData={{
                  orderId: row.order,
                  warehouse: order?.warehouse,
                }}
                disabled={row.orderLineStatus > 20}
                options={[]}
                row={row}
                value={value}
                setColumnValueForRow={setColumnValueForRow}
                updateOrderLine={updateOrderLine}
                currentOrderId={row.order}
                handleRefreshHeader={handleRefreshHeader}
              />
            );
          },
        },
        {
          display: t('common:TXT_Avalaible_Qty'),
          key: 'availableQuantity',
          disableSort: true,
          dataType: 'number',
          cellRenderer: ({ row }: TableCellRendererProps) => {
            return (
              <>
                {row?.availableQuantity !== undefined ? (
                  <span>{row?.availableQuantity}</span>
                ) : (
                  <CircularProgress size={'15px'} />
                )}
              </>
            );
          },
        },
        {
          display: t('common:TXT_Quantity'),
          key: 'orderQuantitySalesUnit',
          dataType: 'number',
          cellRenderer: ({ value, row, setColumnValueForRow }: TableCellRendererProps) => {
            const initialValue = useRef<number>();
            useEffect(() => {
              initialValue.current = value;
            }, []);
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumericField
                  key={row.orderLine}
                  value={value as number}
                  buttonLayout="horizontal"
                  disabled={row.orderLineStatus > 20}
                  size="small"
                  onChangeNumeric={(newQuantity) => {
                    setColumnValueForRow([{ column: 'orderQuantitySalesUnit', value: newQuantity }]);
                    teardown();
                    debouncedUpdateOrderLine({
                      orderId: row.order,
                      line: row.orderLine,
                      newQuantity: newQuantity,
                      currentValue: initialValue.current,
                      itemId: row.item,
                      setColumnValueForRow,
                      setInitialValue: (value) => {
                        initialValue.current = value;
                      },
                    });
                  }}
                />
              </div>
            );
          },
        },
        {
          display: t('sales_orders:TABLE_COLUMN_netAmount'),
          key: 'netSalesValue',
          dataType: 'number',
          cellRenderer: ({ row }: TableCellRendererProps) => {
            return (
              <>
                {row?.netSalesValue || row?.netSalesValue === 0 ? (
                  <span>
                    {row?.netSalesValue !== '-' ? formatCurrency({ amount: row.netSalesValue, currency }) : '-'}
                  </span>
                ) : (
                  <CircularProgress size={'15px'} />
                )}
              </>
            );
          },
        },
        {
          display: t('common:TXT_Status'),
          key: 'orderLineStatus',
          dataType: 'number',
          cellRenderer: ({ value }: TableCellRendererProps) => <OrderStatus orderStatus={value} />,
        },
        {
          display: t('common:TXT_Actions'),
          key: 'actions',
          disableSort: true,
          disableFilter: true,
          cellRenderer: ({ row, setHighlightStateForRow }: TableCellRendererProps) => {
            useEffect(() => {
              if (openedTextOrderLineId === row.orderLine || openedTextDialog?.orderLineId === row.orderLine) {
                setHighlightStateForRow({
                  rowHighlightType: RowHighlightType.info,
                  rowHighlightMessage: '',
                });
              } else {
                setHighlightStateForRow({
                  rowHighlightType: RowHighlightType.normal,
                  rowHighlightMessage: '',
                });
              }
            }, [openedTextDialog?.orderLineId, openedTextOrderLineId]);

            return (
              <Stack display={'flex'} flexDirection={'row'} justifyContent="center">
                {row.textsExist && (
                  <TextIcon
                    open={openedTextOrderLineId === row.orderLine}
                    order={+orderId}
                    orderLine={row.orderLine}
                    handleTextOpen={(isOpen) => {
                      setOpenedTextOrderLineId(isOpen ? row.orderLine : null);
                    }}
                    handleOpenFullDialog={() => {
                      setOpenedTextDialog({ orderLineId: row.orderLine, hasText: row.textsExist });
                      setOpenedTextOrderLineId(null);
                    }}
                  />
                )}
                <IconButton
                  variant={'text'}
                  color="primary"
                  icon="delete3"
                  size="small"
                  onClick={() => setOrderLinesToDelete([row])}
                  tooltipText={t('common:TXT_Delete')}
                  disabled={row.orderLineStatus > 20}
                />
                <Dropdown
                  options={[
                    {
                      text: t('sales_orders:ORDERTEXT_orderText'),
                      value: 'text',
                      onClick: () => {
                        setOpenedTextDialog({ orderLineId: row.orderLine, hasText: row.textsExist });
                      },
                    },
                  ]}
                  backgroundColor="light"
                  slotProps={{
                    iconButton: {
                      icon: 'verticalMore',
                      color: 'inherit',
                      variant: 'text',
                      size: 'small',
                    },
                    menu: {
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    },
                  }}
                />
              </Stack>
            );
          },
        },
      ],
      [openedTextDialog?.orderLineId, openedTextOrderLineId, order],
    );

    const tableActionsOptions: MenuItemProps[] = [
      {
        icon: 'delete3',
        text: 'Delete',
        onClick: () => {
          setOrderLinesToDelete(selectedRows || []);
        },
        disabled: selectedRows.length === 0,
      },
    ];

    const tableActionsProps: DropdownProps = {
      backgroundColor: 'light',
      slotProps: {
        button: {
          children: 'Actions',
          color: 'primary',
          id: 'actions',
          variant: 'outlined',
          sx: {
            border: '1px solid #D6D6D6',
            height: '48px',
            padding: '0 16px',
            borderRadius: '4px',
            outline: 'none',
            color: '#525252',
            svg: {
              stroke: '#525252',
            },
          },
        },
      },
      options: tableActionsOptions,
    };

    return (
      <>
        {orderLinesToDelete.length > 0 && (
          <DeleteOrderLinesDialog
            salesOrderLines={orderLinesToDelete}
            show={orderLinesToDelete.length > 0}
            handleClose={(canceled) => {
              setOrderLinesToDelete([]);
              if (!canceled) {
                handleRefreshHeader();
                handleRefreshTable();
              }
            }}
          />
        )}

        {!!openedTextDialog && (
          <OrderText
            open={!!openedTextDialog}
            order={+orderId}
            orderLine={openedTextDialog.orderLineId}
            handleClose={(needsTableRefresh) => {
              if (needsTableRefresh) {
                handleRefreshTable();
              }
              setOpenedTextDialog(null);
            }}
            textExists={openedTextDialog.hasText}
            defaultLanguage={order?.language}
          />
        )}

        <IptorTable
          tableID={`orderItemsTable${currentOrderId ? '-' : ''}${currentOrderId ?? ''}`}
          apiId="internal.v1.base-orders"
          apiEndpoint={currentOrderId ? 'GET /salesOrders/{order}/lines' : 'GET /salesOrdersLines'}
          apiParams={
            currentOrderId
              ? { order: +currentOrderId, orderBy: 'orderLine DESC' }
              : { where: 'orderLineStatus<60', orderBy: 'order DESC' }
          }
          customRef={tableRef}
          loading={loadingValidateItem}
          slotProps={{
            refreshIconButton: {
              sx: { marginTop: currentOrderId ? '18px' : 0 },
            },
          }}
          columns={itemColumns as any}
          selectable={'multiple'}
          disableActionBar={false}
          onSelectedRowsChange={({ rows }) => {
            setSelectedRows(rows);
          }}
          additionalFilters={
            customAdditionalFilters ? (
              customAdditionalFilters
            ) : (
              <AddItemGrid
                currentOrderId={currentOrderId}
                handleRefresh={() => {
                  handleRefreshTable();
                  handleRefreshHeader();
                }}
                order={order}
                loadingValidateItem={loadingValidateItem}
                setLoadingValid={setLoadingValid}
              />
            )
          }
          additionalActions={
            <>
              <TableFilter
                slotProps={{
                  iconButton: {
                    sx: { marginTop: currentOrderId ? '18px' : 0 },
                  },
                }}
              />
              <TableSettings
                slotProps={{
                  iconButton: {
                    sx: { marginTop: currentOrderId ? '18px' : 0 },
                  },
                }}
              />
              <Dropdown {...tableActionsProps} />
              {customAdditionalActions}
            </>
          }
        />
      </>
    );
  },
);

export default OrderItemsTable;

export default (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.58313 17H3.58312C2.47855 16.9999 1.58312 16.1045 1.58313 14.9999L1.58321 2.99999C1.58321 1.89542 2.47864 1 3.58321 1H12.5834C13.688 1 14.5834 1.89543 14.5834 3V8.5M10.5834 14.1667L12.4168 16L16.4168 11.9998M5.08344 5H11.0834M5.08344 8H11.0834M5.08344 11H8.08344"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import { Accordion, DatePicker, NumericField, Text, useClientSettings, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { OperationResponseDataData, InventoryContractDropdown, WarehouseDropdown } from '@iptor/business';
import dayjs from 'dayjs';
import { formatDateToPayloadFormat } from '../../../utils/formatDate';

export type DispatchAndSourcingProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  setValue: UseFormSetValue<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function DispatchAndSourcing({ control, setValue, orderLineInfo }: DispatchAndSourcingProps) {
  const { t } = useIptorTranslation();

  const { formatDate } = useClientSettings();

  return (
    <Accordion
      headingTitle={t('sales_orders:ITEM_INFO_dispatchSourcing')}
      expanded={true}
      moreInfo={
        <Grid container spacing={2}>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_salesStockUnit')}`}</Text>
            <Text variant="body1">{orderLineInfo.orderQuantitySalesUnit || ' '}</Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_sourcingPol')}`}</Text>
            <Text variant="body1">{orderLineInfo.sourcingPolicy || ' '}</Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Delivery_Date')}`}</Text>
            <Text variant="body1">{formatDate({ date: orderLineInfo.deliveryDate }) || ' '}</Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Offer_Used')}`}</Text>
            <Text variant="body1">
              {`${orderLineInfo.orderFulfilmentRule ? orderLineInfo.orderFulfilmentRule : ' '} ${orderLineInfo.orderFulfilmentRuleOverride ? '/ ' + orderLineInfo.orderFulfilmentRuleOverride : ' '}`}
            </Text>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="dispatchTime"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                autoFocus={true}
                value={field.value ? dayjs(field.value) : null}
                onChange={(value) => setValue('dispatchTime', formatDateToPayloadFormat(value))}
                label={t('common:TXT_Dispatch_Date')}
                readOnly={orderLineInfo.orderLineStatus > 20}
                slotProps={{
                  textField: {
                    id: field.name,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name={'orderQuantitySalesUnit'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                value={field.value}
                id={field.name}
                fullWidth
                label={t('common:TXT_Quantity')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="warehouse"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <WarehouseDropdown
                item={orderLineInfo?.item}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                id={field.name}
                fullWidth
                label={t('common:TXT_Warehouse')}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="inventoryContractNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InventoryContractDropdown
                value={field.value}
                onChange={(value) => field.onChange(value)}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Inventory_Contract')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="transportTime"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                value={field.value}
                label={t('common:TXT_Transport_Time')}
                id={field.name}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="requestedDeliveryDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={t('common:TXT_Requested_Delivery_Date')}
                slotProps={{
                  textField: {
                    id: field.name,
                  },
                }}
                value={field.value ? dayjs(field.value) : null}
                onChange={(value) => setValue('requestedDeliveryDate', formatDateToPayloadFormat(value))}
                readOnly={orderLineInfo.orderLineStatus > 20}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="promisedDispatchDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={t('common:TXT_Promised_Date')}
                slotProps={{
                  textField: {
                    id: field.name,
                  },
                }}
                value={field.value ? dayjs(field.value) : null}
                onChange={(value) => setValue('promisedDispatchDate', formatDateToPayloadFormat(value))}
                readOnly={orderLineInfo.orderLineStatus > 45 || orderLineInfo.orderLineStatus < 20}
              />
            )}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
}

export default DispatchAndSourcing;

export type Order = {
  order: number;
  type?: string;
  orderStatus: number;
  totalAmountExclVAT: number;
  totalAmountExclVATSys: number;
  vatAmount: number;
  vatAmountSys: number;
  totalAmountInclVAT: number;
  totalAmountInclVATSys: number;
  exchangeRate: number;
  cashSales?: boolean;
  internalOrderType?: string;
  internalOrder?: boolean;
  orderTypeForMDC?: string;
  deliveryScheduleOrder?: boolean;
  deliveryScheduleNumber?: string;
  claimOrderType?: string;
  text?: number;
  heldReasonCode?: string;
  simulationOrderType: boolean;
  handler: string;
  customer: string;
  orderType: string;
  orderSourceCode?: string;
  dispatchAddress?: number;
  pricingDate?: string;
  orderPropertyCode?: string;
  salesPromotion?: string;
  promotionCode?: string;
  orderReference?: string;
  dispatchTime: string;
  warehouse?: string;
  inventoryContractNumber?: string;
  toWarehouse?: string;
  primaryCurrency: string;
  primaryCurrencyISO: string;
  supplier?: string;
  defaultBtBsupplier?: string;
  supplierOrderReference?: string;
  costCentre?: string;
  project?: number;
  projectPhase?: number;
  projectCostCode1?: string;
  projectCostCode2?: string;
  cashRegister?: string;
  customerReference: string;
  salesman: string;
  creditCard?: string;
  creditCardNo?: string;
  priceList?: string;
  status: string;
  customerName?: string;
  creditLimit?: number;
  totalBalanceSysCurr?: number;
  orderDate: string;
  orderTime: string;
  autoSource?: boolean;
  holdingCompany?: string;
  autoFulfilment?: boolean;
  parentCompany?: string;
  orderFulfilmentRule?: string;
  language?: string;
  ediCreated?: boolean;
  completeDelivery?: boolean;
  dispatchDateAdjustment?: boolean;
  printUnfulfilledSupply?: boolean;
  availCheckAtOrderEntry?: string;
  availCheckAtPicklistPrint?: boolean;
  defaultWarehouse?: boolean;
  firmAllocateOrder?: boolean;
  vat?: boolean;
  vatHandlingCode?: string;
  taxCatalogue?: string;
  backlog?: boolean;
  salesTax?: boolean;
  calculateSummaryDiscount?: boolean;
  backlogAfterPickListConf?: boolean;
  termsOfDelivery: string;
  shipmentPreparation?: string;
  mannerOfTransport: string;
  transportNoteWithAmounts?: boolean;
  goodsMarking: string;
  pickConsolidation?: string;
  returnCollectionRequired?: boolean;
  totalWeight: number;
  unitForWeight?: string;
  udf1Header_CashFee?: string;
  freightFee: number;
  manualFreightFee?: boolean;
  vatHandlingCodeOfFreight?: string;
  freightTaxCatalogue?: string;
  udf3Header_CashFee?: string;
  insuranceFee: number;
  calculateInsurance?: boolean;
  vatHandlingCodeOfInsurance?: string;
  insuranceTaxCatalogue?: string;
  insurancePercentage?: number;
  surchargeHandling?: boolean;
  udf2Header_CashFee?: string;
  postageFee: number;
  vatHandlingCodeOfPost?: string;
  postageTaxCatalogue?: string;
  administrationFees: number;
  administrationFee?: boolean;
  vatHandlingCodeOfAdmFee?: string;
  adminTaxCatalogue?: string;
  invoiceFees: number;
  invoiceFee?: boolean;
  vatHandlingCodeOfInvFee?: string;
  invoiceTaxCatalogue?: string;
  documentType?: string;
  bankOrderCurrency?: string;
  bankSystemCurrency?: string;
  relatedInvoice: number;
  holdOrder: boolean;
  automaticSettlement?: boolean;
  debtor: string;
  heldOrderLinesExist?: boolean;
  periodicInvoicing?: boolean;
  proofOfDeliveryHold?: boolean;
  mergeInvoicing?: string;
  holdInvoice?: boolean;
  salesOrderPriorityCode?: number;
  debtorVatRegistrationNo?: string;
  backOrderPriorityCode?: number;
  termsOfPayment: string;
  standardTextNumber?: string;
  noOfInvoiceCopies?: number;
  invoicingCalendar?: string;
  division?: string;
  creditDays: number;
  statisticalProcedure?: string;
  holdDocumentInv?: boolean;
  toCompany?: string;
  supplierInSellingCompany?: string;
  endCustomerInSellingCompany?: string;
  currencyClauseHandling?: boolean;
  printCurrencyClause?: boolean;
  printAndFaxInvoice?: boolean;
  grossSales?: number;
  discount?: number;
  expectedRebateAmount?: number;
  netLineAmount: number;
  netAmount: number;
  headerDiscount?: number;
  headerDiscountPerc?: number;
  discountGroup?: string;
  costPrice?: number;
  profitMargin1?: number;
  profitPercentage1?: number;
  cashDiscount1Amount?: number;
  cashDiscountPerc?: number;
  profitMargin2?: number;
  profitPercentage2?: number;
  customerGroup?: string;
  creditStopCode?: string;
  orderLinesExist: boolean;
  discountsExist: boolean;
  textsExist: boolean;
  lostSaleReasonInd?: boolean;
  shippingAgent: string;
  workStationId: string;
  route: string;
  departure: string;
  destination: string;
  orderValue: number;
  internalVisibility?: boolean;
};

export type OrderDetailsType = Partial<{
  customer: string;
  dispatchAddress: string;
  dispatchTime: string;
  internalOrderType: string;
  inventoryContractNumber: string;
  order: string | number;
  orderDate: string;
  orderReference: string;
  orderPropertyCode: string;
  pricingDate: string;
  purchaseOrder: string;
  deliveryDate: string;
  primaryCurrency: string;
  orderTime: string;
  salesman: string;
  salesPromotion: string;
  warehouse: string;
  toWarehouse: string;
  handler: string;
  orderType: string;
  internalVisibility?: boolean;
}>;

export type MandatoryFields = {
  customer?: string | boolean;
  handler?: string | boolean;
  orderType?: string | boolean;
  dispatchAddress?: string | boolean;
  toWarehouse?: string | boolean;
  fromWarehouse?: string | boolean;
};

export type OrderSimParms = {
  customer?: string;
  orderType?: string;
  dispatchTime?: string;
  warehouse?: string;
};

export enum Loading {
  INITIAL = 'initial',
  PENDING = 'pending',
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}

export type DropdownOptionsType = { label: string; value: string | number }[];

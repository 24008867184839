export default (
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <circle
      stroke="currentColor"
      cx="11"
      cy="11"
      r="10"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M7 16C7.91212 14.7856 9.36433 14 11 14C12.6357 14 14.0879 14.7856 15 16"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M7.00897 8H7M15 8H14.991"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

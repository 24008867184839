import React, { ForwardedRef, MutableRefObject, useCallback, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { AppsFunctions } from '..';
import { XT } from '../../framework/handlers/xt';
import { AuthTokenEmitter } from '../../index';
import { LocaleContext } from '../../App';
import { useCustomAuth } from '@iptor/base';

export const QueryBuilderFrame = React.forwardRef<AppsFunctions | null, { setInitialised: () => void }>(
  (props, ref: ForwardedRef<AppsFunctions | null>) => {
    const { getUser } = useCustomAuth((auth) => ({
      getUser: auth.getUser,
    }));
    const user = getUser();
    const [ready, setReady] = useState(false); //State for checking QB is loaded
    const { localeSettings } = useContext(LocaleContext);
    const print = () => {
      alert('Printing "Query Manager" window not implemented yet');
      // this cause cors issue
      // frameRef.current?.focus();
      // frameRef.current?.contentWindow?.print();
    };
    const refresh = () => {
      const defaultTheme = localStorage.getItem('default_theme');
      let theme = localStorage.getItem('theme');
      if (theme === null || theme === 'default') theme = defaultTheme;

      const linkToQueryManager = theme ? `${XT.appConfig?.xtQueryBuilder}_${theme}` : XT.appConfig?.xtQueryBuilder;

      //Get company from session storage
      const company = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';
      const locale = localeSettings.locale;
      const decimalSep = localeSettings.decimalSeparator;
      const groupSep = localeSettings.groupSeparator;
      const dateFormat = localeSettings.dateFormat;
      if (frameRef.current) {
        //Switch URL to OIDC and append company
        frameRef.current.src = `${linkToQueryManager}?auth-oidc=true&env-company=${
          company?.id ?? ''
        }&user-locale=${locale};${dateFormat};${groupSep};${decimalSep}`;
      }
    };
    useEffect(() => {
      //@ts-ignore
      if (ref?.current) {
        //@ts-ignore
        ref.current.print = print;
        //@ts-ignore
        ref.current.refresh = () => {
          if (ready) setReady(false);
          refresh();
        };
      }
      // refresh();
      props.setInitialised();
    }, [ready]);

    useEffect(() => {
      window.addEventListener('message', onMessage);
      return () => {
        window.removeEventListener('message', onMessage);
      };
    }, []);

    const updateToken = useCallback(
      (token: string) => {
        if (ready)
          frameRef.current?.contentWindow?.postMessage({ cmd: 'update', access_token: token }, frameRef?.current?.src); // Remark: paremeter src added for security reasons (allows caller to check it is us))
      },
      [ready],
    );

    useEffect(() => {
      //Update token if ready and token has changed;
      AuthTokenEmitter.on('token', updateToken);
      updateToken(user?.access_token || '');
      return () => {
        AuthTokenEmitter.off('token', updateToken);
      };
    }, [updateToken, user?.access_token]);
    const onMessage = (e: MessageEvent) => {
      // Only handle messages from the expected origin (security reasons)
      // ================================================================
      if (!e.origin || !frameRef?.current?.src || e.origin !== new URL(frameRef.current.src).origin) return;
      if (!e.source || e.source !== frameRef?.current?.contentWindow) return;

      // Handle data
      // ===========
      if (!e.data) return;
      switch (e.data.cmd) {
        case 'ready': //Wait for Query Builder to be ready
          //Set QB ready
          setReady(true);
          break;
        default:
          console.info('command received, ', e.data);
          break;
      }
    };

    const frameRef: MutableRefObject<HTMLIFrameElement | null> = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
      refresh();
    }, []);

    return <iframe style={{ width: '100%', height: '100%' }} src="about:blank" ref={frameRef} title="Query Builder" />;
  },
);
export const MemoizedQueryBuilderFrame = React.memo(QueryBuilderFrame);

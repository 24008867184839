import {
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Radio,
  styled,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Divider,
  Box,
} from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { Radius } from 'src/constants/Radius';
import { TextSize, TextWeight } from 'src/constants/Typography';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Icon } from '../Icon';
import { Dialog } from '../Dialog';
import { Text } from '../Text';
import { Button } from '../Button';
import { useIptorTranslation } from 'src/framework/translations';
import { ExpandMore } from '@mui/icons-material';
import IconType from 'src/constants/IconType';

export interface ExportDataDialogProps {
  open: boolean;
  handleClose: (data?: any) => void;
}

type CustomMenuItemProps = {
  icon?: IconType;
  text?: string;
  value?: string;
  tooltip?: string;
};

export const ExportDataDialog = ({ open, handleClose }: ExportDataDialogProps) => {
  const theme = useTheme();
  const [selectedFileType, setSelectedFileType] = useState<string | null>('excel');
  const [selectedDropdown, setSelectedDropdown] = useState<CustomMenuItemProps>({
    icon: 'selectedRows',
    value: 'selectedRows',
    text: 'Selected Rows',
  });
  const [expanded, setExpanded] = useState<boolean>(false);

  const { t } = useIptorTranslation();

  const CustomText = styled(Text)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    fontSize: TextSize.TABLE_FOOTER,
    fontWeight: TextWeight.REGULAR,
    lineHeight: theme.spacing(3),
  }));

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const stringRgb = `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)} , ${parseInt(result[3], 16)}`;
    return stringRgb;
  }

  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.MuiToggleButton-root.Mui-selected': {
      background: `rgba(${hexToRgb(theme.palette.primary[500])}, 0.05)`,
      border: `solid 1px ${theme.palette.primary.main}`,
      '&:hover': {
        background: `rgba(${hexToRgb(theme.palette.primary[500])}, 0.05)`,
      },
    },
    '&:hover': {
      color: theme.palette.primary.main,
      background: `rgba(${hexToRgb(theme.palette.primary[500])}, 0.05)`,
      cursor: 'pointer',
    },
  }));

  const handleFileTypeChange = (
    event: React.MouseEvent<HTMLElement> | ChangeEvent<HTMLInputElement>,
    newFileType: string | null,
  ) => {
    if (newFileType) {
      setSelectedFileType(newFileType);
    }
  };

  const dropdownOptions: CustomMenuItemProps[] = [
    {
      icon: 'selectedRows',
      value: 'selectedRows',
      text: 'Selected Rows',
    },
    {
      icon: 'allRows',
      value: 'allRows',
      text: 'All Rows',
    },
    {
      icon: 'endOfFile',
      value: 'endOfFile',
      text: 'End of File',
    },
  ];

  const FileTypeToggle = () => {
    return (
      <>
        <Box textAlign="center" sx={{ paddingY: 2 }}>
          <ToggleButtonGroup
            value={selectedFileType}
            exclusive
            onChange={handleFileTypeChange}
            aria-label="File Type Selection"
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: '65px',
            }}
          >
            <CustomToggleButton value="excel" aria-label="Excel File" sx={{ padding: 2, borderRadius: '8px' }}>
              <Radio checked={selectedFileType === 'excel'} color="primary" />
              <Icon color="transparent" icon="excelLogo" size="M" />
              <CustomText>{t('common:TXT_Excel_File')}</CustomText>
            </CustomToggleButton>
            <CustomToggleButton value="pdf" aria-label="PDF File" sx={{ padding: 2 }}>
              <Radio checked={selectedFileType === 'pdf'} color="primary" />
              <Icon color="transparent" icon="pngLogo" size="M" />
              <CustomText> {t('common:TXT_Pdf_file')}</CustomText>
            </CustomToggleButton>
            <CustomToggleButton value="csv" aria-label="CSV File" sx={{ padding: 2, borderRadius: '8px' }}>
              <Radio checked={selectedFileType === 'csv'} color="primary" />
              <Icon color="transparent" icon="csvLogo" size="M" />
              <CustomText>{t('common:TXT_Csv_file')}</CustomText>
            </CustomToggleButton>
          </ToggleButtonGroup>
        </Box>
      </>
    );
  };

  const accordianMenu = useMemo(() => {
    return (
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded); // Set state to expanded panel or false to collapse all
        }}
        sx={{
          borderRadius: '10px',
          border: `1px solid rgba(${hexToRgb(theme.palette.foreground[400])}, 0.12)`,
          boxShadow: 'none',
          '&::before': {
            content: '""', // Ensure the pseudo-element exists
            display: 'none', // Disable the default MUI styling for `::before`
          },
          '& .MuiButtonBase-root': {
            minHeight: '56px!important',
            '&:before': { display: 'none' },
          },

          '& .MuiAccordionSummary-root': {
            borderTopLeftRadius: '10px', // Explicitly set top-left radius
            borderTopRightRadius: '10px', // Explicitly set top-right radius
            borderBottom: 'none',
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderBottom: `1px solid rgba(${hexToRgb(theme.palette.foreground[400])}, 0.12)`,
            height: '36px',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .MuiMenuItem-root': {
            minHeight: '36px!important',
          },
          '& .Mui-expanded': {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            paddingY: theme.spacing(1.25),
            paddingX: theme.spacing(2),
            borderBottom: `1px solid rgba(${hexToRgb(theme.palette.foreground[400])}, 0.12)`,
            margin: 0,

            '& .MuiAccordionSummary-expandIconWrapper': {
              transition: 'transform 0.3s ease', // Smooth icon transition
            },
          }}
        >
          <Typography variant="body1" fontWeight="500">
            {selectedDropdown?.text}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <List>
            {dropdownOptions.map((option) => (
              <MenuItem
                sx={{
                  display: 'flex',
                  justifyContent: 'row',
                  gap: '8px',
                  paddingY: theme.spacing(0.75),
                  paddingX: theme.spacing(1),
                  height: '36px',
                  '& .MuiMenuItem-root': {
                    height: '36px!important',
                  },
                  '&:hover': {
                    background: `rgba(${hexToRgb(theme.palette.primary[500])}, 0.05)`,
                  },
                }}
                onClick={(e) => {
                  setSelectedDropdown(option);
                  setExpanded(false);
                }}
              >
                <Icon color="transparent" icon={option.icon} size="M" />
                <Text
                  sx={{
                    fontWeight: TextWeight.REGULAR,
                    fontSize: TextSize.TABLE_FOOTER,
                    color: theme.palette.foreground[500],
                  }}
                >
                  {option.text}
                </Text>
              </MenuItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }, [selectedDropdown, expanded]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '720px',
            maxHeight: '562px',
            borderRadius: Radius.S,
          },
        }}
      >
        <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
          <Text variant="h6" fontWeight={600} sx={{ color: theme?.palette.foreground[100] }}>
            {t('common:TXT_Export_Data')}
          </Text>
          <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
            <Icon icon="cancel01" onClick={() => handleClose(false)} />
          </Box>
        </DialogTitle>
        <Divider
          sx={(theme) => ({
            opacity: 0.6,
            borderWidth: '1px',
            marginX: theme.spacing(2),
          })}
        />
        <DialogContent sx={{ paddingY: theme.spacing(2) }}>
          <Text fontWeight={600} sx={{ fontSize: TextSize.TABLE_FOOTER, color: theme?.palette.foreground[600] }}>
            {t('common:TXT_Select_File_Type')}
          </Text>
          <FileTypeToggle />
          {accordianMenu}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={theme.spacing(2)}
            paddingY={theme.spacing(1.25)}
          >
            <Text
              sx={{
                fontWeight: TextWeight.REGULAR,
                fontSize: TextSize.XS,
                color: theme.palette.foreground[500],
              }}
            >
              {'5MB File size'}
            </Text>
            <Text
              sx={{
                fontWeight: TextWeight.REGULAR,
                fontSize: TextSize.XS,
                color: theme.palette.foreground[500],
              }}
            >
              {'Records: 20'}
            </Text>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button variant="outlined" onClick={() => handleClose(false)}>
            {t('common:TXT_Cancel')}
          </Button>
          <Button variant="contained">{t('common:TXT_Export_New')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

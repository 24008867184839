import React, {
  useState,
  DragEvent,
  ChangeEvent,
  useRef,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { styled } from '@mui/system';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Theme } from 'src/hooks/useTheme';

const ExcelIcon = () => {
  return (
    <svg width="37" height="40" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.08 0H27.1935C28.8504 0 30.1935 1.34315 30.1935 3V33C30.1935 34.6569 28.8504 36 27.1935 36H3C1.34315 36 0 34.6569 0 33L0 10.08L10.08 0Z"
        fill="#1B9754"
      />
      <path d="M0 10.08L10.0804 0L10.0801 7.08011C10.08 8.73692 8.73691 10.08 7.0801 10.08H0Z" fill="#D9FFEB" />
    </svg>
  );
};

const CSVIcon = () => {
  return (
    <svg width="37" height="40" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.08 0H27.1935C28.8504 0 30.1935 1.34315 30.1935 3V33C30.1935 34.6569 28.8504 36 27.1935 36H3C1.34315 36 0 34.6569 0 33L0 10.08L10.08 0Z"
        fill="#8B8B8B"
      />
      <path d="M0 10.08L10.0804 0L10.0801 7.08011C10.08 8.73692 8.73691 10.08 7.0801 10.08H0Z" fill="#C9C9C9" />
    </svg>
  );
};

const DefaultIcon = () => {
  return (
    <svg width="37" height="40" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.08 0H27.1935C28.8504 0 30.1935 1.34315 30.1935 3V33C30.1935 34.6569 28.8504 36 27.1935 36H3C1.34315 36 0 34.6569 0 33L0 10.08L10.08 0Z"
        fill="#8B8B8B"
      />
      <path d="M0 10.08L10.0804 0L10.0801 7.08011C10.08 8.73692 8.73691 10.08 7.0801 10.08H0Z" fill="#C9C9C9" />
    </svg>
  );
};

export enum FileType {
  CSV = 'CSV',
  XMLX = 'XMLX',
  PDF = 'PDF',
  JPEG = 'JPEG',
  PNG = 'PNG',
  DOCX = 'DOCX',
}

const fileTypeMap: { [key in FileType]: { mimeTypes: string[]; icon: JSX.Element } } = {
  [FileType.CSV]: { mimeTypes: ['text/csv'], icon: <CSVIcon /> },
  [FileType.XMLX]: {
    mimeTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    icon: <ExcelIcon />,
  },
  [FileType.PDF]: { mimeTypes: ['application/pdf'], icon: <DefaultIcon /> },
  [FileType.JPEG]: { mimeTypes: ['image/jpeg'], icon: <DefaultIcon /> },
  [FileType.PNG]: { mimeTypes: ['image/png'], icon: <DefaultIcon /> },
  [FileType.DOCX]: {
    mimeTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    icon: <DefaultIcon />,
  },
};

export interface FileDropZoneProps {
  onFileListChange?: (files: File[]) => void;
  fileTypes?: FileType[];
}

const getFileTypeExtensions = (fileType: FileType): string[] => {
  return fileTypeMap[fileType]?.mimeTypes || [];
};

const getFileTypeIconFromExtension = (mimeType: string): JSX.Element | null => {
  for (const { mimeTypes, icon } of Object.values(fileTypeMap)) {
    if (mimeTypes.includes(mimeType)) {
      return icon;
    }
  }
  return null;
};

// Drop Area styled component
export const DropArea = styled('div')<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  border: isDragging ? `2px solid ${theme.palette.success.main}` : `2px dashed ${theme.palette.grey[400]}`,
  padding: theme.spacing(5),
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  backgroundColor: isDragging ? theme.palette.success.light : theme.palette.background.default,
  borderRadius: theme.spacing(2),
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  textAlign: 'center',
  marginTop: theme.spacing(2),
  ...(theme as unknown as Theme).typography.body1, // Correctly typed typography
}));

// Drag content styled component
export const DragContent = styled('div')(({ theme }) => ({
  minHeight: theme.spacing(22.5),
  alignContent: 'center',
  ...(theme as unknown as Theme).typography.body1, // Correctly typed typography
}));

// File list container styled component
export const FileListContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'left',
  maxHeight: theme.spacing(17.5),
  overflowY: 'auto',
  ...(theme as unknown as Theme).typography.body1, // Correctly typed typography
}));

// File list item styled component
export const FileListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  height: theme.spacing(5.5),
  alignItems: 'center',
  ...(theme as unknown as Theme).typography.body1, // Correctly typed typography
}));

// File info styled component
export const FileInfo = styled('div')(({ theme }) => ({
  ...(theme as unknown as Theme).typography.caption, // Correctly typed typography
  lineHeight: 1.5,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

// No files text styled component
export const NoFilesText = styled('div')(({ theme }) => ({
  height: theme.spacing(5.5),
  ...(theme as unknown as Theme).typography.body1, // Correctly typed typography
}));

export const FileDropZone: React.FC<FileDropZoneProps> = forwardRef(({ onFileListChange, fileTypes }, ref) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const allowedTypeExtensions = useMemo(() => {
    return fileTypes?.flatMap(getFileTypeExtensions);
  }, [fileTypes]);

  const validateFileType = useCallback(
    (file: File) => {
      if (!allowedTypeExtensions) {
        return true;
      }
      return allowedTypeExtensions.includes(file.type);
    },
    [allowedTypeExtensions],
  );

  const processFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(validateFileType);
    if (newFiles.length > validFiles.length) {
      alert(`Some files have invalid format, allowed format is ${fileTypes?.toString()}`);
    }
    const uniqueFiles = filterDuplicates(validFiles);

    if (uniqueFiles.length < validFiles.length) {
      alert('Some files have already been added');
    }

    if (uniqueFiles.length > 0) {
      const updatedFileList = [...fileList, ...uniqueFiles];
      setFileList(updatedFileList);

      if (onFileListChange) {
        onFileListChange(updatedFileList);
      }
    }
  };

  const formatBytes = (bytes: number): string => {
    const kb = 1024;
    const mb = 1024 * kb;

    if (bytes < kb) return '1.00 KB';
    if (bytes < mb) return `${(bytes / kb).toFixed(2)} KB`;
    return `${(bytes / mb).toFixed(2)} MB`;
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      processFiles(Array.from(e.target.files));
      e.target.value = '';
    }
  };

  const filterDuplicates = (newFiles: File[]) => {
    const existingFileNames = new Set(fileList.map((file) => `${file.name}-${file.size}-${file.lastModified}`));
    return newFiles.filter(
      (newFile) => !existingFileNames.has(`${newFile.name}-${newFile.size}-${newFile.lastModified}`),
    );
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFileList = fileList.filter((_, i) => i !== index);
    setFileList(updatedFileList);

    if (onFileListChange) {
      onFileListChange(updatedFileList);
    }
  };

  // Expose the removeFile function to the parent via ref
  useImperativeHandle(ref, () => ({
    handleRemoveFile,
  }));

  return (
    <div>
      <DropArea onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} isDragging={isDragging}>
        <input
          type="file"
          multiple
          accept={allowedTypeExtensions?.toString()}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-upload"
          ref={fileInputRef}
        />
        <label htmlFor="file-upload" style={{ display: 'block', cursor: 'pointer' }}>
          {isDragging ? (
            <DragContent>{'Drop your files here'}</DragContent>
          ) : (
            <div
              style={{
                minHeight: '180px',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Icon icon="cloudUpload" size="XXL" />
              <div>
                <div>Drag & Drop your files here</div>
                {fileTypes && <div>Allowed file types: {fileTypes.toString()} only</div>}
                <div>OR</div>
              </div>
              <Button variant="contained" color="primary" onClick={handleBrowseClick}>
                Browse
              </Button>
            </div>
          )}
        </label>
      </DropArea>
      <h2 style={{ fontSize: '20px', marginTop: '16px' }}>
        <b> Upload status:</b>
      </h2>
      {fileList.length > 0 ? (
        <FileListContainer>
          <ul>
            {fileList.map((file, index) => (
              <FileListItem key={index}>
                {getFileTypeIconFromExtension(file.type)}
                <FileInfo>
                  <div>
                    <b>{file.name}</b>
                  </div>
                  <div>{formatBytes(file.size)}</div>
                </FileInfo>
                <div style={{ marginLeft: 'auto', marginRight: '16px', marginTop: 'auto', marginBottom: 'auto' }}>
                  <IconButton variant="text" icon="delete3" color="error" onClick={() => handleRemoveFile(index)} />
                </div>
              </FileListItem>
            ))}
          </ul>
        </FileListContainer>
      ) : (
        <NoFilesText>No files selected</NoFilesText>
      )}
    </div>
  );
});

import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../styles/ColumnVisibility.scss';
import { SquareIcon, Icons } from './SquareIcon';
import { ColumnVisibilityTable } from './ColumnVisibilityTable';
import { Localization } from '../framework/localization/Localization';
/*
 * isShown: if true, column is visible
 * modalTitle: Title of Dialog box(pop up)
 */

export type ColumnSettings = {
  id: string;
  name: string;
  hide: boolean;
  noHeader: boolean;
};

export type ColumnVisibilityProps = {
  className?: string;
  isShown: boolean;
  hide: () => void;
  modalTitle?: string;
  columnsSettings: ColumnSettings[];
  setTooltipSequence: (payload: any) => void;
  tooltipSequence: { [key: string]: string };
  setHCols: Function;
  hCols?: string[];
};

export const ColumnVisibility: FunctionComponent<ColumnVisibilityProps> = ({
  isShown,
  hide,
  modalTitle,
  columnsSettings,
  tooltipSequence,
  setTooltipSequence,
  setHCols,
  hCols = [],
}: ColumnVisibilityProps) => {
  const [show, setShow] = useState(false);
  const columnsHeader = useMemo(
    () => [
      {
        Header: ' ',
        isVisible: false,
        hideHeader: false,
        columns: [
          {
            Header: Localization.instance.getString('EDIT_COLUMNS_Column'),
            accessor: 'column',
          },
          {
            Header: Localization.instance.getString('EDIT_COLUMNS_Enabled'),
            accessor: 'enabled',
          },
          {
            Header: Localization.instance.getString('EDIT_COLUMNS_TooltipSequence'),
            accessor: 'tooltipSequence',
          },
        ],
      },
    ],
    [],
  );

  const ttsArray: any = [];
  if (tooltipSequence) {
    for (let key in tooltipSequence)
      if (tooltipSequence.hasOwnProperty(key)) ttsArray.push({ key, seq: parseInt(tooltipSequence[key]) });
  }

  const dataWothTTSeq = columnsSettings.map((item) => {
    const isSeqInvalid = ttsArray.find(
      (ttsItem: any) =>
        ttsItem.key !== item.id && tooltipSequence?.[item.id] && ttsItem.seq == tooltipSequence?.[item.id],
    );
    return { ...item, tooltipSequence: tooltipSequence?.[item.id] || '', isSeqInvalid };
  });

  const onChangeTooltipSequence = (id: string, value: string) => {
    // update tooltip sequence when tooltip sequence is changed
    if (!value.trim()) {
      delete tooltipSequence[id];
      setTooltipSequence({ ...tooltipSequence });
    } else {
      setTooltipSequence({ ...tooltipSequence, [id]: value });
    }
  };

  const onClose = () => {
    const errorSeq = dataWothTTSeq.find((item) => item.isSeqInvalid);
    if (!errorSeq) {
      hide();
    }
  };

  const invertAll = () => {
    let newList = columnsSettings.filter((column: ColumnSettings) => !column.hide).map((c: ColumnSettings) => c.id);
    setHCols(newList);
  };

  const handleSelectAll = () => {
    setHCols([]);
  };
  const handleUnselectAll = () => {
    let newList = columnsSettings?.map((column: ColumnSettings) => column.id);
    setHCols(newList);
  };

  const handleInvert = () => {
    invertAll();
  };

  const handleReset = () => {
    setHCols(undefined);
    setShow(false);
  };

  const toggleHidden = (id: string) => {
    let hidden = new Set(hCols);
    if (hCols.includes(id)) {
      hidden.delete(id);
    } else {
      hidden.add(id);
    }
    setHCols(Array.from(hidden));
  };

  return (
    <div>
      <Modal
        data-event={'ignore'}
        animation={false}
        className={'right-overlay settings-modal'}
        show={isShown}
        onHide={onClose}
        backdrop={true}
        keyboard={true}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <span>
              <h1>{modalTitle}</h1>
            </span>
          </Modal.Title>
          <span onClick={onClose} className="column-visibilty-icon">
            <SquareIcon onClick={onClose} className="column-visibilty-icon" size="18px">
              {Icons.XCircle}
            </SquareIcon>
          </span>
        </Modal.Header>
        <Modal.Body>
          <ColumnVisibilityTable
            toggleHidden={toggleHidden}
            columns={columnsHeader}
            data={dataWothTTSeq}
            onChangeTooltipSequence={onChangeTooltipSequence}
            handleReset={handleReset}
            handleInvert={handleInvert}
            handleSelectAll={handleSelectAll}
            handleUnselectAll={handleUnselectAll}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

import { ChangeEvent, FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import './../styles/TextArea.scss';
import PromptArrow from './../assets/edit-prompt-arrow.svg';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { useAdvancedEffect } from '../types/hooks';
import { Column } from './DataTable';
import { Localization } from 'framework/localization/Localization';
import { Loader } from 'views/partials/Loader';
import { useSelector } from 'react-redux';
import { RootState } from 'framework/base';
import { RequestError } from 'types/RequestError';

type DataRow = {
  id?: string;
  value: string;
};

const buildValue = (rows: DataRow[], limit: number) => {
  const lastDataRowIdx = rows.findLastIndex((row) => row.value.trim() !== '');
  return rows.reduce((value, row, index) => {
    if (index > lastDataRowIdx) {
      // Do not append "trailing empty lines" => prevents program to execute too much loadMore when adding ("inserting") lines
      return value;
    } else if (row.value.length < limit && index < rows.length - 1 && index < lastDataRowIdx) {
      return value + row.value + '\n';
    } else {
      return value + row.value;
    }
  }, '');
};

const splitValue = (value: string, limit: number) => {
  let remainingValue = value;
  const values: string[] = [];
  while (remainingValue.length > 0) {
    const index = remainingValue.indexOf('\n');
    if (index >= 0 && index < limit) {
      values.push(remainingValue.substring(0, index));
      remainingValue = remainingValue.substring(index + 1);
    } else if (remainingValue.length > limit) {
      values.push(remainingValue.substring(0, limit));
      remainingValue = remainingValue.substring(limit);
    } else {
      values.push(remainingValue);
      remainingValue = '';
    }
  }
  return values;
};
type RequestInfo = { type?: 'initialize' } | { type: 'loadMore'; rowCount: number };

/**
 * @TextAreaProps
 * attributes: XML attributes for selected element
 */
type TextAreaProps = {
  attributes: Record<string, any>;
  className?: string;
  id: string;
  limit: number;
  printLimit?: number;
  readOnly?: boolean;
  type?: 'single-line' | 'multi-line';
  variant?: 'text' | 'prompt';
  panelID: string;
  loadMore?: (
    panelID: string,
    EOF?: boolean,
    columns?: Column[],
    rowCount?: number,
    attributes?: Record<string, any>,
    cb?: (data: Record<string, any>) => void,
  ) => void;
};

export const TextArea: FunctionComponent<TextAreaProps> = ({
  attributes,
  className = '',
  id,
  limit,
  printLimit = -1,
  readOnly = false,
  variant = 'text',
  type = 'single-line',
  panelID,
  loadMore,
}) => {
  const { addToRowCommand, windowData, cursor } = useContext(CommandContext); //Creating a command for the XT
  const [value, setValue] = useState('');
  const setHasFocus = useState(false)[1]; // force rerender on focus/blur (to calculate the right width)
  const [bodySizeChanged, setBodySizeChanged] = useState({ changed: true }); // force rerender on resolution changed (to calculate the right width)
  const [requestInfo, setRequestInfo] = useState<RequestInfo>({ type: 'initialize' });
  const dataRows = useRef<DataRow[]>([]);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  const scrollbarWidth = useMemo(() => {
    if (bodySizeChanged.changed) {
      const div = document.createElement('div');
      div.style.overflow = 'scroll';
      div.style.visibility = 'hidden';
      div.style.position = 'absolute';
      div.style.width = '100px';
      div.style.height = '100px';
      document.body.appendChild(div);
      const width = div.offsetWidth - div.clientWidth;
      document.body.removeChild(div);
      return width;
    }
    return 8; // not expected to be ever execued
  }, [bodySizeChanged]);

  const requestError = useSelector<RootState, RequestError.JSON | undefined>(
    (state) => state.desktop.requestErrors.pending,
  );

  useEffect(() => {
    if (document.body) {
      const registerChange = () => setBodySizeChanged({ changed: true });
      document.body.addEventListener('resize', registerChange);
      return document.body.removeEventListener('resize', registerChange);
    }
  }, []);

  useEffect(() => {
    if (textRef.current) {
      /*
        Remark: the "cols" property of the textarea is not always working as expected. Looks like it's only
        working for some specific resolutions and width adjustment missing when adding/removing scrollbar
        by adding/removing text lines (Google)
      */
      const style = window.getComputedStyle(textRef.current);
      const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
      const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
      const scroll = textRef.current.scrollHeight > textRef.current.clientHeight ? scrollbarWidth : 0;
      textRef.current.style.width = `calc(${limit}ch + ${Math.ceil(padding + border + scroll + 2)}px)`; // 2 more than really needed to avoid rounding errors
    }
  });

  useEffect(() => {
    if (textRef.current) {
      const scrollInfo = XT.getScrollInfo(textRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  useAdvancedEffect(
    () => {
      const panel = windowData.form?.panel?.find((x: any) => panelID === x.$.id);
      if (!panel) return;

      dataRows.current = [];
      for (let i in panel.row) {
        const row = panel.row[i];
        const val = XT.getValueFromWindowRow({ data: windowData }, panelID, row.$.id, attributes.rowCtrl) || '';
        dataRows.current.push({
          id: row.$.id,
          value: val.trimEnd(),
        });
      }

      if (requestInfo.type === 'loadMore') {
        if (dataRows.current.length <= requestInfo.rowCount) {
          setRequestInfo({});
          alert(
            Localization.instance.getString(
              'TXT_Unexpected_error__insufficient_rows_available_and_loading_more_rows_failed',
            ),
          );
        } else {
          const values = splitValue(value, limit);
          setRequestInfo({});
          adjustValueDataRows(values);
        }
      } else {
        setValue(buildValue(dataRows.current, limit));
      }
    },
    [windowData, panelID, attributes.rowCtrl],
    [requestInfo],
  );

  useAdvancedEffect(
    () => {
      /*
        Remark: looks like the (initial?) value must be rendered before you can set the 
        selection start/end (at least in Google)
      */
      if (textRef.current && requestInfo.type === 'initialize' && value) {
        textRef.current.selectionEnd = 0;
        textRef.current.selectionStart = 0;
        textRef.current.scrollTop = 0;
        textRef.current.scrollLeft = 0;
        setRequestInfo({});
      }
    },
    [value],
    [requestInfo.type],
  );

  useAdvancedEffect(
    () => {
      if (requestError && requestInfo.type === 'loadMore') {
        setRequestInfo({}); // ==> end loading !!
        alert(
          Localization.instance.getString(
            'TXT_Unexpected_error__insufficient_rows_available_and_loading_more_rows_failed',
          ),
        );
      }
    },
    [requestError],
    [requestInfo.type],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (requestInfo.type === 'initialize') setRequestInfo({});
    adjustValueDataRows(splitValue(e.target.value, limit));
    setValue(e.target.value);
    e.stopPropagation();
  };

  const adjustValueDataRows = (values: string[]) => {
    for (let i = 0; i < values.length; i++) {
      if (i < dataRows.current.length) {
        dataRows.current[i].value = values[i];
        addToRowCommand(panelID, dataRows.current[i].id!, attributes.rowCtrl, values[i], -1);
      } else {
        if (!loadMore) {
          alert(
            Localization.instance.getString(
              'TXT_Unexpected_error__insufficient_rows_available_and_load_more_not_supported_in_this_function',
            ),
          );
        } else {
          loadMore(panelID);
          setRequestInfo({ type: 'loadMore', rowCount: dataRows.current.length });
        }
        break;
      }
    }
    if (values.length < dataRows.current.length) {
      for (let i = values.length; i < dataRows.current.length; i++) {
        dataRows.current[i].value = '';
        addToRowCommand(panelID, dataRows.current[i].id!, attributes.rowCtrl, '', -1);
      }
    }
  };

  return (
    <div
      className="area-container"
      data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
      data-for="global"
      data-iscapture="true"
    >
      <Loader loading={requestInfo.type === 'loadMore'} />
      <Form.Control
        ref={textRef}
        data-event={'textarea'}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={type === 'multi-line' ? undefined : onChange}
        data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
        tabIndex={!readOnly ? x + y : -1}
        as="textarea"
        className={`monospace text-area text-area-style ${variant === 'prompt' && 'text-right-padding'} ${className}`}
        value={value}
        cols={limit - 1} // Remark: does not work always (calculation width overwritten)
        //rows={splitValue(value, limit).length - 1}
        readOnly={readOnly || requestInfo.type === 'loadMore'} // Prevent launching multiple loadmore actions in paralel !!
      />
      {printLimit > 0 && <div style={{ left: '0ch' }} className="divider" />}
      {printLimit > 0 && <div style={{ left: printLimit + 'ch' }} className="divider" />}
      {variant === 'prompt' && (
        <button className={'text-font'}>
          <img src={PromptArrow} alt="F4" style={{ transform: 'rotate(180deg)' }} />
        </button>
      )}
    </div>
  );
};

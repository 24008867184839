export default (
  <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.8123 0H25.4644C27.1212 0 28.4644 1.34315 28.4644 3V30.3842C28.4644 32.0411 27.1212 33.3842 25.4644 33.3842H3.46472C1.80786 33.3842 0.464722 32.0411 0.464722 30.3842L0.464722 9.34758L9.8123 0Z"
      fill="#D31919"
    />
    <path
      d="M0.46405 9.34758L9.81196 0L9.81174 6.34769C9.81168 8.0045 8.46855 9.34758 6.81174 9.34758H0.46405Z"
      fill="#FF7070"
    />
    <path
      d="M8.106 19.2293C8.40512 19.2293 8.63303 19.1616 8.78971 19.0263C8.95351 18.8839 9.03542 18.6702 9.03542 18.3853C9.03542 18.1147 8.94995 17.9082 8.77903 17.7657C8.61522 17.6162 8.37307 17.5414 8.05258 17.5414C7.93863 17.5414 7.84249 17.545 7.76415 17.5521C7.69293 17.5521 7.61814 17.5592 7.5398 17.5734V19.2293H8.106ZM7.55049 22.6585C7.47214 22.6799 7.34751 22.7013 7.17658 22.7226C7.01278 22.744 6.84897 22.7547 6.68517 22.7547C6.52136 22.7547 6.37536 22.7404 6.24717 22.7119C6.12609 22.6906 6.02282 22.6478 5.93736 22.5837C5.8519 22.5196 5.7878 22.4306 5.74507 22.3167C5.70234 22.2027 5.68097 22.0532 5.68097 21.868V16.8363C5.68097 16.6868 5.72014 16.5728 5.79848 16.4945C5.88395 16.409 5.9979 16.3413 6.14034 16.2915C6.38248 16.206 6.65668 16.1455 6.96292 16.1099C7.27629 16.0671 7.58966 16.0458 7.90302 16.0458C8.88585 16.0458 9.63722 16.2559 10.1571 16.6761C10.677 17.0963 10.937 17.666 10.937 18.3853C10.937 18.7343 10.88 19.0512 10.7661 19.3361C10.6592 19.6139 10.4954 19.856 10.2746 20.0626C10.061 20.262 9.78678 20.4187 9.45205 20.5326C9.12444 20.6394 8.74342 20.6929 8.30898 20.6929H7.55049V22.6585Z"
      fill="white"
    />
    <path
      d="M13.7472 21.3125C13.8255 21.3267 13.9217 21.341 14.0356 21.3552C14.1496 21.3623 14.2564 21.3659 14.3561 21.3659C14.5983 21.3659 14.819 21.3303 15.0185 21.2591C15.225 21.1878 15.3995 21.0774 15.5419 20.9279C15.6915 20.7783 15.8054 20.586 15.8838 20.351C15.9692 20.1089 16.012 19.8204 16.012 19.4857C16.012 18.8447 15.8624 18.364 15.5633 18.0435C15.2642 17.7159 14.8653 17.5521 14.3668 17.5521C14.2671 17.5521 14.1638 17.5556 14.057 17.5628C13.9573 17.5699 13.854 17.5806 13.7472 17.5948V21.3125ZM14.3454 22.8722C14.2244 22.8722 14.0855 22.8686 13.9288 22.8615C13.7721 22.8544 13.6083 22.8401 13.4374 22.8188C13.2736 22.7974 13.1062 22.7689 12.9353 22.7333C12.7715 22.7048 12.6184 22.6621 12.4759 22.6051C12.0842 22.4555 11.8883 22.1956 11.8883 21.8253V16.8363C11.8883 16.6868 11.9275 16.5728 12.0059 16.4945C12.0913 16.409 12.2053 16.3413 12.3477 16.2915C12.654 16.1918 12.978 16.1277 13.3199 16.0992C13.6617 16.0636 13.968 16.0458 14.2386 16.0458C14.7941 16.0458 15.2962 16.1134 15.7449 16.2488C16.2007 16.3841 16.5888 16.5942 16.9093 16.879C17.2369 17.1568 17.4898 17.5093 17.6678 17.9367C17.8459 18.364 17.9349 18.8696 17.9349 19.4536C17.9349 20.0234 17.8494 20.5219 17.6785 20.9493C17.5076 21.3694 17.2619 21.7255 16.9414 22.0175C16.628 22.3024 16.2506 22.5161 15.809 22.6585C15.3674 22.801 14.8796 22.8722 14.3454 22.8722Z"
      fill="white"
    />
    <path
      d="M23.5543 16.2274C23.6042 16.3057 23.6469 16.4126 23.6825 16.5479C23.7252 16.6761 23.7466 16.8114 23.7466 16.9538C23.7466 17.2316 23.6861 17.431 23.565 17.5521C23.451 17.666 23.2943 17.723 23.0949 17.723H20.8515V18.9302H23.1911C23.2481 19.0085 23.2943 19.1118 23.33 19.24C23.3727 19.3682 23.3941 19.5035 23.3941 19.6459C23.3941 19.9166 23.3335 20.1124 23.2124 20.2335C23.0985 20.3474 22.9418 20.4044 22.7424 20.4044H20.8729V22.6585C20.7945 22.6799 20.6699 22.7013 20.499 22.7226C20.3352 22.744 20.1714 22.7547 20.0076 22.7547C19.8438 22.7547 19.6978 22.7404 19.5696 22.7119C19.4485 22.6906 19.3452 22.6478 19.2598 22.5837C19.1743 22.5196 19.1102 22.4306 19.0675 22.3167C19.0247 22.2027 19.0034 22.0532 19.0034 21.868V17.2209C19.0034 16.9147 19.0924 16.6725 19.2704 16.4945C19.4485 16.3164 19.6906 16.2274 19.9969 16.2274H23.5543Z"
      fill="white"
    />
  </svg>
);

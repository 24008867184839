import { Theme } from '@mui/material';

export const Button = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      padding: '14px 32px',
      lineHeight: '21.79px',
      fontSize: 16,
      textTransform: 'none',
      boxShadow: 'none',
      svg: {
        width: 20,
        maxHeight: 20,
      },
      borderRadius: theme.spacing(0.5),
      '&.Mui-disabled': {
        pointerEvents: 'auto', // Allows hover only for tooltip
        cursor: 'not-allowed',
      },
      '&.Mui-disabled:hover': {
        backgroundColor: `${theme.palette.action.disabledBackground}!important`,
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: '8px 24px',
            lineHeight: '16px',
            fontSize: 14,
            svg: {
              width: 16,
              maxHeight: 16,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '14px 32px',
            lineHeight: '24.5px',
            fontSize: 18,
            svg: {
              width: 24,
              maxHeight: 24,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            ':hover': {
              backgroundColor: theme.palette.primary['500'], // '#00A3A5'
            },
            ':focus': {
              backgroundColor: theme.palette.primary['500'], // '#00A3A5'
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`, // '#0A4B55'
            },
            ':active': {
              backgroundColor: theme.palette.primary.main, // '#007F81'
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            color: theme.palette.foreground[900], // '#FAFAFA'
            ':hover': {
              backgroundColor: theme.palette.secondary[400],
              boxShadow: theme.shadows[1],
              color: theme.palette.foreground[900], // '#FAFAFA'
            },
            ':focus': {
              backgroundColor: theme.palette.secondary[400], // '#00A3A5'
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`, // '#0A4B55'
              color: theme.palette.foreground[900], // '#FAFAFA'
            },
            ':active': {
              backgroundColor: theme.palette.secondary.main, // '#007F81'
              color: theme.palette.foreground[900], // '#FAFAFA'
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            ':hover': {
              backgroundColor: theme.palette.success.light, // '#E0FFF5'
            },
            ':focus': {
              backgroundColor: theme.palette.success.light, // '#E0FFF5'
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`, // '#0A4B55'
            },
            ':active': {
              backgroundColor: theme.palette.success.light, // '#E0FFF5'
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            ':hover': {
              backgroundColor: theme.palette.success.light, // '#E0FFF5'
            },
            ':focus': {
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`, // '#0A4B55'
            },
            ':active': {
              backgroundColor: theme.palette.success.light, // '#E0FFF5'
            },
          },
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            color: theme.palette.info.main, // '#00A3A5'
            ':hover': {
              backgroundColor: theme.palette.info.light, // '#E0FFF5'
            },
            ':focus': {
              outline: `solid 2px ${theme.palette.info.main}`, // '#00A3A5'
            },
            ':active': {
              backgroundColor: theme.palette.info.light, // '#E0FFF5'
            },
          },
        },
      ],
    }),
  },
};

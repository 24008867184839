export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path stroke="currentColor" d="M2 20V14H8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      stroke="currentColor"
      d="M2 14L6.64 18.36C7.71475 19.4353 9.04437 20.2208 10.5048 20.6432C11.9652 21.0657 13.5089 21.1112 14.9917 20.7757C16.4745 20.4402 17.8482 19.7346 18.9845 18.7246C20.1209 17.7146 20.9828 16.4332 21.49 15"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M21.4902 3.99756V9.99756H15.4902"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M21.4902 9.99756L16.8502 5.63756C15.7755 4.56227 14.4459 3.77676 12.9854 3.35433C11.525 2.9319 9.98134 2.88631 8.49852 3.22182C7.0157 3.55732 5.64203 4.26299 4.5057 5.27298C3.36937 6.28296 2.5074 7.56434 2.00023 8.99756"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default (
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none">
    <path
      d="M6.67711 0.57666H17.8576C19.0411 0.57666 20.0006 1.53609 20.0006 2.7196V22.2805C20.0006 23.4641 19.0411 24.4235 17.8576 24.4235H2.14294C0.959429 24.4235 0 23.4641 0 22.2805L0 7.25377L6.67711 0.57666Z"
      fill="#164bff"
      stroke="none"
    />
    <path
      d="M0 7.25377L6.67735 0.57666L6.67719 5.11091C6.67714 6.29439 5.71773 7.25377 4.53424 7.25377H0Z"
      fill="#6c8dff"
      stroke="none"
    />
  </svg>
);

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export type Parameters = Record<string, unknown>;

export type FormState = Record<string, Record<string, Parameters>>;

const FormStateContext = createContext<{ state: FormState; setState: Dispatch<SetStateAction<FormState>> }>({
  state: {},
  setState: () => {
    return; //ESLint does not allow empty function?!
  },
});

export const FormStateProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<FormState>({});
  return <FormStateContext.Provider value={{ state, setState }}>{children}</FormStateContext.Provider>;
};

export const useFormState = <T extends Record<string, unknown>>(stateObject: T) => {
  const { state, setState } = useContext(FormStateContext);
  useEffect(() => {
    updateFormState(stateObject);
  }, Object.values(stateObject));

  const location = useLocation();
  const path = useMemo(() => location.pathname, [location]);
  const updateFormState = useCallback(
    (newState: Partial<T>, mode: 'overwrite' | 'merge' = 'merge') => {
      setState((state) => ({
        ...state,
        [path]: {
          ...(mode === 'merge' ? state[path] : {}),
          [path]: {
            ...(mode === 'merge' ? state[path]?.[path] : {}),
            ...newState,
          },
        },
      }));
    },
    [path],
  );
  const formState = useMemo(() => state[path], [path]);
  return {
    formState,
    updateFormState,
  };
};

export const useComponentState = <T extends Record<string, unknown>>(componentID: string, stateObject: T) => {
  const { state, setState } = useContext(FormStateContext);
  const path = useMemo(() => location.pathname, [location]);

  useEffect(() => {
    updateComponentState(stateObject);
  }, Object.values(stateObject));

  const updateComponentState = useCallback(
    (parameters: Partial<T>) => {
      //Partial as for merge only some params may be updated
      setState((state) => ({
        ...state,
        [path]: {
          ...state[path],
          [componentID]: {
            ...state[path]?.[componentID],
            ...parameters,
          },
        },
      }));
    },
    [path],
  );
  const componentState = useMemo(() => (state?.[path]?.[componentID] ?? {}) as T, [path, componentID]);
  return {
    componentState,
    updateComponentState,
  };
};

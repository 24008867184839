import { FunctionComponent } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import '../styles/Title.scss';

type TitleProps = {
  className?: string;
  crumbs: string[];
  id: string;
  visible?: boolean;
};

export const Title: FunctionComponent<TitleProps> = ({ className = '', crumbs = [], id, visible = true }) => {
  return (
    crumbs && (
      <Breadcrumb className={className} id={id} hidden={!visible}>
        {crumbs
          ?.filter((crumb) => !!crumb.trim())
          .map((crumb: any, i: number) => (
            <Breadcrumb.Item key={i}>{crumb}</Breadcrumb.Item>
          ))}
      </Breadcrumb>
    )
  );
};

import { Combo, ComboProps } from '@iptor/base';
import { OperationParameters, useOpenAPIData } from '../../framework/openapi';

type paramsType = OperationParameters<'internal.v1.base-tables', 'GET /textTypes/{textType}/documents'>;

export type DocumentsDropdownProps = Omit<ComboProps, 'options'> & { params?: paramsType };

export const DocumentsDropdown = ({ params = { textType: '*SALORDHDR' }, ...props }: DocumentsDropdownProps) => {
  const { response, loading } = useOpenAPIData(
    'internal.v1.base-tables',
    'GET /textTypes/{textType}/documents',
    params,
  );

  return (
    <Combo
      size="small"
      fullWidth
      options={response?.data?.data?.items.map((item) => ({
        value: item.documentID,
        label: item.description,
      }))}
      id="document"
      sx={{
        '& .MuiInputBase-root': {
          minWidth: '100px',
          height: 'auto',
          paddingTop: '8px',
          paddingBottom: '8px',
        },
      }}
      multiple
      disableCloseOnSelect
      loading={loading}
      disabled={loading}
      {...props}
    />
  );
};

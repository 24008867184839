import { Grid2 as Grid } from '@mui/material';
import { Button, DialogLayout, useIptorTranslation } from '@iptor/base';
import { useCallback, useRef, useState } from 'react';
import AssortmentItemsTable from './components/AssortmentItemTable';
import AssortmentGroupsTable from './components/AssortmentGroupsTable';
import { Order } from '../../constants';

interface Props {
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  currentOrder: Order;
}

function Assortments(props: Props) {
  const assortmentItemsTableRef = useRef(null);
  const assortmentGroupsTableRef = useRef(null);
  const { open, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [groupInView, setGroupInView] = useState<{ assortmentGroup: string; assortmentID: string } | undefined>();
  const [isAddDisabled, setisAddDisabled] = useState(true);
  const { t } = useIptorTranslation();
  const handleAdd = useCallback(() => {
    setLoading(true);
    if (groupInView) {
      assortmentItemsTableRef?.current?.handleAddOrderLines();
    } else {
      assortmentGroupsTableRef?.current?.handleAddGroups();
    }
  }, [assortmentGroupsTableRef, assortmentItemsTableRef, groupInView]);

  return (
    <DialogLayout
      actions={[
        <Button
          id="close"
          variant={!groupInView ? 'outlined' : 'text'}
          size="medium"
          onClick={() => handleClose(false)}
        >
          {t('common:TXT_Close')}
        </Button>,
        <Button
          id="back"
          hidden={!groupInView}
          variant="outlined"
          size="medium"
          onClick={() => setGroupInView(undefined)}
        >
          {t('common:TXT_Back')}
        </Button>,
        <Button
          id="add"
          variant="contained"
          size="medium"
          onClick={handleAdd}
          loading={loading}
          disabled={isAddDisabled}
        >
          {t('common:ROLE_BUTTON_Add')}
        </Button>,
      ]}
      onClose={() => handleClose(false)}
      open={open}
      title={
        groupInView
          ? t('sales_orders:ASSORTMENTS_assortmentsItems', '', {
              assortment: groupInView.assortmentID,
            })
          : t('sales_orders:ASSORTMENTS_assortmentsGroups')
      }
    >
      <Grid container sx={{ width: '100%' }}>
        {groupInView ? (
          <AssortmentItemsTable
            selectedAssortmentGroup={groupInView}
            currentOrder={props.currentOrder.order}
            ref={assortmentItemsTableRef}
            handleClose={handleClose}
            setisAddDisabled={setisAddDisabled}
          />
        ) : (
          <AssortmentGroupsTable
            currentOrder={props.currentOrder.order}
            setGroupInView={(value) => {
              setGroupInView(value);
            }}
            ref={assortmentGroupsTableRef}
            handleClose={handleClose}
            setisAddDisabled={setisAddDisabled}
          />
        )}
      </Grid>
    </DialogLayout>
  );
}

export default Assortments;

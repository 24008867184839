import {
  TableFilter,
  TableSettings,
  Dropdown,
  Switch,
  MenuItemProps,
  DropdownProps,
  Combo,
  useIptorTranslation,
  IconButton,
  RowHighlightType,
  Icon,
  useClientSettings,
  ExportDataDialog,
  Button,
} from '@iptor/base';
import { IptorTable, IptorTableColumn, OperationParameters } from '@iptor/business';
import { Box, Link, Stack, Tooltip, useTheme } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import DeleteOrders from '../../../dialogs/DeleteOrders';
import CreditOrder from '../../../dialogs/CreditOrder';
import OrderStatus from '../../../components/OrderStatus';
import { SalesOrderConfirmation } from '../../../dialogs/PrintDialogs/SalesOrderConfirmation';
import { PickListConfirmation } from '../../../dialogs/PrintDialogs/PickListConfirmation';
import { ProformaInvoice } from '../../../dialogs/PrintDialogs/ProformaInvoice';
import { Invoicing } from '../../../dialogs/PrintDialogs/Invoicing';
import OrderDiscountLabel from '../../../components/discounts/OrderDiscountLabel';
import UnlockOrder from '../../../dialogs/UnlockOrder';
import { TextIcon } from '../../../dialogs/OrderText/components/TextIcon';
import OrderText from '../../../dialogs/OrderText';

type HandlerOptionType =
  | { label: string; value: 'open_orders' }
  | { label: string; value: 'close_orders' }
  | { label: string; value: 'my_closed_orders' }
  | { label: string; value: 'my_open_orders' };

export const OrderListTable = ({
  setShowSalesOrderLines,
}: {
  setShowSalesOrderLines: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useIptorTranslation();
  const { palette } = useTheme();
  const [selectedRows, setSelectedRows] = useState([]);

  const query = new URLSearchParams(location.search);

  const tableActionsOptions: MenuItemProps[] = [
    {
      icon: 'orderConfirmation',
      text: t('common:TXT_Order_Confirmation'),
      onClick: () => {
        if (selectedRows.length > 0) {
          setOrderConfirmationOrders(selectedRows);
        }
      },
      disabled: selectedRows?.length === 0,
    },
    {
      icon: 'pickListConfirmation',
      text: t('common:TXT_Pick_List'),
      onClick: () => {
        if (selectedRows.length > 0) {
          setPickListConfirmationOrders(selectedRows);
        }
      },
      disabled: selectedRows?.length === 0,
    },
    {
      icon: 'proFormaInvoice',
      text: t('common:TXT_Pro_forma_Invoice'),
      onClick: () => {
        if (selectedRows.length > 0) {
          setProformaInvoiceOrders(selectedRows);
        }
      },
      disabled: selectedRows?.length === 0,
    },
    {
      icon: 'invoicing',
      text: t('common:TXT_Invoicing'),
      onClick: () => {
        if (selectedRows.length > 0) {
          setInvoicingOrders(selectedRows);
        }
      },
      disabled: selectedRows?.length === 0,
    },
    {
      icon: 'attachFile',
      text: t('common:TXT_Export'),
      onClick: () => {
        toggleopenExportRowsPopup(true);
      },
      disabled: selectedRows?.length === 0,
    },
    {
      icon: 'delete3',
      text: t('common:TXT_Delete'),
      onClick: () => {
        setOrdersToDelete(selectedRows);
      },
      disabled: selectedRows?.length === 0,
    },
  ];

  const tableActionsProps: DropdownProps = {
    backgroundColor: 'light',
    slotProps: {
      button: {
        children: 'Actions',
        color: 'primary',
        id: 'actions',
        variant: 'outlined',
        sx: {
          border: `1px solid ${palette.mode === 'dark' ? palette.primary.main : palette.foreground[700]}`,
          color: palette.foreground[500],
        },
      },
    },
    options: tableActionsOptions,
  };

  const [showOrdersInError, setShowOrdersInError] = useState(query.get('showOrdersInError') === 'true' || false);
  const [ordersToDelete, setOrdersToDelete] = useState([]);
  const [openExportRowsPopup, toggleopenExportRowsPopup] = useState(false);
  const [openedTextOrderId, setOpenedTextOrderId] = useState<null | number>(null);
  const [openedTextDialog, setOpenedTextDialog] = useState<null | {
    orderId: number;
    hasText: boolean;
    defaultLanguage: string;
  }>(null);
  const [orderToUnlock, setOrderToUnlock] = useState<{ workStationId: string; order: number }>(undefined);
  const [orderConfirmationOrders, setOrderConfirmationOrders] = useState([]);
  const [pickListConfirmationOrders, setPickListConfirmationOrders] = useState([]);
  const [invoicingOrders, setInvoicingOrders] = useState([]);
  const [proformaInvoiceOrders, setProformaInvoiceOrders] = useState([]);
  const [creditOrderDialogProps, setCreditOrderDialogProps] = useState<{
    orderId?: number;
    customerNumber?: string;
    handler?: string;
    orderType?: string;
  }>();

  const tableRef = useRef(null);
  const { formatCurrency, formatDate, handler: myHandler, currency } = useClientSettings();
  const handlerOptions: HandlerOptionType[] = [
    { label: t('sales_orders:ORDER_openOrders'), value: 'open_orders' },
    { label: t('sales_orders:ORDER_closedOrders'), value: 'close_orders' },
    { label: t('sales_orders:ORDER_myOpenOrders'), value: 'my_open_orders' },
    { label: t('sales_orders:ORDER_myClosedOrders'), value: 'my_closed_orders' },
  ];
  const handlerFromQuery = handlerOptions.find((option) => query.get('handler') === option.value);

  const [handler, setHandler] = useState<HandlerOptionType>(
    handlerFromQuery || { label: t('sales_orders:ORDER_openOrders'), value: 'open_orders' },
  );

  const selectHandler = useMemo(
    () =>
      handler.value === 'my_open_orders' || handler.value === 'my_closed_orders' ? myHandler?.toUpperCase() : undefined,
    [myHandler, handler],
  );

  const {
    selectStatusFrom,
    selectStatusTo,
  }: {
    selectStatusFrom: number | undefined;
    selectStatusTo: number | undefined;
  } = useMemo<{
    selectStatusFrom: number | undefined;
    selectStatusTo: number | undefined;
  }>(
    () =>
      handler.value === 'open_orders' || handler.value === 'my_open_orders'
        ? {
            selectStatusFrom: undefined,
            selectStatusTo: 50,
          }
        : {
            selectStatusFrom: 50,
            selectStatusTo: undefined,
          },
    [myHandler, handler],
  );

  const params = useMemo<OperationParameters<'internal.v1.base-orders', 'GET /salesOrders'>>(
    () =>
      showOrdersInError
        ? { ordersInError: true, selectHandler }
        : {
            orderBy: 'order DESC',
            selectHandler,
            selectStatusFrom,
            selectStatusTo,
          },
    [showOrdersInError, selectHandler, selectStatusFrom, selectStatusTo],
  );

  useEffect(() => {
    query.set('showOrderLines', 'false');
    query.set('showOrdersInError', showOrdersInError.toString());
    query.set('handler', handler.value);
    history.replace({ pathname: location.pathname, search: query.toString() });
  }, [showOrdersInError, handler]);

  useEffect(() => {
    tableRef.current?.refresh();
  }, [params]);

  const columns: IptorTableColumn<'internal.v1.base-orders', 'GET /salesOrders', { actions: 'string' }>[] =
    useMemo(() => {
      return [
        {
          display: t('sales_orders:TABLE_COLUMN_order'),
          key: 'order',
          dataType: 'number',
          cellRenderer: ({ value, row, setHighlightStateForRow }) => {
            useEffect(() => {
              setHighlightStateForRow({
                rowHighlightType: row.status === 'E' ? RowHighlightType.error : RowHighlightType.normal,
                rowHighlightMessage: row.status === 'E' ? 'Order has error' : '',
              });
            }, []);
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Link id="order" sx={{ fontWeight: '600' }} component={RouterLink} to={`/orders/${value}/items`}>
                  {value}
                </Link>
                <OrderDiscountLabel discount={row.discountsExist} type="order" />
                {row.status === 'E' && (
                  <Tooltip title={row.rowHighlightMessage} placement="top" arrow color={'error'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Icon color={palette.error.main} icon="alert01" />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        },
        {
          display: t('sales_orders:TABLE_COLUMN_customer'),
          key: 'customer',
          cellRenderer: ({ row, value }) => (
            <>
              {row['customerName'] || value}
              <br />
              <small id="customerName">{row['customerName'] && value}</small>
            </>
          ),
        },
        { display: t('sales_orders:TABLE_COLUMN_handler'), key: 'handler' },
        {
          display: t('sales_orders:TABLE_COLUMN_status'),
          key: 'orderStatus',
          cellRenderer: ({ value }) => <OrderStatus orderStatus={value} />,
          dataType: 'number',
        },
        { display: t('sales_orders:TABLE_COLUMN_type'), key: 'orderType' },
        {
          display: t('sales_orders:TABLE_COLUMN_date'),
          key: 'orderDate',
          dataType: 'date',
          cellRenderer: ({ value }) => <>{formatDate({ date: value })}</>,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_totalWeightKG'),
          key: 'totalWeight',
          disableFilter: true,
          disableSort: true,
          dataType: 'number',
        },
        {
          display: t('sales_orders:TABLE_COLUMN_value'),
          key: 'totalAmountInclVATSys',
          dataType: 'number',
          cellRenderer: ({ value }) => (
            <>
              {formatCurrency({
                amount: value,
                currency: currency,
              })}
            </>
          ),
        },
        {
          display: '',
          key: 'actions',
          disableFilter: true,
          disableSort: true,
          cellRenderer: ({ row, setHighlightStateForRow }) => {
            useEffect(() => {
              if (openedTextOrderId === row.order || openedTextDialog?.orderId === row.order) {
                setHighlightStateForRow({
                  rowHighlightType: RowHighlightType.info,
                  rowHighlightMessage: '',
                });
              } else {
                setHighlightStateForRow({
                  rowHighlightType: RowHighlightType.normal,
                  rowHighlightMessage: '',
                });
              }
            }, [openedTextOrderId, openedTextDialog?.orderId]);

            return (
              <Stack direction="row" justifyContent={'flex-end'}>
                {row.workStationId && (
                  <IconButton
                    tooltipText={t('sales_orders:ORDER_unlockTitle')}
                    icon="squareUnlock01"
                    variant="text"
                    size="small"
                    id="unlockTitle"
                    onClick={() => {
                      setOrderToUnlock({ order: row.order, workStationId: row.workStationId });
                    }}
                  />
                )}
                {row.textsExist && (
                  <TextIcon
                    order={row.order}
                    open={openedTextOrderId === row.order}
                    handleTextOpen={(isOpen) => {
                      setOpenedTextOrderId(isOpen ? row.order : null);
                    }}
                    handleOpenFullDialog={() => {
                      setOpenedTextDialog({
                        orderId: row.order,
                        hasText: row.textsExist,
                        defaultLanguage: row.language,
                      });
                      setOpenedTextOrderId(null);
                    }}
                  />
                )}
                <IconButton
                  tooltipText={t('common:TXT_Header_Info')}
                  icon="note03"
                  variant="text"
                  id="headerInfo"
                  size="small"
                  onClick={() => history.push(`/orders/${row.order}/header`)}
                />
                <IconButton
                  tooltipText={t('common:TXT_Delete')}
                  icon="delete3"
                  variant="text"
                  id="delete"
                  size="small"
                  onClick={() => setOrdersToDelete([row])}
                />

                <Dropdown
                  options={[
                    {
                      text: t('common:TXT_Credit'),
                      value: 'credit',
                      onClick: () => {
                        setCreditOrderDialogProps({
                          orderId: row.order,
                          customerNumber: row.customer,
                          handler: row.handler,
                          orderType: row.orderType,
                        });
                      },
                    },
                    {
                      text: t('common:TXT_Order_Confirmation'),
                      value: 'order_confirmation',
                      onClick: () => {
                        setOrderConfirmationOrders([row]);
                      },
                    },
                    {
                      text: t('common:TXT_Pick_List'),
                      value: 'pick_list_confirmation',
                      onClick: () => {
                        setPickListConfirmationOrders([row]);
                      },
                    },
                    {
                      text: t('common:TXT_Pro_forma_Invoice'),
                      value: 'pro_forma_invoice',
                      onClick: () => {
                        setProformaInvoiceOrders([row]);
                      },
                    },
                    {
                      text: t('common:TXT_Invoicing'),
                      value: 'invoicing',
                      onClick: () => {
                        setInvoicingOrders([row]);
                      },
                    },
                    {
                      text: t('sales_orders:ORDERTEXT_orderText'),
                      value: 'text',
                      onClick: () => {
                        setOpenedTextDialog({
                          orderId: row.order,
                          hasText: row.textsExist,
                          defaultLanguage: row.language,
                        });
                      },
                    },
                  ]}
                  backgroundColor="light"
                  slotProps={{
                    iconButton: {
                      icon: 'verticalMore',
                      color: 'inherit',
                      variant: 'text',
                      size: 'small',
                      id: 'moreActions',
                    },
                    menu: {
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                    },
                  }}
                />
              </Stack>
            );
          },
        },
      ];
    }, [openedTextOrderId, openedTextOrderId, openedTextDialog?.orderId, currency]);

  return (
    <>
      {!!creditOrderDialogProps && (
        <CreditOrder
          open={!!creditOrderDialogProps}
          handleClose={() => setCreditOrderDialogProps(undefined)}
          orderId={creditOrderDialogProps?.orderId}
          customerNumber={creditOrderDialogProps?.customerNumber}
          handler={creditOrderDialogProps?.handler}
          orderType={creditOrderDialogProps?.orderType}
          refreshTable={() => {
            tableRef.current?.refresh();
          }}
        />
      )}

      {!!openedTextDialog && (
        <OrderText
          open={!!openedTextDialog}
          order={openedTextDialog?.orderId}
          textExists={openedTextDialog?.hasText}
          defaultLanguage={openedTextDialog?.defaultLanguage}
          handleClose={(needsTableRefresh) => {
            if (needsTableRefresh) {
              tableRef.current?.refresh();
            }
            setOpenedTextDialog(null);
          }}
        />
      )}

      {orderConfirmationOrders?.length > 0 && (
        <SalesOrderConfirmation
          open={orderConfirmationOrders?.length > 0}
          handleClose={() => setOrderConfirmationOrders([])}
          selectedRows={orderConfirmationOrders}
          onSuccess={() => {
            tableRef.current?.refresh(true);
          }}
        />
      )}
      {pickListConfirmationOrders?.length > 0 && (
        <PickListConfirmation
          open={pickListConfirmationOrders?.length > 0}
          handleClose={() => setPickListConfirmationOrders([])}
          selectedRows={pickListConfirmationOrders}
          onSuccess={() => {
            tableRef.current?.refresh(true);
          }}
        />
      )}
      {proformaInvoiceOrders?.length > 0 && (
        <ProformaInvoice
          open={proformaInvoiceOrders?.length > 0}
          handleClose={() => setProformaInvoiceOrders([])}
          selectedRows={proformaInvoiceOrders}
          onSuccess={() => {
            tableRef.current?.refresh(true);
          }}
        />
      )}
      {invoicingOrders?.length > 0 && (
        <Invoicing
          open={invoicingOrders?.length > 0}
          handleClose={() => setInvoicingOrders([])}
          selectedRows={invoicingOrders}
          onSuccess={() => {
            tableRef.current?.refresh(true);
          }}
        />
      )}

      {ordersToDelete.length > 0 && (
        <DeleteOrders
          orders={ordersToDelete}
          open={ordersToDelete.length > 0}
          handleClose={() => {
            setOrdersToDelete([]);
          }}
          refreshTable={() => {
            tableRef.current?.refresh(true);
          }}
        />
      )}

      {orderToUnlock && (
        <UnlockOrder
          orderToUnlock={orderToUnlock}
          open={!!orderToUnlock}
          handleClose={(refresh: boolean) => {
            setOrderToUnlock(undefined);
            refresh && tableRef.current?.refresh();
          }}
        />
      )}

      {openExportRowsPopup && (
        <ExportDataDialog
          open={openExportRowsPopup}
          handleClose={() => {
            toggleopenExportRowsPopup(!openExportRowsPopup);
          }}
        />
      )}

      <IptorTable
        tableID="sales-order-list"
        apiId="internal.v1.base-orders"
        apiEndpoint={'GET /salesOrders'}
        selectable="multiple"
        additionalActions={
          <>
            <TableFilter />
            <TableSettings />
            <Dropdown {...tableActionsProps} />
            <Button id="newSoe" variant="contained" color="secondary" component={RouterLink} to="/orders/create">
              + {t('common:TXT_New_Sales_Order')}
            </Button>
          </>
        }
        columns={columns}
        additionalFilters={
          <>
            <Combo
              size="small"
              disableClearable
              disableTyping
              value={handler}
              id="orderCategory"
              onChange={(_, v) => setHandler(v as HandlerOptionType)}
              options={handlerOptions}
              sx={{
                '& .MuiInputBase-root': {
                  minWidth: '175px',
                  backgroundColor: palette.background.default,
                },
              }}
            />
            <Switch
              label={t('common:TXT_View_by_Items')}
              labelPosition="start"
              id="viewItems"
              checked={false}
              onChange={() => setShowSalesOrderLines(true)}
            />
            <Switch
              label={t('common:TXT_Orders_in_Error')}
              labelPosition="start"
              id="orderInError"
              checked={showOrdersInError}
              onChange={() => setShowOrdersInError(!showOrdersInError)}
            />
          </>
        }
        customRef={tableRef}
        apiParams={params}
        onSelectedRowsChange={(props) => {
          setSelectedRows(props.rows);
        }}
      />
    </>
  );
};

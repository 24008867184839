import { Accordion, Checkbox, DatePicker, Text, useIptorTranslation, FormControlLabel } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import DiscountCardsSection from '../../../components/discounts/DiscountCardsSection';
import { Order } from '../../../constants';
import { formatDate, formatDateToPayloadFormat } from '../../../utils/formatDate';
import { SalesPromotionDropdown, VatHandlingCodesDropdown } from '@iptor/business';

type CurrencyAndTaxProps = {
  register?: UseFormRegister<Partial<Order>>;
  setValue?: UseFormSetValue<Partial<Order>>;
  getValues?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  order?: number;
  createProcess?: boolean;
  orderCurrency: string;
};

const CurrencyAndTax = ({
  control,
  register,
  getValues,
  setValue,
  order,
  createProcess,
  orderCurrency,
}: CurrencyAndTaxProps) => {
  const { t } = useIptorTranslation();
  return (
    <Accordion
      headingTitle={t('common:TXT_Currency_And_Tax')}
      moreInfo={
        <Grid container columnSpacing={3} rowSpacing={1} alignItems="flex-end">
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', margin: 0 }}
              control={
                <Text id="primaryCurrency" fontSize={14} color="textDisabled">
                  {getValues?.('primaryCurrency') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_Currency')}</Text>}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', margin: 0 }}
              control={
                <Text id="priceList" fontSize={14} color="textDisabled">
                  {getValues?.('priceList') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_Price_List')}</Text>}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <Controller
              name="backlogAfterPickListConf"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox id={field.name} {...field} />}
                  label={t('common:TXT_Backlog_At_Pick')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid size={12 / 4}>
          <Controller
            name="vatHandlingCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <VatHandlingCodesDropdown
                value={field.value}
                onChange={(value) => field.onChange(value)}
                id="vatHandlingCode"
                fullWidth
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_VAT_Handling_Codes')}
                readOnly={!createProcess}
              />
            )}
          />
        </Grid>

        <Grid size={12 / 4}>
          <Controller
            name="promotionCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <SalesPromotionDropdown
                onChange={(value) => field.onChange(value)}
                id="promotionCode"
                value={field.value}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                fullWidth
                label={t('common:TXT_Promotion')}
              />
            )}
          />
        </Grid>

        <Grid size={12 / 2}>
          <DatePicker
            label={t('common:TXT_Pricing_Date')}
            value={formatDate(getValues?.('pricingDate'))}
            onChange={(value) => {
              setValue('pricingDate', formatDateToPayloadFormat(value));
            }}
            slotProps={{
              textField: {
                id: 'pricingDate',
              },
            }}
          />
          <input {...register('pricingDate')} type="hidden" name="pricingDate" />
        </Grid>

        <Grid size={12} sx={{ marginTop: 3 }}>
          <Controller
            name="vat"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox id="vat" {...field} checked={!!field.value} />}
                label={t('common:TXT_VAT_Based')}
                readOnly={!createProcess}
              />
            )}
          />
        </Grid>
        {order && <DiscountCardsSection order={order} orderCurrency={orderCurrency} />}
      </Grid>
    </Accordion>
  );
};

export default CurrencyAndTax;

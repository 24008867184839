import {
  debounce,
  DialogLayout,
  IconButton,
  SnackbarWrapper,
  Switch,
  TableFilter,
  TableRowValues,
  TableSettings,
  TextField,
  theme,
  useCustomSnackbar,
  useIptorTranslation,
} from '@iptor/base';
import { Button, InputAdornment, Stack } from '@mui/material';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IptorTable, IptorTableColumn } from '../IptorTable';
import { OperationParameters } from '../../framework/openapi';
import { PageSize } from '@iptor/base';

export type BusinessPartnersProps = {
  open: boolean;
  handleClose: () => void;
  handleSelected: (businessPartners: Record<string, TableRowValues>) => void;
  initialValue?: string;
  limit?: number;
  pageSize?: PageSize;
  businessPartnerType?: BusinessPartnerType;
  fullWidth?: boolean;
};
export enum BusinessPartnerType {
  Customer = 1,
  Supplier = 2,
  BusinessPartner = undefined,
}

export const BusinessPartners: FunctionComponent<BusinessPartnersProps> = ({
  open,
  handleClose,
  handleSelected,
  initialValue,
  limit = 50,
  pageSize = 10,
  businessPartnerType = BusinessPartnerType.BusinessPartner,
  fullWidth,
  ...props
}: BusinessPartnersProps) => {
  const [searchValue, setSearchValue] = useState<string>(initialValue);
  const debouncedSetSearchValue = useCallback(
    (value: string, debounceTimeInMs = 300) => debounce(() => setSearchValue(value), debounceTimeInMs)[0](),
    [],
  );
  const [businessPartnersActive, setBusinessPartnersActive] = useState<boolean>(true);
  const { t } = useIptorTranslation();
  const { showSnackbar } = useCustomSnackbar();
  const iptorTableRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>();
  const handleInputFieldChange = (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSetSearchValue(e?.target?.value ?? '');
  };
  const params = useMemo<OperationParameters<'internal.v1.base-general', 'GET /businessPartners'>>(() => {
    let params: OperationParameters<'internal.v1.base-general', 'GET /businessPartners'> = {
      limit: limit,
      active: businessPartnersActive,
      freeTextSearch: searchValue,
    };
    if (businessPartnerType) params = { ...params, type: businessPartnerType };
    return params;
  }, [searchValue, businessPartnersActive]);

  useEffect(() => {
    iptorTableRef?.current?.resetToFirstPage();
    iptorTableRef?.current?.refresh();
  }, [params]);
  const businessPartnerColumns = useMemo<
    IptorTableColumn<'internal.v1.base-general', 'GET /businessPartners', { active: 'string' }>[]
  >(
    () => [
      {
        display: t('common:TXT_Number'),
        key: 'businessPartner',
      },
      {
        display: t('common:TXT_InternalName'),
        key: 'internalName',
      },
      {
        display: t('common:TXT_Name'),
        key: 'description',
      },
      {
        display: t('common:TXT_Type'),
        key: 'type',
      },
      {
        display: t('common:TXT_Active'),
        key: 'active',
        disableFilter: true,
        disableSort: true,
        cellRenderer: () => (
          // 'active' field not available on the response
          <>
            {businessPartnersActive ? (
              <IconButton
                variant={'text'}
                id="active"
                color="primary"
                icon="checkmarkCircle02"
                style={{ pointerEvents: 'none' }}
              ></IconButton>
            ) : (
              <IconButton
                variant={'text'}
                color="error"
                icon="cancelCircle"
                style={{ pointerEvents: 'none' }}
              ></IconButton>
            )}
          </>
        ),
      },
    ],
    [businessPartnersActive],
  );

  return (
    <SnackbarWrapper>
      <DialogLayout
        title={t(
          businessPartnerType === BusinessPartnerType.Customer
            ? `common:TXT_Customers`
            : businessPartnerType === BusinessPartnerType.Supplier
              ? `common:TXT_Suppliers`
              : `common:TXT_BusinessPartners`,
        )}
        open={open}
        fullScreen
        onClose={handleClose}
        fullWidth={fullWidth}
        actions={
          <>
            <Button id="close" variant="outlined" size="medium" onClick={handleClose}>
              {t('common:TXT_Close')}
            </Button>
            <Button
              variant="contained"
              id="select"
              size="medium"
              onClick={() => {
                if (iptorTableRef?.current?.selectedRows && iptorTableRef?.current?.selectedRows.length > 0) {
                  handleSelected(iptorTableRef?.current?.selectedRows[0]);
                } else {
                  showSnackbar({
                    message: t('common:TXT_No_Rows_Selected'),
                    variant: 'warning',
                  });
                }
              }}
            >
              {t('common:TXT_Select')}
            </Button>
          </>
        }
      >
        <IptorTable
          {...props}
          // maxHeight={500} // TODO: set 100% when typing inside IptorTable ready
          apiId="internal.v1.base-general"
          apiEndpoint={'GET /businessPartners'}
          customRef={iptorTableRef}
          apiParams={params}
          additionalActions={
            <>
              <TableFilter />
              <TableSettings />
            </>
          }
          disableActionBar={false}
          selectable={'single'}
          columns={businessPartnerColumns}
          pageSize={pageSize}
          additionalFilters={
            <Stack gap={2} direction="row" alignSelf="center" height="max-content">
              <TextField
                inputRef={inputRef}
                id="businessPartner"
                placeholder={t('common:TXT_Enter_Business_Partner_Placeholder')}
                defaultValue={initialValue}
                onChange={(e) => {
                  handleInputFieldChange(e);
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    minWidth: '500px',
                    height: '48px',
                    background: theme.palette.background.default,
                  },
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton variant={'text'} color="primary" icon="search01"></IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (inputRef.current) {
                            inputRef.current.value = '';
                            handleInputFieldChange(); // run onChange manually as it would not run after ref assignment
                          }
                        }}
                      >
                        {searchValue && <IconButton variant={'text'} color="primary" icon="cancel01"></IconButton>}
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Switch
                label={t('common:TXT_Active')}
                labelPosition="start"
                checked={businessPartnersActive}
                id="activeSwitch"
                onChange={() => setBusinessPartnersActive(!businessPartnersActive)}
              />
            </Stack>
          }
        />
      </DialogLayout>
    </SnackbarWrapper>
  );
};

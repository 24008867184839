import { forwardRef, PropsWithChildren, ElementType } from 'react';
import { Box as MuiBox, BoxProps as MuiBoxProps, useTheme } from '@mui/material';
import { Box as BoxStyles } from '../../constants/Box';

export type BoxProps<C extends ElementType = 'div'> = MuiBoxProps<C> & {
  className?: string;
  variant?: 'default' | 'primary' | 'secondary';
  component?: C;
};

export const Box = forwardRef(
  <C extends ElementType = 'div'>(
    { className, variant = 'default', component, children, sx, ...props }: PropsWithChildren<BoxProps<C>>,
    ref: React.Ref<unknown>,
  ) => {
    const theme = useTheme();
    const variantStyle = BoxStyles.styleOverrides
      .root({ theme })
      .variants.find((v: { props: { variant: string } }) => v.props.variant === variant)?.style;

    return (
      <MuiBox
        className={className}
        ref={ref}
        component={component}
        sx={[variantStyle || {}, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        {children}
      </MuiBox>
    );
  },
) as <C extends ElementType = 'div'>(props: BoxProps<C> & { ref?: React.Ref<unknown> }) => JSX.Element;

import {
  Button,
  Checkbox,
  CurrencyField,
  DialogLayout,
  NumericField,
  Text,
  TextField,
  useClientSettings,
  useIptorTranslation,
} from '@iptor/base';
import { DiscountIdsDropdown, openAPIManager, OperationResponseDataData, useOpenAPIMethod } from '@iptor/business';
import { Grid2 as Grid, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import { formatErrorText } from '../../utils/formatError';

type DiscountType = OperationResponseDataData<
  'internal.v1.base-orders',
  'GET /salesOrders/{order}/discounts/{generalDiscountKey}'
>;

interface Props {
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  isEdit: boolean;
  discountToEdit?: DiscountType;
  orderId: number;
  orderLine?: number;
  currency: string;
}

interface DiscountFormValues {
  discountId: string;
  discountPercentage: number;
  discountDescription: string;
  discountAmount: number;
}

const LabelValueComponent = ({ label, value }: { label: string; value: string }) => (
  <div>
    <dt>
      <Text fontSize={14} fontWeight={400}>
        {label}
      </Text>
    </dt>
    <dd>
      <Text id={label} fontSize={16} fontWeight={600}>
        {value || '-'}
      </Text>
    </dd>
  </div>
);

function AddEditDiscount({ handleClose, open, isEdit, discountToEdit, orderId, orderLine, currency }: Props) {
  const { t } = useIptorTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedDiscountType, setSelectedDiscountType] = useState<'percentage' | 'amount'>('percentage');
  const { notify } = useOrderNotifications();
  const { formatNumber, formatCurrency } = useClientSettings();
  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<DiscountFormValues>({
    defaultValues: {
      discountId: undefined,
      discountPercentage: undefined,
      discountAmount: undefined,
      discountDescription: undefined,
    },
  });

  const discountDetailsApi = useOpenAPIMethod(
    'internal.v1.base-orders',
    orderLine
      ? 'GET /salesOrders/{order}/lines/{orderLine}/discounts/{generalDiscountKey}'
      : 'GET /salesOrders/{order}/discounts/{generalDiscountKey}',
  );

  useEffect(() => {
    if (isEdit && discountToEdit) {
      const { generalDiscountKey, sequence, sequenceGroup } = discountToEdit;
      discountDetailsApi.execute({ order: orderId, generalDiscountKey, sequence, sequenceGroup, orderLine });
      setValue('discountId', discountToEdit.discountId);
      if (discountToEdit.discountPercentage || !orderLine) {
        setSelectedDiscountType('percentage');
        setValue('discountPercentage', discountToEdit.discountPercentage);
      } else {
        setSelectedDiscountType('amount');
        setValue('discountAmount', discountToEdit.discountAmountPerUnit);
      }
    }
  }, [isEdit, discountToEdit, setValue]);

  const onSubmit = async (data: DiscountFormValues) => {
    setLoading(true);

    try {
      if (isEdit && discountToEdit) {
        const { generalDiscountKey, sequence, sequenceGroup } = discountToEdit;

        await openAPIManager.execute(
          'internal.v1.base-orders',
          orderLine
            ? 'PUT /salesOrders/{order}/lines/{orderLine}/discounts/{generalDiscountKey}'
            : 'PUT /salesOrders/{order}/discounts/{generalDiscountKey}',
          { generalDiscountKey, sequence, sequenceGroup, order: orderId, orderLine },
          { discountPercentage: data.discountPercentage, discountAmount: data.discountAmount },
        );

        notify('success', t('sales_orders:DISCOUNT_editSuccessfully'));
      } else {
        await openAPIManager.execute(
          'internal.v1.base-orders',
          orderLine ? 'POST /salesOrders/{order}/lines/{orderLine}/discounts' : 'POST /salesOrders/{order}/discounts',
          { order: orderId, orderLine },
          {
            discountId: data.discountId,
            discountPercentage: data.discountPercentage,
            discountAmount: data.discountAmount,
          },
        );

        notify('success', t('sales_orders:DISCOUNT_addSuccessfully'));
      }
    } catch (error) {
      handleClose();
      notify(
        'error',
        formatErrorText(error),
        false,
        `${t('sales_orders:DISCOUNT_errorWhile')} ${t(isEdit ? 'sales_orders:DISCOUNT_editing' : 'sales_orders:DISCOUNT_adding')}: `,
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <DialogLayout
      title={isEdit ? t('sales_orders:DISCOUNT_editTitle') : t('sales_orders:DISCOUNT_addTitle')}
      actions={[
        <Button id={'cancelDiscount'} variant="outlined" size="medium" onClick={() => handleClose(false)}>
          {t('sales_orders:DISCOUNT_cancel')}
        </Button>,
        <Button
          variant="contained"
          size="medium"
          onClick={handleSubmit(onSubmit)}
          id={'addDiscount'}
          loading={loading}
          disabled={
            discountToEdit?.discountPercentage === watch('discountPercentage') &&
            discountToEdit?.discountAmountPerUnit === watch('discountAmount')
          }
        >
          {isEdit ? t('sales_orders:DISCOUNT_save') : t('sales_orders:DISCOUNT_add')}
        </Button>,
      ]}
      open={open}
      onClose={() => handleClose(false)}
    >
      <form>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid size={12 / 2}>
            {isEdit ? (
              <TextField
                id={'discountId'}
                label={t('sales_orders:DISCOUNT_id')}
                readOnly
                fullWidth
                value={`${discountToEdit?.discountId} - ${discountToEdit?.discountIdDescription}`}
              />
            ) : (
              <>
                <DiscountIdsDropdown
                  onChange={(value) => {
                    setValue('discountDescription', `${t('sales_orders:DISCOUNT_descriptionPrefix')} ${value}`);
                    setValue('discountId', `${value}`);
                  }}
                  id={'discountDescriptionPrefix'}
                  fullWidth
                  label={t('sales_orders:DISCOUNT_id_dropdown')}
                  color={errors.discountId ? 'error' : 'primary'}
                  helperText={errors.discountId ? errors.discountId.message : ''}
                  placeHolder="Discount Type"
                  value={watch('discountId') ?? ''}
                />
                <input {...register('discountId', { required: t('sales_orders:DISCOUNT_idRequired') })} type="hidden" />
              </>
            )}
          </Grid>
          <Grid size={12 / 2}>
            {isEdit && (
              <TextField
                id={'discountType'}
                label={t('sales_orders:DISCOUNT_type')}
                readOnly
                fullWidth
                loading={discountDetailsApi.loading}
                value={`${discountToEdit?.discountType} - ${discountToEdit?.discountTypeDescription}`}
              />
            )}
          </Grid>
        </Grid>
        {orderLine && !isEdit && (
          <Grid container columnSpacing={1} rowSpacing={2} alignItems="center" marginTop={2}>
            <Radio
              sx={{ padding: 0, paddingY: 1 }}
              id={'discountAmount'}
              data-iscapture="true"
              data-for="global"
              data-tip={'discountAmount'}
              disabled={loading || isEdit}
              checked={selectedDiscountType === 'percentage'}
              onChange={(e, checked) => {
                setSelectedDiscountType(checked ? 'percentage' : 'amount');
                setValue('discountAmount', undefined);
              }}
            />
            <Text marginRight={3}>{t('sales_orders:DISCOUNT_percentage')}</Text>
            <Radio
              sx={{ padding: 0, paddingY: 1 }}
              disabled={loading || isEdit}
              id={'discountPercentage'}
              data-iscapture="true"
              data-for="global"
              data-tip={'discountPercentage'}
              checked={selectedDiscountType === 'amount'}
              onChange={(e, checked) => {
                setSelectedDiscountType(checked ? 'amount' : 'percentage');
                setValue('discountPercentage', undefined);
              }}
            />{' '}
            <Text>{t('sales_orders:DISCOUNT_amount')}</Text>
          </Grid>
        )}
        <Grid container columnSpacing={3} rowSpacing={2} alignItems="center">
          <Grid size={6}>
            {selectedDiscountType === 'amount' ? (
              <Controller
                name="discountAmount"
                control={control}
                rules={{
                  required:
                    selectedDiscountType === 'amount' ? t('sales_orders:DISCOUNT_percentageRequired') : undefined,
                }}
                render={({ field }) => (
                  <CurrencyField
                    {...field}
                    value={watch('discountAmount')}
                    label={'Discount Amount'}
                    min={0}
                    helperText={errors.discountAmount?.message}
                    id={field.name}
                    fullWidth
                    color={errors.discountAmount?.message ? 'error' : undefined}
                    onChangeNumeric={field.onChange}
                    disabled={selectedDiscountType !== 'amount'}
                    currency={currency}
                    placement="start"
                  />
                )}
              />
            ) : (
              <Controller
                name="discountPercentage"
                control={control}
                rules={{
                  required:
                    selectedDiscountType === 'percentage' ? t('sales_orders:DISCOUNT_percentageRequired') : undefined,
                  min: { value: 0, message: t('sales_orders:DISCOUNT_percentageMin') },
                  max: { value: 100, message: t('sales_orders:DISCOUNT_percentageMax') },
                }}
                render={({ field }) => (
                  <NumericField
                    {...field}
                    label={t('sales_orders:DISCOUNT_discountPercentage')}
                    id={field.name}
                    helperText={errors.discountPercentage?.message}
                    min={0}
                    max={100}
                    fullWidth
                    color={errors.discountPercentage?.message ? 'error' : undefined}
                    onChangeNumeric={field.onChange}
                    disabled={selectedDiscountType !== 'percentage'}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        {isEdit && (
          <>
            <Text marginY={2.5}>{t('sales_orders:DISCOUNT_information')}</Text>

            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid size={12 / 4}>
                <LabelValueComponent
                  label={t('sales_orders:DISCOUNT_totalAmount')}
                  value={formatCurrency({
                    amount: discountDetailsApi.response?.data?.data?.headerDiscountAmount,
                    currency: currency,
                  })}
                />
              </Grid>
              <Grid size={12 / 4}>
                <LabelValueComponent
                  label={t('sales_orders:DISCOUNT_vatAmount')}
                  value={formatCurrency({
                    amount: discountDetailsApi.response?.data?.data?.headerDiscountVatAmount,
                    currency: currency,
                  })}
                />
              </Grid>
              <Grid size={12 / 4}>
                <LabelValueComponent
                  label={t('sales_orders:DISCOUNT_quantityLimit')}
                  value={formatNumber({ number: discountDetailsApi.response?.data?.data?.quantityLimit })}
                />
              </Grid>
              <Grid size={12 / 4}>
                <LabelValueComponent
                  value={discountDetailsApi.response?.data?.data?.limitUnit}
                  label={t('sales_orders:DISCOUNT_unit')}
                />
              </Grid>
              <Grid size={12 / 4}>
                <LabelValueComponent
                  value={formatCurrency({
                    amount: discountDetailsApi.response?.data?.data?.amountLimit,
                    currency: discountDetailsApi.response?.data?.data?.limitCurrency || currency,
                  })}
                  label={t('sales_orders:DISCOUNT_amountLimit')}
                />
              </Grid>

              <Grid size={12 / 4}>
                <Checkbox
                  checked={discountDetailsApi.response?.data?.data?.claimable}
                  label={t('sales_orders:DISCOUNT_claimable')}
                  id={'disount_claimable'}
                  readOnly
                  disabled
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </DialogLayout>
  );
}

export default AddEditDiscount;

import { ApplicationContext, CopyableTooltip, Program, SnackbarWrapper } from '@iptor/base';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, MemoryRouter as Router, Switch, useLocation } from 'react-router-dom';
import CreateSalesOrder from './forms/CreateSalesOrder';
import HeaderInfoForm from './forms/SalesOrderHeader';
import { ItemInfoForm } from './forms/ItemInfo';
import { SalesOrderListForm } from './forms/SalesOrderList';
import { OrderState } from './store';
import EditSalesOrderItems from './forms/SalesOrderItems';
import { FormStateProvider } from '@iptor/base';
import { SideMenuContextProvider } from '@iptor/business';
import ReactTooltip from 'react-tooltip';

const _Router = ({ windowId }: { windowId: string }): null => {
  useEffect(() => {
    sessionStorage.setItem(windowId, location.pathname + location.search);
    const observer = new MutationObserver(() => {
      ReactTooltip.rebuild();
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect(); // Cleanup on unmount
  }, [location, windowId]);

  return null; // Don't render children here, handled in SOEApplication
};

export const SOEApplication = ({
  id,
  quicklinks,
  quickLaunch,
  endLoading,
  startLoading,
  flattenedMenu,
  getMenuItems,
  handleShowMenu,
  mainMenu,
  handleShowFavourites,
  desktopState,
  restoreFocusWindow,
  handleSettingsShow,
  handleRoleShow,
  dateFormat,
  CloseWindow,
  onRefresh,
}: {
  id: string;
  quicklinks: Program[];
  quickLaunch: (program: Program, endLoading: () => void, type: string) => void;
  endLoading?: () => void;
  startLoading?: () => void;
  flattenedMenu: Record<string, any>;
  getMenuItems?: (programId: string, flattenedMenu: Record<string, any>) => Program;
  handleShowMenu: (menuId: string) => void;
  mainMenu: any;
  handleShowFavourites: () => void;
  desktopState?: any;
  restoreFocusWindow?: (timeoutInterval?: number) => void;
  handleSettingsShow: () => void;
  handleRoleShow: () => void;
  dateFormat?: string;
  CloseWindow: (windowId: string, endLoading: () => void) => void;
  onRefresh: () => void;
}) => {
  const [key, setKey] = useState(0);
  const [tooltipKey, setTooltipKey] = useState(0);

  useEffect(() => {
    setTooltipKey((prev) => prev + 1); // Force tooltip re-render
  }, [location, id]);

  return (
    <Provider store={OrderState}>
      <FormStateProvider>
        <SideMenuContextProvider>
          <CopyableTooltip key={tooltipKey} />
          <ApplicationContext.Provider
            value={{
              applicationID: 'SALES_ORDER_ENTRY',
              quicklinks: quicklinks,
              quickLaunch: quickLaunch,
              endLoading: endLoading,
              startLoading: startLoading,
              flattenedMenu: flattenedMenu,
              getMenuItems: getMenuItems,
              handleShowMenu: handleShowMenu,
              mainMenu: mainMenu,
              handleShowFavourites: handleShowFavourites,
              desktopState: desktopState,
              restoreFocusWindow: restoreFocusWindow,
              handleSettingsShow: handleSettingsShow,
              handleRoleShow: handleRoleShow,
              dateFormat: dateFormat,
              closeWindow: () => {
                startLoading();
                CloseWindow(id, endLoading);
              },
              refresh: () => {
                setKey((key) => key + 1);
                onRefresh();
              },
            }}
          >
            <div id="overlay-containersoe"></div>
            <SnackbarWrapper>
              <Router>
                <_Router key={key} windowId={id} />
                <Switch>
                  <Route key={key} path="/orders/create" exact>
                    <CreateSalesOrder />
                  </Route>
                  <Route key={key} path="/orders/:orderId/items" exact>
                    <EditSalesOrderItems />
                  </Route>
                  <Route key={key} path="/orders/:orderId/header" exact>
                    <HeaderInfoForm />
                  </Route>
                  <Route key={key} path="/items/:orderId/:orderLine/info" exact>
                    <ItemInfoForm />
                  </Route>
                  <Route key={key} path="/" exact>
                    <SalesOrderListForm />
                  </Route>
                </Switch>
              </Router>
            </SnackbarWrapper>
          </ApplicationContext.Provider>
        </SideMenuContextProvider>
      </FormStateProvider>
    </Provider>
  );
};

export class PeriodFormatter {
  isNumeric(value: string): boolean {
    return !isNaN(Number(value));
  }

  in(value: string) {
    let formatString = value.trim();
    if (this.isNumeric(formatString)) {
      if (formatString.length === 5 || formatString.length === 6) {
        let index = formatString.length - 4;
        return formatString.substring(0, index) + '/' + formatString.substring(index);
      }
    }
    return formatString;
  }

  out(value: string, externalize: boolean) {
    if (externalize) return value.replace('/', '');
    return this.in(value);
  }

  formatEdit(value: string) {
    return this.out(value, true);
  }
}

import { ChangeEvent, FocusEvent, useRef } from 'react';
import { Box, Combo, ComboProps, Text } from '@iptor/base';
import { AutocompleteChangeReason, List, ListItem, ListItemText, Paper } from '@mui/material';

export type BaseDropdownProps = Omit<ComboProps, 'value' | 'onChange'> & {
  value?: string | number;
  columnNames?: string[];
  onChange: (value: string | number, reason?: AutocompleteChangeReason) => void;
  optionLabel?: 'value' | 'label';
};

export const BaseDropdown = ({
  columnNames,
  value,
  loading,
  onChange,
  optionLabel = 'value',
  options,
  ...props
}: BaseDropdownProps) => {
  const selectedOption = options.find(
    (option) => option.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase(),
  );

  const previousValueRef = useRef<string | number | undefined>(value);

  const handleFocus = () => {
    previousValueRef.current = value;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (!inputValue && previousValueRef.current) {
      onChange('', 'clear');

      return;
    }
    let matchingOption;
    if (!!inputValue && inputValue !== '') {
      matchingOption = options.find((option) => option.value.toString().toLowerCase() === inputValue.toLowerCase());

      if (!matchingOption) {
        matchingOption = options.find((option) => option.label.toString().toLowerCase() === inputValue.toLowerCase());
      }
    }

    const newValue = matchingOption ? matchingOption.value : inputValue ? inputValue : '';

    if (newValue.toString() !== previousValueRef.current?.toString()) {
      onChange(newValue, 'selectOption');
    }
  };

  return (
    <Combo
      {...props}
      freeSolo={true}
      forcePopupIcon={true}
      disableCloseOnSelect={false}
      loading={loading}
      clearIcon={!value}
      value={
        selectedOption?.value
          ? { label: selectedOption.label, value: selectedOption.value }
          : value
            ? { label: value.toString(), value: value }
            : { label: '', value: '' }
      }
      options={options}
      onChange={(_event, newValue, reason) => {
        const newValueToSet = (newValue as { value: string | number; label: string })?.value ?? '';

        previousValueRef.current = newValueToSet;
        onChange(newValueToSet, reason);
      }}
      textFieldProps={{
        ...props.textFieldProps,
        onFocus: handleFocus,
        onBlur: handleBlur,
        placeholder: !loading && options.length === 0 ? 'No options' : undefined,
      }}
      getOptionLabel={(option) => String((option as { value: string | number; label: string })?.[optionLabel])}
      autoComplete
      PaperComponent={({ children }) => (
        <Paper
          sx={(theme) => ({
            width: '100%',
            overflow: 'hidden',
            padding: theme.spacing(1),
            paddingBottom: 0,
            backgroundColor: theme.palette.background.default,
          })}
          square
        >
          {!loading && columnNames && columnNames.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({
                display: 'flex',
                backgroundColor: 'transparent',
                padding: theme.spacing(1),
              })}
            >
              {columnNames.map((columnName, index) => (
                <Text key={`${columnName}[${index}]`} variant="subtitle2" fontWeight={600} sx={{ width: '50%' }}>
                  {columnName}
                </Text>
              ))}
            </Box>
          )}

          <List dense sx={{ maxHeight: '300px', overflow: 'hidden', padding: '0 !important' }}>
            {children}
          </List>
        </Paper>
      )}
      renderOption={(props, option) => {
        const itemData = options.find((item) => item.value === option.value);

        return (
          itemData && (
            <ListItem
              {...props}
              key={`${option.value}-${option.label}`}
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                padding: `${theme.spacing(1)} !important`,
              })}
            >
              <ListItemText primary={<Text variant="body2">{itemData.value}</Text>} sx={{ width: '50%' }} />
              <ListItemText primary={<Text variant="body2">{itemData.label}</Text>} sx={{ width: '50%' }} />
            </ListItem>
          )
        );
      }}
      ListboxProps={{
        sx: {
          maxHeight: '300px',
          padding: '0 !important',
          paddingBottom: '8px !important',
          '& .MuiAutocomplete-listbox.MuiAutocomplete-option': {
            padding: '0 8px 8px !important',
          },
        },
      }}
    />
  );
};

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { Button, useCustomSnackbar, useIptorTranslation } from '@iptor/base';
import { FormLayout, openAPIManager, OperationResponseItems, useOpenAPIMethod } from '@iptor/business';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import CreateSalesOrderSteps, { FormStep } from '../../components/CreateSalesOrderSteps';
import OrderInsights from '../../components/OrderInsights';
import { Order } from '../../constants';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import CurrencyAndTax from './components/CurrencyAndTax';
import InvoicingInfo from './components/InvoicingInfo';
import Miscellaneous from './components/Miscellaneous';
import Addresses from './components/OrderHeaderAddresses';
import ProjectInfoFees from './components/ProjectInfoFees';
import ReferenceSourcingBacklog from './components/ReferenceSourcingBacklog';
import ShippingInfo from './components/ShippingInfo';

const Container = styled(Box)`
  width: 100%;
`;

const HeaderInfoForm = () => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const { notify } = useOrderNotifications();
  const { t } = useIptorTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const createProcess = searchParams.get('create') === 'true';
  const { closeAllSnackbars } = useCustomSnackbar();
  const [order, setOrder] = useState<OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders'>[0]>(null);
  const getSalesOrder = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Partial<Order>>();

  useEffect(() => {
    getSalesOrder.execute({ order: +orderId });
  }, []);

  useEffect(() => {
    if (getSalesOrder.response?.data?.data) {
      setOrder(getSalesOrder.response.data.data);
    }
  }, [getSalesOrder.response]);

  useEffect(() => {
    if (order) {
      reset(order);
    }
  }, [order, reset]);

  const onSubmit = handleSubmit((data) => {
    closeAllSnackbars();
    setIsSubmitting(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        'PUT /salesOrders/{order}',
        {
          order: +order.order,
        },
        { ...data },
      )
      .then((response: any) => {
        setOrder(JSON.parse(response.config.data));
        getSalesOrder.execute({ order: +orderId });
        notify('success', `Order (${order.order}) has been updated successfully`);
        if (response?.data?.messages) {
          notify('warning', response?.data?.messages, true);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        notify('error', error?.response?.data?.messages);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return (
    <FormLayout
      title={t('common:TXT_Order_Header_Info')}
      actions={[
        <Button
          variant="outlined"
          size="medium"
          id="close"
          onClick={() => {
            closeAllSnackbars();
            if (createProcess) {
              history.go(-3);
            } else {
              history.goBack();
            }
          }}
        >
          {t('common:TXT_Close')}
        </Button>,
        <Button
          variant="contained"
          id="update"
          size="medium"
          form="orderHeaderForm"
          disabled={getSalesOrder.loading}
          loading={isSubmitting}
          hidden={createProcess}
          type="submit"
        >
          {createProcess ? t('common:TXT_Create_new_sales_order') : t('common:TXT_Update')}
        </Button>,
      ]}
      fixedActionBar
    >
      <Container>
        {createProcess && order && (
          <CreateSalesOrderSteps
            watch={watch}
            setValue={setValue}
            reset={reset}
            control={control}
            getValues={getValues}
            currentStep={FormStep.ADDITIONAL_INFO}
            order={order}
          />
        )}
        <OrderInsights order={order as Order} />
        <Typography fontSize={18} fontWeight={600} sx={{ margin: [1, 2] }}>
          {t('common:TXT_Order_Header_Details')}
        </Typography>
        <Grid component="form" container direction="column" spacing={1} id="orderHeaderForm" onSubmit={onSubmit}>
          {!order ? (
            <>
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
              <Skeleton height={83} sx={{ transform: 'none' }} />
            </>
          ) : (
            <>
              <ShippingInfo
                register={register}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                control={control}
                errors={errors}
                createProcess={createProcess}
                businessPartner={order?.customer}
              />
              <CurrencyAndTax
                register={register}
                setValue={setValue}
                getValues={getValues}
                control={control}
                order={order.order}
                orderCurrency={order.primaryCurrencyISO || order.primaryCurrencyISO}
                createProcess={createProcess}
              />
              <ReferenceSourcingBacklog
                register={register}
                setValue={setValue}
                getValues={getValues}
                control={control}
                errors={errors}
                createProcess={createProcess}
              />
              <ProjectInfoFees control={control} errors={errors} createProcess={createProcess} />
              <InvoicingInfo getValues={getValues} control={control} errors={errors} createProcess={createProcess} />
              <Addresses
                order={order.order}
                customer={order.customer}
                orderStatus={order.orderStatus}
                onAddressChange={() => {
                  getSalesOrder.execute({ order: +orderId });
                }}
              />
              <Miscellaneous getValues={getValues} control={control} errors={errors} />
            </>
          )}
        </Grid>
      </Container>
    </FormLayout>
  );
};

export default HeaderInfoForm;

import { Dialog, Button, Icon, Text, useCustomSnackbar, DataCard } from '@iptor/base';
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LostItemReason } from './components/LostSalesReason';
import { openAPIManager, OperationResponseItems } from '@iptor/business';

type OrderType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders'>[0];
interface Props {
  orders: OrderType[];
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  refreshTable: () => void;
}

function DeleteOrders(props: Props) {
  const [orders, setOrders] = useState(props.orders);
  const { showSnackbar } = useCustomSnackbar();
  const [lostSalesReason, setLostSalesReason] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { open, handleClose, refreshTable } = props;

  useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);

  useEffect(() => {
    orders.length === 0 && handleClose();
  }, [orders, handleClose]);

  const isUndeletable = (order: OrderType) => {
    return !!(order.orderStatus > 20 || order.workStationId);
  };

  const undeletableOrders = useMemo(() => {
    return orders.filter((order) => isUndeletable(order));
  }, [orders]);

  const SubText = useCallback(() => {
    if (undeletableOrders.length > 0) {
      return (
        <>
          {undeletableOrders.length} orders cannot be deleted because they are locked or have status greater than 20.
          <b> Please remove them from the list to proceed</b>
        </>
      );
    }
    if (orders.length === 1) {
      return (
        <>
          Order Number <b>{orders[0].order}</b> will be deleted. <b>Do you still wish to proceed?</b>
        </>
      );
    } else {
      return (
        <>
          {orders.length} orders will be deleted. <b>Do you still wish to proceed?</b>
        </>
      );
    }
  }, [orders, undeletableOrders]);

  const handleDelete = async (orders: OrderType[]) => {
    setLoading(true);
    const deletePromises = orders.map((order) =>
      openAPIManager.execute('internal.v1.base-orders', 'DELETE /salesOrders/{order}', {
        order: order.order,
        lostSaleReason: lostSalesReason,
      }),
    );

    const results = await Promise.allSettled(deletePromises);

    const errors = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[];

    if (errors.length > 0) {
      errors.forEach((error) => {
        showSnackbar({
          message:
            'An error occurred while deleting orders: ' +
            (error.reason as any)?.response?.data?.messages?.map((m: any) => m.text).join(' '),
          variant: 'error',
          autoHide: false,
        });
      });
    } else {
      showSnackbar({ message: 'Orders deleted successfully', variant: 'success' });
    }

    setLoading(false);
    handleClose();
    refreshTable();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
        <Icon icon="delete3" color="error.main" size="L" />
        <Text variant="h6" fontWeight={600}>
          Delete Order
        </Text>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Icon icon="cancel01" onClick={() => handleClose(false)} />
        </Box>
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text variant="subtitle1" paddingBottom={1}>
          <SubText />
        </Text>
        <Stack direction="row" width={'100%'} gap={0.5} overflow="auto" paddingY={1} marginY={1} paddingX={0.25}>
          {orders
            .sort((a, b) => {
              const aIsUndeletable = isUndeletable(a) ? 0 : 1;
              const bIsUndeletable = isUndeletable(b) ? 0 : 1;
              return aIsUndeletable - bIsUndeletable;
            })
            .map((order) => (
              <DataCard
                title="Customer"
                subTitle="Order number"
                firstVal={order.customerReference || 'No Data'}
                secondVal={order.customer}
                thirdVal={order.order}
                error={undeletableOrders.filter((curOrder) => curOrder.order === order.order).length > 0}
                onClose={() =>
                  setOrders((prev) => {
                    return prev.filter((value) => value !== order);
                  })
                }
              />
            ))}
        </Stack>
        <LostItemReason onChange={(value: string) => setLostSalesReason(value)} />
      </DialogContent>
      <DialogActions>
        <Button id="cancel" variant="outlined" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button
          disabled={!lostSalesReason || undeletableOrders.length > 0}
          variant="contained"
          onClick={() => handleDelete(orders)}
          id="delete"
          loading={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteOrders;

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" color="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 10.5V10C19 6.22876 19 4.34315 17.8284 3.17157C16.6569 2 14.7712 2 11 2C7.22876 2 5.34315 2 4.17157 3.17157C3 4.34315 3 6.22876 3 10V16C3 17.8638 3 18.7956 3.30448 19.5307C3.71046 20.5108 4.48915 21.2895 5.46927 21.6955C6.20435 22 7.13623 22 9 22"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.25 19H14.5C14.5 19.4142 14.8358 19.75 15.25 19.75V19ZM17.75 19V19.75C18.1642 19.75 18.5 19.4142 18.5 19H17.75ZM15.25 15C15.25 14.3096 15.8096 13.75 16.5 13.75V12.25C14.9812 12.25 13.75 13.4812 13.75 15H15.25ZM16.5 13.75C17.1904 13.75 17.75 14.3096 17.75 15H19.25C19.25 13.4812 18.0188 12.25 16.5 12.25V13.75ZM15.25 19.75H17.75V18.25H15.25V19.75ZM16 19C16 17.8951 15.7903 17.098 15.5917 16.4538C15.3849 15.7825 15.25 15.4228 15.25 15H13.75C13.75 15.7097 13.9901 16.35 14.1583 16.8956C14.3347 17.4683 14.5 18.1049 14.5 19H16ZM18.5 19C18.5 18.1049 18.6653 17.4683 18.8417 16.8956C19.0099 16.35 19.25 15.7097 19.25 15H17.75C17.75 15.4228 17.6151 15.7825 17.4083 16.4538C17.2097 17.098 17 17.8951 17 19H18.5Z"
      fill="currentColor"
    />
    <path
      d="M21 22H12V20.7543C12 20.3078 12.2664 19.9154 12.6528 19.7928L14.9076 19.077C15.0684 19.0259 15.2348 19 15.4021 19H17.5979C17.7652 19 17.9316 19.0259 18.0924 19.077L20.3472 19.7928C20.7336 19.9154 21 20.3078 21 20.7543V22Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path d="M7 7H15M7 11H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
  </svg>
);

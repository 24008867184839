import React, {
  ChangeEvent,
  FocusEvent,
  MouseEventHandler,
  MouseEvent,
  FunctionComponent,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import './../styles/Edit.scss';
import PromptArrow from './../assets/edit-prompt-arrow.svg';
import axios, { AxiosResponse } from 'axios';
import { Icons, SquareIcon } from './SquareIcon';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { XTString } from '../framework/parsers/models/XTData';
import { Formatters } from '../framework/parsers';
import { format } from 'date-fns';
import { LocaleContext } from '../App';
import { DateFormatter } from '../framework/parsers/DateFormatter';
import { Localization } from '../framework/localization/Localization';
import { createPortal } from 'react-dom';
import { LocalStorage } from '../framework/handlers/localStorage';
import { AperioViewCommand, AperioViews } from '../types/AperioViews';
import { WindowActions } from '../types/actionTypes';
import { debounce } from '@iptor/base';
import { AperioView } from '../framework/handlers/aperioView';
import { useComplexState } from '../hooks/complex-state';
import { useAdvancedEffect } from '../types/hooks';
import { useDispatch } from 'react-redux';
import { useNotes } from '../framework/your-note/NotesProvider';
import { XTValueToNote } from '../framework/your-note/XTValueParser';

type Option = { label: string; value: string };
/**
 * @EditProps
 * attributes: XML attributes for selected element
 */

type EditProps = {
  attributes: Record<string, any>;
  autoCapitalize?: boolean;
  className?: string;
  defaultValue?: string | Option;
  disabled?: boolean;
  errorMessage?: string;
  hasFocusConstraint?: boolean;
  id: string;
  isInvalid?: boolean;
  isValid?: boolean;
  name: string;
  placeholder?: string;
  readOnly?: boolean;
  textDirection?: 'left' | 'right';
  uppercase?: boolean;
  variant?: 'edit' | 'prompt' | 'remote-prompt' | 'combo' | 'combo-editable';
  visible?: boolean;
  panelID?: string;
  autoComplete: boolean;
  autoCompleteEndpoint?: string;
  autoCompleteKey?: string;
  autoCompleteParams?: any | any[];
  disableAutoComplete?: boolean;
  rowID?: string;
  rowFlags?: string;
  rowField?: string;
  minLettersToRun?: number;
  apiLimit?: number;
  onClose?: Function;
  onRowChange?: Function;
  preferredCaretPosition?: number;
  changePreferredCaretPosition?: (position: number) => void;
  options?: Option[];
};

type AutocompleteState = {
  data: Record<string, any>[];
  showList: boolean;
  filter: string;
  getMore: boolean;
  textSendToApi: string;
};

type AutoCompleteListProps = {
  filtered: Record<string, any>;
  highlight: number;
  autocompleteState: AutocompleteState;
  boundingClientRect: DOMRect;
  rowID?: string;
  onMouseMove?: MouseEventHandler<HTMLUListElement>;
  onClick?: any;
  onPagesizeChanged?: Function;
  isCombo?: boolean;
};

const AutoCompleteList: FunctionComponent<AutoCompleteListProps> = ({
  filtered,
  highlight,
  autocompleteState,
  boundingClientRect,
  rowID,
  onMouseMove = () => {},
  onClick = () => {},
  onPagesizeChanged = () => {},
  isCombo = false,
}) => {
  const listRef = useRef<any>();

  useEffect(() => {
    // Communicate pagesize (max rows with height completely visible)
    // ==============================================================
    const heightRow = listRef?.current?.querySelector?.('.auto-complete-item')?.offsetHeight || 24;
    onPagesizeChanged(Math.floor(listRef?.current?.clientHeight / heightRow));
  }, [listRef?.current?.clientHeight]);

  useEffect(() => {
    // Scroll highlighted row into view
    // ================================
    if (highlight !== -1) {
      const heightRow = listRef?.current?.querySelector?.(`[data-index="${highlight}"]`)?.offsetHeight;
      if (heightRow) {
        const topHighlightedRow = highlight * heightRow;
        const topFirstVisibleRow = Math.ceil(listRef.current.scrollTop / heightRow) * heightRow;
        const lastVisibleRowIndex =
          Math.floor((listRef.current.clientHeight + listRef.current.scrollTop) / heightRow) - 1;
        const topLastVisibleRow = lastVisibleRowIndex * heightRow;

        const adjustScrolling = () => {
          if (topHighlightedRow < listRef.current.scrollTop) {
            // Moving up ==> show highlighted row at top of visible part of list
            listRef.current.scrollTop = topHighlightedRow;
          } else {
            // Moving down ==> show highlighted row at bottom of visible part of list
            listRef.current.scrollTop = topHighlightedRow - listRef.current.clientHeight + heightRow;
          }
        };

        if (topHighlightedRow > topLastVisibleRow) {
          adjustScrolling();
        } else if (topHighlightedRow < topFirstVisibleRow) {
          adjustScrolling();
        }
      }
    }
  }, [highlight, autocompleteState.showList]);

  let { x, y, bottom, top, height, width } = boundingClientRect || {};
  return (
    <ul
      ref={listRef}
      id="autocomplete-input-list"
      onMouseMove={onMouseMove}
      onMouseDown={(e: MouseEvent) => {
        e.stopPropagation();
      }} // added to prevent table context menu to be shown when scrolling longer than 1 second
      hidden={!(autocompleteState.showList && filtered.length > 0)}
      className={'list-unstyled custom-dropdown autocomplete-dropdown ' + (top < 350 ? 'open-bottom' : 'open-top')}
      style={{
        [top < 350 ? 'top' : 'bottom']: top < 350 ? bottom : window.innerHeight - bottom + (rowID ? 20 : 10),
        left: x,
        width: isCombo && width ? `${width}px` : 'auto',
      }}
    >
      {filtered.map((record: any, i: number) => (
        <li
          data-index={i}
          key={autocompleteState.filter + i.toString()}
          className={
            'auto-complete-item dropdown-list-item ignore-height truncate' +
            (highlight === i || !!record.selected ? ' active' : '') +
            (record.label === '' ? ' empty-value' : '')
          }
          onClick={() => onClick(record)}
          tabIndex={-1} // Remark: make sure list element can get input focus (needed for onBlur functionality)
        >
          {
            //TODO: filter only fields which are visible in the table, not all available in api - possible?
            isCombo
              ? record.label
              : JSON.stringify(Object.values(record))
                  .substring(1, JSON.stringify(Object.values(record)).length - 1)
                  .replaceAll(',', ' - ')
                  .replaceAll('"', '')
          }
        </li>
      ))}
    </ul>
  );
};

export const _Edit: FunctionComponent<EditProps> = ({
  attributes,
  autoCapitalize = true,
  className = '',
  defaultValue = '',
  disabled = false,
  errorMessage,
  hasFocusConstraint = false,
  id,
  isInvalid = false,
  isValid = false,
  name,
  placeholder,
  readOnly = false,
  textDirection = 'left',
  variant = 'edit',
  visible = true,
  panelID = '',
  rowID,
  rowFlags,
  rowField,
  autoComplete = false,
  autoCompleteEndpoint,
  autoCompleteKey,
  autoCompleteParams,
  minLettersToRun = 2,
  disableAutoComplete,
  apiLimit = 50,
  preferredCaretPosition = -1,
  changePreferredCaretPosition = () => {},
  onClose,
  onRowChange,
  options = [],
}) => {
  const { setFocussedElement, setDisplayValue, setFieldValue } = useNotes();

  const isCombo = ['combo', 'combo-editable'].indexOf(variant) > -1;
  const isComboEditable = variant === 'combo-editable';

  const editRef = useRef<any>(null);
  // @ts-ignore
  const [value, setValue] = useState((defaultValue?.label ? defaultValue?.label : defaultValue) ?? '');
  const valueInServerFormatRef = useRef<string>('');
  const [origValue, setOrig] = useState('');
  const [highlight, setHighlight] = useState(-1);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [parsingErrorMessage, setParsingErrorMessage] = useState<string | null>(null); //NTH: show parsing error as tooltip?
  const [initialValueSelection, setInitialValueSelection] = useComplexState({ requested: false });
  const isMountingRef = useRef<boolean>(true);
  const autocompletePagesizeRef = useRef<number>(8);
  const dispatch = useDispatch();
  const caretInfoRef = useRef<{
    preferredPosition: number;
    registerChanges: boolean;
    selectionStart: number;
    selectionEnd: number;
    selectionDirection: string;
  }>(
    visible && !disabled && !readOnly && attributes.autoselect === 'false' // Remark: only supposed to be used when attributes.autoselect === 'false' (meaning no select of entire value when element gets focus)
      ? {
          preferredPosition: preferredCaretPosition,
          registerChanges: false,
          selectionStart: preferredCaretPosition,
          selectionEnd: preferredCaretPosition,
          selectionDirection: 'forward',
        }
      : {
          preferredPosition: -1,
          registerChanges: false,
          selectionStart: -1,
          selectionEnd: -1,
          selectionDirection: 'forward',
        },
  );

  useEffect(() => {
    /*
      Some explanation for "preferred" caret position that tries to simulate the behavior of the caret position when navigating up/down in most
      text editors (notepad++, word, ...):

      Suppose we have 3 lines of text with a different length: first line has 50 characters, second 10 characters and last 80 characters.
      When you position the caret at the end of the first line and press <arrow down>, the caret will be moved to the end of the second line.
      When you press <arrow down> again the caret will be moved to the third line just after character 50.

      How it works:
      1. User moves caret to the end of the first line: this is an explicit user action and as a result the preferred caret position will be changed to 50.
      2. When pressing <arrow down> the program determines the new caret position based on the current preferred caret position. In this case the preferred
         caret position is 50, but the second line contains only 10 characters, so the caret will be moved to the end of the second line. Although pressing
         arrow up/down impacts the actual caret position, it has no impact on the preferred caret position.
      3. After pressing <arrow down> again, the actual caret will be moved to the third line just behind character 50.

      Summarized: on arriving on a new line, the actual caret position is calculated from the current preferred caret position. The current preferred caret
      position can only changed by user interaction on the same line (eg pressing <arrow left>, pressing any other key changing the value, click, ...)

      MouseUp, KeyUp events are used because we are interested in the resulting caret position (on the down events we still have the "old" position)
      However, as we only want to register caret position changes invoked by explicit user interaction, we have to skip the first "up" event (eg
      maintainable table: when we press <arrow down>, a new edit control is instantiated and when the user keeps the button too long down this should not
      be considered as a new user action)
    */

    function startRegisterCaretPosition() {
      if (!caretInfoRef.current.registerChanges) {
        caretInfoRef.current.registerChanges = true;
        caretInfoRef.current.selectionStart = editRef?.current?.selectionStart ?? -1;
        caretInfoRef.current.selectionEnd = editRef?.current?.selectionEnd ?? -1;
        caretInfoRef.current.selectionDirection = editRef?.current?.selectionDirection ?? 'forward';
      }
    }

    function registerCaretPosition(forceRegister = false) {
      if (caretInfoRef.current.registerChanges) {
        // Ignore if selection not changed, unless force register requested                  --> eg keyup <shift> (unless eg <arrow left> on first position)
        // ----------------------------------------------------------------
        if (
          !forceRegister &&
          caretInfoRef.current.selectionStart === (editRef?.current?.selectionStart ?? -1) &&
          caretInfoRef.current.selectionEnd === (editRef?.current?.selectionEnd ?? -1) &&
          caretInfoRef.current.selectionDirection === (editRef?.current?.selectionDirection ?? 'forward')
        ) {
          return;
        }

        // Determine new preferred position
        // --------------------------------
        if (editRef?.current?.selectionDirection === 'forward') {
          caretInfoRef.current.preferredPosition = editRef?.current?.selectionEnd ?? -1;
        } else if (editRef?.current?.selectionDirection === 'backward') {
          caretInfoRef.current.preferredPosition = editRef?.current?.selectionStart ?? -1;
        } else if (caretInfoRef.current.selectionEnd !== (editRef?.current?.selectionEnd ?? -1)) {
          // included as not sure if all browsers support selectionDirection & only case I know where start and end of selection changed is <CTRL+A> and in this case Chrome puts caret at the end
          caretInfoRef.current.preferredPosition = editRef?.current?.selectionEnd ?? -1;
        } else {
          caretInfoRef.current.preferredPosition = editRef?.current?.selectionStart ?? -1;
        }

        // Save current selection
        // ----------------------
        caretInfoRef.current.selectionStart = editRef?.current?.selectionStart ?? -1;
        caretInfoRef.current.selectionEnd = editRef?.current?.selectionEnd ?? -1;
        caretInfoRef.current.selectionDirection = editRef?.current?.selectionDirection ?? 'forward';
      }
    }

    function startRegisterCaretPosition_keydown(e: ReactKeyboardEvent) {
      if (caretInfoRef.current.registerChanges) {
        // Handle <ESC> in editable table    --> default behavior table is uncommit changes ==> restore original preffered position
        if (e.key.toLowerCase() === 'escape' && rowID) {
          caretInfoRef.current.preferredPosition = preferredCaretPosition;
        }
      } else {
        // Start registration
        startRegisterCaretPosition();
      }
    }

    function registerCaretPosition_keyup(e: ReactKeyboardEvent) {
      // Ignore tab
      // -----------
      if (e.key.toLowerCase() === 'tab') {
        return;

        // Ignore navigation up/down in table
        // ----------------------------------
      } else if (['arrowdown', 'arrowup', 'pageup', 'pagedown'].indexOf(e.key.toLowerCase()) > -1 && rowID) {
        return;
      }

      // Registration
      // ------------
      let forceRegister = false;
      if (['arrowleft', 'arrowright', 'home', 'end'].indexOf(e.key.toLowerCase()) > -1) {
        forceRegister = true; // change preferred caret position, even when current caret position not changed (eg press <arrow left> at first position)
      }
      registerCaretPosition(forceRegister);
    }

    // Inactivate functionality if appropiate
    // ======================================
    if (!(visible && !disabled && !readOnly && attributes.autoselect === 'false')) {
      preferredCaretPosition = -1;
    }

    // Initialize functionality
    // ========================
    if (preferredCaretPosition !== -1) {
      // Copy preferred psition
      caretInfoRef.current.preferredPosition = preferredCaretPosition;

      // Add event listeners
      editRef?.current?.addEventListener('mouseup', registerCaretPosition);
      editRef?.current?.addEventListener('keyup', registerCaretPosition_keyup);
      editRef?.current?.addEventListener('mousedown', startRegisterCaretPosition);
      editRef?.current?.addEventListener('keydown', startRegisterCaretPosition_keydown);

      // Handle case element has currently input focus
      if (document.activeElement === editRef?.current) {
        if (editRef?.current && editRef?.current?.selectionStart !== caretInfoRef.current.preferredPosition) {
          editRef.current.setSelectionRange(
            caretInfoRef.current.preferredPosition,
            caretInfoRef.current.preferredPosition,
          );
          caretInfoRef.current.selectionStart = editRef?.current?.selectionStart ?? -1;
          caretInfoRef.current.selectionEnd = editRef?.current?.selectionEnd ?? -1;
          caretInfoRef.current.selectionDirection = editRef?.current?.selectionDirection ?? 'forward';
        }
      }

      // Clean-up: remove event listeners
      return () => {
        editRef?.current?.removeEventListener('mouseup', registerCaretPosition);
        editRef?.current?.removeEventListener('keyup', registerCaretPosition_keyup);
        editRef?.current?.removeEventListener('mousedown', startRegisterCaretPosition);
        editRef?.current?.removeEventListener('keydown', startRegisterCaretPosition_keydown);
      };
    }
  }, [preferredCaretPosition, visible, disabled, readOnly]);

  useEffect(() => {
    // Remark: seems like blur event is not (always) executed in editable grid when cell is changed ==> register caret change also at unmounting time
    return () => {
      if (
        preferredCaretPosition > -1 &&
        caretInfoRef.current.preferredPosition > -1 &&
        preferredCaretPosition !== caretInfoRef.current.preferredPosition
      ) {
        changePreferredCaretPosition(caretInfoRef.current.preferredPosition);
      }
    };
  }, []);

  useEffect(() => {
    /** Setting the X & Y for tab order calculation  */
    if (editRef.current) {
      const scrollInfo = XT.getScrollInfo(editRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  const {
    addToRowCommand,
    removeFromRowCommand,
    addToCommand,
    removeFromCommand,
    setScope,
    errors,
    windowData,
    updateFlag,
    promptHandler,
    settings,
    cursor,
    getCommand,
    localization,
    formAperioLinks,
    getFormLayout,
  } = useContext(CommandContext); /**Handling the change event, like interact with the input fields */
  const { localeSettings } = useContext(LocaleContext);
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);
  // const [attributeId, setPromptType] = useState<string>('');

  const { decimalsign } = settings?.regionals?.codes[0].$ || {};
  let { century_break_year, dateSeparator, format6, format8, format8Sep } = settings?.regionals?.dateformats[0].$ || {};

  useEffect(() => {
    /**Updates the drityflag if editRef is found */
    if (editRef && editRef.current) {
      let _dirtyflag = +(editRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
    }
  }, []);

  useEffect(() => {
    if (rowID) {
      editRef?.current?.focus();
    }
  }, [editRef]);

  useEffect(() => {
    let _value = (rowID ? defaultValue : XT.getValueFromWindow({ data: windowData }, panelID, name)) || '';

    _value = _value
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&amp;/gi, '&')
      .replace(/&apos;/gi, "'")
      .replace(/&quot;/gi, '"');

    setOrig(_value.trim()); //Comparison purpose
    valueInServerFormatRef.current = _value;
    if (parsingErrorMessage) setParsingErrorMessage(null);

    _value = convertValueFromServerToClientFormat(_value);
    // @ts-ignore
    setValue(defaultValue?.label ?? _value.trimEnd());

    if (editRef && editRef.current) {
      let _dirtyflag = +(editRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      updateFlag(_dirtyflag, false);
      if (attributes.hiddendata === 'true') {
        updateFlag(_dirtyflag, true);
        addToCommand(panelID, id, XT.getValueFromWindow({ data: windowData }, panelID, id) || '', _dirtyflag);
      }
    }
  }, []);

  useEffect(() => {
    /**
     * Getting the value from framework
     */
    let _value = (rowID ? defaultValue : XT.getValueFromWindow({ data: windowData }, panelID, id)) || '';

    /**
     * reloaded-> Reload when value updated
     */
    let reloaded = rowID
      ? XT.getReloadedFromWindowRow({ data: windowData }, panelID, rowID, name)
      : XT.getReloadedFromWindow({ data: windowData }, panelID, id);

    _value = _value
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&amp;/gi, '&')
      .replace(/&apos;/gi, "'")
      .replace(/&quot;/gi, '"');

    if (reloaded === true || reloaded === undefined) {
      setOrig(_value.trimEnd());
      valueInServerFormatRef.current = _value;
      if (parsingErrorMessage) setParsingErrorMessage(null);
    }

    _value = convertValueFromServerToClientFormat(_value);

    if (reloaded === true || reloaded === undefined) {
      // @ts-ignore
      if (onRowChange) _value = defaultValue?.label ?? _value;
      // @ts-ignore
      else _value = defaultValue?.label ?? _value.trimEnd();
      setValue(_value);
    }
    if (editRef && editRef.current) {
      let _dirtyflag = +(editRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      updateFlag(_dirtyflag, false);
      if (attributes.hiddendata === 'true') {
        updateFlag(_dirtyflag, true);
        addToCommand(panelID, id, XT.getValueFromWindow({ data: windowData }, panelID, id) || '', _dirtyflag);
      }
    }

    // Handle case edit control has currently input focus
    if (editRef && editRef.current && document.activeElement === editRef.current) {
      if (!(disabled || readOnly)) {
        if (attributes.mask === 'decimal' || attributes.formatter?.toString() === 'decimal') {
          setValue(_value.replaceAll(localization.groupSeparator, ''));
        }
      }
      setInitialValueSelection({ requested: true });
    }
  }, [windowData]);

  useAdvancedEffect(
    () => {
      // Only if edit control has still input focus
      if (initialValueSelection.requested === true && editRef?.current && editRef.current === document.activeElement) {
        if (attributes.autoselect !== 'false') {
          editRef.current.select();
        } else if (preferredCaretPosition > -1) {
          editRef.current.selectionStart = caretInfoRef.current.preferredPosition;
          editRef.current.selectionEnd = caretInfoRef.current.preferredPosition;
        }
      }
    },
    [initialValueSelection],
    [attributes.autoselect, preferredCaretPosition],
  );

  useEffect(() => {
    if (!isMountingRef.current) {
      let _value = convertValueFromServerToClientFormat(valueInServerFormatRef.current);
      if (parsingErrorMessage) setParsingErrorMessage(null);
      setValue(_value.trimEnd());
    }
  }, [localeSettings]);

  const isCommaAlwaysServerDecimalSeparator = (): boolean => {
    return XT.isCommaAlwaysServerDecimalSeparator(attributes?.formatter, attributes?.mask);
  };

  const getServerDecimalSeparator = (): string => {
    return isCommaAlwaysServerDecimalSeparator() ? ',' : decimalsign;
  };

  const getResultingMask = (attributes: Record<string, any>): string => {
    let mask = attributes.mask;
    if (!attributes.mask && attributes.formatter === 'FormattedNumeric') mask = 'numeric';
    if (
      attributes.formatter === 'PositiveIntegerWithZeros' ||
      (attributes.formatter === 'FormattedNumericAllowZeros' && mask === 'positiveinteger')
    )
      mask = 'positiveinteger_with_zeros';
    if (attributes.mask === 'delimitedquantity') {
      mask = 'decimal';
    } else if (attributes.formatter?.toLowerCase() === 'time') mask = 'time';
    if (attributes.formatter === 'PadZerosOrShowEmpty') mask = 'positiveinteger';
    if (attributes.formatter === 'FormattedNumericAllowZeros' && mask === 'decimal') mask = 'decimal_with_zeros';
    return mask || '';
  };

  const convertValueFromServerToClientFormat = (value: string): string => {
    let _value = value;
    let mask = getResultingMask(attributes);
    // 100,00 Remote(',', '.') Local(',|.', ',|.| ') => '100,00' => 100 => '100{ds}{0*nDec}
    let xtValue = new XTString(_value);
    let xtNum = xtValue.serverFormatToNumber(getServerDecimalSeparator());
    switch (mask) {
      case 'positiveinteger':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(
              localization.decimalSeparator,
              localization.groupSeparator,
              false,
              false,
              false,
              attributes.formatter?.toLowerCase() === 'decimal' && !onRowChange,
            )
            .toString();
        break;
      case 'positiveinteger_with_zeros':
      case 'PositiveIntegerWithZeros':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(
              localization.decimalSeparator,
              localization.groupSeparator,
              false,
              true,
              false,
              attributes.formatter?.toLowerCase() === 'decimal' && !onRowChange,
            )
            .toString();
        break;
      case 'decimal':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(
              localization.decimalSeparator,
              localization.groupSeparator,
              true,
              false,
              true,
              !onRowChange,
            )
            .toString();
        break;
      case 'positivedecimal':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(
              localization.decimalSeparator,
              localization.groupSeparator,
              true,
              false,
              false,
              attributes.formatter?.toLowerCase() === 'decimal' && !onRowChange,
            )
            .toString();
        break;
      case 'integer':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(
              localization.decimalSeparator,
              localization.groupSeparator,
              false,
              false,
              true,
              attributes.formatter?.toLowerCase() === 'decimal' && !onRowChange,
            )
            .toString();
        break;
      case 'numeric':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(localization.decimalSeparator, localization.groupSeparator, true, false, true)
            .toString();
        break;
      case 'decimal_with_zeros':
        if (!xtNum.isNaN)
          _value = xtNum
            .toXTStringClientFormat(localization.decimalSeparator, localization.groupSeparator, true, true, true)
            .toString();
        break;
      case 'date':
        _value = Formatters.Date.convertValueFromServerFormatToClientFormat(
          _value,
          settings?.regionals?.dateformats[0].$,
          localization.dateFormat,
        );
        break;
      case 'time':
        let time = Formatters.Time.in(_value?.trim(), +(attributes?.limit ?? '0'));
        _value = format(time || new Date(), attributes?.decimals === '0' ? 'HH:mm' : 'HH:mm:ss');
        break;
      default:
        break;
    }

    if (attributes.formatter === 'RemoveTextLimitMarker') {
      _value = _value.replace(']', '');
    }
    return _value || attributes?.text || '';
  };

  const getParsingErrorMessage = (errors: DateFormatter.ParseError[] | undefined): string | null => {
    if (!errors) return null;
    if (errors.length < 1) return null;

    return errors.reduce((message, error) => {
      let errorMessage = localization.getString(error.errorMessage.toString());
      if (error.params) {
        for (let i = 0; i < error.params.length; i++) {
          errorMessage = errorMessage.replace(`{${i.toString()}}`, error.params[i]);
        }
      }
      if (message) message = message + '\n';
      return message + errorMessage;
    }, '');
  };

  const getAutoCompleteData = async (endpoint?: string, searchValue?: string, params?: Record<string, string>) => {
    if (!disableAutoComplete) {
      /**
       * Populating the data when user start typing on the input field
       */
      //calculate id - method taken from XT
      const constValueToFormatMiliseconds = 36; //TODO: get it from config
      const date = new Date();
      const millisec = date.getTime();
      const id = millisec.toString(constValueToFormatMiliseconds);
      const payload = {
        IptorAPI: '1.0',
        method: endpoint,
        params: params,
        control: {
          freeTextSearch: searchValue,
          limit: apiLimit,
        },
        id: id,
      };
      let response = {} as { data: { items: Record<string, any>[] }; control: { total: number } };
      let rs: AxiosResponse | null = null;
      try {
        rs = await axios.post('/aperio/api/service', payload);
        response = rs?.data || [];
      } catch (error) {
        console.error(`Error from getAutoCompleteData ${error}`);
      }

      return response;
    }
  };

  const controlType = 'text';
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState>({
    data: [],
    showList: false,
    filter: '',
    getMore: false,
    textSendToApi: '',
  });

  const abortAutoCompleteMode = () => {
    setAutocompleteState({ ...autocompleteState, showList: false, data: [], textSendToApi: '' });
  };

  const refHandleAperioViewOnBlur = useRef<((windowData: any) => {}) | undefined>(undefined);
  const refIsInAutocompleteMode = useRef<() => boolean>(() => autocompleteState.showList);
  //const isInAutocompleteMode = useMemo(() => autocompleteState.showList, [autocompleteState.showList]);
  useEffect(() => {
    // Initialize
    // ==========
    const dispatchUpdateContext = (aperioViewCommand: AperioViewCommand | undefined) => {
      dispatch({ type: WindowActions.UPDATE_CONTEXT, payload: { aperioViewCommand } });
    };
    const setAperioViewCommand = (link: AperioViews.Link, windowData: any) => {
      const aperioViewCommand = AperioView.getCommandBase(
        link,
        getCommand(),
        windowData,
        getFormLayout(),
        settings?.regionals,
      );
      dispatchUpdateContext(aperioViewCommand);
    };
    let onFocusInfo: { timestamp: Date; serverValue: string; ignoreTime: boolean } | undefined;

    // Get event info
    // ==============
    const eventInfo =
      rowID || !editRef?.current // -->  not used in table as not yet supported
        ? undefined
        : AperioView.getFieldComponentEventInfo(id, panelID, formAperioLinks || []);

    // Only needed when an event is configured
    // ========================================
    if (eventInfo?.link) {
      // Grab instance
      const _edit = editRef.current;
      // Create/add focus event listener
      const onFocus = () => {
        onFocusInfo = {
          timestamp: new Date(),
          serverValue: valueInServerFormatRef.current,
          ignoreTime: refIsInAutocompleteMode.current(),
        };
      };
      _edit.addEventListener('focus', onFocus);

      // Initialize on focus info (if appropriate)
      if (document.activeElement === _edit) onFocus();

      // Create debounced function to update the context
      const ms = eventInfo.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE ? 10 : 300;
      const [submitCommand, cancel] = debounce(setAperioViewCommand, ms);

      // Set function to call debounced function only when needed
      refHandleAperioViewOnBlur.current = (windowData: any) => {
        // --> Handle only on human interactions (filter out software interactions unless autocomplete)
        if (!onFocusInfo) {
          return Promise.resolve();
        } else if (!onFocusInfo.ignoreTime && new Date().getTime() - onFocusInfo.timestamp.getTime() < 100) {
          return Promise.resolve();
        }

        // --> For event type "updated", handle only if value changed
        if (
          eventInfo.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE &&
          onFocusInfo.serverValue === valueInServerFormatRef.current
        ) {
          return Promise.resolve();
        }

        // --> Submit debounced function
        return submitCommand(eventInfo.link, windowData);
      };

      // Clean-up effect
      return () => {
        _edit.removeEventListener('focus', onFocus);
        cancel();
        refHandleAperioViewOnBlur.current = undefined;
        dispatchUpdateContext(undefined);
      };
    }
  }, [id, panelID, rowID, formAperioLinks, dispatch, settings?.regionals, getCommand, getFormLayout]);

  const onFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    /**
     * On Focus  adds group seperator, when decimal mask is used
     */
    if (attributes.mask === 'decimal' || attributes.formatter?.toString() === 'decimal') {
      setValue(e.target.value.replaceAll(localization.groupSeparator, ''));
    }
    setScope(attributes?.ddspos || '1,1', attributes?.rowOffset || 0);
  };

  const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
    /**
     * On Blur  remove group seperator if present.
     */
    const localDecimal = localization.decimalSeparator;
    const localGroupSep = localization.groupSeparator;
    if (attributes.mask === 'decimal' || attributes.formatter?.toString() === 'decimal') {
      const sep = localGroupSep;
      const v = e.target.value.replaceAll(sep, ''); //Force remove separator else blur event after formatting will result in 2 separators
      let o = v.lastIndexOf(localDecimal) !== -1 ? v.substring(v.lastIndexOf(localDecimal)) : '';
      let ind = v.length - o.length;
      while (ind > 0) {
        o = sep + v.substring(ind - 3, ind) + o;
        ind -= 3;
      }
      if (o.charAt(0) === sep) o = o.substring(1);
      if (o.startsWith('-' + sep)) o = '-' + o.substring(2);
      e.target.value = o;
      setValue(o);
    }

    // Move resulting "formatted" client value to React state (eg 120121 => 12/01/2021)
    if (attributes.mask === 'date') {
      let result = Formatters.Date.parseValueFromClientFormatToServerFormat(
        value,
        localization.dateFormat,
        settings?.regionals?.dateformats[0].$,
      );
      if (!result.errors) setValue(result.clientValue);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    /**
     * Applying mask base on every change event, as per mask/formatter used in XML attribute
     */
    const handleNumberChange = (
      value: string,
      origValue: string,
      cursorPosition: number,
      allowNegative: boolean,
      totalNumberOfDigits: number,
      numberOfDecimals: number,
    ): { value: string; cursorPosition: number } => {
      // Initialize
      // ==========
      let result = '';
      let isLeadingZeroStripped = false;
      let ignoreChange = false;
      const origCursorPosition = cursorPosition;
      let _numberOfDecimals = numberOfDecimals;
      let _totalNumberOfDigits = totalNumberOfDigits;

      // Handle number of decimals not defined
      // =====================================
      if (_numberOfDecimals === -1) {
        _numberOfDecimals = totalNumberOfDigits;
        _totalNumberOfDigits = totalNumberOfDigits * 2;
      }
      let allowDecimalSeparator = _numberOfDecimals > 0 ? true : false;

      // Override nonsense
      // =================
      if (_numberOfDecimals < 0) _numberOfDecimals = 0;
      if (_totalNumberOfDigits <= 0) _totalNumberOfDigits = 999; // 999??
      if (_numberOfDecimals > _totalNumberOfDigits) _numberOfDecimals = _totalNumberOfDigits;

      // Remove heading spaces
      // =====================
      if (value !== value.trimStart()) {
        cursorPosition = Math.max(0, cursorPosition - (value.length - value.trimStart().length));
        value = value.trimStart();
      }

      // Remove trailing spaces
      // ======================
      value = value.trimEnd();
      cursorPosition = Math.min(cursorPosition, value.length);

      // Special case: ignore adding any characters if front of negative number               --> before this had a nasty side effect: as minus sign is then no longer in first position, the minus is dropped: eg when you have -1 and you type a 9 before it, the result was 91 (=confusing for user)
      // ======================================================================
      if (allowNegative && origValue.startsWith('-') && value.endsWith(origValue)) {
        ignoreChange = true;
      }

      // Keep only allowed characters
      // ============================
      for (let i = 0; i < value.length; i++) {
        // Initialize
        // ----------
        if (ignoreChange) break;
        let keepCharacter = true;

        // Keep minus only if allowed and on first position
        // ------------------------------------------------
        if (value[i] === '-') {
          if (i !== 0 || !allowNegative) keepCharacter = false; // just remove invalid character to allow to copy/paste a formatted number, eg 111-459-22222

          // Keep decimal separator only if allowed and first occurence
          // ----------------------------------------------------------
        } else if (value[i] === localization.decimalSeparator) {
          if (!allowDecimalSeparator) {
            keepCharacter = false; // just remove invalid character to allow to copy/paste a formatted number, eg 111.459.22222
          } else {
            allowDecimalSeparator = false; //==> only allow first occurance
          }

          // Keep only digits
          // ----------------
        } else if (/[^0-9]$/.test(value[i])) {
          keepCharacter = false; // just remove invalid character to allow to copy/paste a formatted number, eg BE-9000

          // Handle digits
          // -------------
        } else {
          // Case no decimals (always keep digit if maximum length not yet reached)
          if (_numberOfDecimals === 0) {
            // if maximum length reached...
            if (result.length >= _totalNumberOfDigits + (result.startsWith('-') ? 1 : 0)) {
              // ... if leading zero, strip
              if (result.startsWith('0')) {
                result = result.substring(1);
                if (cursorPosition > 0) cursorPosition -= 1;
                isLeadingZeroStripped = true;

                // ... if leading zero (bis), strip
              } else if (result.startsWith('-0')) {
                result = '-' + result.substring(2);
                if (cursorPosition > 1) cursorPosition -= 1;
                isLeadingZeroStripped = true;

                // ... invalid change
              } else {
                ignoreChange = true; // remark: just dropping the current digit might be confusing (as current digit is not necessarily the one that was inserted, through copy/paste it is even possible to add multiple digits)
                break;
              }
            }

            // Case decimals allowed
          } else {
            let indexDecimalSeparator = result.indexOf(localization.decimalSeparator);

            // decimal separator not yet included ...
            if (indexDecimalSeparator < 0) {
              // ... if decimal separator position reached
              if (result.length === _totalNumberOfDigits - _numberOfDecimals + (result.startsWith('-') ? 1 : 0)) {
                // ... if leading zero, strip
                if (result.startsWith('0')) {
                  result = result.substring(1);
                  if (cursorPosition > 0) cursorPosition -= 1;
                  isLeadingZeroStripped = true;

                  // ... if leading zero (bis), strip
                } else if (result.startsWith('-0')) {
                  result = '-' + result.substring(2);
                  if (cursorPosition > 1) cursorPosition -= 1;
                  isLeadingZeroStripped = true;

                  // ... auto insert decimal separator                                        // only when adding a single digit at the end (otherwise too confusing for end user)
                } else if (i === value.length - 1 && result === origValue && numberOfDecimals !== -1) {
                  if (cursorPosition >= result.length) cursorPosition += 1;
                  result += localization.decimalSeparator;
                  allowDecimalSeparator = false; //==> only allow first occurance

                  // ... invalid change (number of leading digits not respected)
                } else {
                  ignoreChange = true; // remark: just dropping the current digit might be confusing (as current digit is not necessarily the one that was inserted, through copy/paste it is even possible to add multiple digits)
                  break;
                }
              }

              // decimal separator already included ...
            } else {
              // keep digit only if maximum number of decimals not yet reached
              if (numberOfDecimals === -1) {
                if (result.length > totalNumberOfDigits + 1 + (result.startsWith('-') ? 1 : 0)) {
                  ignoreChange = true; // remark: just dropping the current digit might be confusing (as current digit is not necessarily the one that was inserted, through copy/paste it is even possible to add multiple digits)
                  break;
                }
              } else {
                if (result.length - indexDecimalSeparator > _numberOfDecimals) {
                  ignoreChange = true; // remark: just dropping the current digit might be confusing (as current digit is not necessarily the one that was inserted, through copy/paste it is even possible to add multiple digits)
                  break;
                }
              }
            }
          }
        }

        // Set result
        // ----------
        if (keepCharacter) {
          result += value[i];
        } else {
          if (cursorPosition > result.length) cursorPosition -= 1;
        }
      }

      // Handle ignore change
      // ====================
      if (ignoreChange) {
        if (value.length > origValue.length) {
          cursorPosition = Math.max(origCursorPosition - (value.length - origValue.length), 0); // eg if you are in front of the value and you paste 3 new characters, the cursor position will be three (and the we need to substract 3 again to return to the beginning position)
        } else {
          cursorPosition = origCursorPosition; // eg try to delete leading decimal separator         NTH: backspace decimal separator: ??? if ((e.nativeEvent as Record<string, any>).inputType === 'deleteContentBackward')
        }
        result = origValue;

        // Insert leading zero (only when no leading digits left)                                     // eg .500 ==> 0.500
        // ======================================================
      } else if (isLeadingZeroStripped) {
        if (result.startsWith(localization.decimalSeparator)) {
          result = '0' + result;
          if (cursorPosition > 0) cursorPosition -= 1;
        } else if (result.startsWith('-' + localization.decimalSeparator)) {
          result = '-0' + result.substring(1);
        }
      }

      // Return result
      // =============
      return { value: result, cursorPosition };
    };
    const handleDateChange = (value: string): { value: string; cursorPosition: number } => {
      // Determine current client date separator
      // =======================================
      const dateSeparator = localization.dateFormat.replace(/[Mdy]/g, '').charAt(0) || '';

      // Keep only allowed characters
      // ============================
      let result = '';
      for (let i = 0; i < value.length; i++) {
        // Initialize
        // ----------
        let keepCharacter = true;

        // Keep only digits and current date separator
        // -------------------------------------------
        if (new RegExp(`[^0-9${dateSeparator}]`, 'g').test(value[i])) {
          keepCharacter = false; // just remove invalid character to allow to copy/paste a formatted date
        }

        // Set result
        // ----------
        if (keepCharacter) {
          result += value[i];
        } else {
          if (cursorPosition > result.length) cursorPosition -= 1;
        }
      }

      // Return result
      // =============
      return { value: result, cursorPosition };
    };

    let limit = +(e.target.closest('[data-limit]')?.getAttribute('data-limit') || 0);
    let cursorPosition = Math.min(
      e.target.selectionStart ?? e.target.value.length,
      e.target.selectionEnd ?? e.target.value.length,
    );
    let mask = getResultingMask(attributes);
    let numericValidation: {
      allowNegative: boolean;
      totalNumberOfDigits: number;
      numberOfDecimals: number;
    } | null = null;

    switch (mask) {
      case 'positiveinteger':
      case 'positiveinteger_with_zeros':
      case 'PositiveIntegerWithZeros':
        numericValidation = { allowNegative: false, totalNumberOfDigits: limit, numberOfDecimals: 0 };
        break;
      case 'decimal':
      case 'decimal_with_zeros':
      case 'numeric':
        numericValidation = {
          allowNegative: true,
          totalNumberOfDigits: limit,
          numberOfDecimals: +attributes.decimals || -1,
        };
        break;
      case 'positivedecimal':
        numericValidation = {
          allowNegative: false,
          totalNumberOfDigits: limit,
          numberOfDecimals: +attributes.decimals || -1,
        };
        break;
      case 'integer':
        numericValidation = { allowNegative: true, totalNumberOfDigits: limit, numberOfDecimals: 0 };
        break;
      case 'date':
        const result = handleDateChange(e.target.value);
        e.target.value = result.value;
        cursorPosition = result.cursorPosition;
        break;
      default:
        const cursorLoc = e.target.selectionStart;
        const el = e.target;
        window.requestAnimationFrame(() => {
          el.selectionStart = cursorLoc;
          el.selectionEnd = cursorLoc;
        });
        break;
    }

    if (numericValidation) {
      const result = handleNumberChange(
        e.target.value,
        value,
        cursorPosition,
        numericValidation.allowNegative,
        numericValidation.totalNumberOfDigits,
        numericValidation.numberOfDecimals,
      );
      e.target.value = result.value;
      cursorPosition = result.cursorPosition;
    }

    e.target.selectionStart = cursorPosition;
    e.target.selectionEnd = cursorPosition;
  };

  const onChangeEvent = async (e: ChangeEvent<HTMLInputElement>) => {
    // Save entered value
    // ==================
    let entered = e.target.value;

    // Remove (ignore) invalid chars (mask dependant)
    // ==============================================
    handleChange(e);

    // Store changed client value in state
    // ===================================
    if (autoCapitalize) e.target.value = e.target.value.toUpperCase();
    setValue(e.target.value);

    // Convert value from client format to server format
    // =================================================

    // Initialize
    // ----------
    let _value = e.target.value;
    let _parsingErrorMessage: string | null = null;
    let decSep = getServerDecimalSeparator();
    let padChar = e.target.closest('[data-padchar]')?.getAttribute('data-padchar');
    if (padChar === '') {
      padChar = ' ';
    }
    let limit = +(e.target.closest('[data-limit]')?.getAttribute('data-limit') || 0);
    let mask = getResultingMask(attributes);

    // Convert value
    // -------------
    let xtValue = new XTString(_value);
    let xtNum = xtValue.clientFormatToNumber(localization.decimalSeparator, localization.groupSeparator);
    switch (mask) {
      case 'positiveinteger':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, false, false, false, limit, padChar).toString();
        let padding = String(padChar || '');
        if (padChar !== undefined && padChar !== null) {
          padding = padChar === '' ? ' ' : padding;
        }
        while (_value.startsWith(padding) && !!padding) {
          _value = _value.slice(1);
        }
        while (entered.startsWith('0')) {
          _value = `0${_value}`;
          entered = entered.slice(1);
        }
        if (limit > 0) {
          _value = _value.padStart(limit, padding);
        }
        break;
      case 'positiveinteger_with_zeros':
      case 'PositiveIntegerWithZeros':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, false, true, false, limit, padChar).toString();
        break;
      case 'decimal':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, true, false, true, 0, padChar).toString();
        break;
      case 'positivedecimal':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, true, false, false, 0, padChar).toString();
        break;
      case 'integer':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, false, false, true, limit, padChar).toString();
        break;
      case 'numeric':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, true, false, true, 0, padChar).toString();
        break;
      case 'decimal_with_zeros':
        if (!xtNum.isNaN) _value = xtNum.toXTStringServerFormat(decSep, true, true, true, 0, padChar).toString();
        break;
      case 'date':
        let result = Formatters.Date.parseValueFromClientFormatToServerFormat(
          _value,
          localization.dateFormat,
          settings?.regionals?.dateformats[0].$,
        );
        _value = result.serverValue;
        _parsingErrorMessage = getParsingErrorMessage(result.errors);
        // remark: do not use result.clientValue here, impossible to detect here if end user has finished typing. The resulting client value is changed in the onBlur event.
        break;
      default:
        break;
    }

    // Handle padding
    // --------------
    if (_value.trim()) {
      //Pad only if value is not empty
      _value = _value.padStart(limit, padChar || '');
    }

    // Save result
    // -----------
    if (isCombo) {
      let newOption = options.find((o) => o.label === _value);
      if (newOption) {
        _value = newOption.value ?? newOption.label;
      }
    }
    valueInServerFormatRef.current = _value;
    const noteValue = XTValueToNote(
      mask,
      valueInServerFormatRef.current,
      attributes.limit,
      getServerDecimalSeparator(),
      localization,
      '',
      '',
      settings,
    );
    setFieldValue(noteValue);
    setDisplayValue(e.target.value);
    setParsingErrorMessage(_parsingErrorMessage);
    if (onRowChange) onRowChange(_value);
    if (_value.trimRight() !== origValue) {
      //Formatters and mask checks here
      if (rowID) {
        setOrig(_value);
        addToRowCommand(panelID, rowID, name, _value, dirtyflag, rowFlags);
      } else {
        addToCommand(panelID, id, _value, dirtyflag);
      }
    } else {
      if (rowID) {
        removeFromRowCommand(panelID, rowID, id, dirtyflag);
      } else removeFromCommand(panelID, id, dirtyflag);
    }

    // Handle autotab functionality
    // ============================
    if (attributes.autotab === 'true') {
      // Determine max/current length
      // ----------------------------
      let maximumLength = limit;
      let currentLength = e.target.value.length; // remark: ("corrected") ENTERED value is used to determine the current length (allowing user to type leading zeros for numbers to enforce autotab)

      if (_parsingErrorMessage) {
        maximumLength = 0; // never autotab if invalid value entered
      } else {
        switch (mask) {
          case 'decimal':
          case 'decimal_with_zeros':
          case 'numeric':
          case 'integer':
            maximumLength = 0; // autotab not supported if negative numbers allowed (as not possible to reach the limit + 1 for positive numbers)
            break;
          case 'positiveinteger':
          case 'positiveinteger_with_zeros':
          case 'PositiveIntegerWithZeros':
            break;
          case 'positivedecimal':
            if ((+attributes.decimals || -1) !== 0) {
              // Be aware that the decimal attribute can be undefined (case server side validation) !!
              maximumLength = 0; // autotab not supported if decimals allowed or in case of server side validation
            }
            break;
          case 'date':
            currentLength = e.target.value.replaceAll(localization.dateSeparator, '').length; // adjust current length as seperators also not included in maximum length
            break;
          case 'time':
            currentLength = e.target.value.replaceAll(':', '').length; // adjust current length as seperators also not included in maximum length
            break;
          default:
            break;
        }
      }

      // Execute autotab functionality
      // -----------------------------
      if (maximumLength > 0 && currentLength >= maximumLength) {
        selectNextElement();
        return;
      }
    }

    // Handle auto complete functionality (only if configured to)
    // ==========================================================
    if (autoComplete) {
      setHighlight(-1);

      if (e.target.value.length >= minLettersToRun) {
        if (
          autocompleteState.textSendToApi &&
          e.target.value.length >= autocompleteState.textSendToApi.length &&
          e.target.value.includes(autocompleteState.textSendToApi) &&
          !autocompleteState.getMore //narrowing searched text, when all data was already returned for shorter string (!getMore)
        ) {
          setAutocompleteState({
            ...autocompleteState,
            showList: getFilteredAutocompleteData(e.target.value).length > 0 ? true : false,
            filter: e.target.value,
          });
        } else {
          let _params: Record<string, string> = {};
          if (Array.isArray(autoCompleteParams)) {
            for (let k in autoCompleteParams) {
              let param = autoCompleteParams[k];
              _params[param.id] = param.value;
            }
          } else if (Object(autoCompleteParams) === autoCompleteParams) {
            _params[autoCompleteParams.id] = autoCompleteParams.value;
          }

          const textSendToApi = e.target.value; // Remark: original code was using e.target.value after the await, but that value could be changed in the meantime (async !!) and that case the value in the state was wrong
          const _data = await getAutoCompleteData(autoCompleteEndpoint, e.target.value, _params); //NTH? include some delay to allow user to key in multiple characters before launching the api call ?

          if (e.target.value.length >= minLettersToRun) {
            setAutocompleteState({
              ...autocompleteState,
              showList: editRef.current === document.activeElement,
              data: _data && _data.data && _data.data.items ? _data.data.items : [],
              getMore: !!_data?.control && !!_data.control.total && _data.control.total > limit,
              textSendToApi: textSendToApi,
              filter: e.target.value,
            });
          }
        }
      } else {
        abortAutoCompleteMode();
      }
    }
  };

  const selectNextElement = () => {
    if (autoComplete && autocompleteState.showList && filtered.length > 0) abortAutoCompleteMode();

    setTimeout(() => {
      /* Remark: timeout really needed in several cases as state changes are only availble at next rendering. Known cases:
          ° in autocomplete mode where we need the focus to be on the input control first (eg not the autocomplete list item that might be clicked)
          ° in editable grid (without timeout the last entered character is not yet added to the value!!)
          ° date mask (without time out the client date formatting is not done)
      */
      if (document.activeElement && document.activeElement === editRef.current) XT.selectNextElement();
    }, 0);
  };

  const getFilteredAutocompleteData = (filter: string) => {
    //filter list according to written letters
    //'sequential fuzzy search' - filter accross all fields and sequential combination of searched letters, e.g.
    //search EST will find: ESTimate, tEST, pESTka, wErSeT, but not SET or TEarS
    return (autocompleteState.data || []).filter((x) =>
      Object.values(x).some((y) => new RegExp(`.*${filter}.*`, 'gi').test(y)),
    );
  };

  let filtered = isCombo
    ? options.map((o) => ({
        ...o,
        selected: o.label === value,
      }))
    : getFilteredAutocompleteData(editRef.current?.value || ''); //autocompleteState.filter);
  // if (highlight === -1 && filtered.length === 1) setHighlight(0); //No longer needed due to latest fix where enter triggers server interaction when no item is highlighted

  const autoCompleteEditField = (autoComplete: string) => {
    editRef?.current?.focus(); // give focus back to edit control when clicking list element (before changing state to prevent value will be selected in the onFocus event)
    setAutocompleteState({
      ...autocompleteState,
      showList: false,
      data: [], // hide list
      textSendToApi: autoComplete,
      getMore: false,
    }); // prevent api call with selected value

    /**
     * Editing handle for the auto complete field
     */
    setTimeout(() => {
      // make sure state is changed first when selecting list element (prevent api call with selected value)
      if (!!editRef && !!editRef.current) {
        let nativeInputValueSetter = Object?.getOwnPropertyDescriptor?.(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        nativeInputValueSetter?.call(editRef.current, autoComplete);
        editRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      }
      setValue(autoComplete);
    }, 0);
  };

  const setComboValue = (index: number) => {
    setTimeout(() => {
      // make sure state is changed first when selecting list element (prevent api call with selected value)
      if (!!editRef && !!editRef.current) {
        let nativeInputValueSetter = Object?.getOwnPropertyDescriptor?.(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        nativeInputValueSetter?.call(editRef.current, options[index].label);
        editRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      }
      //Set highlight when setting combo value
      setHighlight(index);
      setValue(options[index].label);
    }, 0);
  };

  useEffect(() => {
    if (!autocompleteState.showList && isCombo) {
      if (editRef?.current === document.activeElement) {
        editRef.current.select();
      }
    }
  }, [autocompleteState.showList]);

  const handlePrompt = (e: MouseEvent) => {
    /**
     * Calling the window propmt handler with panel attribute
     */
    e.preventDefault();
    let panel = editRef?.current?.closest('[data-ddspanel]')?.getAttribute('data-ddspanel') || panelID || '';
    setScope(getddspos(), attributes?.rowOffset || 0);
    promptHandler(name, panel, rowField, rowID);
  };

  const handleAlternativePrompt: MouseEventHandler<HTMLImageElement> = (e: MouseEvent) => {
    e.preventDefault();
    let panel = editRef?.current?.closest('[data-ddspanel]')?.getAttribute('data-ddspanel') || panelID || '';
    setScope(getddspos(), attributes?.rowOffset || 0);
    promptHandler(name, panel, rowField, rowID, attributes?.alternativepromptkey);
  };

  const getddspos = (): string => {
    let ddspos = attributes?.ddspos || '1,1';
    if (editRef?.current?.selectionStart && editRef?.current?.selectionStart !== editRef?.current?.maxLength) {
      let split = ddspos.split(',');
      ddspos = '' + (+split[0] + editRef.current.selectionStart) + ',' + split[1];
    }
    return ddspos;
  };

  const isCursorInddsRange = (cursor: string): boolean => {
    // only if valid cursor position supplied (something like "69,20" expected representing column and row)
    if (!cursor) return false;
    const cursorPosition = cursor.split(',');
    if (cursorPosition.length !== 2) return false;

    // only if valid dds position available

    if (!attributes?.ddspos) return false;
    const elementPosition = attributes.ddspos.split(',');
    if (elementPosition.length !== 2) return false;

    // only if same row
    if (cursorPosition[1] !== elementPosition[1]) return false;

    // only if cursor column within dds range
    if (+cursorPosition[0] < +elementPosition[0]) return false;
    if (+cursorPosition[0] > +elementPosition[0] + +attributes.limit) return false;

    return true;
  };

  let inputMask = getResultingMask(attributes);
  let inputLimit = +attributes.limit;

  if (['decimal', 'positivedecimal', 'decimal_with_zeros'].indexOf(inputMask) > -1) {
    inputLimit += 2; //NTH_SKE: no limit for number and date? (allowing to paste "formatted" data: eg "ID:20-2323"). Currently paste may fail if lenght formatted value exceeds the limit
  }

  if (['integer', 'integer_with_zeros'].indexOf(inputMask) > -1) {
    inputLimit += 1;
  }

  const [hasFocus, setHasFocus] = useState(false); //Added for combo arrow navigation
  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled || readOnly) {
      return false;
    } else if (rowID) {
      return false;
    } else if (autoComplete && autocompleteState.showList && filtered.length > 0) {
      return false;
    } else if (isCombo && hasFocus) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Developper remark: do NOT move this code, keep this code just before return JSX.eleemnt (consider end of mounting just before first rendering)
    isMountingRef.current = false;
  }, []);
  return (
    <>
      <InputGroup
        className={!readOnly && !disabled ? variant : ''}
        data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
        //data-html={true}
        data-for="global"
        data-iscapture="true"
      >
        <Form.Control
          data-selected-val={origValue}
          //data-event={autoComplete && autocompleteState.showList && filtered.length > 0 ? 'ignore' : undefined}
          onKeyUp={(e: ReactKeyboardEvent) => {
            e.preventDefault(); // Remark SKE 20221007: not sure this is needed, but it was there so I kept it for safety reasons
            if ((autoComplete || isCombo) && autocompleteState.showList && filtered.length > 0) {
              /*
                Remark: the specific behavior when pressing keyboard buttons while autocomplete mode is active, is implemented in the onKeyDown function (see further).
                However as our implementation of hotkeys mostly is triggered by keyup events (see Window.tsx), we also have to prevent in some specific cases that the
                event is bubbling up to the hotkeys implementation.
              */
              if (['arrowdown', 'arrowup', 'escape'].indexOf(e.key.toLowerCase()) > -1) {
                e.stopPropagation(); // Remark: currently not "really" used by our hotkeys implementation, but included here anyway as a protection against future enhancements
              } else if (
                (highlight !== -1 || filtered.find((o) => !!o.selected)) &&
                ['pagedown', 'pageup'].indexOf(e.key.toLowerCase()) > -1
              ) {
                e.stopPropagation(); // Remark: really needed as for tables hotkeys would give focus to the table resulting in abortion of autocomplete mode
              } else if ((highlight !== -1 || filtered.find((o) => !!o.selected)) && e.key.toLowerCase() === 'enter') {
                e.stopPropagation(); // Remark: hotkeys implementation for <ENTER> currently on keydown, but included here anyway as a protection against future enhancements
              }
            }
            // if(variant === 'combo') {
            //   if(!['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'tab', 'enter'].includes(e.key.toLowerCase())) {
            //     e.preventDefault();
            //     e.stopPropagation();
            //   }
            // }

            if (
              ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
              LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
            ) {
              // const mask = getResultingMask(attributes);
              // if (['decimal', 'integer', 'numeric', 'decimal_with_zeros', 'positiveinteger', 'positiveinteger_with_zeros', 'PositiveIntegerWithZeros', 'positivedecimal', 'date', 'time'].includes(mask)) {
              selectNextElement();
              // } //else: text ==> ignore act as tab
            }
          }}
          onKeyDown={(e: ReactKeyboardEvent) => {
            // when contextMenu is opened then other than its key events ['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'],
            // so e.g. pageDown / pageUp should be disabled
            const contextMenuOpened = document.getElementsByClassName('react-contextmenu--visible')?.length > 0;
            const selectedAndNotDisabledMenuItem =
              document.querySelectorAll('.react-contextmenu-item--selected:not(.react-contextmenu-item--disabled)')
                ?.length > 0;
            if (
              (contextMenuOpened &&
                !['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'].includes(
                  e.key.toLowerCase(),
                )) ||
              (contextMenuOpened && !selectedAndNotDisabledMenuItem && e.key.toLowerCase() === 'enter')
            ) {
              e.stopPropagation();
              e.preventDefault();
              return;
            }
            // Handle numeric pad +/- "act as tab" behavior
            // ============================================
            if (
              ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
              LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
            ) {
              const mask = getResultingMask(attributes);
              if (['decimal', 'integer', 'numeric', 'decimal_with_zeros'].includes(mask)) {
                editRef?.current?.setSelectionRange(0, 0); //==> (minus) sign will be added at the beginning
              } else {
                if (autoComplete && autocompleteState.showList && filtered.length > 0 && highlight !== -1) {
                  autoCompleteEditField(
                    autoCompleteKey
                      ? filtered[highlight][autoCompleteKey]
                      : Object.values(filtered[highlight] || {})[0],
                  );
                }
                e.preventDefault(); //swallow sign if not numeric or negative values not allowed
              }
            }
            // Adjust cursor position before server interaction
            // ================================================
            if (/^f[0-9]{1,2}/.test(e.key.toLowerCase())) {
              setScope(getddspos(), attributes?.rowOffset || 0);
            }
            // Combo specific
            // ===========================
            let _highlight = -1;
            if (isCombo) {
              if (e.key.toLowerCase() === 'arrowdown' || e.key.toLowerCase() === 'arrowup') {
                e.preventDefault(); // default behavior input control for arrowdown is moving cursor to the end: not wanted here
                e.stopPropagation(); // do not trigger other functionalities like arrow navigation
                if (e.altKey) {
                  setAutocompleteState({
                    ...autocompleteState,
                    showList: !autocompleteState.showList,
                    data: [...options],
                    textSendToApi: '',
                  });
                } // else {
                let optionIndex = options.findIndex((o) => o.label === editRef.current.value);
                _highlight = optionIndex;

                //Updated conditions for handling scroll on combo open
                if (!e.altKey) {
                  if (e.key.toLowerCase() === 'arrowdown') {
                    optionIndex++;
                  } else if (e.key.toLowerCase() === 'arrowup') {
                    optionIndex--;
                  }
                  if (optionIndex < 0) {
                    optionIndex = options.length - 1;
                  } else if (optionIndex >= options.length) {
                    optionIndex = 0;
                  }
                }
                setComboValue(optionIndex);
                //}
              } else if (e.key.toLowerCase() === 'pagedown' && autocompleteState.showList) {
                let optionIndex = options.findIndex((o) => o.label === editRef.current.value);
                e.preventDefault(); // do NOT trigger browser default behavior (statement really needed !!)
                e.stopPropagation(); // do not trigger other functionalities like pagedown in editable table
                setComboValue(Math.min(optionIndex + autocompletePagesizeRef.current, filtered.length - 1));
              } else if (e.key.toLowerCase() === 'pageup' && autocompleteState.showList) {
                let optionIndex = options.findIndex((o) => o.label === editRef.current.value);
                e.preventDefault(); // do NOT trigger browser default behavior (statement really needed !!)
                e.stopPropagation(); // do not trigger other functionalities like pageup in editable table
                setComboValue(Math.max(optionIndex - autocompletePagesizeRef.current, 0));
              } else if ((!isComboEditable || autocompleteState.showList) && e.key.toLowerCase().length === 1) {
                //If non-editable combo or combo list is opened behave like readonly combo
                e.preventDefault(); // default behavior input control for arrowdown is moving cursor to the end: not wanted here
                e.stopPropagation(); // do not trigger other functionalities like arrow navigation
                let optionIndex = options.findIndex((o) => o.label === editRef.current.value);

                let newOptionIndex = options.findIndex(
                  (o, i) => o.label.toLowerCase().startsWith(e.key.toLowerCase()) && i > optionIndex,
                );
                if (newOptionIndex < 0) {
                  newOptionIndex = options.findIndex((o) => o.label.toLowerCase().startsWith(e.key.toLowerCase()));
                }
                if (newOptionIndex > -1) {
                  setComboValue(newOptionIndex);
                  setHighlight(newOptionIndex);
                }
              } else if (!isComboEditable && ['backspace', 'delete', 'del'].includes(e.key.toLowerCase())) {
                //Handle backspace and delete on non editable combo
                e.preventDefault();
                e.stopPropagation();
              }
            }
            // Auto complete mode and combo specific
            // ===========================
            if ((autoComplete || isCombo) && autocompleteState.showList && filtered.length > 0) {
              /*
                Remark: the autocomplete functionality was mainly designed to assist less experienced users allowing them to use a search filter to get a
                list of existing values. However, as the autocomplete function cannot be configured per user, we should also consider the more experienced
                users. Those kind of users only tolerate the autocomplete functionality as long as it does not really impacts their normal way of working
                and more specific as long as it does not introduce any additional keystrokes or additional mousehandling. So they certainly not want to be
                obliged to select a value before they kan continue. The trigger that is used to distinguish the two type of users is the fact that a row in
                the autocomplete list is selected or not.

                The more experienced users normally do not select any row. As long as no row is selected the only keystrokes that will be interpreted by the
                autocomplete functionallity are:
                  <arrow down>   select first row of the autocomplete list
                  <arrow up>     select last row of the autocomplete list
                  <escape>       hide the autocomplete list (& for editable table: undo cell changes as this is the default behavior)
                All other keystrokes behave exactly the same as if there was no autocomplete function including <tab>, <enter> and any other function keys.

                Once a row is selected the behavior for more keys will be changed:
                  <arrow down>   select next row of the autocomplete list (if last row was the selected one, as a result no row will be selected anymore)
                  <arrow up>     select previous row of the autocomplete list (if first row was the selected one, as a result no row will be selected anymore)
                  <escape>       hide the autocomplete list
                  <page down>    scroll to next page of the autocomplete list
                  <page up>      scroll to previous page of the autocomplete list
                  <enter>        moves value of current selected row to the corresponding input element
                  <tab>          moves value of current selected row to the corresponding input element and selects next element
                All other function keys will hide the autocomplete list without selecting the value of the current row and trigger the expected behavior as if
                there was no autocomplete configured (beware if this needs to be changed and if value needs to be selected additional coding will be needed for
                editable tables). All other keystrokes behave exactly the same as if there was no autocomplete function including <home>, <end>, ...
              */

              if (!isCombo || _highlight === -1) {
                //Get highlight from state for non combo elements or else if _highlight is not set by previous conditions
                _highlight = highlight;
              }

              if (e.key.toLowerCase() === 'arrowdown' && !(isCombo && e.altKey)) {
                e.preventDefault(); // default behavior input control for arrowdown is moving cursor to the end: not wanted here
                e.stopPropagation(); // do not trigger other functionalities like arrow navigation
                if (_highlight === -1) {
                  setHighlight(0);
                } else if (_highlight === filtered.length - 1) {
                  if (isCombo) {
                    setHighlight(0);
                  } else {
                    setHighlight(-1);
                  }
                } else {
                  setHighlight(_highlight + 1);
                }
              } else if (e.key.toLowerCase() === 'arrowup' && !(isCombo && e.altKey)) {
                e.preventDefault(); // default behavior input control for arrowup is moving cursor to the start: not wanted here
                e.stopPropagation(); // do not trigger other functionalities like arrow navigation
                if (_highlight === -1) {
                  setHighlight(filtered.length - 1);
                } else if (_highlight === 0) {
                  if (isCombo) {
                    setHighlight(filtered.length - 1);
                  } else {
                    setHighlight(-1);
                  }
                } else {
                  setHighlight(_highlight - 1);
                }
              } else if (e.key.toLowerCase() === 'pagedown' && _highlight !== -1) {
                e.preventDefault(); // do NOT trigger browser default behavior (statement really needed !!)
                e.stopPropagation(); // do not trigger other functionalities like pagedown in editable table
                setHighlight(Math.min(_highlight + autocompletePagesizeRef.current, filtered.length - 1));
              } else if (e.key.toLowerCase() === 'pageup' && _highlight !== -1) {
                e.preventDefault(); // do NOT trigger browser default behavior (statement really needed !!)
                e.stopPropagation(); // do not trigger other functionalities like pageup in editable table
                setHighlight(Math.max(_highlight - autocompletePagesizeRef.current, 0));
              } else if (e.key.toLowerCase() === 'enter' && _highlight !== -1) {
                e.stopPropagation(); // do not trigger other functionalities, BEWARE: in case of editable table, pressing <ENTER> commits the change of the cell value and as a result next lines of code would not able to change the value anymore !!
                autoCompleteEditField(
                  autoCompleteKey
                    ? filtered[_highlight][autoCompleteKey]
                    : Object.values(filtered[_highlight] || {})[0],
                );
                setHighlight(-1);
              } else if (['escape', 'enter'].indexOf(e.key.toLowerCase()) > -1) {
                if (e.key.toLowerCase() === 'escape' && _highlight !== -1 && !!rowID) e.stopPropagation(); // suppress default behavior editable table whne row is selected: undo cell changes (if you want to undo cell changes when a autocomplete row is selected, yoo will need to press <escape> twice, first time to abort autocompletion mode & second time to undo the cell change)
                abortAutoCompleteMode(); // Remark: when no row is highlighted, pressing <enter> should trigger the applications default action (so abort autocomplete mode to prevent list is still visible)
              } else if (e.key.toLowerCase() === 'tab' && _highlight !== -1) {
                if (rowID) e.stopPropagation(); // BEWARE: in case of editable table, pressing <TAB> commits the change of the cell value and as a result next lines of code would not able to change the value anymore !!
                autoCompleteEditField(
                  autoCompleteKey
                    ? filtered[_highlight][autoCompleteKey]
                    : Object.values(filtered[_highlight] || {})[0],
                );
                setHighlight(-1);
                if (rowID) setTimeout(selectNextElement, 0); //REMARK: timeout needed to avoid conflict with autotab functionality
              } else if (/^f[0-9]{1,2}/.test(e.key.toLowerCase())) {
                abortAutoCompleteMode();
              }
            }
          }}
          data-invalid={isInvalid}
          data-input-invalid={!!parsingErrorMessage} // used to prevent server calls & to give input focus
          data-mask={attributes.mask}
          autoCapitalize={autoCapitalize ? 'on' : 'off'}
          ref={editRef}
          autoComplete={'off'}
          id={id}
          type={attributes?.password === 'true' ? 'password' : controlType}
          placeholder={
            attributes?.mask === 'date' && !readOnly && !disabled
              ? `${Localization.instance.getString(localization.dateFormat)}`
              : placeholder
          }
          data-hiddendata={attributes.hiddendata}
          maxLength={attributes.mask === 'date' ? `${localization.dateFormat}`.length : inputLimit}
          className={`text-style text-font padding 
              ${autoCapitalize && attributes.mask !== 'date' ? 'auto-capitalise' : ''}
              ${className} 
              text-${textDirection || 'left'}`}
          name={name}
          data-value={value}
          value={value}
          readOnly={disabled || readOnly}
          hidden={!visible}
          data-autofocus={isCursorInddsRange(cursor)}
          data-focusconstraint={hasFocusConstraint ? 'true' : 'false'}
          data-defaultfocus={attributes.defaultinitialfocus === 'true' ? 'true' : 'false'}
          data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
          tabIndex={!readOnly && !disabled ? x + y : -1}
          isValid={isValid}
          isInvalid={(errors && isInvalid) || !!parsingErrorMessage}
          onClick={() => {
            if (!isComboEditable) {
              //Open on click only if combo is readonly as open list behaves like a readonly combo and will not allow entering of custom values
              setAutocompleteState({
                ...autocompleteState,
                showList: !autocompleteState.showList,
                data: [...options],
                textSendToApi: '',
              });
            }
          }}
          onChange={onChangeEvent}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            if (e.relatedTarget === document.body) {
              e.target.focus();
              return false;
            }
            if (!(disabled || readOnly)) onBlurEvent(e);

            // Abort autocomplete mode (if appropriate)
            // =======================================
            if (autocompleteState.showList) {
              let abort = true;

              // not if current application has also no longer focus (eg email alert)
              if (!document.hasFocus()) {
                abort = false;

                // not if any line of the autocomplete list gets focus (eg by clicking one)
              } else if (e.relatedTarget instanceof HTMLElement) {
                if (e.relatedTarget.classList.contains('auto-complete-item')) {
                  abort = false;
                }
              }

              // abort mode
              if (abort) abortAutoCompleteMode();
            } else if (rowID && onClose) {
              onClose(true);
            }
            if (
              preferredCaretPosition > -1 &&
              caretInfoRef.current.preferredPosition > -1 &&
              preferredCaretPosition !== caretInfoRef.current.preferredPosition
            ) {
              changePreferredCaretPosition(caretInfoRef.current.preferredPosition);
            }
            setHasFocus(false);

            // Handle aperio view
            // ==================
            refHandleAperioViewOnBlur?.current?.(windowData);
          }}
          onFocus={(e: FocusEvent<HTMLInputElement>) => {
            //TODO: Move back to click
            // if(isCombo) {
            //   setAutocompleteState({ ...autocompleteState, showList: !autocompleteState.showList, data: [...options], textSendToApi: '' });
            // }
            setFocussedElement(id);
            const mask = getResultingMask(attributes);
            const noteValue = XTValueToNote(
              mask,
              valueInServerFormatRef.current,
              attributes.limit,
              getServerDecimalSeparator(),
              localization,
              '',
              '',
              settings,
            );
            setFieldValue(noteValue);
            setDisplayValue(e.target.value);

            if (!(disabled || readOnly)) onFocusEvent(e);
            if (!autocompleteState.showList) {
              setInitialValueSelection({ requested: true }); // Remark state change needed: previous statement may result in a change of the react state (value) ==> resulting value in HTML input element only available during next rendering cycle
            }
            setHasFocus(true);
          }}
        />
        {variant !== 'edit' && !readOnly && !disabled && (
          <InputGroup.Append>
            {attributes.alternativepromptkey && (
              <InputGroup.Text className={'text-font'} onMouseDown={handleAlternativePrompt}>
                <img src={PromptArrow} alt={'alt prompt arrow'} />
              </InputGroup.Text>
            )}
            <InputGroup.Text
              className={'text-font'}
              onMouseDown={(e: MouseEvent) => {
                if (isCombo) {
                  setTimeout(() => {
                    setAutocompleteState({
                      ...autocompleteState,
                      showList: !autocompleteState.showList,
                      data: [...options],
                      textSendToApi: '',
                    });
                    //Moved inside for accommodating closing of combo
                    editRef.current?.focus();
                  }, 0);
                } else {
                  handlePrompt(e);
                }
              }}
            >
              {attributes.mask === 'date' ? (
                <SquareIcon size={'14px'}>{Icons.Calendar}</SquareIcon>
              ) : isCombo ? (
                <SquareIcon size={'14px'}>{Icons.DropdownArrow}</SquareIcon>
              ) : (
                <img src={PromptArrow} style={{ transform: 'rotate(180deg)' }} alt={'prompt arrow'} />
              )}
            </InputGroup.Text>
          </InputGroup.Append>
        )}
        {/*{isCombo && !readOnly && !disabled && (*/}
        {/*  <InputGroup.Append>*/}
        {/*    <InputGroup.Text className={'text-font'} onMouseDown={() => {*/}
        {/*      setAutocompleteState({ ...autocompleteState, showList: !autocompleteState.showList, data: [...options], textSendToApi: '' });*/}
        {/*    }}>*/}
        {/*      <SquareIcon size={'14px'}>{Icons.DropdownArrow}</SquareIcon>*/}
        {/*    </InputGroup.Text>*/}
        {/*  </InputGroup.Append>*/}
        {/*)}*/}
        {/*{isCombo && <InputGroup.Append>*/}
        {/*  <DropdownButton*/}
        {/*    variant="primary"*/}
        {/*    title=""*/}
        {/*    id="input-group-dropdown-1"*/}
        {/*  >*/}
        {/*    {!disabled && (*/}
        {/*      <Dropdown.Menu className={`full-width dropdown-list ${className}`}>*/}
        {/*        {options.map((option, index) => (*/}
        {/*          <Dropdown.Item*/}
        {/*            data-event="ignore"*/}
        {/*            className={'dropdown-list-item'}*/}
        {/*            key={`${id}-${option.label}-${index + 1}`}*/}
        {/*            onClick={() => {*/}
        {/*              // setSelectedOption(option);*/}
        {/*              setValue(option.label);*/}
        {/*              addToCommand(panelID, id, option.value || '', dirtyflag);*/}
        {/*            }}*/}
        {/*            value={option.value}*/}
        {/*          >*/}
        {/*            <span>{option.label}</span>*/}
        {/*          </Dropdown.Item>*/}
        {/*        ))}*/}
        {/*      </Dropdown.Menu>*/}
        {/*    )}*/}
        {/*  </DropdownButton>*/}
        {/*</InputGroup.Append>}*/}
      </InputGroup>
      {(autoComplete || isCombo) &&
        autocompleteState.showList &&
        filtered.length > 0 &&
        createPortal(
          <AutoCompleteList
            filtered={filtered}
            highlight={highlight}
            autocompleteState={autocompleteState}
            boundingClientRect={editRef?.current?.getBoundingClientRect()}
            rowID={rowID}
            onMouseMove={() => setHighlight(-1)}
            isCombo={isCombo}
            onClick={(record: any) => {
              autoCompleteEditField((autoCompleteKey ? record[autoCompleteKey] : Object.values(record)[0]) as string);
            }}
            onPagesizeChanged={(pagesize: number) => {
              autocompletePagesizeRef.current = pagesize;
            }}
          />,
          document.body,
        )}
      {errorMessage && isInvalid && <span className="error-msg-style">{errorMessage}</span>}
    </>
  );
};

export { _Edit as Edit };

// export const Edit = connectNote(_Edit);

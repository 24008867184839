import React, { useMemo } from 'react';
import {
  TextField as MuiTextField,
  Typography,
  SvgIcon,
  CircularProgress,
  useTheme,
  TextFieldProps as MuiTextFieldProps,
  Stack,
} from '@mui/material';
import { Check, Warning as WarningIcon, Error as ErrorIcon } from '@mui/icons-material';
import { IMaskInput } from 'react-imask';
import merge from 'lodash.merge';

interface CustomMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask?: string;
  definitions?: { [key: string]: RegExp }; // Add definitions prop here
}

export type TextFieldProps = MuiTextFieldProps & {
  mask?: string;
  definitions?: { [key: string]: RegExp }; // Add definitions prop here
  loading?: boolean;
  isAutoComplete?: boolean;
  readOnly?: boolean;
};

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomMaskProps>(function CustomMask(props, ref) {
  const { onChange, mask, definitions, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={mask} // custom mask accepted
      definitions={definitions}
      inputRef={ref}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const TextField = ({
  mask = '',
  helperText = '',
  loading = false,
  color = 'primary',
  label,
  placeholder = '',
  fullWidth = false,
  definitions = {},
  isAutoComplete,
  readOnly,
  ...props
}: TextFieldProps) => {
  const { palette } = useTheme();
  const theme = useTheme();
  const helperIcon = useMemo(() => {
    if (loading)
      if (!helperText) {
        return (
          <>
            <CircularProgress size={16} sx={{ color: palette.foreground[400], mr: 1 }} />
            <Typography variant="caption" color={color}>
              loading
            </Typography>
          </>
        );
      } else
        return (
          <>
            <CircularProgress size={16} sx={{ color: palette.foreground[400], mr: 1 }} />
          </>
        );

    switch (color) {
      case 'success':
        return <SvgIcon component={Check} sx={{ color: palette[color].main, fontSize: 16, mr: 1 }} />;
      case 'warning':
        return <SvgIcon component={WarningIcon} sx={{ color: palette[color].main, fontSize: 16, mr: 1 }} />;
      case 'error':
        return <SvgIcon component={ErrorIcon} sx={{ color: palette[color].main, fontSize: 16, mr: 1 }} />;
    }
  }, [loading, color, helperText]);

  const readOnlyStyles = useMemo(
    () =>
      readOnly
        ? {
            '& .MuiInputLabel-root': {
              color: `${theme.palette.foreground[600]} !important`,
            },
            '& .MuiInputBase-root': {
              border: 'none',
              pointerEvents: 'auto', //  Allows hover interaction

              '& .MuiInputBase-input': {
                WebkitTextFillColor: theme.palette.text.disabled,
                caretColor: 'transparent', // Prevents text cursor (caret) from appearing
              },
              '&:before': {
                borderBottomStyle: 'solid',
              },
              '&:focus-within': {
                outline: 'none', //  Removes focus outline
              },
            },
          }
        : {},
    [readOnly, theme],
  );

  const mergedSlotProps = merge(
    {
      input: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: mask && definitions ? (TextMaskCustom as any) : undefined,
      },
    },
    props.slotProps,
  );

  let mergedInputProps = merge(
    {
      mask,
      definitions,
      readOnly,
      'data-1p-ignore': '',
    },
    props.inputProps,
  );
  const primaryInputProps = {
    'data-for': 'global',
    'data-iscapture': 'true',
    'data-tip': `${props.id}`,
  };

  const inputProps = {
    ...primaryInputProps,
    ...(isAutoComplete ? props.inputProps : mergedInputProps),
  };

  return (
    <MuiTextField
      {...props}
      disabled={props.disabled}
      sx={merge({}, props.sx, readOnlyStyles)}
      color={color}
      label={label}
      fullWidth={fullWidth}
      placeholder={placeholder}
      helperText={
        helperText ? (
          <Stack gap={0} direction={'row'} alignItems={'center'} overflow="hidden">
            {helperIcon}
            <Typography variant="caption" color={color}>
              {helperText}
            </Typography>
          </Stack>
        ) : (
          <Stack
            gap={0}
            direction={'row'}
            alignItems={'center'}
            sx={{ position: 'absolute', bottom: '-20px' }}
            overflow="hidden"
          >
            {helperIcon}
          </Stack>
        )
      }
      slotProps={isAutoComplete ? props.slotProps : mergedSlotProps}
      inputProps={inputProps}
    />
  );
};

import { Component } from 'react';
import { Localization } from '../framework/localization/Localization';
import './../styles/Loading.scss';
export enum LoaderContainerType {
  Full = 0,
  ImageOnly = 1,
  TextOnly = 2
}
export type LoaderContainerProps = {
  loaderType: LoaderContainerType;
  text?: string;
};
export class LoaderContainer extends Component<LoaderContainerProps> {
  render() {
    return (
      <div id={`loader_container`} className={window.location.pathname === '/' ? 'login' : ''}>
        {(this.props.loaderType === LoaderContainerType.Full ||
          this.props.loaderType === LoaderContainerType.ImageOnly) && (
          <div id='loader-bg'>
            <div id='loader_part_1_cover'>
              <div id='loader_part_1'></div>
            </div>
            <div id='loader_part_2_cover'>
              <div id='loader_part_2'></div>
            </div>
            <div id='loader_part_3_cover'>
              <div id='loader_part_3'></div>
            </div>
            <span id='loader-cover'></span>
          </div>
        )}
        {(this.props.loaderType === LoaderContainerType.Full ||
          this.props.loaderType === LoaderContainerType.TextOnly) && (
          <p id='loader-text'>{this.props.text ?? Localization.instance.getString('TXT_Loading')}</p>
        )}
      </div>
    );
  }
}

import { Theme } from '@mui/material';
import { Radius } from './Radius';

export const NotificationSnackbar = {
  defaultProps: {
    variant: 'success',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: '435px',
      minHeight: '58px',
      maxHeight: '91px',
      borderRadius: Radius.M,
      fontSize: '16px',
      lineHeight: theme.spacing(2.68),
      color: theme.palette.foreground[400],
      border: 'solid 1px',
      success: {
        backgroundColor: theme.palette.success.light,
        borderColor: theme.palette.success.main,
      },
      warning: {
        backgroundColor: theme.palette.warning.light,
        borderColor: theme.palette.warning.main,
      },
      error: {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.main,
      },
      info: {
        backgroundColor: `${theme.palette.info.light}!important`,
        borderColor: theme.palette.info.main,
      },
    }),
  },
};

import { LiveSearchApiWrapper, LiveSearchApiWrapperProps } from './wrapper/LiveSearchWrapper';
import { Clients } from '../../framework/openapi';
import { useMemo } from 'react';

export type LiveSearchBusinessPartnersProps = Omit<
  LiveSearchApiWrapperProps<'internal.v1.base-general', 'GET /businessPartners'>,
  'apiClient' | 'apiFunction' | 'columnKey'
>;

// return all BusinessPartners
export const LiveSearchBusinessPartners = ({ columnIds, ...props }: LiveSearchBusinessPartnersProps) => {
  return (
    <LiveSearchApiWrapper
      {...props} // needs to be on the beginning to not override specified below props for the defaults
      apiClient={Clients.Internal.Base.V1.General}
      apiFunction={'GET /businessPartners'}
      columnKey={'businessPartner'}
      columnIds={columnIds ? columnIds : ['businessPartner', 'internalName', 'description', 'type']}
    />
  );
};

// return all Customers - subset of BusinessPartners, type: 1 (only Customers) and type: 3 (bp which is supplier and customer at the same time)
// remark: type: 3 is added inside the api, when you search for type: 1
export const LiveSearchCustomers = ({ columnIds, ...props }: LiveSearchBusinessPartnersProps) => {
  const additionalSearchParams = useMemo(() => ({ type: 1 }), []);
  return (
    <LiveSearchApiWrapper
      {...props} // needs to be on the beginning to not override specified below props for the defaults
      apiClient={Clients.Internal.Base.V1.General}
      apiFunction={'GET /businessPartners'}
      columnKey={'businessPartner'}
      columnIds={columnIds ? columnIds : ['businessPartner', 'internalName', 'description', 'type']}
      additionalSearchParams={additionalSearchParams}
    />
  );
};

// return all Suppliers - subset of BusinessPartners, type: 2 (only suppliers) and type: 3 (bp which is supplier and customer at the same time)
// remark: type: 3 is added inside the api, when you search for type: 2
export const LiveSearchSuppliers = ({ columnIds, ...props }: LiveSearchBusinessPartnersProps) => {
  const additionalSearchParams = useMemo(() => ({ type: 2 }), []);
  return (
    <LiveSearchApiWrapper
      {...props} // needs to be on the beginning to not override specified below props for the defaults
      apiClient={Clients.Internal.Base.V1.General}
      apiFunction={'GET /businessPartners'}
      columnKey={'businessPartner'}
      columnIds={columnIds ? columnIds : ['businessPartner', 'internalName', 'description', 'type']}
      additionalSearchParams={additionalSearchParams}
    />
  );
};

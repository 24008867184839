import React, { FunctionComponent } from 'react';
import { useCustomAuth } from 'src/contexts/CustomAuthContext';

export const withCustomAuth =  <T extends object>(WrappedComponent: React.ComponentType<T>) : React.FunctionComponent<T> => {
  const Wrapper: FunctionComponent<T> = (props) => {
    const auth = useCustomAuth((auth) => auth);
    return <WrappedComponent {...(props as T)} auth={auth} />;
  };
  return Wrapper;
};


export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      stroke="currentColor"
      d="M21.4398 11.0499L12.2498 20.2399C11.124 21.3658 9.59699 21.9983 8.0048 21.9983C6.41262 21.9983 4.88565 21.3658 3.7598 20.2399C2.63396 19.1141 2.00146 17.5871 2.00146 15.9949C2.00146 14.4027 2.63396 12.8758 3.7598 11.7499L12.9498 2.55992C13.7004 1.80936 14.7183 1.3877 15.7798 1.3877C16.8413 1.3877 17.8592 1.80936 18.6098 2.55992C19.3604 3.31048 19.782 4.32846 19.782 5.38992C19.782 6.45138 19.3604 7.46936 18.6098 8.21992L9.4098 17.4099C9.03452 17.7852 8.52553 17.996 7.9948 17.996C7.46407 17.996 6.95508 17.7852 6.5798 17.4099C6.20452 17.0346 5.99369 16.5256 5.99369 15.9949C5.99369 15.4642 6.20452 14.9552 6.5798 14.5799L15.0698 6.09992"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

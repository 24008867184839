import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export const HandlerDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: signaturesOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /signatures',
    labelAttribute: 'signatureName',
    valueAttribute: 'signature',
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} options={signaturesOptions} loading={loading} columnNames={COLUMN_NAMES} />;
};

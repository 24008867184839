import { Box, DatePicker, Switch, Text, TextField, useIptorTranslation, TableRowValues } from '@iptor/base';
import { LiveSearchCustomers, WarehouseDropdown, BusinessPartners, BusinessPartnerType } from '@iptor/business';
import { FormControlLabel, Grid2 as Grid, Tooltip } from '@mui/material';
import { useCallback, useRef, useState } from 'react';

import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Order } from '../../../constants';
import { formatDate, formatDateToPayloadFormat } from '../../../utils/formatDate';
import { useOrderDetails } from '../hooks/useOrderDetails';

interface OrderDetailsProps {
  watch?: UseFormWatch<Partial<Order>>;
  setValue?: UseFormSetValue<Partial<Order>>;
  getValues?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  reset?: UseFormReset<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
  clearErrors: UseFormClearErrors<Partial<Order>>;
  setChoosenCustomer?: (customer: string) => void;
}

const OrderDetails = ({
  watch,
  setValue,
  control,
  reset,
  getValues,
  errors,
  clearErrors,
  setChoosenCustomer,
}: OrderDetailsProps) => {
  const dateFormat = 'YYYY-MM-DD';
  const { t } = useIptorTranslation();
  const previousValueRef = useRef<string | null>(null);
  const [internalSalesOrderVisibility, setInternalSalesOrderVisibility] = useState(false);
  const [openBusinessPartners, setOpenBusinessPartners] = useState<boolean>(false);

  const { updateOrderDetails, isLiveSearchLoading } = useOrderDetails({
    watch,
    setValue,
    control,
    reset,
    getValues,
    clearErrors,
  });

  const selectedWarehouse = watch('warehouse');
  const selectedCustomer = watch('customer');
  const selectedOrderType = watch('orderType');
  const selectedDispatchTime = watch('dispatchTime');

  const handleLiveSearchBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event?.relatedTarget?.id === 'ViewAllId') {
        return;
      }

      const value = event?.target?.value?.trimEnd()?.toUpperCase();

      if (value !== previousValueRef.current) {
        previousValueRef.current = value;

        if (value) {
          updateOrderDetails(value, selectedOrderType || '', selectedWarehouse || '', selectedDispatchTime || '');
          setChoosenCustomer(value);
          setValue('customer', value);
        }
      }
    },
    [updateOrderDetails, selectedOrderType, selectedWarehouse, selectedDispatchTime, setValue],
  );

  const handleCustomerChange = (reason: string, value: string, fieldOnChange?: (value: string) => void) => {
    if (reason === 'selectOption' && value) {
      setValue('customer', value);
      fieldOnChange?.(value);
      setValue('warehouse', '');
      setValue('dispatchAddress', undefined);
      setChoosenCustomer(value);
      updateOrderDetails(value, selectedOrderType || '', selectedWarehouse || '', selectedDispatchTime || '');
    } else if (reason === 'clear') {
      setValue('customer', '');
      fieldOnChange?.('');
      setValue('warehouse', '');
      setValue('dispatchAddress', undefined);
    }
  };
  return (
    <Box boxShadow={1}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid container size={12} alignItems="center" justifyContent="space-between">
          <Grid size={8} mb={1.5}>
            <Controller
              name="customer"
              control={control}
              rules={{ required: t('common:TXT_Customer_Mandatory') }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <LiveSearchCustomers
                    {...field}
                    error={!!error}
                    loading={isLiveSearchLoading}
                    onBlur={handleLiveSearchBlur}
                    liveSearchInputValue={selectedCustomer || ''}
                    onLiveSearchInputChange={(e, value, reason) => {
                      handleCustomerChange(reason, value, field.onChange);
                    }}
                    id={field.name}
                    color={errors?.customer?.message ? 'error' : 'primary'}
                    helperText={errors?.customer?.message || ''}
                    required
                    label={t('common:TXT_Customer')}
                    autoFocus
                    fullWidth
                    handleViewAll={{
                      onRequested: () => setOpenBusinessPartners(true),
                      enabled: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid size={2} display={'flex'} justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch
                  value={internalSalesOrderVisibility}
                  id="internalSalesOrderVisibility"
                  onChange={() => {
                    setInternalSalesOrderVisibility(!internalSalesOrderVisibility);
                    setValue('toWarehouse', '');
                  }}
                />
              }
              label={
                <Text component="label" variant="body2" margin={1}>
                  {t('common:TXT_Internal_Sales_Order')}
                </Text>
              }
              sx={{ flexDirection: 'row-reverse', margin: 0 }}
            />
          </Grid>
        </Grid>

        <Grid container size={12}>
          <Grid size={4}>
            <Controller
              name="warehouse"
              control={control}
              rules={{
                required: t('common:TXT_From_Warehouse_Mandatory'),
              }}
              render={({ field }) => {
                return (
                  <WarehouseDropdown
                    value={field?.value}
                    id={field.name}
                    onChange={(newValue, reason) => {
                      const newWarehouse = newValue?.toString() ?? '';
                      field.onChange(newWarehouse);

                      if (reason === 'selectOption' && newWarehouse && selectedCustomer) {
                        updateOrderDetails(
                          selectedCustomer || '',
                          selectedOrderType || '',
                          newWarehouse,
                          selectedDispatchTime || '',
                        );
                      }
                    }}
                    required={internalSalesOrderVisibility}
                    label={t('common:TXT_From_warehouse')}
                    color={internalSalesOrderVisibility && errors?.warehouse?.message ? 'error' : 'primary'}
                    helperText={
                      internalSalesOrderVisibility && errors?.warehouse?.message
                        ? t('common:TXT_From_Warehouse_Mandatory')
                        : ''
                    }
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid size={4}>
            <Controller
              name="dispatchTime"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label={t('common:TXT_Requested_Delivery_Date')}
                  value={field.value ? formatDate(field.value, dateFormat) : null}
                  slotProps={{
                    textField: {
                      id: field.name,
                    },
                  }}
                  onChange={(newValue) => {
                    const formattedDate = formatDateToPayloadFormat(newValue);
                    field.onChange(formattedDate);

                    if (newValue && selectedCustomer) {
                      updateOrderDetails(
                        selectedCustomer || '',
                        selectedOrderType || '',
                        selectedWarehouse || '',
                        formattedDate,
                      );
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={4}>
            <Controller
              name="orderReference"
              control={control}
              render={({ field }) => (
                <TextField
                  id={field.name}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || ''}
                  label={t('common:TXT_Reference')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        {internalSalesOrderVisibility && (
          <Grid size={4}>
            <Controller
              name="toWarehouse"
              rules={{
                required: t('common:TXT_To_Warehouse_Mandatory'),
              }}
              control={control}
              render={({ field }) => (
                <WarehouseDropdown
                  {...field}
                  onChange={(newValue) => {
                    const warehouseValue = newValue?.toString();
                    field.onChange(warehouseValue);
                  }}
                  id={field.name}
                  label={t('common:TXT_To_Warehouse')}
                  required
                  color={internalSalesOrderVisibility && errors?.toWarehouse?.message ? 'error' : 'primary'}
                  helperText={errors?.toWarehouse?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        {openBusinessPartners && (
          <BusinessPartners
            open={openBusinessPartners}
            handleClose={() => setOpenBusinessPartners(false)}
            handleSelected={(row: Record<string, TableRowValues>) => {
              handleCustomerChange('selectOption', row['businessPartner']);
              setOpenBusinessPartners(false);
            }}
            initialValue={selectedCustomer}
            businessPartnerType={BusinessPartnerType.Customer}
          />
        )}
      </Grid>
    </Box>
  );
};

export default OrderDetails;

import { Button, Icon, Text, useCustomAuth, useCustomSnackbar, useIptorTranslation } from '@iptor/base';
import { openAPIManager } from '@iptor/business';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { formatErrorText } from '../../utils/formatError';

interface Props {
  orderToUnlock: { order: number; workStationId: string };
  open: boolean;
  handleClose: (refresh?: boolean) => void;
}

function UnlockOrder({ orderToUnlock, open, handleClose }: Props) {
  const { t } = useIptorTranslation();
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useCustomSnackbar();
  const { userId } = useCustomAuth((auth) => ({ userId: auth.userId }));

  const handleUnlock = () => {
    setLoading(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        'PUT /salesOrders/{order}',
        {
          order: orderToUnlock.order,
        },
        { unlock: true },
      )
      .then(() => {
        showSnackbar({ message: t('sales_orders:ORDER_unlockedSuccessfully'), variant: 'success' });
        handleClose(true);
      })
      .catch((error) => {
        showSnackbar({
          message: t('sales_orders:ORDER_unlockError') + formatErrorText(error),
          variant: 'error',
          autoHide: false,
        });
        handleClose(true);
      });
  };

  const isMyOrder = orderToUnlock.workStationId === userId?.toString().toUpperCase();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
        <Icon icon="squareUnlock01" color="error.main" size="L" />
        <Text variant="h6" fontWeight={600}>
          {t('sales_orders:ORDER_unlockTitle')}
        </Text>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Icon id="cancel" icon="cancel01" onClick={() => handleClose(false)} />
        </Box>
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text variant="subtitle1" paddingBottom={1}>
          {t('sales_orders:ORDER_order')} <b> {` ${orderToUnlock.order} `} </b>
          {isMyOrder ? t('sales_orders:ORDER_orderLockedByYou') : t('sales_orders:ORDER_orderLockedByHandler')}
          <b>{isMyOrder ? '.' : ` ${orderToUnlock.workStationId}.`}</b>
          <b> {t('sales_orders:ORDER_doYouWantToUnlock')}</b>
        </Text>
      </DialogContent>
      <DialogActions>
        <Button id="no" variant="outlined" onClick={() => handleClose(false)}>
          {t('common:No')}
        </Button>
        <Button id="yes" variant="contained" onClick={() => handleUnlock()} loading={loading}>
          {t('common:Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnlockOrder;

export const Autocomplete = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      alignSelf: 'center',
      '& .MuiButtonBase-root': {
        outline: 'none !important',
        backgroundColor: 'transparent !important',
        marginRight: '2px',
      },
      '& .MuiChip-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
      },
      '& .MuiInputBase-root ': {
        minWidth: '294px',
        padding: '16px',
      },

      '& .MuiInputBase-input ': {
        padding: '0 !important',
      },
    },
  },
};

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      stroke="currentColor"
      d="M20 2H4C2.89543 2 2 2.89543 2 4V8C2 9.10457 2.89543 10 4 10H20C21.1046 10 22 9.10457 22 8V4C22 2.89543 21.1046 2 20 2Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M20 14H4C2.89543 14 2 14.8954 2 16V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V16C22 14.8954 21.1046 14 20 14Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path stroke="currentColor" d="M6 6H6.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M6 18H6.01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

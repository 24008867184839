import { stringToNumerableEnum } from '../loggerUtils';
import { AppenderSettings, ILoggerAppender, LogAppenderType, LogInfo, LogLevel } from '../loggerTypes';

export abstract class LoggerAppender implements ILoggerAppender {
  private level: LogLevel;
  private type: keyof typeof LogAppenderType;
  private name?: string;
  private description?: string;
  protected config?: any;

  constructor(settings: AppenderSettings, defaultLevel: keyof typeof LogLevel) {
    this.level = stringToNumerableEnum<LogLevel>(LogLevel as any, settings.level || defaultLevel);
    this.type = settings.type;
    this.name = settings.name;
    this.description = settings.description;
    this.config = settings.config;
  }

  protected get logLevel(): LogLevel {
    return this.level;
  }

  public static stringifyTags(tags: string[]): string {
    if (tags.length < 1) return '';
    return '[' + tags.reduce((result, tag) => `${result} <${tag.toLowerCase()}>,`, '').slice(0, -1) + ' ]';
  }

  public static stringifyData(data: any): string {
    try {
      return JSON.stringify(data) || ''; //NTH_SKE: use same component as winston
    } catch (e: any) {
      return `"ERROR - JSON.stringify() threw an exception: ${(e as Error).message}"`;
    }
  }

  abstract log(logInfo: LogInfo, logLevel: LogLevel): void;
}

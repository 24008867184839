import { Backdrop, Paper, PaperProps, Slide, Stack, styled } from '@mui/material';
import React from 'react';
import { Icon } from '../../Icon';
import { Text } from '../../Text';
import { ActionBar, ActionBarProps } from '../ActionBar';
import { withFocusCapture } from '../../FocusCaptureHOC';

export interface DialogLayoutProps {
  open: boolean;
  onClose: (event: object, reason: 'backdropClick') => void;
  fullScreen?: boolean;
  slotProps?: {
    paper?: PaperProps;
    actionBar?: ActionBarProps;
  };
  children: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  fixedActionBar?: boolean;
  fullWidth?: boolean;
}

export interface CustomDialogPaperProps extends PaperProps {
  fullScreen?: boolean;
  open?: boolean;
}

const SlideWithFocus = withFocusCapture(Slide);

const DialogLayoutPaper: React.FC<CustomDialogPaperProps> = ({ fullScreen = false, open, sx, children, ...props }) => {
  const calculatedWidth = '100%';
  const calculatedHeight = fullScreen ? '100%' : 'auto';

  return (
    <SlideWithFocus in={open} direction="up" timeout={300} appear>
      <Paper
        elevation={24}
        sx={(theme) => ({
          display: open ? 'flex' : 'none',
          flexDirection: 'column',
          minHeight: '70%',
          top: fullScreen ? 0 : '26%',
          position: 'absolute',
          width: calculatedWidth,
          height: calculatedHeight,
          maxHeight: fullScreen ? 'none' : 'calc(100vh - 64px)',
          padding: theme.spacing(3),
          borderRadius: fullScreen ? '0px' : `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
          left: 0,
          bottom: 0,
          transform: fullScreen ? 'none' : 'translateX(0)',
          boxShadow: '0px -11px 15px -7px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14)',
          outline: 'none',
          overflowY: 'hidden',
          zIndex: 1000,
          ...(typeof sx === 'function' ? sx(theme) : (sx as object) || {}),
        })}
        {...props}
      >
        {children}
      </Paper>
    </SlideWithFocus>
  );
};

const OverFlowWrapper = styled('div')(() => ({
  width: '100%',
  overflowY: 'scroll',
  paddingRight: '4px',
}));

export const DialogLayout: React.FC<DialogLayoutProps> = ({
  open,
  onClose,
  fullScreen = false,
  slotProps = {},
  children,
  title,
  actions,
  fixedActionBar = true,
  fullWidth = false,
}) => (
  <Backdrop
    open={open}
    onClick={() => onClose && onClose({}, 'backdropClick')}
    sx={{
      zIndex: 3,
      position: 'absolute',
      width: fullWidth ? '100%' : 'calc(100% - 59px)',
      left: fullWidth ? '0' : '59px',
      overflow: 'clip',
      backgroundColor: 'rgba(0,0,0,0.2)',
    }}
  >
    <DialogLayoutPaper
      open={open}
      fullScreen={fullScreen}
      onClick={(e) => e.stopPropagation()}
      {...slotProps.paper}
      sx={{ overflowY: fixedActionBar ? 'auto' : 'visible', ...slotProps.paper?.sx }}
    >
      <Stack direction="row" justifyContent="space-between" paddingBottom={2}>
        <Text variant="h6" fontWeight="600">
          {title}
        </Text>
        <Icon icon="cancel01" size="M" onClick={() => onClose({}, 'backdropClick')} />
      </Stack>
      <OverFlowWrapper sx={{ overflowY: fixedActionBar ? 'auto' : 'visible' }}>{children}</OverFlowWrapper>
      {actions && (
        <ActionBar
          alignment="right"
          sx={(theme) => ({
            marginBottom: `-${theme.spacing(3)}`,
            marginX: `-${theme.spacing(3)}`,
            marginTop: 'auto',
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[1],
          })}
          {...slotProps.actionBar}
        >
          {actions}
        </ActionBar>
      )}
    </DialogLayoutPaper>
  </Backdrop>
);

import {
  CustomTableProps,
  IconButton,
  RowHighlightState,
  Table,
  TableColumn,
  TableRef,
  TableRowType,
  TableRowValues,
  useIptorTranslation,
  useTheme,
} from '@iptor/base';
import React, { FC, PropsWithChildren, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  OpenAPIId,
  OperationConfig,
  OperationData,
  OperationId,
  OperationParameters,
  OperationResponseDataData,
  useOpenAPIMethod,
} from '../../framework/openapi';
import { Filters, SortType, InternalTableRef } from '@iptor/base';
import { SxProps } from '@mui/material';

export type IptorTableRef = TableRef & {
  refresh: (clearSelected?: boolean) => void;
};

export type IptorTableRowType<
  T extends OpenAPIId,
  K extends OperationId<T>,
  TRows extends TableRowType,
> = OperationResponseDataData<T, K>['items'][0] & TRows & TableRowType;

export type IptorTableCellRendererProps<T extends OpenAPIId, K extends OperationId<T>, TRows extends TableRowType> = {
  row: IptorTableRowType<T, K, TRows>;
  col: IptorTableColumn<T, K, TRows>;
  value: TableRowValues;
  setHighlightStateForRow: ({ rowHighlightType, rowHighlightMessage }: RowHighlightState) => void;
  setColumnValueForRow: (values: { column: string; value: TableRowValues }[]) => void;
};

export interface IptorTableBaseProps extends Omit<CustomTableProps, 'ref' | 'rows' | 'totalRows'> {
  customRef?: React.MutableRefObject<IptorTableRef>;
}

export type IptorTableColumn<T extends OpenAPIId, K extends OperationId<T>, TRows> = Omit<
  TableColumn,
  'key' | 'cellRenderer'
> & {
  key: keyof (OperationResponseDataData<T, K>['items'][0] & TRows) & string;
  cellRenderer?: FC<PropsWithChildren<IptorTableCellRendererProps<T, K, TRows>>>;
};
export interface IptorTableProps<T extends OpenAPIId, K extends OperationId<T>, TRows extends TableRowType>
  extends Omit<IptorTableBaseProps, 'columns' | 'onSelectedRowsChange' | 'onRowsChange'> {
  apiId: T;
  apiEndpoint: K;
  apiParams?: OperationParameters<T, K>;
  apiData?: OperationData<T, K>;
  apiConfig?: OperationConfig<T, K>;
  columns: IptorTableColumn<T, K, TRows>[];
  onSelectedRowsChange?: ({ rows }: { rows: IptorTableRowType<T, K, TRows>[] }) => void;
  onRowsChange?: ({ rows }: { rows: IptorTableRowType<T, K, TRows>[] }) => void;
  slotProps?: {
    refreshIconButton?: {
      sx?: SxProps;
    };
  };
}

//TODO: Generate columns by default (Allow overriding the column definition from "columns" prop)
//TODO: Add "defaultVisibleColumns" prop

export const IptorTable = <T extends OpenAPIId, K extends OperationId<T>, TRows>({
  apiId,
  apiEndpoint,
  apiParams = {},
  apiData,
  apiConfig,
  customRef,
  additionalActions,
  tableID = 'NA',
  slotProps,
  ...rest
}: IptorTableProps<T, K, TRows>) => {
  const apiCall = useOpenAPIMethod(apiId, apiEndpoint);
  const internalRef = useRef<InternalTableRef>(null);
  const { t } = useIptorTranslation();
  const { palette } = useTheme();
  const [paginationParams, setPaginationParams] = useState<{ offset?: number; limit?: number }>({});
  const [filterString, setFilterString] = useState<string>('');
  const [sortString, setSortString] = useState<string>('');

  useImperativeHandle(
    customRef,
    () => ({
      refresh: (clearSelected = true) => {
        //As the API hook allows only one execution at a time. It is good to block refresh till the first table call is completed
        if (!paginationParams?.limit && !paginationParams?.offset) return; //Block refresh if pagination is not yet set

        const where = filterString ? filterString : apiParams.where;
        const orderBy = sortString ? sortString : apiParams.orderBy;
        apiCall.execute({ ...{ ...apiParams, orderBy, where }, ...paginationParams }, apiData, apiConfig);
        if (clearSelected) {
          internalRef.current.setSelected([]);
        }
      },
      clearState: () => {
        throw new Error('Not implemented');
      },
      get rows(): IptorTableRowType<T, K, TRows> {
        return internalRef.current.rows as IptorTableRowType<T, K, TRows>;
      },
      get selectedRows(): Partial<IptorTableRowType<T, K, TRows>[]> {
        return internalRef.current.selectedRows as Partial<IptorTableRowType<T, K, TRows>[]>;
      },
      get page() {
        return internalRef.current.page;
      },
      get rowsPerPage() {
        return internalRef.current.rowsPerPage;
      },
      get resetToFirstPage() {
        return internalRef.current.resetToFirstPage;
      },
      get deselectRows() {
        return internalRef.current.deselectRows;
      },
      get filter() {
        return internalRef.current.filter;
      },
      get sort() {
        return internalRef.current.sort;
      },
    }),
    [apiCall.execute, apiParams, paginationParams, apiData, apiConfig, filterString, sortString, internalRef?.current],
  );

  const reload = useCallback(() => {
    if (!paginationParams?.limit && !paginationParams?.offset) return; //Block refresh if pagination is not yet set
    const where = filterString ? filterString : apiParams.where;
    const orderBy = sortString ? sortString : apiParams.orderBy;
    apiCall.execute({ ...{ ...apiParams, orderBy, where }, ...paginationParams }, apiData, apiConfig);
  }, [paginationParams, filterString, sortString, apiCall.execute, apiParams, apiData, apiConfig]);

  const rows: IptorTableRowType<T, K, TRows> = apiCall?.response?.data?.data?.items || [];

  const totalRows = useMemo<number | 'unknown'>(
    () => apiCall?.response?.data?.control?.total ?? 'unknown',
    [apiCall?.response?.data?.control?.total],
  );

  const onLoad = (page: number, pageSize: number, sort?: { column: string; order: SortType }, filters?: Filters) => {
    const pagination = { offset: (page - 1) * pageSize, limit: pageSize };
    setPaginationParams(pagination);
    console.log('Loading data', page, pageSize, sort, filters);
    const _filterStr = `${
      filters
        ?.map(({ column, value: { equals, min, max } }) =>
          equals
            ? `UPPER(${column}) like '%${equals.toUpperCase()}%'`
            : min && max
              ? `${column}>=${typeof min === 'string' || min instanceof String ? `'${min}'` : min} AND ${column}<=${typeof max === 'string' || max instanceof String ? `'${max}'` : max}`
              : max
                ? `${column}<=${typeof max === 'string' || max instanceof String ? `'${max}'` : max}`
                : min
                  ? `${column}>=${typeof min === 'string' || min instanceof String ? `'${min}'` : min}`
                  : '',
        )
        .filter((s) => s?.trim())
        .join(' AND ') ?? ''
    }`;
    setFilterString(_filterStr);
    let orderBy = apiParams.orderBy;
    const where = _filterStr ? _filterStr : apiParams.where;
    if (sort) {
      const { column, order } = sort;
      const _sortString = `${column} ${order}`;
      setSortString(_sortString);
      orderBy = order !== SortType.NONE ? _sortString : apiParams.orderBy;
    }

    apiCall.execute(
      { ...apiParams, offset: pagination.offset, limit: pagination.limit, orderBy, where },
      apiData,
      apiConfig,
    );
    // const orderBy = sortString ? sortString : apiParams.orderBy;
  };

  return (
    <Table
      {...(rest as CustomTableProps)}
      tableID={tableID}
      additionalActions={
        <>
          <IconButton
            sx={{
              border: `1px solid ${palette.mode === 'light' ? palette.foreground[700] : palette.primary.main}`,
              outline: 'none',
              ...slotProps?.refreshIconButton?.sx,
            }}
            onClick={() => reload()}
            variant="outlined"
            icon="refresh"
            tooltipText={t('common:TXT_Refresh')}
          />
          {additionalActions}
        </>
      }
      rows={rows}
      totalRows={totalRows}
      hasMorePages={(apiCall?.response?.data?.control?.more ?? false) as boolean}
      onLoad={onLoad}
      loading={rest.loading || apiCall.loading}
      internalRef={internalRef}
    />
  );
};

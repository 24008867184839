import { useEffect } from 'react';
import { Box, Stepper, useCustomAuth, useIptorTranslation } from '@iptor/base';
import { HandlerDropdown, SalesOrderTypesDropdown, useOpenAPIMethod, openAPIManager } from '@iptor/business';
import { Grid2 as Grid, Stack, Tooltip } from '@mui/material';

import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Order } from '../constants';
import { useOrderDetails } from '../forms/CreateSalesOrder/hooks/useOrderDetails';
import { useOrderNotifications } from '../hooks/useOrderNotification';
import { formatErrorText } from '../utils/formatError';

export type ComboOptionType = { label: string; value: string };

export enum FormStep {
  ORDER_DETAILS,
  ADD_ITEMS,
  ADDITIONAL_INFO,
}

export const useFormSteps = () => {
  const { t } = useIptorTranslation();

  const formSteps = [
    {
      value: FormStep.ORDER_DETAILS,
      label: t('common:TXT_Select_Customer'),
    },
    {
      value: FormStep.ADD_ITEMS,
      label: t('common:TXT_Add_Items'),
    },
    {
      value: FormStep.ADDITIONAL_INFO,
      label: t('common:TXT_Header_Info'),
    },
  ];

  return formSteps;
};

const CreateSalesOrderSteps = ({
  currentStep = FormStep.ORDER_DETAILS,
  order,
  watch,
  setValue,
  control,
  reset,
  getValues,
  errors,
  clearErrors,
}: {
  currentStep: number;
  order?: Partial<Order>;
  watch?: UseFormWatch<Partial<Order>>;
  setValue?: UseFormSetValue<Partial<Order>>;
  control?: Control<Partial<Order>>;
  reset?: UseFormReset<Partial<Order>>;
  getValues?: UseFormGetValues<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
  clearErrors?: UseFormClearErrors<Partial<Order>>;
}) => {
  const { userId } = useCustomAuth((auth) => ({ userId: auth.userId }));
  const { t } = useIptorTranslation();
  const steps = useFormSteps();
  const { notify } = useOrderNotifications();

  const orderTypes = useOpenAPIMethod('internal.v1.base-tables', 'GET /promptValues/{windowId}');
  const defaultHandlerApi = useOpenAPIMethod('internal.v1.base-tables', 'GET /DC1Users/current');
  const defaultOrderTypeApi = useOpenAPIMethod('internal.v1.base-tables', 'GET /DC1Users/{user}');

  const { updateOrderDetails, isLiveSearchLoading } = useOrderDetails({
    watch,
    setValue,
    control,
    reset,
    getValues,
    clearErrors,
  });

  useEffect(() => {
    if (currentStep === FormStep.ORDER_DETAILS || currentStep === FormStep.ADD_ITEMS) {
      defaultHandlerApi.execute();
      defaultOrderTypeApi.execute({
        user: userId?.toUpperCase(),
      });
    }

    orderTypes.execute({
      windowId: 'salesOrderTypes',
    });

    if (order) {
      setValue('handler', order?.handler);
      setValue('orderType', order?.orderType);
    }
  }, [currentStep, order]);

  useEffect(() => {
    if (
      (currentStep === FormStep.ORDER_DETAILS || currentStep === FormStep.ADD_ITEMS) &&
      defaultHandlerApi.response?.data?.data &&
      !order
    ) {
      const handler = defaultHandlerApi?.response?.data?.data?.handler;
      setValue('handler', handler);
    }
  }, [currentStep, defaultHandlerApi?.response]);

  useEffect(() => {
    if (
      (currentStep === FormStep.ORDER_DETAILS || currentStep === FormStep.ADD_ITEMS) &&
      defaultOrderTypeApi.response?.data?.data &&
      !order
    ) {
      const orderType = defaultOrderTypeApi?.response?.data?.data?.defaultSalesOrderType;
      setValue('orderType', orderType);
    }
  }, [currentStep, defaultOrderTypeApi?.response]);

  const onHandlerChangeInternal = async (handler: string) => {
    if (handler && order?.order) {
      try {
        const resp = await openAPIManager.execute(
          'internal.v1.base-orders',
          'PUT /salesOrders/{order}',
          { order: +order.order },
          { handler },
        );
        if (resp?.data) {
          notify('success', 'Handler Updated successfully');
        }
        setValue('handler', handler);
      } catch (error) {
        notify('error', formatErrorText(error));
      }
    }
  };

  const selectedWarehouse = watch && watch('warehouse');
  const selectedCustomer = watch && watch('customer');
  const selectedDispatchTime = watch && watch('dispatchTime');

  return (
    <Box marginBottom={2} boxShadow={1}>
      <Grid container spacing={12}>
        <Grid size={6.5}>
          <Stepper activeStep={currentStep} steps={steps} />
        </Grid>

        <Grid container spacing={1} size={5.5} justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Tooltip title={t('common:TXT_Handler')} placement="top" arrow>
              <div>
                <Controller
                  name="handler"
                  control={control}
                  rules={{ required: t('common:TXT_Handler_Mandatory') }}
                  render={({ field }) => (
                    <HandlerDropdown
                      onChange={(newValue, reason) => {
                        if (reason === 'selectOption' || reason === 'removeOption' || reason === 'clear') {
                          onHandlerChangeInternal(newValue as string);
                        }
                        field.onChange(newValue as string);
                      }}
                      id={field.name}
                      loading={isLiveSearchLoading}
                      required
                      color={errors?.handler?.message ? 'error' : 'primary'}
                      helperText={errors?.handler?.message || ''}
                      size="small"
                      value={field.value}
                      fullWidth
                      sx={{
                        '& .MuiInputBase-root': {
                          minWidth: '290px!important',
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Tooltip>
            <Tooltip title={t('common:TXT_Order_Type')} placement="top" arrow>
              <div>
                <Controller
                  name="orderType"
                  control={control}
                  rules={{ required: t('common:TXT_Order_Type_Mandatory') }}
                  render={({ field }) => (
                    <SalesOrderTypesDropdown
                      onChange={(newValue, reason) => {
                        if (reason === 'selectOption' && newValue && selectedCustomer) {
                          updateOrderDetails(
                            selectedCustomer,
                            newValue as string,
                            selectedWarehouse,
                            selectedDispatchTime,
                          );
                        }
                        field.onChange(newValue as string);
                      }}
                      id={field.name}
                      required
                      color={errors?.orderType?.message ? 'error' : 'primary'}
                      helperText={errors?.orderType?.message || ''}
                      size="small"
                      value={field.value}
                      loading={orderTypes?.loading}
                      fullWidth
                      sx={{
                        '& .MuiInputBase-root': {
                          minWidth: '290px!important',
                        },
                      }}
                      disabled={currentStep != FormStep.ORDER_DETAILS}
                    />
                  )}
                />
              </div>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSalesOrderSteps;

import { forwardRef, PropsWithChildren } from 'react';
import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material';
import { Box } from '../Box';

export type ModalProps = MuiModalProps & {
  className?: string;
};

export const Modal = forwardRef(
  (
    {
      className,
      container = document.getElementById('overlay-container'),
      component,
      children,
      ...props
    }: PropsWithChildren<ModalProps>,
    ref: React.Ref<unknown>,
  ) => {
    return (
      <MuiModal
        {...props}
        ref={ref}
        className={className}
        component={component}
        container={container}
        sx={{
          '.MuiBackdrop-root.MuiModal-backdrop': {
            top: '100px',
            left: ' 59px',
          },
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 400,
            borderRadius: '8px',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {children}
        </Box>
      </MuiModal>
    );
  },
);

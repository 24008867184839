import React from 'react';
import { Grid2 as Grid, styled, Typography } from '@mui/material';
import { ActionBar, ActionBarProps } from '../ActionBar';

import { ContextLayoutPaper } from '../ContextLayout';

const MainLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(9.625),
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const OverFlowWrapper = styled('div')(() => ({
  width: '100%',
  overflowY: 'scroll',
}));

const ApplicationToolbarWrapper = styled('div')({
  alignContent: 'center',
});

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '60px',
  padding: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  flexBasis: '60%',
  color: theme.palette.foreground[100],
  fontSize: '20px',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '28px',
}));

const FormToolbar = styled('div')({
  flexBasis: '40%',
  alignSelf: 'flex-end',
});

const Content = styled('div')<{ bottomMargin: number }>(({ theme, bottomMargin }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: theme.spacing(0, 4, 4, 4),
  height: '100%',
  marginBottom: `${bottomMargin}px`,
}));

export interface FormLayoutProps {
  slotProps?: {
    actionBar?: ActionBarProps;
    contextMenu?: {
      open: boolean;
      onClose: () => void;
      title?: string;
      children: React.ReactNode;
      width?: number;
      onWidthChange?: (width: number) => void;
    };
  };
  titleActions: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  toolbar?: React.ReactNode;
  children: React.ReactNode;
  fixedActionBar?: boolean;
}

export const FormLayout = ({
  title,
  titleActions,
  toolbar,
  children,
  actions,
  fixedActionBar = true,
  slotProps,
}: FormLayoutProps) => {
  return (
    <MainLayout className="application-bg">
      <ApplicationToolbarWrapper>{toolbar}</ApplicationToolbarWrapper>
      <ContentWrapper sx={{ overflowY: fixedActionBar ? 'hidden' : 'visible' }}>
        <OverFlowWrapper
          sx={{
            overflowY: fixedActionBar ? 'scroll' : 'visible',
            position: 'unset !important',
          }}
          className="work-area"
        >
          <Header>
            <Title>{title}</Title>
            <FormToolbar>{titleActions}</FormToolbar>
          </Header>
          <div style={{ display: 'flex' }}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              width="100%"
              sx={{ overflowX: 'auto', transition: 'all 0.3s' }}
            >
              <Grid>
                <Content bottomMargin={0}>{children}</Content>
              </Grid>
            </Grid>

            {slotProps?.contextMenu && <ContextLayoutPaper {...slotProps?.contextMenu} />}
          </div>
        </OverFlowWrapper>
        {actions && <ActionBar alignment="right">{actions}</ActionBar>}
      </ContentWrapper>
    </MainLayout>
  );
};

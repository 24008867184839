import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  styled,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { TextWeight } from '../../constants/Typography';

interface ISteps {
  label: string;
}
export interface StepperProps {
  steps: ISteps[];
  activeStep: number;
  completedStep?: boolean;
  handleStep?: (step: number) => void; // Prop for handling step changes
  nonLinear?: boolean; // prop for enabling step label click
}

interface StepIconStyleProps {
  active: boolean;
  completed: boolean;
}

const CustomStepIconRoot = styled('div')<StepIconStyleProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function CustomStepIcon(props: StepIconProps) {
  const { palette } = useTheme();

  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot color={palette?.primary?.main} active={!!active} completed={!!completed} className={className}>
      <div
        className="MuiStepIcon-text"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          height: '25px',
          width: '25px',
          textAlign: 'center',
          lineHeight: completed ? 1.4 : 1.8,
          fontWeight: TextWeight.SEMIBOLD,
          background: completed || active ? palette?.primary[400] : palette.background.paper,
          color: completed ? 'none' : active ? palette?.primary?.contrastText : palette.foreground[600],
        }}
      >
        {completed ? (
          <SvgIcon component={CheckCircleOutlineIcon} sx={{ color: palette['primary'].main, fontSize: 18 }} />
        ) : (
          props.icon
        )}
      </div>
    </CustomStepIconRoot>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.foreground[100],
    borderRadius: '4px',
  },
  padding: '8px',
  cursor: 'pointer',
}));

export const Stepper: React.FC<StepperProps> = ({ steps, activeStep, completedStep, nonLinear, handleStep }) => {
  const { palette } = useTheme();

  return (
    <MuiStepper connector={<StepConnector />} nonLinear={nonLinear} activeStep={activeStep}>
      {steps.map((step: ISteps, index: number) => (
        <Step key={step?.label} completed={completedStep}>
          <StyledBox sx={{ pointerEvents: nonLinear ? 'cursor' : 'none' }} onClick={() => handleStep?.(index)}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Typography color={index === activeStep ? palette?.primary[500] : palette?.foreground[500]}>
                {step?.label}
              </Typography>
            </StepLabel>
          </StyledBox>
        </Step>
      ))}
    </MuiStepper>
  );
};

export const headerColumns = [
  { key: 'warehouse', name: 'Warehouse', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'date', name: 'Disp date', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shipmentNumber', name: 'Shipment number', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'description', name: 'Description', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'route', name: 'Route', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'departure', name: 'Departure', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'time', name: 'Dispatch time', resizable: true, width: 100, isVisible: true, draggable: true },
  {
    key: 'custWithOrders',
    name: 'Customers with placed orders',
    resizable: true,
    width: 100,
    isVisible: true,
    draggable: true
  },
  { key: 'linesLess30', name: 'Lines with status < 30', resizable: true, width: 100, isVisible: true, draggable: true },
  {
    key: 'linesEqual30',
    name: 'Lines with status = 30',
    resizable: true,
    width: 100,
    isVisible: true,
    draggable: true
  },
  {
    key: 'linesGreater30',
    name: 'Lines with status > 30',
    resizable: true,
    width: 100,
    isVisible: true,
    draggable: true
  },
  { key: 'vehicle', name: 'Vehicle', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'driver', name: 'Driver', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'maxWeight', name: 'Maximum weight', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'accWeight', name: 'Accumulated weight', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'maxVolume', name: 'Maximum volume', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'accVolume', name: 'Accumulated volume', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'cutOffDate', name: 'Cut-off date', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'cutOffTime', name: 'Cut-off time', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shipmentStatus', name: 'Status', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shipmentType', name: 'Shipment type', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'loadingZone', name: 'Loading zone', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'loadingLocation', name: 'Loading location', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shippingAgent', name: 'Shipping agent', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'mannerOfTransp', name: 'Manner of transport', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'handler', name: 'Handler', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'creationDate', name: 'Created date', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'manCreated', name: 'Manually created', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'minValue', name: 'Minimum value', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'maxValue', name: 'Maximum value', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'accValue', name: 'Accumulated value', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'pickRelDate', name: 'Pick release date', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'pickRelTime', name: 'Pick release time', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shippedDate', name: 'Shipped date', resizable: true, width: 100, isVisible: true, draggable: true },
  { key: 'shippedTime', name: 'Shipped time', resizable: true, width: 100, isVisible: true, draggable: true }
];

export const lineColumns = [
  { key: 'orderStatus', name: 'Order status', resizable: true, width: 100, isVisible: true },
  { key: 'order', name: 'Order', resizable: true, width: 100, isVisible: true },
  { key: 'orderLine', name: 'Line', resizable: true, width: 100, isVisible: true },
  { key: 'orderType', name: 'Order type', resizable: true, width: 100, isVisible: true },
  { key: 'customer', name: 'Customer', resizable: true, width: 100, isVisible: true },
  { key: 'name', name: 'Name', resizable: true, width: 100, isVisible: true },
  { key: 'item', name: 'Item', resizable: true, width: 100, isVisible: true },
  { key: 'description', name: 'Description', resizable: true, width: 100, isVisible: true },
  { key: 'orderQuantity', name: 'Order quantity', resizable: true, width: 100, isVisible: true },
  { key: 'confirmedQuantity', name: 'Confirmed quantity', resizable: true, width: 100, isVisible: true },
  { key: 'qtyOnHand', name: 'Quantity on hand', resizable: true, width: 100, isVisible: true },
  { key: 'qtyOnPicklist', name: 'Quantity on pick list ', resizable: true, width: 100, isVisible: true },
  { key: 'stockUnit', name: 'Stock unit', resizable: true, width: 100, isVisible: true },
  { key: 'orderQuantitySalesUnit', name: 'Order quantity sales unit', resizable: true, width: 100, isVisible: true },
  { key: 'confirmedQuantitySalesUnit', name: 'Confirmed qty sales unit', resizable: true, width: 100, isVisible: true },
  { key: 'salesUnit', name: 'Sales unit', resizable: true, width: 100, isVisible: true },
  { key: 'workStationId', name: 'Work station id/job name', resizable: true, width: 100, isVisible: true },
  { key: 'dispatchTime', name: 'Dispatch time', resizable: true, width: 100, isVisible: true },
  { key: 'totalWeight', name: 'Weight', resizable: true, width: 100, isVisible: true },
  { key: 'totalVolume', name: 'Volume', resizable: true, width: 100, isVisible: true },
  { key: 'picklistNumber', name: 'Pick list', resizable: true, width: 100, isVisible: true },
  { key: 'handler', name: 'Handler', resizable: true, width: 100, isVisible: true },
  { key: 'salesman', name: 'Salesman', resizable: true, width: 100, isVisible: true },
  { key: 'warehouse', name: 'Warehouse', resizable: true, width: 100, isVisible: true },
  { key: 'dispatchDateAdjustment', name: 'Date adjustment', resizable: true, width: 100, isVisible: true }
];

export const addressColumns = [
  { key: 'shipment', name: 'Shipment no', resizable: true, width: 100, isVisible: true },
  { key: 'delSeq', name: 'Delivery sequence', resizable: true, width: 100, isVisible: true },
  { key: 'customer', name: 'Customer', resizable: true, width: 100, isVisible: true },
  { key: 'addrSeq', name: 'Address sequence', resizable: true, width: 100, isVisible: true },
  { key: 'addrNumber', name: 'Address no', resizable: true, width: 100, isVisible: true },
  { key: 'customerName', name: 'Name', resizable: true, width: 100, isVisible: true },
  { key: 'addrLine4', name: 'Address line 4', resizable: true, width: 100, isVisible: true },
  { key: 'addrLine1', name: 'Address line 1', resizable: true, width: 100, isVisible: true },
  { key: 'addrLine2', name: 'Address line 2', resizable: true, width: 100, isVisible: true },
  { key: 'addrLine3', name: 'Address line 3', resizable: true, width: 100, isVisible: true },
  { key: 'postalCode', name: 'Postal code', resizable: true, width: 100, isVisible: true },
  { key: 'county', name: 'County', resizable: true, width: 100, isVisible: true },
  { key: 'country', name: 'Country', resizable: true, width: 100, isVisible: true },
  { key: 'stateProvince', name: 'State/Province', resizable: true, width: 100, isVisible: true },
  { key: 'destination', name: 'Destination', resizable: true, width: 100, isVisible: true },
  { key: 'accWeight', name: 'Accumulated weight', resizable: true, width: 100, isVisible: true },
  { key: 'accVolume', name: 'Accumulated volume', resizable: true, width: 100, isVisible: true },
  { key: 'linesLess30', name: 'Lines with status < 30', resizable: true, width: 100, isVisible: true },
  { key: 'linesEqual30', name: 'Lines with status = 30', resizable: true, width: 100, isVisible: true },
  { key: 'linesGreater30', name: 'Lines with status > 30', resizable: true, width: 100, isVisible: true },
  { key: 'accValue', name: 'Accumulated value', resizable: true, width: 100, isVisible: true },
  { key: 'containerSimulated', name: 'No of containers simulated', resizable: true, width: 100, isVisible: true },
  { key: 'containerAssigned', name: 'No of containers assigned', resizable: true, width: 100, isVisible: true },
  { key: 'containerConfirmed', name: 'No of containers confirmed', resizable: true, width: 100, isVisible: true },
  { key: 'workSeq', name: 'Work sequence', resizable: true, width: 100, isVisible: true }
];

export const colMenuFlag = {
  headerMenulist: false,
  lineMenuList: false,
  addressMenuList: false
};

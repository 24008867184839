import styled from '@emotion/styled';
import { Button, useCustomSnackbar, useIptorTranslation } from '@iptor/base';
import { FormLayout, useOpenAPIData } from '@iptor/business';
import { Box, Grid2 as Grid } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CreateSalesOrderSteps, { FormStep } from '../../components/CreateSalesOrderSteps';
import { Order } from '../../constants';
import OrderItemsTable from './components/OrderItemsTable';
import OrderSummary from './components/OrderSummary';
import OrderInsights from '../../components/OrderInsights';
import TableProcedures from './components/OrderLineTableProcedures';

const Container = styled(Box)`
  width: 100%;
`;

const EditSalesOrderItems = () => {
  const { t } = useIptorTranslation();
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const orderLineRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const createProcess = searchParams.get('create') === 'true';
  const { closeAllSnackbars, closeVariantSnackbar } = useCustomSnackbar();
  const { reset, control, setValue, watch, getValues } = useForm<Partial<Order>>();
  const orderDetailsApi = useOpenAPIData('internal.v1.base-orders', 'GET /salesOrders/{order}', { order: +orderId });

  const refreshTable = useCallback(() => {
    if (orderLineRef.current) {
      orderLineRef?.current?.handleRefresh();
    }
    orderDetailsApi.refresh();
  }, [orderLineRef.current]);

  useEffect(() => {
    return () => {
      closeVariantSnackbar('error');
    };
  }, []);

  return (
    <FormLayout
      title={t('common:TXT_Item_List')}
      actions={[
        <Button
          variant="outlined"
          size="medium"
          id="close"
          onClick={() => {
            closeAllSnackbars();
            if (createProcess) {
              history.go(-2);
            } else {
              history.goBack();
            }
          }}
        >
          {t('common:TXT_Close')}
        </Button>,
        <>
          {createProcess && (
            <Button
              variant="contained"
              id="next"
              size="medium"
              onClick={() => {
                closeAllSnackbars();
                history.push(`/orders/${+orderId}/header?create=true`);
              }}
            >
              {t('common:TXT_Next')}
            </Button>
          )}
        </>,
      ]}
    >
      <Container>
        {createProcess && !orderDetailsApi.loading && (
          <CreateSalesOrderSteps
            watch={watch}
            setValue={setValue}
            reset={reset}
            control={control}
            getValues={getValues}
            currentStep={FormStep.ADD_ITEMS}
            order={orderDetailsApi.response?.data?.data}
          />
        )}
        <OrderInsights order={orderDetailsApi.response?.data?.data as Order} removeBottomBorder={true} />
        <TableProcedures order={orderDetailsApi.response?.data?.data as Order} refreshTable={refreshTable} />
        <Grid container direction="column" spacing={1}>
          <OrderItemsTable
            ref={orderLineRef}
            refreshOrder={() => orderDetailsApi.refresh()}
            order={orderDetailsApi.response?.data?.data as Order}
          />
          <OrderSummary order={orderDetailsApi.response?.data?.data as Order} />
        </Grid>
      </Container>
    </FormLayout>
  );
};

export default EditSalesOrderItems;

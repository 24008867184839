import { Action, combineReducers, configureStore, EnhancedStore, Reducer, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  editSalesOrderItemsReducer,
  EditSalesOrderItemsState,
} from '../forms/SalesOrderItems/redux/SalesOrderItems.slice';

const rootReducer = combineReducers({
  editSalesOrderItems: editSalesOrderItemsReducer as Reducer<EditSalesOrderItemsState>,
});

type RootState = ReturnType<typeof rootReducer>;

const store: EnhancedStore<RootState> = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<ThunkDispatch<RootState, unknown, Action>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store as OrderState };

import {
  Accordion,
  Checkbox,
  CurrencyField,
  NumericField,
  Text,
  TextField,
  useClientSettings,
  useIptorTranslation,
} from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { OperationResponseDataData, VatHandlingCodesDropdown } from '@iptor/business';
import DiscountCardsSection from '../../../components/discounts/DiscountCardsSection';

export type PriceVATTaxProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function PriceVATTax({ orderLineInfo, control }: PriceVATTaxProps) {
  const { t } = useIptorTranslation();
  const { formatCurrency, currency } = useClientSettings();
  return (
    <Accordion
      headingTitle={t('common:TXT_Pricing_Vat_Tax')}
      moreInfo={
        <>
          <Grid container spacing={2}>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_netLinePr')}`}</Text>
              <Text id="netPriceInSystemCurrencyText" variant="body1">
                {formatCurrency({ amount: orderLineInfo.netPriceInSystemCurrency, currency }) ||
                  formatCurrency({ amount: 0, currency })}
              </Text>
            </Grid>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_netLineAm')}`}</Text>
              <Text id="netSalesValue"variant="body1">
                {formatCurrency({ amount: orderLineInfo.netSalesValue, currency }) ||
                  formatCurrency({ amount: 0, currency })}
              </Text>
            </Grid>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_expectedRebAmount')}`}</Text>
              <Text id="expectedRebateAmount" variant="body1">
                {formatCurrency({ amount: orderLineInfo.expectedRebateAmount, currency }) ||
                  formatCurrency({ amount: 0, currency })}
              </Text>
            </Grid>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_margLineCost')}`}</Text>
              <Text id="marginCostValueSystCurr" variant="body1">
                {formatCurrency({ amount: orderLineInfo.marginCostValueSystCurr, currency }) ||
                  formatCurrency({ amount: 0, currency })}
              </Text>
            </Grid>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_smsRebate')}`}</Text>
              <Text id="smsRebateInSystemCurrency" variant="body1">
                {formatCurrency({ amount: orderLineInfo.smsRebateInSystemCurrency, currency }) ||
                  formatCurrency({ amount: 0, currency })}
              </Text>
            </Grid>
            <Grid size={3}>
              <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Price_Code')}`}</Text>
              <Text variant="body1" id="itemPriceCode">
                {orderLineInfo.itemPriceCode || ' '}
              </Text>
            </Grid>
            <Grid size={3} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                sx={{
                  paddingLeft: 0,
                }}
                value={orderLineInfo.orderSummaryDiscounts}
                checked={!!orderLineInfo.orderSummaryDiscounts}
                id="orderSummaryDiscounts"
                disabled
              />
              <Text variant="body1">{`${t('sales_orders:ITEM_INFO_orderSumDisc')}`}</Text>
            </Grid>
            <Grid size={3} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                sx={{
                  paddingLeft: 0,
                }}
                id="manuallyPricedAtCreation"
                value={orderLineInfo.manuallyPricedAtCreation}
                checked={!!orderLineInfo.manuallyPricedAtCreation}
                disabled
              />
              <Text variant="body1">{`${t('sales_orders:ITEM_INFO_manPriced')}`}</Text>
            </Grid>
          </Grid>
        </>
      }
    >
      <Text
        variant="h6"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        {t('common:TXT_Pricing')}
      </Text>
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="salesPrice"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CurrencyField
                {...field}
                fullWidth
                error={!!error}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Sales_Price')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
                currency={(orderLineInfo.salesPrimaryCurrencyISO || orderLineInfo.salesPrimaryCurrency) as any}
                placement="start"
              />
            )}
          />
        </Grid>
        <Grid alignContent={'end'} size={3}>
          <Controller
            name="unit"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t('common:TXT_Unit')}
                {...field}
                id={field.name}
                error={!!error}
                helperText={error ? error.message : ''}
                fullWidth
                disabled
                slotProps={{
                  inputLabel: {
                    disabled: false,
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    border: 'none',
                    outline: 'none',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="grossMargin"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                error={!!error}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Gross_Margin')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid size={3} alignContent="flex-end">
          <Controller
            name={'freeOfCharge'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                id={field.name}
                label={t('sales_orders:ITEM_INFO_FOC')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
      </Grid>
      <Text
        variant="h6"
        sx={(theme) => ({
          marginY: theme.spacing(3),
        })}
      >
        {t('common:TXT_Vat_Tax')}
      </Text>
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="vatHandlingCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <VatHandlingCodesDropdown
                value={field.value}
                onChange={(value) => field.onChange(value)}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_VAT_Handling_Codes')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="includedVATHandlingCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <VatHandlingCodesDropdown
                fullWidth
                color={error ? 'error' : 'primary'}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Included_VAT_Handling_Codes')}
                value={field.value}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>

        <Grid size={2} alignContent="flex-end">
          <Controller
            name={'vatBased'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                id={field.name}
                label={t('common:TXT_VAT_Based')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
        <Grid size={2} alignContent="flex-end">
          <Controller
            name={'vatIncluded'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                id={field.name}
                checked={!!field.value}
                label={t('common:TXT_VAT_Included')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <DiscountCardsSection
          order={orderLineInfo.order}
          orderLine={orderLineInfo.orderLine}
          orderCurrency={orderLineInfo.salesPrimaryCurrencyISO || orderLineInfo.salesPrimaryCurrency}
        />
      </Grid>
    </Accordion>
  );
}

export default PriceVATTax;

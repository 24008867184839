import {
  OpenAPIClientAxios,
  OpenAPIClient,
  Document,
  AxiosRequestConfig,
  ParamsArray,
  SingleParam,
} from 'openapi-client-axios';
import { Context, createContext } from 'react';

import V1BaseTablesDefinition from './resources/openapi-base-tables-v1.json';
import V1BaseOrdersDefinition from './resources/openapi-base-orders-v1.json';
import V1BaseGeneralDefinition from './resources/openapi-base-general-v1.json';
import V1BaseInventoryDefinition from './resources/openapi-base-inventory-v1.json';
import V1BaseFinanceDefinition from './resources/openapi-base-finance-v1.json';
import V1BaseInterfacesDefinition from './resources/openapi-base-interfaces-v1.json';
import V1BaseManufacturingDefinition from './resources/openapi-base-manufacturing-v1.json';
import V1BaseShipmentsDefinition from './resources/openapi-base-shipments-v1.json';
import V1USPharmaDefinition from './resources/openapi-us-pharma-v1.json';

import {
  OperationMethods as V1BaseTablesOperations,
  PathsDictionary as V1BaseTablesPaths,
} from './types/openapi/v1/base-tables';
import {
  OperationMethods as V1BaseOrdersOperations,
  PathsDictionary as V1BaseOrdersPaths,
} from './types/openapi/v1/base-orders';
import {
  OperationMethods as V1BaseGeneralOperations,
  PathsDictionary as V1BaseGeneralPaths,
} from './types/openapi/v1/base-general';
import {
  OperationMethods as V1BaseInventoryOperations,
  PathsDictionary as V1BaseInventoryPaths,
} from './types/openapi/v1/base-inventory';
import {
  OperationMethods as V1BaseFinanceOperations,
  PathsDictionary as V1BaseFinancePaths,
} from './types/openapi/v1/base-finance';
import {
  OperationMethods as V1BaseInterfacesOperations,
  PathsDictionary as V1BaseInterfacesPaths,
} from './types/openapi/v1/base-interfaces';
import {
  OperationMethods as V1BaseManufacturingOperations,
  PathsDictionary as V1BaseManufacturingPaths,
} from './types/openapi/v1/base-manufacturing';
import {
  OperationMethods as V1BaseShipmentsOperations,
  PathsDictionary as V1BaseShipmentsPaths,
} from './types/openapi/v1/base-shipments';
import {
  OperationMethods as V1USPharmaOperations,
  PathsDictionary as V1USPharmaPaths,
} from './types/openapi/v1/us-pharma';

// ==============================================================================================================
// Typing
// ==============================================================================================================
type INTERNAL_V1_BASE_TABLES = 'internal.v1.base-tables';
type INTERNAL_V1_BASE_ORDERS = 'internal.v1.base-orders';
type INTERNAL_V1_BASE_GENERAL = 'internal.v1.base-general';
type INTERNAL_V1_BASE_INVENTORY = 'internal.v1.base-inventory';
type INTERNAL_V1_BASE_FINANCE = 'internal.v1.base-finance';
type INTERNAL_V1_BASE_INTERFACES = 'internal.v1.base-interfaces';
type INTERNAL_V1_BASE_MANUFACTURING = 'internal.v1.base-manufacturing';
type INTERNAL_V1_BASE_SHIPMENTS = 'internal.v1.base-shipments';
type INTERNAL_V1_US_PHARMA = 'internal.v1.us-pharma';

export type OpenAPIId =
  | INTERNAL_V1_BASE_TABLES
  | INTERNAL_V1_BASE_ORDERS
  | INTERNAL_V1_BASE_GENERAL
  | INTERNAL_V1_BASE_INVENTORY
  | INTERNAL_V1_BASE_FINANCE
  | INTERNAL_V1_BASE_INTERFACES
  | INTERNAL_V1_BASE_MANUFACTURING
  | INTERNAL_V1_BASE_SHIPMENTS
  | INTERNAL_V1_US_PHARMA;

export type OpenAPIClientConfig = {
  id: OpenAPIId;
  constructorOptions: ConstructorParameters<typeof OpenAPIClientAxios>[0];
  clientUrl?: string;
  version?: string;
  getToken?: () => Promise<string | undefined>;
  requestInterceptor?: (
    config: AxiosRequestConfig,
    openAPIClientConfig: OpenAPIClientConfig,
  ) => Promise<AxiosRequestConfig>;
};
export type OpenAPIClientConfigs = Record<OpenAPIId, OpenAPIClientConfig>;

export interface ClientsOperationMethods {
  ['internal.v1.base-tables']: V1BaseTablesOperations;
  ['internal.v1.base-orders']: V1BaseOrdersOperations;
  ['internal.v1.base-general']: V1BaseGeneralOperations;
  ['internal.v1.base-inventory']: V1BaseInventoryOperations;
  ['internal.v1.base-finance']: V1BaseFinanceOperations;
  ['internal.v1.base-interfaces']: V1BaseInterfacesOperations;
  ['internal.v1.base-manufacturing']: V1BaseManufacturingOperations;
  ['internal.v1.base-shipments']: V1BaseShipmentsOperations;
  ['internal.v1.us-pharma']: V1USPharmaOperations;

  /*  REMARKS:
      ========
      1) The used component 'openapi-client-axios' REQUIRES A FILE AT RUN-TIME containing the open API specification (json or yaml). At RUN-TIME 
      (during intialization) the component reads the open API specification, creates a new axios instance and adds an extra method on the axios
      instance FOR EACH SPECIFIED "operationId" (with a parameter structure conform to the open API definitions?).

      Optionally, the component supports the generation of the corresponding typing that can be used AT DESIGN-TIME

      This interface is used to link each openAPI client to the strong typed OperationMethods generated for that client. If no client specific 
      typing is generated, link that client to the more generic type: UnknownOperationMethods (eg ['external.forecast']: UnknownOperationMethods;)

      Without the generated typings, the defined operation ids are still added as method names on the axios instance at RUN-TIME, but the method 
      names are "not visible" at DESIGN-TIME. However by linking this client to "UnknownOperationMethods", you are defining the type of the axios
      instance as AxiosInstance & UnknownOperationMethods & ... and this will allow the developper to specify "unknown methods" at DESIGN-TIME
      with 3 arguments (optional "light-typed" parameters, optional data (any) & optional axios request config).

      So it allows you to call "any" methods at design-time and if they correspond to existing operationids of the open API specification, they 
      will be executed as expected, if not the application will crash at run-time (method not defined). If for existing methos invalid parameters
      are specified, an error response from the server should be expected

      2) If any client in this interface is missing, you probably get one of following typescript errors (or both):
        ° "Type 'T' cannot be used to index type 'ClientsOperationMethods'."
        ° "'client' is of type 'unknown'."
      In this case: add entries as needed or make sure you to check for any typing errors (case sensitive !)
  */
}
export interface ClientsPathsDictionary {
  ['internal.v1.base-tables']: V1BaseTablesPaths;
  ['internal.v1.base-orders']: V1BaseOrdersPaths;
  ['internal.v1.base-general']: V1BaseGeneralPaths;
  ['internal.v1.base-inventory']: V1BaseInventoryPaths;
  ['internal.v1.base-finance']: V1BaseFinancePaths;
  ['internal.v1.base-interfaces']: V1BaseInterfacesPaths;
  ['internal.v1.base-manufacturing']: V1BaseManufacturingPaths;
  ['internal.v1.base-shipments']: V1BaseShipmentsPaths;
  ['internal.v1.us-pharma']: V1USPharmaPaths;
  /* If no client specific typing is generated, link that client here to the more generic type: UnknownPathsDictionary */
}

export type OperationMethods<T extends OpenAPIId> = ClientsOperationMethods[T];
export type PathsDictionary<T extends OpenAPIId> = ClientsPathsDictionary[T];
export type Client<T extends OpenAPIId> = OpenAPIClient<OperationMethods<T>, PathsDictionary<T>>;

export type OperationId<T extends OpenAPIId> = keyof OperationMethods<T>;
export type OperationMethod<T extends OpenAPIId, K extends OperationId<T>> = OperationMethods<T>[K] extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any
  ? OperationMethods<T>[K]
  : never;
export type OperationMethodArgs<T extends OpenAPIId, K extends OperationId<T>> = Parameters<OperationMethod<T, K>>;
export type OperationParameters<T extends OpenAPIId, K extends OperationId<T>> = OperationMethodArgs<T, K>[0];
export type OperationParametersObject<T extends OpenAPIId, K extends OperationId<T>> = Exclude<
  OperationParameters<T, K>,
  ParamsArray | SingleParam
>;
export type OperationData<T extends OpenAPIId, K extends OperationId<T>> = OperationMethodArgs<T, K>[1];
export type OperationConfig<T extends OpenAPIId, K extends OperationId<T>> = OperationMethodArgs<T, K>[2];
export type OperationResponse<T extends OpenAPIId, K extends OperationId<T>> = Awaited<
  Promise<PromiseLike<ReturnType<OperationMethod<T, K>>>>
>;

export type OperationResponseData<T extends OpenAPIId, K extends OperationId<T>> = OperationResponse<T, K>['data'];
export type OperationResponseControl<T extends OpenAPIId, K extends OperationId<T>> = OperationResponse<
  T,
  K
>['data']['control'];
export type OperationResponseDataData<T extends OpenAPIId, K extends OperationId<T>> = OperationResponse<
  T,
  K
>['data']['data'];
export type OperationResponseItems<T extends OpenAPIId, K extends OperationId<T>> = OperationResponse<
  T,
  K
>['data']['data']['items'];
export type OperationResponseItemsRow<T extends OpenAPIId, K extends OperationId<T>> = OperationResponse<
  T,
  K
>['data']['data']['items'][0];
export type OperationResponseItemsColumnId<
  T extends OpenAPIId,
  K extends OperationId<T>,
> = keyof OperationResponseItemsRow<T, K>;

export type GetClientContextType = (openAPIId: OpenAPIId) => {
  openAPIClientPromise: Promise<Client<OpenAPIId>>;
  onOperationMethodRequest: (operationId: string) => void;
  onOperationMethodResponse: (operationId: string) => void;
};
export type OpenAPIProviderContextType = {
  getClientContext: GetClientContextType | undefined;
};
export const OpenAPIProviderContext: Context<OpenAPIProviderContextType> = createContext<OpenAPIProviderContextType>({
  getClientContext: undefined,
});

// ==============================================================================================================
// Helpers/configuration
// ==============================================================================================================
export function getError(error: unknown): Error {
  try {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error(`Unkown error (${error})`);
    }
  } catch (e: unknown) {
    if (e instanceof Error) {
      return new Error(`OpenAPIId: Unexpected error (parsing error: ${e})`);
    } else {
      return new Error(`OpenAPIId: Unexpected error (parsing error)`);
    }
  }
}

export namespace Clients {
  export namespace Internal {
    export namespace Base {
      export namespace V1 {
        export const Tables = 'internal.v1.base-tables';
        export const Orders = 'internal.v1.base-orders';
        export const General = 'internal.v1.base-general';
        export const Inventory = 'internal.v1.base-inventory';
        export const Finance = 'internal.v1.base-finance';
        export const Interfaces = 'internal.v1.base-interfaces';
        export const Manufacturing = 'internal.v1.base-manufacturing';
        export const Shipments = 'internal.v1.base-shipments';
      }
      export namespace V2 {}
    }
    export namespace US {
      export namespace V1 {
        export const Pharma = 'internal.v1.us-pharma';
      }
    }
  }
  export namespace External {}
}
export namespace Defaults {
  export namespace Internal {
    export async function requestInterceptor(config: AxiosRequestConfig, openAPIClientConfig: OpenAPIClientConfig) {
      // Build url
      // =========
      let prependUrl = '/openapi/internal/rest'; // Remark: harcoded, but if node server proxy is used, configuration files can be used to replace the hardcoded part
      if (openAPIClientConfig.clientUrl) prependUrl += `/${openAPIClientConfig.clientUrl}`;
      if (openAPIClientConfig.version) prependUrl += `/${openAPIClientConfig.version}`;
      config.url = prependUrl + config.url; // --> allows node server to convert to eg: http://iptpdk701.ibs.net:9150/rest/base-tables/v1/areas?limit=5

      // Add headers
      // ===========

      // --> Initialize
      if (!config.headers) config.headers = {};

      // --> Company
      const company = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';
      config.headers['company'] = company?.id; // BEWARE without proxy: config.headers['Env-Company'] = company?.id;

      // --> Bearer token
      if (openAPIClientConfig.getToken) {
        const token = await openAPIClientConfig.getToken();
        config.headers['authorization'] = 'Bearer ' + (token || '');
      }

      // Return result
      // ============
      return config;
    }
    export function getConstructorOptions(
      definition: string | Document,
    ): ConstructorParameters<typeof OpenAPIClientAxios>[0] {
      const redirectToNodeServer = window.location.origin;
      return { definition, withServer: redirectToNodeServer };
    }
  }
  export namespace External {
    export function getConstructorOptions(
      definition: string | Document,
    ): ConstructorParameters<typeof OpenAPIClientAxios>[0] {
      const redirectToNodeServer = window.location.origin;
      return { definition, withServer: redirectToNodeServer };
    }
  }

  export const openAPIClientConfigs: OpenAPIClientConfigs = {
    ['internal.v1.base-tables']: {
      id: 'internal.v1.base-tables',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseTablesDefinition as Document),
      clientUrl: 'base-tables',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    'internal.v1.base-orders': {
      id: 'internal.v1.base-orders',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseOrdersDefinition as Document),
      clientUrl: 'base-orders',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-general']: {
      id: 'internal.v1.base-general',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseGeneralDefinition as Document),
      clientUrl: 'base-general',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-inventory']: {
      id: 'internal.v1.base-inventory',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseInventoryDefinition as Document),
      clientUrl: 'base-inventory',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-finance']: {
      id: 'internal.v1.base-finance',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseFinanceDefinition as Document),
      clientUrl: 'base-finance',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-interfaces']: {
      id: 'internal.v1.base-interfaces',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseInterfacesDefinition as Document),
      clientUrl: 'base-interfaces',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-manufacturing']: {
      id: 'internal.v1.base-manufacturing',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseManufacturingDefinition as Document),
      clientUrl: 'base-manufacturing',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.base-shipments']: {
      id: 'internal.v1.base-shipments',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1BaseShipmentsDefinition as Document),
      clientUrl: 'base-shipments',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
    ['internal.v1.us-pharma']: {
      id: 'internal.v1.us-pharma',
      constructorOptions: Defaults.Internal.getConstructorOptions(V1USPharmaDefinition as Document),
      clientUrl: 'us-pharma',
      version: 'v1',
      requestInterceptor: Defaults.Internal.requestInterceptor,
    },
  };
}

// ==============================================================================================================
// OpenAPIManager
// ==============================================================================================================
type OpenAPIInfo = {
  openAPIClientAxios: OpenAPIClientAxios;
  openAPIClientConfig: OpenAPIClientConfig;
  openAPIClientPromise: Promise<OpenAPIClient>;
};

export type ExecuteAllSettledArgsAll<T extends OpenAPIId, K extends OperationId<T>, A> = {
  params?: OperationParameters<T, K>;
  data?: OperationData<T, K>;
  config?: OperationConfig<T, K>;
  additionalInfo?: A;
}[];
export type ElementResultRejected<T extends OpenAPIId, K extends OperationId<T>, A> = {
  args: ExecuteAllSettledArgsAll<T, K, A>[0];
  status: 'rejected';
  error: Error;
};
export type ElementResultFullfilled<T extends OpenAPIId, K extends OperationId<T>, A> = {
  args: ExecuteAllSettledArgsAll<T, K, A>[0];
  status: 'fullfilled';
  response: OperationResponse<T, K>;
};
export type ExecuteAllSettledResult<T extends OpenAPIId, K extends OperationId<T>, A> = {
  getAll: () => (ElementResultRejected<T, K, A> | ElementResultFullfilled<T, K, A>)[];
  getRejected: () => ElementResultRejected<T, K, A>[];
  getFullfilled: () => ElementResultFullfilled<T, K, A>[];
  someRejected: boolean;
  someFullfilled: boolean;
};

export class OpenAPIManager {
  private defaultOpenAPIClientConfigs: OpenAPIClientConfigs;
  private openAPIInfos: Record<string, OpenAPIInfo> = {};
  public constructor(defaultOpenAPIClientConfigs: OpenAPIClientConfigs) {
    this.defaultOpenAPIClientConfigs = defaultOpenAPIClientConfigs;
  }
  private getOpenAPIInfo = (openAPIId: OpenAPIId): OpenAPIInfo => {
    if (this.openAPIInfos[openAPIId]) {
      return this.openAPIInfos[openAPIId];
    } else {
      return this.createOpenAPIInfo(openAPIId);
    }
  };
  private createOpenAPIInfo = (openAPIId: OpenAPIId): OpenAPIInfo => {
    const openAPIClientConfig = { ...this.defaultOpenAPIClientConfigs[openAPIId] };
    const openAPIClientAxios = new OpenAPIClientAxios(openAPIClientConfig.constructorOptions);
    const openAPIInfo = {
      openAPIClientAxios,
      openAPIClientConfig,
      openAPIClientPromise: this.getPromiseOfAnInitializedClient(openAPIClientAxios, openAPIClientConfig),
    };
    this.openAPIInfos[openAPIId] = openAPIInfo;
    return openAPIInfo;
  };
  private async getPromiseOfAnInitializedClient(
    openAPIClientAxios: OpenAPIClientAxios,
    openAPIClientConfig: OpenAPIClientConfig,
  ) {
    const axiosInstance = await openAPIClientAxios.init();
    axiosInstance.defaults.paramsSerializer = {
      encode: (param) => encodeURIComponent(param),
    };
    axiosInstance.interceptors.request.use(async (config) => {
      if (openAPIClientConfig.requestInterceptor) {
        return await openAPIClientConfig.requestInterceptor(config, openAPIClientConfig);
      } else {
        return config;
      }
    });
    return axiosInstance;
  }
  public async getClient<T extends OpenAPIId>(openAPIId: T): Promise<Client<T>> {
    const client = await this.getOpenAPIInfo(openAPIId).openAPIClientPromise;
    return client as unknown as Client<T>;
    // BUG in component: concurrent calls to init & getClient all return a different axios instance (until first promise is resolved)
    // ==> always return the the same promise ==> always same axiosInstance !!  NOT: return this.getOpenAPIInfo(openAPIId).openAPIClientAxios.getClient<Client<T>>();
  }
  public async getOperationMethod<T extends OpenAPIId, K extends OperationId<T>>(
    openAPIId: T,
    operationId: K,
  ): Promise<OperationMethod<T, K>> {
    const client = await this.getClient(openAPIId);
    return client[operationId] as OperationMethod<T, K>;
  }
  public async execute<T extends OpenAPIId, K extends OperationId<T>>(
    openAPIId: T,
    operationId: K,
    params?: OperationParameters<T, K>,
    data?: OperationData<T, K>,
    config?: OperationConfig<T, K>,
  ): Promise<OperationResponse<T, K>> {
    const operationMethod = await this.getOperationMethod(openAPIId, operationId);
    return await operationMethod(params, data, config);
  }
  public async executeAllSettled<T extends OpenAPIId, K extends OperationId<T>, A = unknown>(
    openAPIId: T,
    operationId: K,
    argsAll: ExecuteAllSettledArgsAll<T, K, A>,
  ): Promise<ExecuteAllSettledResult<T, K, A>> {
    const baseResults = await Promise.allSettled(
      argsAll.map((args) => this.execute(openAPIId, operationId, args.params, args.data, args.config)),
    );

    const resultSummary = { someRejected: false, someFullfilled: false };
    const results = baseResults.map((result, i) => {
      if (result.status === 'rejected') {
        resultSummary.someRejected = true;
        return {
          args: argsAll[i],
          status: 'rejected',
          error: getError(result.reason),
        } as ElementResultRejected<T, K, A>;
      } else {
        resultSummary.someFullfilled = true;
        return {
          args: argsAll[i],
          status: 'fullfilled',
          response: result.value,
        } as ElementResultFullfilled<T, K, A>;
      }
    });

    return {
      getAll: () => results,
      getRejected: () => results.filter((result) => result.status === 'rejected') as ElementResultRejected<T, K, A>[],
      getFullfilled: () =>
        results.filter((result) => result.status === 'fullfilled') as ElementResultFullfilled<T, K, A>[],
      someRejected: resultSummary.someRejected,
      someFullfilled: resultSummary.someFullfilled,
    };
  }
  public changeOpenAPIConfig<T extends OpenAPIId>(
    openAPIId: T,
    openAPIConfigAttributes: Omit<Partial<OpenAPIClientConfig>, 'id'>,
  ) {
    if (this.openAPIInfos[openAPIId]?.openAPIClientConfig) {
      this.openAPIInfos[openAPIId].openAPIClientConfig = {
        ...this.openAPIInfos[openAPIId].openAPIClientConfig,
        ...openAPIConfigAttributes,
      };
    }
    if (this.defaultOpenAPIClientConfigs[openAPIId]) {
      this.defaultOpenAPIClientConfigs[openAPIId] = {
        ...this.defaultOpenAPIClientConfigs[openAPIId],
        ...openAPIConfigAttributes,
      };
    }
  }
  public changeAllOpenAPIConfigs(openAPIConfigAttributes: Omit<Partial<OpenAPIClientConfig>, 'id'>) {
    Object.keys(this.defaultOpenAPIClientConfigs).forEach((openAPIId) => {
      this.changeOpenAPIConfig(openAPIId as OpenAPIId, openAPIConfigAttributes);
    });
  }
}
export const openAPIManager = new OpenAPIManager(Defaults.openAPIClientConfigs);

import advanceMoney from './advanceMoney';
import alert01 from './alert01';
import alertCircle from './alertCircle';
import analysisTextLink from './analysisTextLink';
import arrowDown01Round from './arrowDown01Round';
import arrowLeft01Sharp from './arrowLeft01Sharp';
import arrowLeftRightRound from './arrowLeftRightRound';
import arrowReloadHorizontalRound from './arrowReloadHorizontalRound';
import arrowRight01Round from './arrowRight01Round';
import arrowRight02Round from './arrowRight02Round';
import arrowTurnBackwardSharp from './arrowTurnBackwardSharp';
import arrowUp01Round from './arrowUp01Round';
import assortments from './assortments';
import attachFile from './attachFile';
import audit01 from './audit01';
import bank from './bank';
import bulkUpload from './bulkUpload';
import calculator from './calculator';
import calendar04 from './calendar04';
import cancel01 from './cancel01 ';
import cancelCircle from './cancelCircle';
import cash from './cash';
import chat1 from './chat1';
import chatting01 from './chatting01';
import checkList from './checkList';
import checkmarkCircle01 from './checkmark-circle01';
import checkmarkCircle02 from './checkmark-circle02';
import chevronDown from './chevronDown';
import chevronRight from './chevronRight';
import chip from './chip';
import circleArrowDownRound from './circleArrowDownRound';
import circleArrowLeft02Round from './circleArrowLeft02Round';
import circleArrowRight01 from './circleArrowRight01';
import circleArrowUpRound from './circleArrowUpRound';
import clockBackward from './clockBackward';
import cloudUpload from './cloudUpload';
import configuration from './configuration';
import copy from './copy';
import copy01 from './copy01';
import coupon01 from './coupon01';
import delete3 from './delete3';
import documentValidation from './documentValidation';
import download4 from './download4';
import edit from './edit';
import ellipsis from './ellipsis';
import error_report from './error_report';
import fallback from './fallback';
import file01 from './file01';
import fileAdd from './fileAdd';
import fileAttachment from './fileAttachment';
import fileSync from './fileSync';
import filter from './filter';
import filterVertical from './filterVertical';
import flow from './flow';
import help_circle from './help_circle';
import home01 from './home01';
import hydroPower from './hydroPower';
import information from './information';
import informationCircle from './informationCircle';
import invoice01 from './invoice01';
import invoice02 from './invoice02';
import invoice03 from './invoice03';
import invoice04 from './invoice04';
import invoicing from './invoicing';
import left_arrow_circle from './left_arrow_circle';
import link02 from './link02';
import listView from './listView';
import minusSign from './minusSign';
import moneyBag02 from './moneyBag02';
import moneySend from './moneySend';
import moreVertical from './moreVertical';
import notebook01 from './notebook01';
import notification from './notification';
import orderConfirmation from './orderConfirmation';
import packageProcess from './packageProcess';
import paperclip from './paperclip';
import personal_settings from './personal_settings';
import pickListConfirmation from './pickListConfirmation';
import pin from './pin';
import pinFilled from './pinFilled';
import plusSign from './plusSign';
import printer from './printer';
import printerOff from './printerOff';
import proFormaInvoice from './proFormaInvoice';

import excelLogo from './excelLogo';
import pngLogo from './pngLogo';
import csvLogo from './csvLogo';
import allRows from './allRows';
import selectedRows from './selectedRows';
import endOfFile from './endOfFile';

import query from './query';
import refresh from './refresh';
import refresh2 from './refresh2';
import save from './save';
import schoolReportCard from './schoolReportCard';
import search01 from './search01';
import searchFocus from './searchFocus';
import server_view from './server_view';
import setting04 from './setting04';
import settings from './settings';
import sortIcon from './sortIcon';
import sortIconAsc from './sortIconAsc';
import sortIconDesc from './sortIconDesc';
import txt02 from './txt02';
import squareUnlock01 from './squareUnlock01';
import star from './star';
import starFilled from './starFilled';
import tickDouble01 from './tickDouble01';
import toolbar_settings from './toolbar_settings';
import upload from './upload';
import vector from './vector';
import verticalMore from './verticalMore';
import wallet from './wallet';
import warning from './warning';
import xml_doc from './xml_doc';
import note03 from './note03';
import xCircle from './x-circle';
import close from './close';
import view from './view';
import attachmentPdf from './attachmentPdf';
import attachmentCsv from './attachmentCsv';
import attachmentExcel from './attachmentExcel';
import attachmentDefault from './attachmentDefault';
import attachmentPng from './attachmentPng';
import linkSquare01 from './linkSquare01';

const svgs = {
  chevronRight,
  chevronDown,
  fallback,
  attachFile,
  plusSign,
  minusSign,
  fileAttachment,
  cloudUpload,
  close,
  delete3,
  filter,
  settings,
  excelLogo,
  pngLogo,
  csvLogo,
  selectedRows,
  allRows,
  endOfFile,
  advanceMoney,
  bank,
  hydroPower,
  schoolReportCard,
  alert01,
  setting04,
  chip,
  filterVertical,
  home01,
  cancelCircle,
  circleArrowLeft02Round,
  star,
  starFilled,
  calculator,
  cash,
  wallet,
  moneyBag02,
  documentValidation,
  notebook01,
  checkmarkCircle01,
  flow,
  calendar04,
  arrowTurnBackwardSharp,
  copy01,
  fileSync,
  arrowReloadHorizontalRound,
  arrowLeftRightRound,
  informationCircle,
  tickDouble01,
  link02,
  file01,
  pin,
  pinFilled,
  chatting01,
  arrowRight01Round,
  arrowRight02Round,
  arrowUp01Round,
  arrowDown01Round,
  fileAdd,
  cancel01,
  moreVertical,
  chat1,
  copy,
  edit,
  ellipsis,
  error_report,
  help_circle,
  left_arrow_circle,
  paperclip,
  personal_settings,
  printer,
  query,
  refresh,
  refresh2,
  save,
  server_view,
  toolbar_settings,
  upload,
  xml_doc,
  information,
  warning,
  vector,
  search01,
  circleArrowRight01,
  arrowLeft01Sharp,
  clockBackward,
  assortments,
  bulkUpload,
  verticalMore,
  notification,
  moneySend,
  listView,
  configuration,
  searchFocus,
  alertCircle,
  analysisTextLink,
  download4,
  orderConfirmation,
  pickListConfirmation,
  proFormaInvoice,
  invoicing,
  audit01,
  checkList,
  invoice01,
  invoice02,
  invoice03,
  invoice04,
  packageProcess,
  printerOff,
  coupon01,
  squareUnlock01,
  sortIcon,
  sortIconAsc,
  sortIconDesc,
  checkmarkCircle02,
  txt02,
  circleArrowDownRound,
  circleArrowUpRound,
  note03,
  xCircle,
  view,
  attachmentPdf,
  attachmentCsv,
  attachmentExcel,
  attachmentDefault,
  attachmentPng,
  linkSquare01,
};

export default svgs;

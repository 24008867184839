export default (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12" r="12" fill="#0076BB" />
    <g clip-path="url(#clip0_10316_57107)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4956 1.50475C9.1626 1.11625 9.9996 1.59675 9.9996 2.36875V3.99825C10.1966 3.99825 10.3916 4.03705 10.5736 4.11243C10.7556 4.18781 10.921 4.2983 11.0603 4.43759C11.1995 4.57688 11.31 4.74224 11.3854 4.92422C11.4608 5.10621 11.4996 5.30127 11.4996 5.49825C11.4996 5.69523 11.4608 5.89029 11.3854 6.07228C11.31 6.25426 11.1995 6.41962 11.0603 6.55891C10.921 6.6982 10.7556 6.80869 10.5736 6.88407C10.3916 6.95945 10.1966 6.99825 9.9996 6.99825V8.49825C9.9996 9.32225 9.0591 9.79275 8.3996 9.29825L7.3696 8.52525C6.81903 8.11243 6.17798 7.83681 5.4996 7.72125V9.14325C5.49965 9.47012 5.38155 9.78599 5.16705 10.0326C4.95255 10.2793 4.65612 10.4401 4.33241 10.4854C4.00869 10.5307 3.67951 10.4575 3.40554 10.2792C3.13157 10.1009 2.93128 9.82958 2.8416 9.51525L2.0566 6.76725C1.77411 6.43364 1.59027 6.02786 1.52574 5.5955C1.46122 5.16315 1.51857 4.72137 1.69134 4.31982C1.86412 3.91827 2.14547 3.57287 2.50377 3.32245C2.86208 3.07203 3.28313 2.92651 3.7196 2.90225L5.2286 2.81825C5.96691 2.77716 6.68492 2.56142 7.3236 2.18875L8.4956 1.50475ZM3.3166 7.53725L3.8031 9.24075C3.82651 9.32321 3.87897 9.39441 3.95079 9.44121C4.0226 9.48801 4.10892 9.50724 4.19381 9.49536C4.2787 9.48347 4.35643 9.44128 4.41263 9.37656C4.46884 9.31184 4.49973 9.22897 4.4996 9.14325V7.63825L3.7196 7.59475C3.58386 7.58722 3.44903 7.56798 3.3166 7.53725ZM10.4996 5.49825C10.4996 5.36564 10.4469 5.23846 10.3532 5.1447C10.2594 5.05093 10.1322 4.99825 9.9996 4.99825V5.99825C10.1322 5.99825 10.2594 5.94557 10.3532 5.8518C10.4469 5.75804 10.4996 5.63086 10.4996 5.49825Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10316_57107">
        <rect width="12" height="12" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

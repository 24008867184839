import { useEffect, useRef } from 'react';
import { OpenAPIId, OperationId, useOpenAPIMethod } from '../framework/openapi';
import { getOptionsWithLabelValuePair } from '../utils/getOptionsWithLabelValuePair';

type UseDropdownOptionsProps<T extends OpenAPIId, K extends OperationId<T>> = {
  apiId: T;
  operationId: K;
  labelAttribute: string;
  valueAttribute: string;
  params?: Record<string, unknown>;
  requiredParams?: Record<string, unknown>;
  shouldPreventApiCall?: boolean;
};

type DropdownOption = {
  label: string;
  value: string | number;
};

export const useDropdownOptions = <T extends OpenAPIId, K extends OperationId<T>>({
  apiId,
  operationId,
  labelAttribute,
  valueAttribute,
  params = {},
  requiredParams = {},
  shouldPreventApiCall,
}: UseDropdownOptionsProps<T, K>) => {
  const apiMethod = useOpenAPIMethod(apiId, operationId);
  const hasCalledApiRef = useRef(false);

  useEffect(() => {
    hasCalledApiRef.current = false; // Reset when params change
  }, [JSON.stringify(params)]);

  useEffect(() => {
    const areRequiredParamsProvided = Object.keys(requiredParams).every((key) => !!requiredParams[key]);

    // Only execute API if:
    // 1. Required params are provided
    // 2. Component is not disabled
    // 3. API hasn't been called before
    if (areRequiredParamsProvided && !shouldPreventApiCall && !hasCalledApiRef.current) {
      apiMethod.execute({ ...params, ...requiredParams });
      hasCalledApiRef.current = true;
    }
  }, [shouldPreventApiCall, JSON.stringify(params), ...Object.values(requiredParams)]);

  const options: DropdownOption[] = getOptionsWithLabelValuePair({
    list: apiMethod?.response?.data?.data?.items || [],
    labelAttribute,
    valueAttribute,
  });

  return {
    response: apiMethod.response,
    options,
    loading: apiMethod.loading,
    error: apiMethod.error,
    refetch: () => {
      hasCalledApiRef.current = true; // Mark as called when manually refetching
      return apiMethod.execute({ ...params, ...requiredParams });
    },
  };
};

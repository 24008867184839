import { Accordion, Checkbox, Text, TextField, useIptorTranslation, FormControlLabel } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { Order } from '../../../constants';
import { OrderPropertyDropdown, CostCentresDropdown, StandardTextDropdowns } from '@iptor/business';

type MiscellaneousProps = {
  createProcess?: boolean;
  getValues?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
};

const Miscellaneous = ({ getValues, control, errors, createProcess }: MiscellaneousProps) => {
  const { t } = useIptorTranslation();

  return (
    <Accordion
      headingTitle={t('common:TXT_Miscellaneous')}
      moreInfo={
        <Grid container columnSpacing={3} rowSpacing={2} alignItems="flex-end">
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', marginLeft: 0 }}
              id={'division'}
              control={
                <Text id="division" fontSize={14} color="textDisabled">
                  {getValues?.('division') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_Division')}</Text>}
              readOnly={!createProcess}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <Controller
              name="holdOrder"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  sx={{ margin: 0 }}
                  control={<Checkbox id={field.name} {...field} />}
                  readOnly={!createProcess}
                  label={t('common:TXT_Order_Hold')}
                />
              )}
            />
          </Grid>
          <Grid size={3}>
            <Controller
              name="heldOrderLinesExist"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  sx={{ margin: 0 }}
                  control={<Checkbox id={field.name} {...field} />}
                  readOnly={!createProcess}
                  label={t('common:TXT_Held_Lines')}
                />
              )}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid size={12}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Miscellaneous')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="salesOrderPriorityCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Order_Priority')}
                  id={field.name}
                  inputProps={{
                    // Tooltip content
                    id: `${field.name}`,
                  }}
                  fullWidth
                  error={!!errors?.salesOrderPriorityCode}
                  helperText={errors?.salesOrderPriorityCode?.message}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="backOrderPriorityCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Back_Order_Priority')}
                  id={field.name}
                  inputProps={{
                    // Tooltip content
                    id: `${field.name}`,
                  }}
                  fullWidth
                  error={!!errors?.backOrderPriorityCode}
                  helperText={errors?.backOrderPriorityCode?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="standardTextNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StandardTextDropdowns
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  inputProps={{
                    // Tooltip content
                    id: `${field.name}`,
                  }}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Text_Code')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="costCentre"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CostCentresDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  inputProps={{
                    // Tooltip content
                    id: `${field.name}`,
                  }}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Cost_Centre')}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="orderPropertyCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <OrderPropertyDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  inputProps={{
                    // Tooltip content
                    id: `${field.name}`,
                  }}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Order_Property')}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};
export default Miscellaneous;

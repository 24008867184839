import { Environment, ExtendedProfile, User, UserProfile } from 'oidc-client-ts';
import { jwtDecode, JwtPayload } from 'jwt-decode';

declare module 'oidc-client-ts' {
  interface User {
    idTokenParsed(): any;
    accessTokenParsed(): any;
    extendedUserProfile(): ExtendedProfile;
  }
  type Environment = {
    key: string;
    label: string;
  };

  type ExtendedProfile = UserProfile & IptorTokenProps;
}
type IptorTokenProps = {
  environments?: Environment[]; // array of available environments, like: dev, prod, stagging, etc.
  app_user_id?: string; // used for communication with DC1
  envConfigKey?: string;
  roles?: string[]; // available roles for user, used in Dashboard editor, e.g. 'marketing_admin', 'dashboard_admin', 'marketing_user', 'offline_access', 'default-roles-iptor.com', 'uma_authorization', 'dashboard_global'
};
type ExtendedJwtPayload = JwtPayload & IptorTokenProps;
const parseToken = (token: string) => {
  try {
    return jwtDecode(token) as ExtendedJwtPayload;
  } catch (error) {
    console.error(
      'Error decoding Base64 token string. Possible reasons: Do we use JWE tokens without decryptionKey instead of JWT tokens? Or the payload of the token is empty?, error: ',
      error,
    );
  }
};

User.prototype.idTokenParsed = function (this: User) {
  return parseToken(this.id_token);
};
User.prototype.accessTokenParsed = function (this: User) {
  return parseToken(this.access_token);
};

User.prototype.extendedUserProfile = function (this: User): ExtendedProfile {
  const tokenParsed = parseToken(this.id_token);
  let userProfileWithIptorSpecificAttributes: ExtendedProfile;
  userProfileWithIptorSpecificAttributes = {
    environments: tokenParsed?.['environments'] as Environment[], // deprecated
    app_user_id: tokenParsed?.app_user_id,
    envConfigKey: tokenParsed?.envConfigKey,
    roles: (tokenParsed as any)?.realm_access?.roles,
    ...this.profile,
  };
  return userProfileWithIptorSpecificAttributes;
};

import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import IconType from '../../constants/IconType';
import icons from '../../resources/svgs';
import { IconProps as MuiIconProps } from '@mui/material';
import { ThemeColor } from 'src/types';
import { getThemeColor } from 'src/utils/getThemeColor';

enum IconSize {
  XXL = 72,
  XL = 42,
  L = 28,
  M = 18,
  S = 14,
  XS = 10,
}

export type IconName = keyof typeof icons;

export type IconProps = {
  icon?: IconType;
  src?: string;
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
  color?: ThemeColor | string;
  className?: string;
  onClick?: () => void;
} & Omit<MuiIconProps, 'color'>;

const StyledIcon = styled.span<IconProps>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: ${({ size }) => size && IconSize[size]}px;
  height: ${({ size }) => size && IconSize[size]}px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  svg {
    fill: none;
    stroke: currentColor;
    width: ${({ size }) => size && IconSize[size]}px;
    height: ${({ size }) => size && IconSize[size]}px;
  }

  img {
    width: ${({ size }) => size && IconSize[size]}px;
    height: ${({ size }) => size && IconSize[size]}px;
    object-fit: contain;
  }
`;

const defaultFallbackIcon: IconType = 'fallback';

export function Icon({ icon, src, size = 'M', color, className, onClick, ...rest }: IconProps) {
  const [hasError, setHasError] = useState(false);
  const theme = useTheme();
  const defaultColor = theme.palette.foreground[600];

  const handleImageError = () => {
    setHasError(true);
  };

  return (
    <StyledIcon
      size={size}
      style={{ color: getThemeColor({ color, theme, defaultColor }) }}
      className={className}
      onClick={onClick}
      {...rest}
      data-iscapture="true"
      data-for="global"
      data-tip={rest?.id}
    >
      {src && !hasError ? (
        <img src={src} onError={handleImageError} alt="icon" />
      ) : (
        icons[(hasError ? defaultFallbackIcon : icon) as IconType]
      )}
    </StyledIcon>
  );
}

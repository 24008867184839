import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  autocompleteClasses,
  PaperProps,
  TableCellProps,
  TableHeadProps,
  useTheme,
  AutocompleteInputChangeReason,
} from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { debounce } from '../../utils/debounce';
import { Button } from '../Button';
import { TextField, TextFieldProps } from '../TextField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Row = Record<string, string | number | boolean | any>; // TODO: check any, and narrow typing;

const IptorAutocomplete = Autocomplete<Row, undefined, undefined, true, undefined>;

type autocompleteProps = Pick<
  AutocompleteProps<Row, undefined, undefined, true, undefined>,
  'onInputChange' | 'inputValue'
>;
export interface LiveSearchRef {
  clearListbox: () => void;
}
export type LiveSearchBaseProps = Omit<TextFieldProps, 'onChange' | 'value' | 'defaultValue'> & {
  minLettersToRun?: number;
  isLoading: boolean;
  columnKey: string;
  handleViewAll?: HandleViewAll;
  columns: Column[];
  getRows: (search: string) => void;
  rows: Row[];
  // TODO: Refactoring change
  // onInputChange <=== Typing (Controlled input)
  // onValueChange <== accepted value (Actual form event)
  onLiveSearchInputChange?: autocompleteProps['onInputChange']; // controlled way to handle changes on input field
  liveSearchInputValue?: autocompleteProps['inputValue']; // controlled way to handle initial value of input field
  showListBoxOnFocus?: boolean;
  liveSearchRef?: React.MutableRefObject<LiveSearchRef>;
};

export type Column = {
  id: string;
  header: string;
  hidden?: boolean;
};

export type HandleViewAll =
  | {
      enabled: true;
      onRequested: () => void;
      label?: string;
    }
  | {
      enabled: false;
    };

export const LiveSearchBase: FunctionComponent<LiveSearchBaseProps> = ({
  minLettersToRun = 2,
  label,
  isLoading,
  columnKey,
  columns,
  handleViewAll,
  getRows,
  rows,
  showListBoxOnFocus = false,
  liveSearchRef,
  ...props
}: LiveSearchBaseProps) => {
  const theme = useTheme();
  const [sourceRows, setSourceRows] = useState<Row[]>(rows as Row[]);
  const [inputValue, setInputValue] = useState<string>(props.liveSearchInputValue ?? '');

  useEffect(() => {
    setSourceRows(rows);
  }, [rows]);

  const debouncedGetData = useMemo(() => debounce((value: string) => getRows(value), 300)[0], [getRows]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleViewAllClick = useCallback(() => {
    if (handleViewAll?.enabled && handleViewAll.onRequested) {
      handleViewAll.onRequested();
      setIsOpen(false); // Close the results table on View All click
    }
  }, [handleViewAll]);

  const clearListbox = () => {
    setSourceRows([] as Row[]);
  };

  // Expose the removeFile function to the parent via ref
  useImperativeHandle(liveSearchRef, () => ({
    clearListbox,
  }));

  const renderInputField = (params: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { variant, ..._props } = props; // variant added only to bypass typescript errors - check it!

    return (
      <TextField
        {...params} // needed got inputRef
        {..._props} // needed to get all textField props
        isAutoComplete={true}
        label={label}
        onFocus={(e) => {
          if (showListBoxOnFocus && e.currentTarget.value?.length >= minLettersToRun && sourceRows.length === 0) {
            debouncedGetData(e.currentTarget.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key.toUpperCase() === 'F4') {
            e.preventDefault();
            e.stopPropagation();
            handleViewAllClick();
          }
        }}
        slotProps={{
          input: {
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress sx={(theme) => ({ color: theme.palette.foreground[400], mr: 1 })} size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          },
        }}
      />
    );
  };

  const renderPaperComponent = (props: PaperProps) =>
    inputValue ? (
      <Paper
        sx={{
          width: 'fit-content',
          '& td, & th': { textWrap: 'nowrap' },
        }}
      >
        <TableContainer
          sx={{
            maxHeight: '50px',
            overflow: 'hidden',
          }}
          className={autocompleteClasses.listbox}
        >
          <Table stickyHeader>
            {
              // this header would be visible
              renderTableHeaderRow(undefined, {
                borderBottom: `1px solid ${theme.palette.background.paper} !important`,
              })
            }
            <TableBody>
              {
                // these rows would be hidden (maxHeight: '50px' - will show only header) - render it only for properly calculation columns widths
                columns &&
                  columns.length > 0 &&
                  sourceRows.length > 0 &&
                  sourceRows.map((row) => renderTableBodyRow({ id: props.id }, row))
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          // here would be render listbox: renderListboxComponent
          // inside would be rendered: hidden header (render it only for properly calculation columns widths) and visible rows
          props.children
        }
        {handleViewAll && handleViewAll.enabled && (
          <Box
            sx={{
              position: 'sticky',
              left: 0,
              right: 0,
              bottom: 0,
              background: (theme) => theme.palette.background.default,
            }}
          >
            <Divider />
            <Stack direction="row" justifyContent="end">
              <Button
                id="ViewAllId"
                variant="text"
                onClick={() => {
                  handleViewAllClick();
                }}
              >
                {handleViewAll.label ?? 'View all' + ' >'}
              </Button>
            </Stack>
          </Box>
        )}
      </Paper>
    ) : (
      <></>
    );
  // Custom Listbox component to render a table with a header row
  interface renderListboxComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
  }
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'block',
    maxHeight: '35vh !important',
    overflow: 'auto',
    [`& tr.Mui-focused`]: {
      background: theme.palette.background.paper,
    },
    background: `${theme.palette.background.default} !important`,
    padding: `0px !important`,
  }));
  const renderListboxComponent = forwardRef(function liveSearchListboxComponent(
    props: renderListboxComponentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const { children: tableBodyRows } = props;
    return (
      <StyledTableContainer ref={ref} {...props}>
        {inputValue && (
          <Table>
            {
              // this header would be hidden - render it only to set up width of columns properly
              renderTableHeaderRow(
                { style: { visibility: 'hidden' } },
                { style: { lineHeight: 0, paddingTop: 0, paddingBottom: 0, textWrap: 'nowrap' } },
              )
            }
            <TableBody>
              {
                // these rows would be visible
                tableBodyRows
              }
            </TableBody>
          </Table>
        )}
      </StyledTableContainer>
    );
  });

  const renderTableHeaderRow = (
    additionalTableHeadProps?: TableHeadProps,
    addtionalTableCellProps?: TableCellProps & { borderBottom?: string },
  ) => {
    return (
      <TableHead {...additionalTableHeadProps}>
        <TableRow>
          {columns &&
            columns.length > 0 &&
            columns
              .filter((column) => !column.hidden)
              .map((column) => (
                <TableCell
                  {...addtionalTableCellProps}
                  sx={(theme) => {
                    // Conditionally apply background style if it exists in additionalTableCellProps
                    const borderBottom = addtionalTableCellProps?.borderBottom
                      ? { borderBottom: addtionalTableCellProps.borderBottom }
                      : {};
                    return {
                      ...borderBottom,
                      background: `${theme.palette.background.default} !important`,
                    };
                  }}
                  style={{ ...addtionalTableCellProps.style, fontWeight: 'bold', borderRadius: 'unset' }}
                  scope="col"
                >
                  {column.header}
                </TableCell>
              ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBodyRow = (props: React.HTMLAttributes<HTMLLIElement>, row: Row) => {
    return (
      //e.g. props.id = ':r1:-option-0'
      <TableRow
        {...props}
        component={'li'}
        hover
        key={row[columnKey] + '||' + props.id}
        style={{ display: 'table-row' }}
        className={autocompleteClasses.option}
      >
        {columns &&
          columns.length > 0 &&
          columns.map((col: Column) => <TableCell style={{ border: '0px', padding: '12px' }}>{row[col.id]}</TableCell>)}
      </TableRow>
    );
  };
  const onInputChange = async (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    setInputValue(value);
    if (props.onLiveSearchInputChange) {
      props.onLiveSearchInputChange(event, value, reason);
    }
    if (reason !== 'input') {
      //AutocompleteInputChangeReason = "input" | "reset" | "clear" | "blur" | "selectOption" | "removeOption"
      clearListbox();
      return;
    }

    if (value.length < minLettersToRun) {
      if (sourceRows.length > 0) {
        clearListbox();
      }
    } else {
      await debouncedGetData(value as string);
      // TODO: if 0 < rows.length < limit, filter results without sending request to api - HOW TO do it?
    }
  };

  return (
    <div data-iscapture="true" data-for="global" data-tip={props.id}>
      <IptorAutocomplete
        freeSolo
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={(event) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((event as any).relatedTarget?.id === 'ViewAllId') {
            // handle close event directly inside the viewAll button to be able run its stuff before close
            return;
          } else {
            setIsOpen(false);
          }
        }}
        onBlur={props.onBlur as React.FocusEventHandler<HTMLDivElement>} // TODO: test it!
        inputValue={props.liveSearchInputValue}
        options={sourceRows}
        renderInput={renderInputField}
        renderOption={renderTableBodyRow}
        getOptionLabel={(row) => (typeof row === 'string' ? row : row[columnKey])}
        getOptionKey={(row) => (typeof row === 'string' ? row : row[columnKey])}
        isOptionEqualToValue={(option, value) => option[columnKey] === value[columnKey]}
        slots={{
          // outer -> inner: Popper (container for listbox) -> Paper (visual layer) -> Listbox
          // popper: here you can add props for popper container
          paper: renderPaperComponent,
          listbox: renderListboxComponent,
        }}
        filterOptions={(x) => x} // don't filter existing options, each letter = separate call to api for new
        onChange={(event, value, reason) => {
          // when you try to select from the listbox the same item which is in the inputField, then instead of onInputChange event, onChange is fired - redirect it manually to onInputChange
          if (reason === 'selectOption') {
            onInputChange(event, typeof value === 'string' ? value : (value as Row)[columnKey], reason);
          }
        }}
        onInputChange={onInputChange}
      />
    </div>
  );
};

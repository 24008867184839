import dayjs, { Dayjs } from 'dayjs';

export const formatDate = (date: Date | string, dateFormat = 'YYYY-MM-DD') => {
  if (!date) return null;

  if (date instanceof Date) {
    return dayjs(date);
  }
  return dayjs(date, dateFormat);
};

export const formatDateToPayloadFormat = (value: string | Date | Dayjs | null) => {
  return value ? dayjs(value).format('YYYY-MM-DD') : null;
};

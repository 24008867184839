import React, { Component, FormEvent } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import axios, { AxiosError } from 'axios';
import { Company } from '../types/company';
import '../styles/Login.scss';
import { Localization } from '../framework/localization/Localization';
import { LocalStorage } from '../framework/handlers/localStorage';
import { SettingsCombo, Option } from '../components/SettingsCombo';
import { LoggerContext } from '../logger/react/LoggerProvider';
import { ClientLogger } from '../logger/ClientLogger';
import { AuthContextProps } from 'react-oidc-context';
import { Environment } from 'oidc-client-ts';
import { LoadingSetters } from '..';
import { CustomAuthContextProps, withCustomAuth } from '@iptor/base';

export type AuthType = {
  auth?: AuthContextProps;
};

type LoginState = {
  company?: Company;
  username?: string;
  password?: string;
  selectCompany: boolean;
  validated: boolean;
  companies?: Company[];
  errorMsg: string;
  error: boolean;
  loading: boolean;
  fullName?: string;
  resetPassword: boolean;
  allowSkip: boolean;
  env?: string;
  selectedLocaleOption: string;
  localeOptions: Option[];
};

class LoginBase extends Component<LoadingSetters & { auth?: CustomAuthContextProps }> {
  _logger: ClientLogger | undefined;
  state: LoginState;
  context!: React.ContextType<typeof LoggerContext>;

  constructor(props: LoadingSetters & { auth?: CustomAuthContextProps }) {
    super(props);
    const user = this.props.auth?.getUser();
    const userEnvironments = user?.extendedUserProfile()?.environments;
    this.state = {
      selectCompany: (!userEnvironments || userEnvironments.length < 2) && !process.env.REACT_APP_IS_DISPATCHER,
      validated: false,
      errorMsg: 'Password is required',
      error: false,
      loading: false,
      resetPassword: false,
      allowSkip: false,
      selectedLocaleOption: Localization.Maintenance.getLocaleOptionValueFromCurrentLocale(),
      localeOptions: Localization.Maintenance.getLocaleOptionsList(),
    };
    this.login = this.login.bind(this);
    this.forceLogin = this.forceLogin.bind(this);
    this.tryFocus = this.tryFocus.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.selectCompany = this.selectCompany.bind(this);
    axios.defaults.withCredentials = true;
  }

  public get logger(): ClientLogger {
    if (!this._logger) {
      this._logger = this.context.getLogger('Login');
    }
    return this._logger!;
  }

  resetPassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      this.setState({ validated: true, errorMsg: Localization.instance.getString('TXT_PASSWD_REQUIRED') });
      return;
    }
    const data = new FormData(e.currentTarget);
    if (data.get('new-password') !== data.get('confirm-password')) {
      e.stopPropagation();
      // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
      // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
      ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
      form.checkValidity();
      this.setState({ validated: true, errorMsg: Localization.instance.getString('TXT_PASSWD_NOT_MATCH') });
      return;
    }
    if (data.get('old-password') === data.get('confirm-password')) {
      e.stopPropagation();
      // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
      // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
      ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
      form.checkValidity();
      this.setState({ validated: true, errorMsg: Localization.instance.getString('TXT_PASSWD_THE_SAME') });
      return;
    }
    this.setState({ oldPassword: data.get('old-password'), newPassword: data.get('new-password'), loading: true });
    this.props.startLoading();
    axios
      .post('/auth/reset-password', {
        user: this.state.username,
        password: data.get('old-password'),
        newPassword: data.get('new-password'),
      })
      .then((res) => {
        this.setState({ resetPassword: false, allowSkip: false, oldPassword: '', newPassword: '' });
      })
      .catch(() => {
        //TODO: Handle specific errors
        // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
        // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
        ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
        form.checkValidity();
        this.setState({ errorMsg: Localization.instance.getString('TXT_PASSWD_ERR_ON_CHANGE') });
      })
      .finally(() => {
        ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
        ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
        ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
        this.setState({ loading: false });
        this.props.endLoading();
      });
  }

  forceLogin() {
    this.props.startLoading();
    axios
      .post('/public/login', {
        username: this.state.username,
        password: this.state.password,
        skipCheck: true,
      })
      .then(async (res) => {
        const constValueToFormatMiliseconds = 36; //TODO: get it from config
        const date = new Date();
        const millisec = date.getTime();
        const id = millisec.toString(constValueToFormatMiliseconds);
        const payload = {
          IptorAPI: '1.0',
          method: 'apiUserProfile.get',
          id: id,
        };
        this.setState({
          selectCompany: true,
          companies: res.data['list']['companies']['company'].map((x: any) => x['$']),
          fullName: this.state.username,
          resetPassword: false,
          allowSkip: false,
        });
        this.setState({ validated: false });
        let _res = await axios.post(`/aperio/api/service`, payload);
        this.setState({
          fullName: _res.data?.data?.text || this.state.username,
        });
      })
      .catch((err) => {
        let axErr = err as AxiosError;
        if (axErr?.response?.status === 407 || axErr?.response?.status === 399) {
          this.setState({ resetPassword: true, allowSkip: axErr?.response?.status === 399, validated: false });
        } else {
          this.setState({
            errorMsg:
              axErr?.response?.status === 431
                ? Localization.instance.getString('TXT_USER_DISABLED')
                : Localization.instance.getString('TXT_INVALID_CREDENTIALS'),
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
        this.props.endLoading();
      });
  }

  login(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      this.setState({ validated: true, errorMsg: Localization.instance.getString('TXT_PASSWD_REQUIRED') });
      return;
    }
    this.props.startLoading();
    const data = new FormData(e.currentTarget);
    this.setState({ validated: true, username: data.get('username'), password: data.get('password'), loading: true });
    axios
      .post('/public/login', {
        username: data.get('username'),
        password: data.get('password'),
      })
      .then(async (res) => {
        if (res.status === 200 && !process.env.REACT_APP_IS_DISPATCHER) window.location.replace('/app');
        const constValueToFormatMiliseconds = 36; //TODO: get it from config
        const date = new Date();
        const millisec = date.getTime();
        const id = millisec.toString(constValueToFormatMiliseconds);
        const payload = {
          IptorAPI: '1.0',
          method: 'apiUserProfile.get',
          id: id,
        };
        this.setState({
          selectCompany: true,
          companies: res.data['list']['companies']['company'].map((x: any) => x['$']),
          fullName: this.state.username,
        });
        this.setState({ validated: false });
        ((e.target as HTMLFormElement).password as HTMLInputElement).value = '';
        let _res = await axios.post(`/aperio/api/service`, payload);
        this.setState({
          fullName: _res.data?.data?.text || this.state.username,
        });
      })
      .catch((err) => {
        let axErr = err as AxiosError;

        if (axErr?.response?.status === 407 || axErr?.response?.status === 399) {
          this.setState({ resetPassword: true, allowSkip: axErr?.response?.status === 399, validated: false });
        } else {
          ((e.target as HTMLFormElement).password as HTMLInputElement).value = '';
          form.checkValidity();
          this.setState({
            errorMsg:
              axErr?.response?.status === 431
                ? Localization.instance.getString('TXT_USER_DISABLED')
                : Localization.instance.getString('TXT_INVALID_CREDENTIALS'),
          });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
        this.props.endLoading();
      });
  }

  selectCompany(company: Company) {
    this.logger.trace('Changing company', { company });
    if (this.state.company) return;
    this.setState({ company: company, error: false });
    this.props.startLoading();
    sessionStorage.setItem('company', JSON.stringify(company));
    const userId = this.props.auth?.preferredUsername || this.state.username;
    LocalStorage.LoginDefaults.save(userId || '', this.state.env || '', company.id);
    window.location.replace('/app');
  }

  tryFocus(e: KeyboardEvent) {
    if (this.state.selectCompany) {
      let button = document.querySelector(`.company-list [id^=company-${e.key} i]`);
      if (button) (button as HTMLButtonElement).focus();
    }
  }

  getCompanies() {
    this.props.startLoading();
    let companiesList: Array<Company> = [];
    axios
      .get('/public/companies', {})
      .then(async (res) => {
        if (res.status === 200 && !res.data.list.platform) {
          window.location.replace('/app');
        }
        if (res.data.list.companies?.company?.length > 1) {
          companiesList = res.data['list']['companies']['company']?.map((x: any) => x['$']);
        } else if (res.data['list']['platform']?.$?.company) {
          companiesList = [{ id: res.data['list']['platform'].$.company, name: '' }];
        }

        this.setState({
          selectCompany: true,
          companies: companiesList,
          fullName: res.data.username,
        });
        this.setState({ validated: false });
        if (!companiesList || companiesList.length === 0) {
          this.setState({
            error: true,
            errorMsg: Localization.instance.getString('TXT_NO_COMPANIES_AVAILABLE'),
          });
        } else if (companiesList.length === 1) {
          this.selectCompany(companiesList[0]);
        }
      })
      .catch((err) => {
        let axErr = err as AxiosError;
        switch (axErr?.response?.status) {
          case 399:
            this.setState({
              resetPassword: true,
              allowSkip: true,
              validated: false,
            });
            break;
          case 403:
            this.setState({
              error: true,
              errorMsg: Localization.instance.getString('TXT_USER_DISABLED_LONG'),
            });
            break;
          default:
            this.setState({
              error: true,
              errorMsg: Localization.instance.getString('TXT_NO_ACCESS_TO_ENV'),
            });
            break;
        }
        console.error(`Error from getCompanies ${err}`);
        if (companiesList.length === 1) {
          this.setState({ loading: false });
          this.props.endLoading();
        }
      })
      .finally(() => {
        if (companiesList.length !== 1) {
          this.setState({ loading: false });
          this.props.endLoading();
        }
      });
  }

  componentDidMount() {
    document.addEventListener('keyup', this.tryFocus);
    const user = this.props.auth?.getUser();
    const userEnvironments = user?.extendedUserProfile()?.environments;
    if ((!userEnvironments || userEnvironments.length < 2) && !process.env.REACT_APP_IS_DISPATCHER) {
      const envKey = user?.extendedUserProfile()?.environments?.[0]?.key ?? '';
      this.setState({ env: envKey });
      sessionStorage.setItem('env', envKey);
      const company = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';
      if (company) {
        window.location.replace('/app');
        return;
      }
      this.getCompanies();
    } else {
      this.setState({ username: JSON.parse(sessionStorage.getItem('user') || '{}')?.username });
    }

    if (userEnvironments && userEnvironments.length > 1) {
      const userId = this.props.auth?.preferredUsername;
      if (userId) {
        const defaultEnvironment = LocalStorage.LoginDefaults.getEnvironmentId(userId);
        if (defaultEnvironment) {
          let button = document.querySelector(`.company-list [id=environment-${defaultEnvironment} i]`);
          if (button) (button as HTMLButtonElement).focus();
        }
      }
    }
  }

  componentDidUpdate(_: LoadingSetters, prevState: LoginState) {
    if (this.state.selectCompany && (!prevState.selectCompany || this.state.companies !== prevState.companies)) {
      // = environment selected || single (or no) environment
      const userId = this.props.auth?.preferredUsername || this.state.username;
      const defaultCompanyId = LocalStorage.LoginDefaults.getCompanyId(userId || '', this.state.env || '');
      if (defaultCompanyId) {
        setTimeout(() => {
          let button = document.querySelector(`.company-list [id=company-${defaultCompanyId} i]`);
          if (button) (button as HTMLButtonElement).focus();
        }, 0);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.tryFocus);
  }

  backToLogin = async () => {
    this.props.startLoading();
    try {
      if (!process.env.REACT_APP_IS_DISPATCHER) {
        window.location.replace('/logout');
      }
    } catch (error) {
      console.error(`Error from backToLogin ${error}`);
    } finally {
      sessionStorage.clear();
      this.props.endLoading();
    }
  };

  render() {
    const user = this.props.auth?.getUser();
    return (
      <Container
        fluid
        style={{
          height: '100vh',
          background:
            'url("' +
            process.env.REACT_APP_LOGIN_BACKGROUND +
            '"), url("/Sign-in-page-bg-image_flying-car-text-1920x1080.jpg")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Row style={{ height: '100vh' }} className="align-items-end">
          <Col sm={0} md={7} lg={8} style={{ padding: '0px 100px', margin: '160px 0px' }}>
            {/* <Image src="/logo-white.png" hidden style={{ margin: '32px 0px' }} />*/}
            <p className="login-banner" hidden>
              A milestone in the Iptor transformation journey
            </p>
            <p className="login-subtitle" hidden>
              Version 12 is the key platform for distribution and publishing businesses to turn digital disruption into
              digital opportunity.
            </p>
          </Col>
          <Col style={{ height: '100vh' }} sm={12} md={5} lg={4}>
            <div
              style={{
                height: 'calc(100vh - 30px)',
                margin: '15px 0',
                padding: '15px 0px',
                position: 'relative',
                overflow: 'auto',
              }}
              className="bg-white"
            >
              <Image style={{ paddingLeft: '24px' }} src={'/logo-color.png'} />
              <h6
                hidden={this.props.loading || this.state.resetPassword}
                className="welcome-message"
                style={{ marginTop: '32px' }}
              >
                {Localization.instance.getString('TXT_HI')}{' '}
                {this.props.auth?.profileName || this.state.fullName
                  ? `${this.props.auth?.profileName || this.state.fullName},`
                  : Localization.instance.getString('TXT_THERE')}
                <br />
                {this.state.selectCompany
                  ? Localization.instance.getString('TXT_SELECT_COMPANY_MSG')
                  : process.env.REACT_APP_IS_DISPATCHER
                    ? Localization.instance.getString('TXT_WELCOME_BACK')
                    : Localization.instance.getString('TXT_SELECT_ENV_MSG')}
              </h6>
              <h6
                hidden={this.props.loading || !this.state.resetPassword}
                className="welcome-message"
                style={{ marginTop: '32px' }}
              >
                {this.state.allowSkip
                  ? Localization.instance.getString('Login_pwd_about_to_expire')
                  : Localization.instance.getString('TXT_PASSWORD_HAVE_TO_CHANGE')}
              </h6>
              <div
                hidden={
                  this.props.loading ||
                  this.state.selectCompany ||
                  this.state.resetPassword ||
                  !!process.env.REACT_APP_IS_DISPATCHER
                }
                style={{ marginTop: '48px' }}
              >
                <div className={'company-list' + (this.state.error ? ' has-error' : '')}>
                  {user?.extendedUserProfile()?.environments?.map((env: Environment) => {
                    return (
                      <Button
                        key={env.key}
                        id={`environment-${env.key}`}
                        className={`company-select-item ${env.key === this.state.env ? 'active' : ''}`}
                        onClick={() => {
                          this.setState({ env: env.key });
                          sessionStorage.setItem('env', env.key);
                          this.getCompanies();
                        }}
                      >
                        {env.label}
                      </Button>
                    );
                  })}
                </div>
                {/* <p hidden={!this.state.error} className="text-danger">
                  {this.state.errorMsg}
                </p> */}
              </div>

              <Form
                hidden={this.state.selectCompany || this.state.resetPassword || !process.env.REACT_APP_IS_DISPATCHER}
                noValidate
                validated={this.state.validated}
                style={{ marginTop: '72px', padding: '0 48px' }}
                onSubmit={this.login}
              >
                <Form.Label className="text-left">{Localization.instance.getString('TXT_USER_ID')}</Form.Label>
                <Form.Control id="username" defaultValue={this.state.username} name="username" type="text" required />
                <Form.Control.Feedback type="invalid">
                  {Localization.instance.getString('TXT_USER_ID_REQUIRED')}
                </Form.Control.Feedback>
                <Form.Label className="text-left" style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_LOGIN_PAGE_PASSWORD')}
                </Form.Label>
                <Form.Control id="password" name="password" type="password" required />
                <Form.Control.Feedback type="invalid">{this.state.errorMsg}</Form.Control.Feedback>
                <Form.Label className="text-left" style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_language_selection')}
                </Form.Label>
                <SettingsCombo
                  options={this.state.localeOptions}
                  defaultValue={
                    this.state.localeOptions.find((a) => a.value === this.state.selectedLocaleOption) || {
                      label: '',
                      value: '',
                    }
                  }
                  translateOptionLabels={false}
                  onChangeOption={(option: Option) => {
                    Localization.Maintenance.setSettingsByLocaleOptionValue(option.value);
                    this.setState({
                      selectedLocaleOption: option.value,
                      localeOptions: Localization.Maintenance.getLocaleOptionsList(),
                    });
                  }}
                />
                <Form.Label
                  className="text-left pointer"
                  onClick={() => (window.location.href = '/forgot-password')}
                  style={{ float: 'right', marginTop: '8px', color: '#00a3a5' }}
                >
                  {Localization.instance.getString('TXT_LOGIN_PAGE_FORGOT_PASSWORD')}
                </Form.Label>
                <Button
                  disabled={this.state.loading}
                  style={{
                    marginTop: '64px',
                    width: '100%',
                    background: '#00a3a5',
                    height: '40px',
                    fontSize: '12px',
                    padding: '12px auto',
                  }}
                  type="submit"
                >
                  {Localization.instance.getString('TXT_LOGIN_PAGE_LOGIN_BTN')}
                </Button>
              </Form>

              <Form
                hidden={!this.state.resetPassword}
                noValidate
                style={{ marginTop: '40px', padding: '0 48px' }}
                validated={this.state.validated}
                onSubmit={this.resetPassword}
                onChange={() => this.setState({ validated: false })}
              >
                <Form.Label>{Localization.instance.getString('TXT_OLD_PASSWORD')}</Form.Label>
                <Form.Control id="old-password" name="old-password" type="password" required />
                <Form.Control.Feedback type="invalid">
                  {Localization.instance.getString('TXT_OLD_PASSWORD_FIELD_REQUIRED')}
                </Form.Control.Feedback>
                <Form.Label style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_NEW_PASSWORD')}
                </Form.Label>
                <Form.Control id="new-password" name="new-password" type="password" required />
                <Form.Control.Feedback type="invalid">
                  {Localization.instance.getString('TXT_NEW_PASSWORD_FIELD_REQUIRED')}
                </Form.Control.Feedback>
                <Form.Label style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_CONFIRM_NEW_PASSWORD')}
                </Form.Label>
                <Form.Control id="confirm-password" name="confirm-password" type="password" required />
                <Form.Control.Feedback type="invalid">{this.state.errorMsg}</Form.Control.Feedback>
                <Button
                  disabled={this.state.loading}
                  style={{
                    marginTop: '37px',
                    width: '100%',
                    background: '#00a3a5',
                    height: '40px',
                    fontSize: '12px',
                    padding: '12px auto',
                  }}
                  type="submit"
                >
                  {Localization.instance.getString('TXT_RESET_PASSWORD_CONFIRM')}
                </Button>
                <Button
                  hidden={!this.state.allowSkip}
                  onClick={() => this.forceLogin()}
                  style={{
                    marginTop: '16px',
                    width: '100%',
                    border: 'none',
                    height: '40px',
                    fontSize: '12px',
                    padding: '12px auto',
                    background: 'none',
                    color: '#525252',
                  }}
                >
                  {Localization.instance.getString('TXT_SKIP')}
                </Button>
              </Form>

              <div hidden={this.props.loading || !this.state.selectCompany} style={{ marginTop: '48px' }}>
                <div className={'company-list' + (this.state.error ? ' has-error' : '')}>
                  {this.state.companies?.map((company: Company) => {
                    return (
                      <Button
                        key={company.id}
                        id={`company-${company.id}`}
                        className={`company-select-item ${company.id === this.state.company?.id ? 'active' : ''}`}
                        onClick={() => this.selectCompany(company)}
                      >
                        {company.id} - {company.name}
                      </Button>
                    );
                  })}
                </div>

                {/* <p hidden={!this.state.error} className="text-danger">
                  {this.state.errorMsg}
                </p> */}
                {/*<button*/}
                {/*  onClick={() => this.setState({ selectCompany: false, error: false })}*/}
                {/*  style={{*/}
                {/*    border: 'none',*/}
                {/*    background: 'none',*/}
                {/*    padding: '8px',*/}
                {/*    marginLeft: '20px',*/}
                {/*    marginTop: '20px'*/}
                {/*  }}*/}
                {/*>*/}
                {/*<div*/}
                {/*  style={{*/}
                {/*    display: 'inline-block',*/}
                {/*    width: '22px',*/}
                {/*    height: '22px',*/}
                {/*    marginRight: '13px',*/}
                {/*    verticalAlign: 'middle'*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      clipRule="evenodd"*/}
                {/*      d="M1.375 11.5C1.375 14.0527 2.38906 16.5009 4.1941 18.3059C5.99913 20.1109 8.44729 21.125 11 21.125C13.5527 21.125 16.0009 20.1109 17.8059 18.3059C19.6109 16.5009 20.625 14.0527 20.625 11.5C20.625 8.94729 19.6109 6.49913 17.8059 4.6941C16.0009 2.88906 13.5527 1.875 11 1.875C8.44729 1.875 5.99913 2.88906 4.1941 4.6941C2.38906 6.49913 1.375 8.94729 1.375 11.5ZM22 11.5C22 14.4174 20.8411 17.2153 18.7782 19.2782C16.7153 21.3411 13.9174 22.5 11 22.5C8.08262 22.5 5.28473 21.3411 3.22183 19.2782C1.15893 17.2153 0 14.4174 0 11.5C0 8.58262 1.15893 5.78473 3.22183 3.72183C5.28473 1.65893 8.08262 0.5 11 0.5C13.9174 0.5 16.7153 1.65893 18.7782 3.72183C20.8411 5.78473 22 8.58262 22 11.5ZM15.8125 10.8125C15.9948 10.8125 16.1697 10.8849 16.2986 11.0139C16.4276 11.1428 16.5 11.3177 16.5 11.5C16.5 11.6823 16.4276 11.8572 16.2986 11.9861C16.1697 12.1151 15.9948 12.1875 15.8125 12.1875H7.84712L10.7992 15.1383C10.8632 15.2022 10.9139 15.2781 10.9485 15.3616C10.9831 15.4451 11.0009 15.5346 11.0009 15.625C11.0009 15.7154 10.9831 15.8049 10.9485 15.8884C10.9139 15.9719 10.8632 16.0478 10.7992 16.1117C10.7353 16.1757 10.6594 16.2264 10.5759 16.261C10.4924 16.2956 10.4029 16.3134 10.3125 16.3134C10.2221 16.3134 10.1326 16.2956 10.0491 16.261C9.96556 16.2264 9.88967 16.1757 9.82575 16.1117L5.70075 11.9867C5.63673 11.9229 5.58593 11.847 5.55127 11.7635C5.51661 11.68 5.49877 11.5904 5.49877 11.5C5.49877 11.4096 5.51661 11.32 5.55127 11.2365C5.58593 11.153 5.63673 11.0771 5.70075 11.0133L9.82575 6.88825C9.95484 6.75916 10.1299 6.68663 10.3125 6.68663C10.4951 6.68663 10.6702 6.75916 10.7992 6.88825C10.9283 7.01734 11.0009 7.19243 11.0009 7.375C11.0009 7.55757 10.9283 7.73266 10.7992 7.86175L7.84712 10.8125H15.8125Z"*/}
                {/*      fill="#00A3A5"*/}
                {/*    />*/}
                {/*  </svg>*/}
                {/*</div>*/}
                {/* <div
                    style={{
                      display: 'inline-block',
                      fontStyle: 'normal',
                      fontSize: '18px',
                      lineHeight: '25px',
                      verticalAlign: 'middle',
                      color: '#00A3A5',
                      paddingTop: '6px'
                    }}
                  >
                    {Localization.instance.getString('Back_to_login')}
                  </div> */}
                {/*</button>*/}
              </div>
              <div hidden={this.props.loading || !this.state.error} className="text-danger login-error-message">
                <span style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}>
                  {Localization.instance.getString('TXT_LOGIN_ERROR_HEADER')} <br />
                </span>
                {this.state.errorMsg}
              </div>
              <div hidden={this.props.loading} className="desk-backto-login">
                <label className="txt-link footer-anchor-button" onClick={this.backToLogin}>
                  {Localization.instance.getString('Back_to_login')}
                </label>
              </div>
            </div>

            <p hidden={true} className="copyright-notice-login">
              {Localization.instance.getString('TXT_COPYRIGHT_FOOTER')}
              <br />
              {Localization.instance.getString('TXT_PRIVACY_POLICY')} |{' '}
              {Localization.instance.getString('TXT_UK_LEGAL')} |{' '}
              {Localization.instance.getString('TXT_CODE_OF_CONDUCT')}
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
LoginBase.contextType = LoggerContext; //loggerContext = LoggerContext.Consumer;
export const Login = withCustomAuth(LoginBase);

import { TableFilter, TableSettings, Text, useIptorTranslation } from '@iptor/base';
import { IptorTable, openAPIManager, OperationResponseItems } from '@iptor/business';
import { Stack } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import OrderDiscountLabel from '../../../components/discounts/OrderDiscountLabel';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import WarehouseAvailableQuantity from './WarehouseAvailableQuantity';
interface Props {
  currentOrder: number;
  selectedOrderId: number;
  handleClose: () => void;
  setDisabled: (value: boolean) => void;
}
type OrderLineType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrdersLines'>[0];

const PastItemsTable = forwardRef(({ selectedOrderId, currentOrder, handleClose, setDisabled }: Props, ref) => {
  const [selectedRows, setSelectedRows] = useState<OrderLineType[]>([]);
  const { notify } = useOrderNotifications();
  const { t } = useIptorTranslation();

  useImperativeHandle(ref, () => ({
    handleAddOrderLines,
  }));

  useEffect(() => {
    setDisabled(selectedRows.length === 0);
  }, [selectedRows]);

  const handleAddOrderLines = async () => {
    const postRequests = selectedRows.map((orderLineItem) => {
      const { orderLine, item, orderQuantitySalesUnit, unit, warehouse } = orderLineItem;

      return {
        orderLine,
        request: openAPIManager.execute(
          'internal.v1.base-orders',
          'POST /salesOrders/{order}/lines',
          { order: currentOrder },
          { item, unit, warehouse, orderQuantitySalesUnit },
        ),
      };
    });

    const results = await Promise.allSettled(postRequests.map(({ request }) => request));

    const failedRequests = results
      .map((result, index) => ({ result, orderLine: postRequests[index].orderLine }))
      .filter(({ result }) => result.status === 'rejected') as Array<{
      result: PromiseRejectedResult;
      orderLine: number;
    }>;

    if (failedRequests.length > 0) {
      failedRequests.forEach(({ result, orderLine }) =>
        notify(
          'error',
          (result.reason as any)?.response?.data?.messages,
          false,
          t('sales_orders:PASTORDERS_failedToCopyOrderlines', '', { orderLine }),
        ),
      );
      const successfulPromises = results.length - failedRequests.length;
      notify('success', t('sales_orders:PASTORDERS_remainingOrderLinesAdded', '', { successfulPromises }));
    } else {
      notify('success', t('sales_orders:PASTORDERS_allOrderLinesAdded'));
    }
    handleClose();
  };

  return (
    <IptorTable
      tableID={`pastItemsTable-${selectedOrderId}`}
      selectable="multiple"
      apiId="internal.v1.base-orders"
      apiEndpoint="GET /salesOrdersLines"
      columns={[
        { display: t('sales_orders:TABLE_COLUMN_orderLine'), key: 'orderLine', dataType: 'number' },
        {
          display: t('sales_orders:TABLE_COLUMN_items'),
          key: 'item',
          cellRenderer: ({ row }) => (
            <Stack direction="row" sx={{ placeItems: 'center' }}>
              <Stack direction="column">
                <span id="item">{row.item}</span>
                <small id="description">{row.description}</small>
              </Stack>
              <OrderDiscountLabel discount={row.discountsExist} type="line" />
            </Stack>
          ),
        },
        { display: t('sales_orders:TABLE_COLUMN_order'), key: 'order', dataType: 'number' },
        { display: t('sales_orders:TABLE_COLUMN_unit'), key: 'unit' },
        {
          display: t('sales_orders:TABLE_COLUMN_warehouse'),
          key: 'warehouse',
        },
        {
          display: t('sales_orders:TABLE_COLUMN_availableQuantity'),
          key: 'orderQuantity',
          cellRenderer: ({ row }) => {
            return <WarehouseAvailableQuantity warehouse={row.warehouse} item={row.item} />;
          },
          disableFilter: true,
          disableSort: true,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_quantity'),
          key: 'orderQuantity',
          dataType: 'number',
        },
        { display: t('sales_orders:TABLE_COLUMN_unitPrice'), key: 'salesPrice', dataType: 'number' },
      ]}
      additionalFilters={
        <Stack gap={2} direction="row" alignSelf="center" height="max-content">
          <Text>{t('sales_orders:PASTORDERS_itemsSelected')}</Text>
          <Text fontWeight={600}>{selectedRows.length}</Text>
        </Stack>
      }
      additionalActions={
        <>
          <TableFilter />
          <TableSettings />
        </>
      }
      apiParams={{ order: selectedOrderId, status: '' }}
      onSelectedRowsChange={({ rows }) => {
        setSelectedRows(rows);
      }}
    />
  );
});
export default PastItemsTable;

export default (
  <svg width="14" height="18" viewBox="0 0 14 18" color="inherit" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.35587 1.54243C6.72809 1.22898 7.27191 1.22898 7.64414 1.54243L11.9042 5.12983C12.6176 5.73063 12.1928 6.89474 11.26 6.89474H2.73997C1.80723 6.89474 1.38237 5.73063 2.09583 5.12983L6.35587 1.54243Z"
      stroke="currentColor"
      stroke-linejoin="round"
    />
    <path
      d="M7.64413 16.4576C7.27191 16.771 6.72809 16.771 6.35586 16.4576L2.09583 12.8702C1.38237 12.2694 1.80723 11.1053 2.73997 11.1053L11.26 11.1053C12.1928 11.1053 12.6176 12.2694 11.9042 12.8702L7.64413 16.4576Z"
      stroke="currentColor"
      fill="currentColor"
      stroke-linejoin="round"
    />
  </svg>
);

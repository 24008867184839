import { Accordion, Checkbox, Text, TextField, useIptorTranslation, FormControlLabel } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormGetValues } from 'react-hook-form';
import { Order } from '../../../constants';
import {
  InventoryContractDropdown,
  DocumentTypesDropdown,
  TermsOfPaymentsDropdown,
  MergeInvoicesDropdown,
  CalendarIdsDropdown,
} from '@iptor/business';

type InvoicingInfoProps = {
  createProcess?: boolean;
  getValues?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
};

const InvoicingInfo = ({ control, errors, getValues, createProcess }: InvoicingInfoProps) => {
  const { t } = useIptorTranslation();

  return (
    <Accordion
      headingTitle={t('common:TXT_Invoicing_Info')}
      moreInfo={
        <Grid container columnSpacing={3} rowSpacing={2} alignItems="flex-end">
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', marginLeft: 0 }}
              readOnly={!createProcess}
              id={'debtor'}
              control={
                <Text fontSize={14} id="debtor" color="textDisabled">
                  {getValues?.('debtor') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_Debtor')}</Text>}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              readOnly={!createProcess}
              id={'debtorVatRegistrationNo'}
              control={
                <Text id="debtorVatRegistrationNo" fontSize={14} color="textDisabled">
                  {getValues?.('debtorVatRegistrationNo') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_VAT_Reg_No')}</Text>}
              labelPlacement="top"
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid size={12} sx={{ marginBottom: 3 }}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Invoicing_Info')}
          </Text>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="documentType"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DocumentTypesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Document_Type_Table')}
                  readOnly={!createProcess}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="customerReference"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('common:TXT_Invoice_Reference')}
                  id={field.name}
                  fullWidth
                  error={!!errors?.customerReference}
                  readOnly={!createProcess}
                  helperText={errors?.customerReference?.message}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="termsOfPayment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TermsOfPaymentsDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Payment_Terms')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="mergeInvoicing"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MergeInvoicesDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Merge_Invoice')}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="invoicingCalendar"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CalendarIdsDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Invoice_Calendar')}
                />
              )}
            />
          </Grid>

          <Grid size={12 / 2}>
            <Controller
              name="inventoryContractNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InventoryContractDropdown
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  id={field.name}
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  helperText={error ? error.message : ''}
                  label={t('common:TXT_Inventory_Contract')}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}> </Grid>
          <Grid size={12 / 2}> </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={1} size={12 / 2}>
          <Grid size={12 / 2}>
            <Controller
              name="periodicInvoicing"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} />}
                  label={t('common:TXT_Periodic_Invoice')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2}>
            <Controller
              name="holdInvoice"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} />}
                  label={t('common:TXT_Hold_Invoice')}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default InvoicingInfo;

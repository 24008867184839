import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export const InventoryContractDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: orderPropertyOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'inventoryContractNumber',
    params: { windowId: 'inventoryContracts' },
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} loading={loading} options={orderPropertyOptions} columnNames={COLUMN_NAMES} />;
};

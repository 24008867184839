import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type WarehouseDropdownProps = BusinessDropdownProps & {
  item?: string;
};

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export const WarehouseDropdown = ({ item, ...props }: WarehouseDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: warehousesOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-inventory',
    operationId: 'GET /warehouses',
    labelAttribute: 'description',
    valueAttribute: 'warehouse',
    params: item ? { item: item } : { type: 1 },
    shouldPreventApiCall,
  });

  useEffect(() => {
    refetch();
  }, [item]);

  return <BaseDropdown {...props} loading={loading} options={warehousesOptions} columnNames={COLUMN_NAMES} />;
};

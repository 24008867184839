import { Icon, IconType } from '@iptor/base';
import { Box, Tooltip } from '@mui/material';

interface Props {
  orderStatus: number;
}

const statusMap: Record<number, { icon: IconType; text: string }> = {
  0: { icon: 'pickListConfirmation', text: 'Order header created' },
  10: { icon: 'checkList', text: 'Sales order entered' },
  18: { icon: 'packageProcess', text: 'Orders to be delivered later' },
  20: { icon: 'invoice03', text: 'Order Confirmation printed' },
  30: { icon: 'invoice04', text: 'Pick list printed' },
  40: { icon: 'documentValidation', text: 'Pick list confirmed' },
  45: { icon: 'printer', text: 'Transport note printed' },
  50: { icon: 'invoice01', text: 'Under invoicing' },
  60: { icon: 'invoice02', text: 'Invoiced' },
};

function OrderStatus(props: Props) {
  const { orderStatus } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      gap={1}
    >
      <Tooltip title={statusMap[orderStatus]?.text || ''} placement="top" arrow>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            padding: theme.spacing(0.5),
            borderRadius: '50%',
          })}
        >
          <Icon id="invoicing" icon={statusMap[orderStatus]?.icon || 'invoicing'} color="inherit" size="S" />
        </Box>
      </Tooltip>
      {orderStatus}
    </Box>
  );
}

export default OrderStatus;

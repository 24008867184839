export default (
  <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.676 0H24.3222C26.5347 0 28.3283 1.79362 28.3283 4.00615V29.3785C28.3283 31.591 26.5347 33.3846 24.3222 33.3846H4.33447C2.12193 33.3846 0.328308 31.591 0.328308 29.3785L0.328308 9.34769L9.676 0Z"
      fill="#AED319"
    />
    <path
      d="M0.328308 9.34769L9.676 0L9.67581 5.34168C9.67573 7.55416 7.88214 9.34769 5.66966 9.34769H0.328308Z"
      fill="#E9FF91"
    />
    <path
      d="M8.37655 17.5309C7.82815 17.5309 7.40795 17.6983 7.11595 18.033C6.82394 18.3678 6.67794 18.8414 6.67794 19.4539C6.67794 20.0735 6.83819 20.5507 7.15868 20.8854C7.48629 21.2202 7.9243 21.3875 8.4727 21.3875C8.7647 21.3875 9.01041 21.3484 9.20983 21.27C9.41637 21.1917 9.61222 21.1026 9.7974 21.0029C9.91847 21.1026 10.0111 21.2237 10.0752 21.3662C10.1464 21.5015 10.182 21.6617 10.182 21.8469C10.182 22.1389 10.0182 22.3882 9.69057 22.5947C9.37008 22.7941 8.87865 22.8938 8.2163 22.8938C7.73912 22.8938 7.29044 22.8262 6.87023 22.6909C6.45003 22.5555 6.08325 22.349 5.76988 22.0712C5.45651 21.7864 5.20723 21.4303 5.02206 21.0029C4.84401 20.5685 4.75498 20.0521 4.75498 19.4539C4.75498 18.8984 4.84045 18.4105 5.01138 17.9903C5.18943 17.563 5.42802 17.2033 5.72715 16.9113C6.03339 16.6193 6.3895 16.3985 6.79545 16.249C7.20141 16.0994 7.63586 16.0246 8.09879 16.0246C8.75402 16.0246 9.25968 16.1314 9.61579 16.3451C9.97901 16.5588 10.1606 16.833 10.1606 17.1677C10.1606 17.3529 10.1143 17.5131 10.0217 17.6484C9.92916 17.7838 9.82232 17.8906 9.70125 17.9689C9.51608 17.8479 9.31666 17.7446 9.103 17.6591C8.89646 17.5737 8.65431 17.5309 8.37655 17.5309Z"
      fill="white"
    />
    <path
      d="M13.0161 20.1269C12.7027 20.0201 12.4178 19.9133 12.1614 19.8064C11.9051 19.6925 11.6843 19.5572 11.4991 19.4005C11.3139 19.2438 11.1679 19.0586 11.0611 18.8449C10.9614 18.6242 10.9115 18.3571 10.9115 18.0437C10.9115 17.4383 11.143 16.954 11.6059 16.5908C12.076 16.2276 12.7312 16.046 13.5716 16.046C13.8779 16.046 14.1627 16.0673 14.4263 16.1101C14.6898 16.1528 14.9141 16.2205 15.0993 16.3131C15.2916 16.3985 15.4412 16.5125 15.548 16.6549C15.6548 16.7902 15.7082 16.9505 15.7082 17.1357C15.7082 17.3208 15.6655 17.4811 15.58 17.6164C15.4946 17.7446 15.3913 17.855 15.2702 17.9476C15.1135 17.8479 14.9034 17.7624 14.6399 17.6912C14.3764 17.6128 14.088 17.5737 13.7746 17.5737C13.4541 17.5737 13.2191 17.62 13.0695 17.7125C12.9199 17.798 12.8452 17.9084 12.8452 18.0437C12.8452 18.1505 12.8915 18.2396 12.984 18.3108C13.0766 18.3749 13.2155 18.4354 13.4007 18.4924L13.9669 18.674C14.6364 18.8877 15.1491 19.1619 15.5052 19.4966C15.8685 19.8242 16.0501 20.2729 16.0501 20.8427C16.0501 21.4481 15.8115 21.9395 15.3343 22.3169C14.8571 22.6873 14.1556 22.8725 13.2298 22.8725C12.9021 22.8725 12.5959 22.844 12.311 22.787C12.0332 22.7372 11.7875 22.6624 11.5739 22.5627C11.3673 22.4558 11.2035 22.3276 11.0825 22.1781C10.9685 22.0214 10.9115 21.8433 10.9115 21.6439C10.9115 21.4374 10.9721 21.2629 11.0931 21.1204C11.2142 20.9709 11.346 20.8569 11.4884 20.7786C11.6878 20.9353 11.93 21.0706 12.2149 21.1845C12.5069 21.2985 12.8238 21.3555 13.1657 21.3555C13.5146 21.3555 13.7603 21.3021 13.9028 21.1952C14.0452 21.0884 14.1164 20.9638 14.1164 20.8213C14.1164 20.6789 14.0595 20.572 13.9455 20.5008C13.8316 20.4225 13.6713 20.3477 13.4648 20.2765L13.0161 20.1269Z"
      fill="white"
    />
    <path
      d="M20.7841 22.4879C20.6844 22.5733 20.5277 22.6481 20.3141 22.7122C20.1075 22.7763 19.8796 22.8084 19.6303 22.8084C19.3312 22.8084 19.0748 22.7656 18.8612 22.6802C18.6475 22.5876 18.5015 22.463 18.4231 22.3063C18.3448 22.1425 18.2487 21.9324 18.1347 21.676C18.0208 21.4124 17.8997 21.1204 17.7715 20.8C17.6504 20.4795 17.5222 20.1412 17.3869 19.7851C17.2587 19.4218 17.1341 19.055 17.013 18.6847C16.8919 18.3144 16.778 17.9511 16.6711 17.595C16.5643 17.2389 16.4717 16.9042 16.3934 16.5908C16.4931 16.4911 16.6355 16.4021 16.8207 16.3237C17.013 16.2383 17.216 16.1955 17.4296 16.1955C17.6931 16.1955 17.9068 16.2525 18.0706 16.3665C18.2415 16.4733 18.3662 16.6763 18.4445 16.9754C18.6368 17.6734 18.8291 18.3428 19.0214 18.9838C19.2208 19.6177 19.4274 20.28 19.641 20.9709H19.7051C19.8974 20.3014 20.0933 19.5714 20.2927 18.7809C20.4992 17.9903 20.6951 17.2033 20.8803 16.4199C21.0085 16.3558 21.1438 16.3024 21.2862 16.2596C21.4358 16.2169 21.6031 16.1955 21.7883 16.1955C22.0518 16.1955 22.2762 16.2561 22.4614 16.3772C22.6465 16.4911 22.7391 16.6834 22.7391 16.954C22.7391 17.1107 22.6999 17.3386 22.6216 17.6378C22.5504 17.9369 22.4542 18.2752 22.3332 18.6527C22.2192 19.023 22.0874 19.4112 21.9379 19.8171C21.7954 20.2231 21.6494 20.6077 21.4999 20.9709C21.3574 21.327 21.2221 21.6439 21.0939 21.9217C20.9657 22.1923 20.8625 22.381 20.7841 22.4879Z"
      fill="white"
    />
  </svg>
);

// dialogUtils.ts
import { ConfirmationDialogOptions, getConfirmationDialogContext } from '@iptor/base';

export const showDialog = async (
  options: ConfirmationDialogOptions,
): Promise<{ value: string; isConfirmed: boolean }> => {
  const dialogContext = getConfirmationDialogContext();

  return new Promise((resolve) => {
    // Save the resolve function to be called later when the dialog is closed
    dialogContext.showDialog({
      ...options,
      onConfirm: (value) => {
        options?.onConfirm && options.onConfirm?.(value);
        resolve({ value, isConfirmed: true }); // Resolve with confirmed value
      },
      onCancel: () => {
        resolve({ value: undefined, isConfirmed: false }); // Resolve with empty value for cancel
      },
    });
  });
};

import { Stack, StackProps, styled } from '@mui/material';

export interface ActionBarProps extends StackProps {
  alignment?: 'left' | 'right';
}

const StyledActionBar = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<ActionBarProps>(({ theme, alignment = 'right' }) => ({
  backgroundColor: theme.palette.background.default,
  height: '60px',
  alignContent: 'center',
  alignItems: 'center',
  paddingBlock: theme.spacing(6),
  paddingInline: theme.spacing(5),
  justifyContent: alignment === 'left' ? 'flex-start' : 'flex-end',
  boxShadow: theme.shadows[1],
}));

export const ActionBar = ({ alignment = 'right', children, ...props }: ActionBarProps) => {
  return (
    <StyledActionBar alignment={alignment} direction="row" spacing={2} {...props}>
      {children}
    </StyledActionBar>
  );
};

export const FormControlLabel = {
  styleOverrides: {
    root: {
      variants: [
        {
          props: { readOnly: true },
          style: {
            pointerEvents: 'auto!important',
          },
        },
      ],
    },
  },
};

import { Button, DataCardList, DataCardProps, Text, useClientSettings, useIptorTranslation } from '@iptor/base';
import { OperationResponseDataData, useOpenAPIMethod } from '@iptor/business';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import DeleteDiscount from '../../dialogs/DeleteDiscount';
import AddEditDiscount from '../../dialogs/AddEditDiscount';
import DiscountList from '../../dialogs/DiscountList';

interface Props {
  order: number;
  orderLine?: number;
  orderCurrency: string;
}

type DiscountType = OperationResponseDataData<
  'internal.v1.base-orders',
  'GET /salesOrders/{order}/discounts/{generalDiscountKey}'
>;

function DiscountCardsSection(props: Props) {
  const { t } = useIptorTranslation();
  const { order, orderLine, orderCurrency } = props;
  const [selectedDiscounts, setSelectedDiscounts] = useState<DataCardProps[]>([]);
  const [discountToDelete, setDiscountToDelete] = useState<DiscountType | undefined>();
  const [discountToEdit, setDiscountToEdit] = useState<DiscountType | undefined>();
  const [openNewDiscount, setOpenNewDiscount] = useState(false);
  const [openViewAll, setOpenViewAll] = useState(false);
  const { formatNumber, formatCurrency } = useClientSettings();
  const discounts = useOpenAPIMethod(
    'internal.v1.base-orders',
    orderLine ? 'GET /salesOrders/{order}/lines/{orderLine}/discounts' : 'GET /salesOrders/{order}/discounts',
  );

  useEffect(() => {
    discounts.execute({ order, orderLine });
  }, []);

  useEffect(() => {
    if (discounts.response) {
      const value =
        discounts.response?.data?.data?.items.map((row) => ({
          title: t('sales_orders:DISCOUNT_id'),
          subTitle: row.discountPercentage ? t('sales_orders:DISCOUNT_percentage') : t('sales_orders:DISCOUNT_amount'),
          firstVal: row.discountId || ' ',
          secondVal: row.discountIdDescription,
          thirdVal: row.discountPercentage
            ? formatNumber({ number: row.discountPercentage })
            : formatCurrency({
                amount: orderLine ? (row as DiscountType).discountAmountPerUnit : row.headerDiscountAmount,
                currency: orderCurrency,
              }),
          onClose: () => {
            setDiscountToDelete(row);
          },
          onEdit: () => {
            setDiscountToEdit(row);
          },
        })) || [];

      setSelectedDiscounts(value);
    }
  }, [discounts.response?.data?.data?.items]);

  return (
    <Box sx={{ width: '100%' }}>
      <Text variant="h6" fontWeight={500} marginY={2}>
        {t('sales_orders:DISCOUNTS')}
      </Text>
      {openViewAll && !openNewDiscount && !discountToEdit && (
        <DiscountList
          open={openViewAll}
          orderId={order}
          orderLine={orderLine}
          handleClose={() => {
            setOpenViewAll(false);
          }}
          handleEdit={(value) => {
            setDiscountToEdit(value);
          }}
          handleNew={() => {
            setOpenNewDiscount(true);
          }}
          currency={orderCurrency}
        />
      )}
      {discountToDelete && (
        <DeleteDiscount
          open={!!discountToDelete}
          discountToDelete={discountToDelete}
          handleClose={(refresh = true) => {
            setDiscountToDelete(undefined);
            refresh && discounts.execute({ order, orderLine });
          }}
          orderLine={orderLine}
          currency={orderCurrency}
        />
      )}
      {(discountToEdit || openNewDiscount) && (
        <AddEditDiscount
          open={!!discountToEdit || openNewDiscount}
          discountToEdit={discountToEdit}
          handleClose={(refresh = true) => {
            setDiscountToEdit(undefined);
            setOpenNewDiscount(false);
            refresh && discounts.execute({ order, orderLine });
          }}
          isEdit={!!discountToEdit}
          orderId={order}
          orderLine={orderLine}
          currency={orderCurrency}
        />
      )}
      <DataCardList
        slotProps={{
          listContainer: {
            sx: {
              paddingY: 0,
              boxShadow: 'none',
            },
          },
        }}
        loading={discounts.loading}
        options={selectedDiscounts}
        disableDefaultShowAll
        extraAction={[
          <>
            {discounts.response?.data?.data?.items?.length > 0 && (
              <Button
                variant="text"
                size="small"
                id="viewAllDiscounts"
                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                onClick={() => {
                  setOpenViewAll(true);
                }}
              >
                {t('sales_orders:DISCOUNT_viewAll')}
              </Button>
            )}
          </>,
          <Button
            variant="contained"
            iconLeft="plusSign"
            id="addDiscounts"
            size="small"
            sx={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={() => {
              setOpenNewDiscount(true);
            }}
          >
            {t('sales_orders:DISCOUNT_add')}
          </Button>,
        ]}
      />
    </Box>
  );
}

export default DiscountCardsSection;

import { Theme } from '@mui/material';

export const Switch = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: theme.spacing(6),
      height: theme.spacing(3),
      padding: 1,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: theme.spacing(0.5),
        transitionDuration: theme.transitions.duration.standard,
        color: theme.palette.grey[500],

        '&.Mui-checked': {
          transform: `translateX(${theme.spacing(3)})`,
          color: theme.palette.common.white,
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
            outlineColor: theme.palette.primary.main,
            opacity: 1,
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.success.main,
            border: `6px solid ${theme.palette.common.white}`,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: theme.palette.success.main,
          border: `6px solid ${theme.palette.grey[500]}`,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.action.disabledBackground,
          outlineColor: theme.palette.action.disabledBackground,
          opacity: 1,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.grey[400],
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: theme.spacing(2),
        height: theme.spacing(2),
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        height: theme.spacing(2.75),
        borderRadius: theme.spacing(12),
        backgroundColor: 'transparent',
        opacity: 1,
        outlineWidth: 1,
        outlineStyle: 'solid',
        outlineOffset: -0.5,
        outlineColor: theme.palette.grey[500],
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.complex,
        }),
      },
      '&:hover': {
        '& .MuiSwitch-switchBase': {
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.common.white,
          },
        },
        '& .MuiSwitch-track': {
          outlineColor: theme.palette.primary.main,
        },
      },
      '&.Mui-focusVisible': {
        '& .MuiSwitch-track': {
          outlineColor: theme.palette.primary['500'] + ' !important',
        },
      },
    }),
  },
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { WindowActions } from '../../types/actionTypes';
import { Program } from '../../types/program';
import { Command } from './command';
import { Action } from '../../components/Button';
import { exportToFile } from '../../components/helpers/exportUtils';
import { XT } from '../handlers/xt';
import { Localization } from '../localization/Localization';
import { logger } from '../../index';
import store, { RootState } from '../../framework/base/index';
import { AperioViews } from '../../types/AperioViews';
import { desktopState } from './desktopReducer';
import { RequestError } from '../../types/RequestError';
import { showDialog } from './dialogUtils';

const dummy = XT.dummy; // NTH: Fix this in a nice way.  Seems like this dummy statement is needed for Webpack to let him load XT before LoadActions

namespace Helpers {
  export function handleUnexpectedResponseStatusLessThan300(
    actionType: RequestError.ActionType,
    response: AxiosResponse,
    dispatch: Dispatch,
    windowId?: string,
  ) {
    const requestError = RequestError.createFromUnexpectedResponseStatusLessThan300(actionType, response, windowId);
    dispatch({
      type: WindowActions.NEW_REQUEST_ERROR,
      payload: { requestError: RequestError.toJSON(requestError) },
    });
  }
  export function hasToHandleWarnings(
    actionType: RequestError.ActionType,
    getArgs: () => any[],
    processResult: () => any,
    response: AxiosResponse,
    dispatch: Dispatch,
    windowId?: string,
  ): boolean {
    if (response.data.warningInfo && response.data.warningInfo.length > 0) {
      const errorInfo = response.data.warningInfo.map((error: any) => RequestError.ErrorInfo.fromJSON(error));
      const create = RequestError.createFromAxiosRequest;
      const requestError = create(actionType, getArgs(), processResult, errorInfo, undefined, windowId);
      dispatch({
        type: WindowActions.NEW_REQUEST_ERROR,
        payload: { requestError: RequestError.toJSON(requestError) },
      });
      return true;
    }
    return false;
  }
  export function handleErrors(
    actionType: RequestError.ActionType,
    getArgs: () => any[],
    error: AxiosError,
    dispatch: Dispatch,
    processErrorResult?: () => any,
    windowId?: string,
    program?: Program,
  ) {
    let data: any = error.response?.data;
    let errorInfo: RequestError.ErrorInfo[] | undefined;
    if (data?.errorInfo) {
      data.errorInfo.clientMessage = addProgramInfo(data.errorInfo.clientMessage, program);
      errorInfo = [RequestError.ErrorInfo.fromJSON(data?.errorInfo)];
    }
    const create = RequestError.createFromAxiosRequest;
    if (!processErrorResult) processErrorResult = () => {};
    const requestError = create(actionType, getArgs(), processErrorResult, errorInfo, error, windowId);
    dispatch({
      type: WindowActions.NEW_REQUEST_ERROR,
      payload: { requestError: RequestError.toJSON(requestError) },
    });
  }
  function addProgramInfo(serverMessage: string, program?: Program): string {
    if (!program) return serverMessage;
    if (serverMessage !== 'ERR_Launch_failed') return serverMessage;
    return Localization.instance.getString('ERR_Launch_failed') + `: "${program.text} (${program.id})"! `;
  }
}

export const WindowManger = {
  Launch: (program: Program, endLoading: Function, type: string, fromCheckpoint?: RequestError.Checkpoint) => {
    return async (dispatch: Dispatch, getState: any) => {
      // Initialize
      // ==========
      const getArgs = () => [program, endLoading, type];
      const child = logger.childWithFunctionInfo('WindowManger.Launch', { program, type });
      child.trace(`Request launching ${program.id}`);
      let { desktop } = getState();
      let layoutIDs = Object.keys(desktop.layouts);

      // Post launch request
      // ===================
      await axios
        .post('/window/launch', {
          program: program.id,
          title: program.text,
          type: type,
          layouts: layoutIDs,
          view: program.view,
          fromCheckpoint: fromCheckpoint,
        })
        // Handle request ended successfully
        // ---------------------------------
        .then(async (res: AxiosResponse) => {
          try {
            switch (res.status) {
              // --> Handle expected status
              case 200: {
                const processResult = () => {
                  child.trace(`Response launching ${program.id} SUCCESS`);
                  dispatch({
                    type: WindowActions.LAUNCH,
                    payload: { ...res.data, program: program },
                  });
                };
                if (!Helpers.hasToHandleWarnings('LAUNCH', getArgs, processResult, res, dispatch)) {
                  processResult();
                }
                break;
              }
              // --> Handle unexpected status
              default:
                Helpers.handleUnexpectedResponseStatusLessThan300('LAUNCH', res, dispatch);
            }
          } catch (err: any) {
            const requestError = RequestError.createFromError(err, 'ERR_Launch_failed', true); // NTH: logging
            dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
          }
        })
        // Handle request ended in error
        // -----------------------------
        .catch((err: any) => {
          try {
            switch (err.response?.status || 0) {
              // --> Handle expected redirection
              // case 300: {
              //   break;
              // }
              // --> Handle errors
              default:
                Helpers.handleErrors('LAUNCH', getArgs, err, dispatch, undefined, undefined, program);
            }
          } catch (err: any) {
            const requestError = RequestError.createFromError(err, 'ERR_Launch_failed', true); // NTH: logging
            dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
          }
        })
        .finally(() => endLoading());
    };
  },
  Interact: (
    windowId: string,
    command: Command,
    endLoading: Function,
    scopeHandler: Function,
    errHandler?: Function,
    novalidate?: boolean,
    isPageLoad?: boolean,
    exportToFile?: exportToFile,
    isEOF?: boolean,
    disabledOn?: string,
    EOFParams?: {
      columns: any[];
      rowCount: number;
      attributes: Record<string, any>;
      panelID: string;
      cb: Function;
      reload: Function;
      refreshCommand: string;
    },
    fromCheckpoint?: RequestError.Checkpoint,
  ) => {
    return async (dispatch: Dispatch, getState: any) => {
      // Initialize
      // ==========
      const getArgs = () => [
        windowId,
        command,
        endLoading,
        scopeHandler,
        errHandler,
        novalidate,
        isPageLoad,
        exportToFile,
        isEOF,
        disabledOn,
        EOFParams,
      ];
      let { desktop } = getState();
      let _window = desktop.windows[windowId];
      let layoutIDs = Object.keys(desktop.layouts);
      let keepLoading = false;

      // Post interact request
      // =====================
      await axios
        .post(`/window/${windowId}/interact`, {
          command: command.toString(),
          layouts: layoutIDs,
          program: command.getEndpointProgram(_window.program.id),
          novalidate: novalidate || false,
          isPageLoad: isPageLoad || false,
          EOF: isEOF,
          disabledOn: disabledOn,
          panelID: EOFParams?.panelID,
          exportType: exportToFile?.type?.toString(),
          EOFColumns: EOFParams?.columns,
          attributes: EOFParams?.attributes,
          fromCheckpoint: fromCheckpoint,
          refreshCommand: EOFParams?.refreshCommand,
        })
        // Handle request ended successfully
        // ---------------------------------
        .then(async (res: AxiosResponse) => {
          try {
            switch (res.status) {
              // --> Handle expected status
              case 200: {
                const processResult = () => {
                  const formId = res.data?.data?.form?.$?.id || '';
                  if (formId === 'EOF') {
                    dispatch({ type: WindowActions.CLOSE, payload: { window_id: windowId } });
                  } else if (['QueryResult', 'QueryManager'].includes(formId)) {
                    dispatch({
                      type: WindowActions.LAUNCH,
                      payload: {
                        window: { title: _window.title },
                        program: { id: 'query_result', text: 'Query Result' },
                        ...res.data,
                      },
                    });
                  } else {
                    dispatch({
                      type: WindowActions.INTERACT,
                      payload: { window_id: windowId, window: { title: _window.title }, ...res.data },
                    });
                  }
                };
                if (!Helpers.hasToHandleWarnings('INTERACT', getArgs, processResult, res, dispatch, windowId)) {
                  processResult();
                }
                break;
              }
              case 202: {
                keepLoading = true;
                if (res?.data?.exporting) document.body.setAttribute('exporting', 'true');
                break;
              }
              // --> Handle unexpected status
              default: {
                Helpers.handleUnexpectedResponseStatusLessThan300('INTERACT', res, dispatch, windowId);
              }
            }
          } catch (err: any) {
            const requestError = RequestError.createFromError(err, 'ERR_Interact_failed', true, windowId); // NTH: logging
            dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
          }
        })
        // Handle request ended in error
        // -----------------------------
        .catch((err: any) => {
          /*
            IMPORTANT REMARK:                                                        
              Currently part of the server interaction is rewritten for enhanced error handling, ending up with two different situations now (budget limitations).
              The "old" window error handler was not pure error handling, but did also contain some code to reset some variables. That's why in the new situation
              we will still include a call to the "old" error handler. For now, as a workaround, in order to make it possible to distinguish the two different 
              situations inside the errorhandler(s) we will append an new attribute to the error: advanced = true
          */
          try {
            const errorHandler = errHandler || WindowManger.errorHandler;
            err.advanced = true; // --> IMPORTANT_REMARK_20240324

            switch (err?.response?.status || 0) {
              // --> Handle expected redirection
              case 300: {
                // Basic confirmation (e.g. delete spool file)
                // dispatch({
                //   type: WindowActions.INTERACT,
                //   payload: { window_id: windowId, window: { title: _window.title }, ..._window },
                // });
                errorHandler(err);
                break;
              }
              case 320: {
                // Change spool file attributes
                errorHandler(err);
                break;
              }
              // --> Handle errors
              default: {
                // ... determine whether "logic/soft" errors (eg: not allowed to select multiple rows if you want to view a spool file)
                let isSoftError = false;
                let serverData: string = '';
                if (err?.response?.status === 403) {
                  const errorInfo: RequestError.ErrorInfo.JSON | undefined = err?.response?.data?.errorInfo;
                  if (errorInfo) {
                    serverData = errorInfo.serverData;
                    if (typeof serverData === 'string' && !serverData.includes('403')) isSoftError = true;
                  }
                }

                // ... handle "logic/soft" errors
                if (isSoftError) {
                  // "convert" response to old situation (until everything follows new system)
                  err.response.status = 350;
                  err.response.data = serverData;
                  delete err.advanced;
                  errorHandler(err);
                }

                // ... handle other errors
                if (!isSoftError) {
                  const processResult = () => {
                    errorHandler(err); // Only to execute the last line of code ;-(
                  };
                  Helpers.handleErrors('INTERACT', getArgs, err, dispatch, processResult, windowId);
                }
              }
            }
          } catch (err: any) {
            const requestError = RequestError.createFromError(err, 'ERR_Interact_failed', true, windowId); // NTH: logging
            dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
          }
        })
        // Finalize
        // --------
        .finally(() => {
          if (!keepLoading) {
            endLoading();
            scopeHandler();
          }
        });
    };
  },
  CloseWindow: (windowId: string, endLoading: Function) => {
    return async (dispatch: Dispatch, getState: any) => {
      try {
        if (windowId !== '') {
          const { desktop } = getState();

          await axios
            .post(`/window/${windowId}/close`, {
              command: '',
              program: { view: desktop?.windows[windowId]?.program?.view },
            })
            .catch((err) => {
              if(err?.response?.status === 401){
                Helpers.handleErrors('NONE', () => [], err, dispatch);
              } else {
                WindowManger.errorHandler(err);
              }
            });
          dispatch({ type: WindowActions.CLOSE, payload: { window_id: windowId } });
        }
      } catch (err) {
        if(err?.response?.status === 401){
          Helpers.handleErrors('NONE', () => [], err, dispatch);
        } else {
          WindowManger.errorHandler(err);
        }
      } finally {
        endLoading();
      }
    };
  },
  LoadContext: (
    windowId: string,
    payload: any,
    command: Command | undefined,
    endLoading: Function,
    scopeHandler: Function,
  ) => {
    return (dispatch: Dispatch, getState: any) => {
      let { desktop } = getState();
      let window = desktop.windows[windowId];
      let layoutIDs = Object.keys(desktop.layouts);
      // dispatch({})
      if (!!command) {
        axios
          .post(`/window/${windowId}/load/context`, {
            command: command?.toString(),
            layouts: layoutIDs,
            payload: payload,
          })
          .then((res: AxiosResponse) => {
            dispatch({
              type: WindowActions.LOAD_CONTEXT,
              payload: {
                window_id: windowId,
                window: window,
                formID: payload.formID,
                context: payload.table,
                ...res.data,
              },
            });
          })
          .catch((err: any) => WindowManger.errorHandler(err))
          .finally(() => {
            endLoading();
            scopeHandler();
          });
      } else {
        let contextOptions: any = {};
        for (let i in payload.firstEntries) {
          let entry = payload.firstEntries[i] || '';
          let options: string[] = entry.split('=') || [];
          let option = options[0].trim();
          for (let j = 1; j < options.length; j++) {
            let str = options[j].trim();
            let endIndex = j === options.length - 1 ? -1 : str.lastIndexOf(' ');
            contextOptions[option] = endIndex === -1 ? str : str.substring(0, endIndex).trim();
            option = str.substring(endIndex).trim();
          }
        }
        dispatch({
          type: WindowActions.LOAD_CONTEXT,
          payload: {
            window_id: windowId,
            window: window,
            formID: payload.formID,
            context: payload.table,
            data: { contextOptions: contextOptions },
          },
        });
        endLoading();
        scopeHandler();
      }
    };
  },
  LoadActions: (windowId: string, command: Command, template: Action, endLoading: Function, scopeHandler: Function) => {
    return (dispatch: Dispatch, getState: any) => {
      let { desktop } = getState();
      let window = desktop.windows[windowId];
      let layoutIDs = Object.keys(desktop.layouts);
      axios
        .post(`/window/${windowId}/load/actions`, {
          command: command.toString(),
          layouts: layoutIDs,
          template: template,
        })
        .then((res: AxiosResponse) => {
          dispatch({
            type: WindowActions.LOAD_ACTIONS,
            payload: { window_id: windowId, window: window, ...res.data },
          });
        })
        .catch((err: any) => WindowManger.errorHandler(err))
        .finally(() => {
          endLoading();
          scopeHandler();
        });
    };
  },
  UpdateSettings: () => {
    return (dispatch: Dispatch, getState: any) => {
      let { desktop, settings } = getState();
      const userId =
        desktop.settings.regionals.user[0].$.userId || JSON.parse(sessionStorage.getItem('user') || '')?.id;
      const companyId =
        desktop.settings.regionals.user[0].$.activeCompany || JSON.parse(sessionStorage.getItem('company') || '')?.id;
      if (userId) {
        axios
          .post('/client/settings', { settings: settings, userId: userId, companyId: companyId })
          .catch((err: any) => WindowManger.errorHandler(err));
      }
    };
  },
  logout: (startLoading?: Function, endLoading?: Function) => {
    if (startLoading) startLoading();
    let { desktop } = store.getState();
    const settings = JSON.parse(sessionStorage.getItem('clientSettings') || '{}'); //Get latest data from session storage as modules do not have access to the base store
    const userId = desktop.settings.regionals.user[0].$.userId || JSON.parse(sessionStorage.getItem('user') || '')?.id;
    const companyId =
      desktop.settings.regionals.user[0].$.activeCompany || JSON.parse(sessionStorage.getItem('company') || '')?.id;

    axios
      .post('/auth/logout', {
        settings: settings,
        userId: userId,
        companyId: companyId,
      })
      .catch((err) => {
        logger
          .childWithFunctionInfo('logout')
          .debug(`Error: ${err}`, { api: '/auth/logout', settings: settings, userId: userId, companyId: companyId });
      })
      .finally(async () => {
        try {
          // Get session values we want to keep
          // ----------------------------------
          const user = sessionStorage.getItem('user') || '';
          const enq = sessionStorage.getItem('enquiry_window') || '';
          // const theme = sessionStorage.getItem('theme') || '';

          // Clear session storage
          // ---------------------
          sessionStorage.clear();

          // Restore session values we want to keep
          // --------------------------------------
          sessionStorage.setItem('user', user);
          sessionStorage.setItem('enquiry_window', enq);
          // sessionStorage.setItem('theme', theme);

          // Finalize logout
          // ---------------
          window.history.replaceState(null, '', '/');
          if (!process.env.REACT_APP_IS_DISPATCHER) {
            window.location.replace('/logout');
          }
        } finally {
          if (endLoading) endLoading();
        }
      });
  },
  SetActiveAperioLinks: (activeAperioLinks: AperioViews.Link[]) => {
    return async (dispatch: Dispatch, getState: any) => {
      // Get current state
      // -----------------
      const desktop = getState().desktop as desktopState;
      const contextArea = desktop.contextAreas[desktop.activeWindow];

      // Determine smooth transition
      // --------------------------
      /*
        Remark: smooth transition includes some delay of resetting command of previous form/panel to avoid "flickering" between different aperio 
        views (background: grey -> white ->  grey)
        At least two known scenerios:
          1. aperio view open & call form where aperio onload event is defined
          2. aperio view open & call action returning a business error (eg try to change a locked order while aperio is visible) 
      */

      // --> initialize
      let smoothTransitionRequested = true;

      // --> never if currently no aperio command to show data
      if (!contextArea?.aperioViewCommand) {
        smoothTransitionRequested = false;

        // --> never if next state has no links defined
      } else if (!activeAperioLinks || activeAperioLinks.length < 1) {
        smoothTransitionRequested = false;
      }

      // Change redux state
      // ------------------
      if (!smoothTransitionRequested) {
        dispatch({
          type: WindowActions.UPDATE_CONTEXT,
          payload: { activeAperioLinks },
        });
      } else {
        // --> start closing process
        dispatch({
          type: WindowActions.UPDATE_CONTEXT,
          payload: {
            activeAperioLinks,
            aperioViewStatus: 'CLOSING',
          },
        });

        // --> force end of closing process (if still needed)

        // ... initialize delay
        let ms = 200;

        // ... give some more time for tables with the row selection event (as row selection is also debounced with 300 ms)
        if (
          activeAperioLinks.some((link) =>
            link.events.some((event) => event.type === AperioViews.EventTypes.ON_ROW_SELECTION),
          )
          // && related table will have focus && ...: exact condition too complicated for now,  let's see if somebody complains when using this simplified condition
        ) {
          ms = 400;
        }

        setTimeout(() => {
          dispatch({
            type: WindowActions.UPDATE_CONTEXT,
            payload: {
              aperioViewStatus: 'CLOSED',
            },
          });
        }, ms);
      }
    };
  },

  errorHandler: (err: any, title?: string, msg?: string, logout: boolean = false) => {
    if (err?.advanced) return; // --> see IMPORTANT_REMARK_20240324

    // force logout when xt session expired or unknown error appeard and inform user about that
    if (err?.response?.status === 401 || err?.response?.status?.toString()?.substring(0, 1) === '5') {
      logout = true;
      title = Localization.instance.getString('SWAL_NOT_AUTHORIZED_TITLE');
      msg = Localization.instance.getString('SWAL_NOT_AUTHORIZED_TEXT');
    }

    showDialog({
      title: title,
      message: msg ?? Localization.instance.getString('TXT_UNKNOWN_ERROR'),
      icon: logout ? 'error' : 'warning',
      completeScreenBlock: true,
      restrictOutsideClick: true,
      hideCancelButton: true,
      confirmText: Localization.instance.getString('TXT_OK'),
      overlayContainerId: 'legacy',
      onCancel: () => {
        if (logout) {
          WindowManger.logout();
        }
      },
    });
  },

  LoadEnquiryTemplateSettings: (enquiryTemplate: string, endLoading: Function) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      // Initialize
      // ==========
      const templates = getState().desktop.enquiries?.top?.templates?.[0]?.template;
      const next = {
        id: enquiryTemplate,
        seq:
          templates?.findIndex((template: any) => {
            return template.$.id === enquiryTemplate;
          }) + 1,
      };
      const defaultTemplate = getState().desktop.enquiries?.top?.defaultTemplate[0].$.id;
      const current = {
        id: defaultTemplate,
        seq:
          templates?.findIndex((template: any) => {
            return template.$.id === defaultTemplate;
          }) + 1,
      };

      // Switching template
      // ==================
      axios
        .post('/client/enquiry', {
          next,
          current,
        })
        .then(async (res: AxiosResponse) => {
          try {
            dispatch({ type: WindowActions.UPDATE_SETTINGS, payload: { enquiries: res.data.enquiries } });
          } catch (err: any) {
            const requestError = RequestError.createFromError(err, 'CHANGE_ENQUIRY_TEMPLATE_FAILED_TITLE', false); // NTH: logging
            dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
          }
        })
        .catch((err: Error) => {
          const requestError = RequestError.createFromError(err, 'CHANGE_ENQUIRY_TEMPLATE_FAILED_TITLE', false); // NTH: logging
          dispatch({ type: WindowActions.NEW_REQUEST_ERROR, payload: { requestError } });
        })
        .finally(() => {
          endLoading();
        });
    };
  },
};

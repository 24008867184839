import { forwardRef, PropsWithChildren } from 'react';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material';

export type DialogProps = MuiDialogProps & {
  className?: string;
  windowId?: string;
};

export const Dialog = forwardRef(
  (
    {
      className,
      windowId = 'soe',
      container = document.getElementById('overlay-container' + windowId),
      component,
      children,
      ...props
    }: PropsWithChildren<DialogProps>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <MuiDialog
        {...props}
        ref={ref}
        className={className}
        component={component}
        container={container}
        sx={{
          '.MuiBackdrop-root.MuiModal-backdrop': {
            top: '100px',
            left: ' 59px',
          },
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
          ...props.sx,
        }}
      >
        {children}
      </MuiDialog>
    );
  },
);

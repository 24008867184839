export const Drawer = {
  styleOverrides: {
    root: {
      variants: [
        {
          props: { variant: 'permanent', color: 'primary' },
          style: {
            '& .MuiPaper-root': {
              backgroundColor: '#2F3C46',
              top: '100px',
              bottom: 0,
              left: 0,
              zIndex: 'unset',
              svg: {
                color: 'white',
              },
            },
          },
        },
      ],
    },
  },
};

import { IconButton, useTheme } from '@mui/material';
import { Check, ContentCopy } from '@mui/icons-material';
import { useState } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { Icon } from '../Icon';
import { useTooltipVisibility } from '../TooltipVisibilityContext';
// import { useTooltipVisibility, useTheme } from '@iptor/base';

export type CustomTooltipProps = TooltipProps & {
  isTooltipVisible?: boolean;
};

export const CopyableTooltip = (props: CustomTooltipProps) => {
  const theme = useTheme();
  const { isTooltipVisible } = useTooltipVisibility();
  const [copied, setCopied] = useState(false);
  const [copyError, setCopyError] = useState(false);

  const handleCopy = (text: string) => {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        });
      } else {
        setCopyError(true);
        setTimeout(() => setCopyError(false), 1000);

        throw new Error('Clipboard API not available');
      }
    } catch (err) {
      try {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      } catch (fallbackErr) {
        setCopyError(true);
        setTimeout(() => setCopyError(false), 1000);
        console.error('Copy failed:', fallbackErr);
      }
    }
  };
  return (
    <ReactTooltip
      id="global"
      disable={!isTooltipVisible}
      textColor={theme.palette.info.main}
      clickable
      //   html={true}
      //   event="mouseenter focus"
      delayHide={500}
      backgroundColor={theme.palette.info[100]}
      getContent={(dataTip) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {dataTip}
          {copyError ? (
            <Icon sx={{ color: theme.palette.error.main }} icon="cancelCircle" fontSize="small" />
          ) : (
            <IconButton
              size="small"
              onClick={() => handleCopy(dataTip)}
              disabled={copied}
              sx={{ outline: 'none', color: theme.palette.info.main }}
            >
              {copied ? <Check fontSize="small" /> : <ContentCopy fontSize="small" />}
            </IconButton>
          )}
        </div>
      )}
      //   place="right"
      {...props}
    />
  );
};

export enum WindowActions {
  LAUNCH = 'LAUNCH',
  CLOSE = 'CLOSE',
  EDIT = 'EDIT',
  RENAME = 'RENAME',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  UPDATE_CONTEXT = 'UPDATE_CONTEXT',
  LOAD_ATTACHMENTS = 'LOAD_ATTACHMENTS',
  SWITCH = 'SWITCH',
  INTERACT = 'INTERACT',
  LOAD_CONTEXT = 'LOAD_CONTEXT',
  LOAD_ACTIONS = 'LOAD_ACTIONS',
  CHANGE_LOCALE_SETTINGS = 'CHANGE_LOCALE_SETTINGS',
  NEW_REQUEST_ERROR = 'NEW_REQUEST_ERROR',
  CONFIRM_REQUEST_ERROR = 'CONFIRM_REQUEST_ERROR'
}

export enum TableActions {
  SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS',
  SET_COLUMN_WIDTHS = 'SET_COLUMN_WIDTHS',
  SET_COLUMN_ORDER = 'SET_COLUMN_ORDER',
  RESET_COLUMN_ORDER = 'RESET_COLUMN_ORDER',
  RESET_COLUMN_WIDTH = 'RESET_COLUMN_WIDTH',
  SET_DEFAULT_ACTION = 'SET_DEFAULT_ACTION',
  SET_TOOLTIP_SEQUENCE = 'SET_TOOLTIP_SEQUENCE',
  TOGGLE_TOOLTIP = 'TOGGLE_TOOLTIP',
  SET_COLUMN_SORT_BY = 'SET_COLUMN_SORT_BY'
}

export enum SettingsActions {
  LOAD = 'LOAD',
  UPDATE = 'UPDATE',
  QUICK_LINKS_UPDATE = 'QUICK_LINKS_UPDATE',
  QUICK_LINKS_REORDER = 'QUICK_LINKS_REORDER',
  AUTO_LINKS_UPDATE = 'AUTO_LINKS_UPDATE'
}

export enum AppActions {
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR'
}

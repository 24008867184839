import { TableRowValues } from '@iptor/base';
import { openAPIManager } from '@iptor/business';
import React, { useEffect } from 'react';

interface rowState {
  orderId: number;
  row: Record<string, any>;
  setColumnValueForRow?: (values: { column: string; value: TableRowValues }[], currentIndex?: number) => void;
  value?: string;
}

const OrderSimulation = ({ orderId, row, setColumnValueForRow }: rowState) => {
  useEffect(() => {
    openAPIManager
      .execute('internal.v1.base-orders', 'GET /salesOrders/{order}/lineSimulations', {
        order: orderId,
        item: row.item,
        orderQuantitySalesUnit: row.quantity ? row.quantity : 1,
      })
      .then((response) => {
        setColumnValueForRow([
          { column: 'warehouse', value: response?.data?.data.warehouse },
          {
            column: 'salesPrice',
            value: response?.data?.data.salesPrice,
          },
          {
            column: 'availableQuantity',
            value: response?.data?.data.availableQuantity,
          },
          { column: 'salesPrimaryCurrency', value: response?.data?.data.salesPrimaryCurrency },
        ]);
      })
      .catch(() => {
        setColumnValueForRow([
          { column: 'warehouse', value: '-' },
          {
            column: 'salesPrice',
            value: '-',
          },
          {
            column: 'availableQuantity',
            value: '-',
          },
        ]);
      });
  }, []);

  return <></>;
};

export default OrderSimulation;

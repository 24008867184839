import { forwardRef, PropsWithChildren } from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  styled,
} from '@mui/material';

export type FormControlLabelProps = MuiFormControlLabelProps & {
  className?: string;
  readOnly?: boolean;
};

const StyledFormControlLabel = styled(MuiFormControlLabel)<{ readOnly?: boolean }>(({ theme, readOnly }) => ({
  ...(readOnly && {
    cursor: 'default',
    pointerEvents: 'none',
    '& .MuiFormControlLabel-label': {
      color: `${theme.palette.foreground[600]} !important`,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.text.disabled,
    },
  }),
}));

export const FormControlLabel = forwardRef(
  ({ className, readOnly, disabled, ...props }: PropsWithChildren<FormControlLabelProps>, ref: React.Ref<unknown>) => {
    return (
      <StyledFormControlLabel
        className={className}
        ref={ref}
        {...props}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        data-iscapture="true"
        data-for="global"
      />
    );
  },
);

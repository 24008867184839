import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  LiveSearchCustomers,
  BusinessPartners,
  BusinessPartnerType,
  LiveSearchBusinessPartnersProps,
} from '@iptor/business';

interface LiveSearchCustomersWithTableProps extends LiveSearchBusinessPartnersProps {
  setValue?: (name: string, value: any) => void;
  selectedValue?: string;
  fullWidthTable?: boolean;
}

const LiveSearchCustomersWithTable: React.FC<LiveSearchCustomersWithTableProps> = ({
  name,
  label,
  required = false,
  error,
  helperText,
  setValue,
  selectedValue,
  fullWidthTable,
  ...props
}) => {
  const [openBusinessPartners, setOpenBusinessPartners] = useState(false);
  const previousValueRef = useRef<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const handleLiveSearchBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event?.relatedTarget?.id === 'ViewAllId') {
        return;
      }
      const value = event?.target?.value?.trimEnd()?.toUpperCase();
      if (value !== previousValueRef.current) {
        previousValueRef.current = value;
        if (value) {
          setValue(name, value);
          setInputValue(value);
        }
      }
    },
    [setValue, name],
  );

  const handleCustomerChange = (reason: string, value: string, fieldOnChange?: (value: string) => void) => {
    if (reason === 'selectOption' && value) {
      setValue(name, value);
      fieldOnChange?.(value);
    } else if (reason === 'clear') {
      setValue(name, '');
      fieldOnChange?.('');
    }
  };

  useEffect(() => {
    setInputValue(selectedValue || '');
  }, [selectedValue]);

  return (
    <>
      <LiveSearchCustomers
        {...props}
        error={!!error}
        onBlur={handleLiveSearchBlur}
        liveSearchInputValue={inputValue}
        onLiveSearchInputChange={(e, value, reason) => {
          setInputValue(value);
          handleCustomerChange(reason, value);
        }}
        color={error ? 'error' : 'primary'}
        helperText={helperText || ''}
        required={required}
        label={label}
        autoFocus
        fullWidth
        handleViewAll={{
          onRequested: () => setOpenBusinessPartners(true),
          enabled: true,
        }}
      />

      {openBusinessPartners && (
        <BusinessPartners
          open={openBusinessPartners}
          handleClose={() => setOpenBusinessPartners(false)}
          handleSelected={(row: Record<string, any>) => {
            handleCustomerChange('selectOption', row['businessPartner']);
            setOpenBusinessPartners(false);
          }}
          initialValue={inputValue}
          businessPartnerType={BusinessPartnerType.Customer}
          fullWidth={fullWidthTable}
        />
      )}
    </>
  );
};

export default LiveSearchCustomersWithTable;

import { Theme } from '@mui/material';

export const Menu = {
  styleOverrides: {
    paper: ({ theme }: { theme: Theme }) => ({
      fontSize: '16px',
      borderRadius: '8px',
      boxShadow: theme.shadows[1],
      gap: '2px',
      variants: [
        {
          props: { color: 'light' },
          style: {
            background: theme.palette.common.white,
          },
        },
        {
          props: { color: 'dark' },
          style: {
            background: theme.palette.tertiary.bigStone,
          },
        },
      ],
    }),
  },
};

import React, { Context } from 'react';
import IconType from 'src/constants/IconType';

export type Program = {
  id: string;
  text: string;
  type?: string;
  view?: string;
  isAutoStart?: boolean;
  image?: IconType;
};

export type ApplicationContextType = {
  applicationID: string;
  quicklinks: Program[];
  quickLaunch: (program: Program, endLoading: () => void, type: string) => void;
  endLoading?: () => void;
  startLoading?: () => void;
  flattenedMenu: Record<string, any>;
  getMenuItems?: (programId: string, flattenedMenu: Record<string, any>) => Program;
  handleShowMenu: (menuId: string) => void;
  mainMenu: any;
  handleShowFavourites: () => void;
  desktopState?: any;
  restoreFocusWindow?: (timeoutInterval?: number) => void;
  refresh?: () => void;
  handleSettingsShow: () => void;
  handleRoleShow: () => void;
  dateFormat?: string;
  userProfile?: Record<string, string>;
  closeWindow: () => void;
};

export const ApplicationContext: Context<ApplicationContextType> = React.createContext<ApplicationContextType>({
  applicationID: '',
  quicklinks: [],
  quickLaunch: (program: Program, endLoading: () => void, type = 'program') => {
    return;
  },
  flattenedMenu: {},
  handleShowMenu: (menuId: string) => {
    return;
  },
  mainMenu: {},
  handleShowFavourites: () => {
    return;
  },
  handleSettingsShow: () => {
    return;
  },
  handleRoleShow: () => {
    return;
  },
  closeWindow: () => {
    return;
  },
});

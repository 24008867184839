export default (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" color="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.06142 17.9273L7.3695 16.923C6.94009 16.6681 6.72539 16.5407 6.4871 16.5333C6.22961 16.5254 6.01109 16.6476 5.54709 16.923C5.02741 17.2315 3.99407 18.0812 3.32565 17.676C2.91663 17.428 2.91663 16.7982 2.91663 15.5384V6.66699C2.91663 4.30997 2.91663 3.13146 3.64886 2.39923C4.38109 1.66699 5.5596 1.66699 7.91663 1.66699H12.0833C14.4403 1.66699 15.6188 1.66699 16.3511 2.39923C17.0833 3.13146 17.0833 4.30997 17.0833 6.66699V15.5384C17.0833 16.7982 17.0833 17.428 16.6743 17.676C16.0059 18.0812 14.9725 17.2315 14.4528 16.923C14.0234 16.6681 13.8087 16.5407 13.5704 16.5333C13.3129 16.5254 13.0944 16.6476 12.6304 16.923L10.9385 17.9273C10.4821 18.1981 10.2539 18.3336 9.99996 18.3336C9.74603 18.3336 9.5178 18.1981 9.06142 17.9273Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 6.66602L7.5 11.666"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 11.666H12.4925M7.50747 6.66602H7.5"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

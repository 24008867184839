export default (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      d="M9.47059 21C5.47751 21 3.48098 21 2.24049 19.8284C1 18.6569 1 16.7712 1 13L1 9C1 5.22876 1 3.34315 2.24049 2.17157C3.48098 1 5.47752 1 9.47059 1L10.5294 1C14.5225 1 16.519 1 17.7595 2.17157C19 3.34315 19 5.22876 19 9M9.5 21H11"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path d="M6 6H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M6 11H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path
      d="M15.5 17.5896L14.5978 20.7428C14.5572 20.9011 14.7139 21.0385 14.8659 20.9778L16.8514 20.1849C16.9468 20.1468 17.0532 20.1468 17.1486 20.1849L19.1531 20.9854C19.3014 21.0446 19.456 20.9149 19.4231 20.7589L18.6589 17.4911M20 14.9951C20 13.341 18.6569 12 17 12C15.3431 12 14 13.341 14 14.9951C14 16.6493 15.3431 17.9902 17 17.9902C18.6569 17.9902 20 16.6493 20 14.9951Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

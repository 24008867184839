import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAuth, AuthContextProps } from 'react-oidc-context';
import { createContext, useContextSelector } from 'use-context-selector';

export type CustomAuthContextProps = Partial<
  Pick<
    AuthContextProps,
    'isAuthenticated' | 'isLoading' | 'signinRedirect' | 'signoutRedirect' | 'signinSilent' | 'activeNavigator'
  >
> & {
  userLocale?: string;
  userId?: string;
  preferredUsername?: string;
  profileName?: string;
  issuer?: string;
  clientId?: string;
  getUser?: () => Pick<AuthContextProps, 'user'>['user'] | null;
  givenName?: string;
  familyName?: string;
  email?: string;
};

const CustomAuthContext = createContext<CustomAuthContextProps>({});
export const CustomAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const userRef = useRef(auth.user);

  useEffect(() => {
    userRef.current = auth.user;
  }, [auth.user]);

  const getUser = useCallback(() => {
    return userRef.current ?? null;
  }, []);

  const selectedAuth = useMemo(
    () => ({
      isAuthenticated: auth?.isAuthenticated,
      isLoading: auth?.isLoading,
      userLocale: auth?.user?.profile.locale,
      signinRedirect: auth?.signinRedirect,
      signoutRedirect: auth?.signoutRedirect,
      signinSilent: auth?.signinSilent,
      activeNavigator: auth?.activeNavigator,
      userId: auth?.user?.profile.app_user_id as string,
      preferredUsername: auth?.user?.profile.preferred_username,
      profileName: auth?.user?.profile.name,
      givenName: auth?.user?.profile.given_name,
      familyName: auth?.user?.profile.family_name,
      email: auth?.user?.profile.email,
      issuer: auth?.user?.profile.iss,
      clientId: auth?.settings.client_id,
      getUser: getUser,
    }),
    [
      auth?.isAuthenticated,
      auth?.isLoading,
      auth?.user?.profile.locale,
      auth?.signinRedirect,
      auth?.signoutRedirect,
      auth?.signinSilent,
      auth?.activeNavigator,
      auth?.user?.profile.app_user_id,
      auth?.user?.profile.preferred_username,
      auth?.user?.profile.name,
      auth?.user?.profile.given_name,
      auth?.user?.profile.family_name,
      auth?.user?.profile.email,
      auth?.user?.profile.iss,
      auth?.settings.client_id,
      getUser,
    ],
  );

  return <CustomAuthContext.Provider value={selectedAuth}>{children}</CustomAuthContext.Provider>;
};

export const useCustomAuth = (functionToSelect: (auth: CustomAuthContextProps) => CustomAuthContextProps) => {
  return useContextSelector(CustomAuthContext, functionToSelect);
};

export default (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2C1 1.44772 1.44772 1 2 1H4C4.55228 1 5 1.44772 5 2V4C5 4.55228 4.55228 5 4 5H2C1.44772 5 1 4.55228 1 4V2Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M7 2C7 1.44772 7.44772 1 8 1H10C10.5523 1 11 1.44772 11 2V4C11 4.55228 10.5523 5 10 5H8C7.44772 5 7 4.55228 7 4V2Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M13 2C13 1.44772 13.4477 1 14 1H16C16.5523 1 17 1.44772 17 2V4C17 4.55228 16.5523 5 16 5H14C13.4477 5 13 4.55228 13 4V2Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M1 8C1 7.44772 1.44772 7 2 7H4C4.55228 7 5 7.44772 5 8V10C5 10.5523 4.55228 11 4 11H2C1.44772 11 1 10.5523 1 10V8Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M7 8C7 7.44772 7.44772 7 8 7H10C10.5523 7 11 7.44772 11 8V10C11 10.5523 10.5523 11 10 11H8C7.44772 11 7 10.5523 7 10V8Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M13 8C13 7.44772 13.4477 7 14 7H16C16.5523 7 17 7.44772 17 8V10C17 10.5523 16.5523 11 16 11H14C13.4477 11 13 10.5523 13 10V8Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M1 14C1 13.4477 1.44772 13 2 13H4C4.55228 13 5 13.4477 5 14V16C5 16.5523 4.55228 17 4 17H2C1.44772 17 1 16.5523 1 16V14Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M7 14C7 13.4477 7.44772 13 8 13H10C10.5523 13 11 13.4477 11 14V16C11 16.5523 10.5523 17 10 17H8C7.44772 17 7 16.5523 7 16V14Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <path
      d="M13 14C13 13.4477 13.4477 13 14 13H16C16.5523 13 17 13.4477 17 14V16C17 16.5523 16.5523 17 16 17H14C13.4477 17 13 16.5523 13 16V14Z"
      stroke="currentColor"
      stroke-width="1.3"
    />
  </svg>
);

import { Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { Box, useClientSettings, useIptorTranslation } from '@iptor/base';
import { Order } from '../constants';

type OrderInsightsProps = {
  order: Order;
  removeBottomBorder?: boolean;
};

const OrderInsights = ({ order, removeBottomBorder }: OrderInsightsProps) => {
  const { t } = useIptorTranslation();

  const { formatCurrency, currency } = useClientSettings();

  return (
    <>
      {!order ? (
        <Skeleton
          variant="rectangular"
          height={93}
          animation="pulse"
          sx={(theme) => ({
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            borderBottomLeftRadius: removeBottomBorder ? 0 : theme.spacing(1),
            borderBottomRightRadius: removeBottomBorder ? 0 : theme.spacing(1),
          })}
        />
      ) : (
        <Box
          variant="primary"
          sx={(theme) => ({
            color: theme.palette.foreground[400],
            backgroundColor: theme.palette.primary[100],
            borderBottomLeftRadius: removeBottomBorder ? 0 : 'unset',
            borderBottomRightRadius: removeBottomBorder ? 0 : 'unset',
          })}
        >
          <Grid container component="dl" spacing={2}>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Customer_Number')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  id={'customer'}
                  data-iscapture="true"
                  data-for="global"
                  data-tip="customer"
                  fontSize={16}
                  fontWeight={600}
                  // data-tooltip="customer"
                >
                  {order.customer}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Customer_Name')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="customerName"
                  id={'customerName'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {order.customerName}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Default_Warehouse')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="warehouse"
                  id={'warehouse'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {order.warehouse}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Order_Number')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="orderNumber"
                  id={'orderNumber'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {order.order}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Order_Type')}
                </Typography>
              </dt>
              <dd>
                <Typography data-iscapture="true" data-for="global" data-tip="orderType" fontSize={16} fontWeight={600}>
                  {order.orderType}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Credit_Limit')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="creditLimit"
                  id={'creditLimit'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {formatCurrency({
                    amount: order.creditLimit,
                    currency: currency,
                  })}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Credit_Balance')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="totalBalanceSysCurr"
                  id={'totalBalanceSysCurr'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {formatCurrency({
                    amount: order.totalBalanceSysCurr,
                    currency: currency,
                  })}
                </Typography>
              </dd>
            </Grid>
            <Grid size={12 / 8}>
              <dt>
                <Typography fontSize={14} fontWeight={400}>
                  {t('common:TXT_Order_Value')}
                </Typography>
              </dt>
              <dd>
                <Typography
                  data-iscapture="true"
                  data-for="global"
                  data-tip="totalAmountInclVATSys"
                  id={'totalAmountInclVATSys'}
                  fontSize={16}
                  fontWeight={600}
                >
                  {formatCurrency({
                    amount: order.totalAmountInclVATSys,
                    currency: currency,
                  })}
                </Typography>
              </dd>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OrderInsights;

import { Checkbox as MuiCheckbox, CheckboxProps, FormControlLabel } from '@mui/material';

interface LabeledCheckboxProps extends CheckboxProps {
  label?: string;
  labelPosition?: 'start' | 'end';
}

export function Checkbox({ label, labelPosition = 'end', sx, ...props }: LabeledCheckboxProps) {
  const checkboxSx = {
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.15)',
        pointerEvents: 'auto',
        cursor: 'not-allowed',
      },
    },
    ...sx,
  };

  return label ? (
    <FormControlLabel
      control={<MuiCheckbox disableRipple focusVisibleClassName=".Mui-focusVisible" sx={checkboxSx} {...props} />}
      label={label}
      labelPlacement={labelPosition}
      data-iscapture="true"
      data-tip={props.id}
      data-for="global"
      onClick={(event) => event.stopPropagation()}
    />
  ) : (
    <MuiCheckbox
      data-iscapture="true"
      data-for="global"
      data-tip={props.id}
      disableRipple
      focusVisibleClassName=".Mui-focusVisible"
      sx={checkboxSx}
      {...props}
    />
  );
}

import { PropsWithChildren, useEffect } from 'react';
import i18n from './init';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useCustomAuth } from 'src/contexts/CustomAuthContext';

export const IptorTranslationProvider = ({ children }: PropsWithChildren) => {
  const { userLocale } = useCustomAuth(auth => ({ userLocale: auth.userLocale }));

  useEffect(() => {
    i18n.changeLanguage(userLocale);
  }, [userLocale]);

  return (
    <I18nextProvider i18n={i18n} defaultNS={'common'}>
      {children}
    </I18nextProvider>
  );
};

export const useIptorTranslation = useTranslation;

import { DecimalFormatter } from '../parsers/DecimalFormatter';
import { Localization } from '../localization/Localization';

export class PositiveDecimalFormatter {
  replace = true;

  in(value: string, decimalSeperator: string, groupSepLocale: string) {
    /*
    value = value.trim();
    if (value == '') return '';
    let temp = value;
    if (decimalSeperator == ',' || this.replace) {
      temp = temp.replace(groupSepLocale, '');
      temp = value = temp.replace(',', '.');
      temp =
        value.substring(0, value.indexOf('.')).replace(/(?=(?:\d{3})+\b)(?!\b)/g, ',') + temp.substring(value.indexOf('.'), value.length);
    }
    let noOFDec = temp.indexOf('.') === -1 ? -1 : temp.length - temp.indexOf('.') - 1;
    let d = parseFloat(temp);
    if (d == 0 && this.replace) return '';
    if (d < 0) d = d * -1;
    temp = d.toString();
    if (noOFDec !== -1) temp = d.toFixed(noOFDec);
    const gs = Localization.instance.groupSeparator || ',';
    const ds = Localization.instance.decimalSeparator || '.';
    temp = temp.replace(/([,.])/g, (string) => (string === ',' ? gs : ds));
    return temp;
    */
    /* 
      REMARK: 
        ° FAST FIX, NTH clean-up 
        ° original code returns 1.00 for input value 1000.12
    */
    let temp = new DecimalFormatter().in(value, decimalSeperator);
    if (temp.startsWith('-')) return temp.substring(1);
    return temp;
  }

  out(value: string, decimalSeperator: string, groupSepLocale: string, externalize: boolean) {
    if (externalize) {
      let temp = value;
      // temp = temp.replace(groupSepLocale, '');

      // if (decimalSeperator == ',') temp = temp.replace(',', '.');
      if (decimalSeperator == ',') temp = temp.replace('.', ',');
      // let d = parseFloat(temp);
      if (temp.length == 0) value = '0';
      else {
        value = temp.toString();
      }
    } else {
      this.replace = false;
      value = this.in(value, decimalSeperator, decimalSeperator);
      this.replace = false;
    }

    return value;
  }
}

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" color="inherit" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.875 3.25C14.875 4.83782 13.5878 6.125 12 6.125C10.4122 6.125 9.125 4.83782 9.125 3.25C9.125 1.66218 10.4122 0.375 12 0.375C13.5878 0.375 14.875 1.66218 14.875 3.25ZM14.875 12C14.875 13.5878 13.5878 14.875 12 14.875C10.4122 14.875 9.125 13.5878 9.125 12C9.125 10.4122 10.4122 9.125 12 9.125C13.5878 9.125 14.875 10.4122 14.875 12ZM12 23.625C13.5878 23.625 14.875 22.3378 14.875 20.75C14.875 19.1622 13.5878 17.875 12 17.875C10.4122 17.875 9.125 19.1622 9.125 20.75C9.125 22.3378 10.4122 23.625 12 23.625Z"
      fill="currentColor"
    />
  </svg>
);

import { useEffect, useRef, useState } from 'react';
import { Box, Grid2 as Grid, Grid2Props, Skeleton, styled } from '@mui/material';
import { DataCard, DataCardProps } from '../DataCard';
import { Button } from '../Button';
import { useIptorTranslation } from '../../framework/translations';

export type DataCardListProps = {
  options: DataCardProps[];
  extraAction?: React.ReactNode;
  loading?: boolean;
  disableDefaultShowAll?: boolean;
  slotProps?: {
    listContainer: Grid2Props;
  };
};
const StyledGridContainer = styled(Grid)(({ theme }) => ({
  boxShadow: '0px 3.5px 5.5px 0px #00000005',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
}));
const ActionButtonContainer = styled(Grid)(({ theme }) => ({
  alignSelf: 'center',
  textAlign: 'right',
  padding: theme.spacing(1),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.spacing(1.25),
  width: '138px',
  height: '135px',
}));

export const DataCardList = ({
  options,
  extraAction,
  loading,
  disableDefaultShowAll,
  slotProps,
}: DataCardListProps) => {
  const [maxVisibleCards, setMaxVisibleCards] = useState<number>(0);
  const gridRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const [showAll, setShowAll] = useState<boolean>(false);
  const { t } = useIptorTranslation();
  const calculateMaxAllowedCards = () => {
    const availableWidth = gridRef.current?.clientWidth - actionsRef.current?.clientWidth;
    const cardWidth = 140;
    const maxCardsAllowed = Math.floor(availableWidth / cardWidth - 1);
    setMaxVisibleCards(maxCardsAllowed);
  };
  useEffect(() => {
    calculateMaxAllowedCards();
    window.addEventListener('resize', calculateMaxAllowedCards);
    return () => window.removeEventListener('resize', calculateMaxAllowedCards);
  });

  return (
    <Box>
      <StyledGridContainer ref={gridRef} container spacing={0.75} {...slotProps?.listContainer}>
        {loading && Array.from({ length: 3 }, (_, index) => <StyledSkeleton key={index} variant="rectangular" />)}
        {!loading &&
          options.slice(0, showAll ? options.length : maxVisibleCards).map((card, index) => (
            <Grid key={index}>
              <DataCard
                title={card.title}
                subTitle={card.subTitle}
                firstVal={card.firstVal}
                secondVal={card.secondVal}
                thirdVal={card.thirdVal}
                onClose={card.onClose}
                error={card.error}
                onEdit={card.onEdit}
              />
            </Grid>
          ))}
        <Box sx={{ display: 'flex' }} ref={actionsRef}>
          {extraAction}
          {!disableDefaultShowAll && !showAll && options.length > maxVisibleCards && (
            <ActionButtonContainer>
              <Button iconRight="chevronDown" onClick={() => setShowAll(true)} size="small" variant="text">
                +{`${options.length - maxVisibleCards} ${t('common:TXT_More')}`}
              </Button>
            </ActionButtonContainer>
          )}
          {showAll && options.length > maxVisibleCards && (
            <ActionButtonContainer>
              <Button onClick={() => setShowAll(false)} size="small" variant="text">
                {t('common:Show_less')}
              </Button>
            </ActionButtonContainer>
          )}
        </Box>
      </StyledGridContainer>
    </Box>
  );
};

import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ContentEditor } from '../content-editor/Editor';
import { Toolbar } from '../views/partials/Toolbar';
import { ValueWithLegacy } from '@react-page/editor';
import axios from 'axios';
import { Company } from '../types/company';
import { connect, useDispatch } from 'react-redux';
import { User } from '../types/user';
import { WindowManger } from '../framework/base/windowManager';
import { RequestError } from '../types/RequestError';
import { WindowActions } from '../types/actionTypes';
import { useCustomAuth } from '@iptor/base';

export const DashboardEditorComponent: FunctionComponent<any> = ({
  startLoading,
  endLoading,
  quickLaunch,
  company,
  settings,
  user,
}: {
  startLoading: Function;
  endLoading: Function;
  quickLaunch: Function;
  company: Company;
  settings: Record<string, any>;
  user: User;
}) => {
  const { preferredUsername, isAuthenticated } = useCustomAuth((auth) => ({
    preferredUsername: auth.preferredUsername,
    isAuthenticated: auth.isAuthenticated,
  }));
  const dispatch = useDispatch();
  const defaultDashboard = {
    id: '0ol6nd',
    version: 1,
    rows: [
      {
        id: '5z0943',
        cells: [
          {
            id: 'nnf8vr',
            size: 4,
            plugin: {
              id: 'single-value',
              version: 1,
            },
            dataI18n: {
              default: {
                value: 'newSalesOrderMTD',
              },
            },
            rows: [],
            inline: null,
          },
          {
            id: '13dtb5',
            size: 4,
            plugin: {
              id: 'single-value',
              version: 1,
            },
            dataI18n: {
              default: {
                value: 'openOrdersValueTillDate',
              },
            },
            rows: [],
            inline: null,
          },
          {
            id: 'qz7309',
            size: 4,
            plugin: {
              id: 'single-value',
              version: 1,
            },
            dataI18n: {
              default: {
                value: 'totalRevenueMTD',
              },
            },
            rows: [],
            inline: null,
          },
        ],
      },
      {
        id: 'g89zfo',
        cells: [
          {
            id: 'y5ovcv',
            size: 6,
            plugin: {
              id: 'alerts-table',
              version: 1,
            },
            dataI18n: {
              default: {
                title: '',
                height: '300px',
              },
            },
            rows: [],
            inline: null,
          },
          {
            id: 'e0jk9q',
            size: 6,
            plugin: {
              id: 'rss-reader',
              version: 1,
            },
            dataI18n: {
              default: {
                title: 'Iptor RSS',
                url: 'https://iptor.com/feed/',
                height: '300px',
              },
            },
            rows: [],
            inline: null,
          },
        ],
      },
      {
        id: '9bgv1a',
        cells: [
          {
            id: 'jjngm8',
            size: 4,
            plugin: {
              id: 'quick-links',
              version: 1,
            },
            dataI18n: {
              default: {
                title: '',
                height: '300px',
              },
            },
            rows: [],
            inline: null,
          },
          {
            id: 'i87f9q',
            size: 4,
            plugin: {
              id: 'company-information',
              version: 1,
            },
            dataI18n: {
              default: {
                height: '300px',
              },
            },
            rows: [],
            inline: null,
          },
          {
            id: 'pa7jfh',
            size: 4,
            plugin: {
              id: 'pie-chart',
              version: 1,
            },
            dataI18n: {
              default: {
                title: '',
                height: '300px',
              },
            },
            rows: [],
            inline: null,
          },
        ],
      },
      {
        id: 'tl2gsr',
        cells: [
          {
            id: '0hrtuc',
            size: 12,
            plugin: {
              id: 'ory/editor/core/content/slate',
              version: 1,
            },
            dataI18n: {
              default: {
                slate: [
                  {
                    children: [
                      {
                        text: 'Powered by ',
                      },
                      {
                        type: 'LINK/LINK',
                        children: [
                          {
                            text: 'Iptor.com',
                          },
                        ],
                        data: {
                          href: 'https://iptor.com',
                          openInNewWindow: true,
                        },
                      },
                      {
                        text: '',
                      },
                    ],
                    data: {
                      align: 'right',
                    },
                  },
                ],
              },
            },
            rows: [],
            inline: null,
          },
        ],
      },
    ],
  };
  const [editing, setEditing] = useState<boolean>(false);
  const [content, setContent] = useState<ValueWithLegacy | null>(null);

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const saveDashboard = (targets: string[]) => {
    startLoading();
    axios
      .post('/client/publish', {
        targets: targets,
        layout: content,
      })
      .finally(() => {
        endLoading();
        setEditing(false);
      });
  };

  const resetDashboard = (targets: string[]) => {
    startLoading();
    setEditing(true);
    axios
      .post('/client/reset/', {
        targets: targets,
      })
      .finally(() => {
        endLoading();
        setEditing(false);
      });
  };

  const reloadDashboard = () => {
    startLoading();
    axios
      .get(`/client/get/${sessionStorage.getItem('env-config-key') || 'default'}/${company.id}/${preferredUsername}`)
      .then((res) => {
        setContent(res?.data?.doc?.layout || defaultDashboard);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          const requestError = RequestError.createFromAxiosRequest('NONE', [], () => {}, undefined, err);
          dispatch({
            type: WindowActions.NEW_REQUEST_ERROR,
            payload: { requestError: RequestError.toJSON(requestError) },
          });
        }
      })
      .finally(() => {
        endLoading();
      });
  };

  useEffect(() => {
    if (!editing && isAuthenticated) {
      reloadDashboard();
    }
  }, [editing, isAuthenticated]);

  const refresh = () => {
    setContent(null);
    reloadDashboard();
  };

  return (
    <Container className="dashboard" fluid>
      <div className="dashboard-container">
        <ContentEditor content={content} setContent={setContent} editing={editing} />
      </div>
      <Toolbar
        saveDashboard={saveDashboard}
        resetDashboard={resetDashboard}
        editing={editing}
        setEditing={toggleEditing}
        startLoading={startLoading}
        endLoading={endLoading}
        quickLaunch={quickLaunch}
        refresh={refresh}
      />
    </Container>
  );
};

const mapStateToProps = ({ desktop, settings }: any) => {
  let props: {
    company: Company;
    user: User;
    settings: Record<string, any>;
  } = {
    company: {
      id: desktop.settings?.regionals?.user[0].$.activeCompany,
      name:
        desktop.settings?.regionals?.company.find(
          (x: any) => x.$.code === desktop.settings.regionals.user[0].$.activeCompany,
        )?.$.name || '',
    },
    user: {
      id: desktop.settings?.regionals?.user[0].$.userId,
      username: desktop.settings?.regionals?.user[0].$.userId,
      name: desktop.settings?.fullName,
    },
    settings: desktop.settings,
  };
  return props;
};

export const DashboardEditor = connect(mapStateToProps, { quickLaunch: WindowManger.Launch })(DashboardEditorComponent);

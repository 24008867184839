/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid2 as Grid, Stack, Typography } from '@mui/material';
import { Box, Checkbox, Combo, NumericField, TextField, useIptorTranslation } from '@iptor/base';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

export type PrintParametersProps = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  control: Control<{ email: string; numberOfCopies: number; printerQueue: any; checked: boolean }, any>;
  watch: UseFormWatch<{ email: string; numberOfCopies: number; printerQueue: any; checked: boolean }>;
  printQueueOptions: Array<{ value: string; label: string; type?: string }>;
  comboLoading: boolean;
};
export const PrintParameters = ({ control, printQueueOptions, comboLoading, watch }: PrintParametersProps) => {
  const selectedPrinterQueue = watch('printerQueue');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { t } = useIptorTranslation();
  return (
    <>
      <Typography fontSize={18} fontWeight={600} sx={{ margin: [1, 2] }}>
        Printing Parameters
      </Typography>
      <Box boxShadow={'0px 3.5px 5.5px 0px #00000005'}>
        <Grid height={'100px'} size={{ xs: 12 }} display={'flex'} alignItems="center" justifyContent="space-evenly">
          <Grid height={'100%'} size={{ xs: 4 }}>
            <Controller
              name="printerQueue"
              control={control}
              rules={{ required: t('sales_orders:PRINT_printerQueueRequiredError') }}
              render={({ field, fieldState: { error } }) => (
                <Combo
                  {...field}
                  id={field.name}
                  color={error ? 'error' : 'primary'}
                  onChange={(event, value) => field.onChange(value)}
                  options={printQueueOptions}
                  label={t('sales_orders:PRINT_printerQueue')}
                  loading={comboLoading}
                  size={'small'}
                  required
                  disabled={!printQueueOptions}
                  textFieldProps={{
                    sx: {
                      width: '300px',
                    },
                    error: !!error,
                  }}
                />
              )}
            />
          </Grid>
          <Grid height={'100%'} size={{ xs: 4 }}>
            <Controller
              name="numberOfCopies"
              control={control}
              rules={{ required: t('sales_orders:PRINT_printerQueueRequiredField'), validate: (value) => value > 0 }}
              render={({ field, fieldState: { error } }) => (
                <NumericField
                  {...field}
                  color={error ? 'error' : 'primary'}
                  required={true}
                  id={field.name}
                  value={field.value ?? 1}
                  step={1}
                  error={!!error}
                  buttonLayout={'horizontal'}
                  label={t('sales_orders:PRINT_numberOfCopies')}
                  min={0}
                  max={100}
                  size={'small'}
                  onChangeNumeric={(value) => field.onChange(value)}
                />
              )}
            />
          </Grid>
          <Grid height={'100%'} size={{ xs: 4 }}>
            <Controller
              name={'checked'}
              control={control}
              render={({ field }) => (
                <Stack marginTop={3} direction={'row'} alignItems={'center'}>
                  <Checkbox
                    checked={field.value}
                    id={field.name}
                    size={'medium'}
                    onChange={(_, value) => field.onChange(value)}
                  />
                  <Typography variant={'body1'}>{t('sales_orders:PRINT_checkboxLabel')}</Typography>
                </Stack>
              )}
            />
          </Grid>
        </Grid>
        {selectedPrinterQueue?.type === '2' && (
          <Grid container height={'100px'} size={{ xs: 12 }} alignItems="center" justifyContent="flex-start">
            <Grid height={'100%'} size={{ xs: 5 }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t('sales_orders:PRINT_emailRequiredError'),
                  pattern: {
                    value: emailRegex,
                    message: t('sales_orders:PRINT_emailValidationError'),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={t('common:TXT_Email')}
                    autoComplete={'off'}
                    id={field.name}
                    color={error ? 'error' : 'primary'}
                    required={true}
                    sx={{
                      width: '300px',
                    }}
                    size="small"
                    error={!!error}
                    onChange={(value) => field.onChange(value)}
                    helperText={
                      error
                        ? error.message
                        : field.value && !emailRegex.test(field.value)
                          ? t('sales_orders:PRINT_emailValidationError')
                          : ''
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

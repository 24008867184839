import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { openAPIManager as businessOpenAPIManager } from '@iptor/business';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './framework/base/index';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LoggerProvider } from './logger/react/LoggerProvider';

import { Localization } from './framework/localization/Localization';
import { sleep } from './helpers/sleep';
import { AppActions } from './types/actionTypes';
import { ErrorType } from './framework/base/appReducer';
import { ClientLogger } from './logger/ClientLogger';
import { v4 as uuidv4 } from 'uuid';
import EventEmitter from 'events';
import { AuthProvider } from 'react-oidc-context';
import { User, UserManager } from 'oidc-client-ts';
import './types/oidc-client-ts.User';
import { StartOTEL, StartAutoInstrumentation, generateOTELAttrs } from './instrumentation';
import { trace, context, propagation, Span } from '@opentelemetry/api';
import { CustomAuthProvider } from '@iptor/base';

export type LoaderProps = {
  loading: boolean;
};
export type LoadingSetters = {
  startLoading: () => void;
  endLoading: () => void;
} & LoaderProps;

let auth: UserManager;
let msalInstance: any;
const { dispatch } = store;
let logger = new ClientLogger({ level: 'ERROR', appenders: [] }); // Remark; this is a dummy one, real one is instantiated when config from server is retrieved. Dummy first to make typescript clear there wlll always be an instance (to prevent usage of logger?. in our application)
export const appId = uuidv4(); // id to identify specific client app / browser tab

window.addEventListener('beforeunload', async (event) => {
  let windowCount = +(sessionStorage.getItem('windowOpened') || 1);
  let skip = false;
  if (document.body.hasAttribute('exporting')) {
    document.body.removeAttribute('exporting');
    skip = true;
  }
  const user = await auth.getUser();

  if (
    !skip &&
    windowCount > 1 &&
    user &&
    !user.expired &&
    window.location.pathname === '/app' &&
    window.location.search !== '?change'
  ) {
    event.preventDefault();
    event.returnValue = ``;
  }
});

window.addEventListener('load', (event) => {
  if (window.location.search === '?change') {
    window.history.replaceState(null, '', '/app');
  }
});

document.body.tabIndex = 0;

const getEnvConfigKey = () => {
  const envConfigKeyUrl = new URLSearchParams(window.location.search).get('env');
  const envConfigKeyLocal = sessionStorage.getItem('env-config-key');
  if (envConfigKeyUrl && (!envConfigKeyLocal || envConfigKeyUrl !== envConfigKeyLocal)) {
    sessionStorage.setItem('env-config-key', envConfigKeyUrl);
    return envConfigKeyUrl;
  } else {
    return envConfigKeyLocal;
  }
};
// const userAuthenticatedInApp = () => {
//   const resource = auth?.user?.resource_access[auth?.settings.client_id];
//   return resource && resource.roles.some((x: any) => x === 'product');
// };
const closeApp = () => {
  sessionStorage.removeItem('env-config-key');
  // close the current tab/window
  // TODO: currently doesn't work due to browser restrictions
  const a = window.open('', '_self');
  a?.close();
  // alternative: redirect to main Portal page or Iptor.com
  // window.location.replace('http://Iptor.com');
};
let requesting = false;

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  while (requesting) {
    await sleep(100);
  }
  // possibility that headers could be not set was added on last package update
  // we want to push our headers, so if for some reason header is not present - add minimal setup for it
  if (!config.headers) {
    config.headers = {};
  }

  if (config.url?.match(/^(\/aperio|\/client)/g)) requesting = true;
  const company = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';
  const locale = Localization.instance.locale;
  if (config.data) config.data.screenHeight = window.screen.height;

  let session = sessionStorage.getItem('eui_session_id');
  if (!session) {
    session = `${uuidv4()}`;
    sessionStorage.setItem('eui_session_id', session);
  }
  config.headers['sessions'] = session;
  config.headers['xt-cid'] = appId; // xt-cid header defined on the XT side, to identify requests from specific App instances /  browser tabs

  if (!process.env.REACT_APP_IS_DISPATCHER) {
    const user = await auth?.getUser();
    if (user?.expires_in && user.expires_in < 15) {
      await auth.signinSilent();
    }
    config.headers['Authorization'] = 'Bearer ' + user?.access_token;
    config.headers['x-env'] = sessionStorage.getItem('env') || user?.extendedUserProfile()?.environments?.[0];

    const envConfigKey = getEnvConfigKey();
    // parse only if auth service initialized and user logged in
    if (user && !user.expired) {
      if (envConfigKey) {
        if (user?.accessTokenParsed()[envConfigKey]) {
          config.headers['env-config-key'] = envConfigKey;
        } else {
          dispatch({
            type: AppActions.SET_ERROR,
            payload: {
              type: ErrorType.envNotPermitted,
              message: `You have no access to this app! (environment = ${envConfigKey})! Please contact your administrator.`,
              title: 'No Access',
              action: closeApp,
            },
          });
          throw new Error('User has no access to App');
        }
      } else {
        // when/if turning on - change keycloak to auth!!
        // temporary disabled - to review
        // const userAuthenticated = userAuthenticatedInApp();
        // // don't dispatch if keycloak not initialized yet
        // if (keycloak && !userAuthenticated) {
        //   dispatch({
        //     type: AppActions.SET_ERROR,
        //     payload: {
        //       type: ErrorType.appNotAuthenticated,
        //       message: 'You have no access to this app! Please contact your administrator.',
        //       title: 'No Access',
        //       action: closeApp
        //     }
        //   });
        //   throw new Error('User has no access to App');
        // }
      }
    }
  }
  config.headers['company'] = company ? company.id : '';
  config.headers['locale'] = locale;

  const tracer = trace.getTracer('eui-web-client-manual');
  let { title, windowID, rssURL } = generateOTELAttrs(config.url);
  return tracer.startActiveSpan(
    `${title}`,
    {
      attributes: {
        endpoint: config.url,
        company: config.headers?.company as string,
        locale: config.headers?.locale as string,
        windowID,
        session,
        rssURL,
        program: config.data?.program ?? '',
      },
    },
    (span: Span) => {
      config.span = span;
      let output: { traceparent?: string; tracestate?: string } = {};
      propagation.inject(context.active(), output);
      const { traceparent, tracestate } = output;
      config.headers = { ...config.headers, traceparent: traceparent ?? '' };
      return config;
    },
  );

  // logger.trace(config);
  /* tracer.startActiveSpan('start-request', (clientSpan: Span) => {
    //Rest of the API call
    config.span = clientSpan;
  }) */
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    /* response.config.span.end() */
    requesting = false;
    response.config.span?.end();
    if (response.data.apiSession) sessionStorage.setItem('api_session_id', response.data.apiSession.split(';')[0]);
    return response;
  },
  (error: AxiosError) => {
    error.config?.span?.end();
    requesting = false;
    return Promise.reject(error);
  },
);

// Try to recover session if it exists
axios.get('/auth/session/recover').catch((e) => console.error(`Recover session if it exists,${e}`));

// Call force logout to mark session for deletion
window.addEventListener('pagehide', async (event) => {
  const user = await auth?.getUser();
  if (!user?.expired && window.location.pathname === '/app' && window.location.search !== '?change') {
    const token = user?.access_token || '';
    const aperioToken = sessionStorage.getItem('aperio-authorization') || '';
    let fd = new FormData();
    fd.append('token', token);
    fd.append('aperio-token', aperioToken);
    const session = sessionStorage.getItem('eui_session_id');
    if (session) {
      fd.append('sessions', session);
    }
    if (!event.persisted) navigator.sendBeacon('/auth/session/close', fd);
  }
});

const getREACTLogger = (componentName: string): ClientLogger => {
  return logger.childWithTags('react', componentName);
};

export const AuthTokenEmitter = new EventEmitter();

//Emit token updated event when auth token is updated
const emitUpdatedTokens = async (authTokenEmitter: EventEmitter, auth: UserManager) => {
  //Use AuthTokenEmitter.on('token|refreshToken|idToken', (string) => void) for receiving tokens in components
  const user = await auth.getUser();
  authTokenEmitter.emit('token', user?.access_token);
  authTokenEmitter.emit('idToken', user?.id_token);
  authTokenEmitter.emit('refreshToken', user?.refresh_token);
};
const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const openAPIManager = businessOpenAPIManager;
openAPIManager.changeAllOpenAPIConfigs({
  getToken: async () => {
    const user = await auth?.getUser();
    if (user?.expires_in && user.expires_in < 15) {
      await auth.signinSilent();
    }
    return user?.access_token;
  },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
if (!process.env.REACT_APP_IS_DISPATCHER)
  Promise.all([fetch('/authconfig'), fetch('/msgraphconfig'), fetch('/logconfig'), fetch('/otelconfig')])
    .then(async ([authResp, msResp, logResp, otelResp]) => {
      const msalConfig = await msResp.json();
      const logConfig = await logResp.json();
      const authConfig = await authResp.json();
      const otelConfig = await otelResp.json();

      if (otelConfig.enabled) {
        //Start OTEL only if otel is enabled on the server
        StartOTEL();
        const params = new URL(window.location.href).searchParams;
        if (params.get('instrumentation') === 'auto') {
          StartAutoInstrumentation();
        }
      }

      auth = new UserManager({
        automaticSilentRenew: false,
        redirect_uri: window.location.href,
        ...authConfig,
      });
      auth.events.addAccessTokenExpiring(async () => {
        await auth.signinSilent();
        await emitUpdatedTokens(AuthTokenEmitter, auth);
      });

      logger = new ClientLogger(logConfig);

      if (msalConfig?.auth?.clientId) {
        msalInstance = new PublicClientApplication(msalConfig);

        root.render(
          <LoggerProvider getLogger={getREACTLogger}>
            <React.StrictMode>
              <AuthProvider userManager={auth} onSigninCallback={onSigninCallback}>
                <CustomAuthProvider>
                  <MsalProvider instance={msalInstance}>
                    <Provider store={store}>
                      <App appId={appId} />
                    </Provider>
                  </MsalProvider>
                </CustomAuthProvider>
              </AuthProvider>
            </React.StrictMode>
          </LoggerProvider>,
        );
      } else {
        root.render(
          <LoggerProvider getLogger={getREACTLogger}>
            <React.StrictMode>
              <AuthProvider userManager={auth} onSigninCallback={onSigninCallback}>
                <CustomAuthProvider>
                  <Provider store={store}>
                    <App appId={appId} />
                  </Provider>
                </CustomAuthProvider>
              </AuthProvider>
            </React.StrictMode>
          </LoggerProvider>,
        );
      }
    })
    .catch((error) => {
      console.error(`TokensUpdated ${error}`);
    });
else
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App appId={appId} />
      </Provider>
    </React.StrictMode>,
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { logger, auth }; // Remark; logger exposed to make it possible to be also used outside components

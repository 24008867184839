import { ValueFormatterExtension } from './ValueFormatterExtension';

export class RemoveTrailingBlanksFormatter implements ValueFormatterExtension {
  in(value: string) {
    if (value[value.length - 1] === ' ') {
      value = value.trimRight();
    }
    return value;
  }

  out(value: string) {
    return value;
  }
}

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke-width="1.5" />
    <path
      d="M8 13.5L11.7 10.725C11.8778 10.5917 12.1222 10.5917 12.3 10.725L16 13.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

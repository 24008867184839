import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios, { AxiosResponse } from 'axios';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Doughnut } from 'react-chartjs-2';
import { string } from 'prop-types';
import { format } from 'date-fns';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import { Localization } from '../../framework/localization/Localization';
const companyInformation: CellPlugin = {
  Renderer: ({ data }: any) => {
    const [response, setResponse] = useState<any>({});
    const getData = async () => {
      const date = new Date();
      const millisec = date.getTime();
      const id = millisec.toString(36);
      let payload = {
        IptorAPI: '1.0',
        method: 'companyInfo.get',
        id: id,
      };
      let res = await axios
        .request({
          url: '/aperio/api/service',
          data: payload,
          method: 'POST',
        })
        .catch((err) => {
          console.error(`Error from companyInformation ${err}`);
        });
      setResponse((res as AxiosResponse)?.data || {});
    };
    useEffect(() => {
      getData();
    }, [data]);

    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <span style={{ overflow: 'auto' }}>
            <span style={{ paddingRight: '15px', overflow: 'auto' }}>
              <div className="row">
                <div className="col-sm-12">
                  <p>
                    <strong>{Localization.instance.getString('Company_Information_Widget')}</strong>
                  </p>
                </div>
                <div className="col-sm-4">{Localization.instance.getString('CON_name')}</div>
                <div className="col-sm-8">{response?.data?.name || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('TXT_code')}</div>
                <div className="col-sm-8">{response?.data?.company || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('Attachment_link_address')} 1</div>
                <div className="col-sm-8">{response?.data?.addressLine1 || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('Attachment_link_address')} 2</div>
                <div className="col-sm-8">{response?.data?.addressLine2 || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('Attachment_link_address')} 3</div>
                <div className="col-sm-8">{response?.data?.addressLine3 || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('TXT_Postal_Code')}</div>
                <div className="col-sm-8">{response?.data?.postalCode || ''}</div>
                <hr />
                <div className="col-sm-12">
                  <p>
                    <strong>{Localization.instance.getString('TXT_System_Information')}</strong>
                  </p>
                </div>
                <div className="col-sm-4">{Localization.instance.getString('TXT_Country')}</div>
                <div className="col-sm-8">{response?.data?.systemCountry || ''}</div>

                <div className="col-sm-4">{Localization.instance.getString('TXT_Environment')}</div>
                <div className="col-sm-8">
                  {response?.data?.environmentId || ''} <br /> {response?.data?.environmentDescription || ''}
                </div>
              </div>
            </span>
          </span>
        </Widget>
      </div>
    );
  },
  id: 'company-information',
  title: 'Company information',
  description: 'Display company information from DC1',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        height: {
          type: 'string',
        },
      },
      required: [],
    },
  },
};
export default companyInformation;

import { CheckCircle as CheckCircleIcon, Close as CloseIcon, Warning as ErrorIcon } from '@mui/icons-material';
import { Box, Link, Stack, styled, SvgIcon, Theme, Typography, useTheme } from '@mui/material';
import { SnackbarOrigin, SnackbarProvider, useSnackbar, VariantType } from 'notistack';
import React, { createContext, useContext, useState } from 'react';
import { Radius } from '../../constants/Radius';
import { TextSize, TextWeight } from '../../constants/Typography';
import { Button } from '../Button';
import { Icon } from '../Icon';

// Define the props for the notification
export type NotificationProps = {
  message: string;
  variant: VariantType; // success, error, warning, info
  anchorOrigin?: SnackbarOrigin;
  duration?: number | null; // Duration in milliseconds
  autoHide?: boolean; // Whether the Snackbar should auto-hide
  messageTitle?: string;
  mode?: 'default' | 'cta' | 'cta-description';
  ctaLabel?: string;
  ctaDescription?: string;
  additionalDetails?: string;
};

// Define the Snackbar context type
type SnackbarContextType = {
  showSnackbar: (props: NotificationProps) => void;
  closeAllSnackbars?: () => void; // New property to close all snackbars
  closeVariantSnackbar?: (variant: VariantType) => void; // New property to close a specific variant snackbars
};

interface SnackbarStyle {
  backgroundColor: string;
  borderColor: string;
  svg: {
    fill: string;
  };
}

// Create context to manage Snackbar state and methods
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Hook to use the Snackbar context
export const useCustomSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useCustomSnackbar must be used within a EnqueueProvider');
  }
  return context;
};

const MessageText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: 16,
  fontWeight: TextWeight.REGULAR,
  lineHeight: theme.spacing(2.68),
  color: theme?.palette?.foreground[600],
  maxWidth: '340px',
  height: 'auto',
}));

const CustomLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: TextSize.XXS,
  fontWeight: TextWeight.SEMIBOLD,
  lineHeight: theme.spacing(2.68),
  color: theme?.palette.info.main,
}));

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ClearAllButton = ({ show, closeAllSnackbars }: { show: boolean; closeAllSnackbars: () => void }) => {
  if (!show) return null; // Hide the button if no snackbars are active

  return (
    <Box justifyContent={'flex-end'} mb={1}>
      <Button
        size="small"
        variant="outlined"
        sx={{ marginBottom: '10px' }}
        onClick={() => {
          closeAllSnackbars && closeAllSnackbars();
        }}
      >
        {'Clear All'}
      </Button>
    </Box>
  );
};

// EnqueueProvider component
export const EnqueueProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarCount, setSnackbarCount] = useState(0);
  const [snackbarKeys, setSnackbarKeys] = useState<Map<string | number, VariantType>>(new Map());
  const theme: Theme = useTheme();

  const rootStyles = {
    width: '435px',
    minHeight: '58px',
    height: 'auto',
    borderRadius: Radius.M,
    border: 'solid 1px',
  };
  const snackbarStyles: Record<VariantType, SnackbarStyle> = {
    default: {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
      svg: {
        fill: theme.palette.success.main,
      },
    },
    success: {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
      svg: {
        fill: theme.palette.success.main,
      },
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.main,
      svg: {
        fill: theme.palette.warning.main,
      },
    },
    error: {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
      svg: {
        fill: theme.palette.error.main,
      },
    },
    info: {
      backgroundColor: theme.palette.info.light,
      borderColor: theme.palette.info.main,
      svg: {
        fill: theme.palette.info.main,
      },
    },
  };
  const getVariantIcon = (variant: string) => {
    switch (variant) {
      case 'success':
        return <CheckCircleIcon sx={{ fill: snackbarStyles.success.borderColor }} />;
      case 'error':
        return <ErrorIcon sx={{ fill: snackbarStyles.error.borderColor }} />;
      case 'warning':
        return <Icon icon="warning" size="M" />;
      case 'info':
        return <Icon icon="information" size="M" />;
      default:
        return null; // Fallback case
    }
  };

  const SnackbarWithDetails = ({
    message,
    messageTitle,
    variant,
    mode,
    additionalDetails,
    ctaLabel,
    ctaDescription,
  }: {
    message: string;
    messageTitle?: string;
    additionalDetails?: string;
    variant: string;
    mode: string;
    ctaLabel?: string;
    ctaDescription?: string;
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const transitionStyles = {
      // overflow: 'hidden',
      transition: 'max-height 0.35s ease-out',
      maxHeight: isExpanded ? '200px' : '0px', // Adjust maxHeight based on your content
      overflow: 'auto',
    };

    return (
      <StyledDiv>
        {getVariantIcon(variant)}
        {mode === 'cta-description' ? (
          <Box sx={{ ml: theme.spacing(1) }} display="flex" flexDirection={'column'}>
            <Typography
              sx={{
                color: theme.palette.foreground[300],
                fontWeight: TextWeight.SEMIBOLD,
                fontSize: 16,
                lineHeight: theme.spacing(3),
              }}
            >
              {message}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{
                color: theme.palette.foreground[300],
                fontWeight: TextWeight.REGULAR,
                fontSize: TextSize.XS,
                lineHeight: theme.spacing(2.6),
              }}
            >
              {ctaDescription || ''}
            </Typography>
          </Box>
        ) : (
          <Stack display={'flex'} direction="column" alignItems="left" marginLeft={1} spacing={1}>
            <MessageText>{(messageTitle ?? '') + message}</MessageText>
            {additionalDetails && (
              <>
                <Link
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                  component={'button'}
                  sx={(theme) => ({
                    color: theme.palette.info.main,
                    fontWeight: theme.typography.fontWeightBold,
                    '&:hover': {
                      textDecoration: 'underline', // Adds underline on hover
                      backgroundColor: 'transparent', // Removes background color on hover
                    },
                  })}
                >
                  <Stack display={'flex'} direction="row" gap={0.2}>
                    <Typography
                      sx={{
                        color: theme.palette.info.main,
                        fontWeight: TextWeight.MEDIUM,
                        fontSize: TextSize.XS,
                        lineHeight: theme.spacing(2.6),
                      }}
                    >
                      {isExpanded ? 'Read Less' : 'Read More'}
                    </Typography>
                    <Box mt={'2px'}>
                      {' '}
                      <Icon
                        sx={{ cursor: 'pointer', color: theme.palette.info.main }}
                        icon={isExpanded ? 'arrowUp01Round' : 'arrowDown01Round'}
                        size="M"
                        onClick={() => {}}
                      />{' '}
                    </Box>
                  </Stack>
                </Link>
                <Box sx={{ ...transitionStyles }}>
                  {<MessageText sx={{ fontSize: TextSize.XS }}>{additionalDetails || ''}</MessageText>}
                </Box>
              </>
            )}
          </Stack>
        )}
      </StyledDiv>
    );
  };
  // Function to show the snackbar
  const showSnackbar = ({
    message = '',
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
    variant = 'info',
    mode = 'default',
    ctaDescription = '',
    ctaLabel = '',
    autoHide = true,
    duration = 5000,
    additionalDetails = '',
    messageTitle = '',
  }: NotificationProps) => {
    setSnackbarCount((prev) => prev + 1);
    // Store the key with its variant

    const key = enqueueSnackbar(
      <SnackbarWithDetails
        message={message}
        additionalDetails={additionalDetails}
        ctaLabel={ctaLabel}
        variant={variant}
        messageTitle={messageTitle}
        mode={mode}
      />,
      {
        variant,
        autoHideDuration: autoHide ? duration || null : null, // Control auto-hide behavior
        anchorOrigin, // Set the position
        hideIconVariant: true,
        style: {
          ...rootStyles,
          ...snackbarStyles[variant],
        },
        onClose: (_, reason) => {
          if (reason !== 'maxsnack') {
            setSnackbarCount((prev) => Math.max(0, prev - 1)); // Decrease count when a snackbar is closed
          }
        },
        /** Required for future use */
        // onExited: () => {
        //   setSnackbarCount((prev) => Math.max(0, prev - 1)); // Ensure count is decremented
        // },
        action: (key) => (
          <>
            {mode !== 'default' && <CustomLabel>{ctaLabel}</CustomLabel>}
            <Box
              sx={{
                position: 'absolute',
                top: '15px', // Adjust this for fine-tuning vertical alignment
                right: '8px', // Adjust this for fine-tuning horizontal alignment
              }}
            >
              <SvgIcon
                component={CloseIcon}
                aria-label="close"
                fontSize="small"
                sx={{ cursor: 'pointer', color: theme.palette.foreground[600] }}
                onClick={() => closeSnackbar(key)}
              />
            </Box>
          </>
        ),
      },
    );
    setSnackbarKeys((prevKeys) => new Map(prevKeys).set(key, variant));
  };
  const closeAllSnackbars = () => {
    setSnackbarCount(0);
    closeSnackbar(); // Notistack clears all snackbars when no argument is provided
  };

  const closeVariantSnackbar = (variant: VariantType) => {
    snackbarKeys?.forEach((value, key) => {
      if (value === variant) {
        closeSnackbar(key);
      }
    });

    // Remove specific variant snackbars from the map
    setSnackbarKeys((prevKeys) => {
      const updatedKeys = new Map(prevKeys);
      updatedKeys?.forEach((value, key) => {
        if (value === variant) {
          updatedKeys.delete(key);
        }
      });
      return updatedKeys;
    });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeAllSnackbars, closeVariantSnackbar }}>
      <Box
        sx={{
          position: 'fixed',
          top: 8,
          right: 352,
          zIndex: 9999,
          display: 'flex',
        }}
      >
        <ClearAllButton show={snackbarCount > 1} closeAllSnackbars={closeAllSnackbars} />
      </Box>
      {children}
    </SnackbarContext.Provider>
  );
};

// Wrap EnqueueProvider with SnackbarProvider from notistack
export const SnackbarWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <SnackbarProvider
      classes={{
        containerRoot: 'snackbar-container',
      }}
      style={{
        position: 'relative',
        top: '30px',
      }}
      maxSnack={5}
    >
      <EnqueueProvider>{children}</EnqueueProvider>
    </SnackbarProvider>
  );
};

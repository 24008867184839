import { Box, Button } from '@iptor/base';
import { Grid2 as Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { Order } from '../../../constants';
import Assortments from '../../../dialogs/Assortments';
import BulkUpload from '../../../dialogs/BulkUpload';
import PastOrders from '../../../dialogs/PastOrders';

const TableProcedures = ({ order, refreshTable }: { order?: Order; refreshTable: () => void }) => {
  const [open, setOpen] = useState({ bulkUpload: false, assortments: false, pastOrders: false });
  const handleCloseBulkUpload = (refresh = true) => {
    setOpen((prev) => ({ ...prev, bulkUpload: false }));
    refresh && refreshTable();
  };
  const handleCloseAssortments = (refresh = true) => {
    setOpen((prev) => ({ ...prev, assortments: false }));
    refresh && refreshTable();
  };
  const handleClosePastOrders = (refresh = true) => {
    setOpen((prev) => ({ ...prev, pastOrders: false }));
    refresh && refreshTable();
  };

  return (
    <Box sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      {open.bulkUpload && (
        <BulkUpload open={open.bulkUpload} handleClose={handleCloseBulkUpload} currentOrder={order} />
      )}
      {open.assortments && (
        <Assortments open={open.assortments} handleClose={handleCloseAssortments} currentOrder={order} />
      )}
      {open.pastOrders && (
        <PastOrders open={open.pastOrders} handleClose={handleClosePastOrders} currentOrder={order} />
      )}
      <Grid container alignItems="center" spacing={2}>
        <Grid size={4}></Grid>
        <Grid container alignItems="center" justifyContent="flex-end" size={8}>
          <Grid>
            <Stack direction="row" spacing={2}>
              <Button
                iconLeft="clockBackward"
                onClick={() => {
                  setOpen((prev) => ({ ...prev, pastOrders: true }));
                }}
                id="pastOrders"
                loading={!order}
              >
                Past orders
              </Button>
              <Button
                iconLeft="assortments"
                id="assortments"
                onClick={() => {
                  setOpen((prev) => ({ ...prev, assortments: true }));
                }}
                loading={!order}
              >
                Assortments
              </Button>
              <Button
                iconLeft="bulkUpload"
                onClick={() => {
                  setOpen((prev) => ({ ...prev, bulkUpload: true }));
                }}
                id="bulkUpload"
                loading={!order}
              >
                Bulk upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableProcedures;

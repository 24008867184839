import { DatePicker } from 'src/components/DatePicker';
import { TextField } from '../../TextField';
import { FilterValueType, TableColumn, Filters, TableContext } from '../types';
import { Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

const InputStyle = {
  '.MuiInputBase-root': { padding: '8px 4px' },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': { '-webkit-appearance': 'none' },
  'input[type="number"]': {
    '-moz-appearance': 'textfield',
  },
};

const parseDate = (date: Date | string, dateFormat = 'YYYY-MM-DD') => {
  if (!date) return null;

  if (date instanceof Date) {
    return dayjs(date);
  }
  return dayjs(date, dateFormat);
};

export const FilterInput = ({
  col,
  value,
  filters,
  setFilters,
  isFirstFilter = false,
}: {
  col: TableColumn;
  value: FilterValueType;
  filters: Filters;
  setFilters?: (filters: Filters) => void;
  isFirstFilter: boolean;
}) => {
  const filter = useCallback(
    (v: FilterValueType) => {
      if (Object.keys(v)?.length === 0) return; //Ignore if no value is set (i.e. Ignore initial trigger)
      if (Object.keys(v).every((k) => value[k as keyof FilterValueType] === v[k as keyof FilterValueType])) return; //Ignore if no value has changed
      //noValuesPresent needs to be explicit about null undefined and empty string as 0 is a valid value
      const noValuePresent = Object.values(v).every((s) => s === undefined || s === null || s === '');
      if (noValuePresent) {
        setFilters([...(filters ?? []).filter(({ column }) => column !== col.key)]);
      } else {
        setFilters([...(filters ?? []).filter(({ column }) => column !== col.key), { column: col.key, value: v }]);
      }
    },
    [filters, value],
  );

  const { showFilter } = useContext(TableContext);

  const inputRef = useRef<HTMLInputElement>();

  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal((val) => (Object.keys(val).length === 0 || Object.keys(value).length === 0 ? value : val));
  }, [value]);

  useEffect(() => {
    //Debounce inside useEffect not working
    const id = setTimeout(() => {
      filter(val);
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [val]);

  useEffect(() => {
    if (isFirstFilter && showFilter && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFirstFilter, showFilter]);

  switch (col.dataType) {
    case 'number':
      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <TextField
            sx={InputStyle}
            inputRef={inputRef}
            value={val.min ?? ''}
            size="small"
            placeholder="From"
            type="number"
            onChange={(e) => setVal({ ...value, min: e.target.value === '' ? undefined : +e.target.value })}
          />
          <Typography variant="body1"> - </Typography>
          <TextField
            sx={InputStyle}
            value={val.max ?? ''}
            size="small"
            placeholder="To"
            type="number"
            onChange={(e) => setVal({ ...value, max: e.target.value === '' ? undefined : +e.target.value })}
          />
        </Stack>
      );
    case 'date':
      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <DatePicker
            inputRef={inputRef}
            size="small"
            slotProps={{ textField: { placeholder: 'From' } }}
            sx={InputStyle}
            value={val.min ? parseDate(val.min, 'YYYY-MM-DD') : null}
            onChange={(e) => {
              const dt = e?.format('YYYY-MM-DD');
              if (dt?.toLowerCase() === 'invalid date') return;
              setVal({ ...value, min: dt });
            }}
          />
          <Typography variant="body1"> - </Typography>
          <DatePicker
            size="small"
            slotProps={{ textField: { placeholder: 'To' } }}
            sx={InputStyle}
            value={val.max ? parseDate(val.max, 'YYYY-MM-DD') : null}
            onChange={(e) => {
              const dt = e?.format('YYYY-MM-DD');
              if (dt?.toLowerCase() === 'invalid date') return;
              setVal({ ...value, max: dt });
            }}
          />
        </Stack>
      );
    default:
      return (
        <TextField
          sx={InputStyle}
          inputRef={inputRef}
          value={val.equals ?? ''}
          size="small"
          onChange={(e) => setVal({ ...value, equals: e.target.value })}
        />
      );
  }
};

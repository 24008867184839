import { Action } from '../../components/Button';
import { Knot, KnotElement } from './models/knot';

export class Command extends Knot {
  protected infds: Knot;
  protected _id: string;
  protected _actionSpecificEndpointProgram: string | null = null;

  // public get flags() {
  //   return this._children[flags]
  //   for (let panelIndex in this._children) {
  //     let panel = this._children[panelIndex];
  //     let flags = panel.attributeGet('flags');
  //     if (!flags || index === -1) return;
  //     let bools = flags.split('').map((x) => x === '1');
  //     bools[index - 1] = setTo;
  //     panel.attributeSet('flags', bools.map((x) => ~~x).join(''));
  //   }
  // }

  public get id() {
    return this._id;
  }
  constructor(id: string, infds: Knot, attributes?: Record<string, string>) {
    super(KnotElement.FORM, attributes);
    this._id = id;
    this.infds = infds;
    this.infds.attributeSet('data_length', '0');
    this.infds.attributeSet('flag_bits', '0');
    this.infds.attributeSet('function_key', '0');
    this.infds.attributeSet('major_return_code', '0');
    this.infds.attributeSet('minor_return_code', '0');
    this.setCursor('1,1');
  }

  setFirstVisibleRow = (id?: string) => {
    this.infds.attributeSet('first_visible_line_id', id || '1'); //Confirm setting
  };

  getPanel = (): string | null | undefined => {
    return this.infds.attributeGet('record_format_name');
  };

  setPanel = (panel: string) => {
    this.infds.attributeSet('record_format_name', panel);
  };

  updateFlag = (index: number, setTo: boolean) => {
    for (let panelIndex in this._children) {
      let panel = this._children[panelIndex];
      let flags = panel.attributeGet('flags');
      if (!flags || index === -1) return;
      let bools = flags.split('').map((x) => x === '1');
      bools[index - 1] = setTo;
      panel.attributeSet('flags', bools.map((x) => ~~x).join(''));
    }
  };

  // toggleFlag(index: number) {
  //   for (let panelIndex in this._children) {
  //     let panel = this._children[panelIndex];
  //     let flags = panel.attributeGet('flags');
  //     if (!flags || index === -1) return;
  //     let bools = flags.split('').map((x) => x === '1');
  //     bools[index - 1] = !bools[index - 1];
  //     panel.attributeSet('flags', bools.map((x) => ~~x).join(''));
  //   }
  // }

  setCursor = (ddspos: string | undefined | null, rowOffset?: number) => {
    let rowOfset = rowOffset || 0;
    if (this.infds.attributeGet('cursor_position') !== null && !ddspos) return;
    let [x, y]: number[] = ddspos?.split(',').map((n) => +n) || [0, 0];
    let curPos: number = (y - rowOfset) * 256 + x;
    this.infds.attributeSet('cursor_position', `${curPos}`);
    if (rowOfset !== 0) {
      this.setFirstVisibleRow((rowOfset + 1).toString());
    } else {
      this.setFirstVisibleRow();
    }
  };

  setAction = (action: string) => {
    if (/^F\d$/i.test(action)) action = `${action[0]}0${action[1]}`;
    if ((action || 'DEFAULT').toUpperCase() === 'DEFAULT') action = 'ENTER';
    action = action.toUpperCase();
    this.attributeSet('action', action);
  };

  toString = () => {
    let xmlDefinition = `<?xml version="1.0" encoding="UTF-8"?>`;
    let attrStr: string = '';
    let keys = Object.keys(this.attributes).sort((a: string, b: string) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    for (const i in keys) {
      let key = keys[i];
      attrStr += ` ${key}="${this.attributes[key]}"`;
    }

    if (this._children) {
      let childStr: string = '';
      for (let index in this._children) {
        let child = this._children[index];
        childStr += child.toString();
      }
      //this._children.forEach((child: Knot) => {
      //childStr += child.toString();
      //});
      return `${xmlDefinition}<${this.elementName} id="${this._id}"${attrStr}>${childStr}${this.infds.toString()}</${
        this.elementName
      }>`;
    } else {
      return `${xmlDefinition}<${this.elementName} id="${this._id}"${attrStr}>${this.infds.toString()}</${
        this.elementName
      }>`;
    }
  };
  setActionSpecificEndpointProgram = (action: Action) => {
    if (action?.name === 'RemotePDF') {
      this._actionSpecificEndpointProgram = 'Spooledfiles';
    } else {
      this._actionSpecificEndpointProgram = null;
    }
  };
  getEndpointProgram = (launchingProgram: string): string => {
    return this._actionSpecificEndpointProgram || launchingProgram;
  };
  getFieldInfo = (
    fieldId: string,
    panelId?: string
  ): { fieldId: string; panelId: string; value: string } | undefined => {
    if (panelId) {
      return this._getFieldInfo(fieldId, panelId);
    } else if (!this.children) {
      return undefined;
    } else {
      const panelIds: string[] = Object.keys(this.children);
      for (const panelId of panelIds) {
        const info = this._getFieldInfo(fieldId, panelId);
        if (info) return info;
      }
      return undefined;
    }
  };
  private _getFieldInfo = (
    fieldId: string,
    panelId: string
  ): { fieldId: string; panelId: string; value: string } | undefined => {
    const panelKnot = this.children?.[panelId];
    if (!panelKnot) return undefined;
    const fieldKnot = panelKnot.children?.[fieldId];
    if (!fieldKnot) return undefined;
    const value = (fieldKnot['attributes']?.['value'] || '')
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&amp;/gi, '&')
      .replace(/&apos;/gi, "'")
      .replace(/&quot;/gi, '"');
    return { fieldId, panelId, value };
  };
}

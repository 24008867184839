import { Button, Icon, Text, useIptorTranslation } from '@iptor/base';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  open: boolean;
  type: 'confirm' | 'delete';
  title?: ReactNode;
  text?: ReactNode;
  buttonText?: ReactNode;
}

function GenericConfirmationDialog({ onCancel, onConfirm, loading, open, type, title, text, buttonText }: Props) {
  const { t } = useIptorTranslation();
  const defaultTitle = type === 'delete' ? t('common:TXT_Delete') : t('common:TXT_Confirm');
  const defaultButtonText = type === 'delete' ? t('common:TXT_Delete') : t('common:TXT_Confirm');
  const defaultText = type === 'delete' ? t('common:TXT_DeleteConfirmation') : t('common:TXT_Confirmation');

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
        <Icon icon="delete3" color="error.main" size="L" />
        <Text variant="h6" fontWeight={600}>
          {title || defaultTitle}
        </Text>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Icon icon="cancel01" onClick={onCancel} />
        </Box>
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text variant="subtitle1" paddingBottom={1}>
          {text || defaultText}
        </Text>
      </DialogContent>
      <DialogActions>
        <Button id="cancel" variant="outlined" onClick={onCancel}>
          {t('common:TXT_Cancel')}
        </Button>
        <Button id={defaultButtonText} variant="contained" onClick={onConfirm} loading={loading}>
          {buttonText || defaultButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GenericConfirmationDialog;

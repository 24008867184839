import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type ProjectPhaseDropdownProps = BusinessDropdownProps & {
  projectQuotationNumber?: number;
};

const COLUMN_NAMES: string[] = ['Phase Number', 'Description'];

export const ProjectPhaseDropdown = ({ projectQuotationNumber, ...props }: ProjectPhaseDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: projectPhaseOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'phaseDescription',
    valueAttribute: 'phaseNumber',
    params: projectQuotationNumber
      ? { windowId: 'projectPhase', where: `projectQuotationNumber=${projectQuotationNumber}` }
      : { windowId: 'projectPhase' },
    shouldPreventApiCall,
  });

  useEffect(() => {
    refetch();
  }, [projectQuotationNumber]);

  return <BaseDropdown {...props} loading={loading} options={projectPhaseOptions} columnNames={COLUMN_NAMES} />;
};

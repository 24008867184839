import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { createColorPalette } from './constants/Color';
import typography from './constants/Typography';
import { Button } from './constants/Button';
import { TextField } from './constants/TextField';
import { Stepper } from './constants/Stepper';
import { Drawer } from './constants/Drawer';
import { Table } from './constants/Table';
import { Link } from './constants/Link';
import { IconButton } from './constants/IconButton';
import { Switch } from './constants/Switch';
import { Select } from './constants/Select';
import { MenuItem } from './constants/MenuItem';
import { Menu } from './constants/Menu';
import { MenuListIcon } from './constants/MenuListIcon';
import { Box } from './constants/Box';
import { Autocomplete } from './constants/AutoComplete';
import { NotificationSnackbar } from './constants/NotificationSnackbar';
import { Radius } from './constants/Radius';
import { darkShadows, lightShadows } from './constants/BoxShadows';
import { Tooltip } from 'src/constants/Tooltip';
import { FormControlLabel } from './constants/FormControlLabel';

export const createAppTheme = (mode: 'light' | 'dark' = 'light'): Theme => {
  return createTheme({
    palette: createColorPalette(mode),
    typography,
    shadows: (mode === 'dark' ? darkShadows : lightShadows) as Theme['shadows'],
    shape: {
      small: `${Radius.S}px`,
      medium: `${Radius.M}px`,
      large: `${Radius.L}px`,
      borderRadius: `${Radius.M}px`,
    },
    components: {
      MuiButton: Button,
      MuiTextField: TextField,
      MuiStepper: Stepper,
      MuiDrawer: Drawer,
      MuiTable: Table,
      MuiLink: Link,
      MuiIconButton: IconButton,
      MuiSwitch: Switch,
      MuiSelect: Select,
      MuiMenuItem: MenuItem,
      MuiMenu: Menu,
      MuiListItemIcon: MenuListIcon,
      MuiBox: Box,
      MuiAutocomplete: Autocomplete,
      MuiSnackbar: NotificationSnackbar,
      MuiTooltip: Tooltip,
      MuiFormControlLabel: FormControlLabel,
    } as ThemeOptions['components'],
  });
};

export const theme = createAppTheme('light');

export default theme;

export default (
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none">
    <path
      d="M6.67711 0.57666H17.8576C19.0411 0.57666 20.0006 1.53609 20.0006 2.7196V22.2805C20.0006 23.4641 19.0411 24.4235 17.8576 24.4235H2.14294C0.959429 24.4235 0 23.4641 0 22.2805L0 7.25377L6.67711 0.57666Z"
      fill="#D31919"
      stroke="none"
    />
    <path
      d="M0 7.25377L6.67735 0.57666L6.67719 5.11091C6.67714 6.29439 5.71773 7.25377 4.53424 7.25377H0Z"
      fill="#FF7070"
      stroke="none"
    />
    <path
      d="M5.45977 14.3121C5.67344 14.3121 5.83623 14.2638 5.94816 14.1671C6.06516 14.0654 6.12367 13.9128 6.12367 13.7093C6.12367 13.516 6.06262 13.3684 5.94052 13.2667C5.82352 13.1598 5.65055 13.1064 5.42162 13.1064C5.34022 13.1064 5.27154 13.109 5.21558 13.1141C5.16471 13.1141 5.11129 13.1191 5.05533 13.1293V14.3121H5.45977ZM5.06296 16.7617C5.007 16.7769 4.91797 16.7922 4.79588 16.8075C4.67887 16.8227 4.56186 16.8303 4.44485 16.8303C4.32784 16.8303 4.22355 16.8202 4.13198 16.7998C4.0455 16.7846 3.97173 16.754 3.91068 16.7082C3.84963 16.6625 3.80385 16.5989 3.77332 16.5175C3.7428 16.4361 3.72754 16.3292 3.72754 16.197V12.6028C3.72754 12.4959 3.75552 12.4145 3.81148 12.3586C3.87253 12.2975 3.95392 12.2492 4.05567 12.2136C4.22864 12.1526 4.4245 12.1093 4.64326 12.0839C4.8671 12.0533 5.09094 12.0381 5.31478 12.0381C6.01683 12.0381 6.55355 12.1882 6.92492 12.4883C7.2963 12.7885 7.48198 13.1955 7.48198 13.7093C7.48198 13.9586 7.44128 14.1849 7.35989 14.3884C7.28358 14.5868 7.16657 14.7598 7.00886 14.9073C6.85624 15.0498 6.66038 15.1617 6.42128 15.2431C6.18726 15.3194 5.91509 15.3576 5.60476 15.3576H5.06296V16.7617Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M9.48935 15.8002C9.54531 15.8103 9.61399 15.8205 9.69538 15.8307C9.77678 15.8358 9.85309 15.8383 9.92431 15.8383C10.0973 15.8383 10.255 15.8129 10.3974 15.762C10.545 15.7111 10.6696 15.6323 10.7714 15.5254C10.8782 15.4186 10.9596 15.2813 11.0155 15.1134C11.0766 14.9404 11.1071 14.7344 11.1071 14.4953C11.1071 14.0374 11.0003 13.694 10.7866 13.4651C10.5729 13.2311 10.2881 13.1141 9.93195 13.1141C9.86072 13.1141 9.78696 13.1166 9.71065 13.1217C9.63942 13.1268 9.56566 13.1344 9.48935 13.1446V15.8002ZM9.91668 16.9143C9.8302 16.9143 9.731 16.9117 9.61908 16.9067C9.50715 16.9016 9.39015 16.8914 9.26805 16.8761C9.15104 16.8609 9.03149 16.8405 8.90939 16.8151C8.79239 16.7947 8.68301 16.7642 8.58126 16.7235C8.30146 16.6167 8.16156 16.431 8.16156 16.1664V12.6028C8.16156 12.4959 8.18954 12.4145 8.2455 12.3586C8.30655 12.2975 8.38794 12.2492 8.48969 12.2136C8.70844 12.1424 8.93992 12.0966 9.18411 12.0762C9.4283 12.0508 9.64706 12.0381 9.84037 12.0381C10.2372 12.0381 10.5958 12.0864 10.9163 12.1831C11.2419 12.2797 11.5192 12.4298 11.7481 12.6333C11.9821 12.8317 12.1627 13.0835 12.2899 13.3888C12.4171 13.694 12.4807 14.0552 12.4807 14.4724C12.4807 14.8794 12.4196 15.2355 12.2976 15.5407C12.1755 15.8409 11.9999 16.0952 11.771 16.3038C11.5472 16.5073 11.2775 16.6599 10.9621 16.7617C10.6467 16.8634 10.2982 16.9143 9.91668 16.9143Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M16.4947 12.1678C16.5303 12.2238 16.5608 12.3001 16.5863 12.3967C16.6168 12.4883 16.6321 12.585 16.6321 12.6867C16.6321 12.8851 16.5888 13.0276 16.5023 13.1141C16.4209 13.1955 16.309 13.2362 16.1666 13.2362H14.5641V14.0985H16.2353C16.276 14.1544 16.309 14.2282 16.3345 14.3198C16.365 14.4113 16.3802 14.508 16.3802 14.6097C16.3802 14.803 16.337 14.9429 16.2505 15.0294C16.1691 15.1108 16.0572 15.1515 15.9148 15.1515H14.5793V16.7617C14.5234 16.7769 14.4343 16.7922 14.3122 16.8075C14.1952 16.8227 14.0782 16.8303 13.9612 16.8303C13.8442 16.8303 13.7399 16.8202 13.6484 16.7998C13.5619 16.7846 13.4881 16.754 13.4271 16.7082C13.366 16.6625 13.3202 16.5989 13.2897 16.5175C13.2592 16.4361 13.2439 16.3292 13.2439 16.197V12.8775C13.2439 12.6587 13.3075 12.4858 13.4347 12.3586C13.5619 12.2314 13.7348 12.1678 13.9536 12.1678H16.4947Z"
      fill="white"
      stroke="none"
    />
  </svg>
);

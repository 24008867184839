import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableCell,
  TableFooter,
  TableRow,
  Checkbox,
  TableContainer,
  Pagination,
  TablePagination,
  Stack,
  Paper,
  Grid2 as Grid,
  Skeleton,
  Radio,
  useTheme,
  Typography,
  Badge,
  SxProps,
} from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '../Box';
import { Radius } from '../../constants/Radius';
import { IconButton } from '../IconButton';
import { Text } from '../Text';
import { debounce, isEqual } from 'lodash';
import { TextField } from '../TextField';
import {
  TableContext,
  TableColumn,
  Filters,
  FilterValueType,
  CustomTableProps,
  SortType,
  RowHighlightType,
  TableRowType,
} from './types';
import { FilterInput } from './components/FilterInput';
import { useIptorTranslation } from '../../framework/translations';
import { TextSize, TextWeight } from 'src/constants/Typography';
import { ColumnVisibility } from './components/TableSettings/ColumnVisibility';
import { useLocation } from 'react-router-dom';
import { ApplicationContext } from 'src/utils/ApplicationContext';
import { useComponentState } from 'src/utils/FormState';

export const TableSettings = (props: {
  slotProps?: {
    iconButton?: { sx?: SxProps };
  };
}) => {
  const { t } = useIptorTranslation();
  const { setShowSettings, showSettings, columnSettings, setHCols } = useContext(TableContext);
  const { palette } = useTheme();

  return (
    <>
      <IconButton
        sx={{
          border: `1px solid ${palette.mode === 'light' ? palette.foreground[700] : palette.primary.main}`,
          outline: 'none',
          ...props?.slotProps?.iconButton?.sx,
        }}
        variant="outlined"
        icon="settings"
        onClick={() => setShowSettings(true)}
        tooltipText={t('common:TXT_Settings')}
      />

      <ColumnVisibility
        isShown={showSettings}
        hide={() => setShowSettings(false)}
        columnsSettings={columnSettings}
        modalTitle={t('common:TABLE_EditColumnVisibility')}
        setHCols={setHCols}
      />
    </>
  );
};

export const TableFilter = (props: {
  slotProps?: {
    iconButton?: { sx?: SxProps };
  };
}) => {
  const { setShowFilter, showFilter, filters, setFilters } = useContext(TableContext);
  const { t } = useIptorTranslation();
  const { palette } = useTheme();

  return (
    <Badge
      color="primary"
      slotProps={{
        badge: {
          style: {
            padding: 0,
            background: 'transparent',
            marginTop: (props.slotProps?.iconButton?.sx as { marginTop?: number })?.marginTop ?? 0,
          },
        },
      }}
      badgeContent={
        filters?.length > 0 ? (
          <IconButton
            icon="xCircle"
            variant="contained"
            onClick={() => setFilters([])}
            sx={{
              height: 24,
              width: 24,
              borderRadius: 10,
              p: 0,
              m: 0,
              outline: 'none',
              background: 'transparent!important',
              color: palette.primary.main,
            }}
          />
        ) : (
          0
        )
      }
    >
      <IconButton
        sx={{
          border: `1px solid ${palette.mode === 'light' ? palette.foreground[700] : palette.primary.main}`,
          outline: 'none',
          ...props?.slotProps?.iconButton?.sx,
        }}
        onClick={() => setShowFilter((filter) => !filter)}
        variant="outlined"
        icon="filter"
        tooltipText={showFilter ? t('common:TABLE_HideFilters') : t('common:TABLE_ShowFilters')}
      />
    </Badge>
  );
};

export const Table = ({
  tableID,
  columns: _columns,
  rows,
  pageSize = 5,
  totalRows,
  hasMorePages = false,
  additionalFilters,
  additionalActions,
  className,
  selectable = 'none',
  disableActionBar = false,
  maxHeight = 600, //Default height to approximately 5 rows
  height = 'auto',
  loading = false,
  onSelectedRowsChange,
  onRowsChange,
  onPageChange,
  onSort,
  onFilter,
  onLoad,
  internalRef,
  noDataLabel,
}: PropsWithChildren<CustomTableProps>) => {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState<TableRowType[]>([]);
  const [sort, setSort] = useState<{ column: string; order: SortType } | undefined>();
  const { palette } = useTheme();

  const { t } = useIptorTranslation();

  useEffect(() => {
    if (sort) onSort?.(sort);
  }, [sort]);

  const getSort = useCallback(
    (column: string) => {
      if (sort?.column === column) {
        if (sort.order === SortType.ASCENDING) return SortType.DESCENDING;
        if (sort.order === SortType.DESCENDING) return SortType.NONE;
        return SortType.ASCENDING;
      } else {
        return SortType.ASCENDING;
      }
    },
    [sort],
  );

  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);

  const totalCount = useMemo(() => {
    if (totalRows === 'unknown') {
      if (!hasMorePages) {
        //If no more pages compute count from page and current rows
        return (page - 1) * rowsPerPage + rows.length;
      } else {
        //Count is unknown only if the system has more rows to offer
        return -1;
      }
    } else {
      return totalRows;
    }
  }, [hasMorePages, page, rowsPerPage, rows, totalRows]);

  useEffect(() => {
    onPageChange?.({ page: page, pageSize: rowsPerPage });
  }, [page, rowsPerPage]);

  const [_enhancedRows, setEnhancements] = useState<TableRowType[]>([]);

  const visibleRows = useMemo<TableRowType[]>(() => {
    if (loading || rows.length === 0) return Array.from({ length: rowsPerPage }).fill({ dummy: 'test' }); // Create empty table when loading or no data is available
    return [...rows.map((r, i) => ({ ...r, ...(_enhancedRows[i] ?? {}) }))]; /*.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage,
      );*/
  }, [rows, rowsPerPage, page, _enhancedRows, loading]);

  useEffect(() => {
    onSelectedRowsChange?.({ rows: selected });
  }, [selected, visibleRows]);

  useEffect(() => {
    onRowsChange?.({ rows: visibleRows });
  }, [visibleRows]);

  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<Filters | undefined>();
  const [scroll, setScroll] = useState(0);
  const containerRef = useRef<HTMLDivElement>();

  const [showSettings, setShowSettings] = useState(false);

  const [stateLoaded, setStateLoaded] = useState(false);
  const { componentState } = useComponentState(tableID, {
    page,
    selected,
    sort,
    filters,
    rowsPerPage,
    scroll,
  });

  useEffect(() => {
    const { page, selected, sort, filters, rowsPerPage } = componentState;
    if (stateLoaded) return;
    setPage(page ?? 1);
    setSelected(selected ?? []);
    setSort(sort);
    setFilters(filters);
    setRowsPerPage(rowsPerPage ?? pageSize);
    setStateLoaded(true);
    setShowFilter(filters?.length > 0); // Show filter in case filters are stored as part of state
  }, [componentState]);

  useEffect(() => {
    // Restore scroll on load
    console.log(`setting scroll ${componentState.scroll}`, containerRef.current);
    if (containerRef.current && stateLoaded) containerRef.current.scrollTop = componentState.scroll;
  }, [componentState.scroll, stateLoaded]);

  useEffect(() => {
    if (stateLoaded) onLoad?.(page, rowsPerPage, sort, filters);
  }, [stateLoaded, page, sort, filters, rowsPerPage]);

  useEffect(() => {
    if (filters) onFilter?.(filters);
  }, [filters]);

  useImperativeHandle(
    internalRef,
    () => ({
      setSelected,
      rows: visibleRows,
      selectedRows: selected,
      page,
      rowsPerPage,
      resetState: () => {
        setPage(1);
        setSelected([]);
        setSort(undefined);
        setFilters(undefined);
        setRowsPerPage(pageSize);
        setStateLoaded(true);
        setShowFilter(false);
        if (containerRef.current) containerRef.current.scrollTop = 0;
      },
      resetToFirstPage: () => setPage(1), //Needed when filtering from outside with just params
      deselectRows: ({ row }: { row: TableRowType }) => setSelected([...selected.filter((r) => !isEqual(row, r))]),
      filter: (c: string, v: FilterValueType, resetToFirstPage = false) => {
        setFilters((f) => [...(f ?? []).filter(({ column }) => column !== c), { column: c, value: v }]);
        if (resetToFirstPage) setPage(1);
      },
      sort: (c: string, o: SortType) => setSort({ column: c, order: o }),
    }),
    [visibleRows, selected, page, setPage, pageSize, rowsPerPage, setSelected],
  );

  const setColumnValueForRow = (index: number, values: { column: string; value: string }[]) => {
    setEnhancements((prevEnhancements) => {
      const enhancements = [...prevEnhancements];
      enhancements[index] = {
        ...(enhancements[index] ?? {}),
        ...Object.fromEntries(values.map(({ column, value }) => [column, value])),
      };
      return enhancements;
    });
  };

  const bgColors = {
    [RowHighlightType.normal]: '',
    [RowHighlightType.success]: palette.primary[200],
    [RowHighlightType.warn]: palette.warning.light,
    [RowHighlightType.error]: palette.error.light,
    [RowHighlightType.info]: palette.info[100],
  };
  const borderColors = {
    [RowHighlightType.normal]: '',
    [RowHighlightType.success]: palette.primary.main,
    [RowHighlightType.warn]: palette.warning.main,
    [RowHighlightType.error]: palette.error.main,
    [RowHighlightType.info]: palette.info.main,
  };

  const goToPage = debounce((page: number) => {
    if (totalRows === 'unknown') {
      if (page < 1) return;
      setPage(page);
      return;
    }
    if (page * rowsPerPage > totalRows) return;
    if (page < 1) return;
    setPage(page);
  }, 500);

  const [hCols, setHCols] = useState<string[]>([]);
  const location = useLocation();
  const { applicationID } = useContext(ApplicationContext);

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  useEffect(() => {
    const currentSettings = JSON.parse(sessionStorage.getItem('clientSettings') ?? '{}');
    const formID = `${applicationID}`;
    const panelID = `${location.pathname}`; //Use path as new application does not have panels
    const toggleID = 'NA'; //Static as new application does not have toggles
    setHCols(currentSettings.tables?.[formID]?.[panelID]?.[tableID]?.[toggleID]?.hCols ?? []);
    setSettingsLoaded(true);
  }, [tableID, applicationID, location]);

  useEffect(() => {
    /**
     * formID: formID,
     * panelID: panelID,
     * tableID: id,
     * toggleID: toggleID,
     * hCols: hcols,
     **/
    if (!applicationID || !location?.pathname || !settingsLoaded || !tableID) return;
    const currentSettings = JSON.parse(sessionStorage.getItem('clientSettings') ?? '{}');
    const formID = `${applicationID}`;
    const panelID = `${location.pathname}`; //Use path as new application does not have panels
    const toggleID = 'NA'; //Static as new application does not have toggles
    sessionStorage.setItem(
      'clientSettings',
      JSON.stringify({
        ...currentSettings,
        tables: {
          ...(currentSettings.tables ?? {}),
          [formID]: {
            ...currentSettings.tables?.[formID],
            [panelID]: {
              ...currentSettings.tables?.[formID]?.[panelID],
              [tableID]: {
                ...currentSettings.tables?.[formID]?.[panelID]?.[tableID],
                [toggleID]: {
                  ...currentSettings.tables?.[formID]?.[panelID]?.[tableID]?.[toggleID],
                  hCols: hCols,
                },
              },
            },
          },
          [tableID]: {
            ...currentSettings.tables?.[tableID],
            hCols: hCols,
          },
        },
      }),
    );
  }, [hCols, location, tableID, applicationID, settingsLoaded]);

  const columnSettings = useMemo(() => {
    return _columns
      .map((c) => ({
        id: c.key,
        name: c.display,
        hide: !!hCols.find((h) => h === c.key),
      }))
      .filter((c) => !!c.name);
  }, [_columns, hCols]);

  const columns = useMemo(() => {
    return _columns.filter((c) => !hCols.includes(c.key));
  }, [_columns, hCols]);

  const firstFilterIndex = useRef(-1);

  if (!stateLoaded) return <></>;

  return (
    <TableContext.Provider
      value={{
        filters,
        showFilter,
        setShowFilter,
        setFilters,
        showSettings,
        setShowSettings,
        columnSettings,
        hCols,
        setHCols,
      }}
    >
      <Stack direction={'column'} sx={{ width: '100%' }}>
        {!disableActionBar && (
          <Paper elevation={0} sx={{ mb: 1.5, borderRadius: `${Radius.M}px` }}>
            <Box sx={(theme) => ({ boxShadow: theme.shadows[1] })}>
              <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid justifyContent={'flex-start'} container size={'grow'}>
                  {additionalFilters}
                </Grid>
                <Grid justifyContent={'flex-end'} size={'auto'} alignItems={'center'} container>
                  {additionalActions}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
        <TableContainer
          ref={containerRef}
          onScroll={(e) => setScroll((e.target as HTMLDivElement).scrollTop)}
          style={{ maxHeight: maxHeight, height: height }}
          className={className}
        >
          <MuiTable sx={{ height: '100%' }} stickyHeader>
            <TableHead>
              <TableRow sx={{ background: palette.background.paper }}>
                {['single', 'multiple'].includes(selectable) && (
                  <TableCell
                    className="selectable-box"
                    sx={{
                      background: palette.background.default,
                      borderBottom: 'none',
                    }}
                  >
                    {selectable === 'multiple' ? (
                      <Checkbox
                        size={'small'}
                        checked={visibleRows.every((r) => selected.findIndex((s) => isEqual(s, r)) > -1)}
                        indeterminate={
                          selected.length > 0 &&
                          !visibleRows.every((r) => selected.findIndex((s) => isEqual(s, r)) > -1)
                        }
                        onChange={(e, checked) =>
                          setSelected((prev) =>
                            checked
                              ? [...visibleRows, ...prev.filter((r) => !visibleRows.some((vr) => isEqual(vr, r)))]
                              : [...prev.filter((r) => !visibleRows.some((vr) => isEqual(vr, r)))],
                          )
                        }
                        disabled={loading}
                      />
                    ) : (
                      <div />
                    )}
                  </TableCell>
                )}
                {(columns ?? []).map((col: TableColumn) => (
                  <TableCell
                    align={col.columnAlignment}
                    sx={{
                      background: palette.background.default,
                      borderBottom: 'none',
                      color: `${palette.text.secondary}!important`,
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Text color={palette.foreground[100]}>{col.display}</Text>
                      {col.disableSort ? (
                        <div />
                      ) : (
                        <IconButton
                          variant="text"
                          size="small"
                          sx={{
                            color:
                              palette.mode === 'light'
                                ? `${palette.foreground[500]}!important`
                                : `${palette.primary.main}!important`,
                            outline: 'none',
                          }}
                          onClick={() => setSort({ column: col.key, order: getSort(col.key) })}
                          icon={
                            sort?.column === col.key
                              ? sort?.order === SortType.ASCENDING
                                ? 'sortIconAsc'
                                : sort?.order === SortType.DESCENDING
                                  ? 'sortIconDesc'
                                  : 'sortIcon'
                              : 'sortIcon'
                          }
                        />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ display: showFilter ? 'table-row' : 'none' }}>
                {['single', 'multiple'].includes(selectable) && (
                  <TableCell sx={{ top: 68 }} className="selectable-box">
                    <div />
                  </TableCell>
                )}
                {(columns ?? []).map((col: TableColumn, index: number) => {
                  if (firstFilterIndex.current === -1 && !col.disableFilter) {
                    firstFilterIndex.current = index;
                  }
                  return (
                    <TableCell sx={{ top: 68 }}>
                      {col.disableFilter ? (
                        <div />
                      ) : (
                        <FilterInput
                          key={index}
                          col={col}
                          isFirstFilter={firstFilterIndex.current === index}
                          value={filters?.find((f) => f.column === col.key)?.value ?? {}}
                          filters={filters}
                          setFilters={(filters: Filters) => {
                            setFilters(filters);
                            setPage(1);
                          }}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: 'relative' }}>
              {!loading && rows.length === 0 && (
                <Typography
                  variant="body1"
                  sx={{
                    alignContent: 'center',
                    textAlign: 'center',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {noDataLabel ?? t('common:TABLE_NoDataToShow')}
                </Typography>
              )}
              {(visibleRows ?? []).map((row: TableRowType, index: number) => {
                const bg: string = bgColors[row.rowHighlightType ?? RowHighlightType.normal];
                const border: string = borderColors[row.rowHighlightType ?? RowHighlightType.normal];

                return (
                  <TableRow
                    sx={{
                      background:
                        rows.length === 0
                          ? 'transparent!important'
                          : bg
                            ? `${bg}!important`
                            : `${palette.background.default}!important`,
                      '&:hover': {
                        backgroundColor: `${palette.action.hover}!important`,
                      },
                    }}
                  >
                    {['single', 'multiple'].includes(selectable) && (
                      <TableCell
                        sx={{
                          borderLeft: border ? `2px solid ${border}` : ``,
                          visibility: `${!loading && rows.length === 0 ? 'hidden' : 'visible'}!important`,
                        }}
                        className="selectable-box"
                      >
                        {selectable === 'multiple' ? (
                          <Checkbox
                            checked={selected.findIndex((r) => isEqual(row, r)) > -1}
                            onChange={(e, checked) =>
                              setSelected([
                                ...selected.filter((r) => !isEqual(row, r)),
                                ...(checked ? [{ ...row }] : []),
                              ])
                            }
                            size={'small'}
                            disabled={loading}
                          />
                        ) : (
                          <Radio
                            disabled={loading}
                            checked={selected.findIndex((r) => isEqual(row, r)) > -1}
                            onChange={(e, checked) => {
                              //Seems to be always true but keeping the condition for safety
                              if (checked) {
                                setSelected([{ ...row }]);
                              } //Else should not be added as it will empty the selected rows
                            }}
                          />
                        )}
                      </TableCell>
                    )}
                    {(columns ?? []).map(({ cellRenderer: CellRenderer, ...col }: TableColumn, colIdx: number) => {
                      const value = row[col.key];
                      return (
                        <TableCell
                          align={col.columnAlignment}
                          sx={
                            colIdx === 0 && selectable === 'none'
                              ? {
                                  borderLeft: border ? `2px solid ${border}` : ``,
                                  '& .MuiStack-root': {
                                    alignItems: 'baseline',
                                    '& .MuiBox-root': { marginLeft: '8px' },
                                  },
                                }
                              : {
                                  '& .MuiStack-root': {
                                    alignItems: 'baseline',
                                    '& .MuiBox-root': { marginLeft: '8px' },
                                  },
                                }
                          }
                        >
                          {loading ? (
                            <Skeleton height={'100%'} width={'100%'} /> // Loaders
                          ) : rows.length === 0 ? (
                            <div style={{ height: '100%', width: '100%' }} /> // Empty placeholders
                          ) : CellRenderer ? (
                            <CellRenderer
                              row={row}
                              col={col}
                              value={value}
                              setColumnValueForRow={(values) => {
                                setColumnValueForRow(index, values);
                              }}
                              setHighlightStateForRow={({ rowHighlightType, rowHighlightMessage }) => {
                                setColumnValueForRow(index, [
                                  { column: 'rowHighlightType', value: rowHighlightType },
                                  { column: 'rowHighlightMessage', value: rowHighlightMessage },
                                ]);
                              }}
                            />
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ background: `${palette.background.default}!important` }}>
                <TablePagination
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    background: `${palette.background.paper}!important`,
                    borderTop: `1px solid ${palette.divider}`,
                    '& .MuiTablePagination-displayedRows': {
                      fontWeight: TextWeight.SEMIBOLD,
                      fontSize: TextSize.TABLE_FOOTER,
                      color: `${palette.text.primary} !important`,
                    },
                  }}
                  labelRowsPerPage={''}
                  labelDisplayedRows={({ to, count }) => {
                    if (count !== -1) {
                      return t('common:TABLE_Pagination', `${to > count ? count : to} / ${count}`, {
                        to: to > count ? count : to,
                        count,
                      });
                    } else {
                      const _to = rows.length > 0 ? to : '-';
                      return t('common:TABLE_Pagination_unknown', `${_to} / > ${_to}`, {
                        to: _to,
                      });
                    }
                  }}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  count={totalCount}
                  page={page - 1}
                  onPageChange={(event: unknown, newPage: number) => {
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(1);
                  }}
                  slotProps={{
                    select: {
                      variant: 'outlined',
                      size: 'small',
                      color: 'primary',
                      sx: {
                        fontSize: '16px',
                        width: 'auto',
                        '& .MuiSelect-select.MuiTablePagination-select': {
                          padding: '12px 32px 12px 16px!important',
                        },
                      },
                      IconComponent: KeyboardArrowDownRounded,
                    },
                  }}
                  ActionsComponent={() => {
                    return (
                      <Stack direction={'row'} alignItems={'center'} sx={{ height: '68px' }}>
                        <Pagination
                          shape="rounded"
                          sx={{
                            '& li button.Mui-selected': {
                              background: palette.primary.main,
                              color: palette.foreground[900],
                              fontSize: TextSize.TABLE_FOOTER,
                            },
                            '& .MuiPaginationItem-previousNext': {
                              color: palette.primary.main,
                            },
                          }}
                          count={Math.ceil(
                            (totalRows === 'unknown' ? page * rowsPerPage + +hasMorePages : totalRows) / rowsPerPage,
                          )}
                          page={page}
                          onChange={(e: unknown, newPage: number) => {
                            setPage(newPage);
                          }}
                          size="large"
                        />
                        <div style={{ height: 42, width: 1, background: palette.foreground[400], margin: '0 16px' }} />
                        <Text variant="body2" sx={{ mr: 2, fontSize: '16px' }}>
                          Go to page
                        </Text>
                        <TextField
                          sx={{ width: 55, ml: 2 }}
                          onChange={(e) => {
                            //Stop defaults and propagation if number is not entered or < 1 is entered
                            if (isNaN(+e.target.value)) return false;
                            if (+e.target.value < 1) return false;
                          }}
                          onKeyDown={(e) => {
                            if (e.key?.toLowerCase()?.includes('enter')) {
                              //Change page once enter is pressed
                              e.preventDefault();
                              e.stopPropagation();
                              goToPage(+(e.target as HTMLInputElement).value);
                            }
                          }}
                          variant="outlined"
                          color="primary"
                          size="small"
                        />
                      </Stack>
                    );
                  }}
                />
              </TableRow>
            </TableFooter>
          </MuiTable>
        </TableContainer>
      </Stack>
    </TableContext.Provider>
  );
};

export * from './types';

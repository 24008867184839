import { PrintConfirmationDialog, PrintConfirmationDialogWrapperProps } from './PrintConfirmationDialog';
import { useIptorTranslation } from '@iptor/base';

export const ProformaInvoice = ({
  open,
  handleClose,
  selectedRows,
  onSuccess,
}: PrintConfirmationDialogWrapperProps) => {
  const { t } = useIptorTranslation();
  return (
    <PrintConfirmationDialog
      open={open}
      handleClose={handleClose}
      title={t('sales_orders:ProformaInvoice')}
      selectedRows={selectedRows || []}
      documentType={'proFormaInvoice'}
      onSuccess={onSuccess}
    />
  );
};

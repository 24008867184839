import { Theme } from '@mui/material';

export const MenuListIcon = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      minWidth: 'auto!important',
      borderRadius: theme.spacing(0.75),
      padding: theme.spacing(0.75),
      variants: [
        {
          props: { color: 'dark' },
          style: {
            background: theme.palette.tertiary.bigStone,
          },
        },
      ],
    }),
  },
};

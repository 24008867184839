import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './locales';
import HttpBackend from 'i18next-http-backend';
import Backend from 'i18next-chained-backend';
// import resourcesToBackend from 'i18next-resources-to-backend';

export const defaultNS = 'common';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    partialBundledLanguages: true,
    resources,
    defaultNS,
    debug: true,
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          parse: (data: any) => {
            console.log(data);
            return data;
          },
        },
      ],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

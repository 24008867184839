import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios, { AxiosResponse } from 'axios';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { IconsCombo } from '../components/Combo';
import { connectField } from 'uniforms';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';

const singleValueNew: CellPlugin = {
  Renderer: ({ data }: any) => {
    const [response, setResponse] = useState<any>({});
    const getData = async () => {
      const date = new Date();
      const millisec = date.getTime();
      const id = millisec.toString(36);
      let payload = {
        control: {
          IptorAPI: '1.0',
          method: 'salesOrderStatistics.get',
          id: id,
        },
      };
      let res = await axios
        .request({
          url: '/aperio/api/service',
          data: payload,
          method: 'POST',
        })
        .catch((err) => {
          console.error(`Error from singleValueNew ${err}`);
        });
      let responseData = {
        newSalesOrderMTD: (res as AxiosResponse)?.data?.data?.newSalesOrderMTD,
        openOrdersValueTillDate:
          (res as AxiosResponse)?.data?.data?.openOrdersValueTillDate !== undefined
            ? nFormatter((res as AxiosResponse)?.data?.data?.openOrdersValueTillDate, 2)
            : undefined,
        totalRevenueMTD:
          (res as AxiosResponse)?.data?.data?.totalRevenueMTD !== undefined
            ? nFormatter((res as AxiosResponse)?.data?.data?.totalRevenueMTD, 2)
            : undefined,
        newSentSalesOrderMTD: (res as AxiosResponse)?.data?.data?.newSentSalesOrderMTD,
        serviceLevel: '98',
      };
      setResponse(responseData || {});
    };
    useEffect(() => {
      getData();
    }, [data]);

    const nFormatter = (num: number, digits: number) => {
      const isNeg = num < 0;
      if (isNeg) num = num * -1;
      const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
      ];
      let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      let i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
          break;
        }
      }

      let decSep = localStorage.getItem('localizationInfo') || '.';
      let localInfo = JSON.parse(decSep);
      let locale = localInfo.currentLocale;
      let decimalSep = localInfo.settingsList[locale].decimalSeparator;

      let val = (num / si[i].value).toFixed(digits).replace(rx, '$1').replace('.', decimalSep);
      return (isNeg ? '-' : '') + val + si[i].symbol;
    };

    const defaultTitles: any = {
      newSalesOrderMTD: 'New Sales orders',
      openOrdersValueTillDate: 'Open orders value',
      totalRevenueMTD: 'Total revenue',
      newSentSalesOrderMTD: 'New Sent Sales orders',
    };

    const defaultIcons: any = {
      newSalesOrderMTD: 'Briefcase',
      openOrdersValueTillDate: 'Doc',
      totalRevenueMTD: 'DollarSign',
      newSentSalesOrderMTD: 'Package',
    };

    return (
      <div className={'card'}>
        <Widget height={'auto'} className={'low-pad'}>
          <div className="single-value">
            <Row noGutters>
              <Col className="left-container" sm={7}>
                <h6>{data.leftTitle}</h6>
                <h5 className={'truncate'}>
                  <SquareIcon size="24px">
                    {data.icon
                      ? Icons[data.icon as keyof typeof Icons]
                      : data.value
                      ? Icons[defaultIcons[data.value] as keyof typeof Icons]
                      : Icons.Edit}
                  </SquareIcon>
                  {data.title || defaultTitles[data.value]}
                </h5>
              </Col>
              <Col className="right-container text-right" sm={5}>
                <h6></h6>
                <h5 className="truncate">{response?.[data.value] === undefined ? '-' : response?.[data.value]}</h5>
              </Col>
            </Row>
          </div>
        </Widget>
      </div>
    );
  },
  id: 'single-value-new',
  title: 'New Sales statistics KPI widget',
  description: 'Display sales KPI',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        value: {
          type: 'string',
          uniforms: {
            component: connectField(({ value, onChange, ...props }: any) => {
              return (
                <FormControl fullWidth>
                  <InputLabel variant="standard" id="value-select-input-label">
                    Value to display
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="value-select"
                    variant="standard"
                    value={value}
                    label="Age"
                    onChange={(e) => {
                      onChange(e.target.value as string);
                    }}
                  >
                    <MenuItem value={'newSalesOrderMTD'}>New Sales orders (Month till date)</MenuItem>
                    <MenuItem value={'openOrdersValueTillDate'}>Open orders value (Total till date)</MenuItem>
                    <MenuItem value={'totalRevenueMTD'}>Total revenue (Month till date)</MenuItem>
                    <MenuItem value={'newSentSalesOrderMTD'}>New sent Sales orders (Month till date)</MenuItem>
                  </Select>
                </FormControl>
              );
            }),
          },
          enum: ['newSalesOrderMTD', 'openOrdersValueTillDate', 'totalRevenueMTD', 'newSentSalesOrderMTD'],
        },
        title: {
          type: 'string',
        },
        icon: {
          type: 'string',
          uniforms: {
            component: IconsCombo,
          },
          enum: Object.keys(Icons),
        },
      },
      required: [],
    },
  },
};
export default singleValueNew;

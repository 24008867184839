/*
  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  REMARK 20231121: apart from formatting, exact copy of server code except: const origin = ApplicationError.Origin.CLIENT;
  Make sure changes are synchronised !!!!!!

  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
*/

import axios, { AxiosError, AxiosResponse } from 'axios';

export class ApplicationError {
  private _type: ApplicationError.Type;
  private _messageKey: string; //                             BEWARE: used as a translation key!!
  private _params: string[] | undefined; // NTH: value, type, format (ISO vs Server(BEWARE ALWAYS COMMA) vs CLIENT), translate,
  private _details: string[] | undefined; //                  english only
  private _originalCode: string | undefined; //               eg 403, ENOTFOUND
  private _origin: ApplicationError.Origin;
  private _group: ApplicationError.Group | undefined;
  //NTH: server, retry, errorReport
  private constructor(
    type: ApplicationError.Type,
    messageKey: string,
    params: string[] | undefined,
    details: string[] | undefined,
    originalCode: string | undefined,
    origin: ApplicationError.Origin,
    group: ApplicationError.Group | undefined
  ) {
    this._type = type;
    this._messageKey = messageKey;
    this._params = params;
    this._details = details;
    this._originalCode = originalCode;
    this._origin = origin;
    this._group = group;
  }
  public toJSON = (): ApplicationError.JSON => {
    return {
      type: this._type,
      messageKey: this._messageKey,
      params: this._params,
      details: this._details,
      originalCode: this._originalCode,
      origin: this._origin,
      group: this._group
    };
  };
  public get type(): ApplicationError.Type {
    return this._type;
  }
  public get messageKey(): string {
    return this._messageKey;
  }
  public get params(): string[] | undefined {
    return this._params;
  }
  public get details(): string[] | undefined {
    return this._details;
  }
  public get originalCode(): string | undefined {
    return this._originalCode;
  }
  public get origin(): ApplicationError.Origin {
    return this._origin;
  }
  public get group(): ApplicationError.Group | undefined {
    return this._group;
  }
  public changeMessage(key: string, ...params: string[]) {
    this._messageKey = key;
    this._params = params?.length > 0 ? params : undefined;
  }
  public changeDetails(...details: string[]) {
    this._details = details?.length > 0 ? details : undefined;
  }
  public static fromJSON = (json: ApplicationError.JSON): ApplicationError => {
    // NTH: errorhandling
    return new ApplicationError(
      json.type,
      json.messageKey,
      json.params,
      json.details,
      json.originalCode,
      json.origin || origin,
      json.group
    );
  };
  public static fromAxiosError(axiosError: any): ApplicationError {
    // Program protection
    // ==================
    /*
      Remark: 
        Using typescript, you could expect a typed input parameter like "axiosError: AxiosError"
        However when defining a catch branch it's not possible to directly define the error as an (Axios)Error.
        As a result, for usage comfort, the input parameter was typed as any.
        Beware: this function still expects an AxiosError to be supplied and will generete a run-time Programmin error in any other case
    */

    // --> Parameter mandatory
    if (!axiosError)
      return ApplicationError.createProgrammingError(
        'No value supplied for mandatory parameter "axiosError" when executing "ApplicationError.fromAxiosError"',
        CONTACT_SUPPORT_TEAM
      );

    // --> Parameter must be an error
    if (!(axiosError instanceof Error))
      return ApplicationError.createProgrammingError(
        'Parameter "axiosError" should be an instance of Error when executing "ApplicationError.fromAxiosError"',
        CONTACT_SUPPORT_TEAM
      );

    // --> Parameter must be an axios error
    if (!(axiosError as any).isAxiosError)
      return ApplicationError.createProgrammingError(
        'Parameter "axiosError" should be an axios error when executing "ApplicationError.fromAxiosError"',
        CONTACT_SUPPORT_TEAM
      );

    // Handle known axios errors
    // =========================
    const error = axiosError as AxiosError;

    // --> Case error message is Network Error                  --> set by axios at 0.12 on platforms other than NodeJS.
    if (typeof error.message === 'string' && error.message.toLocaleLowerCase() === 'network error') {
      return ApplicationError.createBase(
        ApplicationError.Type.NETWORK_ERROR,
        'ERROR_Network_error',
        undefined,
        undefined,
        error.code,
        ApplicationError.Group.AXIOS
      );
    }

    // --> Case error code not specified
    if (!error.code)
      return ApplicationError.fromAxiosError_FromResponseStatus(
        error.response ? error.response.status : undefined,
        error
      );

    // --> Case request canceled
    if (error.code === 'ERR_CANCELED')
      return ApplicationError.createBase(
        ApplicationError.Type.CANCEL_ERROR,
        'ERROR_Request_is_canceled',
        undefined,
        ['HTTP request was canceled by the application'],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error codes bad request/response
    if (['ERR_BAD_REQUEST', 'ERR_BAD_RESPONSE'].includes(error.code))
      return ApplicationError.fromAxiosError_FromResponseStatus(error.response?.status, error);

    // --> Case error code connection aborted
    if (error.code === 'ECONNABORTED')
      return ApplicationError.createBase(
        ApplicationError.Type.TIMEOUT_ERROR,
        'ERROR_Timeout_error',
        undefined,
        undefined,
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error code address not found
    if (error.code === 'ENOTFOUND')
      return ApplicationError.createBase(
        ApplicationError.Type.CONNECTION_ERROR,
        'ERROR_Connection_error__the_site_can_not_be_reached',
        undefined,
        [`DNS failure: the gateway can't resolve the host with the given hostname.`],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error code connection refused
    if (error.code === 'ECONNREFUSED')
      return ApplicationError.createBase(
        ApplicationError.Type.CONNECTION_ERROR,
        'ERROR_Connection_error__the_connection_was_refused',
        undefined,
        [
          'No connection could be made because the target machine actively refused it.',
          'This may result from trying to connect to a service that is inactive on the foreign host.',
          'Other possible causes might be Firewall/Anti-Virus software.',
          RETRY_OR_CONTACT_SUPPORT_TEAM
        ],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error code timed out
    if (error.code === 'ETIMEDOUT')
      return ApplicationError.createBase(
        ApplicationError.Type.CONNECTION_ERROR,
        'ERROR_Connection_error__the_site_took_too_long_to_respond',
        undefined,
        [
          'The request took more time than the webserver configuration allows and the connection has been closed by the server.'
        ],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error code connection reset
    if (error.code === 'ECONNRESET')
      return ApplicationError.createBase(
        ApplicationError.Type.CONNECTION_ERROR,
        'ERROR_Connection_error__the_connection_was_reset',
        undefined,
        [
          'A connection was forcibly closed by a peer.',
          'This normally results from a loss of the connection on the remote socket due to a timeout or reboot.',
          RETRY_OR_CONTACT_SUPPORT_TEAM
        ],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // --> Case error code EAI_AGAIN
    if (error.code === 'EAI_AGAIN')
      return ApplicationError.createBase(
        ApplicationError.Type.CONNECTION_ERROR,
        'ERROR_Connection_error__we_are_having_trouble_finding_that_site',
        undefined,
        ['DNS lookup timed out. The domain is possibly unregistered or has expired.'],
        error.code,
        ApplicationError.Group.AXIOS
      );

    // Handle unknown axios errors
    // ===========================
    return ApplicationError.fromAxiosError_CreateUnknownError(error.code, error.name, error.message);
  }
  public static createProgrammingError = (...details: string[]): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.UNEXPECTED_ERROR,
      'ERROR_Programming_error',
      undefined,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.PROGRAMMING_ERROR
    );
  };
  public static createDataError = (...details: string[]): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.UNEXPECTED_ERROR,
      'ERROR_Data_error',
      undefined,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.DATA_ERROR
    );
  };
  public static createAuthenticationError = (...details: string[]): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.AUTHENTICATION_ERROR,
      'ERROR_Authentication_error',
      undefined,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.AUTH
    );
  };
  public static createUnexpectedError = (...details: string[]): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.UNEXPECTED_ERROR,
      'ERR_Unexpected_error',
      undefined,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.UNKNOWN
    );
  };
  public static createLogicalError = (
    messageKey: string,
    params: string[] | undefined,
    ...details: string[]
  ): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.LOGIC_ERROR,
      messageKey,
      params,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.BUSINESS_LOGIC
    );
  };
  public static createConfigError = (
    messageKey: string,
    params: string[] | undefined,
    ...details: string[]
  ): ApplicationError => {
    return ApplicationError.createBase(
      ApplicationError.Type.CONFIG_ERROR,
      messageKey,
      params,
      details?.length > 0 ? details : undefined,
      undefined,
      ApplicationError.Group.BUSINESS_LOGIC
    );
  };
  protected static createBase = (
    type: ApplicationError.Type,
    messageKey: string,
    params: string[] | undefined,
    details: string[] | undefined,
    originalCode: string | undefined,
    group: ApplicationError.Group | undefined
  ) => {
    return new ApplicationError(type, messageKey, params, details, originalCode, origin, group);
  };
  private static fromAxiosError_CreateUnknownError(code?: string, name?: string, message?: string): ApplicationError {
    let details: string[] | undefined = undefined;
    if (message) {
      if (code || name) {
        details = [`${code || name}: ${message}`];
      } else {
        details = [`${message}`];
      }
    } else if (name) {
      if (code) {
        details = [`${code}: ${name}`];
      } else {
        details = [`${name}`];
      }
    } else if (code) {
      details = [`${code}`];
    }

    return ApplicationError.createBase(
      ApplicationError.Type.UNKNOWN_ERROR,
      'ERROR_Unknown_error',
      undefined,
      details,
      code,
      ApplicationError.Group.AXIOS
    );
  }
  private static fromAxiosError_FromResponseStatus(status: undefined | number, error: AxiosError): ApplicationError {
    // Unknown error
    // =============
    if (!status) return ApplicationError.fromAxiosError_CreateUnknownError(error.code, error.name, error.message);

    // Initialize type
    // ===============
    let type: ApplicationError.Type = ApplicationError.Type.UNKNOWN_ERROR;
    if (status >= 300) {
      if (status < 400) {
        type = ApplicationError.Type.REDIRECTION_ERROR;
      } else if (status < 500) {
        type = ApplicationError.Type.CLIENT_ERROR;
      } else if (status < 600) {
        type = ApplicationError.Type.SERVER_ERROR;
      }
    }

    // Return resulting application error
    // ==================================
    if (type === ApplicationError.Type.UNKNOWN_ERROR) {
      return ApplicationError.fromAxiosError_CreateUnknownError(
        status.toString(),
        error.name,
        error.response?.statusText || error.message
      );
    } else {
      // Initialize
      // ----------
      let messageKey = '';
      if (type === ApplicationError.Type.REDIRECTION_ERROR) {
        messageKey = 'ERROR_Redirection_error';
      } else if (type === ApplicationError.Type.CLIENT_ERROR) {
        messageKey = 'ERROR_Client_error';
      } else {
        messageKey = 'ERROR_Server_error';
      }
      let details = [`HTTP error: ${error.message} (${error.response?.statusText || status})`];
      let params: string[] | undefined = undefined;

      // Add more details from response (if available)
      // ---------------------------------------------
      if (status >= 400 && typeof error.response?.data === 'string') {
        details.push(error.response?.data);
      }

      // Refine message key for some common used statuses
      // ------------------------------------------------
      if (status === 401 || status === 407) {
        messageKey = 'ERROR_Not_authorized';
      } else if (status === 403 || status === 418 || status === 451) {
        messageKey = 'ERROR_Server_refused_your_request';
      } else if (status === 404) {
        messageKey = 'ERROR_Page_not_found';
      } else if (status === 408) {
        messageKey = 'ERROR_Request_timeout';
      } else if (status === 410) {
        messageKey = 'ERROR_Page_does_not_exist_anymore';
      } else if (status === 511) {
        messageKey = 'ERROR_Network_authentication_required';
      }

      // Return resulting application error
      // ----------------------------------
      return ApplicationError.createBase(
        type,
        messageKey,
        params,
        details,
        status.toString(),
        ApplicationError.Group.AXIOS
      );
    }
  }
}

export namespace ApplicationError {
  export enum Type {
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    CANCEL_ERROR = 'CANCEL_ERROR',
    CLIENT_ERROR = 'CLIENT_ERROR',
    CONFIG_ERROR = 'CONFIG_ERROR',
    CONNECTION_ERROR = 'CONNECTION_ERROR',
    LOGIC_ERROR = 'LOGIC_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    REDIRECTION_ERROR = 'REDIRECTION_ERROR',
    SERVER_ERROR = 'SERVER_ERROR',
    TIMEOUT_ERROR = 'TIMEOUT_ERROR',
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
  }
  export enum Origin {
    CLIENT = 'CLIENT',
    SERVER = 'SERVER'
  }
  export enum Group {
    AUTH = 'AUTH',
    AXIOS = 'AXIOS',
    BUSINESS_LOGIC = 'BUSINESS_LOGIC',
    SOCKET = 'SOCKET',
    PROGRAMMING_ERROR = 'PROGRAMMING_ERROR',
    DATA_ERROR = 'DATA_ERROR',
    UNKNOWN = 'UNKNOWN'
  }
  export type JSON = {
    type: ApplicationError.Type;
    messageKey: string;
    params?: string[];
    details?: string[];
    originalCode?: string;
    origin: ApplicationError.Origin;
    group?: ApplicationError.Group;
  };
}

export const CONTACT_SUPPORT_TEAM = 'Please contact your support team or create an error report';
export const RETRY_OR_CONTACT_SUPPORT_TEAM =
  'You can retry your action. If the issue persists, contact your support team or create an error report';
const origin = ApplicationError.Origin.CLIENT;

export default ApplicationError;

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type DispatchAddressDropdownProps = BusinessDropdownProps & {
  businessPartner: string;
};

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export const DispatchAddressDropdown = ({ businessPartner, ...props }: DispatchAddressDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: dispatchAddressOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-general',
    operationId: 'GET /businessPartners/{businessPartner}/addresses',
    labelAttribute: 'name',
    valueAttribute: 'addressNumber',
    requiredParams: {
      businessPartner,
      dispatchAddressSelect: true,
    },
    shouldPreventApiCall,
  });

  useEffect(() => {
    if (businessPartner) {
      refetch();
    }
  }, [businessPartner]);

  return <BaseDropdown {...props} loading={loading} options={dispatchAddressOptions} columnNames={COLUMN_NAMES} />;
};

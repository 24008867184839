import { Combo, Text } from '@iptor/base';
import { useOpenAPIMethod } from '@iptor/business';
import { Paper, Box, List, ListItem, ListItemText } from '@mui/material';
import { useState, useEffect } from 'react';

export const LostItemReason = (props: { onChange: (value: string) => void }) => {
  const lostSalesApi = useOpenAPIMethod('internal.v1.base-tables', 'GET /promptValues/{windowId}');
  const columnNames: string[] = ['Lost Sale reason', 'Description', 'Sales History', 'Quote History', 'Price'];
  type SalesOptionType = {
    demandUpdatePercent: string;
    description: string;
    lostSaleReasonCode: string;
    updateLostSalesHistoryForQ: string;
    updateLostSalesHistoryForS: string;
  };
  const [lostSalesOptions, setlostSalesOptions] = useState<SalesOptionType[]>([]);

  useEffect(() => {
    lostSalesApi.execute({ windowId: 'lostSaleReasons' });
  }, []);

  useEffect(() => {
    if (lostSalesApi.response?.data?.data.items) {
      setlostSalesOptions(lostSalesApi.response.data.data.items as SalesOptionType[]);
    }
  }, [lostSalesApi.response]);

  return (
    <Combo
      fullWidth
      loading={lostSalesApi.loading}
      id="lostSales"
      options={lostSalesOptions.map((option) => {
        return {
          label: option.lostSaleReasonCode,
          value: option.lostSaleReasonCode,
        };
      })}
      placeHolder="Lost Sales Reason"
      PaperComponent={({ children }) => (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} square>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ backgroundColor: 'transparent', padding: '16px 16px 0px 16px' }}
          >
            {columnNames.map((n) => (
              <Text variant="subtitle2" fontWeight={600} sx={{ width: '20%' }}>
                {n}
              </Text>
            ))}
          </Box>
          <List dense sx={{ maxHeight: '300px', overflow: 'hidden' }}>
            {children}
          </List>
        </Paper>
      )}
      renderOption={(props, option) => {
        const itemData = lostSalesOptions.find((item) => item.lostSaleReasonCode === option.value);

        return (
          itemData && (
            <ListItem {...props} sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
              <ListItemText primary={itemData.lostSaleReasonCode} sx={{ width: '20%' }} />
              <ListItemText primary={itemData.description} sx={{ width: '20%' }} />
              <ListItemText primary={itemData.updateLostSalesHistoryForS || 'N/A'} sx={{ width: '20%' }} />
              <ListItemText primary={itemData.updateLostSalesHistoryForQ || 'N/A'} sx={{ width: '20%' }} />
              <ListItemText primary={itemData.demandUpdatePercent || 'N/A'} sx={{ width: '20%' }} />
            </ListItem>
          )
        );
      }}
      onChange={(e, option) => {
        props.onChange((option as { label: string; value: string })?.value);
      }}
      ListboxProps={{ sx: { maxHeight: '35vh' } }}
    />
  );
};

import { FunctionComponent, useEffect, useState, useRef } from 'react';

export interface IframeProps {
  src: string;
  title: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export const Iframe: FunctionComponent<IframeProps> = ({
  src,
  title,
  width = '100%',
  height = '500px',
  style = {},
}) => {
  const [loading, setLoading] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const source = src;
  const anchor = 'anchor';

  const setiFrameAnchor = () => {
    if (iFrameRef.current?.getAttribute('src') !== `${source}#${anchor}`)
      iFrameRef.current?.setAttribute('src', `${source}#${anchor}`);
  };

  useEffect(() => {
    if (!loading) {
      setiFrameAnchor();
    }
  }, [anchor]);

  return (
    <iframe
      ref={iFrameRef}
      title={title}
      id={anchor}
      src={`${source}#${anchor}`}
      width={width}
      height={height}
      style={{
        height: '100%',
        zIndex: loading ? -1 : 1,
        border: '2px solid #ccc',
        borderRadius: '4px',
        ...style,
      }}
      onLoad={() => {
        if (loading) {
          setiFrameAnchor();
        }
        setLoading(false);
      }}
    />
  );
};

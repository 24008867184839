import React, { FunctionComponent, useEffect, useState } from 'react';
import { User } from '../../types/user';
import { connect } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { Widget } from '../../views/Dashboard';

import { CellPlugin } from '@react-page/editor';
import { WidgetTable } from './partials/WidgetTable';
import { Localization } from '../../framework/localization/Localization';

const LastSalesOrders: FunctionComponent<any> = (props: any) => {
  const noOfRecords = props.numberOfRecords || 10;
  const selectStatusFrom = props.selectStatusFrom || 10;
  const selectStatusTo = props.selectStatusTo || 45;
  const headers = [
    { label: 'Order no', key: 'order', width: 1 },
    { label: 'Customer', key: 'customer', width: 1 },
    { label: 'Handler', key: 'handler', width: 1 },
    { label: 'Status', key: 'orderStatus', width: 1 },
    { label: 'Type', key: 'orderType', width: 1 },
    { label: 'Order date', key: 'orderDate', width: 1 },
    { label: 'Order value', key: 'orderValue', width: 1 },
  ];
  const [response, setResponse] = useState<any>({});
  const getData = async () => {
    let payload = {
      control: {
        method: 'salesOrderHeaders.get',
        limit: noOfRecords,
        orderBy: 'orderDate DESC',
      },
      params: {
        selectHandler: props?.user?.id,
        selectStatusFrom: selectStatusFrom,
        selectStatusTo: selectStatusTo,
      },
    };
    let res = await axios
      .request({
        url: '/aperio/api/service',
        data: payload,
        method: 'POST',
      })
      .catch((err) => {
        console.error(`Error from LastSalesOrders ${err}`);
      });
    const data = {
      rows: (res as AxiosResponse)?.data?.data?.items,
    };
    setResponse(data || {});
  };

  useEffect(() => {
    getData();
  }, [props]);

  return (
    <WidgetTable
      title={props.title || Localization.instance.getString('DASHBOARD_MyRecentOpenOrders')}
      headers={headers}
      rows={response.rows}
    />
  );
};

const mapStateToProps = ({ desktop, settings }: any) => {
  let props: {
    user: User;
  } = {
    user: {
      id: desktop.settings?.regionals?.user[0].$.userId,
      username: desktop.settings?.regionals?.user[0].$.userId,
      name: desktop.settings?.fullName,
    },
  };
  return props;
};

const LastSalesOrdersComponent = connect(mapStateToProps)(LastSalesOrders);

const lastSalesOrders: CellPlugin = {
  Renderer: ({ data }: any) => {
    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <LastSalesOrdersComponent
            title={data.title}
            numberOfRecords={data.numberOfRecords}
            height={data.height}
            selectStatusFrom={data.selectStatusFrom}
            selectStatusTo={data.selectStatusTo}
          />
        </Widget>
      </div>
    );
  },
  id: 'new-sales-order-table',
  title: 'Open sales orders',
  description: 'Display list of sales order created recently',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string',
        },
        numberOfRecords: {
          type: 'string',
        },
        selectStatusFrom: {
          type: 'string',
        },
        selectStatusTo: {
          type: 'string',
        },
        height: {
          type: 'string',
        },
      },
      required: [],
    },
  },
};
export default lastSalesOrders;

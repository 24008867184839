export enum LogLevel {
  ERROR,
  WARN,
  INFO,
  DEBUG,
  TRACE
}

export enum LogAppenderType {
  CONSOLE,
  SERVER
}

export type AppenderSettings = {
  level?: keyof typeof LogLevel;
  type: keyof typeof LogAppenderType;
  name?: string;
  description?: string;
  config?: any;
};

export type LogSettings = {
  level: keyof typeof LogLevel;
  appenders: AppenderSettings[];
};

export type LogInfo = {
  logData: any;
  tags?: string[];
  meta?: Record<string, any>;
};

export interface ILoggerAppender {
  log: (logInfo: LogInfo, logLevel: LogLevel) => void;
}

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { editSalesOrderLine, getInventoryItems, getSalesOrderLines, validateInventoryItem } from './actions';
import { InventoryItem, OrderLine } from '../constants';
import { Loading } from '../../../constants';

export type EditSalesOrderItemsState = {
  loading: Loading;
  error?: SerializedError;
  orderLines: OrderLine[];
  inventoryItems: InventoryItem[];
  orderLine?: OrderLine;
};

const initialState: EditSalesOrderItemsState = {
  loading: Loading.INITIAL,
  orderLines: [],
  inventoryItems: [],
};

const editSalesOrderItemsSlice = createSlice({
  name: 'editSalesOrderItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesOrderLines.pending, (state) => {
        state.error = null;
        state.loading = Loading.PENDING;
      })
      .addCase(getSalesOrderLines.fulfilled, (state, action) => {
        state.loading = Loading.FULFILLED;
        state.orderLines = action.payload;
      })
      .addCase(getSalesOrderLines.rejected, (state, action) => {
        state.loading = Loading.REJECTED;
        state.error = action.error;
      })
      .addCase(editSalesOrderLine.pending, (state) => {
        state.error = null;
        state.loading = Loading.PENDING;
      })
      .addCase(editSalesOrderLine.fulfilled, (state, action) => {
        state.loading = Loading.FULFILLED;
        const index = state.orderLines.findIndex((orderLine) => orderLine.orderLine === action.payload.orderLine);

        if (index !== -1) {
          state.orderLines[index] = action.payload;
        }
      })
      .addCase(editSalesOrderLine.rejected, (state, action) => {
        state.loading = Loading.REJECTED;
        state.error = action.error;
      })
      .addCase(getInventoryItems.pending, (state) => {
        state.error = null;
        state.loading = Loading.PENDING;
      })
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.loading = Loading.FULFILLED;
        state.inventoryItems = action.payload;
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.loading = Loading.REJECTED;
        state.error = action.error;
      })
      .addCase(validateInventoryItem.pending, (state) => {
        state.error = null;
        state.loading = Loading.PENDING;
      })
      .addCase(validateInventoryItem.fulfilled, (state) => {
        state.loading = Loading.FULFILLED;
      })
      .addCase(validateInventoryItem.rejected, (state, action) => {
        state.loading = Loading.REJECTED;
        state.error = action.error;
      });
  },
});

export const { reducer: editSalesOrderItemsReducer } = editSalesOrderItemsSlice;

export default (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.42843 15.8308H3.1428C1.95936 15.8308 0.999992 14.8714 1 13.6879L1.00008 3.1428C1.00009 1.95936 1.95946 1 3.14289 1H12.7858C13.9692 1 14.9286 1.95937 14.9286 3.14281V6.72387M11.7144 12.6546L13.9056 10.4738M13.9056 10.4738L16 12.556M13.9056 10.4738V15.8308"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.00008 7.6972L1 6.71506C1.00001 5.53163 1.95937 4.57227 3.14281 4.57227H12.7857C13.9691 4.57227 14.9285 5.53163 14.9285 6.71507V7.25078"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      stroke="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.375 12C2.375 14.5527 3.38906 17.0009 5.1941 18.8059C6.99913 20.6109 9.44729 21.625 12 21.625C14.5527 21.625 17.0009 20.6109 18.8059 18.8059C20.6109 17.0009 21.625 14.5527 21.625 12C21.625 9.44729 20.6109 6.99913 18.8059 5.1941C17.0009 3.38906 14.5527 2.375 12 2.375C9.44729 2.375 6.99913 3.38906 5.1941 5.1941C3.38906 6.99913 2.375 9.44729 2.375 12ZM23 12C23 14.9174 21.8411 17.7153 19.7782 19.7782C17.7153 21.8411 14.9174 23 12 23C9.08262 23 6.28473 21.8411 4.22183 19.7782C2.15893 17.7153 1 14.9174 1 12C1 9.08262 2.15893 6.28473 4.22183 4.22183C6.28473 2.15893 9.08262 1 12 1C14.9174 1 17.7153 2.15893 19.7782 4.22183C21.8411 6.28473 23 9.08262 23 12ZM16.8125 11.3125C16.9948 11.3125 17.1697 11.3849 17.2986 11.5139C17.4276 11.6428 17.5 11.8177 17.5 12C17.5 12.1823 17.4276 12.3572 17.2986 12.4861C17.1697 12.6151 16.9948 12.6875 16.8125 12.6875H8.84712L11.7992 15.6383C11.8632 15.7022 11.9139 15.7781 11.9485 15.8616C11.9831 15.9451 12.0009 16.0346 12.0009 16.125C12.0009 16.2154 11.9831 16.3049 11.9485 16.3884C11.9139 16.4719 11.8632 16.5478 11.7992 16.6117C11.7353 16.6757 11.6594 16.7264 11.5759 16.761C11.4924 16.7956 11.4029 16.8134 11.3125 16.8134C11.2221 16.8134 11.1326 16.7956 11.0491 16.761C10.9656 16.7264 10.8897 16.6757 10.8258 16.6117L6.70075 12.4867C6.63673 12.4229 6.58593 12.347 6.55127 12.2635C6.51661 12.18 6.49877 12.0904 6.49877 12C6.49877 11.9096 6.51661 11.82 6.55127 11.7365C6.58593 11.653 6.63673 11.5771 6.70075 11.5133L10.8258 7.38825C10.9548 7.25916 11.1299 7.18663 11.3125 7.18663C11.4951 7.18663 11.6702 7.25916 11.7992 7.38825C11.9283 7.51734 12.0009 7.69243 12.0009 7.875C12.0009 8.05757 11.9283 8.23266 11.7992 8.36175L8.84712 11.3125H16.8125Z"
      fill="#00A3A5"
    />
  </svg>
);

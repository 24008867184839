export enum TextSize {
  XXS = 12,
  XS = 14,
  S = 18,
  M = 24,
  L = 36,
  XL = 48,
  XXL = 56,
  TABLE_FOOTER = 16,
}

export enum TextWeight {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMIBOLD = 600,
}

export enum TextStyle {
  NORMAL = 'normal',
  ITALIC = 'italic',
}

const typography = {
  fontFamily: 'Open Sans, Arial, sans-serif',
  h4: { fontSize: TextSize.S, fontWeight: TextWeight.SEMIBOLD },
  fontWeightLight: TextWeight.LIGHT,
  fontWeightRegular: TextWeight.REGULAR,
  fontWeightMedium: TextWeight.MEDIUM,
  fontWeightBold: TextWeight.SEMIBOLD,
  heading: {
    fontSize: TextSize.XXL,
    fontWeight: TextWeight.SEMIBOLD,
  },
  subheading: {
    fontSize: TextSize.XL,
    fontWeight: TextWeight.SEMIBOLD,
  },
  body: {
    fontSize: TextSize.XS,
    fontWeight: TextWeight.REGULAR,
  },
  caption: {
    fontSize: TextSize.XXS,
    fontWeight: TextWeight.REGULAR,
  },
  button: {
    fontSize: TextSize.XS,
    fontWeight: TextWeight.REGULAR,
  },
  input: {
    fontSize: TextSize.XS,
    fontWeight: TextWeight.REGULAR,
  },
  label: {
    fontSize: TextSize.XS,
  },
};

export default typography;

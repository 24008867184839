import React, { useEffect, useState } from 'react';
import './index.scss';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Widget } from '../../../../views/Dashboard';
import { Localization } from '../../../../framework/localization/Localization';
import { intervalToDuration, format, addMonths } from 'date-fns';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
  useIsAuthenticated
} from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionType,
  InteractionStatus,
  BrowserAuthError
} from '@azure/msal-browser';
import { LoginButton } from '../LoginButton/index';
import { loginRequest } from '../config';
import { CellPlugin } from '@react-page/editor';

const msAxios = axios.create({
  transformRequest: (data, headers) => {
    delete headers.common;
  }
});

const calenderEvents: CellPlugin = {
  Renderer: ({ data }: any) => {
    const today = new Date();
    const startDate = format(today, 'yyyy-MM-dd');
    const endDate = format(addMonths(new Date(today), 1), 'yyyy-MM-dd');
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [graphData, setGraphData] = useState<any>({ items: [] });
    const { instance, accounts, inProgress } = useMsal();
    const [showError, setShowError] = useState<boolean>(false);
    const [url, setUrl] = useState<any>();

    useEffect(() => {
      const getEndpoint = async () => {
        await axios
          .get('/msgraphEndpoint')
          .then((res) => {
            setUrl(res?.data || {});
          })
          .catch((err) => {
            console.error(`Error from calenderEvents step1${err}`);
          });
      };
      getEndpoint();
    }, []);

    useEffect(() => {
      if (inProgress === InteractionStatus.None && url !== undefined) {
        if (graphData.items.length !== 0) {
          return;
        }
        const request = {
          ...loginRequest,
          account: accounts[0]
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            getData(response.accessToken);
          })
          .catch((error) => {
            console.error(`Error from calenderEvents step2 ${error}`);
          });
      }
    }, [instance, accounts, inProgress, url]);

    const handleLogin = () => {
      instance.acquireTokenPopup(loginRequest).catch((error) => {
        setShowError(!showError);
      });
    };

    const getData = (token: string) => {
      msAxios
        .get(
          `${url?.graphEndpoint}/calendarview?startdatetime=${startDate}T00:00:00.000Z&enddatetime=${endDate}T23:59:00.000Z&top=5&orderby=Start%2FDateTime+asc`,
          {
            transformRequest: (data, headers) => {
              if (headers.common) {
                headers.common['Authorization'] = `Bearer ${token}`;
                headers.common['Prefer'] = `outlook.timezone="${currentTimeZone}"`;
              }
              return data;
            }
          }
        )
        .then((data) => {
          setGraphData({ items: data?.data?.value || [] });
        })
        .catch((err) => {
          console.error(`Error from calenderEvents step3 ${err}`);
        });
    };

    const getDuration = (start: any, end: any) => {
      const startTime = new Date(start).valueOf();
      const endTime = new Date(end).valueOf();
      const duration = intervalToDuration({ start: startTime, end: endTime });
      if (duration.days !== 0) {
        return `${duration.days} day`;
      } else if (duration.hours !== 0 && duration.minutes !== 0) {
        return `${duration.hours} hr ${duration.minutes} min`;
      } else if (duration.hours !== 0) {
        return `${duration.hours} hr`;
      } else {
        return `${duration.minutes} min`;
      }
    };

    return (
      <div className={'card calender-events-wrapper'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <Row>
            <Col sm={12}>
              <p>
                <strong>{data.title || 'Events'}</strong>
              </p>
            </Col>
          </Row>
          <UnauthenticatedTemplate>
            <LoginButton
              name={Localization.instance.getString('Login_ok')}
              action={handleLogin}
              message={Localization.instance.getString('LOGIN_MS_WIDGET_LOGIN_MSG')}
            />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {graphData.items?.length > 0 ? (
              <div className='events-list'>
                {graphData.items?.map((item: Record<string, any>) => (
                  <Row style={{ marginTop: '10px' }}>
                    <Col>
                      <div>
                        <strong>{format(new Date(item.start.dateTime), 'dd-MM-yyyy, p')}</strong>
                        <a style={{ color: 'inherit' }} href={item.webLink} target='_blank' rel='noreferrer'>
                          <div className='list-item'>
                            <div className='indicator'></div>
                            <div>
                              <div className='subject-name'>{item.subject}</div>
                              <div className='duration'>{getDuration(item.start.dateTime, item.end.dateTime)}</div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : (
              <div className='no-data-text'>
                {showError ? (
                  <strong>{Localization.instance.getString('DASHBOARD_SomethingWentWrong')}</strong>
                ) : (
                  <strong>{Localization.instance.getString('DASHBOARD_NoData')}</strong>
                )}
              </div>
            )}
          </AuthenticatedTemplate>
        </Widget>
      </div>
    );
  },
  id: 'Events',
  title: 'Events',
  description: 'Events from microsoft outlook account',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default calenderEvents;

export function getOptionsWithLabelValuePair({
  list,
  valueAttribute,
  labelAttribute,
  labels,
}: {
  list: Record<string, any>[];
  valueAttribute: string;
  labelAttribute?: string;
  labels?: string[];
}) {
  const options = list?.map((item) => ({
    label:
      labels && labels.length ? labels.map((label) => item?.[label] ?? '').join(' ') : (item?.[labelAttribute] ?? ''),
    value: item?.[valueAttribute],
  }));
  return options;
}

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { ApplicationContext, Button, useCustomSnackbar, useIptorTranslation, Icon, Text, DataCard } from '@iptor/base';
import { openAPIManager, OperationResponseItems } from '@iptor/business';
import { LostItemReason } from '../DeleteOrders/components/LostSalesReason';

type SalesOrderLine = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines'>[0];

interface DeleteOrderLinesDialogProps {
  salesOrderLines: SalesOrderLine[];
  show: boolean;
  handleClose: (canceled: boolean) => void;
}

function isDeleteAllowed(salesOrderLine: SalesOrderLine) {
  return salesOrderLine.orderLineStatus < 30;
}

function DeleteOrderLinesDialog(props: DeleteOrderLinesDialogProps) {
  const { t } = useIptorTranslation();
  const { startLoading, endLoading } = useContext(ApplicationContext);
  const { showSnackbar } = useCustomSnackbar();
  const [salesOrderLines, setSalesOrderLines] = useState(props.salesOrderLines);
  const [lostSaleReason, setLostSaleReason] = useState('');

  useEffect(() => {
    salesOrderLines.length === 0 && props.handleClose(true);
  }, [salesOrderLines, props.handleClose]);

  const invalidSalesOrderLines = useMemo(() => {
    return salesOrderLines.filter((line) => !isDeleteAllowed(line));
  }, [salesOrderLines]);

  const SubText = useCallback(() => {
    if (invalidSalesOrderLines.length > 0) {
      if (invalidSalesOrderLines.length === salesOrderLines.length) {
        return (
          <>
            <b>
              {t('sales_orders:None_Of_Selected_Item_Lines_Can_Be_Deleted_Because_They_Have_Status_Greater_Than_20')}{' '}
              {t('common:Please_Do_Another_selection')}
            </b>
          </>
        );
      } else {
        return (
          <>
            {t('sales_orders:{number}_Item_Lines_Cannot_Be_Deleted_Because_They_Have_Status_Greater_Than_20', '', {
              number: invalidSalesOrderLines.length,
            })}{' '}
            <b> {t('common:Please_Remove_Them_From_The_List_To_Proceed')} </b>
          </>
        );
      }
    }
    if (salesOrderLines.length === 1) {
      return (
        <>
          {t('sales_orders:Line_Item_{item}_Will_Be_Deleted', '', { item: salesOrderLines[0].item })}{' '}
          <b>{t('common:TXT_Want_To_Proceed')}</b>
        </>
      );
    } else {
      return (
        <>
          {t('sales_orders:{number}_Line_Items_Will_Be_Deleted', '', { number: salesOrderLines.length })}{' '}
          <b>{t('common:TXT_Want_To_Proceed')}</b>
        </>
      );
    }
  }, [salesOrderLines, invalidSalesOrderLines]);

  const handleDelete = async () => {
    startLoading?.();
    try {
      const result = await openAPIManager.executeAllSettled<
        'internal.v1.base-orders',
        'DELETE /salesOrders/{order}/lines/{orderLine}',
        SalesOrderLine
      >(
        'internal.v1.base-orders',
        'DELETE /salesOrders/{order}/lines/{orderLine}',
        salesOrderLines.map((salesOrderLine) => ({
          params: { order: salesOrderLine.order, orderLine: salesOrderLine.orderLine, lostSaleReason: lostSaleReason },
          additionalInfo: salesOrderLine,
        })),
      );

      result.getRejected().forEach((info) => {
        const row = info.args.additionalInfo;
        showSnackbar({
          message:
            t('sales_orders:Deleting_order_{order}_line_{line}_item_{item}_failed:', '', {
              order: row.order,
              line: row.orderLine,
              item: row.item,
            }) +
            '\n' +
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((info.error as any).response?.data?.messages?.map((m: any) => m.text).join('\n') ||
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (info.error as any).response?.data?.error?.message ||
              ''),
          variant: 'error',
          autoHide: false,
        });
      });

      if (result.someFullfilled) {
        if (salesOrderLines.length === 1) {
          showSnackbar({
            message: t('sales_orders:Order_{order}_line_{line}_item_{item}_deleted_successfully.', '', {
              order: salesOrderLines[0].order,
              line: salesOrderLines[0].orderLine,
              item: salesOrderLines[0].item,
            }),
            variant: 'success',
          });
        } else {
          showSnackbar({
            message: t('sales_orders:{number}_order_item_lines_deleted_successfully.', '', {
              number: result.getFullfilled().length,
            }),
            variant: 'success',
          });
        }
      }
      props.handleClose(false);
    } finally {
      endLoading?.();
    }
  };

  return (
    <Dialog open={props.show} maxWidth="sm" fullWidth>
      <DialogTitle alignItems="center" display="flex" gap={1} sx={(theme) => ({ paddingY: theme.spacing(2) })}>
        <Icon icon="delete3" color="error.main" size="L" />
        <Text variant="h6" fontWeight={600}>
          {t('common:TXT_Delete')}
        </Text>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Icon icon="cancel01" onClick={() => props.handleClose(true)} />
        </Box>
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text variant="subtitle1" paddingBottom={1}>
          <SubText />
        </Text>
        <Stack direction="row" width={'100%'} gap={0.5} overflow="auto" paddingY={1} marginY={1} paddingX={0.25}>
          {salesOrderLines
            .sort((a, b) => {
              const aIsDeleteAllowed = isDeleteAllowed(a) ? 0 : 1;
              const bIsDeleteAllowed = isDeleteAllowed(b) ? 0 : 1;
              return bIsDeleteAllowed - aIsDeleteAllowed;
            })
            .map((line) => (
              <DataCard
                title={t('common:Item')}
                firstVal={line.description || t('common:No_Data')}
                secondVal={line.item}
                subTitle={t('common:Order_Line')}
                thirdVal={line.orderLine}
                error={invalidSalesOrderLines.some(
                  (cur) => cur.order === line.order && cur.orderLine === line.orderLine,
                )}
                onClose={() =>
                  setSalesOrderLines((state) => {
                    return state.filter((cur) => !(cur.order === line.order && cur.orderLine === line.orderLine));
                  })
                }
              />
            ))}
        </Stack>
        <LostItemReason onChange={(value: string) => setLostSaleReason(value)} />
      </DialogContent>
      <DialogActions>
        <Button id="cancel" variant="outlined" onClick={() => props.handleClose(true)}>
          {t('common:TXT_Cancel')}
        </Button>
        <Button
          autoFocus
          disabled={!lostSaleReason || invalidSalesOrderLines.length > 0}
          variant="contained"
          id="delete"
          onClick={handleDelete}
        >
          {t('common:TXT_Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteOrderLinesDialog;

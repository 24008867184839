export const fieldConfig = {
  C: [
    'addressNumber',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'postalCode',
    'country',
    'county',
    'state',
    'name',
  ],
  S: [
    'addressNumber',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'postalCode',
    'country',
    'county',
    'state',
    'name',
    'shipPartner',
    'route',
    'departure',
    'shippingAgent',
    'destination',
  ],
  D: [
    'addressNumber',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'postalCode',
    'country',
    'county',
    'state',
    'name',
    'route',
    'departure',
    'shippingAgent',
    'destination',
    'sourcingPolicy',
    'addressCategory',
  ],
  I: [
    'addressNumber',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'postalCode',
    'country',
    'county',
    'state',
    'name',
    'invoiceCustomer',
  ],
  P: [
    'addressNumber',
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'addressLine4',
    'postalCode',
    'country',
    'county',
    'state',
    'name',
    'debtor',
    'vatRegistrationNo',
  ],
};

import React, { FunctionComponent, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './../styles/SettingsCombo.scss';
import { ReactComponent as DropdownArrow } from './../assets/dropdown-arrow.svg';
import { Localization } from '../framework/localization/Localization';

export type Option = { label: string; value: string; language?: string };

type SettingsComboProps = {
  className?: string;
  defaultValue: Option;
  disabled?: boolean;
  options?: Option[];
  translateOptionLabels: boolean;
  readOnly?: boolean;
  isInvalid?: boolean;
  onChangeOption?: (option: Option) => void;
};

export const SettingsCombo: FunctionComponent<SettingsComboProps> = ({
  className,
  options = [],
  translateOptionLabels,
  defaultValue,
  onChangeOption,
  disabled = false,
  isInvalid = false
}) => {
  // const [selectedOption, setSelectedOption] = useState<Option>(defaultValue ? defaultValue : { label: '', value: '' });
  const ref = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  if (!defaultValue.label) {
    defaultValue.label = options.find((option) => option.value === defaultValue.value)?.label || '';
  }
  const defaultValueLabel = translateOptionLabels
    ? Localization.instance.getString(defaultValue.label)
    : defaultValue.label;
  const tempDefaultInfo = Localization.Maintenance.getDefaultValueBasedOnLanguage(
    Localization.Maintenance.getLocaleOptionValueFromCurrentLocale(),
    Localization.instance
  );
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          ref={ref}
          autoFocus={isInvalid}
          aria-disabled={disabled}
          className={`combo-text-style combo-text-font dropdown-box full-width ${className}}`}
          onKeyDown={(e: React.KeyboardEvent) => {
            if(e.key.toLowerCase() === 'escape' && menuRef.current?.classList.contains('show')) {
              e.stopPropagation();
            }
          }}
        >
          {defaultValueLabel !== '' ? (
            <span aria-disabled={disabled} className={'selected-item'}>
              {defaultValueLabel}
            </span>
          ) : (
            <span className={'combo-placeholder-shown'}>{''}</span>
          )}
          <DropdownArrow className={'combo-icon-image'} aria-disabled={disabled} />
        </Dropdown.Toggle>
        {!disabled && (
          <Dropdown.Menu onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key.toLowerCase() === 'escape') {
              e.stopPropagation();
            }
          }} ref={menuRef} className={`full-width dropdown-list ${className}`}>
            {options.map((option, index) => (
              <Dropdown.Item
                data-event='ignore'
                className={'dropdown-list-item'}
                key={`${option.label}-${index + 1}`}
                onClick={() => {
                  // setSelectedOption(option);
                  onChangeOption?.(option);
                }}
                value={option.value}
              >
                <span>
                  {(translateOptionLabels ? Localization.instance.getString(option.label) : option.label) +
                    (option.value === tempDefaultInfo.dateFormat ? '   (' + tempDefaultInfo.locale + ')' : '')}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

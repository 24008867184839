import { forwardRef } from 'react';
import { FileDropZone, FileDropZoneProps, FileType } from '../FileDropZone'; // Reuse the existing FileDropZone
import { Workbook } from 'exceljs';
import { useIptorTranslation } from 'src/framework/translations';
import { useCustomSnackbar } from '../NotificationSnackbar';

export interface SheetDropZoneProps extends Omit<FileDropZoneProps, 'onFileListChange' | 'fileTypes'> {
  onSheetDataChange?: (data: { fileName: string; rows: Array<Record<string, any>> }[]) => void; // Callback to send processed data
}

export const SheetDropZone = forwardRef<
  {
    removeFile: (fileName: string) => void;
  },
  SheetDropZoneProps
>(({ onSheetDataChange, ...restProps }) => {
  // File change handler that processes the files into an array of objects with file name and rows
  const { t } = useIptorTranslation();
  const { showSnackbar } = useCustomSnackbar();

  const handleFileListChange = (files: File[]) => {
    const processedFilesData: { fileName: string; rows: Array<Record<string, any>> }[] = [];
    let processedFiles = 0;

    if (files.length === 0) {
      onSheetDataChange([]);
    }
    files.forEach((file) => {
      const fileType = file.name.split('.').pop()?.toLowerCase();
      if (fileType === 'csv') {
        processCSVFile(file, (fileData) => {
          processedFilesData.push(fileData);
          processedFiles++;
          if (processedFiles === files.length) {
            // All files processed
            if (onSheetDataChange) {
              onSheetDataChange(processedFilesData); // Send combined data of all files
            }
          }
        });
      } else if (fileType === 'xlsx') {
        processXLSXFile(file, (fileData) => {
          processedFilesData.push(fileData);
          processedFiles++;
          if (processedFiles === files.length) {
            // All files processed
            if (onSheetDataChange) {
              onSheetDataChange(processedFilesData); // Send combined data of all files
            }
          }
        });
      }
    });
  };

  // Process CSV file and return file name with its rows
  const processCSVFile = (
    file: File,
    callback: (data: { fileName: string; rows: Array<Record<string, any>> }) => void,
  ) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const text = event.target?.result as string;
      const rows = text.split('\n').map((row) => row.trim().split(','));
      const headers = rows[0];
      const data = rows.slice(1).map((row) => {
        return headers.reduce(
          (acc, header, index) => {
            acc[header] = row[index];
            return acc;
          },
          {} as Record<string, any>,
        );
      });

      // Pass the processed CSV file's data and name to the callback
      callback({ fileName: file.name, rows: data });
    };
    reader.readAsText(file);
  };

  // Process XLSX file and return file name with its rows
  const processXLSXFile = (
    file: File,
    callback: (data: { fileName: string; rows: Array<Record<string, any>> }) => void,
  ) => {
    const reader = new FileReader();
    reader.onload = async function (event) {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const workbook = new Workbook();
      try {
        await workbook.xlsx.load(arrayBuffer);
      } catch (error) {
        showSnackbar({ variant: 'warning', message: t('common:TABLE_IMPORT_INVALID_FILE') });
        return;
      }
      const worksheet = workbook.worksheets[0];

      // Get the headers from the first row (index 1 is the first row in ExcelJS)
      const headerRow = worksheet.getRow(1); // Access first row
      const headers: string[] = [];
      headerRow.eachCell((cell) => {
        return headers.push(cell.text || cell.value.toString());
      });

      // Extract data rows
      const jsonData: Record<string, any>[] = [];
      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        // Skip header row
        if (rowNumber > 1) {
          const rowData: Record<string, any> = {};
          row.eachCell((cell, colNumber) => {
            rowData[headers[colNumber - 1]] = cell.text || cell.value; // Map cell to corresponding header
          });
          jsonData.push(rowData);
        }
      });

      // Filter out empty rows where all values are either undefined, null, or empty strings
      const filteredData = jsonData.filter((row) =>
        Object.values(row).some((value) => value !== undefined && value !== null && value !== ''),
      );

      // Pass the processed XLSX file's data and name to the callback
      callback({ fileName: file.name, rows: filteredData });
    };
    reader.readAsArrayBuffer(file);
  };

  // Use FileDropZone with custom file list change handler
  return (
    <FileDropZone
      {...restProps}
      fileTypes={[FileType.CSV, FileType.XMLX]} // Allow only CSV and XLSX
      onFileListChange={handleFileListChange} // Handle the file processing here
    />
  );
});

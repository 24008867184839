import { SOEApplication } from '@iptor/sales-order-entry';
import { WindowManger } from 'framework/base/windowManager';
import { Localization } from 'framework/localization/Localization';
import { createElement, useCallback, useContext, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LocaleContext } from '../../App';
import { desktopState } from '../../framework/base/desktopReducer';
import { TooltipContext } from '../../helpers/TooltipContext';
import '../../styles/Dashboard.scss';
import '../../styles/spotlightArea.scss';
import { WindowProps } from './Window';
import { IdTokenClaims } from 'oidc-client-ts';
import { ClientSettingsProvider } from '@iptor/base';
import { useOpenAPIData } from '@iptor/business';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import '../../styles/ColumnVisibilityTable.scss';
import '../../styles/ColumnVisibility.scss';
// import '@api/framework/dist/index.css';

export type ApplicationWindowProps = WindowProps & {
  quicklinks?: any;
  flattenedMenu?: any;
  getMenuItems?: Function;
  handleShowMenu: (menuId: string) => void;
  mainMenu: any;
  handleShowFavourites: () => void;
  desktopState?: desktopState;
  tooltipContext?: any;
  localeContext?: any;
  handleSettingsShow: Function;
  handleRoleShow: Function;
  auth?: any;
};

const applicationForms: Record<string, any> = {
  '': () => <div>No Application Found</div>,
  SalesOrderEntry: SOEApplication,
};

export const ApplicationWindowComponent = (props: ApplicationWindowProps) => {
  const applicationComponent = useMemo(
    () => (props.application?.startsWith('remote://') ? props.application : props.application),
    [props.application],
  );

  const tooltipContext = useContext(TooltipContext);
  const localeContext = useContext(LocaleContext);
  const companyData = useOpenAPIData('internal.v1.base-general', 'GET /companies/current');
  const userData = useOpenAPIData('internal.v1.base-tables', 'GET /DC1Users/current');
  const onRefresh = useCallback(() => {
    companyData?.refresh();
    userData?.refresh();
  }, [companyData]);

  return (
    <ClientSettingsProvider
      value={{
        ...localeContext.localeSettings,
        currency:
          (companyData?.response?.data?.data?.systemCurrencyISO || companyData?.response?.data?.data?.systemCurrency) ??
          '',
        reportingCurrency:
          (companyData?.response?.data?.data?.reportingCurrencyISO ||
            companyData?.response?.data?.data?.reportingCurrency) ??
          '',
        handler: userData?.response?.data?.data?.handler ?? '',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container fluid className="h-full full-width no-pad no-mar application-window">
          {createElement(applicationForms[applicationComponent ?? ''] ?? (() => <div>Component not found</div>), {
            tooltipContext,
            localeContext,
            onRefresh,
            ...props,
          })}
        </Container>
      </LocalizationProvider>
    </ClientSettingsProvider>
  );
};

export const ApplicationWindow = connect(
  (state: any) => {
    return {
      quicklinks: state.settings.quickLinks,
      desktopState: state.desktop,
      dateFormat: Localization.instance.dateFormat,
    };
  },
  {
    CloseWindow: (windowId: string, endLoading: Function) => WindowManger.CloseWindow(windowId, endLoading),
    quickLaunch: WindowManger.Launch,
  },
)(ApplicationWindowComponent);

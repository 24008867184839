import { openAPIManager, OperationResponseItems } from '@iptor/business';
import { DialogTitle, DialogContent, DialogActions, Paper, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { formatErrorText } from '../../../utils/formatError';
import { Button, Icon, Text, useIptorTranslation } from '@iptor/base';

interface DeleteTextVersionProps {
  text: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0];
  onClose: () => void;
  refresh: () => void;
  orderLine?: number;
  setNeedsTableRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteTextVersion: React.FC<DeleteTextVersionProps> = ({
  text,
  onClose,
  refresh,
  orderLine,
  setNeedsTableRefresh,
}) => {
  const { notify } = useOrderNotifications();
  const { t } = useIptorTranslation();
  const [loading, setLoading] = useState(false);

  const deleteOrderText = () => {
    setLoading(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        orderLine
          ? 'DELETE /salesOrders/{order}/lines/{orderLine}/texts'
          : 'DELETE /salesOrders/{order}/texts/{version}',
        {
          order: text.order,
          version: text.version,
          orderLine,
          language: text.language,
        },
      )
      .then(() => {
        onClose();
        notify('success', t('sales_orders:ORDERTEXT_deletedSuccessfully'));
        refresh();
      })
      .catch((error) => {
        notify('error', t('sales_orders:ORDERTEXT_failedDelete') + formatErrorText(error));
      })
      .finally(() => {
        setLoading(false);
        setNeedsTableRefresh(true);
      });
  };

  return (
    <Paper>
      <DialogTitle display="flex" alignItems="center" gap={1}>
        <Icon icon="delete3" color="error" /> {t('sales_orders:ORDERTEXT_deleteTitle')}
        <Icon
          icon="cancel01"
          onClick={onClose}
          style={{ marginLeft: 'auto' }}
          sx={(theme) => ({
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          })}
        />
      </DialogTitle>
      <Divider
        sx={(theme) => ({
          opacity: 0.6,
          borderWidth: '1px',
          marginX: theme.spacing(3),
        })}
      />
      <DialogContent>
        <Text>
          {t('sales_orders:ORDERTEXT_confirmDelete_{version}_{language}', '', {
            version: text.version,
            language: text.language,
          })}
        </Text>
      </DialogContent>
      <DialogActions>
        <Button id="cancel" onClick={onClose}>
          {t('common:TXT_Cancel')}
        </Button>
        <Button id="delete" onClick={deleteOrderText} variant="contained" loading={loading}>
          {t('common:TXT_Delete')}
        </Button>
      </DialogActions>
    </Paper>
  );
};

export default DeleteTextVersion;

import { DialogLayout, Button, IconButton, TableFilter, useIptorTranslation, useClientSettings } from '@iptor/base';
import { IptorTable, OperationResponseItems } from '@iptor/business';
import DeleteDiscount from '../DeleteDiscount';
import { useState } from 'react';
import { Link } from '@mui/material';

type DiscountType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/discounts'>[0];

interface Props {
  orderId: number;
  orderLine?: number;
  currency: string;
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  handleEdit: (value: DiscountType) => void;
  handleNew: () => void;
}

function DiscountList({ handleClose, open, orderId, handleEdit, handleNew, orderLine, currency }: Props) {
  const [discountToDelete, setDiscountToDelete] = useState(undefined);
  const { t } = useIptorTranslation();
  const { formatNumber, formatCurrency } = useClientSettings();

  return (
    <>
      {discountToDelete && (
        <DeleteDiscount
          discountToDelete={discountToDelete}
          open={!!discountToDelete}
          handleClose={() => {
            setDiscountToDelete(undefined);
          }}
          currency={currency}
        />
      )}
      <DialogLayout
        title={t('sales_orders:DISCOUNT_listTitle')}
        actions={[
          <Button variant="outlined" size="medium" onClick={() => handleClose(false)}>
            {t('common:TXT_Close')}
          </Button>,
        ]}
        open={open}
        onClose={() => handleClose(false)}
      >
        <IptorTable
          tableID={`discountList-${orderId}${orderLine ? '-' : ''}${orderLine ?? ''}`}
          apiId="internal.v1.base-orders"
          apiEndpoint={
            orderLine ? 'GET /salesOrders/{order}/lines/{orderLine}/discounts' : 'GET /salesOrders/{order}/discounts'
          }
          columns={[
            {
              display: t('sales_orders:DISCOUNT_id'),
              key: 'discountId',
              cellRenderer: ({ value, row }) => (
                <Link
                  sx={{ fontWeight: '600' }}
                  id="discountId"
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  {value}
                </Link>
              ),
            },
            { display: t('sales_orders:DISCOUNT_id_description'), key: 'discountIdDescription' },
            { display: t('sales_orders:DISCOUNT_type'), key: 'discountType' },
            { display: t('sales_orders:DISCOUNT_type_description'), key: 'discountTypeDescription' },
            {
              display: t('sales_orders:DISCOUNT_percentage'),
              key: 'discountPercentage',
              cellRenderer: ({ value }) => {
                return <>{value ? formatNumber({ number: value }) : ''}</>;
              },
            },
            ...(orderLine
              ? [
                  {
                    display: t('sales_orders:DISCOUNT_amount'),
                    key: 'discountAmountPerUnit',
                    cellRenderer: ({ value, row }: any) => (
                      <>{row.discountPercentage ? '' : value ? formatCurrency({ amount: value, currency }) : ''}</>
                    ),
                  },
                ]
              : []),
            {
              display: '',
              key: 'actions',
              disableFilter: true,
              disableSort: true,
              cellRenderer: ({ row }) => (
                <>
                  <IconButton
                    variant={'text'}
                    color="primary"
                    id="delete"
                    icon="delete3"
                    onClick={() => setDiscountToDelete(row)}
                  />
                </>
              ),
            },
          ]}
          apiParams={{ order: orderId, orderLine }}
          additionalFilters={<></>}
          additionalActions={
            <>
              <TableFilter />
              <IconButton
                icon="plusSign"
                variant="contained"
                color="secondary"
                id="addNew"
                onClick={() => {
                  handleNew();
                }}
              ></IconButton>
            </>
          }
        />
      </DialogLayout>
    </>
  );
}

export default DiscountList;

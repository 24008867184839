import { TableFilter, TableSettings, Text, useIptorTranslation } from '@iptor/base';
import { IptorTable, openAPIManager, OperationResponseItems } from '@iptor/business';
import { Divider, Stack } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import OrderStatus from '../../../components/OrderStatus';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';

interface Props {
  currentOrder: number;
  setSelectedOrderId: (val: number) => void;
  handleClose: () => void;
  setDisabled: (value: boolean) => void;
}

type OrderType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders'>[0];

const PastOrdersTable = forwardRef((props: Props, ref) => {
  const [selectedRows, setSelectedRows] = useState<OrderType[]>([]);
  const { t } = useIptorTranslation();
  const { notify } = useOrderNotifications();

  useImperativeHandle(ref, () => ({
    handleAddOrders,
  }));

  useEffect(() => {
    props.setDisabled(selectedRows.length === 0);
  }, [selectedRows]);

  const handleAddOrders = async () => {
    for (const row of selectedRows) {
      await openAPIManager
        .execute('internal.v1.base-orders', 'GET /salesOrders/{order}/lines', {
          order: row.order,
          status: '',
        })
        .then(async (res) => {
          if (!res.data.data.items) {
            notify('warning', t('sales_orders:PASTORDERS_noLinesFound', '', { order: row.order }));
            return;
          }
          const postRequests = res.data.data.items.map((orderLine) => {
            const { item, orderQuantitySalesUnit, unit, warehouse } = orderLine;

            return openAPIManager.execute(
              'internal.v1.base-orders',
              'POST /salesOrders/{order}/lines',
              { order: props.currentOrder },
              { item, orderQuantitySalesUnit, unit, warehouse },
            );
          });

          const results = await Promise.allSettled(postRequests);

          const failedRequests = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[];

          if (failedRequests.length > 0) {
            failedRequests.map((error) =>
              notify(
                'error',
                (error.reason as any)?.response?.data?.messages,
                false,
                t('sales_orders:PASTORDERS_failedToCopyLines', '', { order: row.order }),
              ),
            );
          } else {
            notify('success', t('sales_orders:PASTORDERS_linesCopied', '', { order: row.order }));
          }
        })
        .catch((error) => {
          notify('error', error, false, t('sales_orders:PASTORDERS_failedToFetchLines', '', { order: row.order }));
        });
    }

    props.handleClose();
  };

  return (
    <IptorTable
      tableID="pastOrdersTable"
      apiId="internal.v1.base-orders"
      apiEndpoint="GET /salesOrders"
      selectable="multiple"
      additionalFilters={
        <Stack gap={2} direction="row" alignSelf="center" height="max-content">
          <Text>{t('sales_orders:PASTORDERS_ordersSelected')}</Text>
          <Text fontWeight={600}> {selectedRows.length}</Text>
          <Divider orientation="vertical" flexItem={true} />
          <Text>{t('sales_orders:PASTORDERS_itemsSelected')}</Text>
          <Text fontWeight={600}>{selectedRows.reduce((acc, { itemsNumber }) => acc + itemsNumber, 0)}</Text>
        </Stack>
      }
      additionalActions={
        <>
          <TableFilter />
          <TableSettings />
        </>
      }
      columns={[
        {
          display: t('sales_orders:TABLE_COLUMN_order'),
          key: 'order',
          dataType: 'number',
          cellRenderer: ({ value }) => (
            <Text
              sx={{ fontWeight: '600', cursor: 'pointer' }}
              onClick={() => {
                props.setSelectedOrderId(value);
              }}
              id="order"
              color="info"
            >
              {value}
            </Text>
          ),
        },
        {
          display: t('sales_orders:TABLE_COLUMN_handler'),
          key: 'handler',
          cellRenderer: ({ value }) => <Text id="handler">{value}</Text>,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_itemsNumber'),
          key: 'itemsNumber',
          dataType: 'number',
          cellRenderer: ({ value }) => <Text>{value}</Text>,
          disableFilter: true,
          disableSort: true,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_orderType'),
          key: 'orderType',
          cellRenderer: ({ value }) => <Text id="orderType">{value}</Text>,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_orderValue'),
          key: 'orderValue',
          dataType: 'number',
          cellRenderer: ({ value }) => <Text id="orderValue">{value}</Text>,
        },
        {
          display: t('sales_orders:TABLE_COLUMN_orderDate'),
          key: 'orderDate',
          cellRenderer: ({ value }) => <Text>{value}</Text>,
          dataType: 'date',
        },
        {
          display: t('sales_orders:TABLE_COLUMN_dispatchTime'),
          key: 'dispatchTime',
          cellRenderer: ({ value }) => <Text>{value}</Text>,
          dataType: 'date',
        },
        {
          display: t('sales_orders:TABLE_COLUMN_orderStatus'),
          key: 'orderStatus',
          dataType: 'number',
          cellRenderer: ({ value }) => <OrderStatus orderStatus={value} />,
        },
      ]}
      onSelectedRowsChange={({ rows }) => setSelectedRows(rows)}
    />
  );
});

export default PastOrdersTable;

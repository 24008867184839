import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DialogTitle, DialogContent, DialogActions, Grid2 as Grid, Box } from '@mui/material';
import { Button, NumericField, TextField, useIptorTranslation } from '@iptor/base';
import { DocumentsDropdown, LanguagesDropdown, openAPIManager, OperationResponseItems } from '@iptor/business';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { formatErrorText } from '../../../utils/formatError';

interface FormData {
  version?: number;
  language?: string;
  longText?: string;
  documents?: document[];
}

type document = {
  documentID?: string;
  description?: string;
  shortDescription?: string;
  active?: boolean;
};

export const handleApiTextMessageToNormal = (message: string) => {
  let result = '';
  let lineCounter = 1;

  for (let i = 0; i < message.length; i++) {
    const char = message[i];

    if (char === '\n' && lineCounter > 70 && lineCounter % 70 === 1) {
      lineCounter--;
    } else {
      result += char;
    }
    lineCounter++;
  }

  return result;
};

const AddEditTextView: React.FC<{
  setIsEditView: (isEditView: boolean) => void;
  initialData?: FormData;
  orderId: number;
  refresh: () => void;
  orderLine?: number;
  textExists?: boolean;
  setNeedsTableRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  defaultLanguage?: string;
  setTextToEdit: (text: OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/texts'>[0]) => void;
}> = ({
  setIsEditView,
  initialData,
  orderId,
  refresh,
  orderLine,
  textExists,
  setNeedsTableRefresh,
  defaultLanguage,
  setTextToEdit,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>({
    defaultValues: initialData
      ? undefined
      : textExists
        ? { language: defaultLanguage }
        : { version: 1, language: defaultLanguage },
  });
  const { notify } = useOrderNotifications();
  const [loading, setLoading] = useState(false);

  const { t } = useIptorTranslation();

  useEffect(() => {
    if (initialData) {
      setValue('version', initialData.version);
      setValue('language', initialData.language);
      setValue('longText', handleApiTextMessageToNormal(initialData.longText));
      setValue('documents', initialData.documents);
    }
  }, [initialData, setValue]);

  const onSubmit = (data: FormData) => {
    initialData?.version ? updateOrderText(data) : createOrderText(data);
  };

  const getDocumentToPost = (initialDocuments: document[], currentActiveDocuments: document[]) => {
    return currentActiveDocuments
      .filter((d) => d.active)
      .map((d) => ({
        documentID: d.documentID,
        active: true,
      }))
      .concat(
        initialDocuments
          .filter((doc) => doc.active && !currentActiveDocuments.some((d) => d.documentID === doc.documentID))
          .map((doc) => ({
            documentID: doc.documentID,
            active: false,
          })),
      );
  };

  const updateOrderText = async (data: FormData) => {
    const documents = getDocumentToPost(initialData?.documents?.filter((d) => d.active) || [], data.documents || []);
    setLoading(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        orderLine
          ? 'PUT /salesOrders/{order}/lines/{orderLine}/texts/{version}'
          : 'PUT /salesOrders/{order}/texts/{version}',
        { order: orderId, version: initialData?.version, orderLine },
        {
          language: initialData?.language,
          longText: data.longText,
          documents: documents,
        },
      )
      .then(() => {
        setIsEditView(false);
        setTextToEdit(null);
        refresh();
        notify('success', t('sales_orders:ORDERTEXT_savedSuccessfully'));
      })
      .catch((error) => {
        notify('error', formatErrorText(error), undefined, t('sales_orders:ORDERTEXT_failedSave'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createOrderText = async (data: FormData) => {
    setLoading(true);
    openAPIManager
      .execute(
        'internal.v1.base-orders',
        orderLine ? 'POST /salesOrders/{order}/lines/{orderLine}/texts' : 'POST /salesOrders/{order}/texts',
        { order: orderId, orderLine },
        {
          version: data.version,
          language: data.language,
          longText: data.longText,
          documents: data.documents?.map((doc) => ({
            documentID: doc.documentID,
            active: true,
          })),
        },
      )
      .then(() => {
        setIsEditView(false);
        setTextToEdit(null);
        refresh();
        notify('success', t('sales_orders:ORDERTEXT_savedSuccessfully'));
      })
      .catch((error) => {
        notify('error', formatErrorText(error), undefined, t('sales_orders:ORDERTEXT_failedSave'));
      })
      .finally(() => {
        setLoading(false);
        setNeedsTableRefresh(true);
      });
  };

  return (
    <>
      <DialogTitle>
        <b>{t(initialData ? 'sales_orders:ORDERTEXT_edit' : 'sales_orders:ORDERTEXT_add')}</b>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid size={5.95}>
              <Controller
                name="version"
                control={control}
                rules={{
                  required: t('common:TXT_Required'),
                  min: { value: 1, message: t('common:TXT_Required') },
                }}
                render={({ field }) => (
                  <NumericField
                    label={t('sales_orders:ORDERTEXT_textSet')}
                    size="small"
                    fullWidth
                    id={field.name}
                    buttonLayout="none"
                    precision={0}
                    required
                    helperText={errors.version?.message}
                    color={errors.version ? 'error' : 'primary'}
                    value={watch('version') || initialData?.version}
                    onChangeNumeric={(value) => {
                      clearErrors('version');
                      setValue('version', value);
                    }}
                    disabled={!!initialData?.version}
                  />
                )}
                disabled={!!initialData?.version}
              />
            </Grid>
            <Grid size={5.95}>
              <Controller
                name="language"
                control={control}
                rules={{ required: t('common:TXT_Required') }}
                disabled={!!initialData?.version}
                render={({ field }) => (
                  <LanguagesDropdown
                    label={t('sales_orders:ORDERTEXT_language')}
                    size="small"
                    id={field.name}
                    fullWidth
                    value={watch('language') !== undefined ? watch('language') : initialData?.language}
                    onChange={(value) => {
                      clearErrors('language');
                      setValue('language', (value || '') as string);
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        minWidth: '100px',
                      },
                    }}
                    required
                    helperText={errors.language?.message}
                    color={errors.language ? 'error' : 'primary'}
                    disabled={!!initialData?.version}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            name="longText"
            control={control}
            rules={{ required: t('common:TXT_Required') }}
            render={({ field }) => {
              const handleChange = (e: { target: { value: any } }) => {
                field.onChange(e.target.value);
              };

              return (
                <TextField
                  {...field}
                  onChange={handleChange} // Attach custom onChange logic
                  label={t('sales_orders:ORDERTEXT_textInfo')}
                  multiline
                  id={field.name}
                  rows={4}
                  fullWidth
                  helperText={errors.longText?.message}
                  color={errors.longText ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      sx: {
                        '& .MuiInputBase-input': {
                          fontFamily: 'monospace', // Ensures equal width for all characters
                          whiteSpace: 'normal',
                          wordBreak: 'break-all',
                          fontSize: '16px',
                          height: 'fit-content',
                          overflowWrap: 'anywhere',
                          minWidth: 'calc(70ch + 10px)', // Ensures text fits in the box with scrollbar with extra 2 px
                          maxWidth: 'calc(70ch + 10px)', // Ensures text fits in the box with scrollbar with extra 2 px
                          '::-webkit-scrollbar': {
                            width: '8px', // specify webkit scrollbar size
                          },
                          scrollbarGutter: 'stable',
                          resize: 'vertical',
                        },
                      },
                    },
                  }}
                />
              );
            }}
          />
          <Box width="100%">
            <DocumentsDropdown
              label={t('sales_orders:ORDERTEXT_documents')}
              size="small"
              id="documents"
              fullWidth
              value={
                watch('documents')
                  ?.filter((d) => d.active)
                  .map((doc) => ({
                    value: doc.documentID,
                    label: doc.description,
                  })) || []
              }
              params={{ textType: orderLine ? '*SALORDLIN' : '*SALORDHDR' }}
              onChange={(_e, value) => {
                const documents = (
                  value as {
                    value: string | number;
                    label: string;
                  }[]
                ).map((doc) => ({
                  documentID: doc.value as string,
                  description: doc.label,
                  active: true,
                }));
                setValue('documents', documents);
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          id="close"
          onClick={() => {
            setIsEditView(false);
            setTextToEdit(null);
          }}
        >
          {t('common:TXT_Close')}
        </Button>
        <Button id={'save'} onClick={handleSubmit(onSubmit)} color="primary" loading={loading} variant="contained">
          {t('common:TXT_Save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddEditTextView;

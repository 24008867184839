export default (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      d="M18.0417 14.815C18.0417 15.2292 18.3775 15.565 18.7917 15.565C19.2059 15.565 19.5417 15.2292 19.5417 14.815H18.0417ZM14.75 18.1854C14.75 17.7712 14.4142 17.4354 14 17.4354C13.5858 17.4354 13.25 17.7712 13.25 18.1854H14.75ZM17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25C16.0858 11.25 15.75 11.5858 15.75 12L17.25 12ZM15.75 21C15.75 21.4142 16.0858 21.75 16.5 21.75C16.9142 21.75 17.25 21.4142 17.25 21H15.75ZM16.5 15.6206C15.7216 15.6206 15.3416 15.4859 15.1686 15.3609C15.0494 15.2747 14.9583 15.1452 14.9583 14.815H13.4583C13.4583 15.4871 13.6797 16.1354 14.2897 16.5764C14.8459 16.9786 15.6118 17.1206 16.5 17.1206V15.6206ZM14.9583 14.815C14.9583 14.3806 15.476 13.7502 16.5 13.7502V12.2502C14.9927 12.2502 13.4583 13.2448 13.4583 14.815H14.9583ZM16.5 13.7502C17.524 13.7502 18.0417 14.3806 18.0417 14.815H19.5417C19.5417 13.2448 18.0073 12.2502 16.5 12.2502V13.7502ZM18.25 18.1854C18.25 18.5763 18.1016 18.7936 17.8614 18.9485C17.5773 19.1317 17.1151 19.2502 16.5 19.2502V20.7502C17.2656 20.7502 18.0534 20.6095 18.6742 20.2093C19.3387 19.7808 19.75 19.0908 19.75 18.1854H18.25ZM16.5 19.2502C15.9506 19.2502 15.4838 19.0875 15.1728 18.8617C14.861 18.6354 14.75 18.3868 14.75 18.1854H13.25C13.25 18.9862 13.6986 19.6451 14.2916 20.0756C14.8855 20.5067 15.6687 20.7502 16.5 20.7502V19.2502ZM16.5 17.1206C17.2851 17.1206 17.7195 17.2485 17.9449 17.4063C18.112 17.5232 18.25 17.7152 18.25 18.1854H19.75C19.75 17.3593 19.4714 16.6438 18.8051 16.1774C18.1971 15.7519 17.3816 15.6206 16.5 15.6206V17.1206ZM17.25 13.0002L17.25 12L15.75 12L15.75 13.0002L17.25 13.0002ZM15.75 20.0002V21H17.25V20.0002H15.75Z"
      fill="currentColor"
    />
    <path
      d="M18 8.90909L18 6.81818C18 5.12494 18 4.27832 17.732 3.60214C17.3013 2.5151 16.3902 1.65765 15.2352 1.2522C14.5168 1 13.6173 1 11.8182 0.999999C8.66981 0.999999 7.09563 1 5.83836 1.44135C3.81714 2.15088 2.22282 3.65142 1.46894 5.55375C1 6.73706 1 8.21865 1 11.1818L1 13.7273C1 16.7966 1 18.3313 1.8477 19.3971C2.09058 19.7025 2.37862 19.9736 2.70307 20.2022C3.83546 21 5.46607 21 8.72727 21L9.5 21C10.0347 21 11.0337 21 11.5 20.9972"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 11C1 9.15905 2.49238 7.66667 4.33333 7.66667C4.99912 7.66667 5.78404 7.78333 6.43137 7.60988C7.00652 7.45576 7.45576 7.00652 7.60988 6.43136C7.78333 5.78404 7.66667 4.99912 7.66667 4.33333C7.66667 2.49238 9.15905 1 11 1"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

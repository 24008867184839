import { useOpenAPIMethod } from '@iptor/business';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Order } from '../../../constants';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import _ from 'lodash';

type ParamsType = {
  customer: string;
  orderType?: string;
  warehouse?: string;
  dispatchTime?: string;
};

export type OrderDetailsHookType = {
  watch: UseFormWatch<Partial<Order>>;
  setValue: UseFormSetValue<Partial<Order>>;
  reset: UseFormReset<Partial<Order>>;
  control: Control<Partial<Order>>;
  getValues: UseFormGetValues<Partial<Order>>;
  clearErrors: UseFormClearErrors<Partial<Order>>;
};

export const useOrderDetails = ({ watch, setValue, reset, getValues, clearErrors }: OrderDetailsHookType) => {
  const orderDetailsDataApi = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrdersSimulations/{customer}');
  const { notify } = useOrderNotifications();

  // State management
  const [isLiveSearchLoading, setIsLiveSearchLoading] = useState(false);
  const [cachedApiParams, setCachedApiParams] = useState<Partial<Order>>(null);

  const selectedCustomer = watch('customer');
  const selectedHandler = watch('handler');
  const selectedOrderType = watch('orderType');

  const clearFormFields = () => {
    setValue('warehouse', '');
    setValue('orderType', '');
    setValue('dispatchTime', undefined);
    setValue('dispatchAddress', null);
  };

  const updateFormFields = (response: Partial<Order>) => {
    clearFormFields();
    clearErrors();

    const fieldsToUpdate = {
      ...response,
      warehouse: response.warehouse,
      orderType: selectedOrderType ?? response.orderType,
      dispatchTime: response.dispatchTime,
      dispatchAddress: response.dispatchAddress,
      handler: selectedHandler ?? response.handler,
    };

    Object.entries(fieldsToUpdate).forEach(([key, value]) => {
      if (value !== undefined) {
        setValue(key as keyof Order, value);
      }
    });
  };

  const updateOrderDetails = (customer: string, orderType: string, warehouse: string, dispatchTime: string) => {
    if (!customer) return;

    const params: ParamsType = {
      customer,
      ...(orderType && { orderType }),
      ...(warehouse && { warehouse }),
      ...(dispatchTime && { dispatchTime }),
    };
    setCachedApiParams(params);

    if (!_.isEqual(params, cachedApiParams)) {
      setIsLiveSearchLoading(true);

      orderDetailsDataApi.execute(params);
    }
  };

  // Handle API Success
  useEffect(() => {
    if (!orderDetailsDataApi.response?.data?.data) return;

    setIsLiveSearchLoading(false);
    const response = orderDetailsDataApi.response.data.data;

    if (response) {
      updateFormFields(response);
    }
  }, [orderDetailsDataApi.response]);

  // Handle API Error
  useEffect(() => {
    if (!orderDetailsDataApi.error || !axios.isAxiosError(orderDetailsDataApi.error)) return;

    setIsLiveSearchLoading(false);

    const currentValues = getValues();
    Object.keys(currentValues).forEach((key) => {
      if (!['customer', 'handler', 'orderType'].includes(key)) {
        setValue(key as keyof Order, undefined);
      }
    });

    const resetSalesOrderData: Partial<Order> = {
      customer: selectedCustomer,
      handler: selectedHandler,
      orderType: selectedOrderType,
      warehouse: undefined,
      dispatchAddress: undefined,
      dispatchTime: undefined,
      primaryCurrency: undefined,
    };

    reset(resetSalesOrderData);
    notify('error', orderDetailsDataApi.error?.response?.data?.messages);
  }, [orderDetailsDataApi.error]);

  return {
    isLiveSearchLoading,
    updateOrderDetails,
    cachedApiParams,
  };
};

import { Text, useIptorTranslation } from '@iptor/base';
import { Stack, styled } from '@mui/material';
import { OperationResponseDataData } from '@iptor/business';

const StyledOrderHeaderStack = styled(Stack)`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3)}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => theme.palette.grey[200]};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export type ItemInsightsProps = {
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function ItemInsights({ orderLineInfo }: ItemInsightsProps) {
  const { t } = useIptorTranslation();
  return (
    <StyledOrderHeaderStack direction="row" gap={10}>
      <Stack direction="row" gap={2}>
        <Text variant="body1">{`${t('common:Item')}:`}</Text>
        <Text id="item" variant="body1" fontWeight={600}>
          {orderLineInfo.item}
        </Text>
      </Stack>
      <Stack direction="row" gap={2}>
        <Text variant="body1">{`${t('common:TXT_Description')}:`}</Text>
        <Text id="description" variant="body1" fontWeight={600}>
          {orderLineInfo.description}
        </Text>
      </Stack>
      <Stack direction="row" gap={2}>
        <Text variant="body1">{`${t('sales_orders:ITEM_INFO_lineLink')}:`}</Text>
        <Text variant="body1" id="orderLine" fontWeight={600}>
          {orderLineInfo.orderLine}
        </Text>
      </Stack>
    </StyledOrderHeaderStack>
  );
}

export default ItemInsights;

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Code', 'Description'];

export const AvailCheckAtOrderEntriesDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: availCheckAtOrderEntriesOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'panelValue',
    params: {
      windowId: 'panelValues',
      where: "field='AVCO' AND language='EN'",
    },
    shouldPreventApiCall,
  });

  return (
    <BaseDropdown {...props} loading={loading} options={availCheckAtOrderEntriesOptions} columnNames={COLUMN_NAMES} />
  );
};

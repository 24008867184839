import { Accordion, useIptorTranslation } from '@iptor/base';
import { useOpenAPIMethod } from '@iptor/business';
import { Box, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import AddressCard from '../../../components/addresses/AddressCard';

export type DataCardProps = {
  title: string;
  addressNumber: number;
  text: string;
  onEdit?: () => void;
};

const Addresses = ({
  order,
  customer,
  orderStatus,
  onAddressChange,
}: {
  order: number;
  customer: string;
  orderStatus: number;
  onAddressChange?: () => void;
}) => {
  const { t } = useIptorTranslation();
  const addressesApi = useOpenAPIMethod('internal.v1.base-orders', 'GET /salesOrders/{order}/addresses');

  useEffect(() => {
    addressesApi.execute({ order });
  }, [order]);

  return (
    <>
      <Accordion headingTitle={t('sales_orders:ADDRESS_addresses')}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 3,
          }}
        >
          {!addressesApi.loading
            ? addressesApi.response?.data?.data?.items?.map((address, index) => {
                return (
                  <AddressCard
                    key={address.address + '-' + index}
                    address={address}
                    orderStatus={orderStatus}
                    customer={customer}
                    onAddressEdit={() => {
                      addressesApi.execute({ order });
                      onAddressChange();
                    }}
                  />
                );
              })
            : Array(5)
                .fill(undefined)
                .map((_, idx) => (
                  <Skeleton
                    key={idx}
                    variant="rectangular"
                    sx={(theme) => ({
                      minHeight: '188px',
                      borderRadius: theme.spacing(1),
                    })}
                  />
                ))}
        </Box>
      </Accordion>
    </>
  );
};

export default Addresses;

import { DecimalLiteral } from '@babel/types';
import { Localization } from '../localization/Localization';
import { DecimalFormatter } from '../parsers/DecimalFormatter';

export class NumberFormatter {
  in(value: string, decimalSeperator: string, raw: boolean = false) {
    /* 
      REMARK: 
        ° FAST FIX, NTH clean-up 
        ° ORIG CODE WILL RETURN INVALID VALUE IF input value CONTAINS GROUPSEPERATOR = ','
        ° IN ORIG CODE allowZeros was ALSO not implemented
      Original code:
    */
    //     value = value.trim();

    //     if (value == '') return '';
    //     let temp = value;

    //     let groupSepLocale = decimalSeperator === '.' ? ',' : '.';

    //     if (decimalSeperator == ',') {
    //       temp = temp.replace(groupSepLocale, '');
    //       temp = value = temp.replace(',', '.');
    //       temp =
    //         value.substring(0, value.indexOf('.')).replace(/(?=(?:\d{3})+\b)(?!\b)/g, ',') + temp.substring(value.indexOf('.'), value.length);
    //     }

    //     if (temp.length > 0) {
    //       let signIndex = temp.indexOf('-');
    //       /*if (signIndex == 0) {
    //         temp = temp.substring(1) + '-';
    //       } else*/ if (signIndex == temp.length - 1) {
    //         temp = '-' + temp.substring(0, temp.length - 1);
    //       }

    //       let d = parseFloat(temp);
    //       if (d == 0) return '';

    //       value = d.toString();

    //       let noOFDec = value.indexOf('.') === -1 ? -1 : temp.length - temp.indexOf('.') - 1;
    //       if (noOFDec !== -1) temp = d.toFixed(noOFDec);
    //     }

    //     const gs = Localization.instance.groupSeparator || ',';
    //     const ds = Localization.instance.decimalSeparator || '.';
    //     temp = temp.replace(/([,.])/g, (string) => (string === ',' ? gs : ds));
    //     return temp;

    const groupSepLocale = decimalSeperator === '.' ? ',' : '.';
    return new DecimalFormatter().in(value, decimalSeperator, raw);
  }

  out(value: string, decimalSeperator: string) {
    let temp = value;
    // temp = temp.replaceAll(groupSepLocale, '');
    if (decimalSeperator == ',') temp = temp.replace('.', ',');
    if (temp.length == 0) value = '0';
    else {
      value = temp.toString();
    }
    return value;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Accordion, DatePicker, Text, useIptorTranslation } from '@iptor/base';
import {
  CurrencyDropdown,
  DispatchAddressDropdown,
  InventoryContractDropdown,
  OrderPropertyDropdown,
  SalesPromotionDropdown,
} from '@iptor/business';
import { FormControlLabel, Grid2 as Grid, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import { Order } from '../../../constants';
import { formatDate, formatDateToPayloadFormat } from '../../../utils/formatDate';

type MoreInfoProps = {
  errors?: FieldErrors<Partial<Order>>;
  watch?: UseFormWatch<Partial<Order>>;
  control?: Control<Partial<Order>>;
  choosenCustomer?: string;
};

const MoreInfo = ({ watch, control, errors, choosenCustomer }: MoreInfoProps) => {
  const { t } = useIptorTranslation();

  return (
    <Accordion
      expanded={errors?.dispatchAddress?.message || !isEmpty(errors) ? true : false}
      headingTitle={t('common:TXT_More_Details_Optional')}
    >
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid container size={12}>
          <Grid size={4}>
            <Controller
              name="orderPropertyCode"
              control={control}
              render={({ field }) => (
                <OrderPropertyDropdown
                  value={field.value}
                  id={field.name}
                  onChange={(newValue) => {
                    const orderPropertyCode = newValue || '';
                    field.onChange(orderPropertyCode);
                  }}
                  color={'primary'}
                  label={t('common:TXT_Order_Property')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid size={4}>
            <Controller
              name="salesPromotion"
              control={control}
              render={({ field }) => (
                <SalesPromotionDropdown
                  onChange={(newValue) => {
                    const salesPromotion = newValue || '';
                    field.onChange(salesPromotion);
                  }}
                  id={field.name}
                  value={field?.value ?? ''}
                  label={t('common:TXT_Sales_Promotion')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid size={4}>
            <Controller
              name="dispatchAddress"
              control={control}
              rules={{ required: t('common:TXT_Deliver_Address_Mandatory') }}
              render={({ field, fieldState: { error } }) => (
                <DispatchAddressDropdown
                  {...field}
                  onChange={(newValue) => {
                    const selectedOption = newValue || '';
                    field.onChange(selectedOption);
                  }}
                  id={field.name}
                  value={field.value}
                  color={errors?.dispatchAddress?.message ? 'error' : 'primary'}
                  helperText={errors?.dispatchAddress?.message || ''}
                  label={t('common:TXT_Deliver_Address_No')}
                  businessPartner={choosenCustomer}
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container size={12}>
          <Grid size={4}>
            <Controller
              name="pricingDate"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  label={
                    <Text component="label" variant="body2" fontWeight={600} lineHeight={2}>
                      {t('common:TXT_Pricing_Date')}
                    </Text>
                  }
                  control={
                    <DatePicker
                      value={field.value ? formatDate(field.value) : null}
                      slotProps={{
                        textField: {
                          id: field.name,
                        },
                      }}
                      onChange={(newValue) => {
                        field.onChange(formatDateToPayloadFormat(newValue));
                      }}
                    />
                  }
                  sx={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                    margin: 0,
                  }}
                />
              )}
            />
          </Grid>

          <Grid size={4}>
            <Controller
              name="primaryCurrency"
              control={control}
              render={({ field }) => (
                <CurrencyDropdown
                  onChange={(newValue) => {
                    const selectedOption = newValue || '';
                    field.onChange(selectedOption);
                  }}
                  id={field.name}
                  value={field.value ?? ''}
                  label={t('common:TXT_Currency')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid size={4}>
            <Controller
              name="inventoryContractNumber"
              control={control}
              render={({ field }) => (
                <InventoryContractDropdown
                  onChange={(newValue) => {
                    const selectedOption = newValue || '';
                    field.onChange(selectedOption);
                  }}
                  id={field.name}
                  value={field.value ?? ''}
                  label={t('common:TXT_Inventory_Contract')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Accordion>
  );
};
export default MoreInfo;

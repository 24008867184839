import React, { useState, useEffect } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Icons, SquareIcon } from '../components/SquareIcon';
import { Localization } from '../framework/localization/Localization';
import { useThemeContext } from '@iptor/base';

export const Dark = () => {
  const { mode, toggleTheme } = useThemeContext();
  const [checked, setChecked] = useState(mode === 'dark');

  useEffect(() => {
    setChecked(mode === 'dark');
  }, [mode]);

  const toggleThemeChange = () => {
    toggleTheme();
  };

  const resetTheme = () => {
    localStorage.setItem('theme', 'default');
    const defaultTheme = localStorage.getItem('default_theme') || 'light';
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', defaultTheme);
  };

  return (
    <div className="dark-mode">
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={toggleThemeChange} />
        <span className="slider round" />
      </label>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip className="custom" id={`tooltip-print`}>
            {Localization.instance.getString('Tooltip-Local-Mode')}
          </Tooltip>
        }
      >
        <span className="icon-client-modal-mode" onClick={resetTheme}>
          <SquareIcon size="20px">{Icons.Refresh2}</SquareIcon>
        </span>
      </OverlayTrigger>
    </div>
  );
};

import SpotlightHoverImage from '@images/SpotlightHover.png';
import { Box, Skeleton, Typography, styled, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import { Dayjs } from 'dayjs';
import { DatePicker } from '../DatePicker';
import { Icon } from '../Icon';

type SpotlightStats = {
  currency: string;
  entityID?: string;
  entityName?: string;
  totalCount: string | number;
  totalValue: string | number;
};
export type SpotlightCardProps = {
  // Common props
  cardTitle: string;
  variant: 'primary' | 'secondary';
  loading?: boolean;
  format?: string;
  id?: string;
  // Conditional props for primary
  headerValue?: number | string;
  context?: string;
  prefix?: string;
  subValue?: string | number;
  selectedDate?: Dayjs | null;
  onDatePickerChange?: (value: Date) => void;
  captionString?: string;
  // Conditional props for secondary
  contentList?: SpotlightStats[];
  selectedMonthYear?: Dayjs | null;
  onMonthAndYearChange?: (newValue: Date) => void;
};

// Union type based on the variant
type ConditionalSpotlightCardProps =
  | (SpotlightCardProps & {
      variant: 'primary';
      headerValue: number | string;
      context: string;
      prefix: string;
      subValue: string | number;
      loading?: boolean;
      selectedDate?: Dayjs | null;
      onDatePickerChange?: (value: Dayjs) => void;
      captionString?: string;
      format?: string;
    })
  | (SpotlightCardProps & {
      variant: 'secondary';
      selectedMonthYear?: Dayjs | null;
      contentList: SpotlightStats[];
      loading?: boolean;
      format?: string;
      onMonthAndYearChange?: (newValue: any) => void;
    });

const rgbaFromHex = (hex: string, alpha: number) => {
  // Ensure the hex starts with "#" and remove it
  let cleanHex = hex.replace(/^#/, '');

  // Expand short hex format (e.g., #abc → #aabbcc)
  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Extract RGB values
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  // Return RGBA format
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const StyledCard = styled(Box)`
  ${({ theme }) => {
    const primaryColor = theme.palette.primary.main;
    const hoverGradientStart = rgbaFromHex(primaryColor, 0.4); // For gradient start
    const hoverGradientEnd = rgbaFromHex(primaryColor, 0.88); // For gradient end
    return `
      min-width: 300px;
      min-height: 85px;
      border: 1px solid ${theme.palette.primary[400]};
      border-radius: 16px;
      padding: 16px;
      background-color: ${theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.primary[100]} !important;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: space-between;
      transition: all 0.2s ease;

      &:focus {
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
        border-radius: 4px;
        box-shadow: none;
      }

      &:hover {
        background: linear-gradient(${hoverGradientStart}, ${hoverGradientEnd}), url(${SpotlightHoverImage}) no-repeat !important;
        background-size: cover;
        background-position: right !important;
        background-blend-mode: overlay;
        color: ${theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.foreground[100]} !important;

        p {
          color: ${theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.foreground[100]} !important;
        }
          border-color: ${theme.palette.background.default};
        }

        .MuiBox-root {
          border-color: ${theme.palette.background.default};
        }
      }
    `;
  }}
`;

const Title = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.primary[500]};
    font-size: 14px;
    font-weight: 600;
  `}
`;

const Headline = styled(Typography)`
  ${({ theme }) => `
    padding-right: ${theme.spacing(2)};
    color: ${theme.palette.mode === 'light' ? theme.palette.foreground[400] : theme.palette.foreground[900]} !important;
    border-right: 1px solid ${theme.palette.primary[300]};
    font-size: 24px;
    font-weight: 600;
    min-width:165px;
  `}
`;

const SubValueText = styled(Typography)`
  ${({ theme }) => `
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.mode === 'light' ? theme.palette.foreground[400] : theme.palette.foreground[900]} !important;
    line-height: 1.4;
  `}
`;

const TitleGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const Layout = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Group = styled(Box)`
  ${({ theme }) => `
    display: flex;
    min-width: 90px;
    flex-direction: column;
    padding-right: 20px;
    border-right: solid 1px ${theme.palette.primary.light};

    &.group {
      border-color: ${theme.palette.primary.light};
    }
      
    &:last-child {
      border-right: none;
    }
  `}
`;

const Subheading = styled(Typography)`
  ${({ theme }) => `
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.foreground[900]} !important;
  `}
`;

const Caption = styled(Typography)`
  ${({ theme }) => `    
    font-size: 12px;
    font-weight: 600;
    color: ${theme.palette.mode === 'light' ? theme.palette.foreground[600] : theme.palette.foreground[600]} !important;};
    max-width: 116px;
    line-height: 1.2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `}
`;

const getValueWithSuffix = (totalValue: string | number) => {
  const modifiedValue = +totalValue;
  const valueInMillions: number = +(modifiedValue / 1000000).toFixed(2);
  const valueInThousands: number = +(modifiedValue / 1000).toFixed(2);

  if (valueInMillions >= 1) {
    return `${valueInMillions}M`;
  } else if (valueInThousands >= 1) {
    return `${valueInThousands}K`;
  }
  return totalValue;
};

const getCurrencySymbol = (currency: string) => {
  try {
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency })
      .formatToParts(1)
      .find((x) => x.type === 'currency').value;
  } catch (e) {
    return '';
  }
};

export const SpotlightAreaCard = ({ variant = 'primary', cardTitle, ...props }: ConditionalSpotlightCardProps) => {
  const { palette } = useTheme();
  const theme = useTheme();
  const { headerValue, context, prefix, subValue, captionString, selectedDate, onDatePickerChange, loading, format } =
    props;
  const headLineText = headerValue
    ? `${prefix ? getCurrencySymbol(prefix) : ' '} ${getValueWithSuffix(headerValue)}`
    : ' - ';
  const subText = `${subValue || ' - '} ${context}`;

  const StyledSkeleton = styled(Skeleton)`
    border-radius: ${theme.spacing(0.5)};
    width: 240px;
    height: 40px;
  `;

  const datePickerStyle = {
    width: 'fit-content',
    svg: {
      height: '20px',
      width: '20px',
      fill: theme.palette.mode === 'light' ? theme.palette.foreground[500] : theme.palette.foreground[900],
    },
    '& .MuiInputBase-root': {
      height: '30px',
      fontSize: '12px',
      minWidth: '115px',
      width: 'min-content',
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary[100] : theme.palette.primary[300],
      color: theme.palette.mode === 'light' ? theme.palette.foreground[400] : theme.palette.foreground[900],
      outline: 'none',
      border: 'none',
      padding: '12px',
    },
    '& .MuiButtonBase-root': {
      padding: '4px',
      outline: 'none',
    },
  };

  if (variant === 'secondary') {
    const { contentList, onMonthAndYearChange, selectedMonthYear, format } = props;

    return (
      <StyledCard>
        <TitleGroup>
          <Title style={{ width: '120px', lineHeight: 2.5 }}>{cardTitle}</Title>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedMonthYear}
              format={format}
              onChange={onMonthAndYearChange}
              sx={datePickerStyle}
              views={['month', 'year']}
              slotProps={{
                textField: {
                  id: props.id,
                  inputProps: {
                    // Tooltip content
                    'data-tip': props.id,
                  },
                },
              }}
            />
          </LocalizationProvider>
        </TitleGroup>

        <Layout>
          {loading ? (
            <Box display="flex" flexDirection={'row'} gap={theme.spacing(2.7)} sx={{ maxWidth: '300px' }}>
              <StyledSkeleton variant="rectangular" />
              <StyledSkeleton variant="rectangular" />
              <StyledSkeleton variant="rectangular" />
            </Box>
          ) : contentList && contentList.length > 0 ? (
            contentList.map((content, index) => (
              <Group key={index}>
                <Subheading>
                  {' '}
                  {`${content?.currency ? getCurrencySymbol(content?.currency) : ' '} ${content.totalValue ? getValueWithSuffix(content.totalValue) : '-'}`}
                </Subheading>
                <Caption>{content.entityName || '-'}</Caption>
              </Group>
            ))
          ) : (
            <Subheading>Data not found</Subheading>
          )}
        </Layout>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Title>{cardTitle}</Title>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            format={format}
            onChange={onDatePickerChange}
            sx={datePickerStyle}
            slotProps={{
              textField: {
                id: props.id,
                inputProps: {
                  // Tooltip content
                  'data-tip': props.id,
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {loading ? (
        <StyledSkeleton variant="rectangular" />
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={2} minWidth="75%">
            <Headline>{headLineText}</Headline>
            <Box>
              <>
                <SubValueText>{subText}</SubValueText>
                <Caption sx={{ minWidth: '180px' }}>{captionString}</Caption>
              </>
            </Box>
          </Box>

          <Box>
            <Icon icon="advanceMoney" size="L" color={palette.primary[400]} />
          </Box>
        </Box>
      )}
    </StyledCard>
  );
};

import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './../styles/Combo.scss';
import { ReactComponent as DropdownArrow } from './../assets/dropdown-arrow.svg';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { LocalStorage } from '../framework/handlers/localStorage';
import { useNotes } from '../framework/your-note/NotesProvider';

type Option = { label: string; value: string };
/*
 * attributes: XML attributes,
 * options: options list (dropdown items)
 * panelID: panel id in window
 * */

type ComboProps = {
  attributes: Record<string, any>;
  className?: string;
  defaultValue?: Option;
  disabled?: boolean;
  id: string;
  name: string;
  options?: Option[];
  placeholder?: string;
  readOnly?: boolean;
  visible?: boolean;
  panelID: string;
  isInvalid?: boolean;
};

type ComboState = {
  hasFocus: boolean;
  isOpen: boolean;
};

export const Combo: FunctionComponent<ComboProps> = ({
  id,
  attributes,
  className,
  name,
  visible = true,
  options = [],
  defaultValue,
  placeholder = '',
  disabled = false,
  panelID,
  isInvalid = false,
}) => {
  const { addToCommand, errors, windowData, cursor } = useContext(CommandContext);
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);
  const [randomValue, setRandomValue] = useState<string>('');
  const [comboState, setComboState] = useState<ComboState>({ hasFocus: false, isOpen: false });
  const { setFocussedElement, setFieldValue, setDisplayValue } = useNotes();

  useEffect(() => {
    let value = XT.getValueFromWindow({ data: windowData }, panelID, id);
    let _val = options.find((x: any) => x.value === (value || '')) || { label: '', value: '' };

    setSelectedOption(_val);
    if (!options.find((x: any) => x.value === (value || ''))) {
      setRandomValue(value.trimEnd());
    }
    if (ref && ref.current) {
      let _dirtyflag = +(ref.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      addToCommand(panelID, id, _val?.value || '', _dirtyflag);
    }
  }, []);

  useEffect(() => {
    let value = XT.getValueFromWindow({ data: windowData }, panelID, id);
    let reloaded = XT.getReloadedFromWindow({ data: windowData }, panelID, name);
    let _val = options.find((x: any) => x.value === (value || '')) || { label: '', value: '' };
    if (reloaded === true || reloaded === undefined) setSelectedOption(_val);
    if (!options.find((x: any) => x.value === (value || ''))) {
      setRandomValue(value.trimEnd());
    }
    if (ref && ref.current) {
      let _dirtyflag = +(ref.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      addToCommand(panelID, id, _val?.value || '', _dirtyflag);
    }
  }, [windowData]);

  const [selectedOption, setSelectedOption] = useState<Option>(defaultValue ? defaultValue : { label: '', value: '' });
  const ref = useRef<HTMLButtonElement>(null);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    // set coordinates for tabindex
    if (ref && ref.current) {
      const scrollInfo = XT.getScrollInfo(ref);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled /* || readOnly */) {
      return false;
      // } else if (rowID) {
      //   return false;
    } else if (comboState.hasFocus || comboState.isOpen) {
      // --> keep default behavior arrow (down ==> open combo)
      return false;
    }
    return true;
  };

  return (
    <>
      <Dropdown
        id={'combo-' + id}
        data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
        data-for="global"
        data-iscapture="true"
        onFocus={() => {
          setFocussedElement(id);
          setFieldValue(selectedOption?.value);
          setDisplayValue(selectedOption?.label);
          if (!comboState.hasFocus) setComboState({ ...comboState, hasFocus: true });
        }}
        onBlur={(e: React.FocusEvent) => {
          if (!e.currentTarget.contains(e.relatedTarget) && comboState.hasFocus)
            setComboState({ ...comboState, hasFocus: false });
        }}
        onToggle={(isOpen: boolean) => {
          setComboState({ ...comboState, isOpen: isOpen });
        }}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (
            ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
            document.activeElement === ref.current &&
            /* TODO SKE: 
              temporarily extra condition added ==> no tabbing if list open
              because tabsequence is broken (does not select next but always first of the panel)
              could be fixed by adding "ref.current?.focus();" before "XT.selectNextElement();"
              but what we really want is that the current value is selected too ==> wait until <tab> is implemented
            */
            LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
          ) {
            XT.selectNextElement();
          }
        }}
      >
        <Dropdown.Toggle
          ref={ref}
          autoFocus={isInvalid}
          data-invalid={isInvalid}
          data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
          data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
          tabIndex={!disabled ? x + y : -1}
          aria-disabled={disabled}
          className={`combo-text-style combo-text-font dropdown-box full-width ${className} ${
            errors && isInvalid ? 'is-invalid' : ''
          }`}
        >
          {selectedOption.label !== '' ? (
            <span aria-disabled={disabled} className={'selected-item'}>
              {selectedOption.label}
            </span>
          ) : (
            <span className={'combo-placeholder-shown'}>{randomValue}</span>
          )}
          <DropdownArrow className={'combo-icon-image'} aria-disabled={disabled} />
        </Dropdown.Toggle>
        {!disabled && (
          <Dropdown.Menu className={`full-width dropdown-list ${className}`}>
            {options.map((option, index) => (
              <Dropdown.Item
                data-event="ignore"
                className={'dropdown-list-item'}
                key={`${id}-${option.label}-${index + 1}`}
                onClick={() => {
                  setSelectedOption(option);
                  setFieldValue(option?.value);
                  setDisplayValue(option?.label);
                  addToCommand(panelID, id, option.value || '', dirtyflag);
                }}
                value={option.value}
              >
                <span>{option.label}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { BusinessDropdownProps } from './component/type';

const COLUMN_NAMES: string[] = ['Order Type', 'Description'];

export const PurchaseOrderTypesDropdown = (props: BusinessDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const { options: purchaseOrderTypeOptions, loading } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'orderType',
    params: { windowId: 'purchaseOrderTypes' },
    shouldPreventApiCall,
  });

  return <BaseDropdown {...props} loading={loading} options={purchaseOrderTypeOptions} columnNames={COLUMN_NAMES} />;
};

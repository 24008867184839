import { FunctionComponent, useContext, useEffect } from 'react';
import { LoaderContainerType } from './LoaderContainer';
import '../styles/Exporter.scss';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { Localization } from '../framework/localization/Localization';
import { ExportContext } from '../views/partials/Window';

export const Exporter: FunctionComponent<any> = (props: any) => {
  const { rows, show } = useContext(ExportContext);
  return (
    <div hidden={!show} className='export-container'>
      <Card className='export-card'>
        <Card.Body>
          <Card.Title>{Localization.instance.getString('EXPORT_Exporting')}</Card.Title>
          <Row>
            <Col md={12}>
              <ProgressBar animated now={100} />
            </Col>
            <Col md={6}>
              {Localization.instance.getString('EXPORT_ExportedRows') + ': '} {rows}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={3}>
              <Button block variant='outline-dark' onClick={props.export}>
                {Localization.instance.getString('EXPORT_StopAndExport')}
              </Button>
            </Col>
            <Col md={3}>
              <Button block variant='outline-dark' onClick={props.abort}>
                {Localization.instance.getString('EXPORT_StopAndAbort')}
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </div>
  );
};

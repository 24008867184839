import { Accordion, Text, TextField, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import {
  DispatchAddressDropdown,
  MannerOfTransportDropdown,
  OperationResponseDataData,
  ShipmentGroupDropdown,
  ShippingAgentDropdown,
  TermsOfDeliveryDropdown,
} from '@iptor/business';

export type ShippingProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function Shipping({ control, orderLineInfo }: ShippingProps) {
  const { t } = useIptorTranslation();
  return (
    <Accordion
      headingTitle={t('common:TXT_Shipping')}
      moreInfo={
        <Grid container spacing={2}>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_pickList')}`}</Text>
            <Text variant="body1" id="picklist">
              {orderLineInfo.picklist || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Shipment_Number')}`}</Text>
            <Text variant="body1" id="shipment">
              {orderLineInfo.shipment || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Package_Type')}`}</Text>
            <Text variant="body1" id="packageType">
              {orderLineInfo.packageType || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('sales_orders:ITEM_INFO_handHazCode')}`}</Text>
            <Text variant="body1" id="handlingHazardCode">
              {orderLineInfo.handlingHazardCode || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Route')}`}</Text>
            <Text variant="body1" id="route">
              {orderLineInfo.route || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Departure')}`}</Text>
            <Text variant="body1" id="departure">
              {orderLineInfo.departure || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Destination')}`}</Text>
            <Text variant="body1" id="destination">
              {orderLineInfo.destination || ' '}
            </Text>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="dispatchAddress"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DispatchAddressDropdown
                businessPartner={orderLineInfo?.customer}
                value={field.value}
                id={field.name}
                onChange={(value) => field.onChange(value)}
                fullWidth
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Delivery_Address')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="shippingAgent"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ShippingAgentDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                id={field.name}
                fullWidth
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Ship_Agent')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="termsOfDelivery"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TermsOfDeliveryDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                id={field.name}
                fullWidth
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Terms_Of_Delivery')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="handlingHazardCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                readOnly
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('sales_orders:ITEM_INFO_handHazDetail')}
                sx={{
                  '& .MuiInputBase-root': {
                    border: 'none',
                    outline: 'none',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="shipmentGroup"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ShipmentGroupDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                fullWidth
                color={error ? 'error' : 'primary'}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Shipment_Group')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="mannerOfTransport"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <MannerOfTransportDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                id={field.name}
                fullWidth
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Manner_Of_Transport')}
              />
            )}
          />
        </Grid>
        <Grid size={6}>
          <Controller
            name="shipmentMarking"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Shipment_Marking')}
              />
            )}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
}

export default Shipping;

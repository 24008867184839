import { PropsWithChildren } from 'react';
import { CircularProgress } from '@mui/material';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import { Icon } from '../Icon';
import IconType from '../../constants/IconType';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for routing

export interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  to?: string;
  color?: ButtonProps['color'] | 'info';
}

export const Button = ({
  children,
  loading = false, // default to false
  iconLeft,
  iconRight,
  size,
  to,
  ...props
}: PropsWithChildren<CustomButtonProps>) => {
  const Component = to ? RouterLink : 'button';

  return (
    <MUIButton
      {...props}
      disabled={props.disabled || loading}
      size={size}
      component={Component}
      to={to}
      data-iscapture="true"
      data-tip={props.id}
      data-for="global"
    >
      <>
        {iconLeft && !loading && <Icon icon={iconLeft} color="inherit" />}
        {children}
        {iconRight && !loading && <Icon icon={iconRight} color="inherit" />}
        {loading && <CircularProgress sx={{ height: 'unset !important', width: 'unset !important' }} color="inherit" />}
      </>
    </MUIButton>
  );
};

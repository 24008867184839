import { Component, FunctionComponent, PropsWithChildren, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import '../styles/Dashboard.scss';
import { Icons, SquareIcon } from '../components/SquareIcon';
import { connect } from 'react-redux';
import { WindowManger } from '../framework/base/windowManager';
import { Toolbar } from './partials/Toolbar';
import axios, { AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import { Company } from '../types/company';
import { User } from '../types/user';
import { Program } from '../types/program';
import { Locale } from '../framework/localization/translations';
import { Extension } from '../framework/dashboard/extension';
import { Header, IWidget, SimpleValue, TableData } from '../framework/dashboard/IWidget';
import { string } from 'prop-types';
import { WindowProps } from './partials/Window';
import { DataSource } from '../framework/dashboard/data-source';
import { Localization, LocalizationInfo } from '../framework/localization/Localization';
import { ContentEditor } from '../content-editor/Editor';

const pieCharts = [
  {
    labels: ['APAC', 'EMEA', 'USA'],
    datasets: [
      {
        label: 'Region Wise Sales (# of orders)',
        data: [9, 5, 7],
        backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#00A3A5',
        ],
        cutout: '60%',
      },
    ],
  },
  {
    labels: ['APAC', 'EMEA', 'USA'],
    datasets: [
      {
        label: 'Region Wise Sales (Invoice Value)',
        data: [18700, 15600, 16100],
        backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#00A3A5',
        ],
        cutout: '60%',
      },
    ],
  },
  {
    labels: [
      'B&O Center Ltd',
      'Harton Investments',
      'Walsall Trading',
      'Benson-Regis Home',
      'Davies & Sons Corp',
      'Jones Corp',
      'FSI Co-operative',
      'Schlesser Teknic',
      'CGM Midland Office',
    ],
    datasets: [
      {
        label: 'Customer Wise Sales (# of orders)',
        data: [3, 2, 2, 2, 2, 2, 1, 4, 3],
        backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#00A3A5',
        ],
        cutout: '60%',
      },
    ],
  },
  {
    labels: [
      'B&O Center Ltd',
      'Harton Investments',
      'Walsall Trading',
      'Benson-Regis Home',
      'Davies & Sons Corp',
      'Jones Corp',
      'FSI Co-operative',
      'Schlesser Teknic',
      'CGM Midland Office',
    ],
    datasets: [
      {
        label: 'Customer Wise Sales (Invoice Value)',
        data: [9600, 3000, 5100, 3900, 6000, 3400, 3300, 8700, 7400],
        backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#00A3A5',
        ],
        cutout: '60%',
      },
    ],
  },
];

const barCharts = [
  {
    label: 'Customer Wise Sales',
    labels: [
      'B&O Center Ltd',
      'Harton Investments',
      'Walsall Trading',
      'Benson-Regis Home',
      'Davies & Sons Corp',
      'Jones Corp',
      'FSI Co-operative',
      'Schlesser Teknic',
      'CGM Midland Office',
    ],
    datasets: [
      {
        label: 'Invoice Value (in 1000$)',
        data: [9.6, 3, 5.1, 3.9, 6, 3.4, 3.3, 8.7, 7.4],
        backgroundColor: '#003f5c',
        borderColor: '#003f5c',
      },
      {
        label: '# of orders',
        data: [3, 2, 2, 2, 2, 2, 1, 4, 3],
        backgroundColor: '#374c80',
        borderColor: '#374c80',
      },
    ],
  },
  {
    label: 'Product Wise Sales',
    labels: ['TV 26" M/Style MX2 Stereo', 'Samsung Galaxy S', 'Regency Satin FSC 320x450mm 115GSM RW'],
    datasets: [
      {
        label: 'Invoice Value (in 1000$)',
        data: [11.2, 16.8, 22.4],
        backgroundColor: '#003f5c',
        borderColor: '#003f5c',
      },
      {
        label: '# of orders',
        data: [7, 7, 7],
        backgroundColor: '#374c80',
        borderColor: '#374c80',
      },
    ],
  },
];

const lineCharts = [
  {
    label: 'Customer Wise Sales',
    labels: ['January', 'February', 'March', 'April'],
    datasets: [
      { label: 'B&O Center Ltd', data: [5200, 8400, 9400, 4600], backgroundColor: '#003f5c', borderColor: '#003f5c' },
      { label: 'Harton Investments', data: [3000, 2400, 3200], backgroundColor: '#2f4b7c', borderColor: '#2f4b7c' },
      { label: 'Walsall Trading', data: [5100, 4200, 5400], backgroundColor: '#665191', borderColor: '#665191' },
      { label: 'Benson-Regis Home', data: [3900, 2400, 3600], backgroundColor: '#a05195', borderColor: '#a05195' },
      { label: 'Davies & Sons Corp', data: [6000, 4000, 5600], backgroundColor: '#d45087', borderColor: '#d45087' },
      { label: 'Jones Corp', data: [3400, 2400, 3200], backgroundColor: '#f95d6a', borderColor: '#f95d6a' },
      { label: 'FSI Co-operative', data: [3300, 2550, 3150], backgroundColor: '#ff7c43', borderColor: '#ff7c43' },
      { label: 'Schlesser Teknic', data: [8700, 7600, 9800], backgroundColor: '#ffa600', borderColor: '#ffa600' },
      { label: 'CGM Midland Office', data: [7400, 6500, 8300], backgroundColor: '#00A3A5', borderColor: '#00A3A5' },
    ],
  },
  {
    label: 'Product Wise Sales',
    labels: ['January', 'February', 'March', 'April'],
    datasets: [
      {
        label: 'TV 26" M/Style MX2 Stereo',
        data: [11200, 9000, 11800],
        backgroundColor: '#003f5c',
        borderColor: '#003f5c',
      },
      { label: 'Samsung Galaxy S', data: [16800, 13050, 17250], backgroundColor: '#2f4b7c', borderColor: '#2f4b7c' },
      {
        label: 'Regency Satin FSC 320x450mm 115GSM RW',
        data: [18000, 18400, 22600, 4600],
        backgroundColor: '#665191',
        borderColor: '#665191',
      },
    ],
  },
];

const dataLine = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
  datasets: [
    {
      label: '',
      data: [388000, 336000, 237000, 364000, 75000],
      backgroundColor: '#60798B',
      borderColor: '#60798B',
    },
  ],
};

export const Widget: FunctionComponent<any> = ({ className, height, ...props }: any) => {
  return (
    <div
      className={'card card-body widget ' + className}
      style={{
        width: '100%',
        height: height,
        // backgroundColor: 'white',
        border: 'none',
        boxShadow: '0px 0px 5px 0px lightgrey',
      }}
    >
      {props.children}
    </div>
  );
};

const Quicklinks: FunctionComponent<any> = (props: any) => {
  const getMenuItems = (menuId: string) => {
    let program: Program | null = null;
    for (let key in props?.flattenedMenu) {
      if (key.indexOf('id') > -1) {
        let value = props.flattenedMenu[key];
        if (value.toLowerCase() === menuId.toLowerCase() && props.flattenedMenu[key.replace('id', 'text')]) {
          program = {
            id: value,
            text: props.flattenedMenu[key.replace('id', 'text')],
            view: props.flattenedMenu[key.replace('id', 'view')],
          };
        }
      }
    }
    return program;
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <p>
            <strong>Quicklinks</strong>
          </p>
        </Col>
      </Row>
      <span style={{ paddingRight: '15px', overflow: 'auto' }}>
        {props.quickLinks.map((program: Program) => (
          <p
            className={'pointer'}
            style={{ marginBottom: '8px' }}
            onClick={() => {
              props.startLoading();
              props.quickLaunch(program, props.endLoading);
            }}
          >
            {getMenuItems(program.id)?.text || program.text}
          </p>
        ))}
      </span>
    </>
  );
};

const TableWidget: FunctionComponent<any> = (props: any) => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <p>
            <strong>{props.title}</strong>
          </p>
        </Col>
      </Row>
      {props.rows.length > 0 && (
        <Row noGutters className="dark-bg table-cell">
          {props.headers.map((header: Header, index: number) => {
            return (
              <Col key={index} sm={header.width}>
                <p>
                  <strong>{header.label}</strong>
                </p>
              </Col>
            );
          })}
        </Row>
      )}
      <span style={{ paddingRight: '0px', overflow: 'auto' }}>
        {props.rows.length > 0 ? (
          props.rows.map((row: Record<string, any>) => (
            <Row noGutters className="table-cell">
              {props.headers.map((header: Header, index: number) => {
                return (
                  <Col key={index} sm={header.width}>
                    <p>
                      <strong>{row[header.key]}</strong>
                    </p>
                  </Col>
                );
              })}
            </Row>
          ))
        ) : (
          <p className="text-center" style={{ top: '50%', position: 'absolute', width: '100%' }}>
            <strong>{props.noDataMessage || Localization.instance.getString('DASHBOARD_NoData')}</strong>
          </p>
        )}
      </span>
    </>
  );
};

const SingleValue: FunctionComponent<any> = (props: any) => {
  return (
    <div className="single-value">
      <Row noGutters>
        <Col className="left-container" sm={7}>
          <h6>{props.leftTitle}</h6>
          <h5 className={'truncate'}>
            {props.leftIcon}
            {props.leftValue}
          </h5>
        </Col>
        <Col className="right-container text-right" sm={5}>
          <h6>{props.rightTitle}</h6>
          <h5 className="truncate">
            {props.rightValue}
            {props.rightIcon}
          </h5>
        </Col>
      </Row>
    </div>
  );
};

const DoughtnutWidget: FunctionComponent<any> = (props: any) => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <p>
            <strong>{props.title}</strong>
          </p>
        </Col>
      </Row>
      <span style={{ flex: '1 1 100%' }}>
        <Doughnut data={props.data} options={props.options} />
      </span>
    </>
  );
};

const MultiValue: FunctionComponent<any> = (props: any) => {
  return (
    <div className="single-value">
      <Row>
        <Col className="left-container" sm={8}>
          <h6>{props.leftTitle}</h6>
          <h5>
            {props.leftIcon1}
            {props.leftValue1}
          </h5>
          <h5>
            {props.leftIcon2}
            {props.leftValue2}
          </h5>
          <h5>
            {props.leftIcon3}
            {props.leftValue3}
          </h5>
        </Col>
        <Col className="right-container text-right" sm={4}>
          <h6>{props.rightTitle}</h6>
          <h5>
            {props.rightValue1}
            {props.rightIcon1}
          </h5>
          <h5>
            {props.rightValue2}
            {props.rightIcon2}
          </h5>
          <h5>
            {props.rightValue3}
            {props.rightIcon3}
          </h5>
        </Col>
      </Row>
    </div>
  );
};

export class DashboardComponent extends Component<
  {
    startLoading: Function;
    endLoading: Function;
    quickLaunch: Function;
    quickLinks: Program[];
    user: User;
    dashboard: string;
    locale: LocalizationInfo.Settings;
    flattenedMenu?: Record<string, any>;
  },
  {
    pieType: number;
    barType: number;
    lineType: number;
    newSalesOrders: number;
    openOrderValue: number;
    totalRevenue: number;
    messages: Record<string, any>[];
    feed: Record<string, any>[];
    pieChart: Record<string, any>;
    company: Record<string, any>;
    response?: Record<string, any>;
  }
> {
  state: {
    pieType: number;
    barType: number;
    lineType: number;
    newSalesOrders: number;
    openOrderValue: number;
    totalRevenue: number;
    messages: Record<string, any>[];
    feed: Record<string, any>[];
    pieChart: Record<string, any>;
    company: Record<string, any>;
    response?: Record<string, any>;
    editing: boolean;
  };

  constructor(props: {
    dashboard: string;
    startLoading: Function;
    endLoading: Function;
    quickLaunch: Function;
    quickLinks: Program[];
    user: User;
    locale: LocalizationInfo.Settings;
    flattenedMenu?: Record<string, any>;
  }) {
    super(props);
    this.state = {
      pieType: 0,
      barType: 0,
      lineType: 0,
      newSalesOrders: 0,
      openOrderValue: 0,
      totalRevenue: 0,
      messages: [],
      feed: [],
      pieChart: {},
      company: {},
      response: {},
      editing: false,
    };
    this.nFormatter = this.nFormatter.bind(this);
    this.aperioCall = this.aperioCall.bind(this);
    this.reload = this.reload.bind(this);
    this.setEditing = this.setEditing.bind(this);
  }

  nFormatter(num: number, digits: number) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }

  reload() {
    if (!this.props.user?.id) {
      return;
    }
    let response: Record<string, any> = { ...this.state.response };
    try {
      Object.entries((window as any)?.dashboard(this.props)?.dataSources)?.map(
        // @ts-ignore
        async ([id, source]: [string, DataSource]) => {
          let res = await axios
            .request({
              url: source.endpoint,
              data: source.payload?.(),
              method: source.method,
              params: source.params,
            })
            .catch((err) => {
              console.error(`Error from DashboardComponent reload step1 ${err}`);
            });
          response[id] = !!source.response ? source.response((res as AxiosResponse)?.data) : (res as AxiosResponse)?.data;
          this.setState({ response: response });
        },
      );
    } catch (e: any) {
      console.error(`Error from DashboardComponent reload step2 ${e}`);
    }
  }

  async aperioCall(api: string, params?: Record<string, any>) {
    const date = new Date();
    const millisec = date.getTime();
    const id = millisec.toString(36);
    let payload: any = {
      IptorAPI: '1.0',
      method: api,
      id: id,
    };
    if (params) {
      payload.params = params;
    }
    return axios.post(`/aperio/api/service`, payload);
  }

  refreshInterval?: any;

  componentDidMount() {
    let script: any = document.createElement('script');
    let prior: any = document.getElementsByTagName('script')[0];
    script.async = 1;
    let self = this;
    script.onload = script.onreadystatechange = function (_: any, isAbort: boolean) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null;
        // script = undefined;
        if (!isAbort) {
          self.reload();
          self.refreshInterval = setInterval(() => self.reload(), 120000);
        }
      }
    };
    script.src = this.props.dashboard;
    prior.parentNode.insertBefore(script, prior);
  }

  componentDidUpdate(
    prevProps: Readonly<{
      startLoading: Function;
      endLoading: Function;
      quickLaunch: Function;
      quickLinks: Program[];
      user: User;
      dashboard: string;
      locale: LocalizationInfo.Settings;
      flattenedMenu: Record<string, Program>;
    }>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ) {
    if (
      this.props.locale.decimalSeparator !== prevProps.locale.decimalSeparator ||
      this.props.locale.groupSeparator !== prevProps.locale.groupSeparator
    ) {
      this.reload();
    }
  }

  componentWillUnmount() {
    delete (window as any).dashboard;
    if (this.refreshInterval) clearInterval(this.refreshInterval);
  }

  // componentDidUpdate(
  //   prevProps: Readonly<{ startLoading: Function; endLoading: Function; quickLaunch: Function; quickLinks: Program[]; user: User }>,
  //   prevState: Readonly<{}>,
  //   snapshot?: any
  // ) {
  //   if (this.props.user.id !== prevProps.user.id) this.reload();
  // }

  /* response : { common: {data: {sales: 10, service: 20, revenue: 100 }}} //data: 'common.data.sales'
   * splits: ['common', 'data', 'sales']
   * value=response['common'] = {data: {sales, service, revenue}
   * value = value['data'] = {sales, service, revenue}
   * value = value['sales'] = sales = 10
   * */
  getValue(splits: string[]) {
    let value;
    if (splits && splits.length > 0) {
      let splitsArr = [...splits];
      let data: any = this.state.response?.[splitsArr[0]];
      splitsArr.shift();
      splitsArr.forEach((split: string) => {
        data = data?.[split];
      });
      value = data;
    }
    return value;
  }

  getWidget(widget: IWidget) {
    let props: any = {};
    let data: any = {};
    let splits: string[] = [];
    let value: any = '';
    switch (widget.type) {
      case 'single':
        data = widget.data as SimpleValue;
        splits = data.value?.split('.'); //response : { common: data: {sales: 10, service: 20, revenue: 100 } //data: 'common.data.sales'
        const getValueFromSplits = this.getValue(splits);
        if (splits && (!!getValueFromSplits || getValueFromSplits === 0)) {
          value = getValueFromSplits + (data.unit || '');
        } else {
          value = '–'; //special character - dash, not minus
        }
        props = {
          leftIcon: <SquareIcon size="24px">{Icons[widget.icon as keyof typeof Icons]}</SquareIcon>,
          leftValue: widget.title,
          rightValue: value,
        };
        return <SingleValue {...props} />;
      case 'table':
        data = widget.data as TableData;
        splits = data.rows?.split('.');
        value = splits ? this.getValue(splits) : [];
        props = {
          title: widget.title,
          headers: data.headers,
          rows: value || [],
          noDataMessage: widget.options?.noDataMessage,
        };
        return <TableWidget {...props} />;
      case 'quicklinks': //Client only component
        props = {
          flattenedMenu: this.props.flattenedMenu,
          quickLinks: this.props.quickLinks,
          quickLaunch: this.props.quickLaunch,
          startLoading: this.props.startLoading,
          endLoading: this.props.endLoading,
        };
        return <Quicklinks {...props} />;
      case 'custom':
        data = widget.data as SimpleValue;
        splits = data.value?.split('.');
        if (widget.component) {
          props = {
            data: this.getValue(splits) || {},
          };
          return <span dangerouslySetInnerHTML={{ __html: widget.component(props) }}></span>;
        }
        return <>Component definition not present</>;
      case 'donut':
        data = widget.data as SimpleValue;
        splits = data.value?.split('.');
        value = splits ? this.getValue(splits) : {};
        value = value || {};
        props = {
          title: widget.title,
          data: {
            labels: value.labels || [],
            datasets: [
              {
                label: widget.title,
                data: value.data || [],
                backgroundColor: widget.options?.colors?.data,
                cutout: widget.options?.cutout,
              },
            ],
          },
          options: widget.options?.widgetOptions,
        };
        return <DoughtnutWidget {...props} />;
      default:
        return <p>Invalid widget</p>;
    }
  }

  setEditing() {
    // @ts-ignore
    this.setState({ editing: !this.state.editing });
  }

  render() {
    return (
      <Container className="dashboard" fluid>
        {/*{this.state.response && (*/}
        {/*  <div className="dashboard-container">*/}
        {/*    {(window as any).dashboard?.(this.props).widgets.map((widget: IWidget) => {*/}
        {/*      let style = {*/}
        {/*        gridColumnStart: widget.placement.x + 1,*/}
        {/*        gridColumnEnd: widget.placement.x + widget.placement.width + 1,*/}
        {/*        gridRowStart: widget.placement.y + 1,*/}
        {/*        gridRowEnd: widget.placement.y + widget.placement.height + 1*/}
        {/*      };*/}
        {/*      return (*/}
        {/*        <div className={'card'} style={style}>*/}
        {/*          <Widget height="100%" className="up low-pad">*/}
        {/*            {this.getWidget(widget)}*/}
        {/*          </Widget>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*<div className="dashboard-container">*/}
        {/*  <ContentEditor editing={this.state.editing}/>*/}
        {/*</div>*/}
        <Toolbar
          editing={this.state.editing}
          setEditing={this.setEditing}
          startLoading={this.props.startLoading}
          endLoading={this.props.endLoading}
          quickLaunch={this.props.quickLaunch}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ desktop, settings }: any) => {
  let props: {
    quickLinks: Program[];
    user: User;
    dashboard: string;
  } = {
    quickLinks: settings.quickLinks,
    dashboard: desktop.dashboard,
    user: {
      id: desktop.settings?.regionals?.user[0].$.userId,
      username: desktop.settings?.regionals?.user[0].$.userId,
      name: desktop.settings?.fullName,
    },
  };
  return props;
};

export const Dashboard = connect(mapStateToProps, { quickLaunch: WindowManger.Launch })(DashboardComponent);

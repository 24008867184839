import { PropsWithChildren, useState, useEffect, useMemo, useCallback } from 'react';
import { useNotes } from './NotesProvider';
import { YourNoteEditor } from './Editor';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  ExpandIcon,
  Ellipsis,
  Check,
  Trash2,
  X,
  ChevronUp,
  ChevronDown,
  PlusCircle,
  SquareArrowDownRight
} from 'lucide-react';
import { Badge, OverlayTrigger, Tooltip, Dropdown, Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { SquareIcon, Icons } from '../../components/SquareIcon';
import { Localization } from '../../framework/localization/Localization';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';

export const AutoSortNotes = () => {
  const { sort, setSort } = useNotes();
  return (
    <div
      style={{
        position: 'absolute',
        right: 40,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <small>Auto sorting</small>
      <div className='dark-mode' style={{ marginLeft: 20, height: 18 }}>
        <label className='switch'>
          <input type='checkbox' checked={sort} onChange={() => setSort(!sort)} /* onChange={isSettingsEnabled} */ />
          <span className='slider round' />
        </label>
      </div>
    </div>
  );
};

export const AddNotebutton = () => {
  const { fieldValue: val, selectedLabels, editable, openNote, setNotes, setFullscreen, setEditMode } = useNotes();
  const blankNote: any = {
    id: 'new',
    elemLabels: [],
    envLabels: JSON.parse(sessionStorage.getItem('company') || '{}')?.id
      ? [JSON.parse(sessionStorage.getItem('company') || '{}')?.id]
      : [],
    text: '',
    summary: '',
    fieldValue: '',
    tags: []
  };

  return (
    editable && (
      <OverlayTrigger
        placement='top'
        delay={50}
        overlay={
          <Tooltip className='custom' id={`tooltip-help`}>
            {selectedLabels?.length > 0 ? 'New note' : 'You need to add atleast one label before proceeding'}
          </Tooltip>
        }
        rootClose
      >
        <Button
          variant='flat'
          data-event='ignore'
          disabled={!selectedLabels?.length}
          className={`notes-add`}
          onClick={() => {
            if (selectedLabels?.length > 0) {
              const newNote = { ...blankNote, elemLabels: selectedLabels, fieldValue: val ?? '' };
              openNote(newNote);
              setNotes((notes: any) => [...notes, newNote]);
              setFullscreen(true);
              setEditMode(true);
            } else {
              return false;
            }
          }}
        >
          <SquareIcon size={'20px'} className={'icon-primary-nav'}>
            {Icons.Add}
          </SquareIcon>
        </Button>
      </OverlayTrigger>
    )
  );
};

export const Notes = ({ companies, children }: PropsWithChildren<any>) => {
  const {
    formKey,
    connectionStatus,
    notes,
    setNotes,
    focussedElement: foc,
    setFocussedElement: setFoc,
    selectedLabels,
    fieldValue: val,
    setFieldValue: setVal,
    displayValue,
    setDisplayValue,
    sort,
    setSort,
    elemLabels,
    setElemLabels,
    loading,
    setLoading,
    flattenedLabels,
    setEnabled,
    filterNotes,
    readonly,
    editable,
    editMode,
    setEditMode,
    allLabels,
    openNote,
    closeNote,
    selectedNote,
    updateNote,
    _fullscreen,
    setFullscreen
  } = useNotes();

  const [dragging, setDragging] = useState(false);
  const [noteProps, setNoteProps] = useState({ top: 200, bottom: 200, left: 200, right: 200 });
  const [addModal, setAddModal] = useState(false);
  const [newLabel, setNewLabel] = useState('');

  const updateLabels = useCallback(
    (label: string, add: boolean = false) => {
      let payload = { ...elemLabels };
      const newLabels = [...(selectedLabels ?? []).filter((l: string) => l !== label), ...(add ? [label] : [])];
      if (formKey === foc) {
        const old = { ...elemLabels };
        payload = { ...old, elemLabels: newLabels };
      } else {
        if (!elemLabels.fields?.find(({ fieldKey }: any) => fieldKey === foc)) {
          if (!elemLabels.fields) elemLabels.fields = [];
          elemLabels.fields.push({
            fieldKey: foc,
            elemLabels: newLabels
          });
        }
        payload = {
          ...elemLabels,
          fields:
            elemLabels.fields?.map(({ fieldKey, elemLabels }: any) => {
              if (fieldKey === foc) {
                return {
                  fieldKey,
                  elemLabels: newLabels
                };
              }
              return { fieldKey, elemLabels };
            }) ?? []
        };
      }
      setLoading(true);
      axios
        .put(`/notes/elementLabels/${formKey}`, payload)
        .then((res: AxiosResponse) => {
          // console.log(res.data);
          // setElemLabels(res.data);
          setLoading(false);
          setElemLabels(res.data);
        })
        .catch((e: AxiosError) => {
          setLoading(false);
          console.error(e);
          setElemLabels(payload);
        });
    },
    [formKey, foc, selectedLabels, elemLabels]
  );

  const addLabel = useCallback(
    (label: string) => {
      if (selectedNote?.id) {
        const newLabels = [...(selectedNote.elemLabels ?? []), label];
        let _note = { ...selectedNote };
        // delete _note.id;
        _note.elemLabels = newLabels;
        if (_note.text === null) _note.text = _note.summary;
        setNotes((notes: any) => [
          ...notes.map((n: any) => (n.id !== selectedNote.id ? n : { ..._note, loading: selectedNote?.id !== 'new' }))
        ]);
        setAddModal(false);
        if (selectedNote?.id !== 'new')
          axios
            .put(`/notes/notes/${selectedNote.id}`, _note)
            .then((res) =>
              setNotes((notes: any) => [...notes.map((n: any) => (n.id !== selectedNote.id ? n : { ...res.data }))])
            )
            .catch((e) => console.error(e));
        // .finally(() => setAddModal(false));
      } else {
        updateLabels(label, true);
        setAddModal(false);
      }
      setNewLabel('');
    },
    [selectedNote]
  );

  return (
    <div className='notes-wrapper' data-event='ignore'>
      <Modal
        show={addModal}
        onHide={() => setAddModal(false)}
        size='lg'
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>Add label</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'visible' }}>
          <Form.Group>
            <Typeahead
              id='add-label'
              labelKey='label'
              allowNew
              renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
                <Hint>
                  <Form.Control
                    {...inputProps}
                    data-event='ignore'
                    ref={(node: any) => {
                      inputRef(node);
                      referenceElementRef(node);
                    }}
                  />
                </Hint>
              )}
              onKeyDown={(e) => {
                if (e.key.toLowerCase().includes('enter')) {
                  if (!!e.currentTarget.value.trim() && !newLabel) {
                    setNewLabel(e.currentTarget.value);
                    addLabel(e.currentTarget.value);
                  } else {
                    addLabel(newLabel);
                  }
                }
              }}
              onBlur={(e) => {
                if (!!e.target.value.trim() && !newLabel) setNewLabel(e.target.value);
              }}
              onChange={(opts) => {
                // console.log(opts);
                // @ts-ignore
                setNewLabel(opts[0]?.label?.toString());
              }}
              options={allLabels.map((a: string) => ({ label: a }))}
              placeholder='Enter new label'
              // selected={[newLabel]}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            data-event='ignore'
            onClick={() => {
              addLabel(newLabel);
            }}
            className='truncate btn btn-outline-dark btn-sm col-sm-2'
          >
            Add
          </button>
          <button
            data-event='ignore'
            onClick={() => setAddModal(false)}
            className='truncate btn btn-outline-dark btn-sm col-sm-2'
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className={`element-information ${loading ? 'shimmer' : ''}`}>
        {editable && (
          <>
            <p>
              <strong>Form labels</strong>{' '}
              {(elemLabels?.elemLabels ?? []).map((label: string) => (
                <Badge className='badge-info note-value' style={{ marginRight: 2 }}>
                  {label}{' '}
                  <OverlayTrigger
                    placement='top'
                    delay={50}
                    overlay={
                      <Tooltip className='custom' id={`tooltip-help`}>
                        Remove label
                      </Tooltip>
                    }
                    rootClose
                  >
                    <span className='pointer' onClick={() => updateLabels(label)}>
                      <X className='x' size={16} />
                    </span>
                  </OverlayTrigger>
                </Badge>
              ))}
              {foc === formKey && (
                <OverlayTrigger
                  placement='top'
                  delay={50}
                  overlay={
                    <Tooltip className='custom' id={`tooltip-help`}>
                      Add label
                    </Tooltip>
                  }
                  rootClose
                >
                  <span
                    className='pointer'
                    onClick={() => {
                      closeNote();
                      setAddModal(true);
                    }}
                  >
                    <PlusCircle size={16} />
                  </span>
                </OverlayTrigger>
              )}
            </p>
            {foc !== formKey && (
              <p>
                <strong>{foc.includes('|') ? 'Column' : 'Field'} labels:</strong>{' '}
                {selectedLabels.map((label: string) => (
                  <Badge className='badge-info note-value' style={{ marginRight: 2 }}>
                    {label}{' '}
                    <OverlayTrigger
                      placement='top'
                      delay={50}
                      overlay={
                        <Tooltip className='custom' id={`tooltip-help`}>
                          Remove label
                        </Tooltip>
                      }
                      rootClose
                    >
                      <span className='pointer' onClick={() => updateLabels(label)}>
                        <X className='x' size={16} />
                      </span>
                    </OverlayTrigger>
                  </Badge>
                ))}
                <OverlayTrigger
                  placement='top'
                  delay={50}
                  overlay={
                    <Tooltip className='custom' id={`tooltip-help`}>
                      Add label
                    </Tooltip>
                  }
                  rootClose
                >
                  <span
                    className='pointer'
                    onClick={() => {
                      closeNote();
                      setAddModal(true);
                    }}
                  >
                    <PlusCircle size={16} />
                  </span>
                </OverlayTrigger>
              </p>
            )}
            <p hidden={foc === formKey}>
              <strong>Field value:</strong> {val && <Badge className='badge-success'>{displayValue}</Badge>}
            </p>
          </>
        )}
        <div className='search-bar'>
          <InputGroup>
            <Form.Control
              onChange={(e) => filterNotes(e.target.value)}
              data-event='ignore'
              autoComplete='off'
              // value={this.state.filter}
              id='search-input'
              // onChange={this.initiateSearch}
              placeholder={'Search...'}
              type='text'
            />
          </InputGroup>
        </div>
      </div>
      <div className='notes'>
        {notes
          // ?.filter((note: any) => new RegExp(`.*${searchValue}.*`).test(note?.text))
          .map((note: any) => {
            const open = note.id === selectedNote.id;
            const highlight = selectedLabels.some((label: string) => note.elemLabels.includes(label));
            const fullscreen = open && _fullscreen;
            const _note = { ...note };
            return (
              <div
                data-event='ignore'
                className={`note-overlay-fullscreen ${fullscreen ? 'fullscreen' : ''}`}
                tabIndex={1}
              >
                <div
                  key={note.id}
                  style={fullscreen ? { ...noteProps } : {}}
                  className={`note ${highlight ? 'highlight' : ''} ${open ? 'open' : 'closed'} ${
                    fullscreen ? 'fullscreen' : ''
                  } ${note.loading ? 'shimmer' : ''}`}
                >
                  <div
                    className='note-toolbar'
                    onMouseDown={(e) => {
                      if (fullscreen) {
                        // console.log('started dragging');
                        setDragging(true);
                        document.body.style.userSelect = 'none';
                        document.body.className = 'dragging';
                        const handleDragging = (e: any) => {
                          // console.log('dragging handle', e);
                          setNoteProps(({ top, bottom, left, right }) => {
                            const newInset: any = {
                              top: top + e.movementY,
                              bottom: bottom - e.movementY,
                              left: left + e.movementX,
                              right: right - e.movementX
                            };
                            const shouldApply = Object.keys(newInset).every((side) => newInset[side] > 0);
                            return shouldApply ? newInset : { top, bottom, left, right };
                          });
                        };
                        const stopDragging = () => {
                          setDragging(false);
                          document.body.style.userSelect = 'unset';
                          document.body.className = '';
                          document.removeEventListener('mousemove', handleDragging);
                          document.removeEventListener('mouseup', stopDragging);
                        };
                        document.addEventListener('mousemove', handleDragging);
                        document.addEventListener('mouseup', stopDragging);
                      }
                    }}
                  >
                    <span>
                      {fullscreen && <p className='note-header'>{note.id === 'new' ? 'New note' : 'Edit note'}</p>}
                      {(!fullscreen || !editable) &&
                        note.elemLabels.map((label: string) => (
                          <Badge className='badge-info note-value' style={{ margin: 2, marginLeft: 4, marginRight: 0 }}>
                            {label}
                          </Badge>
                        ))}
                      {(!fullscreen || !editable) && note.fieldValue && (
                        <Badge
                          className='badge-success note-value'
                          style={{ margin: 2, marginLeft: 4, marginRight: 0 }}
                        >
                          {displayValue}
                        </Badge>
                      )}
                    </span>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {!fullscreen && !editable && (
                        <OverlayTrigger
                          placement='top'
                          delay={50}
                          overlay={
                            <Tooltip className='custom' id={`tooltip-help`}>
                              Fullscreen
                            </Tooltip>
                          }
                          rootClose
                        >
                          <span
                            className='note-toolbar-button'
                            onClick={() => {
                              setFullscreen(true);
                              setEditMode(false);
                              openNote(note);
                            }}
                          >
                            <ExpandIcon size={14} />
                          </span>
                        </OverlayTrigger>
                      )}

                      {!fullscreen && editable && (
                        <Dropdown className='note-toolbar-button'>
                          <Dropdown.Toggle className='no-padding' variant='transparent'>
                            <Ellipsis size={20} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setFullscreen(true);
                                setEditMode(false);
                                openNote(note);
                              }}
                            >
                              {editable ? 'Edit' : 'Fullscreen'}
                            </Dropdown.Item>
                            {editable && (
                              <Dropdown.Item
                                onClick={() => {
                                  if (note.id === 'new') {
                                    setNotes((notes: any) => [...notes.filter((n: any) => n.id !== note.id)]);
                                  } else {
                                    axios
                                      .delete(`/notes/notes/${note.id}`)
                                      .then((res) =>
                                        setNotes((notes: any) => [...notes.filter((n: any) => n.id !== note.id)])
                                      )
                                      .catch((e) => console.error(e));
                                  }
                                  closeNote();
                                  setFullscreen(false);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      {fullscreen && editable && (
                        <OverlayTrigger
                          placement='top'
                          delay={50}
                          overlay={
                            <Tooltip className='custom' id={`tooltip-help`}>
                              Save note
                            </Tooltip>
                          }
                          rootClose
                        >
                          <span
                            className='note-toolbar-button'
                            onClick={() => {
                              let _note = { ...note };
                              setNotes((notes: any) => [
                                ...notes.map((n: any) => (n.id !== note.id ? n : { ...n, loading: true }))
                              ]);
                              if (note.id === 'new') {
                                delete _note.id;
                                axios
                                  .post('/notes/notes', _note)
                                  .then((res) =>
                                    setNotes((notes: any) => [
                                      ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                    ])
                                  )
                                  .catch((e) => console.error(e))
                                  .finally(() => {
                                    closeNote();
                                    setFullscreen(false);
                                  });
                              } else {
                                axios
                                  .put(`/notes/notes/${note.id}`, _note)
                                  .then((res) =>
                                    setNotes((notes: any) => [
                                      ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                    ])
                                  )
                                  .catch((e) => console.error(e))
                                  .finally(() => {
                                    closeNote();
                                    setFullscreen(false);
                                  });
                              }
                            }}
                          >
                            <Check size={18} />
                          </span>
                        </OverlayTrigger>
                      )}

                      {fullscreen && editable && (
                        <OverlayTrigger
                          placement='top'
                          delay={50}
                          overlay={
                            <Tooltip className='custom' id={`tooltip-help`}>
                              Delete
                            </Tooltip>
                          }
                          rootClose
                        >
                          <span
                            className='note-toolbar-button'
                            onClick={() => {
                              if (note.id === 'new') {
                                setNotes((notes: any) => [...notes.filter((n: any) => n.id !== note.id)]);
                              } else {
                                axios
                                  .delete(`/notes/notes/${note.id}`)
                                  .then((res) =>
                                    setNotes((notes: any) => [...notes.filter((n: any) => n.id !== note.id)])
                                  )
                                  .catch((e) => console.error(e));
                              }
                              closeNote();
                              setFullscreen(false);
                            }}
                          >
                            <Trash2 size={18} />
                          </span>
                        </OverlayTrigger>
                      )}
                      <OverlayTrigger
                        placement='top'
                        delay={50}
                        overlay={
                          <Tooltip className='custom' id={`tooltip-help`}>
                            {open ? 'Close' : 'Open'}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <span
                          className='note-toolbar-button'
                          onClick={() => {
                            if (open) closeNote();
                            else openNote(note);
                            if (fullscreen) setFullscreen(false);
                            if (note.id === 'new') {
                              setNotes((notes: any) => [...notes.filter((note: any) => note.id !== 'new')]);
                            }
                          }}
                        >
                          {fullscreen ? <X size={20} /> : open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </span>
                      </OverlayTrigger>
                    </span>
                  </div>
                  {fullscreen && editable && (
                    <div className='element-information-note'>
                      <Row>
                        <Col style={{ padding: '0 8px', textAlign: 'right' }} md={3}>
                          <p>Companies</p>
                        </Col>
                        <Col
                          style={{
                            padding: '0 8px',
                            textAlign: 'left',
                            display: 'flex',
                            gap: 4,
                            alignItems: 'center',
                            flexDirection: 'row'
                          }}
                          md={9}
                        >
                          {note.envLabels?.map((label: string) => (
                            <Badge className='badge-info note-value'>
                              {label}{' '}
                              {note?.envLabels?.length > 1 && (
                                <OverlayTrigger
                                  placement='top'
                                  delay={50}
                                  overlay={
                                    <Tooltip className='custom' id={`tooltip-help`}>
                                      Remove label
                                    </Tooltip>
                                  }
                                  rootClose
                                >
                                  <span
                                    className='pointer'
                                    onClick={() => {
                                      // let _note = { ...note, fieldValue: '' };
                                      let _note = { ...note };
                                      _note.envLabels = _note.envLabels.filter((lab: string) => lab !== label);
                                      // if (_note.text === null) _note.text = _note.summary;
                                      setNotes((notes: any) => [
                                        ...notes.map((n: any) =>
                                          n.id !== note.id
                                            ? n
                                            : {
                                                ...n,
                                                elemLabels: n.elemLabels.filter((lab: string) => lab !== label),
                                                loading: note.id !== 'new'
                                              }
                                        )
                                      ]);
                                      if (note.id !== 'new')
                                        axios
                                          .put(`/notes/notes/${note.id}`, _note)
                                          .then((res) =>
                                            setNotes((notes: any) => [
                                              ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                            ])
                                          )
                                          .catch((e) => console.error(e));
                                    }}
                                  >
                                    <X className='x' size={12} />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </Badge>
                          ))}
                          <Dropdown className='note-toolbar-button'>
                            <Dropdown.Toggle className='no-padding' variant='transparent'>
                              <OverlayTrigger
                                placement='top'
                                delay={50}
                                overlay={
                                  <Tooltip className='custom' id={`tooltip-help`}>
                                    Add Company
                                  </Tooltip>
                                }
                                rootClose
                              >
                                <span className='pointer note-value'>
                                  <PlusCircle size={16} />
                                </span>
                              </OverlayTrigger>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {companies?.map((c: { $: { code: string; name: string } }) => (
                                <Dropdown.Item
                                  onClick={() => {
                                    // let _note = { ...note, fieldValue: '' };
                                    let _note = { ...note };
                                    _note.envLabels = [..._note.envLabels, c.$.code];
                                    // if (_note.text === null) _note.text = _note.summary;
                                    setNotes((notes: any) => [
                                      ...notes.map((n: any) =>
                                        n.id !== note.id
                                          ? n
                                          : {
                                              ...n,
                                              envLabels: _note.envLabels,
                                              loading: note.id !== 'new'
                                            }
                                      )
                                    ]);
                                    if (note.id !== 'new')
                                      axios
                                        .put(`/notes/notes/${note.id}`, _note)
                                        .then((res) =>
                                          setNotes((notes: any) => [
                                            ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                          ])
                                        )
                                        .catch((e) => console.error(e));
                                  }}
                                  value={c.$.code}
                                >
                                  {c.$.code} - {c.$.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col style={{ padding: '0 8px', textAlign: 'right' }} md={3}>
                          <p>Labels</p>
                        </Col>
                        <Col
                          style={{
                            padding: '0 8px',
                            textAlign: 'left',
                            display: 'flex',
                            gap: 4,
                            alignItems: 'center',
                            flexDirection: 'row'
                          }}
                          md={9}
                        >
                          {note.elemLabels?.map((label: string) => (
                            <Badge className='badge-info note-value'>
                              {label}{' '}
                              {note.elemLabels?.length > 1 && (
                                <OverlayTrigger
                                  placement='top'
                                  delay={50}
                                  overlay={
                                    <Tooltip className='custom' id={`tooltip-help`}>
                                      Remove label
                                    </Tooltip>
                                  }
                                  rootClose
                                >
                                  <span
                                    className='pointer'
                                    onClick={() => {
                                      // let _note = { ...note, fieldValue: '' };
                                      let _note = { ...note };
                                      _note.elemLabels = _note.elemLabels.filter((lab: string) => lab !== label);
                                      // if (_note.text === null) _note.text = _note.summary;
                                      setNotes((notes: any) => [
                                        ...notes.map((n: any) =>
                                          n.id !== note.id
                                            ? n
                                            : {
                                                ...n,
                                                elemLabels: n.elemLabels.filter((lab: string) => lab !== label),
                                                loading: note.id !== 'new'
                                              }
                                        )
                                      ]);
                                      if (note.id !== 'new')
                                        axios
                                          .put(`/notes/notes/${note.id}`, _note)
                                          .then((res) =>
                                            setNotes((notes: any) => [
                                              ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                            ])
                                          )
                                          .catch((e) => console.error(e));
                                    }}
                                  >
                                    <X className='x' size={12} />
                                  </span>
                                </OverlayTrigger>
                              )}
                            </Badge>
                          ))}
                          <OverlayTrigger
                            placement='top'
                            delay={50}
                            overlay={
                              <Tooltip className='custom' id={`tooltip-help`}>
                                Add label
                              </Tooltip>
                            }
                            rootClose
                          >
                            <span
                              className='pointer note-value'
                              style={{ display: 'flex' }}
                              onClick={() => {
                                setAddModal(true);
                              }}
                            >
                              <PlusCircle size={16} />
                            </span>
                          </OverlayTrigger>
                        </Col>
                        <Col style={{ padding: '0 8px', textAlign: 'right' }} md={3}>
                          <p>Value</p>
                        </Col>
                        <Col
                          style={{
                            padding: '0 8px',
                            textAlign: 'left',
                            display: 'flex',
                            gap: 4,
                            alignItems: 'center',
                            flexDirection: 'row'
                          }}
                          md={9}
                        >
                          {note.fieldValue && (
                            <Badge className='badge-success note-value'>
                              {displayValue}
                              <OverlayTrigger
                                placement='top'
                                delay={50}
                                overlay={
                                  <Tooltip className='custom' id={`tooltip-help`}>
                                    Remove Value
                                  </Tooltip>
                                }
                                rootClose
                              >
                                <span
                                  className='pointer'
                                  onClick={() => {
                                    // const newLabels = [...(selectedLabels ?? []).filter((l: string) => l !== label), ...(add ? [label] : [])]
                                    let _note = { ...note, fieldValue: '' };
                                    if (_note.text === null) _note.text = _note.summary;
                                    setNotes((notes: any) => [
                                      ...notes.map((n: any) =>
                                        n.id !== note.id ? n : { ..._note, loading: note.id !== 'new' }
                                      )
                                    ]);
                                    if (note.id !== 'new')
                                      axios
                                        .put(`/notes/notes/${note.id}`, _note)
                                        .then((res) =>
                                          setNotes((notes: any) => [
                                            ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                                          ])
                                        )
                                        .catch((e) => console.error(e));
                                  }}
                                >
                                  <X className='x' size={12} />
                                </span>
                              </OverlayTrigger>
                            </Badge>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/* {fullscreen ? ( */}
                  <div className='note-container' style={{ flexGrow: 1 }}>
                    <YourNoteEditor
                      note={note}
                      setLoader={(load: boolean) => {
                        setNotes((notes: any) => [
                          ...notes.map((n: any) => (n.id !== note.id ? n : { ...n, loading: load }))
                        ]);
                      }}
                      saveNote={() => {
                        let _note = { ...note };
                        setNotes((notes: any) => [
                          ...notes.map((n: any) => (n.id !== note.id ? n : { ...n, loading: true }))
                        ]);
                        if (note.id === 'new') {
                          delete _note.id;
                          axios
                            .post('/notes/notes', _note)
                            .then((res) =>
                              setNotes((notes: any) => [
                                ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                              ])
                            )
                            .catch((e) => console.error(e));
                        } else {
                          axios
                            .put(`/notes/notes/${note.id}`, _note)
                            .then((res) =>
                              setNotes((notes: any) => [
                                ...notes.map((n: any) => (n.id !== note.id ? n : { ...res.data }))
                              ])
                            )
                            .catch((e) => console.error(e));
                        }
                      }}
                      setNote={(value: string) => {
                        setNotes((notes: any) => [
                          ...notes.map((n: any) => (n.id !== note.id ? n : { ...note, text: value }))
                        ]);
                      }}
                      fullscreen={fullscreen}
                      open={open}
                      readOnly={!fullscreen || !editable}
                    />
                  </div>
                  {fullscreen && (
                    <div className='resize-container'>
                      <span
                        className='note-resizer'
                        onMouseDown={(e) => {
                          // console.log('started resizing');
                          document.body.style.userSelect = 'none';
                          const handleResize = (e: any) => {
                            let noteEl = document.querySelector('.note.fullscreen');
                            let clHeight = noteEl?.clientHeight ?? 0;
                            let clWidth = noteEl?.clientWidth ?? 0;
                            setNoteProps(({ top, bottom, left, right }) => {
                              const newInset: any = {
                                top: top,
                                bottom: e.movementY < 0 && clHeight + e.movementY < 300 ? bottom : bottom - e.movementY,
                                left: left,
                                right: e.movementX < 0 && clWidth + e.movementX < 500 ? right : right - e.movementX
                              };
                              const shouldApply = Object.keys(newInset).every((key) => newInset[key] >= 0);
                              return shouldApply ? newInset : { top, bottom, left, right };
                            });
                          };
                          const stopResizing = () => {
                            document.body.style.userSelect = 'unset';
                            document.removeEventListener('mousemove', handleResize);
                            document.removeEventListener('mouseup', stopResizing);
                          };
                          document.addEventListener('mousemove', handleResize);
                          document.addEventListener('mouseup', stopResizing);
                        }}
                      >
                        <SquareArrowDownRight size={14} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

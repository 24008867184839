import { Theme } from '@mui/material';
export const Tooltip = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    tooltip: ({ theme }: { theme: Theme }) => ({
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: '#D6EDEE',
            color: theme.palette.primary.main,
          },
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: '#FFF7EF',
            color: theme.palette.secondary['500'],
          },
        },
        {
          props: { color: 'error' },
          style: {
            backgroundColor: '#FCEBEE',
            color: theme.palette.tertiary.darkPink,
          },
        },
      ],
    }),
    arrow: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: '#D6EDEE',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            color: '#FFF7EF',
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: '#FCEBEE',
          },
        },
      ],
    },
  },
};

import React, { FunctionComponent, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ColumnVisibilityTable } from './ColumnVisibilityTable';
import { useIptorTranslation } from 'src/framework/translations';
import { IconButton } from 'src/components/IconButton';
/*
 * isShown: if true, column is visible
 * modalTitle: Title of Dialog box(pop up)
 */

export type ColumnSettings = {
  id: string;
  name: string;
  hide: boolean;
};

export type ColumnVisibilityProps = {
  className?: string;
  isShown: boolean;
  hide: () => void;
  modalTitle?: string;
  columnsSettings: ColumnSettings[];
  setHCols: Function;
  hCols?: string[];
};

export const ColumnVisibility: FunctionComponent<ColumnVisibilityProps> = ({
  isShown,
  hide,
  modalTitle,
  columnsSettings,
  setHCols,
  hCols = [],
}: ColumnVisibilityProps) => {
  const [show, setShow] = useState(false);
  const { t } = useIptorTranslation();
  const columnsHeader = useMemo(
    () => [
      {
        Header: ' ',
        isVisible: false,
        hideHeader: false,
        columns: [
          {
            Header: t('common:EDIT_COLUMNS_Column'),
            accessor: 'column',
          },
          {
            Header: t('common:EDIT_COLUMNS_Enabled'),
            accessor: 'enabled',
          },
        ],
      },
    ],
    [],
  );

  const onClose = () => {
    hide();
  };

  const invertAll = () => {
    const newList = columnsSettings.filter((column: ColumnSettings) => !column.hide).map((c: ColumnSettings) => c.id);
    setHCols(newList);
  };

  const handleSelectAll = () => {
    setHCols([]);
  };
  const handleUnselectAll = () => {
    const newList = columnsSettings?.map((column: ColumnSettings) => column.id);
    setHCols(newList);
  };

  const handleInvert = () => {
    invertAll();
  };

  const handleReset = () => {
    setHCols([]);
    setShow(false);
  };

  const toggleHidden = (id: string) => {
    setHCols((cols: string[]) => {
      if (cols.includes(id)) return [...cols.filter((c) => c !== id)];
      else return [...cols, id];
    });
  };

  return (
    <div>
      <Modal
        data-event={'ignore'}
        animation={false}
        className={'right-overlay settings-modal'}
        show={isShown}
        onHide={onClose}
        backdrop={true}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <span>
              <h1>{modalTitle}</h1>
            </span>
          </Modal.Title>
          <IconButton icon="xCircle" color="default" onClick={onClose} variant="text" />
        </Modal.Header>
        <Modal.Body>
          <ColumnVisibilityTable
            toggleHidden={toggleHidden}
            columns={columnsHeader}
            data={columnsSettings}
            handleReset={handleReset}
            handleInvert={handleInvert}
            handleSelectAll={handleSelectAll}
            handleUnselectAll={handleUnselectAll}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

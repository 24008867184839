import { Theme } from '@mui/material';
export const Link = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.info.main,
      textDecoration: 'none',
      cursor: 'pointer',
    }),
  },
};

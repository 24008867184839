import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { Dispatch } from 'redux';

export const initialState = {
  loading: false,
  hasErrors: false,
  shipmentHeaders: {}
};

// A slice for shipmentHeaders with our three reducers
const shipmentHeadersSlice = createSlice({
  name: 'shipmentHeaders',
  initialState,
  reducers: {
    getshipmentHeaders: (state) => {
      state.loading = true;
    },
    getshipmentHeadersSuccess: (state, { payload }) => {
      state.shipmentHeaders = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getshipmentHeadersFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    }
  }
});

// Three actions generated from the slice
export const {
  getshipmentHeaders,
  getshipmentHeadersSuccess,
  getshipmentHeadersFailure
} = shipmentHeadersSlice.actions;

// A selector
export const shipmentHeadersSelector = (state: any) => state;

// The reducer
export default shipmentHeadersSlice.reducer;

// Asynchronous thunk action
export function fetchshipmentHeaders(warehouse: string, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getshipmentHeaders());
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentHeaders.get',
          params: { warehouse: warehouse }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do somthing
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from fetchshipmentHeaders ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
      // alert("Failed")
    }
  };
}
export function fetchshipmentHeadersStartDate(warehouse: string, fromDate: number, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getshipmentHeaders());
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentHeaders.get',
          params: { warehouse: warehouse, dateFrom: fromDate }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do somthing
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from fetchshipmentHeadersStartDate ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
      // alert("Failed")
    }
  };
}
export function fetchshipmentHeadersEndDate(warehouse: string, fromDate: number, toDate: number, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getshipmentHeaders());
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentHeaders.get',
          params: { warehouse: warehouse, dateFrom: fromDate, dateTo: toDate }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do somthing
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from fetchshipmentHeadersEndDate ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
      // alert("Failed")
    }
  };
}

export function closeshipmentHeadersShipment(params: any) {
  return async (dispatch: Dispatch) => {
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipment.close',
          params: { ...params, printerQueue: '' }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do something
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from closeshipmentHeadersShipment ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
    }
  };
}

export function printshipmentHeadersPickList(params: any) {
  return async (dispatch: Dispatch) => {
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentCreatePickList.print',
          params: {
            ...params,
            printerQueue: '',
            consolPrinterQueue: ''
          }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do something
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from printshipmentHeadersPickList ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
    }
  };
}

export function printshipmentHeadersDocuments(params: any) {
  return async (dispatch: Dispatch) => {
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentShippingDocuments.print',
          params: { ...params }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do something
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from printshipmentHeadersDocuments ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
    }
  };
}

export function printshipmentHeadersTransportNote(params: any) {
  return async (dispatch: Dispatch) => {
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentTransportNote.print',
          params: { ...params, printerQueue: '' }
        }
      })
        .then((result) => {
          dispatch(getshipmentHeadersSuccess(result));
        })
        .catch((err) => {
          // Do something
          dispatch(getshipmentHeadersFailure());
          console.error(`Error from printshipmentHeadersTransportNote ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentHeadersFailure());
    }
  };
}

import { ValueFormatterExtension } from './ValueFormatterExtension';

export class PositiveIntegerWithZerosFormatter implements ValueFormatterExtension {
  in(value: string) {
    let convertedValue: bigint;
    try {
      convertedValue = BigInt(value);
    } catch {
      return 'NaN';
    }
    if (convertedValue === BigInt(0)) return '0';
    if (convertedValue < 0) convertedValue = convertedValue * BigInt(-1);
    value = convertedValue.toString();
    return value;
  }

  out(value: string, limit: number) {
    if (value.trim().length == 0) {
      while (value.length < limit) {
        value = `${value}0`;
      }
    }
    return value;
  }
}

import { createContext, CSSProperties, Dispatch, FC, PropsWithChildren, ReactElement, SetStateAction } from 'react';
import { TableProps } from '@mui/material';
import { ColumnSettings } from './components/TableSettings/ColumnVisibilityTable';

export const TableContext = createContext<{
  showFilter: boolean;
  setShowFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  filters: Filters;
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>;
  showSettings: boolean;
  setShowSettings?: React.Dispatch<React.SetStateAction<boolean>>;
  columnSettings: ColumnSettings[];
  setHCols?: React.Dispatch<React.SetStateAction<string[]>>;
  hCols: string[];
}>({
  filters: [],
  showFilter: false,
  showSettings: false,
  columnSettings: [],
  hCols: [],
});

export enum SortType {
  NONE = 'none',
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum ColumnAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
  INHERIT = 'inherit',
}

export type TableRowValues = string | number | boolean | any; //TODO: Add more specific types and remove any

export type TableRowType = Record<string, TableRowValues> & RowHighlightInternalState;

export type TableCellRendererProps = {
  row: TableRowType;
  col: TableColumn;
  value: TableRowValues;
  setHighlightStateForRow: ({ rowHighlightType, rowHighlightMessage }: RowHighlightState) => void;
  setColumnValueForRow: (values: { column: string; value: TableRowValues }[]) => void;
};

// export type TableFilterRendererProps = {
//   col: TableColumn;
// };

export type TableColumn = {
  display: string;
  key: string;
  columnAlignment?: ColumnAlignment;
  sort?: SortType;
  dataType?: 'string' | 'date' | 'number' | 'time';
  disableFilter?: boolean;
  disableSort?: boolean;
  cellRenderer?: FC<PropsWithChildren<TableCellRendererProps>>;
  // filterRenderer?: FC<PropsWithChildren<TableFilterRendererProps>>;
};

export type InternalTableRef = {
  setSelected: Dispatch<SetStateAction<Record<string, any>[]>>;
  rows: TableRowType[];
  selectedRows: TableRowType[];
  page: number;
  rowsPerPage: number;
  resetToFirstPage: () => void;
  deselectRows: ({ row }: { row: TableRowType }) => void;
  filter: (c: string, v: FilterValueType, resetToFirstPage?: boolean) => void;
  sort: (c: string, o: SortType) => void;
};

export type TableRef = Omit<InternalTableRef, 'setSelected' | 'loadState'>;

export type CommonTableProps = Pick<TableProps, 'className'> & {
  tableID?: string;
  selectable?: 'none' | 'single' | 'multiple';
  columns: TableColumn[];
  rows: TableRowType[];
  pageSize?: PageSize;
  additionalFilters: ReactElement;
  additionalActions: ReactElement;
  disableActionBar?: boolean;
  maxHeight?: CSSProperties['maxHeight'];
  height?: number | 'auto' | 'fit-content';
  loading?: boolean;
  onSelectedRowsChange?: ({ rows }: { rows: TableRowType[] }) => void;
  onRowsChange?: ({ rows }: { rows: TableRowType[] }) => void;
  onPageChange?: ({ pageSize, page }: { pageSize: number; page: number }) => void;
  onSort?: ({ column, order }: { column: string; order: SortType }) => void;
  onFilter?: (filters: Filters) => void;
  onLoad?: (
    page: number,
    pageSize: number,
    { column, order }: { column: string; order: SortType },
    filters: Filters,
  ) => void;
  totalRows: number | 'unknown';
  hasMorePages?: boolean;
  customRef?: React.MutableRefObject<TableRef>;
  internalRef?: React.MutableRefObject<InternalTableRef>;
  noDataLabel?: string;
};

export type TablePropsCountKnown = CommonTableProps;

export type TablePropsCountUnknown = CommonTableProps;

export type CustomTableProps = TablePropsCountKnown | TablePropsCountUnknown;

export type PageSize = 5 | 10 | 25 | 50 | 100;

export type FilterValueType = {
  min?: TableRowValues;
  max?: TableRowValues;
  equals?: TableRowValues;
};

export type Filter = {
  column: string;
  value: FilterValueType;
};

export type Filters = Filter[];

export enum RowHighlightType {
  normal = 'normal',
  info = 'info',
  success = 'success',
  warn = 'warn',
  error = 'error',
}

export type RowHighlightState = { rowHighlightType: RowHighlightType; rowHighlightMessage: string };

export type RowHighlightInternalState = { rowHighlightType?: RowHighlightType; rowHighlightMessage?: string };

import { FunctionComponent, useState } from 'react';
import axios from 'axios';
import { b64_md5 } from './../assets/EncoderMd5';
import './../styles/Attachment.scss';
import { LoaderContainer, LoaderContainerType } from './LoaderContainer';
import { Modal } from 'react-bootstrap';
import { Localization } from '../framework/localization/Localization';
import { WindowActions } from '../types/actionTypes';
import { connect } from 'react-redux';
import { base64 } from '../helpers/base64';
import { AttachmentList, ContextAttachments } from '@iptor/business';
/**
 * attachmentList: Attribute from XML,
 * removeAttachment: Remove  attachment from context,
 * addAttachment: Add attachment to context,
 * loadAttachments: Load the  attachments
 *
 */
type AttachmentProps = {
  attachmentList: Record<string, any>[];
  removeAttachment: (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void,
  ) => void;
  addAttachment: (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void,
  ) => void;
  loadAttachments?: (payload: any) => void;
  windowId: string;
};

export class Attachments {
  private attachmentLists: any[] = [];

  constructor(list: any[]) {
    this.attachmentLists = list;
  }

  /**
   *
   * @param list
   * Update the attachment list
   */

  setList(list: any[]) {
    this.attachmentLists = list;
  }

  /**
 *
 * @param category
 * @param docName
 * @param keyName
 * @param windowId
 * @param loadAttachments
 * Function to removed attachment from the list.

 */

  removeAttachmentFromList = (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void,
  ): void => {
    const encodedKey = this.encodeKey(keyName);
    const cat: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let att;
    if (cat) {
      if (cat.keys) {
        att = cat.keys?.find((b: any) => b['$']['name'] === encodedKey)?.att;
      } else if (cat.att) {
        att = cat.att;
      }
    }
    if (att) {
      const ind = att.findIndex((a: any) => a['$']['name'] === docName);
      att.splice(ind, 1);
    }
    loadAttachments?.({
      windowId: windowId,
      attachments: this.attachmentLists,
      attachmentDetails: {
        cat: category,
        docName: docName,
        keyName: keyName,
      },
    });
  };
  /**
   *
   * @param category
   * @param docName
   * @param keyName
   * @param windowId
   * @param loadAttachments
   * Function to create attachment to the list.
   *
   */

  addAttachmentToList = (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void,
  ): void => {
    const encodedKey = this.encodeKey(keyName);
    const categoryAttachments: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let attachments;
    if (categoryAttachments)
      attachments = categoryAttachments.keys?.find((b: any) => b['$']['name'] === encodedKey)?.att;
    if (!attachments) {
      const arr: any[] = [];
      if (categoryAttachments.keys?.find((b: any) => b['$']['name'] === encodedKey)) {
        categoryAttachments.keys.find((b: any) => b['$']['name'] === encodedKey).att.push(arr);
      } else {
        if (!categoryAttachments.keys) categoryAttachments.keys = [];
        categoryAttachments.keys?.push({
          $: {
            name: encodedKey,
          },
          att: arr,
        });
      }
      attachments = arr;
    }
    if (Array.isArray(attachments))
      attachments.push({
        $: {
          name: docName,
          type: docName.split('.')[1],
        },
      });
    loadAttachments?.({
      windowId: windowId,
      attachments: this.attachmentLists,
      attachmentDetails: {
        categoryAttachments: category,
        docName: docName,
        keyName: keyName,
      },
    });
  };

  /**
   *
   * @param category
   * @param key
   * @returns
   * Function to find attachments.

   */

  findAttachments(category: string, key: string): any[] {
    const encodedKey = this.encodeKey(key);
    const cat: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let att = [];
    if (cat) att = cat.keys.find((b: any) => b['$']['name'] === encodedKey)?.att;
    return att;
  }
  /**
   *
   * @param keyRow
   * @param colHeader
   * @returns
   * Find whether the key is exist in attachment list or not.
   */

  hasKey = (keyRow: string[], colHeader?: string): boolean => {
    if (this.attachmentLists && keyRow) {
      for (let i = 0; i < this.attachmentLists.length; i++) {
        const cat: any = this.attachmentLists[i];
        if (cat.keys)
          for (let j = 0; j < keyRow.length; j++) {
            const encodedKey = this.encodeKey(keyRow[j]?.trim() || '');
            if (cat.keys.findIndex((b: any) => b['$']['name'] === encodedKey && b['att'].length > 0) !== -1) {
              if (cat.$?.column === colHeader) {
                return true;
              }
            }
          }
      }
    }
    return false;
  };
  /**
   *
   * @param keys
   * @returns
   * Function to create attachments list rows.

   */

  createAttachmentListRows(keys: Record<string, string>) {
    const dataSeparator = '@@££$$';
    const rows: any[] = [];
    const encodedKeys: string[] = [];
    const keyMap: Record<string, string> = {};
    Object.values(keys).forEach((key) => {
      encodedKeys.push(this.encodeKey(key));
      keyMap[this.encodeKey(key)] = key;
    });
    for (let i = 0; i < this.attachmentLists?.length; i++) {
      const cat: any = this.attachmentLists[i];
      if (cat.$.type?.toLowerCase() !== 'table')
        rows.push({
          entryType: 'cat',
          name:
            (Localization.instance.getString('Att_category_' + cat['$']['id']) || '') +
            ` (${cat.$.key.replaceAll(dataSeparator, ' ')})`,
          cat: cat.$.id,
          key: cat.$.key,
          type: cat.$.type,
        });
      for (let j in cat.att) {
        let ob = cat.att[j];
        rows.push({
          entryType: 'att',
          name: ob['$']['name'],
          key: cat['$']['key'],
          cat: cat.$.id,
          type: ob['$']['type'],
        });
      }
      if (
        rows.findIndex((a) => a.name === cat['$']['id']) === -1 &&
        Object.values(keys).length !== 0 &&
        !rows.find(
          (r: any) =>
            r.entryType === 'cat' &&
            r.name === cat['$']['id'] + ` (${keys[cat.$.key.split(',')[1]]})` &&
            r.key === cat.$.id,
        ) &&
        cat.$.type?.toLowerCase() === 'table'
      ) {
        const keyPartsWithoutTable = cat.$.key.split(',').slice(1);
        if (keyPartsWithoutTable.length >= 1) {
          const joinedKeys = keyPartsWithoutTable.join(',');
          const replacedSeparatorKey = keys[joinedKeys]?.replaceAll(dataSeparator, ' ') || '';
          rows.push({
            entryType: 'cat',
            name:
              (Localization.instance.getString('Att_category_' + cat['$']['id']) || '') + ` (${replacedSeparatorKey})`,
            cat: cat.$.id,
            key: keys[joinedKeys],
            type: cat.$.type,
          });
        }
      }
      for (let j in cat.keys) {
        const ob = cat.keys[j];
        if (encodedKeys.includes(ob['$']['name'])) {
          ob.att.forEach(
            (a: any) =>
              !rows.find(
                (r: any) =>
                  r.entryType === 'att' &&
                  r.cat === cat.$.id &&
                  r.key === keyMap[ob['$']['name']] &&
                  r.name === a['$']['name'],
              ) &&
              rows.push({
                entryType: 'att',
                name: a['$']['name'],
                key: keyMap[ob['$']['name']],
                cat: cat.$.id,
                type: a['$']['type'],
              }),
          );
        }
      }
      if (rows.findIndex((a) => a.key === cat['$']['id']) > -1)
        rows.push({
          entryType: 'add',
          name: '',
          key: cat.$.id,
          type: '',
        });
    }
    return rows;
  }

  /**
   *
   * @param key
   * @returns
   * Encoding into b64_md5
   */
  encodeKey = (key: string = ''): string => {
    let keyBytes = new TextEncoder().encode(key);
    let encoded = b64_md5(String.fromCharCode(...keyBytes));
    encoded = encoded.replaceAll('-', 'V');
    encoded = encoded.replaceAll('=', 'W');
    encoded = encoded.replaceAll('+', 'X');
    encoded = encoded.replaceAll('/', 'Y');
    encoded = encoded.replaceAll('\\', 'Z');
    encoded = encoded.replaceAll('|', 'X');
    return 'F' + encoded;
  };
}

export const AttachmentComponent: FunctionComponent<AttachmentProps> = ({
  attachmentList,
  addAttachment,
  removeAttachment,
  windowId,
  loadAttachments,
}) => {
  const [loading, setLoading] = useState(false);

  const deleteAttachment = (cat: string, key: string, name: string) => {
    setLoading(true);
    axios
      .post('/client/delete-attachments', {
        category: cat,
        attachmentKey: key,
        attachmentName: name,
        qualifier: '',
      })
      .then((res) => {
        document.body.setAttribute('data-uploading-attachment', '1');
        const i = attachmentList.findIndex((att) => att.name === name);
        attachmentList.splice(i, 1);
        removeAttachment(cat, name, key, windowId, loadAttachments);
      })
      .catch((e) => {
        console.error(`Error from deleteAttachment ${e}`);
      })
      .finally(() => setLoading(false));
  };

  const downloadAttachment = (cat: string, key: string, name: string) => {
    setLoading(true);
    axios
      .post(
        '/client/download-attachments',
        {
          category: cat,
          attachmentKey: key,
          attachmentName: name,
          qualifier: '',
        },
        {
          responseType: 'blob',
        },
      )
      .then((res) => {
        let blob = new Blob([res.data]);
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        link.remove();
      })
      .catch((e) => console.error(`Error from downloadAttachment ${e}`))
      .finally(() => setLoading(false));
  };

  const previewAttachment = (cat: string, key: string, name: string, type: string, callback: (url: string) => void) => {
    setLoading(true);
    axios
      .post(
        '/client/download-attachments',
        {
          category: cat,
          attachmentKey: key,
          attachmentName: name,
          qualifier: '',
        },
        {
          responseType: 'blob', // Ensure the response is a blob
        },
      )
      .then((res) => {
        // Determine MIME type
        const mimeTypeMap = {
          pdf: 'application/pdf',
          jpeg: 'image/jpeg',
          jpg: 'image/jpeg',
          png: 'image/png',
          svg: 'image/svg+xml',
          csv: 'text/csv', // Correct MIME for CSV
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };

        const mimeType = mimeTypeMap[type] || res.headers['content-type'] || 'application/octet-stream';

        const blob = new Blob([res.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        callback(url);
      })
      .catch((e) => console.error(`Error from previewAttachment: ${e}`))
      .finally(() => setLoading(false));
  };

  const uploadFile = (cat: string, key: string, file: File | undefined, files: File[]): any => {
    if (file) {
      const encodedKey = base64.bytesToBase64(key, true);
      const encodedCat = base64.bytesToBase64(cat, true);
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('file', file);

      axios
        .post('/client/upload-attachments/' + encodedCat + '/' + encodedKey, formData, {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        })
        .then((res) => {
          document.body.setAttribute('data-uploading-attachment', '1');
          attachmentList.push({
            entryType: 'att',
            name: res.data.name,
            key: key,
            cat: cat,
            type: res.data.name.substring(res.data.name.lastIndexOf('.') + 1),
          });
          addAttachment(cat, res.data.name, key, windowId, loadAttachments);
        })
        .catch((e) => console.error(`Error from uploadFile attachment ${e}`))
        .finally(() => {
          if (files.length > 0) {
            const file = files.shift();
            uploadFile(cat, key, file, files);
          } else {
            setLoading(false);
          }
        });
    }
  };

  const uploadFiles = (cat: string, key: string, files: File[]) => {
    if (files.length > 0) {
      const file = files.shift();
      uploadFile(cat, key, file, files);
    }
    try {
      let input = document.getElementById('attachment-upload-prompt-' + windowId) as HTMLInputElement;
      if (input) input.value = '';
    } catch (e) {
      console.error(`Error from uploadFiles ${e}`);
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <ContextAttachments
        attachmentList={attachmentList as unknown as AttachmentList[]}
        downloadAttachment={downloadAttachment}
        previewAttachment={previewAttachment}
        deleteAttachment={deleteAttachment}
        uploadAttachments={(cat: string, key: string, files: File[]) => uploadFiles(cat, key, files)}
        loading={loading}
      />
    </div>
  );
};

const mapDispatchToProps = {
  loadAttachments: (payload: any) => ({ type: WindowActions.LOAD_ATTACHMENTS, payload: payload }),
};
export const Attachment = connect(() => {}, mapDispatchToProps)(AttachmentComponent);

import { Theme } from '@mui/material';

type Variant = {
  props: {
    variant: 'default' | 'primary' | 'secondary';
  };
  style: {
    backgroundColor: string | undefined;
    color: string | undefined;
    padding: string;
    borderRadius: string;
  };
};

export const Box = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }): { variants: Variant[] } => ({
      variants: [
        {
          props: { variant: 'default' },
          style: {
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
            color: theme.palette.foreground[600],
            padding: theme.spacing(3),
            borderRadius: theme.spacing(1),
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.foreground[600],
            padding: theme.spacing(3),
            borderRadius: theme.spacing(1),
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.foreground[600],
            padding: theme.spacing(3),
            borderRadius: theme.spacing(1),
          },
        },
      ],
    }),
  },
};

import { Combo, ComboProps } from '@iptor/base';
import { useOpenAPIMethod } from '@iptor/business';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { formatErrorText } from '../../../utils/formatError';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { WarehouseState, rowState, UpdateOrderLineFn } from '../constants';

export const OrderLineWarehouse = React.memo(
  ({
    headerData,
    row,
    setColumnValueForRow,
    value,
    disabled,
    updateOrderLine,
    currentOrderId,
    handleRefreshHeader,
  }: rowState &
    ComboProps & {
      updateOrderLine: UpdateOrderLineFn;
      currentOrderId: number;
      handleRefreshHeader: () => void;
    }) => {
    const { notify } = useOrderNotifications();
    const getItemWarehouseStock = useOpenAPIMethod('internal.v1.base-inventory', 'GET /itemBalances/{item}');
    const getWarehouses = useOpenAPIMethod('internal.v1.base-inventory', 'GET /warehouses');
    const defaultWarehouse = row.warehouse || headerData.warehouse;
    const [warehouseState, setWarehouseState] = useState<Record<string, WarehouseState>>({});
    const [initialState, setInitialState] = useState({
      selectedWarehouse: row.warehouse,
      orderQuantitySalesUnit: row.orderQuantitySalesUnit,
      availableQuantity: row.availableQuantity,
      netSalesValue: row.netSalesValue,
    }); // initial state before triggering the API

    // Refs to prevent infinite API calls
    const hasFetchedWarehouses = useRef(false);
    const hasFetchedItemStock = useRef(false);

    // Fetch warehouses only once
    useEffect(() => {
      if (!hasFetchedWarehouses.current) {
        getWarehouses.execute({ type: 1 });
        hasFetchedWarehouses.current = true;
      }
    }, [getWarehouses]);

    useEffect(() => {
      if (getWarehouses.loading) {
        setColumnValueForRow([{ column: 'availableQuantity', value: undefined }]);
      }
    }, [getWarehouses.loading]);

    // Fetch item stock when row.item changes
    useEffect(() => {
      if (row.item && !hasFetchedItemStock.current) {
        getItemWarehouseStock.execute({ item: row.item });
        hasFetchedItemStock.current = true;
      }
    }, [row.item, getItemWarehouseStock, row.netSalesValue]);

    // Reset the item stock fetch flag if row.item changes
    useEffect(() => {
      hasFetchedItemStock.current = false;
    }, [row.item, row.netSalesValue]);

    useEffect(() => {
      if (getItemWarehouseStock.response && getWarehouses.response) {
        const warehouseData = getItemWarehouseStock.response?.data?.data?.items || [];

        const warehouseDropdownOptions = warehouseData.map((item) => {
          const currentWarehouse = getWarehouses.response?.data?.data?.items?.find(
            (option) => option?.warehouse === item.warehouse,
          );
          return {
            label: `${currentWarehouse?.warehouse} - ${currentWarehouse?.description}`,
            value: currentWarehouse?.warehouse,
          };
        });

        const warehouseListWithAvlQty = warehouseData.map((item) => ({
          warehouse: item?.warehouse,
          value: item?.availableQuantity,
        }));

        const chosenWarehouseInfo = warehouseData.find((w) => w.warehouse === defaultWarehouse) || {};
        const defaultQuantity = chosenWarehouseInfo?.availableQuantity || 0;

        setColumnValueForRow([{ column: 'availableQuantity', value: defaultQuantity?.toString() }]);

        setWarehouseState((prevState) => ({
          ...prevState,
          [row.orderLine]: {
            selectedWarehouse: defaultWarehouse,
            availableWarehouses: warehouseDropdownOptions,
            availableQuantity: defaultQuantity,
            warehouseListWithAvlQty,
          },
        }));
      }
    }, [getItemWarehouseStock.response, getWarehouses.response, defaultWarehouse, row.orderLine]);

    const onWarehouseChange = useCallback(
      async (newValue: { label: string; value: string }) => {
        const selectedWarehouse = newValue?.value;
        if (!selectedWarehouse) return;

        const warehouse = warehouseState[row.orderLine]?.warehouseListWithAvlQty.find(
          (wh) => wh.warehouse === selectedWarehouse,
        );

        setColumnValueForRow([
          { column: 'availableQuantity', value: warehouse?.value },
          { column: 'orderQuantitySalesUnit', value: 0 },
          { column: 'warehouse', value: selectedWarehouse },
          { column: 'netSalesValue', value: undefined },
        ]);

        updateOrderLine(
          row.order,
          row.orderLine,
          {
            order: +currentOrderId,
            item: row.item,
            orderQuantitySalesUnit: row?.orderQuantitySalesUnit,
            warehouse: selectedWarehouse,
          },
          (response) => {
            handleRefreshHeader();
            setColumnValueForRow([
              { column: 'orderQuantitySalesUnit', value: response?.orderQuantitySalesUnit },
              { column: 'netSalesValue', value: response?.netSalesValue || '-' },
              { column: 'warehouse', value: response.warehouse },
              {
                column: 'availableQuantity',
                value: warehouse ? warehouse.value : 0,
              },
            ]);
            setInitialState({
              selectedWarehouse,
              orderQuantitySalesUnit: row?.orderQuantitySalesUnit,
              availableQuantity: warehouse ? warehouse.value : 0,
              netSalesValue: response?.netSalesValue || '-',
            });
            notify('success', 'Order line Updated Successfully');
          },
          (err) => {
            setColumnValueForRow([
              { column: 'orderQuantitySalesUnit', value: initialState.orderQuantitySalesUnit },
              { column: 'warehouse', value: initialState.selectedWarehouse },
              { column: 'availableQuantity', value: initialState.availableQuantity },
              { column: 'netSalesValue', value: initialState.netSalesValue },
            ]);
            notify('error', formatErrorText(err));
          },
        );

        setWarehouseState((prevState) => ({
          ...prevState,
          [row.orderLine]: {
            ...prevState[row.orderLine],
            selectedWarehouse,
            availableQuantity: warehouse ? warehouse.value : 0,
          },
        }));
      },
      [warehouseState, updateOrderLine, currentOrderId, row, notify, handleRefreshHeader],
    );

    return (
      <Combo
        size="small"
        value={value ?? defaultWarehouse}
        id="warehouse"
        options={warehouseState[row.orderLine]?.availableWarehouses || []}
        disabled={disabled}
        sx={{ '& .MuiInputBase-root': { minWidth: '200px' } }}
        onChange={async (e, newValue) => onWarehouseChange(newValue as { label: string; value: string })}
      />
    );
  },
);

OrderLineWarehouse.displayName = 'OrderLineWarehouse';

import { Theme } from '@mui/material';

export const Select = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      // minWidth: 294, //Do not use such large min widths in theme
      borderWidth: 0,
      '&.Mui-disabled': {
        background: theme.palette.background.default,
        outlineColor: theme.palette.grey[300],
      },
      '& .MuiInputBase-root': {
        padding: theme.spacing(0),
        margin: theme.spacing(1),
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            padding: theme.spacing(1.5),
            backgroundColor: theme.palette.background.default,
            marginBottom: theme.spacing(0.5),
            outline: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: theme.spacing(0.5),
            '&::after, &::before': {
              borderBottom: 'none!important',
            },
            '&.MuiMenu-paper': { marginTop: theme.spacing(0.25) },
            '&.Mui-focused': {
              outlineWidth: '2px',
              outlineColor: theme.palette.primary.main,
            },
            '&:hover': {
              outlineColor: theme.palette.grey[500],
            },
          },
        },
        {
          props: { color: 'warning' },
          style: {
            background: theme.palette.warning.light,
          },
        },
        {
          props: { color: 'error' },
          style: {
            background: theme.palette.error.light,
          },
        },
        {
          props: { variant: 'standard', color: 'primary' },
          style: {
            '&.Mui-focused': {
              outlineColor: theme.palette.primary.main,
              '& .MuiInputAdornment-root .MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'secondary' },
          style: {
            '&.Mui-focused': {
              outlineColor: theme.palette.secondary.main,
              '& .MuiInputAdornment-root .MuiTypography-root': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'warning' },
          style: {
            background: theme.palette.warning.light,
            outlineColor: theme.palette.warning.main,
            '&.Mui-focused': {
              outlineColor: theme.palette.warning.dark,
              '& .MuiInputAdornment-root .MuiTypography-root': {
                color: theme.palette.warning.main,
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'error' },
          style: {
            background: theme.palette.error.light,
            outlineColor: theme.palette.error.main,
            '&.Mui-focused': {
              outlineColor: theme.palette.error.dark,
              '& .MuiInputAdornment-root .MuiTypography-root': {
                color: theme.palette.error.main,
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'success' },
          style: {
            background: theme.palette.success.light,
            outlineColor: theme.palette.success.main,
            '&.Mui-focused': {
              outlineColor: theme.palette.success.dark,
              '& .MuiInputAdornment-root .MuiTypography-root': {
                color: theme.palette.success.main,
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: theme.typography.pxToRem(14),
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: theme.typography.pxToRem(18),
          },
        },
      ],
    }),
  },
};

import React, { ComponentType, useRef } from 'react';

export const withFocusCapture = <T extends object>(WrappedComponent: ComponentType<T>): React.FC<T> => {
  return (props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const handleFocusStart = () => {
      const focusableElements = containerRef.current?.querySelectorAll(
        'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ) as NodeListOf<HTMLElement>;
      focusableElements?.[focusableElements.length - 1]?.focus();
    };

    const handleFocusEnd = () => {
      const focusableElements = containerRef.current?.querySelectorAll(
        'button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])',
      ) as NodeListOf<HTMLElement>;
      focusableElements?.[0]?.focus();
    };

    return (
      <>
        <div tabIndex={0} onFocus={handleFocusStart} />
        <span ref={containerRef}>
          <WrappedComponent {...props} />
        </span>
        <div tabIndex={0} onFocus={handleFocusEnd} />
      </>
    );
  };
};

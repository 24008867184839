import { Box } from '@mui/material';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { Icon } from '../../Icon';
import { Text } from '../../Text';
import { TextSize, TextWeight } from '../../../constants/Typography';

const ContextLayoutPaper = (props: {
  open: boolean;
  onClose: () => void;
  onWidthChange?: (value: number) => void;
  width?: number;
  title?: string;
  children: ReactNode;
}) => {
  const [width, setWidth] = useState<number>(props.width || 400);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const widthRef = useRef(width);

  const startResizing = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
    if (!sidebarRef.current) return;

    setIsResizing(true);
    event.preventDefault();
    event.currentTarget.setPointerCapture(event.pointerId);

    const startWidth = sidebarRef.current.offsetWidth;
    const startX = event.pageX;

    const handlePointerMove = (moveEvent: PointerEvent) => {
      requestAnimationFrame(() => {
        const newWidth = startWidth - (moveEvent.pageX - startX);
        if (newWidth > 200 && newWidth < 1000) {
          widthRef.current = newWidth;
          setWidth(newWidth);
          props.onWidthChange?.(newWidth);
        }
      });
    };

    const handlePointerUp = () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);

      // Release pointer capture
      event?.currentTarget?.releasePointerCapture(event.pointerId);

      setIsResizing(false);
    };

    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
  }, []);

  return (
    <Box
      ref={sidebarRef}
      sx={(theme) => ({
        width: props.open ? `${width}px` : '0px',
        minWidth: props.open ? `${width}px` : '0px',
        maxWidth: props.open ? `${width}px` : '0px',
        marginLeft: props.open ? theme.spacing(2) : '0px',
        transition: isResizing ? 'none' : 'all 0.3s ease',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        borderRadius: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        cursor: isResizing ? 'ew-resize' : 'auto',
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          cursor: 'ew-resize',
          transition: 'background-color 0.2s',
          backgroundColor: isResizing ? theme.palette.grey[200] : 'transparent',
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        })}
        onPointerDown={startResizing} // Changed from onMouseDown to onPointerDown
      />
      <Box sx={(theme) => ({ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(2) })}>
        <Text fontSize={TextSize.S} fontWeight={TextWeight.SEMIBOLD}>
          {props.title}
        </Text>
        <Icon onClick={props.onClose} icon="cancel01" />
      </Box>
      <Box sx={(theme) => ({ padding: theme.spacing(2), height: '100%' })}>{props.children}</Box>
    </Box>
  );
};

export { ContextLayoutPaper };

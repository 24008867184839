import { NumericField, useIptorTranslation } from '@iptor/base';
import {
  OperationResponseDataData,
  useOpenAPIMethod,
  openAPIManager,
  Items,
  LiveSearchItems,
  UnitsDropdown,
  WarehouseDropdown,
} from '@iptor/business';
import { Button } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { formatErrorText } from '../../../utils/formatError';
import { OrderLine } from '../constants';
import { SalesOrderLineType } from './OrderItemsTable';
import { useOrderNotifications } from '../../../hooks/useOrderNotification';
import { Order } from '../../../constants';

interface Props {
  handleRefresh: () => void;
  currentOrderId: number;
  order: Order;
  loadingValidateItem: boolean;
  setLoadingValid: (value: boolean) => void;
}

interface OrderLineApiResponse {
  payload: {
    data?: Partial<OrderLine>;
    response?: {
      data?: {
        messages: string;
      };
    };
  };
}

function AddItemGrid({ handleRefresh, currentOrderId, order, loadingValidateItem, setLoadingValid }: Props) {
  const [openItems, setOpenItems] = useState(false);
  const { notify, closeAllSnackbars } = useOrderNotifications();
  const { t } = useIptorTranslation();
  const { handleSubmit, control, setValue, watch, reset } = useForm<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >({ mode: 'onChange', defaultValues: { orderQuantitySalesUnit: 1 } });
  const enteredQty = watch('orderQuantitySalesUnit');

  const [addItemsLoading, setAddItemsLoading] = useState<boolean>(false);
  const [itemToBeAddedValue, setItemToBeAddedValue] = useState<string>('');
  const [itemToBeAddedInputValue, setItemToBeAddedInputValue] = useState<string>('');
  const liveSearchRef = useRef<HTMLInputElement>(null);
  const [initialValues] = useState<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >({});

  const addSalesOrderLine = useOpenAPIMethod('internal.v1.base-orders', 'POST /salesOrders/{order}/lines');

  useEffect(() => {
    setValue('item', itemToBeAddedValue);
  }, [itemToBeAddedValue]);

  const createOrderLine = (orderId: number, orderLinePaylod: Partial<OrderLine>) => {
    addSalesOrderLine
      .execute(
        { order: orderId },
        {
          // customer: orderLinePaylod?.customer,
          item: orderLinePaylod?.item,
          warehouse: orderLinePaylod?.warehouse,
          unit: orderLinePaylod?.unit,
          orderQuantitySalesUnit: orderLinePaylod?.orderQuantitySalesUnit,
        },
      )
      .then(() => {
        handleRefresh();
        setLoadingValid(false);
        setAddItemsLoading(false);
        reset({});
        setItemToBeAddedValue('');
        setItemToBeAddedInputValue('');
        setValue('orderQuantitySalesUnit', undefined);

        notify(
          'success',
          `Order line with Item ${orderLinePaylod?.item} for order (${orderLinePaylod?.order}) added successfully.`,
        );
      })
      .catch(() => {
        setLoadingValid(false);
        setAddItemsLoading(false);

        notify('error', `Failed to create Order line for order (${orderLinePaylod?.order})`);
      });
  };

  const handleInventoryItemValidation = ({
    itemId,
    orderId,
    unit,
    orderQuantitySalesUnit,
    warehouse,
  }: {
    itemId: string;
    orderId: number;
    unit: string;
    orderQuantitySalesUnit: number;
    warehouse: string;
  }) => {
    setAddItemsLoading(true);
    openAPIManager
      .execute('internal.v1.base-orders', 'GET /salesOrders/{order}/lineSimulations', {
        order: orderId,
        item: itemId,
        orderQuantitySalesUnit,
        unit,
        warehouse,
      })
      .then((result) => {
        if (axios.isAxiosError(result)) {
          notify('error', result.response?.data?.messages);
        } else {
          if (result.data) {
            createOrderLine(currentOrderId, result.data.data);
            reset();
          }
        }
      })
      .catch((error) => {
        setAddItemsLoading(false);
        notify('error', formatErrorText(error));
      })
      .finally(() => {
        liveSearchRef?.current?.focus();
      });
  };

  const handleMultipleInventoryItems = async (items: SalesOrderLineType[]) => {
    setLoadingValid(true);
    const promises = items.map(
      (item) =>
        new Promise<OrderLineApiResponse>((resolve, reject) => {
          openAPIManager
            .execute('internal.v1.base-orders', 'GET /salesOrders/{order}/lineSimulations', {
              order: +currentOrderId,
              item: item.item,
              orderQuantitySalesUnit: item.orderQuantitySalesUnit,
              unit: item?.stockUnit,
              warehouse: order?.warehouse,
            })
            .then((result) => {
              setLoadingValid(false);
              resolve({
                payload: {
                  data: result.data.data,
                },
              });
            })
            .catch((error) => {
              setLoadingValid(false);
              reject(error);
            });
        }),
    );

    Promise.allSettled(promises).then((results) => {
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          if (axios.isAxiosError(result.value?.payload)) {
            setLoadingValid(false);
            notify('error', result.value.payload?.response?.data?.messages);
          } else {
            const orderLineData = result.value?.payload?.data;
            if (orderLineData) {
              createOrderLine(currentOrderId, orderLineData);
            }
          }
        } else if (result.status === 'rejected') {
          setLoadingValid(false);
          notify('error', result.reason?.response?.data?.messages);
        }
      });
    });
  };
  const onSubmit = handleSubmit((data) => {
    closeAllSnackbars();
    const updatedData: Partial<
      OperationResponseDataData<'internal.v1.base-orders', 'PUT /salesOrders/{order}/lines/{orderLine}'>
    > = {};
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof typeof data;
      if (data[typedKey] !== initialValues[typedKey]) {
        (updatedData as Record<keyof typeof data, any>)[typedKey] = data[typedKey];
      }
    });
    if (Object.keys(updatedData).length === 0) {
      notify('warning', `Changes not pushed as no data was modified`, true);
    } else {
      handleInventoryItemValidation({
        orderId: currentOrderId,
        itemId: updatedData?.item,
        orderQuantitySalesUnit: updatedData?.orderQuantitySalesUnit || 1,
        unit: updatedData?.stockUnit,
        warehouse: updatedData?.warehouse,
      });
    }
  });

  const selectedItemId = watch('item');

  return (
    <>
      {openItems && (
        <Items
          headerData={{
            warehouse: order?.warehouse,
            orderId: currentOrderId,
          }}
          searchedItemId={selectedItemId}
          handleSelectedItemList={(items: any) => {
            handleMultipleInventoryItems(items as SalesOrderLineType[]);
          }}
          open={openItems}
          handleClose={() => {
            setOpenItems(false);
          }}
        />
      )}
      <Grid
        component="form"
        container
        direction="row"
        spacing={1.5}
        size={12}
        id="addItemForm"
        onSubmit={onSubmit}
        sx={{ '.MuiBox-root .MuiGrid2-root .MuiButtonBase-root ': { placeSelf: 'flex-end' }, alignItems: 'flex-end' }}
      >
        <Grid size={3.5}>
          <Controller
            name="item"
            control={control}
            render={({ field }) => (
              <LiveSearchItems
                label="Item"
                loading={loadingValidateItem}
                liveSearchInputValue={itemToBeAddedInputValue}
                autoFocus
                id={field.name}
                inputRef={liveSearchRef}
                size="small"
                onLiveSearchInputChange={(_, value, reason) => {
                  setItemToBeAddedInputValue(value);

                  // TODO: should we uppercase also here as changes are used immediately?
                  if (reason === 'selectOption' && value) {
                    setItemToBeAddedValue(value);
                  }
                }}
                onBlur={(e) => {
                  setItemToBeAddedValue(e.target?.value.toUpperCase());
                  setItemToBeAddedInputValue(e.target?.value.toUpperCase());
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    minWidth: '100px',
                  },
                }}
                placeholder={(t('common:TXT_Enter_Item_Name_Placeholder') as string)?.toUpperCase()}
                handleViewAll={{
                  enabled: true,
                  onRequested: () => {
                    setOpenItems(true);
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size={2.2} justifyItems="center">
          <Controller
            name={'orderQuantitySalesUnit'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                value={field.value ?? (itemToBeAddedValue ? 1 : 0)}
                size="small"
                id={field.name}
                label="Quantity"
                buttonLayout="horizontal"
                additionalSlotProps={{
                  positiveButton: {
                    tabIndex: -1,
                  },
                  negativeButton: {
                    tabIndex: -1,
                  },
                }}
                fullWidth
                onChangeNumeric={(value) => field.onChange(value)}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid size={1.5}>
          <Controller
            name="stockUnit"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <UnitsDropdown
                item={itemToBeAddedValue}
                id={field.name}
                size="small"
                sx={{
                  '& .MuiInputBase-root': {
                    minWidth: '80px !important',
                  },
                }}
                value={field?.value}
                fullWidth
                label={t('common:TXT_Unit')}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid size={2.5}>
          <Controller
            name="warehouse"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <WarehouseDropdown
                item={selectedItemId ?? ''}
                size="small"
                id={field.name}
                value={field.value}
                sx={{
                  '& .MuiInputBase-root': {
                    minWidth: '80px !important',
                  },
                }}
                onChange={(value) => field.onChange(value)}
                fullWidth
                label={t('common:TXT_Warehouse')}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid size={1.7} sx={{ minHeight: '46px' }}>
          <Button
            disabled={enteredQty === 0 || !selectedItemId}
            loading={addItemsLoading}
            variant="contained"
            id="addItem"
            form="addItemForm"
            type="submit"
            sx={{
              height: '42px', // Applies to the button root
              minWidth: '160px', // Applies to the button root
            }}
          >
            {t('common:TXT_Add_Item')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default AddItemGrid;

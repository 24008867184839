import { PaletteOptions } from '@mui/material';

const baseColors = {
  primary: {
    main: '#007F81',
    light: '#E5F4F4',
    500: '#00A3A5',
    400: '#33B5B7',
    300: '#9EDCDD',
    200: '#E6F2F2',
    100: '#CCEDED',
  },
  secondary: {
    main: '#F07D00',
    500: '#F17900',
    400: '#F49433',
    100: '#FCE4CC',
  },
  tertiary: {
    darkPink: '#EB617C',
    cyprus: '#0A4B55',
    bigStone: '#2F3C46',
  },
  foreground: {
    100: '#000000',
    300: '#1F1F1F',
    400: '#404040',
    500: '#626262',
    600: '#767676',
    700: '#BFBFBF',
    800: '#e1e1e1',
    900: '#FAFAFA',
  },
  background: {
    default: '#FFFFFF',
    paper: '#F8F8F8',
    tableHeader: '#EFF0F0',
    dark: '#273B49',
  },
  semantics: {
    success: '#00C29F',
    warning: '#FFAB00',
    error: '#FF1919',
    notification: '#0076BB',
    cta: '#2D7BF9',
  },
  text: {
    primary: '#404040',
    secondary: '#767676',
    disabled: '#767676',
  },
  success: {
    main: '#00C29F',
    light: '#E0FFF5',
  },
  warning: {
    main: '#FFAB00',
    light: '#FFF8EC',
  },
  error: {
    100: '#FFFBFB',
    main: '#FF1919',
    light: '#FFF1F0',
  },
  info: {
    main: '#0076BB',
    light: '#EFF6FB',
    100: '#F2FCFF',
  },
};

export const createColorPalette = (mode: 'light' | 'dark' = 'light'): PaletteOptions => {
  return {
    mode,
    ...baseColors,
    background: {
      default: mode === 'light' ? baseColors.background.default : '#304555',
      paper: mode === 'light' ? baseColors.background.paper : '#2e414f',
      tableHeader: mode === 'light' ? baseColors.background.tableHeader : '#385062',
    },
    foreground: {
      100: mode === 'light' ? baseColors.foreground[100] : '#FFFFFF', // Darkest in light mode becomes white in dark
      300: mode === 'light' ? baseColors.foreground[300] : '#f9f9f9',
      400: mode === 'light' ? baseColors.foreground[400] : '#efefef',
      500: mode === 'light' ? baseColors.foreground[500] : '#dbdbdb',
      600: mode === 'light' ? baseColors.foreground[600] : '#808080',
      700: mode === 'light' ? baseColors.foreground[700] : '#606060',
      800: mode === 'light' ? baseColors.foreground[800] : '#404040',
      900: mode === 'light' ? baseColors.foreground[900] : '#404040', // Lightest in light mode becomes darker in dark
    },
    text: {
      primary: mode === 'light' ? baseColors.text.primary : '#FFFFFF',
      secondary: mode === 'light' ? baseColors.text.secondary : '#BFBFBF',
      disabled: mode === 'light' ? baseColors.text.disabled : '#A0A0A0',
    },
    action: {
      hover: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
      selected: mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.16)',
      disabled: mode === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
      disabledBackground: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
    },
  };
};

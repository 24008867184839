import { ChangeEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import '../styles/RadioButton.scss';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import React from 'react';
import { LocalStorage } from '../framework/handlers/localStorage';

/**
 * @RadioButtonProps
 * attributes: XML attributes for selected element
 */

type RadioButtonProps = {
  attributes: Record<string, any>;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  id: string;
  label: string;
  name: string;
  value: string;
  visible?: boolean;
  panelID: string;
  yes: string;
  no: string;
  isGroup: boolean;
  isInvalid?: boolean;
};

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  attributes,
  className = '',
  disabled = false,
  readOnly = false,
  id,
  name,
  label,
  visible = true,
  panelID,
  yes,
  no,
  isGroup,
  isInvalid = false,
}) => {
  const radioRef = useRef<HTMLInputElement>(null);
  const { addToCommand, removeFromCommand, errors, windowData, cursor } = useContext(CommandContext); //Creating a command for the XT
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (radioRef && radioRef.current) {
      let dirtyflag = +(radioRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(dirtyflag);
      let y = attributes.value?.toLowerCase() === 'true' ? yes : attributes.value || '1';
      let n = attributes.deselctedvalue?.toLowerCase() === 'false' ? no : attributes.value || ' ';
      let value = isGroup
        ? XT.getValueFromWindow({ data: windowData }, panelID, name)
        : XT.getValueFromWindow({ data: windowData }, panelID, id);
      radioRef.current.checked = value === y;
    }
  }, [windowData]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    /**
     * Handling the Radio button
     */
    let y = attributes.value?.toLowerCase() === 'true' ? yes : attributes.value || '1';
    let n = attributes.deselctedvalue?.toLowerCase() === 'false' ? no : attributes.deselctedvalue || ' ';
    let panel = windowData.form.panel.find((pan: any) => pan.$.id === panelID);
    if (isGroup) {
      let val = XT.getValueFromWindow({ data: windowData }, panelID, name);
      let _dirtyflag = +((e.target.closest('[data-dirtyflag]') as HTMLElement)?.getAttribute('data-dirtyflag') || -1);
      if (e.target.value === val) {
        removeFromCommand(panelID, name, _dirtyflag);
      } else {
        addToCommand(panelID, name, e.target.value, _dirtyflag);
      }
    } else {
      let _radios: HTMLCollectionOf<HTMLInputElement> | undefined = e.target
        .closest('.xt-group')
        ?.getElementsByTagName('input');
      let radios = Array.from(_radios as Iterable<HTMLInputElement>);
      let radioKnots: Record<string, { id: string; value: string; dirtyflag: number }> = {};
      let changed = false;
      for (let index in radios) {
        let radio = radios[index];
        let _ctrl = panel.ctrl.find((c: any) => c.$.id === radio.id);
        let _dirtyflag = +((radio.closest('[data-dirtyflag]') as HTMLElement)?.getAttribute('data-dirtyflag') || -1);
        radioKnots[_ctrl.$.id] = {
          id: _ctrl.$.id,
          value: radio.checked ? y : n,
          dirtyflag: _dirtyflag,
        };
        if ((_ctrl.$.value === y) !== radio.checked) {
          changed = true;
        }
      }
      for (let key in radioKnots) {
        let radioKnot = radioKnots[key];
        if (changed) {
          addToCommand(panelID, key, radioKnot.value, radioKnot.dirtyflag);
        } else {
          removeFromCommand(panelID, key, radioKnot.dirtyflag);
        }
      }
    }
  };

  return (
    <div
      data-for="global"
      data-iscapture="true"
      data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
    >
      <Form.Check
        onChange={handleChange}
        onKeyUp={(e: React.KeyboardEvent) => {
          if (
            ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
            LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
          ) {
            XT.selectNextElement();
          }
        }}
        ref={radioRef}
        autoFocus={isInvalid}
        data-invalid={isInvalid}
        data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
        tabIndex={-1} //Remark: tabindex was moved to the parent "radiobutton group"
        className={`custom-radio ${className} ${errors && isInvalid ? 'is-invalid' : ''}`}
        custom={true}
        value={attributes.value}
        defaultChecked={checked}
        disabled={disabled || readOnly}
        hidden={!visible}
        id={id}
        label={label}
        name={name}
        type="radio"
      />
    </div>
  );
};

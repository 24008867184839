export const getOptionsWithLabelValuePair = ({
  list,
  valueAttribute,
  labelAttribute,
  labels,
}: {
  list: Record<string, any>[];
  valueAttribute: string;
  labelAttribute?: string;
  labels?: string[];
}) => {
  const options = list?.map((item) => {
    const value = item?.[valueAttribute];
    const label =
      labels && labels.length
        ? labels.map((label) => item?.[label] ?? '').join(' ')
        : `${item?.[labelAttribute] ?? ''}`;
    const validatedValue = typeof value === 'string' || typeof value === 'number' ? value : '';
    return {
      label,
      value: validatedValue,
    };
  });
  return options;
};

import { openAPIManager } from '@iptor/business';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InventoryItem, OrderLine } from '../constants';

export const getSalesOrderLines = createAsyncThunk(
  'editSalesOrderItems/getSalesOrderLines',
  async ({ orderId }: { orderId: number }, { rejectWithValue }) => {
    const client = await openAPIManager.getClient('internal.v1.base-orders');

    try {
      const response = await client['GET /salesOrders/{order}/lines']({ order: orderId });

      if (!response || !response.data) {
        throw new Error('Failed to fetch sales order lines');
      }

      return response.data.data.items as OrderLine[];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editSalesOrderLine = createAsyncThunk(
  'editSalesOrderItems/editSalesOrderLine',
  async (
    {
      orderId,
      orderLineId,
      orderLinePayload,
    }: { orderId: number; orderLineId: number; orderLinePayload: Partial<OrderLine> },
    { rejectWithValue },
  ) => {
    const client = await openAPIManager.getClient('internal.v1.base-orders');

    try {
      const response = await client['PUT /salesOrders/{order}/lines/{orderLine}'](
        {
          order: orderId,
          orderLine: orderLineId,
        },
        { ...orderLinePayload },
      );

      if (!response || !response.data) {
        throw new Error('Failed to edit sales order line');
      }

      return response.data.data as OrderLine;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getInventoryItems = createAsyncThunk(
  'editSalesOrderItems/getInventoryItems',
  async (_, { rejectWithValue }) => {
    const client = await openAPIManager.getClient('internal.v1.base-inventory');

    try {
      const response = await client['GET /items']();

      if (!response || !response.data) {
        throw new Error('Failed to fetch inventory items');
      }

      return response.data.data.items as InventoryItem[];
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateInventoryItem = createAsyncThunk(
  'editSalesOrderItems/validateInventoryItem',
  async (
    {
      orderId,
      itemId,
      warehouse,
      orderQuantitySalesUnit,
      unit,
    }: { orderId: number; itemId: string; warehouse?: string; orderQuantitySalesUnit?: number; unit?: string },
    { rejectWithValue },
  ) => {
    const client = await openAPIManager.getClient('internal.v1.base-orders');

    try {
      const response = await client['GET /salesOrders/{order}/lineSimulations']({
        order: orderId,
        item: itemId,
        orderQuantitySalesUnit,
        warehouse,
        unit,
      });

      if (!response?.data?.data) {
        throw new Error('Failed to validate inventory item');
      }

      return {
        data: response.data.data,
        status: response.status,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addSalesOrderLine = createAsyncThunk(
  'editSalesOrderItems/addSalesOrderLine',
  async (
    { orderId, orderLinePayload }: { orderId: number; orderLinePayload: Partial<OrderLine> },
    { rejectWithValue },
  ) => {
    const client = await openAPIManager.getClient('internal.v1.base-orders');

    try {
      const response = await client['POST /salesOrders/{order}/lines']({ order: orderId }, { ...orderLinePayload });

      if (!response || !response.data) {
        throw new Error('Failed to add sales order item');
      }
      return response.data.data as OrderLine;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

import { Accordion, Iframe, Icon, IconButton, useTheme, useIptorTranslation } from '@iptor/base';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import GenericConfirmationDialog from '../../../../GenericConfirmationDialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  attachmentList: AttachmentList[];
  downloadAttachment: (cat: string, key: string, name: string) => void;
  previewAttachment: (cat: string, key: string, type: string, name: string, callback: (url: string) => void) => void;
  deleteAttachment: (cat: string, key: string, name: string) => void;
  uploadAttachments: (cat: string, key: string, files: File[]) => void;
  loading?: boolean;
}

export interface AttachmentList {
  cat: string;
  entryType: 'cat' | 'att';
  key: string;
  name: string;
  type: string;
}

const CornerSvg = () => (
  <svg width="50" height="25" viewBox="0 0 250 125" style={{ position: 'absolute', bottom: '-2px', right: '-2px' }}>
    {/* First Triangle (Left, Light Gray - Flipped Horizontally) */}
    <polygon points="125,125 0,125 125,0" fill="#e5e5e5" />

    {/* Second Triangle (Top Right, Red) */}
    <polygon points="125,0 250,0 125,125" fill="#fb4c30" />

    {/* Third Triangle (Bottom Right, Gray) */}
    <polygon points="125,125 250,0 250,125" fill="#FFFFFF" />
  </svg>
);

const typeToIconType = (type: string) => {
  switch (type) {
    case 'png':
      return 'attachmentPng';
    case 'pdf':
      return 'attachmentPdf';
    case 'csv':
      return 'attachmentCsv';
    case 'xls':
      return 'attachmentExcel';
    default:
      return 'attachmentDefault';
  }
};

const PreviewComponent = ({ url, item }: { url: string; item: AttachmentList }) => {
  const theme = useTheme();
  return (
    <Box
      width={'50%'}
      sx={(theme) => ({
        my: 2,
        color: theme.palette.foreground[600],
        fontWeight: '600',
        fontSize: '12px',
      })}
    >
      {item.type === 'pdf' ? (
        <Iframe
          src={url}
          title="iframe"
          style={{
            borderRadius: '0px',
            width: '100%',
            height: '100%',
            border: '2px solid rgb(204, 204, 204)',
            position: 'relative',
            maxWidth: '100%',
            borderColor: theme.palette.grey[300],
          }}
        />
      ) : (
        <img
          src={url}
          style={{
            borderRadius: '0px',
            width: '100%',
            height: '100%',
            border: '2px solid rgb(204, 204, 204)',
            position: 'relative',
            maxWidth: '100%',
            borderColor: theme.palette.grey[300],
          }}
        />
      )}
      <Box
        hidden={item.type !== 'pdf'}
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[100],
          padding: theme.spacing(1),
          maxWidth: '100%',
          height: '100%',
          border: '2px solid rgb(204, 204, 204)',
          borderColor: theme.palette.grey[300],
          position: 'relative',
          marginTop: '-6px',
          marginRight: '-4px',
          py: theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        })}
      >
        <Icon icon={typeToIconType(item.type)} />
        <Box
          //limit to one line
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            lineClamp: 1,
          }}
        >
          {item.name}
        </Box>

        <CornerSvg />
      </Box>
    </Box>
  );
};

const OneRow = ({
  item,
  downloadAttachment,
  previewAttachment,
  deleteAttachment,
  key,
}: {
  item: AttachmentList;
  downloadAttachment: () => void;
  previewAttachment: (callback: (url: string) => void) => void;
  deleteAttachment: () => void;
  key: string;
}) => {
  const [previewURl, setPreviewUrl] = useState<string | null>(null);
  const { t } = useIptorTranslation();
  return (
    <Box
      sx={(theme) => ({
        px: 2,
        py: 1,
        boxShadow: theme.shadows[1],
        borderRadius: theme.spacing(1),
        backgroundColor: 'white',
        alignItems: 'center',
        minHeight: 30,
      })}
      key={key}
    >
      <Stack key={item.key} direction="row" spacing={2}>
        <Box sx={{ display: 'flex', gap: 1, flex: 4, alignItems: 'center' }}>
          <Icon icon={typeToIconType(item.type)} />

          <Box
            sx={{
              cursor: 'pointer',
            }}
            onClick={downloadAttachment}
          >
            {item.name}
          </Box>
        </Box>
        <Box sx={{ flex: 1, alignContent: 'center' }}>{item.type}</Box>
        <Stack direction="row" sx={{ width: 80, justifyContent: 'flex-end' }}>
          <IconButton
            size="small"
            variant="text"
            id="preview"
            tooltipText={t('common:TXT_Preview')}
            onClick={() => {
              if (previewURl) {
                setPreviewUrl(null);
              } else {
                previewAttachment((url) => {
                  setPreviewUrl(url);
                });
              }
            }}
            icon="view"
          />
          <IconButton
            id="downloadAttachment"
            size="small"
            variant="text"
            tooltipText={t('common:TXT_Download')}
            onClick={downloadAttachment}
            icon="download4"
          />
          <IconButton
            id="deleteAttachment"
            size="small"
            tooltipText={t('common:TXT_Delete')}
            variant="text"
            onClick={deleteAttachment}
            icon="cancel01"
          />
        </Stack>
      </Stack>
      {previewURl && <PreviewComponent url={previewURl} item={item} />}
    </Box>
  );
};

export const ContextAttachments = (props: Props) => {
  const { attachmentList, downloadAttachment, previewAttachment, deleteAttachment, uploadAttachments, loading } = props;
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const { t } = useIptorTranslation();

  const mappedList = useMemo(
    () =>
      attachmentList.reduce(
        (acc, curr) => {
          const { cat, entryType } = curr;
          if (!acc[cat]) {
            acc[cat] = [];
          }
          if (entryType === 'att') {
            acc[cat].push(curr);
          }
          return acc;
        },
        {} as Record<string, AttachmentList[]>,
      ),
    [attachmentList],
  );

  return (
    <Stack gap={1} direction="column" sx={{ width: '100%' }}>
      {attachmentToDelete && (
        <GenericConfirmationDialog
          open={true}
          type="delete"
          onConfirm={() => {
            deleteAttachment(attachmentToDelete.cat, attachmentToDelete.key, attachmentToDelete.name);
            setAttachmentToDelete(null);
          }}
          onCancel={() => setAttachmentToDelete(null)}
          loading={loading}
        />
      )}
      {Object.keys(mappedList).map((key) => {
        const list = mappedList[key];
        const category = attachmentList.find((item) => item.cat === key);

        return (
          <Accordion
            headingTitle={category.name}
            key={key}
            size="small"
            extraActions={
              <IconButton
                icon="plusSign"
                size="small"
                variant="text"
                id="upload"
                onClick={(e) => {
                  e.stopPropagation();
                  const input = document.getElementById('attachment-upload-prompt-' + key);
                  if (input) {
                    input.click();
                  }
                }}
                tooltipText={t('common:ATTACHMENT_AddAttachment')}
              />
            }
          >
            <input
              type="file"
              hidden={true}
              id={'attachment-upload-prompt-' + key}
              data-iscapture="true"
              data-for="global"
              data-tip={'attachment-upload-prompt-' + key}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const files = event.target.files;
                if (files) {
                  uploadAttachments(category.cat, category.key, Array.from(files));
                }
              }}
              multiple={true}
            />
            <Stack spacing={1} sx={{ fontSize: '12px' }}>
              <Stack
                direction="row"
                spacing={2}
                sx={(theme) => ({
                  px: 2,
                  py: 1,
                  backgroundColor: (theme) => theme.palette.grey[100],
                  alignItems: 'center',
                  borderRadius: theme.spacing(1),
                  minHeight: 30,
                })}
              >
                <Box sx={{ flex: 4 }}>Item</Box>
                <Box sx={{ flex: 1 }}>Type</Box>
                <Box sx={{ width: 80, BoxAlign: 'right' }}></Box>
              </Stack>
              {list.map((item, index) => (
                <OneRow
                  key={item.key + '' + index}
                  item={item}
                  downloadAttachment={() => downloadAttachment(item.cat, item.key, item.name)}
                  previewAttachment={(callback: (url: string) => void) =>
                    previewAttachment(item.cat, item.key, item.name, item.type, callback)
                  }
                  deleteAttachment={() => setAttachmentToDelete(item)}
                />
              ))}
            </Stack>
          </Accordion>
        );
      })}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Stack>
  );
};

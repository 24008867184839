import { Box, Button, Dialog, Icon, IconButton, Text, useIptorTranslation } from '@iptor/base';
import { HandlerDropdown, openAPIManager, SalesOrderTypesDropdown, useOpenAPIMethod } from '@iptor/business';
import { DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import { getOptionsWithLabelValuePair } from '../../utils/getOptionsWithLabelValuePair';

type CreditOrderProps = {
  orderId: number;
  customerNumber: string;
  handler?: string;
  orderType?: string;
  open: boolean;
  handleClose: () => void;
  refreshTable: () => void;
};

const CreditOrder = ({ open, handleClose, refreshTable, orderId, customerNumber, handler }: CreditOrderProps) => {
  const signatures = useOpenAPIMethod('internal.v1.base-tables', 'GET /signatures');
  const { notify } = useOrderNotifications();
  const { t } = useIptorTranslation();
  const [creditOrderPayload, setCreditOrderPayload] = useState<{
    currencyClause: boolean;
    copyText: boolean;
    customer: string;
    newPrices: string;
    handler?: string;
    orderType?: string;
  }>({ currencyClause: true, copyText: true, customer: customerNumber, newPrices: '0' });
  const [loading, setLoading] = useState(false);

  const signatureOptions = getOptionsWithLabelValuePair({
    list: signatures.response?.data?.data?.items || [],
    labelAttribute: 'signatureName',
    valueAttribute: 'signature',
  });

  const handlerValue = signatureOptions.find((option) => option.value === handler)?.value;

  useEffect(() => {
    signatures.execute();
  }, []);

  useEffect(() => {
    setCreditOrderPayload({
      ...creditOrderPayload,
      handler: handlerValue,
    });
  }, [handlerValue]);

  const handleFieldChange = (field: string, value: string) => {
    setCreditOrderPayload({
      ...creditOrderPayload,
      [field]: value,
    });
  };

  const updateOrderCredit = () => {
    setLoading(true);

    openAPIManager
      .execute(
        'internal.v1.base-orders',
        'POST /salesOrders/{orderToCopy}/copy',
        {
          orderToCopy: +orderId,
        },
        { ...creditOrderPayload },
      )
      .then((response) => {
        notify('success', `Order (${response.data?.data?.orderNumber}) has been credited successfully!`);
      })
      .catch((error) => {
        notify('error', error?.response?.data?.messages);
      })
      .finally(() => {
        setLoading(false);
        refreshTable();
        handleClose();
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && creditOrderPayload.handler && creditOrderPayload.orderType && !loading) {
      updateOrderCredit();
    }
  };

  return (
    <Dialog open={open} fullWidth onClose={handleClose} onKeyPress={handleKeyPress}>
      <Box>
        <DialogTitle sx={{ padding: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: theme.spacing(2),
            })}
          >
            <Text variant="h6" fontWeight={600} sx={{ display: 'flex', alignItems: 'center', span: { marginLeft: 1 } }}>
              <Icon icon="informationCircle" color="primary" /> <span>Credit Order</span>
            </Text>

            <IconButton
              sx={(theme) => ({ padding: theme.spacing(1), color: theme.palette.text.primary })}
              variant="text"
              size="large"
              color="inherit"
              icon="cancelCircle"
              id="cancel"
              onClick={handleClose}
            />
          </Stack>
        </DialogTitle>
        <Divider
          sx={(theme) => ({
            opacity: 0.35,
            marginX: theme.spacing(0.5),
          })}
        />

        <DialogContent
          sx={(theme) => ({
            padding: 0,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(8),
            overflow: 'hidden',
          })}
        >
          <Stack direction="column" spacing={2}>
            <Text component="p" variant="body1">
              {orderId} order will be credit.{' '}
              <Text component="span" fontWeight={600}>
                {t('common:TXT_Do_you_want_to_proceed')}
              </Text>
            </Text>
            <HandlerDropdown
              label={t('common:TXT_Handler')}
              value={creditOrderPayload.handler}
              loading={signatures.loading}
              onChange={(newValue) => handleFieldChange('handler', newValue as string)}
              id="handler"
              textFieldProps={{
                autoFocus: true,
              }}
              fullWidth
            />
            <SalesOrderTypesDropdown
              label={t('sales_orders:CREDIT_ORDER_creditOrderType')}
              where="invoiceOrCreditNote='2'"
              id="salesOrderType"
              onChange={(newValue) => handleFieldChange('orderType', newValue as string)}
              value={creditOrderPayload.orderType}
              fullWidth
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ padding: 0 }}>
          <Button id="cancel" onClick={handleClose}>
            {t('common:TXT_Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!(creditOrderPayload.handler && creditOrderPayload.orderType)}
            loading={loading}
            id="update"
            onClick={updateOrderCredit}
          >
            {t('common:TXT_Update')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreditOrder;

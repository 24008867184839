import styled from '@emotion/styled';
import { Button, useCustomSnackbar, useIptorTranslation, Text } from '@iptor/base';
import { FormLayout, useOpenAPIMethod } from '@iptor/business';
import { Box, Grid2 as Grid } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CreateSalesOrderSteps, { FormStep } from '../../components/CreateSalesOrderSteps';
import { Order } from '../../constants';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import MoreInfo from './components/MoreInfo';
import OrderDetails from './components/OrderDetails';

const Container = styled(Box)`
  width: 100%;
`;

const CreateSalesOrderSteps1 = () => {
  const history = useHistory();
  const { notify } = useOrderNotifications();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Partial<Order>>();

  const { t } = useIptorTranslation();
  const { closeVariantSnackbar } = useCustomSnackbar();
  const [currentSelectedCustomer, setCurrentSelectedCustomer] = useState<string>('');
  const createSalesOrder = useOpenAPIMethod('internal.v1.base-orders', 'POST /salesOrders');

  useEffect(() => {
    if ((createSalesOrder?.response as any)?.data?.messages) {
      notify('warning', (createSalesOrder?.response as any)?.data?.messages, true);
    }
    if (createSalesOrder?.response?.data?.data?.order) {
      notify('success', 'Order Created Successfully');
      history.push(`/orders/${createSalesOrder.response.data.data.order}/items?create=true`);
    }
  }, [createSalesOrder?.response]);

  useEffect(() => {
    if (createSalesOrder?.error) {
      if (axios.isAxiosError(createSalesOrder.error)) {
        const axiosError = createSalesOrder.error as AxiosError;
        notify('error', (axiosError.response?.data as { messages: string })?.messages);
      } else {
        notify('error', createSalesOrder.error.message, true);
      }
    }
  }, [createSalesOrder?.error]);

  const callApi = (errors: FieldErrors<Partial<Order>>, data: Partial<Order>) => {
    if (Object.keys(errors).length > 0) {
      setError('customer', { type: 'required', message: t('common:TXT_Customer_Mandatory') });
      setError('warehouse', { type: 'required', message: t('common:TXT_From_Warehouse_Mandatory') });
      setError('dispatchAddress', { type: 'required', message: t('common:TXT_Deliver_Address_Mandatory') });
    } else {
      createSalesOrder.execute(null, {
        handler: data?.handler,
        customer: data?.customer,
        orderType: data?.orderType,
        dispatchAddress: +data?.dispatchAddress,
        dispatchTime: data?.dispatchTime,
        inventoryContractNumber: data?.inventoryContractNumber,
        orderDate: data?.orderDate,
        orderReference: data?.orderReference,
        orderPropertyCode: data?.orderPropertyCode,
        pricingDate: data?.pricingDate,
        primaryCurrency: data?.primaryCurrency,
        orderTime: data?.orderTime,
        salesman: data?.salesman,
        salesPromotion: data?.salesPromotion,
        warehouse: data?.warehouse,
        toWarehouse: data?.toWarehouse,
      });
    }
  };

  const onSubmit = handleSubmit((data) => {
    callApi(errors, data);
  });

  const handleClose = () => {
    closeVariantSnackbar('error');
    history.goBack();
  };

  return (
    <FormLayout
      title={t('common:TXT_Create_new_sales_order')}
      actions={[
        <Button id="close" variant="outlined" size="medium" onClick={handleClose}>
          {t('common:TXT_Close')}
        </Button>,
        <Button
          form="createOrderForm"
          type="submit"
          id="next"
          variant="contained"
          size="medium"
          loading={createSalesOrder?.loading}
        >
          {t('common:TXT_Next')}
        </Button>,
      ]}
    >
      <Container>
        <Grid
          component="form"
          onSubmit={onSubmit}
          id="createOrderForm"
          container
          direction="column"
          spacing={1}
          noValidate
        >
          <CreateSalesOrderSteps
            watch={watch}
            setValue={setValue}
            reset={reset}
            control={control}
            getValues={getValues}
            errors={errors}
            clearErrors={clearErrors}
            currentStep={FormStep.ORDER_DETAILS}
          />

          <Text sx={{ margin: '8px 16px', fontWeight: 600 }} color="foreground.100">
            {t('common:TXT_Order_Header_Details')}
          </Text>

          <OrderDetails
            watch={watch}
            errors={errors}
            clearErrors={clearErrors}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            setChoosenCustomer={(customer: string) => {
              setCurrentSelectedCustomer(customer);
            }}
            control={control}
          />
          <MoreInfo choosenCustomer={currentSelectedCustomer} errors={errors} watch={watch} control={control} />
        </Grid>
      </Container>
    </FormLayout>
  );
};

const CreateSalesOrder = () => {
  return <CreateSalesOrderSteps1 />;
};

export default CreateSalesOrder;

import React from 'react';

export default (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" color="inherit">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.75 5.25L8 1.5L1.25 5.25V12.75L8 16.5L14.75 12.75V5.25ZM8 11.5C9.43488 11.5 10.5981 10.3807 10.5981 9C10.5981 7.61929 9.43488 6.5 8 6.5C6.56512 6.5 5.40192 7.61929 5.40192 9C5.40192 10.3807 6.56512 11.5 8 11.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

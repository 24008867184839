import { Theme } from '@mui/material';

export const IconButton = {
  defaultProps: {
    size: 'medium', // default size
    variant: 'outlined', // default variant
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: theme.spacing(1), // Use theme spacing
      padding: theme.spacing(1.5),
      borderRadius: 4,
      fontSize: 16,
      alignSelf: 'center',
      height: 'fit-content',
      color: `${theme.palette.foreground[500]}`,
      svg: {
        cursor: 'pointer',
        width: 20,
        height: 20,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.foreground[400],
        outlineColor: theme.palette.foreground[400],
      },

      // Handle variants and loading
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: theme.spacing(1),
            fontSize: 14,
            svg: {
              width: 16,
              height: 16,
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: theme.spacing(2.5),
            fontSize: 18,
            svg: {
              width: 24,
              height: 24,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            ':hover': {
              backgroundColor: theme.palette.primary[500],
            },
            ':focus': {
              backgroundColor: theme.palette.primary[500],
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`,
            },
            ':active': {
              backgroundColor: theme.palette.primary.main,
            },
            '&.MuiIconButton-loading:disabled': {
              color: theme.palette.common.white, // Use white
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            outline: `solid 2px ${theme.palette.primary.main}`,
            ':hover': {
              backgroundColor: theme.palette.success.light,
            },
            ':focus': {
              backgroundColor: theme.palette.success.light,
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`,
            },
            ':active': {
              backgroundColor: theme.palette.success.light,
            },
            '&.MuiIconButton-loading:disabled': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
              outline: `solid 2px ${theme.palette.primary.main}`,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: theme.palette.primary[400],
            ':hover': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.primary.main,
            },
            ':focus': {
              outline: `solid 2px ${theme.palette.tertiary.cyprus}`,
              color: theme.palette.primary.main,
            },
            ':active': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.primary.main,
            },
            '&.MuiIconButton-loading:disabled': {
              backgroundColor: theme.palette.success.light,
              color: theme.palette.primary.main,
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: theme.palette.error.main,
            ':hover': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
            },
            ':focus': {
              outline: `solid 2px ${theme.palette.error.main}`,
              color: theme.palette.error.main,
            },
            ':active': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
            },
            '&.MuiIconButton-loading:disabled': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.error.main,
            },
          },
        },
      ],
    }),
  },
};

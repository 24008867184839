import { useEffect } from 'react';
import { LoadingSetters } from '..';
import { useCustomAuth } from '@iptor/base';

export const Logout = (props: LoadingSetters) => {
  const { getUser, signoutRedirect } = useCustomAuth((auth) => ({
    getUser: auth.getUser,
    signoutRedirect: auth.signoutRedirect,
  }));
  const user = getUser();
  useEffect(() => {
    props.startLoading();
    sessionStorage.clear();
    console.log('logout');
    signoutRedirect({
      redirectMethod: 'replace',
      id_token_hint: user?.id_token,
      post_logout_redirect_uri: window.location.origin,
    })
      .catch((error: Error) => console.error(error))
      .finally(() => {
        props.endLoading();
        return false; // Added to prevent NS_BINDING_ABORTED in firefox
      });
  }, [user?.id_token, signoutRedirect]);
  return <p>Logout</p>;
};

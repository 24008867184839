import { Button, DialogLayout, Text, TextField, useIptorTranslation } from '@iptor/base';
import {
  AddressCategoryDropdown,
  AddressCountryDropdown,
  AddressCountyDropdown,
  AddressDepartureDropdown,
  AddressDestinationDropdown,
  AddressesDropdown,
  AddressRouteDropdown,
  AddressSourcingPolicyDropdown,
  AddressStateProvinceDropdown,
  openAPIManager,
  OperationResponseItems,
  ShippingAgentDropdown,
} from '@iptor/business';
import { Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm, UseFormWatch } from 'react-hook-form';
import { useOrderNotifications } from '../../hooks/useOrderNotification';
import { formatErrorText } from '../../utils/formatError';
import { fieldConfig } from './Constants';
import { AddressTypesTranslation } from '../../components/addresses/constants';
import LiveSearchCustomersWithTable from '../../components/LiveSearchCustomersWithTable';

type AddressType = OperationResponseItems<'internal.v1.base-orders', 'GET /salesOrders/{order}/addresses'>[0];

interface AddressFormValues {
  addressNumber: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postalCode: string;
  country: string;
  county: string;
  state: string;
  name: string;
  shipPartner: string;
  route: string;
  departure: string;
  shippingAgent: string;
  destination: string;
  sourcingPolicy: string;
  addressCategory: string;
  invoiceCustomer: string;
  debtor: string;
  vatRegistrationNo: string;
}

type ValidationState = 'M' | 'N' | 'O';

interface Props {
  open: boolean;
  handleClose: (refresh?: boolean) => void;
  customer: string;
  address: AddressType;
}

function EditAddress({ handleClose, open, customer, address }: Props) {
  const { t } = useIptorTranslation();
  const [countryValidation, setCountryValidation] = useState<{
    showState: ValidationState;
    showCounty: ValidationState;
  }>({
    showState: undefined,
    showCounty: undefined,
  });

  // M mandatory
  // O obligatory
  // N not allowed

  const [loading, setLoading] = useState(false);
  const [isManualEdit, setIsManualEdit] = useState(false);
  const isDebtorType = address.addressType === 'P';

  const { notify } = useOrderNotifications();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<AddressFormValues>({
    defaultValues: {
      addressNumber: address.address,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      addressLine4: address.addressLine4,
      postalCode: address.postalCode,
      country: address.country,
      county: address.county,
      state: address.state,
      name: address.name,
      sourcingPolicy: address.sourcingPolicy,
      addressCategory: address.addressCategory,
      vatRegistrationNo: address.vatRegistrationNo,
      shippingAgent: address.shippingAgent,
      destination: address.destination,
      route: address.route,
      departure: address.departure,
      // Not address specific variables
      invoiceCustomer: address.invoiceCustomer,
      shipPartner: address.shipPartner,
      debtor: address.debtor,
    },
  });
  useEffect(() => {
    if (watch('country')) {
      openAPIManager
        .execute('internal.v1.base-tables', 'GET /countries/{country}', {
          country: watch('country'),
        })
        .then((response) => {
          setCountryValidation({
            showState: response?.data?.data?.validateState as ValidationState,
            showCounty: response?.data?.data?.validateCounty as ValidationState,
          });
        });
    }
  }, [watch('country')]);

  const onFieldChange = () => {
    setIsManualEdit(true);
    setValue('addressNumber', 999);
  };

  const onSubmit = async (data: AddressFormValues) => {
    setLoading(true);
    const { addressNumber, invoiceCustomer, shipPartner, debtor, ...addressData } = data;
    const extraCustomerData = {
      invoiceCustomer,
      shipPartner,
      debtor,
    };

    openAPIManager
      .execute(
        'internal.v1.base-orders',
        'PUT /salesOrders/{order}/addresses/{addressType}',
        {
          addressType: address.addressType,
          order: address.order,
        },
        addressNumber === 999
          ? { address: addressNumber, ...addressData, ...extraCustomerData }
          : { address: addressNumber, ...extraCustomerData },
      )
      .then(() => {
        handleClose(true);
        notify('success', t('sales_orders:ADDRESS_updateSuccess'));
      })
      .catch((error) => {
        notify('error', formatErrorText(error), false, t('sales_orders:ADDRESS_updateError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disableEdit = (watch: UseFormWatch<AddressFormValues>) => {
    const isAddressUnchanged = watch('addressNumber') === address.address;
    const isInvoiceCustomerUnchanged = watch('invoiceCustomer') === address.invoiceCustomer;
    const isShipPartnerUnchanged = watch('shipPartner') === address.shipPartner;
    const isDebtorUnchanged = watch('debtor') === address.debtor;
    const isNotManualEdit = !isManualEdit;
    const isAddressEmpty = !watch('addressNumber');

    const areTheExtraValuesUnchanged =
      isInvoiceCustomerUnchanged && isShipPartnerUnchanged && isDebtorUnchanged && isAddressUnchanged;

    return (areTheExtraValuesUnchanged && isNotManualEdit) || isAddressEmpty;
  };

  const getDisplay = (fieldName: string, validationState?: ValidationState) => {
    if (validationState === 'N') return 'none';

    return fieldConfig[address.addressType as 'C' | 'D' | 'S' | 'I'].includes(fieldName) ? 'block' : 'none';
  };

  const getCurrentCustomer = (address: AddressType, watch: (name: string) => string, customer: string) => {
    if (address.addressType === 'P') return watch('debtor');
    if (address.addressType === 'S') return watch('shipPartner');
    if (address.addressType === 'I') return watch('invoiceCustomer');
    return customer;
  };

  return (
    <DialogLayout
      title={`${t('sales_orders:ADDRESS_editTitle')} / ${t(AddressTypesTranslation[address.addressType as keyof typeof AddressTypesTranslation] as any)}`}
      actions={[
        <Button variant="outlined" id="discountCancel" size="medium" onClick={() => handleClose(false)} key="cancel">
          {t('sales_orders:DISCOUNT_cancel')}
        </Button>,
        <Button
          variant="contained"
          size="medium"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          key="submit"
          id="update"
          type="submit"
          form="editAddressForm"
          disabled={disableEdit(watch)}
        >
          {t('common:TXT_Update')}
        </Button>,
      ]}
      open={open}
      onClose={() => handleClose(false)}
    >
      <form style={{ marginRight: '8px' }} id="editAddressForm">
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid size={12}>
            <Text>
              {t('sales_orders:ADDRESS_editPrompt')} <b>{t('sales_orders:ADDRESS_proceed')}</b>
            </Text>
          </Grid>
          <Grid size={12 / 2} display={getDisplay('addressNumber')}>
            <Controller
              name="addressNumber"
              control={control}
              render={({ field }) => (
                <AddressesDropdown
                  businessPartner={getCurrentCustomer(address, watch, customer)}
                  {...field}
                  id={field.name}
                  onChange={(value, selectedAddress) => {
                    if (!selectedAddress && watch('addressNumber') === 999) {
                      return;
                    }
                    const fieldsToUpdate = {
                      addressNumber: selectedAddress?.addressNumber || ('' as any),
                      addressLine1: selectedAddress?.addressLine1 || '',
                      addressLine2: selectedAddress?.addressLine2 || '',
                      addressLine3: selectedAddress?.addressLine3 || '',
                      addressLine4: selectedAddress?.addressLine4 || '',
                      postalCode: selectedAddress?.postalCode || '',
                      country: selectedAddress?.country || '',
                      county: selectedAddress?.countyCode || '',
                      state: selectedAddress?.stateCode || '',
                      name: selectedAddress?.name || '',
                      shippingAgent: selectedAddress?.shippingAgent || '',
                      destination: selectedAddress?.destinationId || '',
                      addressCategory: selectedAddress?.addressCategory || '',
                      vatRegistrationNo: selectedAddress?.vatRegistrationNumber || '',
                      route: (selectedAddress as any)?.route || '',
                      departure: (selectedAddress as any)?.departure || '',
                    };

                    const preservedValues = {
                      invoiceCustomer: watch('invoiceCustomer'),
                      debtor: watch('debtor'),
                      shipPartner: watch('shipPartner'),
                      sourcingPolicy: watch('sourcingPolicy'),
                    };

                    reset({ ...fieldsToUpdate, ...preservedValues });

                    setIsManualEdit(false);
                    field.onChange(value || '');
                  }}
                  value={watch('addressNumber')}
                  filterAddressType={address.addressType as 'S' | 'D' | 'I' | 'C' | 'P'}
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('shipPartner')}>
            <Controller
              name="shipPartner"
              control={control}
              render={({ field }) => (
                <LiveSearchCustomersWithTable
                  setValue={(name, value) => {
                    field.onChange(value || '');
                  }}
                  selectedValue={field.value || ''}
                  autoFocus
                  fullWidth
                  fullWidthTable={true}
                  label={t('sales_orders:ADDRESS_shipPartner')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('sourcingPolicy')}>
            <Controller
              name="sourcingPolicy"
              control={control}
              render={({ field }) => (
                <AddressSourcingPolicyDropdown
                  {...field}
                  onChange={(value) => {
                    onFieldChange();
                    field.onChange(value || '');
                  }}
                  value={watch('sourcingPolicy')}
                  id={field.name}
                  label={t('sales_orders:ADDRESS_sourcingPolicy')}
                  fullWidth
                />
              )}
              disabled={isDebtorType}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('addressCategory')}>
            <Controller
              name="addressCategory"
              control={control}
              render={({ field }) => (
                <AddressCategoryDropdown
                  {...field}
                  onChange={(value) => {
                    onFieldChange();
                    field.onChange(value || '');
                  }}
                  id={field.name}
                  value={watch('addressCategory')}
                  label={t('sales_orders:ADDRESS_addressCategory')}
                  fullWidth
                  disabled={isDebtorType}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('invoiceCustomer')}>
            <Controller
              name="invoiceCustomer"
              control={control}
              render={({ field }) => (
                <LiveSearchCustomersWithTable
                  setValue={(name, value) => {
                    field.onChange(value || '');
                  }}
                  id={field.name}
                  selectedValue={field.value || ''}
                  autoFocus
                  fullWidth
                  fullWidthTable={true}
                  label={t('sales_orders:ADDRESS_invoiceCustomer')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('debtor')}>
            <Controller
              name="debtor"
              control={control}
              render={({ field }) => (
                <LiveSearchCustomersWithTable
                  setValue={(name, value) => {
                    field.onChange(value || '');
                  }}
                  id={field.name}
                  selectedValue={field.value || ''}
                  autoFocus
                  fullWidth
                  fullWidthTable={true}
                  label={t('sales_orders:ADDRESS_debtor')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 2} display={getDisplay('vatRegistrationNo')}>
            <Controller
              name="vatRegistrationNo"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id={field.name}
                  label={t('sales_orders:ADDRESS_vatRegistationNumber')}
                  {...field}
                  value={watch('vatRegistrationNo') || ''}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                  disabled={isDebtorType}
                />
              )}
            />
          </Grid>
        </Grid>
        <Text marginTop={3} fontSize={16}>
          {t('sales_orders:ADDRESS_information')}
        </Text>
        <Grid container columnSpacing={3} rowSpacing={1} alignItems="flex-end">
          <Grid size={12 / 4} display={getDisplay('name')}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t('sales_orders:ADDRESS_labelAddress')}
                  {...field}
                  id={field.name}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                  required
                  disabled={isDebtorType}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('addressLine1')}>
            <Controller
              name="addressLine1"
              control={control}
              render={({ field }) => (
                <TextField
                  id={field.name}
                  fullWidth
                  {...field}
                  disabled={isDebtorType}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('addressLine2')}>
            <Controller
              name="addressLine2"
              control={control}
              disabled={isDebtorType}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id={field.name}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('addressLine3')}>
            <Controller
              name="addressLine3"
              control={control}
              disabled={isDebtorType}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id={field.name}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('postalCode')}>
            <Controller
              name="postalCode"
              control={control}
              disabled={isDebtorType}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id={field.name}
                  label={t('sales_orders:ADDRESS_labelPostalCode')}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                  autoComplete="off"
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('country')}>
            <Controller
              name="country"
              control={control}
              disabled={isDebtorType}
              render={({ field }) => (
                <AddressCountryDropdown
                  {...field}
                  id={field.name}
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                    setValue('state', '');
                    setValue('county', '');
                  }}
                  value={watch('country')}
                  fullWidth
                  label={t('sales_orders:ADDRESS_labelCountry')}
                />
              )}
            />
          </Grid>
          <Grid
            size={12 / 4}
            display={getDisplay(
              'state',
              countryValidation.showState ? countryValidation.showState : watch('state') ? 'M' : 'N',
            )}
          >
            <Controller
              name="state"
              control={control}
              disabled={isDebtorType}
              rules={{ required: countryValidation.showState === 'M' }}
              render={({ field }) => (
                <AddressStateProvinceDropdown
                  {...field}
                  id={field.name}
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                    setValue('county', '');
                  }}
                  value={watch('state')}
                  fullWidth
                  country={watch('country')}
                  label={t('sales_orders:ADDRESS_labelState')}
                  required={countryValidation.showState === 'M'}
                  helperText={errors.state && t('common:TXT_Required')}
                  color={errors.state ? 'error' : 'primary'}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('county', countryValidation.showState)}>
            <Controller
              name="county"
              control={control}
              disabled={isDebtorType}
              rules={{
                required: countryValidation.showCounty === 'M',
              }}
              render={({ field }) => (
                <AddressCountyDropdown
                  {...field}
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                  }}
                  id={field.name}
                  value={watch('county')}
                  fullWidth
                  country={watch('country')}
                  state={watch('state')}
                  label={t('sales_orders:ADDRESS_labelCounty')}
                  required={countryValidation.showCounty === 'M'}
                  helperText={errors.county && t('common:TXT_Required')}
                  color={errors.county ? 'error' : 'primary'}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('addressLine4')}>
            <Controller
              name="addressLine4"
              control={control}
              disabled={isDebtorType}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id={field.name}
                  label={t('sales_orders:ADDRESS_labelTown')}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    onFieldChange();
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Text marginTop={2} fontSize={16} hidden={address.addressType !== 'D' && address.addressType !== 'S'}>
          {t('common:TXT_More_Info')}
        </Text>
        <Grid container columnSpacing={3} rowSpacing={1} alignItems="flex-end">
          <Grid size={12 / 4} display={getDisplay('route')}>
            <Controller
              name="route"
              control={control}
              render={({ field }) => (
                <AddressRouteDropdown
                  {...field}
                  fullWidth
                  id={field.name}
                  onChange={(value) => {
                    onFieldChange();
                    field.onChange(value || '');
                  }}
                  disabled={isDebtorType}
                  value={watch('route')}
                  label={t('sales_orders:ADDRESS_route')}
                />
              )}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('departure')}>
            <Controller
              name="departure"
              control={control}
              render={({ field }) => (
                <AddressDepartureDropdown
                  {...field}
                  id={field.name}
                  fullWidth
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                  }}
                  value={watch('departure')}
                  label={t('sales_orders:ADDRESS_departure')}
                />
              )}
              disabled={isDebtorType}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('shippingAgent')}>
            <Controller
              name="shippingAgent"
              control={control}
              render={({ field }) => (
                <ShippingAgentDropdown
                  {...field}
                  fullWidth
                  id={field.name}
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                  }}
                  value={watch('shippingAgent')}
                  label={t('sales_orders:ADDRESS_shippingAgent')}
                />
              )}
              disabled={isDebtorType}
            />
          </Grid>
          <Grid size={12 / 4} display={getDisplay('destination')}>
            <Controller
              name="destination"
              control={control}
              render={({ field }) => (
                <AddressDestinationDropdown
                  {...field}
                  fullWidth
                  id={field.name}
                  onChange={(value) => {
                    field.onChange(value || '');
                    onFieldChange();
                  }}
                  value={watch('destination')}
                  label={t('sales_orders:ADDRESS_destination')}
                />
              )}
              disabled={isDebtorType}
            />
          </Grid>
        </Grid>
      </form>
    </DialogLayout>
  );
}

export default EditAddress;

import { FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';
import '../styles/Label.scss';
import React from 'react';

type LabelProps = {
  className?: string;
  id: string;
  src: string;
  formID?: string;
  panelID?: string;
  visible?: boolean;
  disabled?: boolean;
};

export const ImageComponent: FunctionComponent<LabelProps> = ({ src, id }) => (
  <div
    data-for="global"
    data-iscapture="true"
    data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
  >
    <Image id={id} src={src}></Image>
  </div>
);

import { Column, LiveSearchBase, LiveSearchBaseProps } from '@iptor/base';
import {
  OpenAPIId,
  OperationId,
  OperationParameters,
  OperationResponseItemsColumnId,
} from '../../../framework/openapi/manager';
import { useOpenAPIMethod } from '../../../framework/openapi/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
// import axios from 'axios'; // neded for LiveSearchEndpointWrapper

interface LiveSearchBaseWrapperProps extends Omit<LiveSearchBaseProps, 'rows' | 'getRows' | 'isLoading' | 'columns'> {
  limit?: number;
  additionalSearchParams?: Record<string, string | number | boolean>;
}
export interface LiveSearchApiWrapperProps<T extends OpenAPIId, K extends OperationId<T>>
  extends Omit<LiveSearchBaseWrapperProps, 'columnKey' | 'additionalSearchParams'> {
  apiClient: T;
  apiFunction: K;
  columnIds?: OperationResponseItemsColumnId<T, K>[];
  columnKey: OperationResponseItemsColumnId<T, K>;
  additionalSearchParams?: Partial<OperationParameters<T, K>>;
}

// get header, if no header, then you can base header on column names and use that function
const camelCaseToCapitalizedFlat = (camelCaseInput: string) => {
  const camelCaseBase = camelCaseInput.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  let flat = '';
  camelCaseBase.forEach((word: string) => {
    flat += capitalize(word) + ' ';
  });
  return flat.substring(0, flat.length - 1);
};
const capitalize = (word: string) => {
  return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
};

export const LiveSearchApiWrapper = <T extends OpenAPIId, K extends OperationId<T>>({
  limit = 50,
  apiClient,
  apiFunction,
  columnKey,
  columnIds,
  additionalSearchParams,
  ...props
}: LiveSearchApiWrapperProps<T, K>) => {
  const defaultColumns = useRef<Column[] | undefined>(undefined);

  const getApiFunction = useOpenAPIMethod(apiClient, apiFunction);
  const rows = getApiFunction.response?.data?.data?.items || [];

  const search = useCallback(
    (searchText: string): void => {
      getApiFunction.execute({ limit: limit, freeTextSearch: searchText, ...additionalSearchParams });
    },
    [getApiFunction.execute, limit, additionalSearchParams],
  ); // TODO: check

  const _columns: Column[] | undefined = useMemo(() => {
    if (columnIds && columnIds.length > 0) {
      const __columns = columnIds.map((colId) => ({
        id: colId as string,
        header: camelCaseToCapitalizedFlat(colId as string),
      }));

      if (__columns.find((column) => column.id === columnKey.toString())) {
        return __columns;
      } else {
        // include columnKey inside columns, when missing (default hidden)
        return [...__columns, { header: columnKey.toString(), id: columnKey.toString(), hidden: true }];
      }
    }
    return undefined;
  }, [columnIds, columnKey]);

  useEffect(() => {
    if (rows.length > 0 && !defaultColumns.current) {
      if (_columns) {
        defaultColumns.current = _columns;
      } else {
        defaultColumns.current = Object.keys(rows[0] as Record<string, unknown>).map((key) => ({
          id: key,
          header: camelCaseToCapitalizedFlat(key),
        }));
      }
    }
  }, [rows, _columns]);

  return (
    <LiveSearchBase
      {...props}
      columnKey={columnKey.toString()}
      isLoading={getApiFunction.loading}
      rows={rows}
      getRows={search}
      columns={_columns || defaultColumns.current}
    />
  );
};

// --- LiveSearchEndpointWrapper --
// [ outdated ] - review before usage
// use direct axios call instead of openApiMethod
// export interface LiveSearchEndpointWrapperProps extends LiveSearchBaseWrapperProps {
//   endpoint: string;
// }
// export const LiveSearchEndpointWrapper = ({
//   limit = 50,
//   minLettersToRun = 2,
//   label,
//   endpoint,
//   columnKey,
//   columns,
//   handleViewAll,
//   onRowClick,
// }: LiveSearchEndpointWrapperProps) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [data, setData] = useState<Record<string, any>[]>([]);

//   const search = (searchText: string): void => {
//     const constValueToFormatMiliseconds = 36; //TODO: get it from config
//     const date = new Date();
//     const millisec = date.getTime();
//     const id = millisec.toString(constValueToFormatMiliseconds);
//     const payload = {
//       IptorAPI: '1.0',
//       control: {
//         freeTextSearch: searchText,
//         limit: limit,
//       },
//       id: id,
//     };
//     try {
//       setIsLoading(true);
//       axios
//         .post(endpoint, payload)
//         .then((response) => {
//           setData(response?.data?.data?.items || []);
//         })
//         .finally(() => setIsLoading(false));
//     } catch (error) {
//       console.error(`Error from getAutoCompleteData ${error}`);
//     }
//   };

//   return (
//     <BaseLiveSearch
//       minLettersToRun={minLettersToRun}
//       label={label}
//       isLoading={isLoading}
//       columnKey={columnKey}
//       options={data}
//       getOptions={search}
//       columns={columns}
//       handleViewAll={handleViewAll}
//       onRowClick={onRowClick}
//     />
//   );
// };

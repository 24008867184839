import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Theme,
  useTheme,
  Grid2 as Grid,
  Box,
} from '@mui/material';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TextSize } from 'src/constants/Typography';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Text } from '../Text';
import { Dialog } from '../Dialog';
import { Variant } from 'react-bootstrap/esm/types';
import { VariantType } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

import { TextField } from '../TextField';

export interface ConfirmationDialogOptions {
  title?: string | ReactNode;
  message?: string | ReactNode;
  overlayContainerId?: string;
  icon?: 'error' | 'warning'; // VariantType;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: (inputValue?: string) => void;
  onCancel?: () => void;
  paperProps?: SxProps;
  slotProps?: {
    dialogContent?: SxProps;
    dialogTitle?: SxProps;
  };
  completeScreenBlock?: boolean;
  input?: 'text' | 'textArea';
  inputValidator?: (value: string) => string;
  restrictOutsideClick?: boolean;
  hideCancelButton?: boolean;
  hideAlertIcon?: boolean;
}

export interface ConfirmationDialogContextType {
  showDialog: (options: ConfirmationDialogOptions) => void;
}

let confirmationDialogContext: ConfirmationDialogContextType | null = null;

export const setConfirmationDialogContext = (context: ConfirmationDialogContextType) => {
  confirmationDialogContext = context;
};

export const getConfirmationDialogContext = (): ConfirmationDialogContextType => {
  if (!confirmationDialogContext) {
    throw new Error(
      'ConfirmationDialogContext is not set. Make sure your app is wrapped in ConfirmationDialogProvider.',
    );
  }
  return confirmationDialogContext;
};

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType | null>(null);

export const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext);
  if (!context) {
    throw new Error('useConfirmationDialog must be used within a DialogProvider');
  }
  return context;
};

export const ConfirmationDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogOptions, setDialogOptions] = useState<ConfirmationDialogOptions | null>(null);
  const [inputType, setInputType] = useState<'text' | 'textArea' | null>(null);
  const [errorText, setErrorText] = useState<string>('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [inputRef?.current]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ textValue: string }>({});

  const theme: Theme = useTheme();

  const showDialog = (options: ConfirmationDialogOptions) => {
    setDialogOptions(options);
    setErrorText('');
    setInputType(options.input || null);
    setValue('textValue', '');
    inputRef?.current && inputRef?.current?.focus();
  };

  const closeDialog = () => {
    dialogOptions?.onCancel?.();
    setDialogOptions(null);
    setErrorText('');
    setValue('textValue', '');
  };

  const contextValue: ConfirmationDialogContextType = { showDialog };
  setConfirmationDialogContext(contextValue); // Register the context globally

  const onSubmit = handleSubmit((data) => {
    if (dialogOptions.inputValidator) {
      const validationError = dialogOptions?.inputValidator(data?.textValue);
      if (validationError) {
        setErrorText(validationError);
        inputRef?.current && inputRef?.current?.focus();
        return;
      }
    }
    // if (dialogOptions.hideCancelButton) {
    //   dialogOptions?.onCancel?.();
    // }
    dialogOptions.onConfirm?.(data?.textValue);
    setDialogOptions(null);
  });

  return (
    <ConfirmationDialogContext.Provider value={contextValue}>
      {children}
      {dialogOptions && (
        <Dialog
          windowId={dialogOptions.overlayContainerId}
          PaperProps={{
            sx: {
              width: '582px',
              height: '252px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: '8px',
              padding: '8px 0px ',
              paddingBottom: '16px',
              position: 'relative',
              transform: 'translateY(-10%)',
              ...dialogOptions?.paperProps,
            },
          }}
          sx={{
            '.MuiBackdrop-root.MuiModal-backdrop': {
              top: dialogOptions?.completeScreenBlock ? '0px' : '94px',
              left: dialogOptions?.completeScreenBlock ? '0px' : '0px',
            },
            zIndex: dialogOptions?.completeScreenBlock ? 1000 : 100,
          }}
          open
          onClose={(event, reason) => {
            if (reason === 'backdropClick' && !dialogOptions?.restrictOutsideClick) {
              closeDialog();
            }
          }}
          maxWidth="sm"
          fullWidth
        >
          {!dialogOptions?.hideAlertIcon && (
            <DialogTitle padding={'16px 24px'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Stack display={'flex'} flexDirection={'row'} gap="8px">
                <Icon
                  color={dialogOptions?.icon === 'error' ? theme.palette.error.main : theme.palette.warning.main}
                  icon={dialogOptions?.icon === 'error' ? 'alertCircle' : 'alert01'}
                  size="L"
                />
                <Text variant="h4" sx={{ fontSize: '20px', lineHeight: 1.5 }}>
                  {dialogOptions?.title || ''}
                </Text>
              </Stack>
              <IconButton
                icon="close"
                variant="text"
                size="large"
                sx={{ color: theme.palette.foreground[400], padding: '0px!important' }}
                onClick={closeDialog}
              />
            </DialogTitle>
          )}

          <DialogContent sx={{ padding: '16px 32px', ...dialogOptions?.slotProps?.dialogContent }}>
            {dialogOptions.message && (
              <Text variant="body1" sx={{ fontSize: TextSize.S, color: theme.palette.foreground[500] }}>
                {dialogOptions.message}
              </Text>
            )}

            <Grid component="form" onSubmit={onSubmit} id="dialogForm" container direction="column" spacing={1}>
              {inputType && (
                <TextField
                  onChange={(e) => {
                    setErrorText('');
                    setValue('textValue', e?.target?.value as string);
                  }}
                  autoFocus
                  inputRef={inputRef}
                  helperText={errorText}
                  color={errorText ? 'error' : 'primary'}
                  fullWidth
                  rows={inputType === 'textArea' ? 4 : undefined}
                  multiline={inputType === 'textArea'}
                />
              )}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: '0px 24px', minHeight: '60px' }}>
            {!dialogOptions.hideCancelButton && (
              <Button onClick={closeDialog} variant="outlined">
                {dialogOptions.cancelText || 'Cancel'}
              </Button>
            )}

            <Button form="dialogForm" type="submit" variant="contained">
              {dialogOptions.confirmText || 'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationDialogContext.Provider>
  );
};

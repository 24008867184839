// import DA from './da';
// import DE from './de';
import EN from './en';
// import ES from './es';
// import ES_CO from './es_co';
// import ES_MX from './es_mx';
// import FI from './fi';
// import FR from './fr';
// import IT from './it';
// import NL from './nl';
// import NL_BE from './nl_be';
// import NO from './no';
// import PL from './pl';
// import PT from './pt';
// import SV from './sv';
// import ZH from './zh';

export type Locale = string;

export const Translations: Record<string, Record<string, string>> = {
  // [Locale.DA]: DA,
  // [Locale.DE]: DE,
  // [Locale.EN]: EN
  // [Locale.ES]: undefined,
  // [Locale.ES_CO]: ES_CO,
  // [Locale.ES_MX]: ES_MX,
  // [Locale.FI]: FI,
  // [Locale.FR]: FR,
  // [Locale.IT]: IT,
  // [Locale.NL]: NL,
  // [Locale.NL_BE]: NL_BE,
  // [Locale.NO]: NO,
  // [Locale.PL]: PL,
  // [Locale.PT]: PT,
  // [Locale.SV]: SV,
  // [Locale.ZH]: ZH,
};

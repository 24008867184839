import { KeyboardEvent, FocusEvent, FunctionComponent, useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Window, WindowProps } from '../partials/Window';
import { DashboardEditor } from '../../components/DashboardEditor';
import '../../styles/Desktop.scss';
import { WindowActions } from '../../types/actionTypes';
import { CloseButton, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Command } from '../../framework/base/command';
import React from 'react';
import hotkeys from 'hotkeys-js';
import { ApplicationWindow, ApplicationWindowProps } from 'views/partials/ApplicationWindow';
import { WindowManger } from '../../framework/base/windowManager';
import { SquareIcon, Icons } from '../../components/SquareIcon';
import { Localization, LocalizationInfo } from '../../framework/localization/Localization';
import { LocaleContext } from '../../App';
import { XT } from '../../framework/handlers/xt';
import { AperioWindow } from '../partials/AperioWindow';
import { TooltipContext } from '../../helpers/TooltipContext';
import { SelectView } from '../partials/SelectView';
import { RootState } from '../../framework/base';
import { RequestError } from '../../types/RequestError';
import { RequestErrorView } from '../RequestErrorView';
import { connectLoader } from '../../hooks/connect-loader';
import { NotesProvider } from '../../framework/your-note/NotesProvider';
import { Socket } from 'socket.io-client';
import { Setting } from '../partials/Setting';
import { RoleTemplates } from '../partials/RoleTemplates';
import { CopyableTooltip, useTooltipVisibility } from '@iptor/base';

type DesktopProps = {
  windows: Record<string, WindowProps>;
  settings: Record<string, any>;
  activeWindow: string;
  switchTab: (id: string) => void;
  changeLocaleSettings: (localeSettings: LocalizationInfo.Settings) => void;
  startLoading: () => void;
  endLoading: () => void;
  loading: boolean;
  rename: () => void;
  edit: () => void;
  editing: boolean;
  localeSettings: LocalizationInfo.Settings;
  CloseWindow?: Function;
  startExport?: (windowID: string, title: string, cb: (data: Record<string, any>) => void) => void;
  stopExport?: (windowID: string, abort?: boolean) => void;
  flattenedMenu?: Record<string, any>;
  handleShowMenu: (menuId: string) => void;
  mainMenu: any;
  handleShowFavourites: () => void;
  socket?: Socket;
  quickLaunch?: Function;
  getMenuItems?: Function;
};

type WindowInfo = {
  data: Record<string, any>; //        contains the last data received from the server, used to detect server interaction (<== when changed)
  activeElement?: Element; //          contains the last element within a window that had focus before the window itself lost focus
};

type WindowsInfo = {
  activeWindow?: string;
  windows?: Record<string, WindowInfo>;
};

export const DesktopComponent: FunctionComponent<DesktopProps> = ({
  windows,
  settings,
  activeWindow,
  switchTab,
  changeLocaleSettings,
  startLoading,
  endLoading,
  loading,
  rename,
  edit,
  editing,
  localeSettings,
  CloseWindow,
  startExport,
  stopExport,
  flattenedMenu,
  handleShowMenu,
  mainMenu,
  handleShowFavourites,
  socket,
  quickLaunch,
  getMenuItems,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isSettingsShown, setIsSettingsShown] = useState<boolean>(false);
  const [roleTemplateOpen, setRoleTemplateOpen] = useState<boolean>(false);
  const refPreviousIsShow = useRef<boolean | undefined>(undefined);

  const { setTooltipVisibility } = useTooltipVisibility();

  const [showFunctionTooltip, setShowFunctionTooltip] = useState<boolean>(
    localStorage.getItem('showFunctionTooltip') === 'true' || false,
  );
  const [isShowSelectView, setIsShowSelectView] = useState<boolean>(false);
  // add state to move serverFocus execution to next render, when you will have all needed data (components need to set their tabindex first)
  const [serverFocus, setServerFocus] = useState<{ action: '' | 'SUBMIT' | 'REQUESTING' | 'EXECUTE' }>({ action: '' });
  const refPreviousServerFocus = useRef<{ action: '' | 'SUBMIT' | 'REQUESTING' | 'EXECUTE' }>({ action: '' });

  const refCurrentWindowId = useRef<string>('');
  const refPreviousWindowsInfo = useRef<WindowsInfo>({});
  const editBox = useRef<HTMLInputElement>(null);

  const refPreviousActiveWindow = useRef<string>('');
  const refPreviousWindows = useRef<Record<string, WindowProps>>({});
  const pendingRequestErrors = useSelector<RootState, RequestError.JSON | undefined>(
    (state) => state.desktop.requestErrors.pending,
  );

  const handleSettingsShow = () => setIsSettingsShown(!isSettingsShown);
  const handleRoleShow = () => setRoleTemplateOpen(!roleTemplateOpen);

  const switchTabOrEdit = (id: string) => {
    if (id === activeWindow && id !== 'dashboard') {
      //Show edit box for this field
      edit();
      setTimeout(() => {
        if (editBox) {
          editBox?.current?.focus();
        }
      }, 1000);
    } else {
      switchTab(id);
      //Update keybindings scope on switch
    }
  };
  const selectNextWindowTab = (selectPrevious = false) => {
    // Swith tab not allowed in modal mode
    if (XT.isApplicationInModalMode(loading)) return;

    // Get window ids (dashboard exclusive)                         Remark: alphanumeric Object.keys are sorted in their creation order
    const windowIds = Object.keys(windows).filter((id: string) => id !== 'dashboard');

    // No action needed if no other window available
    if (activeWindow === 'dashboard') {
      if (windowIds.length < 1) return;
    } else if (windowIds.length < 2) {
      handleClientFocus(); // if currently no focus (eg focus in contect area), moves focus to program tab
      return;
    }

    // Get index current window
    const currentIndex = windowIds.findIndex((id) => id === activeWindow);

    // Get index next window
    let nextIndex: number;
    if (!selectPrevious) {
      if (currentIndex < 0) {
        //case dashboard currently selected
        nextIndex = 0;
      } else {
        nextIndex = currentIndex + 1;
        if (nextIndex >= windowIds.length) nextIndex = 0;
      }
    } else {
      if (currentIndex < 0) {
        //case dashboard currently selected
        nextIndex = windowIds.length - 1;
      } else {
        nextIndex = currentIndex - 1;
        if (nextIndex < 0) nextIndex = windowIds.length - 1;
      }
    }

    // Select "next" tab
    const nextTabId = windowIds[nextIndex];
    switchTab(nextTabId);
  };
  const toggleFunctionTooltip = (tooltipBoolean: boolean) => {
    setShowFunctionTooltip(tooltipBoolean);
  };

  // old shouldComponentUpdate
  useEffect(() => {
    if (
      activeWindow !== refPreviousActiveWindow.current ||
      !!windows[activeWindow]?.dialog !== !!refPreviousWindows.current[refPreviousActiveWindow.current]?.dialog
    ) {
      hotkeys.setScope(activeWindow + (windows[activeWindow]?.dialog ? '-dialog' : ''));
    }
    refPreviousActiveWindow.current = activeWindow;
    refPreviousWindows.current = windows;
  });

  const setCurrentLocale = () => {
    changeLocaleSettings(Localization.instance.settings);
  };

  const renameTab = (props: WindowProps, title: string) => {
    props.title = title;
    rename();
    return false;
  };

  const toggleModal = () => {
    setIsShow(!isShow);
  };

  const toggleModalSelectView = () => {
    if (!isShowSelectView && XT.isApplicationInModalMode(loading)) return;
    setIsShowSelectView(!isShowSelectView);
  };

  const onWindowClose = () => {
    startLoading();
    CloseWindow?.(refCurrentWindowId.current, endLoading);
  };

  // old componentDidMount
  useEffect(() => {
    setCurrentLocale();
    hotkeys(
      'cmd+f6,ctrl+f6,cmd+shift+f6,ctrl+shift+f6,cmd+f7,ctrl+f7',
      { keyup: true, keydown: true, scope: 'all' },
      (event, handler) => {
        if (event.type === 'keyup' && !event.repeat) {
          switch (handler.key) {
            case 'cmd+f6':
            case 'ctrl+f6':
              selectNextWindowTab();
              break;
            case 'cmd+shift+f6':
            case 'ctrl+shift+f6':
              selectNextWindowTab(true);
              break;
            case 'cmd+f7':
            case 'ctrl+f7':
              toggleModalSelectView();
              break;
            default:
              break;
          }
        }
        return false;
      },
    );
    // old componentWillUnmount
    return () => {
      hotkeys.unbind('cmd+f6,ctrl+f6,cmd+shift+f6,ctrl+shift+f6,cmd+f7,ctrl+f7', 'all');
      setTooltipVisibility(false);
    };
  }, []);

  // old componentDidUpdate
  useEffect(() => {
    if (!loading) {
      if (refPreviousServerFocus.current !== serverFocus) {
        /*
          Remark: 
          Issue rdg table: cannot change selected cell without giving focus to the table as a side effect. 
          When for instance a refresh is requested by the user (case reload = "true"), we need to select the first cell again. 
          This is done inside an effect after the first rendering cycle. However the side effect by the rdg component (give focus 
          to the table) is also done in an effect (after next render cycle).
          Another rdg bug, does not trigger the onSelectedCellChanged event for the first cell when a table is initially selected.
          A workaround for that is some code to capture the first focus on the table. This is also part of a effect. 
          Conclusion; in worst case scenario the table needs 3 render cycles to get its focus right. That's why the desktop should wait 
          three render cycles to make it possble to give focus to the right control :-(  
        */
        if (serverFocus.action === 'SUBMIT') {
          setServerFocus({ action: 'REQUESTING' });
        } else if (serverFocus.action === 'REQUESTING') {
          setServerFocus({ action: 'EXECUTE' });
        } else if (serverFocus.action === 'EXECUTE') {
          handleServerFocus();
        }
      } else if (refPreviousIsShow.current && !isShow) {
        handleClientFocus(250); // timeout needed to get cell of editable table in EDIT mode (NTH: figure out why such long timeout needed)
      } else {
        handleFocus();
      }
      refPreviousServerFocus.current = serverFocus;
      refPreviousIsShow.current = isShow;
    }
  });

  const handleFocus = () => {
    /*
      Remark: Component update is disabled when loading active. As a consequense not all state (props)
      changes are visible in componentDidUpdate and therefor a variable was created to hold the previous
      state (refPreviousWindowsInfo): contains for each window tab the last loaded server data and the element
      that was active when the window lost focus
    */
    const DIALOG_SUFFIX = '-dialog';

    // Get previous/current active window
    // ==================================
    const prevActiveWindow = refPreviousWindowsInfo.current.activeWindow || '';
    let curActiveWindow = activeWindow;

    // Get previous/current data for current active window
    // ===================================================
    const prevData = refPreviousWindowsInfo.current.windows?.[curActiveWindow]?.data || {};
    let curData = windows[curActiveWindow]?.data;

    // Adjust current values in dialog mode
    // ====================================
    let isDialog = false;
    if (windows[curActiveWindow]?.dialog) {
      curData = windows[curActiveWindow].dialog?.data || {};
      curActiveWindow += DIALOG_SUFFIX;
      isDialog = true;
    }

    // No action needed if active window nor its data was changed (keep current focus)
    // ===============================================================================
    if (curActiveWindow === prevActiveWindow && curData === prevData) {
      return;
    }

    // Save new active window
    // ======================
    refPreviousWindowsInfo.current.activeWindow = curActiveWindow;

    // Remove info if previous window closed
    // =====================================
    if (prevActiveWindow.endsWith(DIALOG_SUFFIX) && !windows[prevActiveWindow.replace(DIALOG_SUFFIX, '')]?.dialog) {
      if (refPreviousWindowsInfo.current.windows?.[prevActiveWindow])
        delete refPreviousWindowsInfo.current.windows[prevActiveWindow];
    } else if (prevActiveWindow && !windows[prevActiveWindow]) {
      if (refPreviousWindowsInfo.current.windows?.[prevActiveWindow])
        delete refPreviousWindowsInfo.current.windows[prevActiveWindow];
      //hotkeys.setScope(activeWindow);  //TODO not clear if it's OK to fix this here (needed for dahboard??)
    }

    // Ignore dashboard
    // ================
    if (curActiveWindow === 'dashboard') {
      return; // Remark: no focus related implemention (yet)

      // Ignore dashboard view                                     --> eg: click toolbar icon file upload
      // =====================
    } else if (windows[curActiveWindow]?.program?.view === 'DashboardView') {
      return; // Remark: no "central" focus related implemention (yet)
    }

    // Save data active window
    // =======================
    if (refPreviousWindowsInfo.current.windows) {
      refPreviousWindowsInfo.current.windows[curActiveWindow] = {
        ...refPreviousWindowsInfo.current.windows[curActiveWindow],
        data: curData,
      };
      //refPreviousWindowsInfo.current.windows[curActiveWindow].data = curData;
    } else {
      refPreviousWindowsInfo.current.windows = { [curActiveWindow]: { data: curData } };
    }

    // Case data changed                                                          // this covers server action on the active window tab or a new window tab
    // =================
    if (!(curData === prevData)) {
      // in modal (dialog) set default focus in onShow event
      if (!isDialog) {
        setServerFocus({ action: 'SUBMIT' });
      }
      // console.info('######## server interaction');

      // Case other window tab selected                                             // this covers selection of another tab (explicit selection or by closing another one)
      // ==============================
    } else {
      // console.info('######## client repositioning ');
      handleClientFocus(); // ==> restore client focus from saved position
    }
  };

  const handleClientFocus = (timeoutInterval: number = 0) => {
    setTimeout(() => {
      // Remark: without time-out wrong scrolling non editable grid
      if (refPreviousWindowsInfo.current?.activeWindow) {
        const element =
          refPreviousWindowsInfo.current.windows?.[refPreviousWindowsInfo.current.activeWindow]?.activeElement;
        XT.restoreFocusActiveWindow(element); //NTH save/restore also scrollposition table??
      }
    }, timeoutInterval);
  };

  const startPolling = (appUserId: string) => {
    socket?.emit('message', {
      command: 'START_POLLING',
      user: appUserId,
    });
  };

  const selectTable = (tableSpan: Element | undefined | null): boolean => {
    if (tableSpan) {
      (tableSpan as any).directFocus = true;
      (tableSpan as any).focus();
      return true;
    }
    return false;
  };

  const handleServerFocus = () => {
    const getNextInput = (elements: NodeListOf<Element>): Element | null => {
      if (elements.length > 0) {
        let tabindexList = Array.from(elements).map((x: any) => +(x.getAttribute('tabindex') || '-2'));

        if (tabindexList.some((index) => index > 0)) {
          // first tabindex > 0
          tabindexList = tabindexList.filter((index) => index > 0);
        } else if (tabindexList.some((index) => index === 0)) {
          // then tabindex 0
          return Array.from(elements).find((x: any) => +(x.getAttribute('tabindex') || '-2') === 0) || null;
        } else {
          // finally without tabindex
          return Array.from(elements).find((x: any) => x.getAttribute('tabindex') === null) || null;
        } // remark: tabindex -1 intentionally not included

        if (tabindexList.length > 0) {
          let minIndex = Math.min(...tabindexList);
          return Array.from(elements).find((x: any) => +x.getAttribute('tabindex') === minIndex) || null;
        }
      }
      return null;
    };

    const windowHasExplicitErrorMessage = (props: WindowProps): boolean => {
      const messagesPanel = props?.data?.form?.panel?.find((x: any) => x.$.id.indexOf('MSG') > -1);
      return !!(messagesPanel?.message_list?.length > 0);
    };
    const hasErrorMessage = windowHasExplicitErrorMessage(windows[activeWindow]);

    // let _window = document.querySelector('.window.active .work-area');
    // if (_window) _window.scrollTop = 0;

    // Determine element that needs to get input focus
    // ===============================================

    // --> 1. If modal, only elements inside modal can get focus
    const window = document.querySelector('.modal-body') || document;
    let nextInput = null;

    // --> 2. "first" control in error WITH ERROR MESSAGE (business logic/server driven)
    if (!nextInput && hasErrorMessage) {
      // --> "basic" input controls
      const errorElements = window.querySelectorAll(
        '.window.active [tabindex][data-invalid=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled]), .window.active [tabindex].input-invalid:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(errorElements);

      // --> editable table
      if (!nextInput && errorElements.length > 0) {
        const tableSpan = Array.from(errorElements).find((element) => element.classList.contains('data-table-span'));
        if (selectTable(tableSpan)) return; // Remark: focus is given to the "first" cell in error in the table component itself
      }
    }

    // --> 3. select control determined by server (business logic)    e.g. explicit focus when program loads, return from prompting, ...       e.g. POM020AD: Work with project quotations --> button "Project/Quotation" --> press F6 --> cursor on customer BEFORE errorconstraint project number
    if (!nextInput) {
      // --> "basic" input controls
      const autoFocusElements = window.querySelectorAll(
        '.window.active [tabindex][data-autofocus=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled]), .window.active [tabindex].input-invalid:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(autoFocusElements);

      // --> editable table
      if (!nextInput && autoFocusElements.length > 0) {
        const tableSpan = Array.from(autoFocusElements).find((element) =>
          element.classList.contains('data-table-span'),
        );
        if (tableSpan) return; // Remark: focus is given to the requested cell in the table component itself
      }
    }

    // --> 4. initial focus (business logic)
    if (!nextInput) {
      // --> a. focus constraints
      const focusConstraintElements = window.querySelectorAll(
        '.window.active [tabindex][data-focusconstraint=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled]), .window.active [tabindex].input-invalid:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(focusConstraintElements);

      // --> b. initial default focus
      if (!nextInput) {
        const defaultFocusElements = window.querySelectorAll(
          '.window.active [tabindex][data-defaultfocus=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled]), .window.active [tabindex].input-invalid:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
        );
        nextInput = getNextInput(defaultFocusElements);
      }
    }

    // --> 5. "first" control with input error (client validation, eg wrong date)
    if (!nextInput) {
      const inputErrorElements = window.querySelectorAll(
        '.window.active [tabindex][data-input-invalid=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(inputErrorElements);
    }

    // --> 6. "first" control in error WITHOUT ERROR MESSAGE (business logic/server driven)         e.g. Enquiry program selection ("F24") - ASGD012
    if (!nextInput && !hasErrorMessage) {
      // --> "basic" input controls
      const errorElements = window.querySelectorAll(
        '.window.active [tabindex][data-invalid=true]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled]), .window.active [tabindex].input-invalid:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(errorElements);

      // --> editable table
      if (!nextInput && errorElements.length > 0) {
        const tableSpan = Array.from(errorElements).find((element) => element.classList.contains('data-table-span'));
        if (selectTable(tableSpan)) return; // Remark: focus is given to the "first" cell in error in the table component itself
      }
    }

    // --> 7. editable table
    if (!nextInput) {
      const tableSpan = window.querySelector('.window.active .data-table-span[ data-table-editable="true"]');
      if (selectTable(tableSpan)) return; // Remark: focus is given to the "first" editable cell in the table component itself
    }

    // --> 8. "first" input control
    if (!nextInput) {
      // --> a. "basic" input controls - exclude buttons (when buttons have focus, they consume the keydown event <ENTER>)
      const inputs = window.querySelectorAll(
        '.window.active [tabindex]:not(.panel-area):not(.event-dispatcher-dummy):not(table):not(.btn):not(.rdg-focus-sink):not([readonly]):not([disabled])',
      );
      nextInput = getNextInput(inputs);

      // --> b. inline buttons                    remark: inline "previous" and "next" buttons are filtered out using [data-initial-focus="true"]
      let buttons: NodeListOf<Element>;
      if (!nextInput) {
        buttons = window.querySelectorAll(
          '.window.active .panel-area .btn[tabindex][data-initial-focus="true"]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
        );
        nextInput = getNextInput(buttons);
      }

      // --> c. global buttons                    remark: if OK button available, (always?) first button ==> <ENTER> = OK
      if (!nextInput) {
        buttons = window.querySelectorAll(
          '.window.active .button-bar .btn[tabindex]:not(.event-dispatcher-dummy):not(table):not(.rdg-focus-sink):not([readonly]):not([disabled])',
        );
        nextInput = getNextInput(buttons);
      }

      // --> d. table
      if (!nextInput && inputs.length > 0) {
        const tableSpan = Array.from(inputs).find((element) => element.classList.contains('data-table-span'));
        if (selectTable(tableSpan)) return;
      }
    }

    // Give input focus to resulting control (unless table "server" pagedown requested, as in that case the focus is handled in the table itself)
    // =====================================
    if (nextInput) {
      if (nextInput.classList.contains('radio-buttons-group')) {
        (nextInput as any).directFocus = true;
      }
      (nextInput as HTMLElement).focus();
      (nextInput as any).autofocus = true;
    } else {
      let actWindow = document.querySelector('.window.active');
      if (actWindow) {
        (actWindow as HTMLElement).focus();
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    // Return true and skip the handler if new window (Application window) is active
    if (document.querySelector('.window.active .application-window')) return true;
    // TODO: Event.returnValue deprecated - replace with pure return?
    // more: https://developer.mozilla.org/en-US/docs/Web/API/Event/returnValue

    // when contextMenu is opened then other than its key events ['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'],
    // so e.g. pageDown / pageUp should be disabled
    const contextMenuOpened = document.getElementsByClassName('react-contextmenu--visible')?.length > 0;
    const selectedAndNotDisabledMenuItem =
      document.querySelectorAll('.react-contextmenu-item--selected:not(.react-contextmenu-item--disabled)')?.length > 0;
    if (
      (contextMenuOpened &&
        !['arrowleft', 'arrowright', 'arrowdown', 'arrowup', 'enter', 'escape'].includes(event.key.toLowerCase())) ||
      (contextMenuOpened && !selectedAndNotDisabledMenuItem && event.key.toLowerCase() === 'enter')
    ) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    // Only handle <Tab> key down
    // ==========================
    if (event.key !== 'Tab') return true;

    // Keep default behavior if current element not in active window                  --> eg modal forms like client settings
    // =============================================================
    if (!document.activeElement) return true;
    if (!document.activeElement.closest('.window.active')) return true;

    // Keep default behavior if current element part of calendar
    // =========================================================
    if (document.activeElement.closest('.card-calendar')) return true;

    // Collect info of the current active element
    // ==========================================
    let currentElement: Element | null = null;
    let currentTabindex: number;
    let isRadioButtonGroup: boolean = false;

    // Get datatable parent span (only available for table)
    // ----------------------------------------------------
    const dataTableSpan = document.activeElement.closest('.data-table-span');

    // Case table
    // ----------
    if (dataTableSpan) {
      // --> keep default behavior for <Tab> in editable tables (navigate to next editable cell)
      const isEditableTable = dataTableSpan.getAttribute('data-table-editable');
      if (isEditableTable === 'true') return true;
      else if (document.activeElement.classList.contains('filter')) return true;
      // --> set span as current element
      currentElement = dataTableSpan;

      // --> get "pseudo tabindex" of the parent span
      currentTabindex = +(dataTableSpan.getAttribute('data-pseudo-tabindex') || '-1'); // Remark: getAttribute : string | null;

      // Case radio button
      // -----------------
    } else if (
      document.activeElement instanceof HTMLInputElement &&
      document.activeElement.getAttribute('type') === 'radio'
    ) {
      isRadioButtonGroup = true;

      // --> set parent group as current element
      currentElement = document.activeElement.closest('.xt-group') || document.activeElement;

      // --> get tabindex of the "radio button group"
      currentTabindex = +(currentElement.getAttribute('tabindex') || '-1');

      // Case others
      // -----------
    } else {
      currentElement = document.activeElement;
      currentTabindex = +(currentElement.getAttribute('tabindex') || '-1');
    }

    // Determine next element
    // ======================

    // Get parent container
    // --------------------
    const parent = currentElement.closest('.window.active.modal') || document; // Remark: only consider elements in prompt dialog if appropriate

    // Get sorted array of tabable elements
    // ------------------------------------
    let elements = Array.from(
      parent.querySelectorAll(
        '.window.active [tabindex]:not(.panel-area):not([readonly]):not([disabled]):not(.rdg-focus-sink)',
      ),
    )
      .map((el) => {
        let type = 0; // Remark type: 1 for global buttons, others 0
        let tabindex: number;

        // --> case tables
        if (el.classList.contains('data-table-span')) {
          tabindex = +(el.getAttribute('data-pseudo-tabindex') || '-1');

          // --> case global buttons
        } else if (el.closest('.button-bar')) {
          type = 1;
          tabindex = el.getBoundingClientRect().left; // Important remark: this assumes a single row of buttons

          // --> case others
        } else {
          tabindex = +(el.getAttribute('tabindex') || '-1');
        }

        return { element: el, type: type, tabindex: tabindex };
      })
      .filter((el) => !(el.tabindex === -1 && el.type === 0)) // Remark: elements with tabindex -1 should not be reachable by <Tab> navigation
      .sort((a, b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1; // ==> global buttons at the end of the list
        return a.tabindex < b.tabindex ? -1 : 1;
      });

    // Default behavior if no tabable elements found
    // ---------------------------------------------
    if (elements.length === 0) return true;

    // Get array index current element
    // -------------------------------
    let index = elements.findIndex((el) => el.element === currentElement); // Beware: result might be -1, e.g. current element is readonly or has tabindex -1 (==> filtered out) )

    // Increment/decrement index
    // -------------------------
    let nextIndex: number;
    if (index >= 0) {
      nextIndex = index + (event.shiftKey ? -1 : 1);
    } else if (event.shiftKey) {
      nextIndex = -1;
    } else {
      nextIndex = elements.length;
    }

    // Keep default behavior if within array boundaries and not from/to table/global button
    // ------------------------------------------------------------------------------------
    if (!dataTableSpan && nextIndex >= 0 && nextIndex < elements.length) {
      if (
        elements[nextIndex].type === 0 &&
        elements[index].type === 0 &&
        event.isTrusted && // remark: autotab forces tab events (==> not trusted), seems like default browser behavior is not triggered in this case
        !elements[nextIndex].element.classList.contains('data-table-span') &&
        !isRadioButtonGroup &&
        !(elements[nextIndex].element as HTMLElement).classList.contains('radio-buttons-group')
      ) {
        return true; // --> keep impact as low as possible: some elements (like timer) might have multiple child input elements
      }

      // Adjust next index less than 0
      // -----------------------------
    } else if (nextIndex < 0) {
      nextIndex = elements.length - 1;

      // Adjust next index greater than maximum index array
      // --------------------------------------------------
    } else if (nextIndex > elements.length - 1) {
      nextIndex = 0;
    }

    // Set next element
    // ----------------
    let nextElement = elements[nextIndex].element;

    // Give focus to resulting next element
    // ====================================
    if (!nextElement) return true;
    if (typeof (nextElement as any).focus === 'function') {
      event.preventDefault();
      if (nextElement !== dataTableSpan) {
        if (nextElement.classList.contains('data-table-span')) {
          selectTable(nextElement);
        } else {
          if (nextElement.classList.contains('radio-buttons-group')) {
            (nextElement as any).directFocus = true;
          }
          (nextElement as any).focus();
        }
      }
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {pendingRequestErrors ? (
        <RequestErrorView
          startLoading={startLoading}
          endLoading={endLoading}
          restoreWindowInputFocus={handleClientFocus}
          startPolling={startPolling}
        />
      ) : null}
      {isShowSelectView ? (
        <SelectView onClose={toggleModalSelectView} restoreWindowInputFocus={handleClientFocus} />
      ) : null}
      <LocaleContext.Provider value={{ localeSettings: localeSettings, setCurrentLocale: setCurrentLocale }}>
        <TooltipContext.Provider
          value={{
            showFunctionTooltip: showFunctionTooltip,
            toggleFunctionTooltip: toggleFunctionTooltip,
          }}
        >
          <CopyableTooltip disable={!window.location.href.toLowerCase().endsWith('dev')} />
          <div
            className="desktop"
            onKeyDown={handleKeyDown}
            /* Following code seems not to be needed anymore: if still needed at least replace handleServerFocus() with handleClientFocus() and onBlur needs to be changed to registrate the active element on every blur (so also move focus from one elemant to another in the same window)
          onClick={(e) => {
            if (
              document.activeElement?.className.includes('window active') &&
              !(e.target as HTMLElement).closest('.custom-checkbox') &&
              !(e.target as HTMLElement).closest('.custom-radio') &&
              !(e.target as HTMLElement).closest('.data-table') &&
              !(e.target as HTMLElement).closest('.swal2-container') &&
              !(e.target as HTMLElement).closest('.datepicker-wrapper')             //NTH: if code still needed, check if this one is needed
            ) {
              handleServerFocus();
            }
          }}
          */
            tabIndex={-1}
            onFocus={(e: React.FocusEvent) => {
              if (e.target === e.currentTarget) {
                if ((e.target as any).directFocus) {
                  // Remove temporary property
                  // -------------------------
                  delete (e.target as any).directFocus;

                  // Redirect focus to last element with focus
                  // -----------------------------------------
                  handleClientFocus();
                }
              }
            }}
          >
            <div
              className="window-switcher"
              onMouseDown={(e: React.MouseEvent) => {
                if (e.target === e.currentTarget) handleClientFocus(); // no tab clicked? ==> give focus back to active window
              }}
            >
              {Object.entries(windows).map(([id, props]: [string, WindowProps]) => {
                // console.log(activeWindow, editing, props.id);
                return (
                  <>
                    <div
                      onClick={() => switchTabOrEdit(props.id)}
                      className={
                        'switcher-item truncate ' +
                        (activeWindow === id ? 'active' : '') +
                        (!!editing && activeWindow === props.id ? ' editing' : '')
                      }
                      key={props.id}
                    >
                      <OverlayTrigger
                        placement="right"
                        delay={50}
                        overlay={
                          <Tooltip className="custom" id={`tooltip-help`}>
                            {Localization.instance.getString('Dashboard')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div>
                          {props.icon ? (
                            <SquareIcon className="icon-dashboard" size="24px">
                              {Icons.GridView}
                            </SquareIcon>
                          ) : (
                            ''
                          )}
                        </div>
                      </OverlayTrigger>
                      {!!editing && activeWindow === props.id ? (
                        <Form.Control
                          ref={editBox}
                          onKeyUp={(e: KeyboardEvent) => {
                            const target = e.target as HTMLInputElement;
                            if (e.key.toLowerCase() === 'enter') {
                              renameTab(props, target.value);
                            } else if (e.key.toLowerCase() === 'escape') {
                              renameTab(props, props.title);
                            }
                            e.stopPropagation();
                            return false;
                          }}
                          autoComplete="off"
                          name="newName"
                          defaultValue={props.title}
                          data-event="ignore"
                          className="tabs-editor"
                          onBlur={(e: FocusEvent) => {
                            const target = e.target as HTMLInputElement;
                            renameTab(props, target.value);
                          }}
                        />
                      ) : (
                        <>
                          <OverlayTrigger
                            placement="top"
                            delay={50}
                            overlay={
                              <Tooltip className="custom" id={`tooltip-${id}`}>
                                {props.title}
                              </Tooltip>
                            }
                            rootClose
                          >
                            <div>
                              <span className="tabs-title">{props.title}</span>
                            </div>
                          </OverlayTrigger>
                        </>
                      )}
                    </div>
                    <CloseButton
                      data-event={'ignore'}
                      style={{
                        border: 'none',
                        padding: '2px',
                        fontSize: 'normal',
                        marginLeft: '-15px',
                      }}
                      onClick={() => {
                        const program = windows[id].program;
                        refCurrentWindowId.current = id;
                        switch (program.view) {
                          case 'QueryManagerView':
                          case 'BrowserView':
                          case 'DashboardView':
                          case 'ApplicationView':
                            onWindowClose();
                            break;
                          case 'Xt':
                          default:
                            toggleModal(); // user needs to confirm
                            break;
                        }
                      }}
                      className={`${(() => {
                        const program = windows[id].program;
                        refCurrentWindowId.current = id;
                        switch (program.view) {
                          case 'QueryManagerView':
                          case 'BrowserView':
                          case 'DashboardView':
                          case 'ApplicationView':
                            return 'always-show';
                          case 'Xt':
                          default:
                            return '';
                        }
                      })()}`}
                      hidden={(() => {
                        const program = windows[id].program;
                        if (id === 'dashboard') {
                          return true;
                        }
                        switch (program.view) {
                          case 'QueryManagerView':
                          case 'BrowserView':
                          case 'DashboardView':
                          case 'ApplicationView':
                            return false;
                          case 'Xt':
                          default:
                            return !XT.globalConfig?.window?.closable;
                        }
                      })()}
                    />
                  </>
                );
              })}
            </div>
            <div>
              <Modal
                data-event={'ignore'}
                show={isShow}
                onHide={toggleModal.bind(this)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title>
                    <h4>Iptor.com</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    <p>{Localization.instance.getString('TERMINATE_DoYouReallyWantToTerminateSession')}</p>
                    <br />
                    <p>
                      {Localization.instance.getString(
                        'TERMINATE_TerminatedSessionLeadsToDegradedPerformanceOnTheServer',
                      )}{' '}
                      <br /> {Localization.instance.getString('TERMINATE_PleaseSelectNoAndUseF3OrExit')}
                    </p>
                    <br />
                    <p>{Localization.instance.getString('TERMINATE_Terminate')}</p>
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    data-event="ignore"
                    className="truncate btn-padded-modal btn btn-outline-dark btn-sm col-sm-2"
                    onClick={() => {
                      toggleModal();
                      onWindowClose();
                    }}
                  >
                    {Localization.instance.getString('TERMINATE_BUTTON_Yes')}
                  </button>
                  {/* <button data-event="ignore" onClick={onCloseModal.bind(this)} className="truncate btn btn-outline-dark btn-sm"> */}
                  <button
                    data-event="ignore"
                    onClick={toggleModal.bind(this)}
                    className="truncate btn-padded-modal btn btn-outline-dark btn-sm col-sm-2"
                  >
                    {Localization.instance.getString('TERMINATE_BUTTON_No')}
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div id={'overlay-containerlegacy'}></div>

            <div id="windows" className={`windows`}>
              {Object.entries(windows).map(([id, props]: [string, WindowProps]) => {
                let applicationWindowProps: ApplicationWindowProps;
                props.startLoading = startLoading;
                props.endLoading = endLoading;
                props.startExport = startExport;
                props.stopExport = stopExport;
                props.restoreFocusActiveWindow = handleClientFocus;
                props.onModalWindowShow = handleServerFocus;
                if (props.program.view === 'ApplicationView') {
                  applicationWindowProps = {
                    ...props,
                    handleRoleShow: handleRoleShow,
                    handleSettingsShow: handleSettingsShow,
                    getMenuItems: getMenuItems,
                    flattenedMenu: flattenedMenu,
                    handleShowMenu: handleShowMenu,
                    mainMenu: mainMenu,
                    handleShowFavourites: handleShowFavourites,
                  };
                }

                return (
                  <div
                    tabIndex={1}
                    key={props.id}
                    className={
                      'window' +
                      (activeWindow === id ? ' active' : '') +
                      (props.program.view === 'ApplicationView' ? ' no-toolbar' : '')
                    }
                    onFocus={() => {
                      const windowId = props.id + (props.dialog ? '-dialog' : '');
                      if (refPreviousWindowsInfo.current.windows?.[windowId]) {
                        refPreviousWindowsInfo.current.windows[
                          windowId
                        ].activeElement = XT.getFocusedElementActiveWindow(
                          refPreviousWindowsInfo.current.windows[windowId].activeElement,
                        );
                      }
                    }}
                  >
                    <Setting isShown={isSettingsShown} hide={handleSettingsShow} />
                    <RoleTemplates isShown={roleTemplateOpen} hide={handleRoleShow} />
                    {props.id === 'dashboard' ? (
                      settings?.regionals?.user[0].$.userId && (
                        <DashboardEditor startLoading={startLoading} endLoading={endLoading} />
                      )
                    ) : props.program.view === 'QueryManagerView' ||
                      props.program.view === 'BrowserView' ||
                      props.program.view === 'DashboardView' ? (
                      <AperioWindow {...props} />
                    ) : props.program.view === 'ApplicationView' ? (
                      <ApplicationWindow {...applicationWindowProps} />
                    ) : (
                      <NotesProvider
                        formKey={props.data.form.$.id}
                        rootCheck={(e: FocusEvent<HTMLSpanElement>) => e.target.classList.contains('panel-area')}
                      >
                        <Window key={id} {...props} />
                      </NotesProvider>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </TooltipContext.Provider>
      </LocaleContext.Provider>
    </div>
  );
};

const mapStateToProps = ({ desktop }: { desktop: DesktopProps }) => {
  return {
    windows: desktop.windows,
    settings: desktop.settings,
    activeWindow: desktop.activeWindow,
    editing: desktop.editing,
    localeSettings: desktop.localeSettings,
  };
};

const mapDispatchToProps = {
  changeLocaleSettings: (localeSettings: LocalizationInfo.Settings) => ({
    type: WindowActions.CHANGE_LOCALE_SETTINGS,
    payload: { localeSettings: localeSettings },
  }),
  switchTab: (id: string) => ({ type: WindowActions.SWITCH, payload: { id: id } }),
  interact: (windowId: string, command: Command, endLoading: Function) =>
    WindowManger.Interact(windowId, command, endLoading, () => {}, undefined),
  rename: () => ({ type: WindowActions.RENAME }),
  edit: () => ({ type: WindowActions.EDIT }),
  CloseWindow: (windowId: string, endLoading: Function) => WindowManger.CloseWindow(windowId, endLoading),
};

const _Desktop = connectLoader(DesktopComponent);

export const Desktop = connect(mapStateToProps, mapDispatchToProps)(_Desktop);

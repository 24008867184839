import { NotificationProps, useCustomSnackbar } from '@iptor/base';

const getVariantFromErrorType = (
  errorType: string | undefined,
  specifiedVariant: 'error' | 'warning' | 'success' = 'error',
) => {
  if (!errorType) {
    return specifiedVariant;
  }
  const errorTypeLowerCase = errorType.toLowerCase();
  if (errorTypeLowerCase.includes('warning')) {
    return 'warning';
  }
  return specifiedVariant;
};

export function useOrderNotifications() {
  const { showSnackbar, closeAllSnackbars } = useCustomSnackbar();

  const notify = (
    specifiedVariant: 'error' | 'warning' | 'success',
    messageData: Array<Record<string, string>> | string,
    autoHide?: boolean,
    messageTitle?: string,
  ) => {
    if (Array.isArray(messageData)) {
      messageData?.forEach((item: Record<string, string>) => {
        const variant = getVariantFromErrorType(item?.type, specifiedVariant);
        const cleanedString = item?.text && item?.text.replace(/<HELP>/g, '').trim();
        const notificationProps: NotificationProps = {
          message: cleanedString || '',
          messageTitle,
          duration: 3000,
          autoHide: autoHide ?? variant !== 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: getVariantFromErrorType(item?.type, specifiedVariant),
          additionalDetails: item?.text2,
        };
        showSnackbar(notificationProps);
      });
    } else {
      const notificationProps: NotificationProps = {
        message: messageData,
        messageTitle,
        duration: 3000,
        autoHide: autoHide ?? specifiedVariant !== 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: specifiedVariant,
      };
      showSnackbar(notificationProps);
    }
  };

  return { notify, closeAllSnackbars };
}

import { Drawer, DrawerProps, List, ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { Summarize } from '@mui/icons-material';
import { PropsWithChildren, useContext } from 'react';
import { ApplicationContext, Icon, iconNamesArray } from '@iptor/base';

export const ApplicationToolbar = ({ children: _, ...props }: PropsWithChildren<DrawerProps>) => {
  const { handleShowMenu, mainMenu, handleShowFavourites } = useContext(ApplicationContext);

  return (
    <Drawer variant="permanent" color="primary" {...props} open={false}>
      <List>
        {mainMenu['value']?.map((menu: any) => {
          const iconName = iconNamesArray.includes(menu['$']['image']) ? menu['$']['image'] : 'documentValidation';
          return (
            <ListItem disablePadding>
              <Tooltip title={menu['$']['text']} placement="right" arrow={true}>
                <ListItemButton
                  disableGutters
                  id="mainMenu"
                  data-iscapture="true"
                  data-for="global"
                  data-tip="mainMenu"
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                      justifyContent: 'center',
                    },
                  ]}
                  onClick={() => {
                    handleShowMenu(menu['$']['text']);
                  }}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: 'center',
                        mr: 'auto',
                        paddingLeft: 0,
                        paddingRight: 0,
                        svg: {
                          cursor: 'pointer',
                          pointerEvents: 'visible',
                        },
                      },
                    ]}
                  >
                    {iconName === '' ? <Summarize /> : <Icon icon={iconName} color="white"></Icon>}
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          );
        })}
        <ListItem disablePadding>
          <Tooltip title="Favourites" placement="right" arrow={true}>
            <ListItemButton
              id="favourites"
              data-iscapture="true"
              data-for="global"
              data-tip="favourites"
              disableGutters
              sx={[
                {
                  minHeight: 48,
                  px: 2.5,
                  justifyContent: 'center',
                },
              ]}
              onClick={() => {
                handleShowFavourites();
              }}
            >
              <ListItemIcon
                sx={[
                  {
                    minWidth: 0,
                    justifyContent: 'center',
                    mr: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0,
                    svg: {
                      cursor: 'pointer',
                      pointerEvents: 'visible',
                    },
                  },
                ]}
              >
                <Icon icon={'star'} color="white"></Icon>
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
      </List>
    </Drawer>
  );
};

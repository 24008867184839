import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type UnitsDropdownProps = BusinessDropdownProps & {
  item?: string;
};

const COLUMN_NAMES: string[] = ['Unit'];

export const UnitsDropdown = ({ item, ...props }: UnitsDropdownProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: unitOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-inventory',
    operationId: 'GET /items/{item}/units',
    labelAttribute: '',
    valueAttribute: 'unit',
    requiredParams: { item: item },
    shouldPreventApiCall,
  });

  useEffect(() => {
    if (item) {
      console.log('Refetching units dropdown');
      refetch();
    }
  }, [item]);

  return <BaseDropdown {...props} loading={loading} options={unitOptions} columnNames={COLUMN_NAMES} />;
};

import { InputAdornment } from '@mui/material';
import { NumericField, NumericFieldProps } from '../NumericField';
import merge from 'lodash.merge';

export type CurrencyFieldProps = NumericFieldProps & {
  currency: string;
  placement: 'start' | 'end';
};

export const CurrencyField = ({ currency, placement = 'start', value, min = 0, ...props }: CurrencyFieldProps) => {
  const getCurrencySymbol = (currency: string) => {
    try {
      return new Intl.NumberFormat(navigator.language, { style: 'currency', currency })
        .formatToParts(1)
        .find((x) => x.type === 'currency').value;
    } catch (e) {
      return '';
    }
  };

  const mergedSlotProps = merge(
    {
      input: {
        [`${placement}Adornment`]: <InputAdornment position={placement}>{getCurrencySymbol(currency)}</InputAdornment>,
      },
    },
    props.slotProps,
  );

  return <NumericField {...props} value={value} min={min} slotProps={mergedSlotProps} />;
};

import { Accordion, Checkbox, DatePicker, FormControlLabel, Text, TextField, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Order } from '../../../constants';
import { formatDate, formatDateToPayloadFormat } from '../../../utils/formatDate';
import { DispatchAddressDropdown, MannerOfTransportDropdown, TermsOfDeliveryDropdown } from '@iptor/business';

type ShippingInfoProps = {
  register?: UseFormRegister<Partial<Order>>;
  setValue?: UseFormSetValue<Partial<Order>>;
  getValues?: UseFormGetValues<Partial<Order>>;
  watch?: UseFormGetValues<Partial<Order>>;
  control?: Control<Partial<Order>>;
  errors?: FieldErrors<Partial<Order>>;
  createProcess?: boolean;
  businessPartner: string;
};

const ShippingInfo = ({
  control,
  register,
  setValue,
  getValues,
  errors,
  createProcess,
  businessPartner,
}: ShippingInfoProps) => {
  const { t } = useIptorTranslation();

  return (
    <Accordion
      headingTitle={t('common:TXT_Shipping_Info')}
      moreInfo={
        <Grid container columnSpacing={3} rowSpacing={2} alignItems="flex-end">
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start', marginLeft: 0 }}
              control={
                <Text id="holdingCompany" fontSize={14} color="textDisabled">
                  {getValues?.('holdingCompany') || ' '}
                </Text>
              }
              readOnly={!createProcess}
              label={<Text fontWeight={600}>{t('common:TXT_Holding_Company')}</Text>}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              control={
                <Text id="parentCompany" fontSize={14} color="textDisabled">
                  {getValues?.('parentCompany') || ' '}
                </Text>
              }
              label={<Text fontWeight={600}>{t('common:TXT_Parent_Company')}</Text>}
              readOnly={!createProcess}
              labelPlacement="top"
            />
          </Grid>
          <Grid size={3}>
            <Controller
              name="ediCreated"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  readOnly={!createProcess}
                  id={field.name}
                  control={<Checkbox id={field.name} {...field} />}
                  label={t('common:TXT_EDI_Created')}
                />
              )}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid size={12}>
          <Text fontSize={18} fontWeight={600}>
            {t('common:TXT_Shipment_method')}
          </Text>
        </Grid>

        <Grid size={12 / 4}>
          <DatePicker
            label={t('common:TXT_Dispatch_Date')}
            value={formatDate(getValues?.('dispatchTime'))}
            slotProps={{
              textField: {
                id: 'dispatchTime',
              },
            }}
            onChange={(value) => setValue('dispatchTime', formatDateToPayloadFormat(value))}
          />
          <input {...register('dispatchTime')} type="hidden" name="dispatchTime" />
        </Grid>

        <Grid size={12 / 4}>
          <DatePicker
            label={t('common:TXT_Request_Delivery_Date')}
            value={formatDate(getValues?.('deliveryScheduleNumber'))}
            slotProps={{
              textField: {
                id: 'deliveryScheduleNumber',
              },
            }}
            readOnly={!createProcess}
            onChange={(value) => setValue('deliveryScheduleNumber', formatDateToPayloadFormat(value))}
          />
          <input {...register('deliveryScheduleOrder')} type="hidden" name="deliveryScheduleOrder" />
        </Grid>

        <Grid size={12 / 2}>
          <Controller
            name="dispatchAddress"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DispatchAddressDropdown
                businessPartner={businessPartner}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Address')}
                readOnly
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid size={12 / 2}>
          <Controller
            name="termsOfDelivery"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TermsOfDeliveryDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Terms_Of_Delivery')}
                readOnly={!createProcess}
              />
            )}
          />
        </Grid>

        <Grid size={12 / 2}>
          <Controller
            name="mannerOfTransport"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <MannerOfTransportDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Manner_Of_Transport')}
                readOnly={!createProcess}
              />
            )}
          />
        </Grid>

        <Grid size={12 / 2}>
          <Controller
            name="shippingAgent"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('common:TXT_Shipment_Mark')}
                fullWidth
                id={field.name}
                error={!!errors?.shippingAgent}
                helperText={errors?.shippingAgent?.message}
                readOnly={!createProcess}
              />
            )}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
};

export default ShippingInfo;

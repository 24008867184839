import { Theme } from '@mui/material';

export const MenuItem = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      // minWidth: 294,  //Do not use such large min widths in theme
      padding: theme.spacing(1),
      fontSize: 'inherit',
      variants: [
        {
          props: { color: 'light' },
          style: {
            padding: theme.spacing(0.75, 1.5),
            minWidth: '0',
            color: theme.palette.foreground['500'],
          },
        },
        {
          props: { color: 'dark' },
          style: {
            padding: theme.spacing(0.75, 1.5),
            minWidth: '0',
            boxShadow: 'none',
            color: theme.palette.common.white,
            ':hover': {
              '& .MuiListItemIcon-root': {
                background: theme.palette.primary['400'],
                span: {
                  color: theme.palette.primary['400'],
                },
              },
            },
          },
        },
      ],
    }),
  },
};

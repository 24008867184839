export const lightShadows = [
  'none',
  '0px 2px 4px rgba(171, 190, 209, 0.6)', // 2px, 60%
  '0px 4px 8px rgba(171, 190, 209, 0.4)', // 4px, 40%
  '0px 6px 12px rgba(171, 190, 209, 0.3)', // 6px, 30%
  '0px 8px 16px rgba(171, 190, 209, 0.4)', // 8px, 40%
];

export const darkShadows = [
  'none',
  '0px 2px 4px rgba(28, 42, 53, 0.6)', // 2px, 60%
  '0px 4px 8px rgba(28, 42, 53, 0.4)', // 4px, 40%
  '0px 6px 12px rgba(28, 42, 53, 0.3)', // 6px, 30%
  '0px 8px 16px rgba(28, 42, 53, 0.4)', // 8px, 40%
];

import { FormLayoutProps as BaseFormLayoutProps, useIptorTranslation, FormLayout as BaseFormLayout } from '@iptor/base';
import { FormToolbar } from '../../FormToolbar';
import { AttachmentList, ContextAttachments } from './components/ContextAttachments';
import ContextHelp from './components/ContextHelp';
import ContextNotes from './components/ContextNotes';
import { ContextMenuEnum, useSideMenuContext } from './components/SideMenuContextProvider';
import { ApplicationToolbar } from '../../ApplicationToolbar';
import { useEffect } from 'react';

type FormLayoutProps = Omit<BaseFormLayoutProps, 'titleActions' | 'toolbar'> & {
  titleActions?: BaseFormLayoutProps['titleActions'];
};

const attachmentList: AttachmentList[] = [
  {
    entryType: 'cat',
    name: 'Business partner (MKOCUS)',
    cat: 'NAME',
    key: 'MKOCUS',
    type: 'TABLE',
  },
  {
    entryType: 'att',
    name: '0FSQjo01.pdf',
    key: 'MKOCUS',
    cat: 'NAME',
    type: 'pdf',
  },
  {
    entryType: 'att',
    name: '0FSQjo01.csv',
    key: 'MKOCUS',
    cat: 'NAME',
    type: 'csv',
  },
  {
    entryType: 'cat',
    name: 'Purchase requisition (BWI)',
    cat: 'REQUISIT',
    key: 'BWI',
    type: 'TABLE',
  },
];

export function FormLayout(props: FormLayoutProps) {
  const { t } = useIptorTranslation();
  const { selectedContextMenu, setSelectedContextMenu, width, setWidth } = useSideMenuContext();

  const renderContextMenu = (type: ContextMenuEnum) => {
    switch (type) {
      case ContextMenuEnum.CONTEXT_HELP:
        return <ContextHelp />;
      case ContextMenuEnum.ATTACHMENTS:
        return (
          <ContextAttachments
            attachmentList={attachmentList}
            downloadAttachment={(cat, key, name) => console.log(cat, key, name)}
            previewAttachment={(cat, key, name) => console.log(cat, key, name)}
            deleteAttachment={(cat, key, name) => console.log(cat, key, name)}
            uploadAttachments={(cat, key, files) => console.log(cat, key, files)}
          />
        );
      case ContextMenuEnum.ADD_NOTES:
        return <ContextNotes />;
      default:
        return null;
    }
  };

  const getContextMenuTitle = (type: ContextMenuEnum) => {
    switch (type) {
      case ContextMenuEnum.CONTEXT_HELP:
        return t('common:Context_help');
      case ContextMenuEnum.ATTACHMENTS:
        return t('common:Attachments');
      case ContextMenuEnum.ADD_NOTES:
        return t('common:CONTEXT_AREA_Your_Notes');
      default:
        return;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F1') {
        event.preventDefault();
        setSelectedContextMenu(ContextMenuEnum.CONTEXT_HELP);
      }
    };
    window.addEventListener('keyup', handleKeyDown);
    return () => window.removeEventListener('keyup', handleKeyDown);
  }, [setSelectedContextMenu]);

  return (
    <BaseFormLayout
      toolbar={<ApplicationToolbar />}
      titleActions={
        <FormToolbar
          handleContextMenu={(type: ContextMenuEnum) => {
            setSelectedContextMenu(type);
          }}
        />
      }
      slotProps={{
        contextMenu: {
          open: selectedContextMenu !== null,
          title: getContextMenuTitle(selectedContextMenu),
          children: renderContextMenu(selectedContextMenu),
          onClose: () => setSelectedContextMenu(null),
          onWidthChange: (width: number) => {
            setWidth(width);
          },
          width,
        },
        ...props.slotProps,
      }}
      {...props}
    />
  );
}

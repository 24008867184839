import { Accordion, Checkbox, Text, TextField, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { OrderPriorityDropdown, OperationResponseDataData } from '@iptor/business';
import { Control, Controller } from 'react-hook-form';

export type MiscellaneousProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function Miscellaneous({ control, orderLineInfo }: MiscellaneousProps) {
  const { t } = useIptorTranslation();
  return (
    <Accordion
      headingTitle={t('common:TXT_Miscellaneous')}
      moreInfo={
        <Grid container spacing={2}>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Quotation_Number')}`}</Text>
            <Text variant="body1" id="quotationText">
              {orderLineInfo.quotation || ' '}
            </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Quotation_Version')}`}</Text>
            <Text variant="body1"> </Text>
          </Grid>
          <Grid size={3}>
            <Text variant="body1" fontWeight={600}>{`${t('common:TXT_Quotation_Line')}`}</Text>
            <Text variant="body1"> </Text>
          </Grid>
          <Grid size={3}>
            <Checkbox
              checked={!!orderLineInfo.ediCreated}
              value={orderLineInfo.ediCreated}
              id="ediCreated"
              label={t('sales_orders:ITEM_INFO_diCreated')}
              disabled
            />
          </Grid>
        </Grid>
      }
    >
      <Text
        variant="h6"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        {t('common:TXT_Miscellaneous')}
      </Text>
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="salesOrderPriorityCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <OrderPriorityDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                fullWidth
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Order_Priority')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="backOrderPriorityCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <OrderPriorityDropdown
                value={field.value}
                onChange={(value) => field.onChange(value)}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                id={field.name}
                fullWidth
                label={t('common:TXT_Back_Order_Priority')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="orderPropertyCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                disabled
                color={error ? 'error' : 'primary'}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Order_Property')}
                slotProps={{
                  inputLabel: {
                    disabled: false,
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    border: 'none',
                    outline: 'none',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container paddingTop={1} spacing={2}>
        <Grid size={3}>
          <Controller
            name={'printItemExtendedDesc'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                id={field.name}
                label={t('sales_orders:ITEM_INFO_extDesc')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name={'printPackagingAndLabelling'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                id={field.name}
                label={t('sales_orders:ITEM_INFO_exitLabPack')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name={'printConformityInfo'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={!!field.value}
                id={field.name}
                label={t('sales_orders:ITEM_INFO_confDetails')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name={'rebateCalculation'}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                id={field.name}
                checked={!!field.value}
                label={t('sales_orders:ITEM_INFO_rebateCalc')}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
}

export default Miscellaneous;

export class BooleanFormatter {
  in(value: string | boolean, yes: string, no: string): boolean | undefined {
    if (
      value === '1' ||
      value === yes ||
      value === true ||
      (typeof value === 'string' && value.toLowerCase() === 'true')
    ) {
      return true;
    }
    if (value === no) {
      return false;
    } else {
      return undefined;
    }
  }

  out(value: boolean, yes: string, no: string, indeterminate: boolean): string {
    return indeterminate ? '' : value ? yes : no;
  }
}

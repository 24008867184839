import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Radius } from '../../constants/Radius';
import { TextWeight } from '../../constants/Typography';

import { Text } from '../Text';

export type TableSummaryProps = {
  deliveryAddress: string;
  currency: string;
  subTotal: string | number;
  discount: string | number;
  vat: string | number;
  total: string | number;
  onAddressLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const TableSummaryContainer = styled(Box)`
  ${({ theme }) => `
   display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background-color: ${theme.palette.background.default};
  border-radius: ${Radius.S}px;
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => `
  font-size: 18px;
  font-weight: 700;
  line-height:  ${theme.spacing(3)};
  color: ${theme.palette.foreground[900]};
  `}
`;

const AddressSummary = styled(Box)`
  ${({ theme }) => `
  display: flex;
  min-width:350px;
  max-width: 385px;
  height: 80px;
  align-items: flex-start;
  padding: 16px;
  background-color: ${theme.palette.primary[200]};
  border-radius: 7px;
  margin-bottom: 10px;
 `}
`;

const AddressInfo = styled(Box)`
  flex-grow: 1;
  p {
    max-width: 305px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`;
const AddressText = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.foreground[200]};
  line-height: ${({ theme }) => theme.spacing(2.7)};
`;

const AmountText = styled(Text)`
  font-weight: ${TextWeight.REGULAR};
  font-size: 16px;
  color: ${({ theme }) => theme.palette.foreground[900]};
  line-height: ${({ theme }) => theme.spacing(2.8)};
`;

const SummaryDetails = styled(Box)`
  margin-top: 18px;
  min-width: 25%;
`;

const SummaryItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SummaryTotal = styled(Box)`
  ${({ theme }) => `   
  border-top: 1px solid ${theme.palette.foreground[400]};
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  padding: 8px 0px;`}
`;

const ChangeAddressLink = styled(Link)`
  color: ${({ theme }) => theme.palette.info.main};
  text-decoration: none;
  margin-bottom: 20px;
  display: block;
  font-weight: 600;
  line-height: ${({ theme }) => theme.spacing(2.7)};
  &:hover {
    text-decoration: underline;
  }
`;

export const TableSummaryPanel = ({
  deliveryAddress,
  currency,
  subTotal,
  discount,
  total,
  vat,
  onAddressLinkClick,
}: TableSummaryProps) => {
  return (
    <TableSummaryContainer>
      <div>
        <Title sx={{ lineHeight: 3 }}>Order summary:</Title>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          <AddressSummary>
            <AddressInfo>
              <AddressText sx={{ fontWeight: TextWeight.SEMIBOLD }}>Home Address 1</AddressText>
              <AddressText sx={{ fontWeight: TextWeight.REGULAR }}>{deliveryAddress}</AddressText>
            </AddressInfo>
          </AddressSummary>
        </div>

        <ChangeAddressLink onClick={onAddressLinkClick}>Change delivery address</ChangeAddressLink>
      </div>

      <SummaryDetails>
        <SummaryItem>
          <AmountText>Subtotal</AmountText>
          <AmountText>{`${currency} ${subTotal}`}</AmountText>
        </SummaryItem>

        <SummaryItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <AmountText>Discount</AmountText>
          </Box>
          <AmountText>{currency + ' ' + discount}</AmountText>
        </SummaryItem>

        <SummaryItem>
          <AmountText>VAT</AmountText>
          <AmountText>{`${currency} ${vat}`}</AmountText>
        </SummaryItem>

        <SummaryTotal>
          <AmountText sx={{ fontWeight: TextWeight.SEMIBOLD }}>Total</AmountText>
          <AmountText>{`${currency} ${total}`}</AmountText>
        </SummaryTotal>
      </SummaryDetails>
    </TableSummaryContainer>
  );
};

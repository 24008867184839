import { FunctionComponent } from 'react';
import './index.scss';

export type LoginButtonProps = {
  name: string;
  action: () => void;
  message?: string;
};

export const LoginButton: FunctionComponent<LoginButtonProps> = ({ name, action, message }) => {
  return (
    <div className='login_btn_wrapper'>
      <p className='msg'>{message}</p>
      <button className='btn' onClick={() => action()}>
        {name}
      </button>
    </div>
  );
};

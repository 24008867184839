export default (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={'inherit'}>
    <path
      stroke="currentColor"
      d="M6 20.735C5.69553 20.5592 5.44278 20.3063 5.26721 20.0017C5.09165 19.6971 4.99948 19.3516 5 19V5C5 4.46957 5.21072 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H16"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      stroke="currentColor"
      d="M11 17C11.5304 17 12.0391 17.2107 12.4142 17.5858C12.7893 17.9609 13 18.4696 13 19V21C13 21.2652 12.8946 21.5196 12.7071 21.7071C12.5196 21.8946 12.2652 22 12 22H10C9.73478 22 9.48043 21.8946 9.29289 21.7071C9.10536 21.5196 9 21.2652 9 21V19C9 18.4696 9.21071 17.9609 9.58579 17.5858C9.96086 17.2107 10.4696 17 11 17Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path stroke="currentColor" d="M11 5H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M13 7H12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M11 9H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M13 11H12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M11 13H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path stroke="currentColor" d="M13 15H12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col, Container } from 'react-bootstrap';
import '../styles/RequestErrorView.scss';
import { RootState } from '../framework/base';
import { Localization } from '../framework/localization/Localization';
import { WindowActions } from '../types/actionTypes';
import ApplicationErrors from '../components/ApplicationErrors';
import { WindowManger } from '../framework/base/windowManager';
import { connect } from 'react-redux';
import { useLogger } from '../logger/react/useLogger';
import { RequestError } from '../types/RequestError';
import { Icons, SquareIcon } from '../components/SquareIcon';
import { useCustomAuth } from '@iptor/base';

type RequestErrorViewProps = {
  startLoading: () => void;
  endLoading: () => void;
  closeWindow: (windowId: string, endLoading: () => void) => void;
  interact: (...args: any[]) => void;
  launch: (...args: any[]) => void;
  restoreWindowInputFocus: (timeoutInterval: number) => void; //Remark: should be covered by the modal itself (default value restoreFocus={true}, BUT DID nOT WORK
  startPolling: (appUserId: string) => void;
};

export const RequestErrorViewComponent: FunctionComponent<RequestErrorViewProps> = (props) => {
  // Initialize
  // ==========
  const state = useSelector<RootState, RequestError.JSON | undefined>((state) => state.desktop.requestErrors.pending);
  const requestError: RequestError | undefined = useMemo(() => {
    if (state) return RequestError.fromJSON(state);
    return undefined;
  }, [state]);
  const dispatch = useDispatch();
  const refAuthenticationRequired = useRef<HTMLButtonElement>(null);
  const refAllowRetry = useRef<HTMLButtonElement>(null);
  const refAllowIgnore = useRef<HTMLButtonElement>(null);
  const refAllowClose = useRef<HTMLButtonElement>(null);
  const refAllowLogout = useRef<HTMLButtonElement>(null);
  const logger = useLogger('RequestErrorView');
  const { getUser, signinRedirect } = useCustomAuth((auth) => ({
    getUser: auth.getUser,
    signinRedirect: auth.signinRedirect,
  }));

  useEffect(() => {
    if (requestError?.authenticationRequired) {
      refAuthenticationRequired.current?.focus();
    } else if (requestError?.allowRetry) {
      refAllowRetry.current?.focus();
    } else if (requestError?.allowIgnore) {
      refAllowIgnore.current?.focus();
    } else if (requestError?.allowCloseWindow) {
      refAllowClose.current?.focus();
    } else if (requestError?.allowLogout) {
      refAllowLogout.current?.focus();
    }
  }, []);

  // Handle case no error
  // ====================
  if (!requestError) {
    onCloseModal();
    return null;
  }

  // Determine icon to show
  // ======================
  let isFatalError: boolean = false;
  let isError: boolean = false;
  let isWarning: boolean = false;
  if (requestError.allowIgnore) {
    isWarning = true;
  } else if (requestError.authenticationRequired) {
    isError = true;
  } else if (!requestError.allowRetry) {
    isFatalError = true;
  } else {
    isError = true;
  }

  // Render component
  // ================
  return (
    <div onKeyUp={onKeyUp} onKeyDown={onKeyDown} className="request-error">
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={'request-error-modal ' + requestError.errorLevel.toLocaleLowerCase()}
        backdrop="static"
        animation={false}
        size="lg"
        onEscapeKeyDown={onEscapeKeydown}
        keyboard={true} // remark: Executing callback onEscapeKeydown requires this to be true
        scrollable // ==> limits scrolling to body
      >
        <Modal.Header>
          <Modal.Title>
            <Container>
              <Row>
                <Col md={'auto'} className="column-icon">
                  {isWarning ? <SquareIcon size="54px">{Icons.Warning}</SquareIcon> : null}
                  {isFatalError ? <SquareIcon size="54px">{Icons.FatalError}</SquareIcon> : null}
                  {isError ? <SquareIcon size="54px">{Icons.Error}</SquareIcon> : null}
                </Col>
                <Col md={'auto'}>
                  <h5>{Localization.instance.getString(requestError.clientHeadingMessage)}</h5>
                  {requestError.serverHeadingMessage && (
                    <h6>{Localization.instance.getString(requestError.serverHeadingMessage)}</h6>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {requestError.recoveryInstructions && requestError.recoveryInstructions.length > 0 && (
            <div>
              <h6>{Localization.instance.getString('TXT_You_can')}</h6>
              <ul>
                {requestError.recoveryInstructions.map((text) => {
                  return <li>° {Localization.instance.getString(text)}</li>;
                })}
              </ul>
              <br></br>
            </div>
          )}
          {requestError.sorryMessage && (
            <div>
              <h6>{Localization.instance.getString(requestError.sorryMessage)}</h6>
              <br></br>
            </div>
          )}
          {requestError.supportMessage && (
            <div>
              <h6>{Localization.instance.getString(requestError.supportMessage)}</h6>
              <br></br>
            </div>
          )}
          {requestError.errorInfo && requestError.errorInfo.length > 0 && (
            <div>
              <h6>{Localization.instance.getString('TXT_Detailed_info')}</h6>
              <ul>
                {requestError.errorInfo.map((info) => {
                  return (
                    <div>
                      <li>{Localization.instance.getString(info.clientMessage)}</li>
                      <ApplicationErrors errors={info.errors} />
                    </div>
                  );
                })}
              </ul>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {requestError.authenticationRequired && (
            <button
              tabIndex={1}
              ref={refAuthenticationRequired}
              data-event="ignore"
              className="btn btn-primary col-sm-2"
              onClick={() => reauthenticate()}
            >
              {' '}
              {Localization.instance.getString('TXT_Reauthenticate')}{' '}
            </button>
          )}
          {requestError.allowRetry && (
            <button
              tabIndex={2}
              ref={refAllowRetry}
              data-event="ignore"
              className="btn btn-primary col-sm-2"
              onClick={() => retry()}
            >
              {' '}
              {Localization.instance.getString('TXT_Try_again')}{' '}
            </button>
          )}
          {requestError.allowIgnore && (
            <button
              tabIndex={3}
              ref={refAllowIgnore}
              data-event="ignore"
              className="btn btn-primary col-sm-2"
              onClick={() => ignore()}
            >
              {' '}
              {Localization.instance.getString(isOnlyOneVisibleButton() ? 'TXT_OK' : 'TXT_Ignore')}{' '}
            </button>
          )}
          {requestError.allowCloseWindow && (
            <button
              tabIndex={4}
              ref={refAllowClose}
              data-event="ignore"
              className="btn btn-primary col-sm-2"
              onClick={() => closeWindow()}
            >
              {' '}
              {Localization.instance.getString(
                isOnlyOneVisibleButton()
                  ? 'TXT_OK'
                  : requestError.actionType === 'INTERACT'
                    ? 'TXT_CloseWindowTab'
                    : 'TXT_Close',
              )}{' '}
            </button>
          )}
          {requestError.allowLogout && (
            <button
              tabIndex={5}
              ref={refAllowLogout}
              data-event="ignore"
              className="btn btn-primary col-sm-2"
              onClick={() => logout()}
            >
              {' '}
              {Localization.instance.getString(isOnlyOneVisibleButton() ? 'TXT_OK' : 'TXT_USER_ACTION_CLOSE_APP')}{' '}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );

  function logout() {
    // --> Close modal processing
    onCloseModal();

    // --> Confirm request error
    const userAction: RequestError.UserAction = 'LOGOUT';
    dispatch({
      type: WindowActions.CONFIRM_REQUEST_ERROR,
      payload: { userAction },
    });

    // --> Logout
    WindowManger.logout(props.startLoading, props.endLoading); // BEWARE: confusing, but this is NOT a thunk action !!
  }
  function reauthenticate() {
    // --> Close modal processing
    onCloseModal();

    // --> Confirm request error
    const userAction: RequestError.UserAction = 'LOGIN';
    dispatch({
      type: WindowActions.CONFIRM_REQUEST_ERROR,
      payload: { userAction },
    });

    // --> Reauthenticate on OIDC provider
    signinRedirect({
      redirectMethod: 'replace',
      redirect_uri: window.location.origin + window.location.pathname,
    })
      .then(() => {
        const userObj = getUser();
        const user = userObj?.extendedUserProfile()?.app_user_id;
        props.startPolling(user || '');
      })
      .catch((error: Error) => logger.error(error))
      .finally(() => {
        props.endLoading();
      });
  }
  function closeWindow() {
    // --> Close modal processing
    onCloseModal();

    // --> Confirm request error
    const userAction: RequestError.UserAction = 'CLOSE';
    dispatch({
      type: WindowActions.CONFIRM_REQUEST_ERROR,
      payload: { userAction },
    });

    // --> Close window
    if (requestError!.windowId && !requestError!.windowId.includes('DUMMY')) {
      props.closeWindow(requestError!.windowId, props.endLoading);
    }
  }
  function ignore() {
    // --> Close modal processing
    onCloseModal();

    // --> Process returned server data
    requestError!.processResult();

    // --> Confirm request error
    const userAction: RequestError.UserAction = 'IGNORE';
    dispatch({
      type: WindowActions.CONFIRM_REQUEST_ERROR,
      payload: { userAction },
    });
  }
  function retry() {
    // --> Close modal processing
    onCloseModal();

    // --> Confirm request error
    const userAction: RequestError.UserAction = 'RETRY';
    dispatch({
      type: WindowActions.CONFIRM_REQUEST_ERROR,
      payload: { userAction },
    });

    // --> Retry
    if (requestError!.actionType === 'INTERACT') {
      props.interact(...[...requestError!.actionArguments, requestError!.checkpoint]);
    } else if (requestError!.actionType === 'LAUNCH') {
      props.launch(...[...requestError!.actionArguments, requestError!.checkpoint]);
    } else {
      logger.error(`Programming error: retry for action type '${requestError!.actionType}' is not yet implemented`);
    }
  }
  function onCloseModal() {
    props.restoreWindowInputFocus(0);
  }
  function isOnlyOneVisibleButton(): boolean {
    let numberOfButtons = 0;
    if (requestError?.authenticationRequired) numberOfButtons += 1;
    if (requestError?.allowRetry) numberOfButtons += 1;
    if (requestError?.allowIgnore) numberOfButtons += 1;
    if (requestError?.allowCloseWindow) numberOfButtons += 1;
    if (requestError?.allowLogout) numberOfButtons += 1;
    return numberOfButtons === 1;
  }
  function onKeyDown(e: React.KeyboardEvent) {
    // e.g. hotkeys key down should not be triggered when pressing <SHIFT + F12>        ==> NTH: need more general way to block those function keys
    if (e.key !== 'Tab' && e.key !== 'Enter' && e.key !== 'Escape') {
      e.stopPropagation();
      e.preventDefault(); // by stopping propagation, parents are not informed anymore, so they cannot block default function keys of the browser (without this statement Google opens development center when pressing F12)
    }
  }
  function onKeyUp(e: React.KeyboardEvent) {
    // e.g. hotkeys key up should not be triggered when pressing <F12>                  ==> NTH: need more general way to block those function keys
    e.stopPropagation();
  }

  function onEscapeKeydown() {
    if (requestError.authenticationRequired) {
      reauthenticate();
    } else if (requestError.allowIgnore) {
      ignore();
    } else if (requestError.allowCloseWindow) {
      closeWindow();
    } else if (requestError.allowLogout) {
      logout();
    }
  }
};

const mapDispatchToProps = {
  closeWindow: (windowId: string, endLoading: () => void) => WindowManger.CloseWindow(windowId, endLoading),
  interact: (...args: any[]) => (WindowManger.Interact as any)(...args),
  launch: (...args: any[]) => (WindowManger.Launch as any)(...args),
};

export const RequestErrorView = connect(null, mapDispatchToProps)(RequestErrorViewComponent);

import { Accordion, NumericField, Text, useIptorTranslation } from '@iptor/base';
import { Grid2 as Grid } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import {
  OperationResponseDataData,
  ProjectCostCodeDropdown,
  ProjectPhaseDropdown,
  ProjectsDropdown,
} from '@iptor/business';

export type WeightAndProjectProps = {
  control: Control<
    Partial<OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>>
  >;
  orderLineInfo: OperationResponseDataData<'internal.v1.base-orders', 'GET /salesOrders/{order}/lines/{orderLine}'>;
};

function WeightAndProject({ control, orderLineInfo }: WeightAndProjectProps) {
  const { t } = useIptorTranslation();
  return (
    <Accordion headingTitle="Weight & Project">
      <Text
        variant="h6"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        {t('common:TXT_WeightVolume')}
      </Text>
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="grossWeight"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                error={!!error}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Gross_Weight')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="netWeight"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                id={field.name}
                error={!!error}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Net_Weight')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="grossVolume"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                id={field.name}
                error={!!error}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Gross_Volume')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="netVolume"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericField
                {...field}
                fullWidth
                error={!!error}
                id={field.name}
                helperText={error ? error.message : ''}
                label={t('common:TXT_Net_Volume')}
                buttonLayout="none"
                onChangeNumeric={(value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
      </Grid>
      <Text
        variant="h6"
        sx={(theme) => ({
          marginY: theme.spacing(3),
        })}
      >
        {t('common:TXT_Project')}
      </Text>
      <Grid container spacing={2}>
        <Grid size={3}>
          <Controller
            name="project"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ProjectsDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                id={field.name}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                fullWidth
                label={t('common:TXT_Project')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name={'projectPhase'}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ProjectPhaseDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                id={field.name}
                projectQuotationNumber={orderLineInfo.project}
                color={error ? 'error' : 'primary'}
                helperText={error ? error.message : ''}
                fullWidth
                label={t('common:TXT_Phase')}
              />
            )}
          />
        </Grid>
        <Grid size={3}>
          <Controller
            name="projectCostCode1"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ProjectCostCodeDropdown
                onChange={(value) => field.onChange(value)}
                value={field.value}
                color={error ? 'error' : 'primary'}
                id={field.name}
                helperText={error ? error.message : ''}
                fullWidth
                label={t('common:TXT_Cost_Code')}
              />
            )}
          />
        </Grid>
      </Grid>
    </Accordion>
  );
}

export default WeightAndProject;

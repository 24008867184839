import { Theme } from '@mui/material';
import { Radius } from './Radius';
import { TextSize, TextWeight } from './Typography';

export const TextField = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      variants: [
        {
          props: { color: 'warning' },
          style: {
            '& .MuiInputBase-root': {
              background: theme.palette.warning.light,
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
              color: theme.palette.warning.main,
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            '& .MuiInputBase-root': {
              background: theme.palette.error.light,
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
              color: theme.palette.error.main,
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            label: {
              position: 'relative',
              transform: 'none',
              marginBottom: 0,
              lineHeight: 2,
              fontWeight: TextWeight.SEMIBOLD,
              fontSize: TextSize.XS,
              color: theme.palette.foreground[500],
            },
            input: {
              padding: 0,
            },
            '& .MuiInputBase-root': {
              '&.Mui-disabled ': {
                background: theme.palette.background.paper,
                outlineColor: theme.palette.grey[300],
                color: theme.palette.foreground[400],
                pointerEvents: 'none',
                '&:hover': {
                  border: 'none',
                },
              },
              '&.Mui-focused': {
                borderWidth: '1px',
              },
              border: `1px solid ${theme.palette.grey[400]}`,
              borderRadius: `${Radius.S}px`,
              background: theme.palette.background.paper,
              marginTop: 0,
              fontWeight: TextWeight.REGULAR,
              fontSize: '16px',
              lineHeight: 2,
              color: theme.palette.foreground[500],
              // height: theme.spacing(6),
              padding: theme.spacing(2),
              '&::after, &::before': {
                borderBottom: 'none!important',
              },

              '&:hover': {
                // border: '1px solid #868C92',
                outlineColor: theme.palette.grey[500],
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'primary' },
          style: {
            '& .MuiInputBase-root': {
              '&.Mui-focused': {
                borderColor: theme.palette.primary.main,
                '& .MuiInputAdornment-root .MuiTypography-root': {
                  color: theme.palette.primary.main,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'secondary' },
          style: {
            '& .MuiInputBase-root': {
              '&.Mui-focused': {
                borderColor: theme.palette.secondary.main,
                '& .MuiInputAdornment-root .MuiTypography-root': {
                  color: theme.palette.secondary.main,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'warning' },
          style: {
            '& .MuiInputBase-root': {
              borderColor: theme.palette.warning.main,
              '&.Mui-focused': {
                borderColor: theme.palette.warning.dark,
                '& .MuiInputAdornment-root .MuiTypography-root': {
                  color: theme.palette.warning.main,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'error' },
          style: {
            '& .MuiInputBase-root': {
              borderColor: theme.palette.error.main,
              '&.Mui-focused': {
                borderColor: theme.palette.error.dark,
                '& .MuiInputAdornment-root .MuiTypography-root': {
                  color: theme.palette.error.main,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard', color: 'success' },
          style: {
            '& .MuiInputBase-root': {
              borderColor: theme.palette.success.main,
              '&.Mui-focused': {
                borderColor: theme.palette.success.dark,
                '& .MuiInputAdornment-root .MuiTypography-root': {
                  color: theme.palette.success.main,
                },
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputBase-root': {
              // minWidth: '200px', // Removed as this should be done in indvidual usages. DO NOT use min height / width in base theme unless the values are small e.g.: 48px by 48px etc.
              height: '42px',
            },
          },
        },
      ],
    }),
  },
};

import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuProps as MuiMenuProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Icon } from '../Icon';
import IconType from '../../constants/IconType';

export type MenuItemProps = MuiMenuItemProps & {
  icon?: IconType;
  text?: string;
  onClick?: () => void;
  tooltip?: string;
  disabled?: boolean;
};

export type MenuProps = MuiMenuProps & {
  options: MenuItemProps[];
  backgroundColor: 'light' | 'dark';
  open?: boolean;
  id?: string;
};

export const Menu: React.FC<MenuProps> = ({ options, anchorEl, open, backgroundColor = 'light', ...props }) => {
  const theme = useTheme();
  return (
    <MuiMenu
      id={props.id || 'base-dropdown'}
      anchorEl={anchorEl || null}
      open={open}
      color={backgroundColor}
      {...props}
      data-iscapture="true"
      data-for="global"
      data-tip={props?.id}
      variant={'menu'}
    >
      {options &&
        options.map(({ text, icon, tooltip, ...props }) => (
          <Tooltip title={tooltip || ''} placement={'left'} arrow>
            <MenuItem {...props} color={backgroundColor}>
              {icon && (
                <ListItemIcon
                  sx={{
                    span: {
                      cursor: 'pointer',
                    },
                    svg: {
                      cursor: 'pointer',
                      pointerEvents: 'visible',
                    },
                  }}
                  color={backgroundColor}
                >
                  <Icon
                    icon={icon}
                    size={'M'}
                    color={backgroundColor === 'light' ? theme.palette.common.black : theme.palette.common.white}
                  />
                </ListItemIcon>
              )}
              {text && <ListItemText>{text}</ListItemText>}
            </MenuItem>
          </Tooltip>
        ))}
      {options.length === 0 && (
        <MenuItem disabled={true}>
          <Typography variant={'body2'}>No data</Typography>
        </MenuItem>
      )}
    </MuiMenu>
  );
};

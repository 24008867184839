import { FlagConstraint, IConstraint, MPPlatformConstraint } from './types';

export class Constraint {
  protected invert: boolean;
  protected constraints: IConstraint[] = [];
  protected orConstraints: Constraint[] = [];
  protected andConstraints: Constraint[] = [];

  constructor(constraint: string, invert: boolean = false) {
    this.invert = invert;
    this.initialize(constraint);
  }

  evaluate(flags: boolean[], value: boolean): boolean {
    if (flags !== null) {
      if (this.andConstraints.length > 0) {
        value = true;
        let length: number = this.andConstraints.length;
        for (let i = 0; i < length; i++) {
          let constr: Constraint = this.andConstraints[i];
          if (!constr.evaluate(flags, false)) {
            value = false;
            break;
          }
        }
      } else if (this.orConstraints.length > 0) {
        value = false;
        let length: number = this.orConstraints.length;
        for (let i = 0; i < length; i++) {
          let constr: Constraint = this.orConstraints[i];
          value = constr.evaluate(flags, false);
          if (value) break;
        }
      } else {
        let workBol: boolean = true;
        if (this.constraints.length > 0) {
          let length: number = this.constraints.length;
          for (let i = 0; i < length; i++) {
            let constr: IConstraint = this.constraints[i];
            workBol = constr.evaluate(flags, value);
            if (i === 0) value = workBol;
            else value = workBol && value;
          }
        }
      }
    }
    if (this.invert) return !value;
    return value;
  }

  initialize(constraint: string): void {
    if (!constraint) return;
    if (constraint[0] === '!') {
      this.invert = true;
      constraint = constraint.substring(1);
    }

    let notIndex: number = constraint.indexOf('!');
    let orIndex: number = constraint.indexOf('O');
    let length: number = constraint.length;
    let start: number = 0;
    if (orIndex !== -1 && (notIndex === -1 || orIndex < notIndex)) {
      for (let i = 0; i < length; i++) {
        if (constraint[i] === 'O') {
          this.orConstraints.push(new Constraint(constraint.substring(start, i)));
          start = i + 1;
        }
      }

      if (start + 1 < length) {
        this.orConstraints.push(new Constraint(constraint.substring(start)));
      }
    } else if (notIndex !== -1) {
      this.andConstraints.push(new Constraint(constraint.substring(0, notIndex)));
      this.andConstraints.push(new Constraint(constraint.substring(notIndex + 1), true));
    } else {
      let list: string[] = [];
      if (length > 0 && constraint[0] === 'A') {
        start = 1;
      }

      for (let i = start; i < length; i++) {
        if (constraint[i] === 'A') {
          list.push(constraint.substring(start, i));
          start = i + 1;
        }
      }

      if (start + 1 < length) {
        list.push(constraint.substring(start));
      }

      let _constraint: IConstraint;
      for (let i in list) {
        let flag: string = list[i];
        _constraint =
          flag.indexOf(MPPlatformConstraint.MP_FLAG) > -1 ? new MPPlatformConstraint(flag) : new FlagConstraint(flag);
        // if (flag.indexOf(MPPlatformConstraint.MP_FLAG) > -1) _constraint = new MPPlatformConstraint(flag);
        //else _constraint = new FlagConstraint(flag);
        this.constraints.push(_constraint);
      }
    }
  }

  toString(): string {
    let buf: string = 'Constraints(';
    if (this.constraints.length) buf += this.constraints;
    if (this.invert) buf += ' invert: ' + this.invert;
    if (this.andConstraints.length) buf += ' AND ' + this.andConstraints;
    if (this.orConstraints.length) buf += ' OR ' + this.orConstraints;
    buf += ')';
    return buf;
  }
}

//export const SolveConstraint = (constraint: string, flags?: boolean[]): boolean => {
//let solvedConstraint : boolean = false;
//let closingBrackets = 0;
//let logicalString = constraint;
//closingBrackets +=  logicalString.match(/!/g)?.length || 0;
//closingBrackets +=  logicalString.match(/A/g)?.length || 0;
//closingBrackets +=  logicalString.match(/O/g)?.length || 0;
//logicalString = logicalString.replaceAll('!', '!(');
//logicalString = logicalString.replaceAll('N', '!');
//logicalString = logicalString.replaceAll('O', '||(');
//logicalString = logicalString.replaceAll('A', '&&(');
//logicalString += Array(closingBrackets).fill(')').join('');
//return solvedConstraint;
//}

//40ON40N98N99AN72 -> 40 || ((!40 && !98 && !99) && !72)
//N15ON16!1516 -> !15 || (!16 && !(15 && 16))
//Priority of grouping -> O -> A -> !
//

import React, { createContext, useState, useContext, ReactNode } from 'react';

export enum ContextMenuEnum {
  CONTEXT_HELP = 'CONTEXT_HELP',
  ATTACHMENTS = 'ATTACHMENTS',
  ADD_NOTES = 'ADD_NOTES',
}
interface SideMenuContextProps {
  selectedContextMenu: ContextMenuEnum | null;
  setSelectedContextMenu: (menu: ContextMenuEnum | null) => void;
  width: number;
  setWidth: (width: number) => void;
}

const SideMenuContext = createContext<SideMenuContextProps | undefined>(undefined);

interface SideMenuContextProviderProps {
  children: ReactNode;
}

export const SideMenuContextProvider: React.FC<SideMenuContextProviderProps> = ({ children }) => {
  const [selectedContextMenu, setSelectedContextMenu] = useState<ContextMenuEnum | null>(null);
  const [width, setWidth] = useState<number>(400);
  const value = {
    selectedContextMenu,
    setSelectedContextMenu,
    width,
    setWidth,
  };

  return <SideMenuContext.Provider value={value}>{children}</SideMenuContext.Provider>;
};

// Custom hook to use the context
export const useSideMenuContext = () => {
  const context = useContext(SideMenuContext);
  if (context === undefined) {
    throw new Error('useSideMenuContext must be used within a SideMenuContextProvider');
  }
  return context;
};

import { BaseDropdown } from './component/BaseDropdown';
import { useDropdownOptions } from '../../hooks/useDropdownOptions';
import { useEffect } from 'react';
import { BusinessDropdownProps } from './component/type';

export type AddressCountyProps = BusinessDropdownProps & {
  country?: string;
  state?: string;
};

const COLUMN_NAMES: string[] = ['State/Province', 'Description'];

export const AddressCountyDropdown = ({ country, state, ...props }: AddressCountyProps) => {
  const shouldPreventApiCall = props.disabled || props.readOnly;

  const {
    options: warehousesOptions,
    loading,
    refetch,
  } = useDropdownOptions({
    apiId: 'internal.v1.base-tables',
    operationId: country && state ? 'GET /countries/{country}/states/{state}/counties' : 'GET /promptValues/{windowId}',
    labelAttribute: 'description',
    valueAttribute: 'county',
    params: country && state ? { country, state } : { windowId: 'counties' },
    shouldPreventApiCall,
  });

  useEffect(() => {
    refetch();
  }, [country, state]);

  return (
    <BaseDropdown {...props} loading={loading} label="County" options={warehousesOptions} columnNames={COLUMN_NAMES} />
  );
};

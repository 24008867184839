import { Switch as MuiSwitch, SwitchProps, FormControlLabel } from '@mui/material';
import { useState } from 'react';

interface LabeledSwitchProps extends SwitchProps {
  label?: string;
  labelPosition?: 'start' | 'end';
}

export function Switch({ label, labelPosition = 'end', ...props }: LabeledSwitchProps) {
  const [isFocused, setIsfocused] = useState(false);
  return label ? (
    <FormControlLabel
      control={
        <MuiSwitch
          disableRipple
          {...props}
          onFocusVisible={(e) => {
            setIsfocused(true);
            props?.onFocusVisible?.(e);
          }}
          onBlur={(e) => {
            setIsfocused(false);
            props?.onBlur?.(e);
          }}
          className={`${isFocused ? 'Mui-focusVisible' : ''} ${props.className}`}
        />
      }
      label={label}
      labelPlacement={labelPosition}
      sx={(theme) => ({
        gap: theme.spacing(1),
        color: theme.palette.foreground[500],
        margin: 0,
      })}
      data-iscapture="true"
      data-for="global"
      data-tip={props.id}
      onClick={(event) => event.stopPropagation()}
    />
  ) : (
    <>
      <MuiSwitch
        disableRipple
        {...props}
        onFocusVisible={(e) => {
          setIsfocused(true);
          props.onFocusVisible?.(e);
        }}
        onBlur={(e) => {
          setIsfocused(false);
          props.onBlur?.(e);
        }}
        data-iscapture="true"
        data-for="global"
        data-tip={props.id}
        className={`${isFocused ? 'Mui-focusVisible' : ''} ${props.className}`}
      />
    </>
  );
}

export default (
  <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="none">
    <path
      d="M7 0H17.9677C19.6246 0 20.9677 1.34315 20.9677 3V22C20.9677 23.6569 19.6246 25 17.9677 25H3C1.34315 25 0 23.6569 0 22L0 7L7 0Z"
      fill="#AED319"
      stroke="none"
    />
    <path d="M0 7L7 0L6.99986 4.00011C6.9998 5.65692 5.65667 7 3.99986 7H0Z" fill="#E9FF91" stroke="none" />
    <path
      d="M6.02691 13.128C5.61624 13.128 5.30157 13.2533 5.08291 13.504C4.86424 13.7547 4.75491 14.1093 4.75491 14.568C4.75491 15.032 4.87491 15.3893 5.11491 15.64C5.36024 15.8907 5.68824 16.016 6.09891 16.016C6.31757 16.016 6.50157 15.9867 6.65091 15.928C6.80557 15.8693 6.95224 15.8027 7.09091 15.728C7.18157 15.8027 7.25091 15.8933 7.29891 16C7.35224 16.1013 7.37891 16.2213 7.37891 16.36C7.37891 16.5787 7.25624 16.7653 7.01091 16.92C6.77091 17.0693 6.40291 17.144 5.90691 17.144C5.54957 17.144 5.21357 17.0933 4.89891 16.992C4.58424 16.8907 4.30957 16.736 4.07491 16.528C3.84024 16.3147 3.65357 16.048 3.51491 15.728C3.38157 15.4027 3.31491 15.016 3.31491 14.568C3.31491 14.152 3.37891 13.7867 3.50691 13.472C3.64024 13.152 3.81891 12.8827 4.04291 12.664C4.27224 12.4453 4.53891 12.28 4.84291 12.168C5.14691 12.056 5.47224 12 5.81891 12C6.30957 12 6.68824 12.08 6.95491 12.24C7.22691 12.4 7.36291 12.6053 7.36291 12.856C7.36291 12.9947 7.32824 13.1147 7.25891 13.216C7.18957 13.3173 7.10957 13.3973 7.01891 13.456C6.88024 13.3653 6.73091 13.288 6.57091 13.224C6.41624 13.16 6.23491 13.128 6.02691 13.128Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M9.50122 15.072C9.26655 14.992 9.05322 14.912 8.86122 14.832C8.66922 14.7467 8.50389 14.6453 8.36522 14.528C8.22655 14.4107 8.11722 14.272 8.03722 14.112C7.96255 13.9467 7.92522 13.7467 7.92522 13.512C7.92522 13.0587 8.09855 12.696 8.44522 12.424C8.79722 12.152 9.28789 12.016 9.91722 12.016C10.1466 12.016 10.3599 12.032 10.5572 12.064C10.7546 12.096 10.9226 12.1467 11.0612 12.216C11.2052 12.28 11.3172 12.3653 11.3972 12.472C11.4772 12.5733 11.5172 12.6933 11.5172 12.832C11.5172 12.9707 11.4852 13.0907 11.4212 13.192C11.3572 13.288 11.2799 13.3707 11.1892 13.44C11.0719 13.3653 10.9146 13.3013 10.7172 13.248C10.5199 13.1893 10.3039 13.16 10.0692 13.16C9.82922 13.16 9.65322 13.1947 9.54122 13.264C9.42922 13.328 9.37322 13.4107 9.37322 13.512C9.37322 13.592 9.40789 13.6587 9.47722 13.712C9.54655 13.76 9.65055 13.8053 9.78922 13.848L10.2132 13.984C10.7146 14.144 11.0986 14.3493 11.3652 14.6C11.6372 14.8453 11.7732 15.1813 11.7732 15.608C11.7732 16.0613 11.5946 16.4293 11.2372 16.712C10.8799 16.9893 10.3546 17.128 9.66122 17.128C9.41589 17.128 9.18655 17.1067 8.97322 17.064C8.76522 17.0267 8.58122 16.9707 8.42122 16.896C8.26655 16.816 8.14389 16.72 8.05322 16.608C7.96789 16.4907 7.92522 16.3573 7.92522 16.208C7.92522 16.0533 7.97055 15.9227 8.06122 15.816C8.15189 15.704 8.25055 15.6187 8.35722 15.56C8.50655 15.6773 8.68789 15.7787 8.90122 15.864C9.11989 15.9493 9.35722 15.992 9.61322 15.992C9.87455 15.992 10.0586 15.952 10.1652 15.872C10.2719 15.792 10.3252 15.6987 10.3252 15.592C10.3252 15.4853 10.2826 15.4053 10.1972 15.352C10.1119 15.2933 9.99189 15.2373 9.83722 15.184L9.50122 15.072Z"
      fill="white"
      stroke="none"
    />
    <path
      d="M15.3183 16.84C15.2436 16.904 15.1263 16.96 14.9663 17.008C14.8116 17.056 14.6409 17.08 14.4543 17.08C14.2303 17.08 14.0383 17.048 13.8783 16.984C13.7183 16.9147 13.6089 16.8213 13.5503 16.704C13.4916 16.5813 13.4196 16.424 13.3343 16.232C13.2489 16.0347 13.1583 15.816 13.0623 15.576C12.9716 15.336 12.8756 15.0827 12.7743 14.816C12.6783 14.544 12.5849 14.2693 12.4943 13.992C12.4036 13.7147 12.3183 13.4427 12.2383 13.176C12.1583 12.9093 12.0889 12.6587 12.0303 12.424C12.1049 12.3493 12.2116 12.2827 12.3503 12.224C12.4943 12.16 12.6463 12.128 12.8063 12.128C13.0036 12.128 13.1636 12.1707 13.2863 12.256C13.4143 12.336 13.5076 12.488 13.5663 12.712C13.7103 13.2347 13.8543 13.736 13.9983 14.216C14.1476 14.6907 14.3023 15.1867 14.4623 15.704H14.5103C14.6543 15.2027 14.8009 14.656 14.9503 14.064C15.1049 13.472 15.2516 12.8827 15.3903 12.296C15.4863 12.248 15.5876 12.208 15.6943 12.176C15.8063 12.144 15.9316 12.128 16.0703 12.128C16.2676 12.128 16.4356 12.1733 16.5743 12.264C16.7129 12.3493 16.7823 12.4933 16.7823 12.696C16.7823 12.8133 16.7529 12.984 16.6943 13.208C16.6409 13.432 16.569 13.6853 16.4783 13.968C16.393 14.2453 16.2943 14.536 16.1823 14.84C16.0756 15.144 15.9663 15.432 15.8543 15.704C15.7476 15.9707 15.6463 16.208 15.5503 16.416C15.4543 16.6187 15.3769 16.76 15.3183 16.84Z"
      fill="white"
      stroke="none"
    />
  </svg>
);
